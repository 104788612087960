import React, { Component } from 'react'
import { publish } from '../../services/EventBus'
import './CardMinify.scss'

function card2hash(card) {
  return card.id + card.hidden + card.title
}

function organizeCardDeep(cards) {
  return cards.map(c => {
    return {...c, deep: c.id.split(`$`).length - 1} // split no "id" segundo padrão de nomenclatura
  })
}

export default class CardMinify extends Component {

  state = {
    cards: []
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const cards = organizeCardDeep(nextProps.cards).sort((a,b) => a.id < b.id ? -1 : 1)
    const oldHash = prevState.cards.map(card2hash)
    const newHash = cards.map(card2hash)
    return oldHash === newHash ? null : {cards}
  }

  getHint = (cards) => {
    const numCards = cards.length
    const numHiddenCards = cards.filter(_ => _.hidden).length
    if (numCards === 0) {
      return `NENHUMA VISUALIZAÇÃO`
    }
    const prefix = numHiddenCards ? (`` + (numCards - numHiddenCards) + `/`) : ``
    const suffix = `VISUALIZAÇ` + (numCards === 1 ? `ÃO` : `ÕES`)
    return prefix + numCards + ` ` + suffix
  }

  render() {
    const { cards } = this.state
    return (
      <div className={ `CardMinify n${cards.length}` }>
        <div className="block top"></div>
        <div className="hint">{ this.getHint(cards) }</div>
        <div className="content">
          { cards.length > 1 ? this.renderCloseAll(cards) : null }
          { this.renderContent(cards) }
        </div>
        <div className="block bottom"></div>
      </div>
    )
  }

  closeCardsHandler = () => {
    this.props.closeCards()
  }

  renderCloseAll(cards) {
    const hidden = cards.filter(_ => _.hidden).length < cards.length
    return (
      <div className="line deep deep1 border-bottom main">
        <button className={ `eye ` + (!hidden ? `closed` : ``) } onClick={ this.eyeCloseAllClickHandler }></button>
        <div className="label header">
          <button className="btn-close" onClick={ this.closeCardsHandler }>
            [ LIMPAR ]
          </button>
        </div>
      </div>
    )
  }

  renderContent(cards) {
    return (
      <React.Fragment>
        {cards.map(i => (
          <div key={ i.id } className={ `line deep` + i.deep + (i.hidden ? ` hidden` : ``) }>
            <button className={ `eye ` + (i.hidden ? `closed` : ``) } onClick={ this.eyeClickHandler.bind(this, i) }></button>
            <div className="label" onClick={ this.clickUpLevelHandler.bind(this, i) }>
              { i.title || `CARREGANDO...` }
            </div>
          </div>
        ))}
      </React.Fragment>
    )
  }

  eyeClickHandler = (card) => {
    this.props.setVisibility(card.id, !!card.hidden)
  }

  eyeCloseAllClickHandler = () => {
    const { cards } = this.state
    const closing = cards.filter(_ => _.hidden).length < cards.length
    const cardIds = cards.map(_ => _.id)
    const timer = setInterval(() => {
      if (cardIds.length === 0) {
        clearInterval(timer)
      } else {
        this.props.setVisibility(cardIds.shift(), !closing)
      }
    }, 1)
    this.setState({cards: cards.map(c => ({...c, hidden: closing}))})
  }

  clickUpLevelHandler = (card) => {
    publish(`card::autofocus`, card)
  }

}
