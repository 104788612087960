import * as type from '../fieldTypes'

export const FactionsEvents = {
  created: `2019-09-27`,
  context: `Facções`,
  role: `CEREBRUM_EVENTOS_FACCOES_AREA`,
  title: `Atividades de Facções`,
  description: 'Visualizar as atividades das facções criminosas em uma área',
  parser: () => {
    return { 
      card: `CardFactionsEventsSearch`, 
      props: { } 
    }
  }
}

export const FactionsEventsDate = {
  created: `2019-09-27`,
  context: `Facções`,
  role: `CEREBRUM_EVENTOS_FACCOES_AREA`,
  params: {
    date: { label: `Data`, placeholder: `dd/mm/aaaa`, type: type.date },
    description: { label: `Descrição`, placeholder: `Ex: ônibus, ataque`, type: type.text, optional: true }
  },
  parser: ({ date, description }) => {
    return { 
      card: `CardFactionsEventsSearch`, 
      props: { 
        time: { type: 'date', value: { date } },
        description
      } 
    }
  }
}

export const FactionsEventsRange = {
  created: `2019-09-27`,
  context: `Facções`,
  role: `CEREBRUM_EVENTOS_FACCOES_AREA`,
  description: 'Intervalo de tempo para filtrar os registros de ações das organizações criminosas',
  params: {
    startDateTime: {label: `Data e Hora Inicial`, placeholder: `dd/mm/aaaa hh:mm`, type: type.datetime, optional: true },
    endDateTime: {label: `Data e Hora Final`, placeholder: `dd/mm/aaaa hh:mm`, type: type.datetime, optional: true },
    description: { label: `Descrição`, placeholder: `Ex: ônibus, ataque`, type: type.text, optional: true }
  },
  parser: ({ startDateTime, endDateTime, description }) => {
    if (!startDateTime && !endDateTime && !description) throw Error('Pelo menos um dos valores deve ser informado: início, término ou descrição')
    return { 
      card: `CardFactionsEventsSearch`, 
      props: { 
        time: { type: 'range', value: { startDateTime, endDateTime } },
        description
      } 
    }
  }
}