import React, { Component } from 'react'
import util from '../../../../helpers/util'
import srcImageAvatar from '../../../../images/avatar.png'
import './Carteira.scss'


const Item = (props) => (
  <div className="container-item">
    <div className={`item ` + (props.className || ``)}>
      <label>{props.label}</label>
      <div className="value">{props.children || `-`}</div>
    </div>
  </div>
)

const createGroupRows = (...params) => {
  const g = []
  for (let i = 0; i < params.length - 1; i += 2) {
    const [label, value] = [params[i], params[i + 1]]
    if (value) {
      g.push({ label, value })
    }
  }
  return g
}

export default class Carteira extends Component {

  state = {
    picture: false,
    groups: []
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.hidden && prevState.picture) {
      return { ...prevState, picture: false }
    }
    return null
  }

  componentDidMount() {
    const groups = []
    const { addressInfo } = this.props.json
    const { json } = this.props
    //
    groups.push(createGroupRows(
      `RG`, json.rg + ` ` + json.rgEmitter,
      `CPF`, json.cpf ? util.formatCPF(json.cpf) : null,
      `Nasc.`, util.date2str(json.birthDate) + ` (` + util.calcAge(json.birthDate) + ` anos)`,
    ))
    groups.push(createGroupRows(
      `Mãe`, json.mother,
      `Pai`, json.father
    ))
    if (json.email || json.phones) {
      groups.push(createGroupRows(
        `Email`, json.email,
        `Contato`, json.phones && json.phones.length ? json.phones.map(util.formatPhone).join(` / `) : ``,
      ))
    }
    if (addressInfo) {
      let logr = addressInfo.address
      if (addressInfo.number) {
        logr += `, ` + addressInfo.number
      }
      if (addressInfo.complement) {
        logr += ` ` + addressInfo.complement
      }
      groups.push(createGroupRows(
        `Logradouro`, logr,
        `Bairro`, addressInfo.district,
        `CEP`, addressInfo.cep ? util.formatCEP(addressInfo.cep) : ``,
        `Cidade`, addressInfo.city + ` - ` + addressInfo.uf
      ))
    }
    this.setState({ groups })
  }

  render() {
    if (this.props.hidden) {
      return <div className="Carteira hidden"></div>
    }
    const { hidden } = this.props
    const { picture } = this.state
    return (
      <div className={`Carteira ` + (hidden ? `hidden ` : ``) + (picture ? ` picture-mode` : ``)}>
        <div className="columns">
          <div className="column photo">
            {this.renderPicture()}
          </div>
          <div className="column info">
            {this.renderHeader()}
            {this.renderGroups()}
          </div>
        </div>
      </div>
    )
  }

  renderHeader = () => {
    const { key, type, protocol, registryDate, deliveryPredictDate, deliveryDate } = this.props.json
    const items = [
      { name: `Solicitação`, value: util.date2str(registryDate) },
      { name: `Previsão de Entrega`, value: util.date2str(deliveryPredictDate) },
      { name: `Data de Entrega`, value: deliveryDate ? util.date2str(deliveryDate) : `-` },
    ]
    return (
      <React.Fragment>
        <div className="group-info header">
          {this.props.unique ? <Item label='Bilhete'>{type.toUpperCase()}</Item> : null}
          <Item label='Protocolo'>{protocol}</Item>
          <Item label='Chave'>{key.toUpperCase()}</Item>
          <div className="row">
            {items.map((i, index) => (
              <Item label={i.name}>{i.value}</Item>
            ))}
          </div>
        </div>

      </React.Fragment>
    )
  }

  renderGroups = () => {
    return this.state.groups.map((group, index) => this.renderGroup(group, index))
  }

  renderGroup = (group = [], index = 0) => {
    return (
      <div className="group-info" key={index}>
        {group.map((i, key) => (
          <div key={key}>
            <Item label={i.label}>{i.value}</Item>
          </div>
        ))}
      </div>
    )
  }

  renderPicture = () => {
    const defaultImage = srcImageAvatar
    const url = this.props.json.photo || defaultImage
    const src = /^data/.test(url) || /\.\w{3,4}$/.test(url) ? url : (`data:image/png;base64,` + url)
    const isDefault = src === defaultImage
    return (
      <div className={`picture` + (isDefault ? ` empty` : ``)} onClick={this.togglePictureSize}>
        <img src={src} alt="" />
      </div>
    )
  }

  togglePictureSize = () => {
    const { picture } = this.state
    this.setState({ picture: !picture })
  }


}

