import React, { Component } from 'react'
import MenuBar from '../../Civil/Historico/MenuBar'
import util from '../../../../helpers/util'
import './Depoimentos.scss'

export class CardCVLIOcorrenciaDepoimentos extends Component {

  state = {
    current: 0,
    items: [],
    maximized: null
  }

  componentDidMount() {
    const items = [
      {title: `NARRATIVAS`,  data: this.props.narratives},
      {title: `CONDUTORES`,  data: this.props.conductorsTestimony},
      {title: `ACUSADOS`,    data: this.props.indictedTestimony},
      {title: `VÍTIMAS`,     data: this.props.victimsTestimony},
      {title: `TESTEMUNHAS`, data: this.props.witnessesTestimony},
    ].map(i => {
      const n = i.data.length
      i.text = n === 0 ? (`nada consta`) : (n === 1? `1 item` : `${n} itens`)
      return i
    })
    this.setState({items}, this.props.show)
    this.props.setTitle(`CVLI DEPOIMENTOS ` + this.props.procedureType + ` ` + this.props.id)
  }

  menuChange = index => {
    const item = this.state.items[index].data
    const maximized = item.length === 1 ? (item[0].date + item[0].personCode) : null
    this.setState({maximized, current: index})
  }

  clickHandler = i => {
    const id = i.date + i.personCode
    const maximized = id === this.state.maximized ? null : id
    this.setState({maximized})
  }

  openCriminalHandler = (personCode) => {
    this.props.openCard(`CardCriminalHistorico`, {regNumber: personCode})
  }

  openBuscaFoneticaHandler = (personName) => {
    this.props.openCard(`CardBuscaFonetica`, {name: personName})
  }

  render() {
    if (this.props.hidden) {
      return null
    }
    const { id, procedureType } = this.props
    const { current, items } = this.state
    return (
      <div className="CardCVLIOcorrenciaDepoimentos">
        <div className="card-title">{ procedureType } <span>{ id }</span></div>
        <MenuBar items={ items } current={ current } onChange={ this.menuChange } />
        { this.renderContent() }
      </div>
    )
  }

  renderContent() {
    const { title, data } = this.state.items[this.state.current]
    if (data.length === 0) {
      return <p className="message empty">NENHUM ITEM REGISTRADO</p>
    }
    return (
      <div className="content">
        {data.map((i, index) => (
          <React.Fragment key={ title + index }>
            { this.renderRow(i) }
          </React.Fragment>
        ))}
      </div>
    )
  }

  renderRow(i) {
    const isNarrative = i.narrativeInqOrdinance || i.narrativeBoTco || i.narrativeTco || i.finalReport
    return isNarrative ? this.renderNarrative(i) : this.renderDepoiment(i)
  }

  renderNarrative(i) {
    const list = [
      {title: `CONDUÇÃO`, text: i.narrativeConductPrisoner},
      {title: `BOLETIM DE OCORRÊNCIA`, text: i.narrativeBoTco},
      {title: `PORTARIA`, text: i.narrativeInqOrdinance},
      {title: `TERMO CIRCUNSTANCIADO DE OCORRÊNCIA`, text: i.narrativeTco},
      {title: `RELATÓRIO FINAL`, text: i.finalReport}
    ].filter(_ => _.text && _.text.trim().length).map(i => {
      i.text = i.text.replace(/\n{1,}/g, `\n`).split(`\n`).map((line, index) => {
        return <div key={ index } className="line">{ line }</div>
      })
      return i
    })
    return (
      <div className="depoiment narrative">
        {list.map((i, index) => (
          <React.Fragment key={ index }>
            <div className="caption">{ i.title }</div>
            <div className="text">{ i.text }</div>
          </React.Fragment>
        ))}
      </div>
    )
  }

  renderDepoiment(i) {
    const date = util.date2str(i.date) + ` ` + util.date2time(i.date)
    const text = i.testimony.split(`\\n`).map((line, index) => {
      return <p key={ index }>{ line }</p>
    })
    const maximized = this.state.maximized === i.date + i.personCode
    return (
      <div className={ `depoiment interaction ` + (maximized ? `maximized` : ``) }>
        <div className="headline">
          <button className="btn-close" onClick={ this.clickHandler.bind(this, i) }></button>
          <div className="caption">{ date }</div>
          <div className="name">
            <span className="code" onClick={ this.openCriminalHandler.bind(this, i.personCode) }>{ i.personCode }</span>
            <span className="value" onClick={ this.openBuscaFoneticaHandler.bind(this, i.personName) }>{ i.personName }</span>
          </div>
        </div>
        <div className="text" onClick={ this.clickHandler.bind(this, i) }>{ text }</div>
      </div>
    )
  }

}
