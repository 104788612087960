import React, { Component } from 'react'
import { List } from '../../../UI/List'
import SwitcherOnOff from '../../../UI/SwitcherOnOff'
import { SearchFilterWords } from '../../../UI/SearchFilterWords'
import { onlineObjects } from '../../../../helpers/onlineObjects'
import util from '../../../../helpers/util'
import './index.scss'

export class CardViaturasOnline extends Component {

  state = {
    connected: false,
    error: null,
    items: [],
    total: 0
  }

  componentDidMount() {
    this.props.setTitle(`VIATURAS ONLINE`)
    this.objs = onlineObjects(this, `/online-objects/vehicles`, {
      color: `#08C2A2`,
      title: `Viaturas`,
      popup: this.createPopup,
      frame2obj: this.wsFrameToObj,
      onChange: this.wsChanged
    })
  }

  componentWillUnmount() {
    this.objs.disable()
  }

  wsFrameToObj = frame => {
    const { id, name=`-`, ignitionState=`-`, landmark, collectDate, position } = frame
    const vehicle = ignitionState
    const speed = (landmark || ``).replace(/\.00\sKm/, ` km`)
    const query = [id, name, vehicle].join(` `).toUpperCase()
    const date  = new Date(collectDate)
    return {
      id, name, point: position, date, speed, vehicle, query
    }
  }

  wsChanged = ({connected, error, items, total}) => {
    if (connected && total === 0) {
      this.props.show()
    }
    this.setState({connected, error, items, total})
  }

  createPopup = item => {
    const { id, name, date } = item
    const text = util.date2time(date, true)
    return { title: name ? id + ` - ` + name : id, text }
  }

  changedOnlyRecents = value => {
    this.objs.setLastMinute(value)
  }

  changeWords = words => {
    this.objs.setFilter(words)
  }

  render() {
    return (
      <div className="CardViaturasOnline">
        <div className="card-title">
          VIATURAS <span>online</span>
        </div>
        { this.renderHeader() }
        { this.renderBody() }
      </div>
    )
  }

  renderHeader() {
    const { items, total } = this.state
    return (
      <div className="filter-container">
        <SwitcherOnOff label="ÚLTIMO MINUTO" onChange={ this.changedOnlyRecents } />
        <SearchFilterWords placeholder="Filtro..." subtotal={ items.length } total={ total } onChange={ this.changeWords } />
      </div>
    )
  }

  renderBody() {
    const { connected, error, items, total } = this.state
    if (error) {
      return <p className="message error">{ error }</p>
    }
    if (connected && items.length === 0 && total === 0) {
      return <p className="message">aguardando coletas...</p>
    }
    if (items.length === 0) {
      return <p className="message">nenhum item encontrado</p>
    }
    return <List card={ this } rows={ items } width={ 540 } highlightMarker={ true } zoomOnClick={ 14 }
      renderHeader={ () => (
        <React.Fragment>
          <div className="column code">Código</div>
          <div className="column name">Nome</div>
          <div className="column speed"></div>
          <div className="column vehicle">Situação</div>
        </React.Fragment>
      )}
      renderRow={ i => (
        <React.Fragment>
          <div className="column code">{ i.id }</div>
          <div className="column name">{ i.name || `-` }</div>
          <div className="column speed">{ i.speed }</div>
          <div className="column vehicle">{ i.vehicle }</div>
        </React.Fragment>
      )}
    />
  }

}
