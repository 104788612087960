import React, { Component } from 'react'
import util from '../../../../helpers/util'
import  helper from './helper'
import './index.scss'

function date2inputText(datetime) {
  return formatDateTime(util.datetime2str(datetime))
}

function formatDateTime(text) {
  return text.replace(/\W/g, ``).toUpperCase().replace(/[A-Z]/g, ``)
    .replace(/^(\d{2})(\d{2})(\d{4})(\d{2})(\d+)/, `$1/$2/$3 $4:$5`).slice(0, 16)
    .replace(/^(\d{2})(\d{2})(\d{4})(\d+)/, `$1/$2/$3 $4`)
    .replace(/^(\d{2})(\d{2})(\d+)/, `$1/$2/$3`)
    .replace(/^(\d{2})(\d+)/, `$1/$2`)
}

function text2datetime(text) {
  if (text.length !== 16)
    throw new Error(`Data/hora em formato inválido`)
  const d = text.split(`/`).map(i => parseInt(i, 10))
  const t = text.split(` `)[1].split(`:`).map(i => parseInt(i, 10))
  return new Date(d[2], d[1] - 1, d[0], t[0], t[1], 0, 0)
}


export class CardTornozeladosColetas extends Component {

  static COLORS = [
    `#EB633F`,
    `#CF88BC`,
    `#DB7588`,
    `#B3DAA2`,
    `#BEBCF4`,
  ]

  state = {
    loading: false,
    pages: [],
    errorMessage: null,
    startDateTime: new Date(),
    endDateTime: new Date(),
  }

  componentDidMount() {
    const { code } = this.props
    const group = {color: CardTornozeladosColetas.COLORS[1], title: `Tornozelados`}
    CardTornozeladosColetas.COLORS.forEach((color, index) => {
      this.props.publish(`map::layer.add`, {name: `line${index}`, group, lines: [], color, width: 3, gradient: true})
      this.props.publish(`map::layer.add`, {name: `page${index}`, group, points: [], color, popup: this.createPopup})
    })
    this.props.setTitle(`COLETAS TORNOZELADO ` + code)
    this.props.show()
    setTimeout(() => {
      const now = new Date()
      const start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 4, 0,0,0)
      const startDateTimeText = date2inputText(start)
      const endDateTimeText = date2inputText(now)
      this.setState({startDateTime: start, endDateTime: now, startDateTimeText, endDateTimeText}, this.searchHandler)
    }, 10)
  }

  createPopup = item => {
    return {title: this.props.code, text: util.datetime2str(item.date)}
  }

  clearMap = () => {
    CardTornozeladosColetas.COLORS.forEach((_, index) => {
      this.props.publish(`map::layer.clear`, {name: `line${index}`})
      this.props.publish(`map::layer.clear`, {name: `page${index}`})
    })
  }

  downloadCollects = () => {
    const { startDateTime, endDateTime } = this.state
    const { code } = this.props
    const func = params => this.props.http(`/justice-monitor/history/${code}`, params)
    if (this.downloading) {
      this.downloading.cancel()
    }
    this.downloading = helper.download(func, {startDateTime, endDateTime}, {
      initialized: () => {
        this.setState({pages: []})
        this.clearMap()
      },
      data: ({id, date, time, json}) => {
        const pageId = `page${id}`
        const lineId = `line${id}`
        json.forEach(p => {
          p.date = new Date(p.date)
          p.point = p.position
        })
        const line = json.map(i => i.point)
        this.props.publish(`map::layer.update`, {name: lineId, lines: [line,]})
        this.props.publish(`map::layer.update`, {name: pageId, points: json})
        const color = CardTornozeladosColetas.COLORS[id]
        const pages = [...this.state.pages, {pageId, color, date, time, collects: json}].sort((a,b) => a.pageId < b.pageId)
        this.setState({pages})
      },
      error: err => {
        this.setState({error: true, errorMessage: err.message})
        setTimeout(() => this.setState({error: false, errorMessage: null, loading: false}), 5000)
      },
      done: () => {
        this.setState({loading: false})
      },
    })
  }

  eyeToggleHandler = pageId => {
    const { pages } = this.state
    let eyeClosed = null
    pages.forEach(i => {
      if (i.pageId === pageId) {
        i.eyeClosed = eyeClosed = !i.eyeClosed
      }
    })
    const lineId = pageId.replace(`page`, `line`)
    this.props.publish(`map::layer.visibility`, {name: pageId, hidden: eyeClosed})
    this.props.publish(`map::layer.visibility`, {name: lineId, hidden: eyeClosed})
    this.setState({pages})
  }

  rowClickHandler = (pageId, event) => {
    const t = event.target
    if (t.nodeName !== `BUTTON` || t.className.indexOf(`btn-eye`) === -1) {
      this.props.publish(`map::layer.zoom`, {name: pageId, maxZoom: 18})
    }
  }

  changeInputHandler = (field, event) => {
    const text = formatDateTime(event.target.value)
    const dateField = field.replace(`Text`, ``)
    let datetime = this.state[dateField]
    try {
      datetime = text2datetime(text)
    } catch(e) {}
    this.setState({[field]: text, [dateField]: datetime})
  }

  searchHandler = () => {
    const { startDateTimeText, endDateTimeText } = this.state
    try {
      const start = text2datetime(startDateTimeText)
      const end = text2datetime(endDateTimeText)
      if (end.getTime() < start.getTime()) {
        throw new Error(`DATA DE INÍCIO MAIOR DO QUE A DE TÉRMINO`)
      }
      if (end.getTime() > Date.now()) {
        throw new Error(`DATA DE TÉRMINO NÃO PODE SER FUTURO`)
      }
      this.setState({loading: true, errorMessage: null}, this.downloadCollects)
    } catch(e) {
      this.setState({error: true, errorMessage: e.message})
      setTimeout(() => this.setState({error: false}), 2000)
      setTimeout(() => this.setState({errorMessage: null}), 3000)
    }
  }

  render() {
    if (this.props.hidden) {
      return null
    }
    return (
      <div className="CardTornozeladosColetas">
        <div className="card-title">
          COLETAS TORNOZELADO <span>{ this.props.code }</span>
        </div>
        <div className="container-form">
          { this.renderFields() }
        </div>
        { this.renderStatus() }
        { this.renderTable() }
      </div>
    )
  }

  renderStatus() {
    const { errorMessage, loading } = this.state
    const hidden = !errorMessage && !loading
    let message = `-`
    if (errorMessage) {
      message = errorMessage
    } else if (loading) {
      message = `CARREGANDO HISTÓRICO DE COLETAS`
    }
    return (
      <div className={ `container-status` + (hidden ? ` hidden`:``) + (errorMessage ? ` error`:``) + (loading ? ` loading`:``) }>
        { message }
      </div>
    )
  }

  renderFields() {
    const { error, circle } = this.state
    const fields = [
      {id: `startDateTimeText`, label: `DATA/HORA INÍCIO`,  placeholder: `dd/mm/aaaa hh:mm`},
      {id: `endDateTimeText`,   label: `DATA/HORA TÊRMINO`, placeholder: `dd/mm/aaaa hh:mm`},
    ]
    return (
      <div className={`container-fields fields` + (error ? ` shake` : ``) + (circle ? `` : ` no-edit`)}>
        {fields.map(i => (
          <div className="field" key={ i.id }>
            <label>{ i.label }</label>
            <input type="text" value={ this.state[i.id] } onChange={ this.changeInputHandler.bind(this, i.id) }
              placeholder={ i.placeholder }
              autoComplete="disabled" autoCorrect="disabled"
            />
          </div>
        ))}
        <React.Fragment>
          <div className="field action">
            <button onClick={ this.searchHandler }>BUSCAR</button>
          </div>
        </React.Fragment>
      </div>
    )
  }

  renderTable() {
    const { pages } = this.state
    return (
      <div className="container-table">
        {pages.map(i => (
          <div className={ `row` + (i.eyeClosed ? ` gray`:``) } key={ i.pageId } onClick={ i.eyeClosed ? null : this.rowClickHandler.bind(this, i.pageId) }>
            <div className="col eye">
              <button className={ `btn-eye eye` + (i.eyeClosed ? ` closed` : ``) } onClick={ this.eyeToggleHandler.bind(this, i.pageId) } />
            </div>
            <div className="col color">
              <div className="icon" style={{background: i.color}} />
            </div>
            <div className="col date">{ i.date }</div>
            <div className="col time">{ i.time }</div>
            <div className="col total">
              { i.collects.length }<span>COLETAS</span>
            </div>
          </div>
        ))}
      </div>
    )
  }

}
