import { createApi } from './util'
import config from '../../config'

const { gatewayApp, gatewayIndicators } = config

const staticFilesOrigin = window.location.origin + process.env.PUBLIC_URL;

export const staticFiles = createApi({baseURL: staticFilesOrigin})
export const api = createApi({baseURL: gatewayApp})
export const indicators = createApi({baseURL: gatewayIndicators})
export const logger = createApi({baseURL: gatewayApp, silently: true})
