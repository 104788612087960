import React, { Component } from 'react'
import { List } from 'react-virtualized'
import util from '../../../../helpers/util'
import { publish } from '../../../../services/EventBus'
import './index.scss'

export class CardSispenVisitasRecebidas extends Component {

  state = {
    visits: [],
    loading: true,
    open: false,
    closing: false
  }

  componentDidMount() {
    const { id, name, data } = this.props
    this.props.setTitle(`SISPEN Visitas de ` + name)
    if(data && (data.sispenApenadoVisits || []).filter(i => i.apenadoId === id).length){
      this.setState({visits: data.sispenApenadoVisits, loading: false}, this.props.show)
    } else {
      this.props.http(`/sispen/details/${id}/visits`).then(data => {
        const { error } =  data
        if (error) {
          publish(`card::set-identifiers`, { hash: this.props.hash, data: {sispenApenadoVisits: {error}} })
          this.setState({
            message: error.message,
            loading: false,
          }, this.props.show)
          return;
        }
  
        const visits = data.map(i => {
          i.apenadoId = id
          i.id = i.visitorID
          i.sex = this.formatSex(i.sex)
          i.birthDate = i.birthDate ? util.str2date(i.birthDate) : null
          i.linkDate  = i.linkDate  ? util.str2date(i.linkDate)  : null
          return i
        })
        visits.sort((a,b) => a.linkDate > b.linkDate ? -1 : +1)
        publish(`card::set-identifiers`, { hash: this.props.hash, data: {sispenApenadoVisits: visits} })
        this.setState({visits, loading: false}, this.props.show)
      })
    }
  }

  formatSex = text => {
    if (text === `M`) return `MASCULINO`
    if (text === `F`) return `FEMININO`
    if (text === `I`) return `INDEFINIDO`
    return typeof text === `string` && text.length ? text : `-`
  }

  clickRowHandler = row => {
    const { rg, cpf } = row
    if ((rg || cpf) && this.props.roles.includes(`CEREBRUM_BUSCA_CIVIL`)) {
      this.props.openCard(`CardCivilHistorico`, {rg, cpf}, {}, true) 
    }
  }

  buildContent = (content) => {
    return (
      <div className="CardSispenVisitasRecebidas">
        <button className="accordion" onClick={this.accordionClick}>
          <div className="accordion_title">SISPEN
            <span className='accordion_base'>VISITAS RECEBIDAS</span>
            <span className={`dropdown_icon${!this.state.open ? ` closed` : ``}`}></span>
          </div>
        </button>
        <div className={`accordion_content${this.state.closing ? ` closing`: ``} ${this.state.open ? ` open` : ` closed`}`}>
          {
            this.state.open
            ? <>
                { content }
              </>
            : null
          }
        </div>
      </div>
    )
  }

  accordionClick = () => {
    this.setState({closing: true})
    setTimeout(() => {
      this.setState({open: !this.state.open, closing: false})
    }, 100)
  }

  render() {
    const { loading } = this.state
    if (this.props.hidden) {
      return null
    }

    if(loading){
      return this.buildContent(
        <div className="message-loading">
          CARREGANDO...
        </div>)
    }

    return this.buildContent(this.renderContent())
  }

  renderContent() {
    const { visits, message } = this.state
    if (visits.length === 0) {
      return <div className="message empty">{message ? message : `NENHUMA VISITA REGISTRADA`}</div>
    }
    return (
      <div className="container-list">
        <List width={ 672 } autoHeight height={ 380 } rowHeight={ 70 } rowCount={ visits.length } rowRenderer={ this.renderRow } />
      </div>
    )
  }

  renderRow = ({index, key, style}) => {
    const item = this.state.visits[index]
    const nasc = item.birthDate
    return (
      <div className="row" style={ style } key={ key } onClick={ this.clickRowHandler.bind(this, item) }>
        <div className="column a">
          <div className="date">{ util.date2str(item.linkDate) }</div>
        </div>
        <div className="column b">
          <div className="line">{ item.linkType } - { item.sex }</div>
          <div className="mainline">{ item.name }</div>
          <div className="labels">
            { nasc     ? <span key={ 1 }>{ util.date2str(nasc) } - { util.calcAge(nasc) } anos</span> : null }
            { item.rg ? <span key={ 2 }><b>RG</b>{ item.rg }</span> : null }
            { item.cpf ? <span key={ 3 }><b>CPF</b>{ util.formatCPF(item.cpf) }</span> : null }
          </div>
        </div>
      </div>
    )
  }

}
