import React, { useContext } from "react";
import { QuestionsContext } from "../../../../contexts/questions";

import "./index.scss";

const SideMenuSlider = ({ value, onChange, itemsAmount }) => {
  const questions = useContext(QuestionsContext);

  return (
    <div className="slider">
      <input
        type="range"
        min="1"
        style={{
          "width": `${itemsAmount * 8}rem`,
          "marginTop": `${itemsAmount * 8}rem`,
        }}
        max={questions.length}
        value={value}
        onChange={onChange}
        onInput={onChange}
      />
    </div>
  );
};

export default SideMenuSlider;
