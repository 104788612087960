import React, { Component } from 'react'
import classnames from 'classnames'
import MapCircleSelection from '../../../../UI/MapCircleSelection/'
import util from '../../../../../helpers/util'
import { List } from '../../../../UI/List'
import { SearchFilterWords } from '../../../../UI/SearchFilterWords'
import { getFeatureCoords } from '../../../../Map/Controller'
import './index.scss'

const MAX_RADIUS = 100000 // 100km

export class CardFactionsEventsSearch extends Component {

  state = {
    initialized: false,
    errorMessage: null,
    data: null,
    selected: false,
    searchWords: []
  }

  componentDidMount() {
    const group = {color: `#FEACB1`, title: `Facções`}
    this.props.publish(`map::layer.add`, {
      name: FactionsEventsPointsLayer, 
      points: [],
      group,
      color: `#FEACB1`,
      title: `Facções Eventos`,
      popup: this.createPopup, 
      // click: this.clickPopup
    })
    this.props.publish(`map::layer.add`, {
      name: FactionsEventsPolygonsLayer, 
      polygons: [],
      group,
      color: `#ffa500`, 
      title: `Facções Áreas`,
      popup: this.createPopup, 
      // click: this.clickPopup 
    })
    this.props.show()
    this.props.setTitle(`ATIVIDADES DE FACÇÕES ` + this.timeTitle)
  }

  render() {
    return (
      <div className="CardFactionsEventsSearch">
        { this.renderTitle() }
        { this.renderAreaInfo() }
        { this.renderCardContent() }
      </div>
    )
  }

  renderTitle() {
    const prefix = `ATIVIDADE DE FACÇÕES`
    return (
      <div className="card-title category">
        { prefix }<span>{ this.timeTitle }</span>
      </div>
    )
  }

  renderAreaInfo() {
    const { initialized, errorMessage } = this.state
    const message = initialized ? null : (
      <p className="empty">
        AGUARDANDO DEFINIÇÃO DE ÁREA NO MAPA...
      </p>
    );
    return (
      <div className="container">
        <MapCircleSelection 
          maxRadius={ MAX_RADIUS }
          onChange={ this.mapSelectionHandler } />
        { errorMessage ? <p className="empty">{ errorMessage }</p> : message }
      </div>
    )
  }

  renderCardContent() {
    const { data, selected } = this.state
    
    if (data === null) return null
    
    if (!(data.events || []).length) {
      return (
        <p className="empty white">
          NENHUMA ATIVIDADE ENCONTRADO
        </p>
      )
    }

    const { events } = this
    const total = data.events.length
    const subTotal = events.length
    
    return (
      <div className={ classnames(`events`, { 'seleceted': selected }) }>

        <SearchFilterWords 
          placeholder="Filtro..." 
          subtotal={ subTotal } 
          total={ total } 
          onChange={ this.handleSearchChange } />

        { this.renderList(events) }

      </div>
    )

  }

  renderList(events) { // avoid recomputaion
    events = events || this.events
    return (
      <List 
        card={ this } 
        rows={ events } 
        width={ 720 } 
        highlightMarker={ true } 
        zoomOnClick={ 14 }
        onClickRow={ this.handleRowClick }
        renderHeader={ () => (
          <React.Fragment>
            <div className="column type">Tipo</div>
            <div className="column date">Data</div>
            <div className="column assignment">Atribuição</div>
          </React.Fragment>
        )}
        renderRow={ e => {
          const type = this.renderEventType(e)
          const date = this.renderEventDate(e)
          const assignment = this.renderEventAssignment(e)
          return (
            <React.Fragment>
              <div title={type} className="column type">{ type }</div>
              <div title={date} className="column date">{ date }</div>
              <div title={assignment} className="column assignment">{ assignment }</div>
            </React.Fragment>
          )
        }}
      />
    )
  }

  renderEventType(event) {
    return (event.type || `NÃO TIPIFICADO`).toUpperCase()
  }

  renderEventDate({ date, createdDate }) {
    return date ? util.datetime2str(date) : `INSERIDO ${util.datetime2str(createdDate)}`
  }

  renderEventAssignment({ entity }) {
    const { entityMap } = this.state
    return entityMap(entity).title.toUpperCase()
  }

  renderEventsGeometries() {

    // clear
    this.props.publish(`map::layer.clear`, { name: FactionsEventsPointsLayer })
    this.props.publish(`map::layer.clear`, { name: FactionsEventsPolygonsLayer })

    const { events } = this
    const points = events.filter(e => e.geometry.type === `Point`).map(p => ({ ...p, point: p.geometry.coordinates }))
    const polygons = events.filter(e => e.geometry.type === `Polygon`)
    
    // renders
    this.props.publish(`map::layer.update`, { name: FactionsEventsPointsLayer, points })
    this.props.publish(`map::layer.update`, { name: FactionsEventsPolygonsLayer, polygons })
  }

  handleRowClick = event => {
    this.openDetails(event)
  }

  handleSearchChange = searchWords => {
    this.setState({ searchWords }, () => {
      this.renderEventsGeometries()
    })
  }

  createPopup = event => {
    return { 
      title: this.renderEventType(event),
      text: [
        this.renderEventAssignment(event),
        this.renderEventDate(event)
      ]
    }
  }

  // TODO Resolve click event propagation when polygon and marker overlay one above other.
  // clickPopup = event => {
  //   console.log(event, event.point)
  //   const { entityMap } = this.state
  //   event = { ...event, entity: entityMap(event.entity)  }
  //   this.props.openCard(`CardFactionsEventDetails`, { event })
  //   // focusing marker on it
  //   this.props.publish(`map::marker.add`, {id: `highlight`, point: event.point, className: `circle border animated`})
  // }

  openDetails = event => {
    const { entityMap } = this.state
    event = { ...event, entity: entityMap(event.entity)  }
    this.props.openCard(`CardFactionsEventDetails`, { event })
  }

  mapSelectionHandler = ({ center, radius }) => {
    
    const [ longitude, latitude ] = center
    const { description } = this.props

    this.props.setLoading()
    
    this.setState({ initialized: true })

    const params = { 
      ...this.timeQuery, 
      longitude, 
      latitude, 
      radius 
    }

    if (description) params.description = description
    
    this.props.http(`/factions/events`, params).then(result => {
  
      const entityMap = createEntityMap(result.entities)

      const events = result.events
        .map( e => { 
          const point = getFeatureCoords(e)
          return { point, ...e }
        })

      this.setState({ 
        data: { ...result, events },
        errorMessage: null,
        entityMap
      })

      this.renderEventsGeometries()

      this.props.show()

    }).catch(e => {

      this.setState({ errorMessage: `FALHA AO CONSULTAR DADOS` })
      
      this.props.show()
      
    })
  }

  get timeTitle() {
    const { time } = this.props
    const { type, value } = time || {}
    return TimeLabelRenders[type || 'undefined'](value)
  }

  get timeQuery() {
    const { time } = this.props
    const { type, value } = time || {}
    return TimeQueryRenders[type || 'undefined'](value)
  }

  get eventsCount() {
    return ((this.state.data || {}).events || []).length
  }

  get events() {
    const { searchWords, data } = this.state
    const { events } = data
    // no search
    if (!searchWords.length) return events
    // search
    const uppperSearchWords = searchWords.map(w => w.toUpperCase())
    function searchWordsContains(texts) {
      return uppperSearchWords.every(w => texts.some(text => text.includes(w)))
    }
    return events.filter(e => 
      searchWordsContains([
        this.renderEventType(e),
        this.renderEventDate(e),
        this.renderEventAssignment(e)
      ])
    )
  }

}


export const FactionsEventsPointsLayer = `eventos-faccoes-points`
export const FactionsEventsPolygonsLayer = `eventos-faccoes-polygons`


const TimeLabelRenders = {

  'date': ({ date }) => {
    return util.date2str(date)
  },

  'range': ({ startDateTime, endDateTime }) => {
    if (startDateTime && endDateTime) {
      return `${ util.datetime2str(startDateTime) } - ${ util.datetime2str(endDateTime) }`
    } else if (startDateTime) {
      return `A PARTIR DE ${ util.datetime2str(startDateTime) }`
    } else if (endDateTime) {
      return `ATÉ ${ util.datetime2str(endDateTime) }`
    } else {
      return ``
    }
  },

  'undefined': () => { }

}

const TimeQueryRenders = {

  'date': ({ date }) => {
    return { date: util.date2sql(date) }
  },

  'range': ({ startDateTime, endDateTime }) => {
    const q = { }
    if (startDateTime) q.startDateTime = util.datetime2iso(startDateTime)
    if (endDateTime) q.endDateTime = util.datetime2iso(endDateTime)
    return q
  },

  'undefined': v => v

}


function createEntityMap(entities) {

  const data = {}

  const addOfType = t => i => data[`${t}:${i.id}`] = i

  entities.factions
    .map(f => ({ ...f, title: `${ f.initials && f.initials.length ? `${f.initials} - ` : `` }${f.name}` }))
    .forEach(addOfType(`faction`))

  entities.involved
    .map(i => ({ ...i, title: `${ i.nicknames && i.nicknames.length ? `${ i.nicknames.join(`, `) } - ` : `` }${i.name}` }))
    .forEach(addOfType(`involved`))

  return ({ type, id }) => data[`${type}:${id}`] || { id, type, title: 'Responsável Não Identificado' }
}