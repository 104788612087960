import React, { useEffect, useState, useRef } from 'react';
import avatarImg from '../../../images/avatar.png';
import ForceGraph2D from 'react-force-graph-2d';

const MAIN_VAL = 32;
const NODE_VAL = 20;
const MAIN_COLOR = '#ff0000';
const AFFILIATIONS1_COLOR = '#6497b1';
const AFFILIATIONS2_COLOR = '#00A36C';
const VISITOR_COLOR = '#AF8826';

function createRoundImage(image, width, height) {
	const img = new Image();
	img.src = image;

	var canvas = document.createElement('canvas');
	var context = canvas.getContext('2d');

	canvas.width = width;
	canvas.height = height;
	context.drawImage(img, 0, 0, width, height);
	context.globalCompositeOperation = 'destination-in';
	context.beginPath();
	context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
	context.fill();
	return canvas.toDataURL();
}

export default function GraphMamager({ data, handleNodeClick }) {

	const [graphData, setGraphData] = useState({ nodes: [], links: [] });
	const [nodeHover, setNodeHover] = useState(null);
	const fgRef = useRef();

	function setNodeLegends(node, ctx) {
		const canvasSize = MAIN_VAL * 2;
		const lineHeight = 6;
		let y_interator = node.y + (canvasSize / 3);

		const label1 = node.name;
		const label2 = node.rg ? `RG: ${node.rg}` : null;
		const label3 = node.cpf ? `CPF: ${node.cpf}` : null;

		ctx.font = `6px Roboto`;
		ctx.textAlign = 'center';
		ctx.textBaseline = 'middle';

		var words = label1.split(' ');
		var line = '';
		let textHeight = 0;

		let linesToInsert = [];

		for (let n = 0; n < words.length; n++) {
			let testLine = line + words[n] + ' ';
			var metrics = ctx.measureText(testLine);
			var testWidth = metrics.width;
			if (testWidth > canvasSize && n > 0) {
				linesToInsert.push({ coord: y_interator, line });
				line = words[n] + ' ';
				y_interator += lineHeight;
				textHeight += lineHeight;
			}
			else {
				line = testLine;
			}
		}

		linesToInsert.push({ coord: y_interator, line });
		textHeight += lineHeight;

		if (label2) {
			linesToInsert.push({ coord: y_interator + lineHeight + 1, line: label2 });
			textHeight += lineHeight;

			if (!label3) textHeight += 6;
		}
		if (label3) {
			linesToInsert.push({ coord: y_interator + (2 * (lineHeight)) + 1, line: label3 });
			textHeight += (2 * lineHeight);
			if (!label2) textHeight += 6;
		}

		if (!label2 && !label3) {
			textHeight += 4;
		}

		ctx.fillStyle = '#3B4046'
		ctx.fillRect(node.x - (canvasSize / 2) - 2, node.y + (canvasSize / 3) - 6, canvasSize + 2, textHeight);
		ctx.strokeStyle = node.color;
		ctx.strokeRect(node.x - (canvasSize / 2) - 2, node.y + (canvasSize / 3) - 6, canvasSize + 2, textHeight);

		ctx.fillStyle = '#fff';
		linesToInsert.forEach(item => {
			ctx.fillText(item.line, node.x, item.coord);
		});
	};

	function setNodesLabels(node, ctx) {

		const imgSize = node.main ? MAIN_VAL : NODE_VAL;

		ctx.beginPath();
		ctx.arc(node.x, node.y, imgSize * 1.1, 0, 2 * Math.PI, false);
		ctx.fillStyle = node.color;
		ctx.fill();

		const img = new Image();
		const imgUrl = node.photo ? `data:image/png;base64, ${node.photo}` : avatarImg;
		img.src = createRoundImage(imgUrl, imgSize * 4, imgSize * 4);

		ctx.drawImage(
			img,
			node.x - (imgSize),
			node.y - (imgSize),
			imgSize * 2, imgSize * 2
		);

		if (node.id === nodeHover) setNodeLegends(node, ctx);
	};

	function setLinksLabels(link, ctx, globalScale) {
		const start = link.source;
		const end = link.target;

		if (typeof start !== 'object' || typeof end !== 'object') return;

		const textPos = Object.assign(...['x', 'y'].map(c => ({
			[c]: start[c] + (end[c] - start[c]) / 2 // calc middle point
		})));

		const label = link.name || ''

		ctx.font = '1px Roboto';
		const fontSize = 13 / globalScale;
		ctx.font = `${fontSize}px Roboto`;
		const textWidth = ctx.measureText(label).width;
		const bckgDimensions = [textWidth, fontSize].map(n => n + fontSize * 0.2); // some padding

		ctx.save();
		ctx.translate(textPos.x, textPos.y);

		ctx.fillStyle = 'transparent';
		ctx.fillRect(- bckgDimensions[0] / 2, - bckgDimensions[1] / 2, ...bckgDimensions);

		ctx.textAlign = 'center';
		ctx.textBaseline = 'middle';
		ctx.fillStyle = 'white';
		ctx.fillText(label, 0, 0);
		ctx.restore();
	};

	function checkNode(node, checkData) {
		const finded = checkData.find(item =>
			(item.name && item.name === node.name) ||
			(item.cpf && item.cpf === node.cpf) ||
			(item.rg && item.rg === node.rg));

		if (!finded) return genNodeId(node);
		else return finded.id;
	};

	function genNodeId(node) {
		return `${node.name}-${node.cpf || '_'}-${node.rg}`;
	}

	function buildGraph(newData) {
		try {
			let nodes = [];
			let links = [];
			let newNodes = [];
			let newLinks = [];

			newData.forEach((graph, index) => {
				const { person, affiliations, possibleAffiliations, visitorsSispen } = graph;
				let linkingNodes = [];

				let personNode = {
					name: person.name,
					cpf: person.cpf,
					rg: person.rg,
					main: true,
					val: MAIN_VAL,
					color: MAIN_COLOR,
					fx: index * MAIN_VAL * 6,
					photo: person.photo,
				}

				const targetId = checkNode(personNode, newNodes);
				personNode.id = targetId;
				nodes.push(personNode);
				linkingNodes.push(personNode);

				let mNode = {
					name: affiliations.mother,
					mother: true,
					val: NODE_VAL,
					color: AFFILIATIONS1_COLOR
				};
				const mId = checkNode(mNode, newNodes);
				mNode.id = mId;
				nodes.push(mNode);
				linkingNodes.push(mNode);

				let fNode = {
					name: affiliations.father,
					father: true,
					val: NODE_VAL,
					color: AFFILIATIONS1_COLOR
				};
				const fId = checkNode(fNode, newNodes);
				fNode.id = fId;
				nodes.push(fNode);
				linkingNodes.push(fNode);

				possibleAffiliations.forEach((item) => {
					let finded = nodes.findIndex(node =>
						(node.name && node.name === item.name) ||
						(node.rg && node.rg === item.rg) ||
						(node.cpf && node.cpf === item.cpf));
					if (finded === -1) {
						let bNode = {
							name: item.name,
							cpf: item.cpf,
							rg: item.rg,
							brother: true,
							val: NODE_VAL,
							color: AFFILIATIONS2_COLOR,
							photo: item.photo,
						};
						const bId = checkNode(bNode, newNodes);
						bNode.id = bId;
						nodes.push(bNode);
						linkingNodes.push(bNode);
					} else {
						let toLink = { ...nodes[finded], brother: true, val: NODE_VAL, };
						toLink.mother = false;
						toLink.father = false;
						linkingNodes.push(toLink);
					}
				});

				visitorsSispen.forEach((item) => {
					let finded = nodes.findIndex(node =>
						(node.name && node.name === item.name) ||
						(node.rg && node.rg === item.rg) ||
						(node.cpf && node.cpf === item.cpf));
					if (finded !== -1) {
						const toLink = { ...nodes[finded], visitor: true, color: VISITOR_COLOR };
						toLink.mother = false;
						toLink.father = false;
						toLink.brother = false;
						linkingNodes.push(toLink);
					} else {
						let vNode = {
							name: item.name,
							cpf: item.cpf,
							rg: item.rg,
							val: NODE_VAL,
							color: VISITOR_COLOR,
							photo: item.photo,
						};
						const vId = checkNode(vNode, newNodes);
						vNode.id = vId;
						nodes.push(vNode);
						linkingNodes.push(vNode);
					}
				});

				linkingNodes.forEach((node, indexLink) => {
					if (indexLink !== 0) {
						const newLink = {
							source: node.id,
							target: targetId,
							color: '#888888',
							name: node.mother ? 'Mãe' : node.father ? 'Pai' : node.brother ? 'Irmão' :
								node.visitor ? 'Visitou no SISPEN' : '',
						};

						links.push(newLink);
					}
				});

				nodes.forEach(item => {
					const findedIndex = newNodes.findIndex(oldItem => oldItem.id === item.id);
					if (findedIndex === -1) newNodes.push(item);
					else newNodes[findedIndex] = { ...newNodes[findedIndex], ...item };
				});

				links.forEach(item => {
					const findedIndex = newLinks.findIndex(oldItem => oldItem.source === item.source && oldItem.target === item.target);
					if (findedIndex === -1) newLinks.push(item);
					else newLinks[findedIndex] = { ...newLinks[findedIndex], ...item };
				});
			});

			setGraphData({ nodes: newNodes, links: newLinks });

		} catch (error) {
			console.log('Falha na estrutura de exibição de grafo');
			console.log(error);
		}
	}

	function handleHoverNode(node) {
		const elementToChange = document.getElementsByTagName("body")[0];
		if (!node && nodeHover) {
			elementToChange.style.cursor = "auto";
			return setNodeHover(null);
		}
		elementToChange.style.cursor = "pointer";
		return setNodeHover(node.id);
	}

	useEffect(() => {
		buildGraph(data);

		const fg = fgRef.current;
		try {
			fg.d3Force('link').distance(100);
			fg.d3Force('center', null);
			fg.d3Force('charge')
				.strength(MAIN_VAL * -10)
				.distanceMin(MAIN_VAL * 2)
				.distanceMax(MAIN_VAL * 2);
		} catch (error) {
			console.log(error);
		}

	}, [data]);

	if (data.length === 0) return null;

	return <ForceGraph2D
		ref={fgRef}
		graphData={graphData}

		height={600}
		width={900}
		backgroundColor={'#3B4046'}

		zoomToFit={() => true}
		enableZoomInteraction={true}
		enablePanInteraction={true}
		enableNodeDrag={true}

		nodeCanvasObject={setNodesLabels}
		nodeCanvasObjectMode={() => 'replace'}
		nodeLabel={null}
		onNodeHover={handleHoverNode}
		onNodeClick={handleNodeClick}

		linkCanvasObject={setLinksLabels}
		linkCanvasObjectMode={() => 'after'}
		linkLabel={null}
		linkWidth={2}

		cooldownTicks={100}
		autoPauseRedraw={false}
		onEngineStop={() => fgRef.current.zoomToFit(400, 80)}
	/>
};