import mapboxgl from 'mapbox-gl'
import React, { Component } from 'react'
import { MapController } from './Controller'
import Loading from '../UI/Loading'
import config from '../../config'

import { publish } from '../../services/EventBus'
import './MarkerManager'

import 'mapbox-gl/dist/mapbox-gl.css'
import './style.scss'
import './style-map-marker.scss'
import './style-map-popup.scss'

// chave copiada de um exemplo mapbox usuário:jonahadkins
const ACCESS_TOKEN  = config.mapboxToken
const MAP_THEME     = config.mapboxTheme
const MAP_CENTER    = [-38.6, -3.8]
const MAP_INIT_ZOOM = 10.8

mapboxgl.accessToken = ACCESS_TOKEN


export class Map extends Component {

  state = {
    id: `map${Date.now()}`,
    visible: false,
    width: window.innerWidth,
    height: window.innerHeight,
  }

  dom = null    // instancia do componente no DOM
  map = null    // instancia para mapbox Map
  canvas = null // instancia do canvas gerado pelo mapbox
  marker = {}   // guarda cada instancia DOM dos markers plotados no mapa

  componentDidMount() {
    // registrando resize com `prioridade máxima`
    window.addEventListener('resize', this.resize, false)
    //
    this.dom = document.getElementById(this.state.id)
    this.map = new mapboxgl.Map({
      container: this.state.id,
      style: MAP_THEME,
      center: MAP_CENTER,
      zoom: MAP_INIT_ZOOM,
    })
    MapController.map = this.map
    this.canvas = this.dom.querySelector(`canvas.mapboxgl-canvas`)
    //
    this.map.dom = this.dom
    this.map.canvas = this.canvas
    this.map.drawerDIV = this.dom.querySelector(`div.map-draw-selection`)
    this.map.drawerSVG = this.dom.querySelector(`svg.map-draw-selection`)
    this.map.divCancel = this.dom.querySelector('div.map-draw-cancel')
    //
    var nav = new mapboxgl.NavigationControl({showCompass: false})
    this.map.addControl(nav, 'bottom-right')
    //
    this.map.on(`load`, this.mapLoaded)
    this.fixMarkerTransition()
    //
    this.map.dragRotate.disable()
    this.map.touchZoomRotate.disable()
    //
    publish(`map::instance`, this.map)
  }

  mapLoaded = () => {
    setTimeout(() => {
      this.setState({visible: true})
      publish(`map::ready`)
    }, 1000)
  }

  resize = () => {
    const width  = window.innerWidth
    const height = window.innerHeight
    const cstyle = this.canvas.style
    cstyle.width  = `${width}px`
    cstyle.height = `${height}px`
    cstyle.transition = `none`
    this.dom.style.transition = `none`
    this.setState({width, height})
  }

  /**
   * bug: se existir `transition` os markers ficam `flutuando` no mapa durante as animações `zoom/pan`
   * class `stopped` necessária para que os markers não acumulem transitions
   */
  fixMarkerTransition() {
    let timer = 0
    this.map.on(`move`, () => {
      if (timer === 0) {
        this.dom.classList.remove(`stopped`)
        timer = setTimeout(_ => {
          this.dom.classList.add(`stopped`)
          timer = 0
        }, 500)
      }
    })
  }

  render() {
    const loading = !this.state.visible
    const style = {width: this.state.width, height: this.state.height}
    const clss  = `Map` + (this.state.visible ? ` visible`:``) + (loading ? ` is-loading`:``)
    return (     
      <div className={ clss } id={ this.state.id } style={ style }>
        { loading && <Loading /> }        
        <svg className="map-draw-selection" width={ style.width } height={ style.height } />
        <div className="map-draw-selection" style={ style }></div>
        <div className="map-draw-cancel">
          <button className="btn-cancel"><span>CANCELAR</span></button>
        </div>    
      </div>
    )
  }

}
