import util from '../../../helpers/util'
import * as type from '../fieldTypes'

export const TornozeladosByName = {
  created: `2019-08-05`,
  context: `Tornozelados`,
  role: `CEREBRUM_MONITORADOS_DETALHES`,
  params: {
    name: {placeholder: `Nome`},
  },
  createRequest: params => {
    Object.entries(params).forEach(([key, value]) => {
      params[key] = value === undefined || value === `` ? undefined : value.trim()
    })
    if (util.countNames(params.name || ``) < 2)
      throw new Error(`É necessário detalhar mais o nome da pessoa. Por favor, tente novamente.`)
    //
    return {path: `/justice-monitor/monitored`, params}
  },
  createRow: json => {
    const { code, cpf, name, motherName } = json
    return {
      card: `CardTornozeladosDetalhe`,
      props: {id: code},
      image: `avatar.png`,
      title: name,
      lines: [
        [{label: `CPF`, value: cpf ? util.formatCPF(cpf) : ``}],
        [{label: `Mãe`, value: (motherName || ``).toUpperCase()}],
      ]
    }
  }
}

export const TornozeladosByCode = {
  created: `2019-08-06`,
  context: `Tornozelados`,
  role: `CEREBRUM_MONITORADOS_DETALHES`,
  params: {
    id: {label: `Código (Tornozelado)`, placeholder: `M00000`}
  },
  parser: ({id}) => {
    return {card: `CardTornozeladosDetalhe`, props: {id}}
  }
}

export const TornozeladosByCPF = {
  created: `2019-08-06`,
  context: `Tornozelados`,
  role: `CEREBRUM_MONITORADOS_DETALHES`,
  params: {
    cpf: {label: `CPF`, placeholder: `000.000.000-00`, type: type.cpf}
  },
  parser: ({cpf}) => {
    return {card: `CardTornozeladosDetalhe`, props: {cpf}}
  }
}
