import React, { Component } from 'react'
import srcImageAvatar from '../../../images/avatar.png'
import util from '../../../helpers/util'
import './index.scss'

// const base2title = {
//   civil: `CIVIL`,
//   criminal: `CRIMINAL`,
//   sispen: `SISPEN`,
//   visitors: `SISPEN VISITANTE`
// }

const converters = {
  civil: i => {
    const birthdate = i.birthday ? util.str2date(i.birthday) : ``
    const cpfText   = i.cpf ? util.formatCPF(i.cpf) : ``
    const rgText    = i.rg ? i.rg : ``
    const text = `` + i.orderNumber
    return {...i, text, birthdate, cpfText, rgText, thumb: i.picture}
  },
  criminal: i => {
    const birthdate = i.birthday ? util.str2date(i.birthday) : ``
    const cpfText   = i.cpf ? util.formatCPF(i.cpf) : ``
    const rgText    = [i.rg, i.rgEmitter, i.federalUnitRgEmitter].filter(_ => _).join(` `)
    const text = `` + i.regNumber
    return {...i, text, name: i.regName, birthdate, cpfText, rgText, thumb: i.picture}
  },
  sispen: i => {
    const birthdate = i.birthDate ? util.str2date(i.birthDate) : ``
    const cpfText   = i.cpf ? util.formatCPF(i.cpf) : ``
    const rgText    = [i.rg, i.rgProvider].filter(_ => _).join(` `)
    const text = `` + i.id
    return {...i, text, birthdate, cpfText, rgText, mother: i.motherName, father: i.fatherName}
  },
  visitors: i => {
    const birthdate = i.birthDate ? util.str2date(i.birthDate) : ``
    const cpfText   = i.cpf ? util.formatCPF(i.cpf) : ``
    const text = `` + i.id
    return {...i, text, birthdate, cpfText, rgText: i.rg}
  }
}

const fillPerson = (base, person, json) => {
  person.records.push({type: base, ...json})
  const fields = [`name`, `mother`, `father`, `rg`, `rgText`, `cpf`, `cpfText`, `birthdate`, `text`, `thumb`]
  fields.forEach(field => {
    const value = json[field]
    if (value && !person[field]) {
      person[field] = value
    }
  })
}

export default class BodyPeople extends Component {

  state = {
    rows: [],
    foundBase: false 
  }

  constructor(props) {
    super(props)
    this.state.rows = props.rows.map(i => this.extractPerson(i))
  }

  extractPerson = json => {
    const person = {name: ``, mother: ``, father: ``, rg: ``, cpf: ``, birthdate: ``, thumb: ``, records: []}
    Object.entries(json).forEach(([base, records]) => {
      const parser = converters[base]
      if (parser) {
        records.forEach(record => {
          fillPerson(base, person, parser(record))
        })
      }
    })
    const thumb = this.createImageSrc(person.thumb)
    person.hasThumb = !!person.thumb
    if (person.hasThumb) {
      person.defaultThumb = thumb
    }
    person.thumb = thumb
    return person
  }

  fullnameClickHandler = person => {
    person.records.some(record => {
      return this.clickHandler(record, person)
    })
  }

  clickHandler = (row, person) => {
    const { type } = row
    row.nameSearch = true
    let json = {}
    if (type === `civil`) {
      json = {card: `CardCivilHistorico`, props: row.cpf ? {cpf: row.cpf} : {rg: row.rg}}
    } else if (type === `criminal`) {
      json = {card: `CardCriminalHistorico`, props: row}
    } else if (type === `sispen`) {
      json = {card: `CardSispenDetalhe`, props: {id: row.id}}
    } else if (type === `visitors`) {
      json = {card: `CardSispenVisitante`, props: row.cpf ? {cpf: row.cpf} : {...row, thumbDefault: srcImageAvatar},}
    }

    this.props.onClick(json)

    if(json)
      return true

    return false
  }

  mouseEnterActionRow = (actionRow, personIndex) => {
    const rows = this.state.rows.map((row, index) => {
      return personIndex !== index ? row : {...row, thumb: this.createImageSrc(actionRow.thumb)}
    })
    this.setState({rows})
  }

  mouseLeavePersonRow = (personIndex) => {
    const rows = this.state.rows.map((row, index) => {
      return personIndex !== index ? row : {...row, thumb: row.defaultThumb}
    })
    this.setState({rows})
  }

  render() {
    const { rows } = this.state
    if (!rows) {
      return null
    }

    return (
      <div className="SearchPanel-BodyPeople">
        {rows.map((i, index) => (
          <div key={ index } className="row" onMouseLeave={ !i.hasThumb ? null : this.mouseLeavePersonRow.bind(this, index) }>
            { this.renderPerson(i, index) }
          </div>
        ))}
      </div>
    )
  }

  renderPerson(json, index) {
    const docs = [
      {label: `Idade`, value: json.birthdate ? util.calcAge(json.birthdate)  : ``},
      {label: `Nasc`,  value: json.birthdate ? util.date2str(json.birthdate) : ``},
      {label: `CPF`,   value: json.cpfText},
      {label: `RG`,    value: json.rgText},
    ]
    const filiation = [
      {label: `Mãe`, value: json.mother},
      {label: `Pai`, value: json.father},
    ]
    return (
      <React.Fragment>
        <div className="column image">
          <img src={ json.thumb } alt="" className={ json.hasThumb ? `` : `default` } />
        </div>
        <div className="column data">
          <div className="info">
            <div className="title fullname" onClick={ this.fullnameClickHandler.bind(this, json) }>{ json.name }</div>
            { this.renderInfoRow(docs) }
            { this.renderInfoRow(filiation) }
          </div>
          {/* <div className="options bases">
            {json.records.map((i, idx) => (
              <div className="action-row" key={ idx }
                onMouseEnter={ !json.hasThumb ? null : this.mouseEnterActionRow.bind(this, i, index) }
                onClick={ this.clickHandler.bind(this, i, json) }
                >
                <div className="text">
                  <div className="basename">{ base2title[i.type] }</div>
                  <div className="details">{ i.text }</div>
                </div>
                <div className="btn-done"/>
              </div>
            ))}
          </div> */}
        </div>
      </React.Fragment>
    )
  }

  renderInfoRow(items) {
    items = items.filter(i => i.value)
    if (items.length === 0) {
      return null
    }
    return (
      <div className="info-row">
        {items.map(i => (
          <React.Fragment key={ i.label }>
            <div className="label">{ i.label }</div>
            <div className="value">{ i.value }</div>
          </React.Fragment>
        ))}
      </div>
    )
  }

  createImageSrc(image) {
    if (!image) {
      return srcImageAvatar
    }
    if (!image || image === `` || image.match(/\.\w{3,4}$/)) {
      return image || `#`
    }
    return /^data/.test(image) ? image : (`data:image/png;base64,` + image)
  }

}
