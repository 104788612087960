import React from 'react'

import { ReactComponent as WarningIcon } from '../../images/warning-icon.svg';
import { ReactComponent as ConfirmIcon } from '../../images/confirm-icon.svg';

import { InputCpf } from './InputCpf';
import { ServiceApiLogin } from '../../services/api/login'

import './Inputs.scss'

export default class LoginInputs extends React.Component {

  state = {
    cpf: {},
    message: false,
    error: false,
    sending: false,
    hidden: true,
    forgotPassword: false
  }

  reportMessage = (message, onClose, error) => {
    const state = { message }
    if (error) {
      state.error = true
    }
    this.setState(state, () => {
      setTimeout(() => {
        this.setState({
          message: null,
          error: false,
        }, onClose);
      }, error ? 3000 : 5000);
    });
  };

  focusCpf = () => this.inputCpf.focus();
  focusPassword = () => this.inputPassowrd.focus();
  focusEmail = () => this.inputEmail.focus();

  submitHandler = (event) => {
    event.preventDefault();

    if(this.state.forgotPassword){
      if (!this.inputEmail.value) {
        this.reportMessage("E-mail obrigatório", this.focusEmail, true);
        return;
      }

      this.setState({sending: true})
      this.props.disableModal(true)

      ServiceApiLogin.doPasswordRecovery({
        "email": this.inputEmail.value
      }).then(response => {
        this.inputEmail.value = ""
        this.setState({sending: false})
        this.props.disableModal(false)
        this.reportMessage(response.message, null, false);
      }).catch(({error}) => {
        setTimeout(() => {
          this.setState({
            sending: false
          });
          this.props.disableModal(false)
          this.reportMessage(error.message, this.focusEmail, true);
        }, 2000);
      })

      return;
    }

    const { cpf } = this.state

    if (!cpf.value) {
      this.reportMessage("CPF obrigatório", this.focusCpf, true);
      return;
    }

    if(cpf.invalid){
      this.reportMessage("CPF inválido", this.focusCpf, true);
      return;
    }

    if (!this.inputPassowrd.value) {
      this.reportMessage("Senha obrigatória", this.focusPassword, true);
      return;
    }

    this.setState({sending: true})
    this.props.disableModal(true)
    
    ServiceApiLogin.doLogin({
      "cpf": cpf.value,
      "password": this.inputPassowrd.value,
      "type": "WEB"
    }).then(response => {
      setTimeout(() => {
        this.props.onLogin(response)
      }, 2000);
    }).catch(({error}) => {
      setTimeout(() => {
        this.setState({
          sending: false
        });
        this.props.disableModal(false)
        this.reportMessage(error.message, this.focusCpf, true);
      }, 2000);
    })
  }

  classNameSetup = (className) => {
    let classes = className
    if (this.state.sending) {
      classes += ` sending`
    }
    if(this.state.hidden) {
      classes += ` hidden`
    }
    if(this.state.message) {
      classes += ` message`
    }
    return classes
  }

  componentDidMount() {
    this.timeout = setTimeout(_ => {
      this.setState({ hidden: false })
    }, 1)
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  render() {
    return (
      <div className={this.classNameSetup(`LoginInputsContainer`)}>
        {this.renderContent()}
        {this.renderFeedback()}
      </div>
    )
  }

  renderFeedback() {
    const { message, error } = this.state;
    return (
      <div className="feedback">
        {message ? (
          <>
            <div className="message-icon">
              {error ? <WarningIcon /> : <ConfirmIcon />}
            </div>
            <div className="message-warning">{message}</div>
          </>
        ) : null}
      </div>
    );
  }

  closeCard = () => {
    const { onClose } = this.props
    this.setState({hidden: true})
    setTimeout(() => {
      onClose()
    }, 200)
  }

  handleCpfChange = (cpf) => {
    this.setState({cpf})
  }

  handleForgotPassword = () => {
    this.setState({forgotPassword: !this.state.forgotPassword})
  }

  contentMode = () => {
    if (this.state.forgotPassword) {
      return (
        <input
          autoComplete="off"
          ref={(r) => (this.inputEmail = r)}
          type="email"
          className="email-input"
          placeholder="E-mail"
        />
      );
    }

    return (
      <>
        <InputCpf
          ref={(r) => (this.inputCpf = r)}
          type="text"
          placeholder="CPF"
          autoComplete="off"
          onCpfChange={this.handleCpfChange}
          className="cpf-input"
        />
        <input
          autoComplete="off"
          ref={(r) => (this.inputPassowrd = r)}
          className="password-input"
          type="password"
          placeholder="Senha"
        />
      </>
    );
  };

  buttonMode = () => {
    if (this.state.forgotPassword) {
      return (
        <button className={this.classNameSetup(`acess-button`)}>Enviar</button>
      );
    }
    return (
      <button className={this.classNameSetup(`acess-button`)}>Acessar</button>
    );
  };

  renderContent() {
    const { message, sending, forgotPassword } = this.state

    return (
        <>
          <div className={`close-button${message ? ` hidden` : ``}`} onClick={!sending ? this.closeCard : null} />
          <div className={`content${message ? ` hidden` : ``}`}>
            <div className="title">CEREBRUM</div>
            {forgotPassword && <div className="sub-title">RECUPERAÇÃO DE SENHA</div>}
            <form className='auth-form' onSubmit={!sending ? this.submitHandler : null}>
              {this.contentMode()}
              <div className="remembered-password">
                {forgotPassword && <p>Lembrou a senha?</p>}
                <div className="forgot-password" onClick={this.handleForgotPassword}>
                  {forgotPassword ? "Entrar" : "Esqueceu a senha?"}
                </div>
              </div>
              {this.buttonMode()}
            </form>
          </div>
      </>
    )
  }

}
