
import * as type from '../fieldTypes'

export const CamerasTodas = {
  created: `2019-04-22`,
  context: `Câmeras`,
  role: `CEREBRUM_LISTA_CAMERAS`,
  title: `Consulta em Todas AIS`,
  description: `Vistualizar todas as câmeras de monitoramento`,
  parser: () => {
    return {card: `CardCameraLista`, props: {}}
  }
}

export const CamerasByRaio = {
  created: `2019-04-22`,
  context: `Câmeras`,
  role: `CEREBRUM_LISTA_CAMERAS`,
  title: `CONSULTA POR ÁREA`,
  description: `Vistualizar todas as câmeras de monitoramento existentes dentro de uma área no mapa`,
  parser: () => {
    return {card: `CardCameraLista`, props: {type: `circle`}}
  }
}

export const CamerasByAIS = {
  created: `2019-04-22`,
  context: `Câmeras`,
  role: `CEREBRUM_LISTA_CAMERAS`,
  description: `Listagem de cameras de monitoramento por AIS`,
  params: {
    ais: {label: `AIS`, placeholder: `Código`, type: type.number},
  },
  parser: ({ais}) => {
    return {card: `CardCameraLista`, props: {ais: parseInt(ais)}}
  }
}
