import React, { Component } from 'react';
import MenuBar from '../../Civil/Historico/MenuBar'
import util from '../../../../helpers/util'
import './index.scss'

const renameLabels = {
  "histórico civil": "civil",
  "registros policiais": "criminal",
} 

export class CardBuscaFonetica extends Component {

  state = {
    labels: [], // ex.: [`civil`, `criminal`, `sispen`],
    affiliations: false,
    current: 0,
    json: [],
    filter: ``,
    visibles: 0,
    items: []
  }

  constructor(props) {
    super(props)
    this.state.affiliations = props.name === undefined && (props.mother || props.father)
    this.state.labels = this.getServiceLabels(props.roles, this.state.affiliations)
  }

  getServiceLabels = (roles, affiliations) => {
    const labels = []
    if (roles.includes(`CEREBRUM_BUSCA_CIVIL_AFILIACOES`)) {
      labels.push(`histórico civil`)
    }
    if (roles.includes(`CEREBRUM_BUSCA_CRIMINAL_AFILIACOES`)) {
      labels.push(`registros policiais`)
    }
    if (roles.includes(`CEREBRUM_BUSCA_SISPEN`) && !affiliations) {
      labels.push(`sispen`)
    }
    return labels
  }

  download = () => {
    const { affiliations } = this.state
    const { name, mother, father } = this.props
    const requests = this.state.labels.map(type => {
      if (type === `sispen`) {
        return this.props.http(`/sispen/search`, {name})
      } else if (affiliations) {
        return this.props.http(`/${renameLabels[type]}/affiliations`, {mother, father})
      } else if (type === `histórico civil` && name) {
        return this.props.http(`/civil/search`, {name})
      } else if (type === `registros policiais` && name) {
        return this.props.http(`/criminal/search`, {name})
      }
      return Promise.reject()
    })
    return new Promise(resolve => {
      const results = requests.map(_ => -1)
      requests.forEach((request, index) => {
        request.then(json => {
          json.forEach(i => i.query = JSON.stringify({...i, picture: undefined, thumb: undefined}).toUpperCase())
          results[index] = json
          if (!results.includes(-1)) resolve(results)
        }).catch(_ => {
          results[index] = null
          if (!results.includes(-1)) resolve(results)
        })
      })
    })
  }

  componentDidMount() {
    this.props.disableHttpAutoClose()
    this.download().then(json => {
      const { name, mother, father } = this.props
      if (name) {
        this.props.setTitle(`BUSCA FONÉTICA - ` + name)
      } else {
        this.props.setTitle(`POSSÍVEIS FILIAÇÕES - ` + mother.trim().split(` `)[0] + (father ? ` & ${father.trim().split(` `)[0]}` : ``))
      }
      this.setState({json}, this.menuChange)
      this.props.show()
    })
  }

  sex2str = (sex) => {
    if (sex === `M`) return `MASCULINO`
    if (sex === `F`) return `FEMININO`
    if (sex === `I`) return `SEXO INDEFINIDO`
  }

  father2str = name => {
    return (name === `NI` || name === `ND` || name === `NC`) ? `` : name
  }

  menuChange = (index=0) => {
    const current = index 
    this.setState({current}, () => {
      this.applyFilter(this.state.filter)
    })
  }

  clickRowHandler = item => {
    const { id, cpf, rg, regNumber } = item
    switch (this.state.labels[this.state.current]) {
      case `histórico civil`  : return this.props.openMainCard(`CardCivilHistorico`, cpf ? {cpf} : {rg})
      case `registros policiais`: return this.props.openMainCard(`CardCriminalHistorico`, {regNumber})
      case `sispen`  : return this.props.openMainCard(`CardSispenDetalhe`, {id})
      default        : return null
    }
  }

  render() {
    if (this.props.hidden) {
      return null
    }
    if (this.state.affiliations) {
      const { roles }     = this.props
      const buscaCivil    = roles.includes(`CEREBRUM_BUSCA_CIVIL_AFILIACOES`)
      const buscaCriminal = roles.includes(`CEREBRUM_BUSCA_CRIMINAL_AFILIACOES`)
      if (!buscaCivil && !buscaCriminal) {
        return <div className="CardBuscaFonetica message">VOCÊ NÃO POSSUI PERMISSÃO BUSCA FILIAÇÕES</div>
      }
    }
    return (
      <div className="CardBuscaFonetica">
        { this.renderTitle() }
        { this.renderMenu() }
        { this.renderContent() }
      </div>
    )
  }

  renderTitle() {
    const { name, mother, father } = this.props
    const { affiliations } = this.state
    return (
      <div className={ `title` + (affiliations ? `` : ` nominal`) }>
        <div className="col a">{ affiliations ? `POSSÍVEIS FILIAÇÕES` : `BUSCA FONÉTICA` }</div>
        <div className="col b">
          { affiliations ?
            <React.Fragment>
              <div>{ mother || `-` }</div>
              <div>{ father || `-` }</div>
            </React.Fragment>
            :
            <div>{ name }</div>
          }
        </div>
      </div>
    )
  }

  renderMenu() {
    const { labels, current, json } = this.state
    const items = labels.map((type, index) => {
      const title = type.toUpperCase()
      const value = json[index]
      let text = `-`
      if (value !== null) {
        const n = value.length
        if (n === 0) text = `nenhum registro`
        else text = n + ` registro` + (n > 1 ? `s` : ``)
      }
      return {title, text}
    })
    return (
      <MenuBar items={ items } current={ current } onChange={ this.menuChange } className={'menuTabs'}>
        { this.renderFilterText() }
      </MenuBar>
    )
  }

  filterTextHandler = e => {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(this.applyFilter.bind(this, e.target.value), 200)
  }

  applyFilter = text => {
    const words = text.trim().toUpperCase().split(/\s+/)
    const base  = this.state.json[this.state.current] || []
    const total = base.length
    const visibleItems = base.filter(i => {
      return !this.testHidden(words, i.query)
    })
    const visibles = visibleItems.length
    const items = visibleItems
    this.setState({items, visibles, total, filter: text})
  }

  testHidden = (filterWords, viaturaQuery) => {
    if (filterWords.length === 0) {
      return false
    }
    return filterWords.filter(word => viaturaQuery.indexOf(word) === -1).length
  }

  renderFilterText() {
    const { visibles, total } = this.state
    return (
      <div className="input">
        <input placeholder="Filtro..." onChange={ this.filterTextHandler } />
        <div className="total-hidden">
          { visibles } / { total }
        </div>
      </div>
    )
  }

  renderContent() {
    const { json, labels, current, items } = this.state
    const selected = labels[current]
    if (json[current] === null) {
      return <p className="message warn">DADOS INDISPONÍVEIS NO MOMENTO</p>
    }
    if (items.length === 0) {
      return <p className="message">NENHUM ITEM ENCONTRADO</p>
    }
    return (
      <div className="content">
        { items.map((i, index) => this.renderContentRow(i, index, current, selected)) }
      </div>
    )
  }

  renderContentRow(item, index, current, selected) {
    let clss = `row`
    let body = null
    if (selected === `histórico civil`) {
      clss = clss + (!item.cpf && !item.rg ? ` disabled` : ``)
      body = this.renderCivilRow(item)
    }
    if (selected === `registros policiais`) {
      clss = clss + (!item.regNumber ? ` disabled` : ``)
      body = this.renderCriminalRow(item)
    }
    if (selected === `sispen`) {
      clss = clss + (!item.id ? ` disabled` : ``)
      body = this.renderSispenRow(item)
    }
    return (
      <div className={ clss } key={ current + `-` + index } onClick={ this.clickRowHandler.bind(this, item) }>
        { body }
      </div>
    )
  }

  renderImage = url => {
    if (!url) {
      return <img src="#" alt="" className="empty" />
    } else if (!/\.\w{2,}$/.test(url) && !/^data/.test(url)) {
      url = `data:image/png;base64,${url}`
      return <img src={ url } alt="" />
    }
  }

  renderCivilRow(item) {
    return (
      <React.Fragment>
        <div className="column photo">
          { this.renderImage(item.picture) }
        </div>
        <div className="column info">
          <div className="name">{ item.name }</div>
          { this.renderRowLine(`Mãe`,  item.mother) }
          { this.renderRowLine(`Pai`,  item.father, this.father2str) }
          { this.renderRowLine(`Nasc`, item.birthday, util.date2str) }
          { this.renderRowLine(`Sexo`, item.sex, this.sex2str) }
          { this.renderRowLine(`RG`,   item.rg) }
          { this.renderRowLine(`CPF`,  item.cpf, util.formatCPF) }
        </div>
      </React.Fragment>
    )  
  }

  renderCriminalRow(item) {
    return (
      <React.Fragment>
        <div className="column photo">
          { this.renderImage(item.picture) }
        </div>
        <div className="column info">
          <div className="name">{ item.regName }</div>
          { this.renderRowLine(`Mãe`,  item.mother) }
          { this.renderRowLine(`Pai`,  item.father, this.father2str) }
          { this.renderRowLine(`Nasc`, item.birthday, util.date2str) }
          { this.renderRowLine(`RG`,   item.rg ? `${item.rg} ${item.rgEmitter}` : ``) }
          { this.renderRowLine(`CPF`,  item.cpf, util.formatCPF) }
        </div>
      </React.Fragment>
    )  
  }

  renderSispenRow(item) {
    return (
      <React.Fragment>
        <div className="column photo">
          { this.renderImage(item.thumb) }
        </div>
        <div className="column info">
          <div className="name">{ item.name }</div>
          { this.renderRowLine(`Mãe`,  item.motherName) }
          { this.renderRowLine(`Pai`,  item.fatherName, this.father2str) }
          { this.renderRowLine(`Nasc`, item.birthDate === `` ? undefined : item.birthDate, util.date2str) }
          { this.renderRowLine(`RG`,   item.rg ? `${item.rg} ${item.rgProvider}` : ``) }
          { this.renderRowLine(`CPF`,  item.cpf, util.formatCPF) }
          { this.renderRowLine(`Regime`, item.regime + ` / ` + item.prisonUnit) }
        </div>
      </React.Fragment>
    )
  }

  renderRowLine = (label, value, func) => {
    value = `` + value
    value = value.replace(/undefined/g, ``).replace(/\s{1,}/g, ` `).trim()
    value = func && value ? func(value) : value
    if (!value) {
      return null
    }
    return <div className="line"><label>{ label }</label><b>{ value }</b></div>
  }

}
