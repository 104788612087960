
import util from '../helpers/util'

const { width, height } = window.screen
const screenResolution = [width, height].join(`x`)

export const notifyError = (message, stack, cardName=null, props=null) => {
  const json = {errorMessage: message, errorStack: stack, screenResolution}
  if (cardName) {
    Object.assign(json, {cardName, props: util.truncateJSON(props)})
  }
  console.error(json)
  return new Promise(resolve => {
    return resolve()
    // << api-logger >>.post(`/logs`, json).then(resolve).catch(resolve)
  })
}

