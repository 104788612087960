import React, { Component } from 'react'
import Row from './Row'
import TagList from './TagList'
import './index.scss'

export default class BodyOptions extends Component {

  state = {hidden: true}

  componentDidMount() {
    this.timer = setTimeout(this.show, 1)
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  show = () => this.setState({hidden: false})

  tagClickHandler = tag => {
    if (this.props.onTagClick) {
      this.props.onTagClick(tag)
    }
  }

  optionRowClickHandler = row => {
    if (this.props.onRowClick) {
      this.props.onRowClick(row)
    }
  }

  render() {
    return (
      <div className={ `SearchPanel-BodyOptions` + (this.state.hidden ? ` hidden`:``) }>
        { this.renderTagList() }
        { this.renderSuggestions() }
        { this.renderExamples() }
      </div>
    )
  }

  renderTagList() {
    const { text, tags=[], examples=[], tagsParentsVisible=false } = this.props
    if (tags.length === 0 || (tags.length && examples.length) || (tags.length === 0 && text !== ``))
      return null
    return (
      <div className="container">
        <label>Bases de Pesquisa</label>
        <div className="tags">
          <TagList tags={ tags } onClick={ this.tagClickHandler } parentsVisible={ tagsParentsVisible } />
        </div>
      </div>
    )
  }

  renderSuggestions() {
    const { filteredTags=[], options=[] } = this.props
    if (filteredTags.length === 0 || options.length === 0) {
      return null
    }
    const selected = filteredTags.slice(-1)[0]
    return (
      <div className="container">
        <label>OPÇÕES DE BUSCA</label>
        <div className="suggestions">
          {options.map((i, index) => (
            <Row key={ selected + `-` + index }
              tag={ selected }
              option={ i }
              onClick={ this.optionRowClickHandler }
            />
          ))}
        </div>
      </div>
    )
  }

  renderExamples() {
    const { text, filteredTags, examples, typeSelected } = this.props
    if (filteredTags.length !== 0 || examples.length === 0 || text === ``) {
      return null
    }
    return (
      <div className="container">
        <label>SUGESTÕES DE BUSCA</label>
        <div className="suggestions">
          {examples.map((i, index) => (
            <Row key={ text + `-` + index }
              option={ i }
              tags={ i.context.split(`.`) }
              typeSelected={ typeSelected }
              onClick={ this.optionRowClickHandler }
            />
          ))}
        </div>
      </div>
    )
  }

}
