import React, { Component } from 'react'
import util from '../../../../helpers/util'
import './index.scss'

export class CardSispenColetas extends Component {

  state = {
    maximized: false,
    collects: [],
    current: 0,
    tab: 0,
    open: false,
    closing: false
  }

  componentDidMount() {
    const collects = this.props.collects
    const current = collects.length - 1
    this.setState({collects, current}, () => {
      this.autoSelectTabByCurrentCollect()
      this.props.show()
      this.props.setTitle(`SISPEN COLETAS ` + this.props.person.name)
    })
  }

  autoSelectTabByCurrentCollect() {
    const collect = this.state.collects[this.state.current]
    let tab = 0
    if (collect.type.fingerprints.length) {
      tab = `D1` // primeiro dedo mão direita (polegar)
    } else {
      Object.entries(collect.type).forEach(tuple => {
        const [ type, items ] = tuple
        if (items.length && typeof tab === `number`) {
          tab = type
        }
      })
    }
    this.setState({tab})
  }

  clickFingerHandler = finger => {
    this.setState({tab: finger.hand + finger.id, finger})
  }

  clickTabHandler = tab => {
    this.setState({tab: tab.type})
  }

  timelineClickHandler = index => {
    this.setState({current: index})
  }

  toggleMaximizedHandler = () => {
    const maximized = !this.state.maximized
    this.setState({maximized})
  }

  // getTitleText() {
  //   const { id } = this.props
  //   const { collects } = this.state
  //   const title = `COLETAS ${id}`
  //   if (collects.length !== 1)
  //     return title
  //   return `${title} em ` + util.date2str(collects[0].date)
  // }

  accordionClick = () => {
    this.setState({closing: true})
    setTimeout(() => {
      this.setState({open: !this.state.open, closing: false})
    }, 100)
  }

  render() {
    const { maximized, collects, tab } = this.state

    if (this.props.hidden || collects.length === 0) {
      return null
    }
    return (
      <div className={ `CardSispenColetas` + (collects.length > 8 ? ` many` : ``)}>
        <button className="accordion" onClick={this.accordionClick}>
          <div className="accordion_title">SISPEN
            <span className='accordion_base'>COLETAS</span>
            <span className={`dropdown_icon${!this.state.open ? ` closed` : ``}`}></span>
          </div>
        </button>
        <div className={`accordion_content r_vertical_margin${this.state.closing ? ` closing`: ``} ${this.state.open ? ` open` : ` closed`}`}>
          {
            this.state.open
            ? <>
                { collects.length !== 1 ? this.renderTimeline() : null }
                { this.renderTabs() }
                <div className={ `coletas-content` + (maximized ? ` maximized` : ``) }>
                  { [`E`,`D`].includes(tab[0]) ? this.renderContentFingerprint() : this.renderContentTab() }
                </div>
              </>
            : null
          }
        </div>
      </div>
    )
  }

  renderTimeline() {
    const { collects, current } = this.state
    const items = collects.map((c, index) => {
      return {
        id: c.id,
        date: c.date,
        selected: index === current
      }
    })
    return (
      <div className="timeline">
        <div className="chart">
          {items.map((c, index) => (
            <React.Fragment key={ index }>
              <div className={ `landmark` + (c.selected ? ` selected` : ``) } onClick={ this.timelineClickHandler.bind(this, index) }>
                <label className="top">{ util.date2str(c.date) }</label>
                <button></button>
              </div>
              <div className="space" style={{ display: index === items.length - 1 ? `none` : `inline-block` }}></div>
            </React.Fragment>
          ))}
        </div>
      </div>
    )
  }

  renderTabs() {
    const { current, tab } = this.state
    const type = this.state.collects[current].type
    const fingerprint = [
      {id: 6, hand: `E`, x:  10, y: 50, enabled: true},
      {id: 7, hand: `E`, x:  51, y: 11, enabled: true},
      {id: 8, hand: `E`, x:  69, y: 11, enabled: true},
      {id: 9, hand: `E`, x:  85, y: 20, enabled: true},
      {id:10, hand: `E`, x:  95, y: 37, enabled: true},
      {id: 1, hand: `D`, x: 216, y: 50, enabled: true},
      {id: 2, hand: `D`, x: 174, y: 11, enabled: true},
      {id: 3, hand: `D`, x: 157, y: 11, enabled: true},
      {id: 4, hand: `D`, x: 141, y: 20, enabled: true},
      {id: 5, hand: `D`, x: 131, y: 37, enabled: true},
    ].map(f => {
      f.selected = f.hand + f.id === tab
      f.enabled = type.fingerprints.length > 0
      return f
    })
    const tabs = [
      {id: 1, total: type.voices.length, type: `voices`, title: `Audios`},
      {id: 2, total: type.videos.length, type: `videos`, title: `Vídeos`},
      {id: 3, total: type.photos.length, type: `photos`, title: `Fotos`},
      {id: 4, total: type.tattoos.length, type: `tattoos`, title: `Tatuagens`},
      {id: 5, total: type.bodyMarks.length, type: `bodyMarks`, title: `Marcas Corporais`},
    ].map(t => {
      t.selected = t.type === tab
      return t
    })
    return (
      <div className="hands-container">
        <div className={ `hands` + ([`E`,`D`].includes(tab[0]) ? ` selected ${tab[0]}` : ``) }>
          <div className="picture-hands">
            <div className="label esquerda">E</div>
            <div className="label direita">D</div>
            { fingerprint.map(i => (
              <button className={ `finger` + (i.enabled ? `` : ` disabled`) + (i.selected ? ` selected` : ``) } key={ i.id } style={ {left: i.x, top: i.y} } onClick={ this.clickFingerHandler.bind(this, i) }>
              </button>
            ))}
          </div>
        </div>
        <div className="tab-buttons">
          { tabs.map(t => (
            <button key={ t.id } className={ `tab` + (t.selected ? ` selected` : ``) } onClick={ this.clickTabHandler.bind(this, t) }>
              <b>{ t.total }</b>{ t.title }
            </button>
          ))}
        </div>
      </div>
    )
  }

  renderContentFingerprint() {
    const { collects, current, tab } = this.state
    const id = parseInt(tab.slice(1), 10)
    const finger = collects[current].type.fingerprints.find(f => f.code === id)
    if (!finger) {
      return <p className="message empty">nenhum item encontrado</p>
    }
    return (
      <div className="fingerprint" key={ finger.code }>
        <label>{ finger.text }</label>
        <img src={ finger.path } alt="" onClick={ this.toggleMaximizedHandler } />
      </div>
    )
  }
  
  renderContentTab() {
    const { collects, current, tab } = this.state
    const items = collects[current].type[tab]
    if (!items || items.length === 0) {
      return <p className="message empty">nenhum item encontrado</p>
    }
    const methodName = `render` + tab[0].toUpperCase() + tab.slice(1)
    const func = this[methodName]
    return func ? func(items) : <p>Ops! Ainda não implementado...</p>
  }

  renderPhotos = items => {
    return (
      <div className="photos">
        { items.map(i => (
          <div className="photo" key={ i.id }>
            <img src={ i.path } alt="" onClick={ this.toggleMaximizedHandler } />
            <label>{ i.text }</label>
          </div>
        ))}
      </div>
    )
  }

  renderTattoos = items => this.renderPhotos(items)
  renderBodyMarks = items => this.renderPhotos(items)

  renderVoices = items => {
    return (
      <div className="voices">
        {items.map(i => (
          <div className="audio" key={ i.id }>
            <audio controls>
              <source src={ i.path }></source>
            </audio>
            <label>{ i.text }</label>
          </div>
        ))}
      </div>
    )
  }

  renderVideos = items => {
    return (
      <div className="videos">
        {items.map(i => (
          <div className="video" key={ i.id }>
            <div className="downloadbar">
              <div className="link">{ i.path.split(`?`)[0].split(`/`).slice(-1)[0] }</div>
              <a href={ i.path } target="_blank" rel="noopener noreferrer">DOWNLOAD</a>
            </div>
            <label>{ i.text }</label>
          </div>
        ))}
      </div>
    )
  }

}
