import React, { Component } from 'react'
import MenuBar from '../Historico/MenuBar'
import { normalizeAddress } from '../../../../helpers/addressModel'
import util from '../../../../helpers/util'
import Carteira from './Carteira'
import './index.scss'

export class CardCivilSindionibus extends Component {

  static ROLES = [
    `CEREBRUM_SINDIONIBUS`,
  ]

  state = {
    current: 0,
    items: [],
    name: ``,
  }

  componentDidMount() {
    const query = {cpf: this.props.cpf, rg: this.props.rg}
    this.props.http(`/civil/sindionibus`, query).then(carteiras => {
      if (this.props.isDuplicated(carteiras)) {
        return this.props.close()
      }
      const { name } = carteiras[0] || {name: this.props.cpf ? util.formatCPF(this.props.cpf) : this.props.rg}
      const items = carteiras.map(c => {
        return {title: c.type, text: util.date2str(c.registryDate)}
      })
      this.setState({carteiras, items, name}, () => {
        if (carteiras.length) {
          this.menuChange(0)
        }
      })
      this.props.show()
      this.props.setTitle(`BILHETE ÚNICO SINDIÔNIBUS - ` + name)
    })
  }

  render() {
    if (this.props.hidden) {
      return null
    }
    return (
      <div className="CardCivilSindionibus">
        <div className="card-title category">
          <label>BILHETE ÚNICO SINDIÔNIBUS</label>
          <div>{ this.state.name }</div>
        </div>
        { this.renderContent() }
      </div>
    )
  }

  renderContent() {
    const { items, carteiras, current } = this.state
    if (carteiras.length === 0) {
      return <p className="message">NENHUMA CARTEIRA ENCONTRADA</p>
    }
    const unique = carteiras.length === 1
    return (
      <React.Fragment>
        { !unique ? <MenuBar items={ items } current={ current } onChange={ this.menuChange } /> : null }
        <div className={ `content` + (unique ? ` single` : ``) }>
          {carteiras.map((i, index) => (
            <Carteira key={ index } hidden={ index !== current } unique={ unique } json={ i } />
          ))}
        </div>
      </React.Fragment>
    )
  }

  menuChange = (index) => {
    this.setState({current: index})
    this.geocodeFocus(this.state.carteiras[index])
  }

  geocodeFocus = json => {
    this.props.publish(`map::marker.remove`, {name: `geocode`})
    if (json && json.addressInfo && json.addressInfo.geocode) {
      const { geocode } = json.addressInfo
      const data = {
        id: json.birthDate ? util.date2str(json.birthDate) : json.rg,
        name: json.name,
        address: normalizeAddress(json.addressInfo).text,
        point: geocode.position,
        source: geocode.source,
      }
      const zoom = json.addressInfo.address ? 13 : 8
      this.props.publish(`map::zoom`, {point: data.point, zoom})
      setTimeout(() => {
        this.props.publish(`map::marker.add`, {name: `geocode`, point: data.point, data, color: `#3dca85`, className: `square animated`, popup: this.createPopup})
      }, 1)
    }
  }

  createPopup = json => {
    const { id, name, address, source } = json.data
    return {
      title: id + ` - ` + name,
      text: address,
      source,
    }
  }

}
    