import React, { Component } from 'react'
import util from '../../../../../helpers/util'
import './index.scss'
import ImageViewer from '../../../../ImageViewer'

export class CardFactionsEventDetails extends Component {

  state = {
    images: null, // null ~> loading
    imagesErrorMessage: null
  }

  render() {
    const { event } = this
    const hasEndDate = !!event.endDate
    return (
      <div className="CardFactionsEventDetails">
        <div className="card-title">ATIVIDADE DE FACÇÃO CRIMINOSA</div>
        <div className="content">
          { this.renderImages() }
          { this.renderEventInfo(`TIPO`, this.renderEventType()) }
          { this.renderEventInfo(`RESPONSÁVEL`, this.renderEventAssignment()) }
          { this.renderEventInfo(`DATA`, this.renderEventDate()) }
          { hasEndDate && this.renderEventInfo(`TÉRMINO`, this.renderEventEndDate()) }
          { this.renderEventInfo(`DESCRIÇÃO`, this.renderEventDescription()) }
        </div>
      </div>
    )
  }

  componentDidMount() {
    this.props.show()
    this.callGetImages() 
  }

  componentDidUpdate(prevProps) {
    if (this.event.id !== prevProps.event.id) this.callGetImages()
  }

  callGetImages() {
    const { event } = this
    this.setState({ images: null })
    this.props.http(`/factions/images/${event.id}`).then(result => {
      this.setState({ images: result })
    }).catch(e => {
      this.setState({ imagesErrorMessage: `FALHA AO CONSULTAR IMAGENS` })
    })
  }

  renderImages() {

    const { images, imagesErrorMessage } = this.state
    
    let content;
    
    // exceptions flow
    
    if (imagesErrorMessage) content = (
      <div className="text">
        { imagesErrorMessage }
      </div>
    )

    else if (images === null) content = (
      <div className="text">
        CARREGANDO IMAGENS...
      </div>
    )

    else if (!images.length) content = (
      <div className="text">
        SEM IMAGENS
      </div>
    )

    // happy-day: it has images
    
    else content = (
      <div className="photos">
        { images.map(this.renderImage) }
      </div>
    )

    return (
      <div className="container-text">
        <div className="caption">IMAGENS</div>
        { content }
      </div>
    )

  }

  renderEventInfo(title, text) {
    return (
      <div className="container-text">
        { title && <div className="caption">{ title }</div> }
        <div className="text">
          { text }
        </div>
      </div>
    )
  }
  
  renderEventType() {
    return (this.event.type || `NÃO TIPIFICADO`).toUpperCase()
  }

  renderEventDate() {
    const { date, createdDate } = this.event
    return date ? util.datetime2str(date) : `INSERIDO ${util.datetime2str(createdDate)}`
  }

  renderEventEndDate() {
    return util.datetime2str(this.event.endDate)
  }

  renderEventAssignment() {
    return this.event.entity.title.toUpperCase()
  }

  renderEventDescription() {
    return this.event.description || 'NÃO INFORMADA'
  }

  renderImage = image => {
    return (
      <div 
        key={ image.id } 
        className="photo" 
        onClick={ this.openImageViewerHandle(image) } >
        <img 
          src={ imageBase64(image) } 
          alt=""/>
        <label>{ (image.description || 'SEM DESCRIÇÃO').toUpperCase() }</label>
      </div>
    )
  }

  openImageViewerHandle = image => () => ImageViewer.open({
    imgProps: { src: imageBase64(image), alt: "" },
    label: (image.description || '').toUpperCase()
  })

  get event() {
    return this.props.event
  }
  
}

function imageBase64({ content, extension }) {
  return `data:${extension};base64,${content}`
}
