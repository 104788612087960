import React, { useEffect, useState, useCallback, useMemo } from 'react'
import util from '../../../../helpers/util'
import { List } from '../../../UI/List'
import './index.scss'

export function CardCadOccurrencesList({
  http,
  show,
  openCard,
  setTitle,
  publish,
  startDateTime,
  endDateTime
}) {

  const searchActiveOccurrences = !startDateTime && !endDateTime;

  const [ occurrences, setOccurrences ] = useState([]);
  const [ occurrenceFilter, setOccurrenceFilter ] = useState('');

  const searchMatches = useCallback(( ...words ) => {
    const filterWords = occurrenceFilter.toUpperCase().split(' ');
    return filterWords.every( filter => words.some( word => word.toUpperCase().includes(filter) ) );
  }, [ occurrenceFilter ])

  const filteredOccurrences = useMemo(() => occurrences.filter(occ => {
    const { code, type, address, createdAt } = occ;
    return searchMatches(code, type, address, util.datetime2str(createdAt));
  }), [ searchMatches, occurrences ]);

  function createPopup( item ) {
    return { title: item.type };
  }

  function onOccurrenceClick( occurrence ) {
    openCard('CardCadOccurrenceDetails', { code: occurrence.code });
  }

  function onRowFocus( item ) {
    publish(`map::layer.update`, {name: `current`, points: [item,]});
  }

  function onRowBlur() {
    publish(`map::layer.clear`, {name: `current`});
  }

  useEffect(() => {
    const url = searchActiveOccurrences ? '/cad/occurrences/active' : '/cad/occurrences';
    const params = { 
      startDateTime: startDateTime && util.datetime2iso(startDateTime), 
      endDateTime: endDateTime && util.datetime2iso(endDateTime),
    };

    const group = {color: `#A98AFF`, title: `CICCM - Ocorrências`}
    publish('map::layer.add', { name: `occurrences`, points: [], group, color: `#A98AFF`, opacity: 0.4, popup: createPopup, click: onOccurrenceClick });
    publish(`map::layer.add`, { name: `current`, points: [], group, color: `#C9BAFF`, scale: 1.3})

    http(url, params).then(res => {
      const data = res
        .map(o => ({ ...o, point: o.position }))
        .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      setOccurrences(data);
      show();
      setTitle(`Histórico de ocorrências`);
    });
  }, []);

  useEffect(() => {
    publish('map::layer.update', { name: 'occurrences', points: filteredOccurrences });
    publish(`map::layer.zoom`, {name: `occurrences`});
  }, [ filteredOccurrences ]);

  function handleFilterChange( e ) {
    setOccurrenceFilter( e.target.value );
  }

  function renderEmpty() {
    return (
      <p className="message no-data">
        NENHUMA OCORRÊNCIA ENCONTRADA
      </p>
    );
  }

  function renderRow( occurrence ) {
    const { code, address, createdAt, type } = occurrence;
    return (
      <div 
        key={ code } 
        className="table-row" 
        onClick={ () => onOccurrenceClick(occurrence) } 
        onMouseEnter={() => onRowFocus(occurrence)}
        onMouseLeave={() => onRowBlur(occurrence)}
      >
        <div className="col code">{ code }</div>
        <div className="col type">{ type }</div>
        <div className="col address">{ address }</div>
        <div className="col datetime">{ createdAt && util.datetime2str(createdAt) }</div>
      </div>
    )
  }

  function renderTable() {
    if (filteredOccurrences.length === 0) return renderEmpty();
    
    return (
      <div className="table-list">
        <div className="table-row header">
          <div className="col code">CÓDIGO</div>
          <div className="col type">TIPO</div>
          <div className="col address">LOCAL</div>
          <div className="col datetime">DATA</div>
        </div>
        <List width={ 820 } height={ 350 } rowHeight={ 32 } rows={ filteredOccurrences } renderRow={ renderRow } />
      </div>
    )
  }

  function renderSummary() {
    return (
      <div className="summary">
        <div className="input">
          <input placeholder="Filtro..." onChange={ handleFilterChange } />
          <div className="total-hidden">
            { `${filteredOccurrences.length} / ${occurrences.length}` }
          </div>
        </div>
      </div>
    )
  }

  function renderTitle() {
    const cardTitle = searchActiveOccurrences ? 'Ocorrências ativas' : 'Ocorrências';
    return (
      <div className="card-title">
        CICCM AIS 13
        <span>{ cardTitle }</span>
      </div>
    );
  }

  function renderSubTitle() {
    return (
      <div className="card-sub-title">
        { `${util.datetime2str(startDateTime)} - ${util.datetime2str(endDateTime)}` }
      </div>
    );
  }


  return (
    <div className="CardCadOccurrencesList">
      { renderTitle() }
      { !searchActiveOccurrences && renderSubTitle() }
      { renderSummary() }
      { renderTable() }
    </div>
  )

}
