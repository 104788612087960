
import * as type from '../fieldTypes'

export const CVPCitybyDate = {
  created: `2019-04-22`,
  context: `Indicadores.CVP`,
  role: `CEREBRUM_BUSCA_CVP`,
  params: {
    city: {label: `Cidade`, placeholder: `Nome`, type: type.text},
    date: {label: `Data`, placeholder: `dd/mm/aaaa`, type: type.date},
  },
  title: `CONSULTA POR CIDADE E DATA`,
  subtitle: `Visualizar todos os indicadores de CVP por cidade e data específica`,
  parser: (data) => {
    data.searchType = `City`
    data.type = `date`
    return {card: `CardCVPLista`, props: data}
  }
}

export const CVPCitybyAISAndRange = {
  created: `2019-04-22`,
  context: `Indicadores.CVP`,
  role: `CEREBRUM_BUSCA_CVP`,
  params: {
    city: {label: `Cidade`, placeholder: `Nome`, type: type.text},
    startDate: {label: `Data Inicial`, placeholder: `dd/mm/aaaa`, type: type.date},
    endDate: {label: `Data Final`, placeholder: `dd/mm/aaaa`, type: type.date, optional: true},
  },
  title: `CONSULTA POR CIDADE E INTERVALO ENTRE DATAS`,
  subtitle: `Visualizar todos os indicadores de CVP por cidade e intervalo entre datas`,
  parser: (data) => {
    data.searchType = `City`
    data.type = data.endDate ? `rangeDate` : `startDate`
    return {card: `CardCVPLista`, props: data}
  }
}

export const CVPCitybyAISAndMonthYear = {
  created: `2019-04-22`,
  context: `Indicadores.CVP`,
  role: `CEREBRUM_BUSCA_CVP`,
  params: {
    city: {label: `Cidade`, placeholder: `Nome`, type: type.text},
    year: {label: `Ano`, placeholder: `aaaa`, type: type.year},
    month: {label: `Mês`, placeholder: `mm`, type: type.month, optional: true},
  },
  title: `CONSULTA POR AIS EM ANO E MÊS`,
  subtitle: `Visualizar todos os indicadores de CVP por AIS em um ano e, de maneira opcional, em um mês específico`,
  parser: (data) => {
    data.searchType = `City`
    data.type = data.month ? `month-year` : `year`
    return {card: `CardCVPLista`, props: data}
  }
}
