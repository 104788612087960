
/**
 * gera estrutura básica para layers tipo `points`, `lines`, `polygons`
 * estrutura assumindo `source` como a fonte de dados para o geojson
 * @param {*} layer
 */
export const generateGeoJSON = (data) => {
  const { id, type, scale=1, gradient=false, stroke, color, opacity } = data
  //
  const layer = {id, source: id, paint: {}}
  //
  if (/^points?$/.test(type)) {
    layer.type = `circle`
    layer.paint = {
      'circle-color': normalizeColor(color || `#fff`, opacity || 0.7),
      'circle-stroke-width': 0,
      'circle-opacity': [
        `interpolate`,
        [`linear`],
        [`zoom`],
        3, 0, // zoom, opacity,
        10, 1,
      ],
      'circle-radius': [
        `interpolate`,
        [`linear`],
        [`zoom`],
        12,  5 * scale, // zoom, radius,
        17, 10 * scale
      ]
    }
  }
  //
  if (/^lines?$/.test(type)) {
    layer.type = `line`
    layer.paint = {
      'line-color': color || stroke || `#687277`,
      'line-opacity': data.opacity || 1,
      'line-width': data.width || 4,
    }
    if (gradient) {
      layer.paint[`line-gradient`] = [
        `interpolate`,
        [`linear`],
        [`line-progress`],
        0, normalizeColor(color || `#fff`, 0.0),
        1, normalizeColor(color || `#fff`, 0.5),
      ]
    }
  }
  //
  if (/^circles$/.test(type)) {
    layer.type = `fill`
    layer.paint = {
      'fill-color': color,
      'fill-opacity': opacity,
    }
    if (stroke || color) {
      layer.paint[`fill-outline-color`] = stroke || color
    }
  }
  //
  if (/^polygons?$/.test(type)) {
    layer.type = `fill`
    layer.paint = {
      'fill-color': color || `#ff0`,
      'fill-opacity': opacity || 0.07,
    }
    if (stroke || color) {
      layer.paint[`fill-outline-color`] = stroke || color
    }
  }
  //
  return layer
}

export const data2geojson = (layer) => {
  let features = []
  //
  if (/^points?$/.test(layer.type)) {
    features = layer.data.map(obj => {
      return {
        properties: obj,
        geometry: {
          type: `Point`,
          coordinates: obj.point || obj.lnglat,
        }
      }
    })
  }
  //
  if (/^lines?$/.test(layer.type)) {
    features = layer.data.map(lineCoords => {
      return {
        type: `Feature`,
        geometry: {
          type: `LineString`,
          coordinates: lineCoords
        }
      }
    })
  }
  //
  if (/^circles$/.test(layer.type)) {
    features = layer.data.map(circle => {
      return circle2feature(circle.center, circle.radius)
    })
  }
  //
  if (/^polygons?$/.test(layer.type)) {
    // if array if object
    const data = Array.isArray(layer.data) ? layer.data : [ layer.data ]
    features = data.map(f => {
      const { geometry, ...properties } = f
      if (geometry) {
        return { type: 'Feature', geometry, properties }
      } else {
        return { type:  'Feature', geometry: f }
      }
    })
  }
  //
  const data = {
    type: `geojson`,
    data: {
      type: `FeatureCollection`,
      features,
    }
  }
  if (layer.gradient) {
    data.lineMetrics = true
  }
  return data
}

function normalizeColor(color, opacity) {
  if (/^hsl/.test(color) || /^rgb/.test(color))
    return color
  if (color.length === 4)
    color = `#` + color[1] + color[1] + color[2] + color[2] + color[3] + color[3]
  var r = parseInt(color.slice(1,3), 16)
  var g = parseInt(color.slice(3,5), 16)
  var b = parseInt(color.slice(5,7), 16)
  color = `rgba(${r}, ${g}, ${b}, ${opacity})`
  return color
}

export const circle2coords = (center, radius, numPoints=9) => {
  const feature = circle2feature(center, radius, numPoints)
  return feature.geometry.coordinates
}

function circle2feature(center, radiusInMeters, numPoints=720) {
  var coords = {
    latitude: center[1],
    longitude: center[0]
  }
  var km = radiusInMeters / 1000
  var points = []
  var distanceX = km/(111.320*Math.cos(coords.latitude*Math.PI/180))
  var distanceY = km/110.574
  var theta, x, y
  for (var i = 0; i < numPoints; i++) {
      theta = (i/numPoints)*(2*Math.PI)
      x = distanceX*Math.cos(theta)
      y = distanceY*Math.sin(theta)
      points.push([coords.longitude+x, coords.latitude+y])
  }
  points.push(points[0])
  return {
    type: `Feature`,
    geometry: {
      type: `Polygon`,
      coordinates: [points,]
    }
  }
}
