
/**
* Recebe um JSON em formato `backend` e retorna a estrutura necessária para `frontend`
*/
export default class Model {

  constructor() {
    this.clear()
  }

  clear() {
    this.nodesByDeep = {}
    this.nodes = []
    this.deeps = []
    this.minDeep = 0
    this.maxDeep = 0
  }

  getDeeps() {
    return this.deeps
  }

  getRootNode() {
    return this.nodes[0]
  }

  getNodeById(id) {
    return Object.assign({}, this.nodes.find(_ => _.id === id))
  }

  getNodes() {
    return this.nodes
  }

  getNodesByDeep(deep) {
    if (deep < this.minDeep || deep > this.maxDeep) {
      throw new Error(`deep inválido!`);
    }
    return this.nodesByDeep[deep]
  }

  load(json) {
    json = JSON.parse(JSON.stringify(json)) // clone nodes
    var items = json
    this.clear()
    //
    // garantindo uma propriedade exclusiva para guardar informacoes de desenho
    items.filter(i => i.draw === undefined).map(i => i.draw = {})
    //
    // calc `deep` para cada `node`
    addDeepProperty(items, items[0])
    var maxDeep = items.reduce((a,b) => Math.max(a, b.draw.deep), 0)
    //
    // adicionando `filhos` para todos os `nodes` até `maxDeep`
    addChildrenInMaxDeep(items, maxDeep)
    //
    // calc `percent` para cada barra - considerando `weight` do pai
    addPercentProperty(items, maxDeep)
    //
    // add `fake` children - para preenchimento do grafico - efeito visual
    // addFakeNodes(items, maxDeep)
    //
    addColorProperty(items)
    //
    // id baseado pela hierarquia e sequencia em cada deep
    generateSortId(items, maxDeep)
    //
    // gera dados para os 5 meses anteriores
    addFakeTimeline(items)
    //
    this.clear()
    //
    this.nodes = items
    this.deeps = Array.from({length: maxDeep}, (i, index) => index + 1);
    this.nodesByDeep = {}
    this.deeps.forEach(deep => {
      this.nodesByDeep[deep] = this.nodes.filter(_ => _.draw.deep === deep)
    })
    this.minDeep = 1
    this.maxDeep = maxDeep
  }

  /**
   * método útil para destacar nodes filhos/pais de um determinado node
   */
  findSubTree(nodeId) {
    const output = []
    // pais
    const parentQueue = [nodeId]
    while (parentQueue.length) {
      const id   = parentQueue.shift()
      const node = this.findNodeById(this.nodes, id);
      if (node) {
        output.push(id)
        if (node.draw.parent) {
          parentQueue.push(node.draw.parent)
        }
      }
    }
    // filhos
    const childQueue  = [nodeId]
    while (childQueue.length) {
      const id   = childQueue.shift()
      const node = this.findNodeById(this.nodes, id);
      node.children.forEach(childId => childQueue.push(childId))
      output.push(id)
    }
    return output
  }

  findNodeById(nodes, id) {
    return nodes.find(_ => _.id === id);
  }
}

// gera dados para os 5 meses anteriores
function addFakeTimeline(items) {
  items.forEach(item => {
    var h = item.data.health
    item.draw.lastMonths = []
    for (var i = 0; i < 3; i++) {
      var diff = h * Math.random()*1
      item.draw.lastMonths.push(h + (Math.random() > 0.5 ? +diff : -diff))
    }
    item.draw.lastMonths.push(h)
    item.draw.lastMonths.push(h)
  })
}

// id baseado pela hierarquia e sequencia em cada deep
function generateSortId(items, maxDeep) {
  const sortedIds = [items.find(_ => _.draw.deep === 1).id,]
  //
  const queue = [items.find(_ => _.draw.deep === 1),]
  const LOG = 0
  while (queue.length > 0 && LOG <= 100) {
    queue.shift().children.forEach(childId => {
      //
      sortedIds.push(childId)
      //
      const elem = items.find(_ => _.id === childId)
      if (elem.draw.deep < maxDeep)
        queue.push(elem)
    })
  }
  //
  const array = sortedIds.map(id => items.find(_ => _.id === id))
  array.forEach((item, index) => {
    items[index] = item
  })
}

/**
 * helper para criar atributo `deep` em cada `node`
 */
function addDeepProperty(items, node, deep=1) {
  node.draw.deep = deep
  node.children.forEach(nodeId => {
    var child = items.find(_ => _.id === nodeId)
    if (!child)
      throw new Error(`node índice(${nodeId}) não encontrado!`)
    //
    child.draw.parent = node.id
    addDeepProperty(items, child, deep + 1)
  })
}

function addPercentProperty(items, maxDeep) {
  for (var deep = 1; deep <= maxDeep; deep++) {
    addPercentPropertyByDeep(items, deep)
  }
}

function addPercentPropertyByDeep(items, deep) {
  items.filter(_ => _.draw.deep === deep).forEach(node => {
    var parentPercent = 100.0//%
    if (node.draw.parent) {
      parentPercent = items.find(_ => _.id === node.draw.parent).draw.percent
    }
    node.draw.percent = node.data.weight * parentPercent
  })
}

/**
 * helper para definir a cor baseado na propriedade `health` de cada índice
 */
function addColorProperty(items) {
  items.forEach(i => {
    i.draw.color = i.draw.fake ? `rgba(255,255,255, 0.05)` : i.data.color
  })
}

/**
 * garantindo que qualquer nodes tenha um filho em `maxDeep`
 * ordenando inicialmente pelo peso/weight
 */
function addChildrenInMaxDeep(items, maxDeep, currentDeep=1) {
  if (currentDeep > maxDeep)
    return
  //
  // garantindo sempre o iniciar com itens de menor peso
  if (currentDeep === 1) {
    items.sort((a,b) => a.data.weight - b.data.weight)
  }
  //
  var id = null
  var genId      = Date.now()
  var extraNodes = []
  items.filter(_ => _.draw.deep === currentDeep).forEach(node => {
    var children = items.filter(_ => node.children.includes(_.id))
    var sum      = children.reduce((a,b) => a + b.data.weight, 0.0)
    //
    //
    if (sum > 0.01 && sum <= 0.99) {
      id = genId++
      node.children.push(id)
      extraNodes.push({
        id: id,
        children: [],
        data: {weight: 1.0 - sum},
        draw: {
          parent:  node.id,
          deep:    node.draw.deep + 1,
          percent: node.draw.percent,
          fake:    true,
          rotate:  -360 + Math.random()*720,
        }
      })
    }
    //
    if (children.length === 0 && node.draw.deep < maxDeep) {
      id = genId++
      node.children.push(id)
      extraNodes.push({
        id: id,
        children: [],
        data: {weight: 1.0},
        draw: {
          parent:  node.id,
          deep:    node.draw.deep + 1,
          percent: node.draw.percent,
          fake:    true,
          rotate:  -360 + Math.random()*720,
        }
      })
      return
    }
    //
    if (children.length > 0 && sum >= 0.99) // se já temos filhos preenchendo 100% o `weight`
      return
    if (children.length === 0 && node.draw.deep > maxDeep)
      return
    //
    if (node.draw.deep >= maxDeep)
      return
    //
    id = genId++
    node.children.push(id)
    extraNodes.push({
      id: id,
      children: [],
      data: {weight: 1.0 - sum},
      draw: {
        parent:  node.id,
        deep:    node.draw.deep + 1,
        percent: node.draw.percent,
        fake:    true,
        rotate:  -360 + Math.random()*720,
      }
    })
  })
  //
  // copiando novos `nodes` para a lista de items
  extraNodes.forEach(node => {
    items.push(node)
  })
  //
  addChildrenInMaxDeep(items, maxDeep, currentDeep + 1)
}
