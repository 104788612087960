import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import config from '../../../config'
import './AppLogo.scss'

export default class HeaderLeftAppLogo extends Component {

  componentDidMount() {
    setTimeout(_ => {
      ReactDOM.findDOMNode(this).classList.add(`visible`)
    }, 1100);
  }
  
  render() {
    return (
      <div className={`HeaderLeftAppLogo ${this.props.className}`}>
        <b>{ this.props.pageName && this.props.pageName }{ config.appName }</b>
        <span>{ config.appVersion }</span>
      </div>
    )
  }

}
