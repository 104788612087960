import React, { useState, useEffect } from "react";
import PageNotFound from "../screens/PageNotFound";

const Router = (props) => {
  const [currentPath, setCurrentPath] = useState(window.location.pathname);

  useEffect(() => {
    const onLocationChange = () => {
      setCurrentPath(window.location.pathname);
    };
    window.addEventListener("popstate", onLocationChange);

    return () => {
      window.removeEventListener("popstate", onLocationChange);
    };
  }, []);

  if(currentPath === "/") {
    window.open("/cerebrum", "_self", "noopener,noreferrer");
    return null;
  }

  let subRoutes = [];
  const mainRoutes = props.children.filter((main) => {
    if (main.props.children) {
      subRoutes = main.props.children.filter((sub) => {
        return currentPath === sub.props.path;
      });
    }
    return currentPath === main.props.path;
  });

  if (mainRoutes.length || subRoutes.length) {
    return props.children;
  }

  return <PageNotFound />;
};

export default Router;
