import axios from 'axios'
import { DEFAULT_AUTH_HOST } from './defaults';

export const logout = ({ token, app, host } = {}) => {
  // prepare
  host = host || DEFAULT_AUTH_HOST
  
  // validates
  if (!app) return Promise.reject('aplicação não informada')
  if (!token) return Promise.reject('token não informado')

  // runs
  return axios.delete(`${host}/auth`, {
    headers: {
      'Authorization': token,
      'Accept-Client': app
    }
  }).then(handleLogoutSuccess, handleLogoutError)
};

const handleLogoutSuccess = response => response.data

const handleLogoutError = error => {
  if (error.response) {
    return Promise.reject(error.response.data.message);
  } else {
    console.error('[SPI-OAUTH2-JS | ERROR]', error.message)
    error.request && console.log('Request', error.request)
    return Promise.reject('Não foi possível fazer logout no servidor');
  } 
};
