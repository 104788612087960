import React, { Component } from 'react'
import { List } from 'react-virtualized'
import MapCircleSelection from '../../../UI/MapCircleSelection/'
import './index.scss'

export class CardCameraLista extends Component {

  state = {
    rowsFiltered: [],
    poles: [],
    totalHidden: 0,
  }

  download = (center, radius) => {
    let query = {}
    if (this.props.type === `circle` && center && radius) {
      query = {longitude: center[0], latitude: center[1], radius}
    } else if (this.props.ais) {
      query = {ais: this.props.ais}
    }
    return this.props.http(`/surveillance/cameras`, query).then(data => {
      data.poles.forEach((i, index) => {
        const types = {}
        i.cameras.forEach(_ => types[_.type] = true)
        i.id    = index
        i.point = i.geom.coordinates
        i.type  = Object.keys(types).join(`/`) || `-`
        i.text  = (i.ais ? `AIS ${i.ais} - ` : ``) + i.address
        i.query = ` ${i.identifier} ${i.city} ${i.address} AIS ${i.ais} ${i.type} `.toUpperCase()
        i.cameras.forEach(c => {
          c.poleId = i.identifier
          c.point = i.geom.coordinates
        })
      })
      return data
    })
  }

  componentDidMount() {
    const click = this.props.roles.includes(`CEREBRUM_CAMERAS_STREAM`) ? this.clickPopup : null
    const group = {color: `#1DAED9`, title: `Câmeras`}
    this.props.publish(`map::layer.add`, {name: `current`, points: [], group, color: `#cef`, scale: 1.2})
    this.props.publish(`map::layer.add`, {name: `cameras`, points: [], group, color: `#1DAED9`, popup: this.createPopup, click})
    //
    if (this.props.type === `circle`) {
      this.props.show()
      this.props.setTitle(`CÂMERAS DE MONITORAMENTO POR ÁREA`)
      return
    }
    //
    this.download().then(data => {
      const { ais, poles } = data
      if (ais) {
        const group = {color: `#abb3a8`, title: `AIS ${this.props.ais}`}
        this.props.publish(`map::layer.add`, {polygons: ais.geom, group})
      }
      this.setState({poles, rowsFiltered: poles})
      this.props.publish(`map::layer.update`, {name: `cameras`, points: poles})
      this.props.publish(`map::layer.zoom`, {name: `cameras`})
      this.props.show()
      this.props.setTitle(`CÂMERAS DE MONITORAMENTO` + (this.props.ais ? ` AIS ${this.props.ais}` : ``))
    })
  }

  createPopup = item => {
    return { title: `CÂMERAS POSTE ${item.identifier}` }
  }

  clickPopup = item => {
    item.cameras = JSON.parse(item.cameras)
    this.openCardsFromPole(item)
  }

  openCardsFromPole = (pole) => {
    const poleId = pole.identifier
    if (!this.props.roles.includes(`CEREBRUM_CAMERAS_STREAM`)) {
      return
    }
    if (pole.cameras.length === 0) {
      this.props.openCard(`CardCameraStream`, {poleId, camera: {}, point: pole.point, main: true})
    }
    pole.cameras.forEach((camera, index) => {
      this.props.openCard(`CardCameraStream`, {poleId, camera, point: pole.point, main: index === 0})
    })
  }

  clickRowHandler = row => {
    this.openCardsFromPole(row)
  }

  focusRowHandler = item => {
    this.props.publish(`map::layer.update`, {name: `current`, points: [item,]})
  }

  focusLeaveHandler = () => {
    clearTimeout(this.focusTimer)
    this.props.publish(`map::layer.clear`, {name: `current`})
  }

  filterTextHandler = e => {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(this.applyFilter.bind(this, e.target.value), 200)
  }

  applyFilter = text => {
    text = ` ${text} `.toUpperCase()
    let words = []
    const match = text.match(/\sAIS\s\d+\s/)
    if (match) {
      text = text.replace(match[0], ` `)
      words.push(`AIS ` + match[0].match(/\d+/)[0])
    }
    text.trim().split(/\s+/).forEach(word => {
      words.push(word)
    })
    const poles = this.state.poles
    let totalHidden = 0
    poles.forEach(i => {
      i.hidden = this.testHidden(words, i.query)
      if (i.hidden)
        totalHidden++
    })
    const rowsFiltered = poles.filter(_ => !_.hidden)
    this.props.publish(`map::layer.update`, {name: `cameras`, points: rowsFiltered})
    this.props.publish(`map::layer.zoom`, {name: `cameras`, maxZoom: 15})
    this.setState({rowsFiltered, poles, totalHidden})
  }

  testHidden = (filterWords, itemQuery) => {
    if (filterWords.length === 0) {
      return false
    }
    return filterWords.filter(word => itemQuery.indexOf(word) === -1).length
  }

  mapSelectionHandler = ({center, radius}) => {
    this.props.setLoading()
    this.props.publish(`map::layer.clear`, {name: `cameras`})
    this.setState({initialized: true, totalHidden: 0})
    this.download(center, radius).then(({ poles }) => {
      this.setState({poles, rowsFiltered: poles, errorMessage: null})
      this.props.publish(`map::layer.update`, {name: `cameras`, points: poles})
      this.props.show()
    }).catch(_ => {
      this.setState({errorMessage: `FALHA AO CONSULTAR DADOS`})
      this.props.show()
    })
  }

  renderTitle = () => {
    const ais = this.props.ais ? `AIS ${this.props.ais}` : ``
    return (
      <div className="card-title category">
        CÂMERAS DE MONITORAMENTO <span>{ ais }</span>
      </div>
    )
  }

  render() {
    return (
      <div className="CardCameraLista">
        { this.renderTitle() }
        { this.renderAreaInfo() }
        { this.renderTable() }
      </div>
    )
  }

  renderEmpty() {
    return (
      <p className="message no-data">
        NENHUMA CÂMERA ENCONTRADA
      </p>
    )
  }

  renderAreaInfo() {
    const circle = this.props.type === `circle`
    return (
      <div className="container">
        { circle ? this.renderCircleComponent() : null }
        { this.renderSummary() }
      </div>
    )
  }

  renderCircleComponent() {
    const { initialized, errorMessage } = this.state
    const message = initialized ? null : (
      <p className="message empty">
        AGUARDANDO DEFINIÇÃO DE ÁREA NO MAPA...
      </p>
    );
    return (
      <React.Fragment>
        <MapCircleSelection onChange={ this.mapSelectionHandler } />
        { errorMessage ? <p className="message empty">{ errorMessage }</p> : message }
      </React.Fragment>
    )
  }

  renderSummary() {
    const { poles, rowsFiltered, initialized, errorMessage } = this.state
    if ((this.props.type === `circle` && !initialized) || errorMessage) {
      return null
    }
    return (
      <div className="summary">
        <div className="input">
          <input placeholder="Filtro..." onChange={ this.filterTextHandler } />
          <div className="total-hidden">
            { rowsFiltered.length } / { poles.length }
          </div>
        </div>
      </div>
    )
  }

  renderTable() {
    const { type } = this.props
    const { initialized, errorMessage, rowsFiltered } = this.state
    if ((type === `circle` && !initialized) || errorMessage) {
      return null
    }
    if (rowsFiltered.length === 0) {
      return this.renderEmpty()
    }
    return (
      <div className="table-list" onMouseLeave={ this.focusLeaveHandler }>
        <div className="row header">
          <div className="col code">CÓDIGO</div>
          <div className="col type">TIPO</div>
          <div className="col count">TOTAL</div>
          <div className="col text">LOCAL</div>
        </div>
        <List width={ 700 } height={ 250 } rowHeight={ 27 } rowCount={ rowsFiltered.length } rowRenderer={ this.renderRow } />
      </div>
    )
  }

  renderRow = ({index, key, style}) => {
    const i = this.state.rowsFiltered[index]
    return (
      <div key={ i.id } style={ style } className="row" onClick={ this.clickRowHandler.bind(this, i) } onMouseEnter={ this.focusRowHandler.bind(this, i) }>
        <div className="col code">{ i.identifier }</div>
        <div className="col type">{ i.type }</div>
        <div className="col count">{ i.cameras.length }</div>
        <div className="col text" title={ i.text }>{ i.text }</div>
      </div>
    )
  }

}
