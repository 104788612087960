import React, { Component } from 'react';
import './index.scss'

const INPUT_DELAY = 250 // ms

export class SearchFilterWords extends Component {

  state = {
    value: ``,
    words: []
  }

  text2words = text => {
    return text.toUpperCase().replace(/\s{1,}/g, ` `).trim().split(` `).filter(_ => _)
  }

  changeTextHandler = e => {
    const { value } = e.target
    this.setState({value, typing: true})
    clearTimeout(this.timeout)
    this.timeout = setTimeout(this.applyFilter.bind(this, e.target.value), INPUT_DELAY)
  }

  keyUpHandler = e => {
    if (e.keyCode === 27) {
      this.setState({value: ``}, this.applyFilter)
    }
  }

  applyFilter = text => {
    const words = this.text2words(text || ``)
    this.setState({words, typing: false})
    this.props.onChange(words)
  }

  render() {
    const { value, typing } = this.state
    const { placeholder=`Filtro...`, subtotal=`?`, total=`?` } = this.props
    return (
      <div className="SearchFilterWords">
        <input placeholder={ placeholder } value={ value } onChange={ this.changeTextHandler } onKeyUp={ this.keyUpHandler } />
        <div className="total-hidden">
          { typing ? total : `${subtotal} / ${total}` }
        </div>
      </div>
    )
  }

}
