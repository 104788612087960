
import * as type from '../fieldTypes'

export const DetranVeiculosByCPF = {
  created: `2019-04-22`,
  context: `DETRAN.Veículos`,
  role: `CEREBRUM_BUSCA_VEICULOS_CPF_CNPJ`,
  params: {
    id: {label: `CPF`, placeholder: `000.000.000-00`, type: type.cpf}
  },
  parser: ({id}) => {
    id = id.replace(/\W/g, ``)
    return {card: `CardCNHVeiculos`, props: {id}}
  }
}

export const DetranVeiculosByCNPJ = {
  created: `2019-04-22`,
  context: `DETRAN.Veículos`,
  role: `CEREBRUM_BUSCA_VEICULOS_CPF_CNPJ`,
  params: {
    id: {label: `CNPJ`, placeholder: `00.000.000/0000-00`, type: type.cnpj},
  },
  parser: ({id}) => {
    id = id.replace(/\W/g, ``)
    return {card: `CardCNHVeiculos`, props: {id}}
  }
}

export const DetranVeiculosByPlaca = {
  created: `2019-04-22`,
  context: `DETRAN.Veículos`,
  role: `CEREBRUM_BUSCA_VEICULO_DETALHE`,
  params: {
    plate: {label: `Placa`, placeholder: `AAA0000 ou AAA0A00`, type: type.placaVeiculo},
  },
  parser: ({plate}) => {
    plate = plate.replace(/\W/g, ``)
    return {card: `CardCNHVeiculoById`, props: {plate}}
  }
}

export const DetranVeiculosByMotor = {
  created: `2019-05-02`,
  context: `DETRAN.Veículos`,
  role: `CEREBRUM_BUSCA_VEICULO_DETALHE`,
  params: {
    motor: {label: `Motor`, placeholder: `00000000`, type: type.text},
  },
  parser: ({motor}) => {
    return {card: `CardCNHVeiculoById`, props: {motor}}
  }
}

export const DetranVeiculosByChassi = {
  created: `2019-05-02`,
  context: `DETRAN.Veículos`,
  role: `CEREBRUM_BUSCA_VEICULO_DETALHE`,
  params: {
    chassi: {label: `Chassi`, placeholder: `0AA000AA00A000000`, type: type.text},
  },
  parser: ({chassi}) => {
    return {card: `CardCNHVeiculoById`, props: {chassi}}
  }
}
