
/**
 * cria uma estrutura básica para popup mapa
 */
const popupHtml = (title, text=null, source=null) => {
    let html = ``
    //
    if (title) {
        html += `<div class="tooltip-title">${title}</div>`
    }
    if (text) {
        text = typeof text === `object` && text.constructor === Array ? text.map(line => (`<div>${line}</div>`)).join(``) : text
        html += `<div class="tooltip-text">${text}</div>`
    }
    if (source) {
        html += `<div class="tooltip-source">${source}</div>`
    }
    let className = `tooltip-box`
    if (title && !text && !source) {
        className += ` only-title`
    }
    return `<div class="${className}">` + html + `</div>`
}

export function createHtml(data) {
    const { title, text, source } = data
    return popupHtml(title, text, source)
}
