import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import util from '../../../../helpers/util'
import './TimelineRoutes.scss'

/*
props = {
  currentTime: new Date(`2000-01-01 11:46:27`),   <-- deve existir valor apenas em caso `online`
  routes: [
    {
      id: 1,
      name: `Padrão`,
      color: `#fa3`,
      hidden: false,
      times: [
        {begin: new Date(`2000-01-01 11:00:00`), end: new Date(`2000-01-01 12:00:00`)},
        {begin: new Date(`2000-01-01 12:00:00`), end: new Date(`2000-01-01 13:00:00`)},
      ]
    }
  ],
  onTimeChanged: (begin, end) => {},
  onRouteChanged: (routeId) => {},
  onRouteViewChanged: (routeId, opened) => {},
}
*/
export default class TimelineRoutes extends Component {

  static CELL_WIDTH = 60 // 1:1 pixel/minute

  /**
   * `merge` dos slices ordenando por `begin`
   */
  static routes2timeline(routes) {
    const timeline = []
    routes.forEach(route => {
      route.times.forEach(time => timeline.push({...time, routeId: route.id}))
    })
    return timeline.sort((a,b) => a.begin - b.begin)
  }

  state = {
    timeline: [], // sequencia de tempo contendo todas as rotas
    mode: `column`, // `column` ou `range` (ignorado quando temos `props.currentTime` ~ online)
    index: 0,
  }

  constructor(props) {
    super(props)
    const times = TimelineRoutes.routes2timeline(props.routes)
    this.state.timeline = times
    this.state.alphaStart = times[0].begin.getTime()
    this.state.alphaScale = times[times.length - 1].end.getTime() - times[0].begin.getTime()
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.currentTime !== nextProps.currentTime)
      return true
    if (this.state.mode !== nextState.mode)
      return true
    if (this.state.begin !== nextState.begin || this.state.end !== nextState.end)
      return true
    const routesA = this.props.routes.map(_ => _.hidden).join(``)
    const routesB = nextProps.routes.map(_ => _.hidden).join(``)
    if (routesA !== routesB)
       return true
    return false
  }

  componentDidMount() {
    this.dom = ReactDOM.findDOMNode(this).querySelector(`.routes`)
    this.onClickTimeColumn()
  }

  render() {
    this.mode = this.props.currentTime ? `online` : this.state.mode
    this.online = this.mode === `online`
    let clss = ``
    if (this.online) {
      clss += ` no-interaction`
    }
    if (this.mode === `range` && this.state.begin) {
      clss += ` no-labels`
    }
    return (
      <div className={ `TimelineRoutes ${this.mode} ${clss}` }>
        <div className="container">
          { this.renderRoutes() }
          { this.renderTimelineLabels() }
          { this.renderTimeControl() }
        </div>
      </div>
    )
  }

  renderRoutes() {
    const { routes } = this.props
    const { timeline } = this.state
    return (
      <div className="routes"
        onMouseDown={ this.mouseDownHandler }
        onMouseUp={ this.mouseUpHandler }
        onMouseMove={ this.mouseMoveHandler }
        >
        {routes.map((route, indexRoute) => (
          <div className={ `route` + (route.hidden ? ' eye-closed':'') } key={ indexRoute }>
            <button className="eye" onClick={ this.onClickRouteViewToggle.bind(this, route) }></button>
            <div className="label">
              Rota { route.name }
            </div>
            <div className="traces">
              {timeline.map((timeSlice, indexTime) => (
                <div className="trace-container" key={ indexTime }>
                  {
                    timeSlice.routeId === route.id ?
                    (<div className="trace" style={ {background: route.color} }></div>)
                    :
                    (<div className="trace empty"></div>)
                  }
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    )
  }

  renderTimelineLabels() {
    const { timeline, mode, value } = this.state
    const clss = this.online ? `hidden` : ``
    return (
      <div className={ `timeline ${clss}` }>
        {timeline.map((time, index) => (
          <div key={ index }
            className={ `time ` + ( mode === `label` && value === index ? `selected` : ``) }
            onClick={ this.onClickTimeColumn.bind(this, index) }
          >
            { util.date2time(time.begin) }
          </div>
        ))}
      </div>
    )
  }

  renderTimeControl() {
    const { state } = this
    const { timeline, mode } = state
    let left  = 0
    let width = TimelineRoutes.CELL_WIDTH
    let begin = ``
    let end   = ``
    let clss  = ``
    //
    if (this.online) {
      const a = timeline[0].begin.getTime()
      const b = this.props.currentTime.getTime()
      const c = timeline[timeline.length - 1].end.getTime()
      if (a <= b && b <= c) {
        left  = parseInt(((b - a) / (c - a)) * (width * timeline.length), 10) - 2
        width = 3
        begin = util.date2time(this.props.currentTime)
        clss  = `one-label`
      }
    } else if (mode === `column`) {
      left = state.index * width
      clss = `no-labels`
    } else if (mode === `range` && state.begin && state.end) {
      left  = state.left
      width = state.width
      begin = util.date2time(state.begin)
      end   = util.date2time(state.end)
      clss  = state.moving ? `moving` : ``
    }
    return (
      <div className={ `control ${mode} ${clss}` } style={ {left, width} }>
        <div className="begin">{ begin }</div>
        <div className="end">{ end }</div>
      </div>
    )
  }

  mouseDownHandler = (e) => {
    if (e.button !== 0 || this.online) {
      return
    }
    const marginLeft = 50
    const maxWidth = this.state.timeline.length * TimelineRoutes.CELL_WIDTH
    let x0 = e.pageX - this.dom.getClientRects()[0].x - marginLeft
    if (x0 < 0 || x0 > maxWidth) {
      return // ignoramos click inicial fora da area da rota/tempo
    }
    const date = this.calcDateByAlpha(x0 / maxWidth)
    this.setState({
      mode: `range`,
      moving: true,
      x0,
      left: x0,
      width: 1,
      begin: date,
      end: date,
      clickStarted: Date.now(),
    })
  }

  mouseUpHandler = (e) => {
    if (!this.state.moving) {
      return
    }
    const marginLeft = 50
    const x1 = e.pageX - this.dom.getClientRects()[0].x - marginLeft
    this.setState({moving: false}, () => {
      // se temos click rápido, selecionamos horário/intervalo da coluna clicada
      if (Date.now() - this.state.clickStarted < 300) {
        const indexTime = parseInt(x1 / TimelineRoutes.CELL_WIDTH, 10)
        this.onClickTimeColumn(indexTime)
      } else {
        const { begin, end } = this.state
        if (begin && end) {
          begin.setSeconds(0)
          end.setSeconds(0)
          this.props.onTimeChanged({begin, end, routes: this.extractRoutesFromInterval(begin, end)})
        }
      }
    })
  }

  mouseMoveHandler = (e) => {
    if (!this.state.moving) {
      return
    }
    const marginLeft = 50
    const maxWidth = this.state.timeline.length * TimelineRoutes.CELL_WIDTH
    //
    let x0 = this.state.x0
    let x1 = e.pageX - this.dom.getClientRects()[0].x - marginLeft
    if (x1 > maxWidth) {
      x1 = maxWidth
    } else if (x1 < 0) {
      x1 = 0
    }
    const min   = x0 < x1 ? x0 : x1
    const max   = x0 < x1 ? x1 : x0
    const left  = x0 < x1 ? x0 : x1
    const width = Math.abs(x0 - x1)
    const begin = this.calcDateByAlpha(min / maxWidth)
    const end   = this.calcDateByAlpha(max / maxWidth)
    this.setState({x1, left, width, begin, end})
  }

  onClickTimeColumn = (index=0) => {
    if (this.online) {
      return
    }
    const { begin, end, routeId } = this.state.timeline[index]
    this.setState({mode: `column`, index, begin, end}, () => {
      this.props.onTimeChanged({begin, end, routes: [ routeId ]})
    })
  }

  onClickRouteViewToggle = route => {
    if (this.props.onRouteViewChanged) {
      this.props.onRouteViewChanged(route.id, !route.hidden)
    }
  }

  /**
   * dado um intervalo, buscamos quais rotas o slice de tempo pertence
   */
  extractRoutesFromInterval = (begin, end) => {
    const routes = []
    this.state.timeline.forEach(slice => {
      if (routes.includes(slice.routeId))
        return
      if (slice.begin < begin && slice.end <= begin)
        return
      if (slice.begin >= end && slice.end > end)
        return
      routes.push(slice.routeId)
    })
    return routes
  }

  /**
   * calcula um datetime por interpolação linear na timeline
   */
  calcDateByAlpha = alpha => {
    alpha = alpha < 0 ? 0 : alpha > 1 ? 1 : alpha
    return new Date(this.state.alphaStart + this.state.alphaScale * alpha)
  }

}



