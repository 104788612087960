import React, { Component } from 'react'
import CarDocument from './CarDocument'
import MenuBarVeiculos from './MenuBarVeiculos'
import { normalizeAddress } from '../../../../helpers/addressModel'
import util from '../../../../helpers/util'
import { publish } from "../../../../services/EventBus";
import './index.scss'

export class CardCNHVeiculos extends Component {

  static ROLES = [
    `CEREBRUM_BUSCA_VEICULOS_CPF_CNPJ`,
  ]

  state = {
    title: ``,
    query: null,
    current: 0,
    loading: false,
    items: [],
    currentPage: -1,
    totalPages: 0,
    loadedPages: [],
    noVehicles: false
  }

  constructor(props) {
    super(props)
    this.menuRef = React.createRef()
  }

  dataSetupCount = (vehiclesCount, title, query) => {
    const current = 0;
    // const vehiclesCount = { size: 15, count: 75 }
    vehiclesCount.count === 0 && this.setState({noVehicles: true})
    const totalPages = Math.ceil(vehiclesCount.count / 5)
    this.setState({title, query, totalPages, currentPage: -1, current}, () => {
      this.loadNextPage(() => {
        // this.menuChange(0)
        this.menuChange(current)
        // this.loadCivil()
        this.props.show()
        this.props.setTitle(`VEÍCULOS ` + title)
      })
    })
  }

  componentDidMount() {
    this.menuRef.current.addEventListener(`scroll`, this.scrollHandler, {passive: true})
    //
    const title = this.formatDocumentTitle()
    const {idDetran: id} = this.props
    if(id){
      const query = {identifier: id}
      this.setState({title, query})
      if(this.props.data && (this.props.data.vehiclesCount || {}).count){
        this.dataSetupCount(this.props.data.vehiclesCount, title, query)
      } else {
        this.props.http(`/detran/vehicles/count`, query).then( vehiclesCount => {
          const { error } = vehiclesCount
          if (error) {
            return util.handleError(
              error,
              this.props.show,
              () => {
                this.setState({ noVehicles: true, message: error.message });
              },
              (message) => {
                this.setState({
                  noVehicles: true,
                  message 
                });
              }
            );
          }
          publish(`card::set-identifiers`, { hash: this.props.hash, cpf: id, data: {vehiclesCount} })
          this.dataSetupCount(vehiclesCount, title, query)
        })
      }
    } else {
      this.setState({noVehicles: true})
    }
  }

  componentWillUnmount() {
    this.menuRef.current.removeEventListener(`scroll`, this.scrollHandler)
  }

  scrollHandler = e => {
    const dom = e.target
    const bottom = dom.scrollHeight - dom.scrollTop === dom.clientHeight
    if (bottom) {
      const { currentPage, totalPages, loading } = this.state
      if (currentPage < totalPages - 1 && !loading) {
        this.setState({loading: true}, this.loadNextPage)
      }
    }
  }

  dataSetupVehicles = (vehicles, loadedPages, callbackDone) => {
    this.setState({ loadedPages: [...loadedPages, true] })
    // if (this.props.isDuplicated(vehicles)) {
    //   return this.props.close()
    // }
    vehicles.forEach(i => {
      i.stolen = !!(i.details && i.details.vehicle && i.details.vehicle.theft)
    })
    this.setState({loading: false, items: [...this.state.items, ...vehicles]}, callbackDone)
  }

  loadNextPage = (callbackDone) => {
    const { query, currentPage, totalPages, loadedPages } = this.state
    const updatedPage = currentPage + 1
    if (totalPages === 0 || updatedPage > totalPages) {
      return callbackDone ? callbackDone() : null
    }
    const page = updatedPage / 3
    if(updatedPage % 3 === 0 && !loadedPages[page] && updatedPage < totalPages){
      if(this.props.data && (this.props.data.vehicles || []).length){
        this.dataSetupVehicles(this.props.data.vehicles, loadedPages, callbackDone)
      }else{
        this.props.http(`/detran/vehicles`, {...query, page}).then(vehicles => {
          if(vehicles.error) {
            this.setState({loading: false, noVehicles: true}, callbackDone)
            return;
          }
          publish(`card::set-identifiers`, { hash: this.props.hash, data: {vehicles} })
          this.dataSetupVehicles(vehicles, loadedPages, callbackDone)
        })
      }
    }
    if(updatedPage < totalPages)
      this.setState({currentPage: updatedPage})
  }

  previousPage = () => {
    const { currentPage } = this.state
    if(currentPage >= 0)
      this.setState({currentPage: currentPage - 1})
  }

  loadCivil = () => {
    const { items } = this.state
    const docType = items.length && items[0].ownerCpf && items[0].ownerCpf.length === 11 ? `CPF` : `CNPJ`
    if (docType === `CPF`) {
       // COMENTAMOS ESSA BUSCA
      // this.props.openCard(`CardCivilHistorico`, {cpf: items[0].ownerCpf})
    }
  }

  menuChange = index => {
    this.setState({current: index})
    this.geocodeFocus(this.state.items[index])
  }

  geocodeFocus = json => {
    this.props.publish(`map::marker.remove`, {name: `geocode`})
    if (json && json.details && json.details.address && json.details.address.geocode) {
      const { address } = json.details
      const { geocode } = address
      const data = {
        point: geocode.position,
        source: geocode.source,
        address: normalizeAddress(address).text,
        name: json.markModel,
        plate: util.formatCarPlateNumber(json.plate),
        type: json.type,
        color: json.color,
      }
      const zoom = address.address ? 13 : 8
      this.props.publish(`map::zoom`, {point: data.point, zoom})
      setTimeout(() => {
        this.props.publish(`map::marker.add`, {name: `geocode`, point: data.point, data, color: `#3dca85`, className: `square animated`, popup: this.createPopup})
      }, 1)
    }
  }

  createPopup = json => {
    const { plate, type, color, address, source } = json.data
    return {
      title: plate + ` ` + type + ` ` + color,
      text: address,
      source
    }
  }

  formatDocumentTitle = () => {
    const {idDetran: id} = this.props
    if (id) {
      if (id.length === 11) return util.formatCPF(id)
      if (id.length === 14) return util.formatCNPJ(id)
      return id
    }
    return ``
  }

  render() {
    const { items, current } = this.state
    return (
      <div className="CardCNHVeiculos">
        <div className="title">VEÍCULOS <span>{ this.state.title }</span></div>
        { items.length === 0 ? this.renderEmpty() : null }
        <div className="panels">
          <div className="panel menu" ref={ this.menuRef }>
            {items.length > 0 ? <MenuBarVeiculos items={ items } previousPage={this.previousPage} loadNextPage={this.loadNextPage} totalPages={this.state.totalPages} current={ current } onChange={ this.menuChange } /> : null}
          </div>
          <div className="panel main">
            { this.renderColumnMain(items, current) }
          </div>
        </div>
      </div>
    )
  }

  renderEmpty() {
     return this.state.noVehicles
      ? <p className="message">NENHUM REGISTRO ENCONTRADO</p> 
      : <div className="main-message-loading">CARREGANDO...</div> 
    
  }

  renderColumnMenu(items, current, loading) {
    return (
      <React.Fragment>
        {items.map((i, index) => (
          <button key={ index } className={ (i.stolen ? `stolen ` : ``) +  (current === index ? `selected` : ``) } onClick={ this.menuChange.bind(this, index) }>
            <span>{ util.formatCarPlateNumber(i.plate) }</span>
            <div>{ i.type }</div>
          </button>
        ))}
        { loading ? <div className="message-loading">CARREGANDO...</div> : null }
      </React.Fragment>
    )
  }

  renderColumnMain(items, current) {
    return (
      <React.Fragment>
        {items.map((i, index) => (
          <CarDocument key={ index } hidden={ index !== current } {...i} />
        ))}
      </React.Fragment>
    )
  }

}
