
/**
* Recebe um JSON em formato `backend` e retorna a estrutura necessária para `frontend`
*/
export default (json) => {
  const currentYear = new Date().getFullYear()
  const years = json[0].interpretation.numberOfPreviousYears
  const periodTypes = [
    {
      attr: `lastYear`,
      label: `MÉDIA DO ANO PASSADO`,
      shortLabel: `MÉDIA ANO ${currentYear - 1}`
    },
    {
      attr: `previousYears`,
      label: `MÉDIA DOS ÚLTIMOS ${years} ANOS`,
      shortLabel: `MÉDIA ${currentYear - years}-${currentYear - 1}`
    },
    {
      attr: `lowestOfPreviousYears`,
      label: `MENOR MÉDIA DOS ÚLTIMOS ${years} ANOS`,
      shortLabel: `MÉDIA MÍN ${currentYear - years}-${currentYear - 1}`
    }
  ]
  const models = {}
  periodTypes.forEach(i => {
    models[i.attr] = extractModel(json, i.attr)
  })
  return {years, periodTypes, models}
}

function extractModel(json, attr) {
  const weight = {}
  json.forEach(node => {
    (node.indicator.children || []).forEach(i => {
      weight[i.id] = i.weight
    })
  })
  return json.map(i => {
    const { indicator, weeklyCount } = i
    const { id, name, polarity } = indicator
    const { color, label, value, dailyAverage } = i.interpretation[attr]
    const children = indicator.children.map(({id}) => id)
    const data = {
      id,
      name,
      polarity,
      color,
      status: label,
      value,
      weight: weight[id] === undefined ? 1.0 : weight[id],
      dailyAverage,
      weeklyCount
    }
    return {id, children, data}
  })
}
