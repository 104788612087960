import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import AppLogo from './AppLogo'
import Parceiros from './Parceiros'
import './index.scss'

export default class HeaderLeft extends Component {

  componentDidMount() {
    setTimeout(_ => {
      ReactDOM.findDOMNode(this).classList.add(`visible`)
    }, 300);
  }
  
  render() {
    const page = {
      headerClass: this.props.classes ? this.props.classes.headerLeft : "",
      logoClass: this.props.classes ? this.props.classes.appLogo : "",
      partnershipsClass: this.props.classes ? this.props.classes.partnerships : "",
      name: this.props.pageName ? this.props.pageName : "",
    };

    return (
        <div className={`HeaderLeft ${page.headerClass}`}>
          <AppLogo pageName={page.name} className={page.logoClass}/>
          <Parceiros className={page.partnershipsClass}/>
        </div>
    );
  }

}
