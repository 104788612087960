import React, { Component } from 'react'
import { publish } from '../../../services/EventBus'
import './Search.scss'

export default class HeaderCenterSearch extends Component {

  clickSearch = () => {
    publish(`search::open`)
    publish(`panels::close`)
  }

  render() {
    return (
      <div className="HeaderCenterSearch" onClick={ this.clickSearch }>
        <div className="icon"></div>
        <div className="placeholder">Pesquisar...</div>
      </div>
    )
  }

}
