import React, { Component } from 'react'
import Detalhe from './Detalhe'
import MenuBar from './MenuBar'
import { normalizeAddress } from '../../../../helpers/addressModel'
import { publish } from "../../../../services/EventBus";
import util from '../../../../helpers/util'
import './index.scss'

export class CardCivilHistorico extends Component {

  static ROLES = [
    `CEREBRUM_BUSCA_CIVIL`,
  ]

  state = {
    fullname: ``,
    identidades: [],
    current: null,
    items: [],
    message: null
  }

  request  = async (query) => {
    return Promise.resolve(
      this.props.http(`/civil/details`, query).then((identities) => {
        return identities;
      })
    )
  };

  baseRequest = async (query) => {
    return new Promise((resolve, reject) => {
      return query ? this.request(query).then((response) => {
        return response.error ? reject(response.error) : resolve({response, query});
      }) : reject(false);
    });
  };
  
  errorHandler = (error) => {
    publish(`card::set-identifiers`, { hash: this.props.hash, data: {identidades: {error}} })
    error
      ? util.handleError(
          error,
          this.props.show,
          () => {
            this.setState({ current: 0 });
          },
          (message) => {
            this.setState({
              current: 0,
              message,
            });
          }
        )
      : this.setState({ current: 0 }, this.props.show);
  };

  successHandler = ({response, query}) => {
    const { cpf } = this.props
    response.length > 0
      ? this.dataSetup(response)
      : query.rg && cpf
      ? this.baseRequest({ cpf }).then(this.successHandler, this.errorHandler)
      : this.dataSetup(response);
  };

  componentDidMount() {
    if (this.props.data && this.props.data.identidades && !this.props.data.identidades.error && this.props.data.identidades.length > 0) {
      this.dataSetup(this.props.data.identidades)
    } else {
      const { cpf, rg } = this.props
      let query = rg ? { rg } : cpf ? { cpf } : false;
      this.baseRequest(query).then(this.successHandler, this.errorHandler)
    }
  }

  dataSetup = (identidades) => {
    const { cpf, rg } = this.props
    const current = 0
    if (this.props.isDuplicated(identidades)) {
      return this.props.close()
    }
    if (identidades.length === 0) {
      publish(`card::set-identifiers`, { hash: this.props.hash, cpf, data: {identidades: []} })
      const fullname = cpf ? util.formatCPF(cpf) : rg
      this.setState({ fullname, current: 0 })
      // setTimeout(this.loadExtraCards, 1000)
      this.props.setTitle(`CIVIL ` + fullname)
      return this.props.show()
    }
    identidades.sort((a, b) => new Date(b.rgInfo.identityDate || Date.now()).getTime() - new Date(a.rgInfo.identityDate).getTime())
    //
    const fullname = identidades[0].basicInfo.name
    const items = identidades.map(i => {
      const id = i.orderNumber
      const dt = i.rgInfo.identityDate ? this.date2str(i.rgInfo.identityDate) : `NÃO EXPEDIDA`
      return { title: id, text: dt }
    })
    this.setState({ fullname, identidades, current, items }, () => {
      this.menuChange(current)
      this.props.show()
      this.props.setTitle(`CIVIL ` + fullname)
      const cpfList = this.getCPFNumbers()
      const rgList = this.getRGNumbers()
      const identifiers = {}
      cpfList.forEach(cpf => {
        identifiers['cpf'] = cpf
      })
      rgList.forEach(rg => {
        identifiers['rg'] = rg
      })
      publish(`card::set-identifiers`, { hash: this.props.hash, ...identifiers, overrideRg: true, data: {identidades} })
      // setTimeout(this.loadExtraCards, 1000)
    })
  }

  createPopup = json => {
    const { id, name, address, source } = json.data
    return {
      title: id + ` - ` + name,
      text: address,
      source,
    }
  }

  getCPFNumbers = () => {
    const numbers = {}
    this.state.identidades.forEach(i => {
      if (i.documentInfo && i.documentInfo.cpf && i.documentInfo.cpf.length > 0) {
        const { cpf } = i.documentInfo
        numbers[cpf] = true
      }
    })
    return Object.keys(numbers)
  }

  getRGNumbers = () => {
    const numbers = {}
    this.state.identidades.forEach(i => {
      if (i.rgInfo) {
        numbers[i.rgInfo.rg] = true
      }
    })
    return Object.keys(numbers)
  }

  loadExtraCards = () => {
    const cards = [
      this.loadSispenApenado,
      this.loadSispenVisitas,
      this.loadCNH,
      this.loadCNHVeiculos,
      this.loadSindionibus,
      this.loadTornozelado,
      this.loadEnel,
    ]
    cards.forEach((fn, index) => setTimeout(fn, 10 + (index * 50)))
  }

  loadTornozelado = () => {
    const { openCard, roles, cpf } = this.props
    if (roles.includes(`CEREBRUM_MONITORADOS_DETALHES`)) {
      const cardName = `CardTornozeladosDetalhe`
      const cpfList = this.getCPFNumbers()
      cpfList.forEach(cpf => {
        openCard(cardName, { cpf })
      })
      if (cpfList.length === 0 && cpf) {
        openCard(cardName, { cpf })
      }
    }
  }

  loadEnel = () => {
    const { openCard, roles, cpf } = this.props
    if (roles.includes(`CEREBRUM_BUSCA_ENEL`)) {
      const cardName = `CardEnelHistorico`
      const cpfList = this.getCPFNumbers()
      cpfList.forEach(cpf => {
        openCard(cardName, { cpf })
      })
      if (cpfList.length === 0 && cpf) {
        openCard(cardName, { cpf })
      }
    }
  }

  loadSindionibus = () => {
    const { openCard, roles, cpf, rg } = this.props
    if (roles.includes(`CEREBRUM_SINDIONIBUS`)) {
      const cardName = `CardCivilSindionibus`
      const cpfList = this.getCPFNumbers()
      const rgList = this.getRGNumbers()
      cpfList.forEach(cpf => {
        openCard(cardName, { cpf })
      })
      if (cpfList.length === 0) {
        rgList.forEach(rg => {
          openCard(cardName, { rg })
        })
        if (rgList.length === 0) {
          openCard(cardName, cpf ? { cpf } : { rg })
        }
      }
    }
  }

  loadCNH = () => {
    const { openCard, roles, cpf } = this.props
    if (roles.includes(`CEREBRUM_BUSCA_CNH_CPF`)) {
      const cardName = `CardCNHHabilitacao`
      const cpfList = this.getCPFNumbers()
      cpfList.forEach(cpf => {
        openCard(cardName, { id: cpf })
      })
      if (cpfList.length === 0 && cpf) {
        openCard(cardName, { id: cpf })
      }
    }
  }

  loadCNHVeiculos = () => {
    const { openCard, roles, cpf } = this.props
    if (roles.includes(`CEREBRUM_BUSCA_VEICULOS_CPF_CNPJ`)) {
      const cardName = `CardCNHVeiculos`
      const cpfList = this.getCPFNumbers()
      cpfList.forEach(cpf => {
        openCard(cardName, { id: cpf })
      })
      if (cpfList.length === 0 && cpf) {
        openCard(cardName, { id: cpf })
      }
    }
  }

  loadSispenApenado = () => {
    const { openCard, roles } = this.props
    if (roles.includes(`CEREBRUM_BUSCA_SISPEN`)) {
      const cpf = this.getCPFNumbers()[0] || this.props.cpf
      const rg = this.getRGNumbers()[0] || this.props.rg
      if (cpf || rg) {
        openCard(`CardSispenDetalhe`, cpf ? { cpf } : { rg })
      }
    }
  }

  loadSispenVisitas = () => {
    const { openCard, roles, cpf } = this.props
    if (roles.includes(`CEREBRUM_VISITANTES_SISPEN`)) {
      const cardName = `CardSispenVisitante`
      const cpfList = this.getCPFNumbers()
      cpfList.forEach(cpf => {
        openCard(cardName, { cpf })
      })
      if (cpfList.length === 0 && cpf) {
        openCard(cardName, { cpf })
      }
    }
  }

  render() {
    const { cpf, rg } = this.props
    const identifier = cpf ? util.formatCPF(cpf) : rg
    const { fullname, current } = this.state
    const loading = current === null
    return (
      <div className="CardCivilHistorico">
        <div className="card-title-civil category">
          <div>HISTÓRICO CIVIL</div>
          <span>{loading ? identifier : fullname}</span>
        </div>
        {loading
          ? <div className="message-loading">CARREGANDO...</div>
          : this.renderContent()}
      </div>
    )
  }

  renderContent() {
    const { identidades, current, items, message } = this.state
    const totalPages = Math.ceil(identidades.length / 5)

    if (identidades.length === 0) {
      return <p className="message">{message ? message : `NENHUM REGISTRO ENCONTRADO`}</p>
    }
    return (
      <React.Fragment>
        <MenuBar items={items} totalPages={totalPages} current={current} onChange={this.menuChange} />

        <div className="content" style={{ position: `relative`, paddingTop: 20 }}>
          {identidades.map((i, index) => (
            <Detalhe key={index} hidden={index !== current} roles={this.props.roles} openCard={this.props.openCard} {...i} />
          ))}
        </div>
      </React.Fragment>
    )
  }

  menuChange = index => {
    this.setState({ current: index })
    this.geocodeFocus(this.state.identidades[index])
  }

  geocodeFocus = json => {
    this.props.publish(`map::marker.remove`, { name: `geocode` })
    if (json.addressInfo && json.addressInfo.geocode) {
      const { geocode } = json.addressInfo
      const data = {
        id: json.orderNumber,
        name: json.basicInfo.name,
        address: normalizeAddress(json.addressInfo).text,
        point: geocode.position,
        source: geocode.source,
      }
      const zoom = json.addressInfo.address ? 13 : 8
      this.props.publish(`map::zoom`, { point: data.point, zoom })
      setTimeout(() => {
        this.props.publish(`map::marker.add`, { name: `geocode`, point: data.point, data, color: `#3dca85`, className: `square animated`, popup: this.createPopup })
      }, 1)
    }
  }

  date2str = (text) => {
    if (text.length === 8) {
      return text.slice(0, 2) + `/` + text.slice(2, 4) + `/` + text.slice(4)
    }
    const date = new Date(text)
    const d = date.getDate()
    const m = date.getMonth() + 1
    const a = date.getFullYear()
    return (`0` + d).slice(-2) + `/` + (`0` + m).slice(-2) + `/` + a
  }

}

