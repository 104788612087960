
import * as d3 from 'd3'

export {
  number2str,
  createChartSvg
}

function number2str(num, decimals=3) {
  return num.toFixed(decimals).replace(`.`,`,`).replace(/(\d)(\d{3}),/, `$1.$2,`)
}

function createChartSvg(element, dataset) {
  const root   = d3.select(element)
  const width  = 530
  const height = 220
  const margin = {
    top: 20, right: 20, bottom: 30, left: 60
  }
  const xScale = d3.scaleBand()
                    .rangeRound([0, width])
                    .padding(0.1)
                    .domain(dataset.map(d => d[0]))
  const yScale = d3.scaleLinear()
                    .rangeRound([height, 0])
                    .domain([0, d3.max(dataset, d => Math.max(d[1], d[2]))])
  //
  const hintId = `thermometer-hint`
  root.html(``)
  let timer = 0
  function hideHintNow() {
    const hint = document.getElementById(hintId)
    if (hint) {
      hint.style.opacity = 0
    }
  }
  function showHint(html, className=``) {
    clearTimeout(timer)
    let hint = document.getElementById(hintId)
    if (!hint) {
      hint = document.createElement(`div`)
      hint.setAttribute(`id`, hintId)
      document.body.appendChild(hint)
    }
    hint.className = `Tooltip-hint ` + className
    hint.innerHTML = html
    const left  = d3.event.pageX + 5
    const top   = d3.event.pageY - 35
    const style = `opacity:1;left:${left}px;top:${top}px`
    hint.setAttribute(`style`, style)
  }
  function hideHint() {
    clearTimeout(timer)
    timer = setTimeout(hideHintNow, 600)
  }
  //
  const svg = root.append(`svg`)
                .attr(`width`, width + margin.left + margin.right)
                .attr(`height`, height + margin.top + margin.bottom)
  const g = svg.append(`g`)
                .attr(`transform`, `translate(` + margin.left + `,` + margin.top + `)`)
  // axis x
  g.append(`g`)
    .attr(`class`, `axis axis--x`)
    .attr(`transform`, `translate(0,` + height + `)`)
    .call(d3.axisBottom(xScale).tickFormat(d => dataset.length < 25 ? d : (d % 5 === 0 || d === 1 ? d : ``)))
  // axis y
  g.append(`g`)
    .attr(`class`, `axis axis--y`)
    .call(d3.axisLeft(yScale).ticks(7))
  //
  const bar = g.selectAll(`rect`)
                .data(dataset)
                .enter().append(`g`)
  // bar chart
  bar.append(`rect`)
      .attr(`x`, d => xScale(d[0]))
      .attr(`y`, d => yScale(d[2]))
      .attr(`width`, xScale.bandwidth())
      .attr(`height`, d => height - yScale(d[2]))
      .attr(`class`, `bar`)
      .on(`mouseover`, d => {
        showHint(`<span>realizado</span>` + number2str(d[2]))
      })
      .on(`mouseleave`, hideHint)
  //
  // line chart
  const line = d3.line()
                  .x(d => xScale(d[0]) + xScale.bandwidth() / 2)
                  .y(d => yScale(d[1]))
  //
  bar.append(`path`)
      .attr(`class`, `line`)
      .attr(`d`, line(dataset))
  //
  bar.append(`circle`)
      .attr(`class`, `dot`)
      .attr(`cx`, d => xScale(d[0]) + xScale.bandwidth() / 2)
      .attr(`cy`, d => yScale(d[1]))
      .attr(`r`, 2)
  //
  bar.append(`circle`)
      .attr(`class`, `dot-hint`)
      .attr(`cx`, d => xScale(d[0]) + xScale.bandwidth() / 2)
      .attr(`cy`, d => yScale(d[1]))
      .attr(`r`, 8)
      .on(`mouseover`, d => {
        showHint(`<span>meta</span>` + number2str(d[1]), `white`)
      })
      .on(`mouseleave`, hideHint)
}


