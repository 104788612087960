import React, { useState, useEffect } from 'react'
import './TagList.scss'

export default function TagList({ tags, onClick = () => undefined, parentsVisible }) {

  const [visible, setVisible] = useState(false)

  const tooltipVisibleValidation = [
    { tagName: 'AIS', isAbbreviation: true, tooltipValue: 'Área Integrada de Segurança' },
    { tagName: 'Biometria', isAbbreviation: false, tooltipValue: '' },
    { tagName: 'Câmeras', isAbbreviation: false, tooltipValue: '' },
    { tagName: 'CICCM', isAbbreviation: true, tooltipValue: 'Centro Integrado de Comando e Controle Móvel' },
    { tagName: 'Civil', isAbbreviation: false, tooltipValue: '' },
    { tagName: 'Criminal', isAbbreviation: false, tooltipValue: '' },
    { tagName: 'DETRAN', isAbbreviation: true, tooltipValue: 'Departamento Estadual de Trânsito' },
    { tagName: 'Enel', isAbbreviation: false, tooltipValue: '' },
    { tagName: 'Facções', isAbbreviation: false, tooltipValue: '' },
    { tagName: 'Filiações', isAbbreviation: false, tooltipValue: '' },
    { tagName: 'FUNCEME', isAbbreviation: true, tooltipValue: 'Fundação Cearense de Meteorologia e Recursos Hídricos ' },
    { tagName: 'Indicadores', isAbbreviation: false, tooltipValue: '' },
    { tagName: 'INEP', isAbbreviation: true, tooltipValue: 'Instituto Nacional de Estudos e Pesquisas Educacionais Anísio Teixeira' },
    { tagName: 'Polícia', isAbbreviation: false, tooltipValue: '' },
    { tagName: 'Satélite', isAbbreviation: false, tooltipValue: '' },
    { tagName: 'SISPEN', isAbbreviation: true, tooltipValue: 'Sistema Penitenciário e Prisional' },
    { tagName: 'Tornozelados', isAbbreviation: false, tooltipValue: '' },
    { tagName: 'Viaturas', isAbbreviation: false, tooltipValue: '' },
  ]

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true)
    }, 1)
    return () => clearTimeout(timer)
  }, [])

  function handleClick(tag) {
    return () => onClick(tag)
  }

  function tagKey(tag) {
    return (tag.parents || []).concat(tag.value || ``).join(`.`)
  }

  const [tooltipTagVisibility, setTooltipTagVisibility] = useState({
    activeTooltip: null,
    state: false
  });

  function showTooltipTag(index) {
    setTooltipTagVisibility({ activeTooltip: index, state: !tooltipTagVisibility.state })
  }

  function tooltipKey(tooltip) {
    return Object.values(tooltip).toString()
  }

  return (
    <div className={`SearchPanel-BodyOptions-TagList` + (visible ? `` : ` hidden`)}>
      {tags.map((tag, index) => (
        <button key={tagKey(tag)}
          onClick={handleClick(tag)}
          onMouseOver={(e) => showTooltipTag(index)}
          onMouseOut={(e) => showTooltipTag(index)}
        >
          <div className="content">
            {parentsVisible && tag.parents && tag.parents.length > 0 &&
              <div className="parent">
                {`${tag.parents[tag.parents.length - 1]}`}
              </div>
            }
            {tag.value}
            {tooltipVisibleValidation.map(item => {
              if ((item.tagName === tag.value) & (item.isAbbreviation === true)) {
                return <span key={tooltipKey(item)} className={(tooltipTagVisibility.state) & (index === tooltipTagVisibility.activeTooltip) ? "tooltip-tag-message" : "tooltip-hidden"}>{item.tooltipValue}</span>
              } else {
                return <span key={tooltipKey(item)} className="tooltip-hidden" />
              }
            })}
          </div>
        </button>
      ))}
    </div>
  )

}
