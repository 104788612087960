import React from "react";

const Link = ({ className, to, children, target, rel }) => {
  const clickHandler = (event) => {
    event.preventDefault();
    if (target === "_blank") {
      window.open(to, target, "noopener,noreferrer");
    } else if (window.location.pathname !== to) {
      window.history.pushState(null, null, to);
      const popStateEvent = new PopStateEvent("popstate");
      window.dispatchEvent(popStateEvent);
    }
  };

  return (
    <a
      key={to}
      className={className}
      href={to}
      target={target}
      rel={rel}
      onClick={clickHandler}
    >
      {children}
    </a>
  );
};

export default Link;
