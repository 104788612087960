import { publish } from "./services/EventBus";
import { notifyError } from "./services/ExceptionToServer";

export default function boot() {

  // subscribe to show errors
  // subscribe('error', (type, error) => errorHandler(error));

  // notify global errors to backend
  window.addEventListener(`error`, (e) => {
    notifyError(e.error.message, e.error.stack).then(status => {
      publish(`error`, `Erro ao executar alguma função da aplicação e já foi reportado.`);
    });
  });

  // notify global ESC keydown event
  window.addEventListener(`keydown`, (e) => {
    if (e.keyCode === 27) {
      publish(`event::keyup.esc`);
    }
  });

};