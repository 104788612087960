import React, { Component } from 'react'
import { List } from '../../../UI/List'
import SwitcherOnOff from '../../../UI/SwitcherOnOff'
import { SearchFilterWords } from '../../../UI/SearchFilterWords'
import { onlineObjects } from '../../../../helpers/onlineObjects'
import util from '../../../../helpers/util'
import './index.scss'

export class CardTornozeladosOnline extends Component {

  state = {
    connected: [],
    error: null,
    items: [],
    total: 0
  }

  componentDidMount() {
    const click = this.props.roles.includes(`CEREBRUM_MONITORADOS_DETALHES`) ? this.clickPopup : undefined
    this.props.setTitle(`TORNOZELADOS ONLINE`)
    this.objs = onlineObjects(this, `/justice-monitor/online`, {
      color: `#F4C94C`,
      popup: this.createPopup,
      title: `Tornozelados`,
      click,
      frame2obj: this.wsFrameToObj,
      onChange: this.wsChanged
    })
  }

  componentWillUnmount() {
    this.objs.disable()
  }

  wsChanged = ({connected, error, items, total}) => {
    if (connected && total === 0) {
      this.props.show()
    }
    const cintaVioladas = items.filter(i => i.flag).length
    items.sort((a,b) => {
      if (a.error) {
        return +1
      } else if (b.error) {
        return -1
      } else if (a.flag && b.flag) {
        return b.date - a.date
      } else if (a.flag && !b.flag) {
        return -1
      } else if (b.flag && !a.flag) {
        return +1
      } else {
        return b.date - a.date
      }
    })
    this.setState({connected, error, items, total, cintaVioladas})
  }

  wsFrameToObj = frame => {
    const { code, position, speed, strapViolated, error } = frame
    const flag = strapViolated ? `CINTA VIOLADA` : ``
    const date = new Date(frame.date)
    const dateS = util.date2time(date, true)
    const query = [code, flag, dateS, error].join(` `).toUpperCase()
    return {
      id: code, code, name: code, point: position, speed, date, dateS, flag, query, error
    }
  }

  createPopup = ({name, dateS, flag}) => {
    return {title: flag ? `${name} - ${flag}` : name, text: dateS}
  }

  clickPopup = ({code}) => {
    this.props.openCard(`CardTornozeladosDetalhe`, {id: code})
  }

  changedOnlyRecents = value => {
    this.objs.setLastMinute(value)
  }

  changeWords = words => {
    this.objs.setFilter(words)
  }

  setEffect = value => {
    this.objs.setEffect(value)
  }

  render() {
    return (
      <div className="CardTornozeladosOnline">
        <div className="card-title">
          TORNOZELADOS <span>online</span>
        </div>
        { this.renderHeader() }
        { this.renderStatusBar() }
        { this.renderBody() }
      </div>
    )
  }

  renderHeader() {
    const { items, total } = this.state
    return (
      <div className="filter-container">
        <div className="row">
          <SwitcherOnOff label="SINAL BRILHANDO" onChange={this.setEffect} />
          <div className="zoom-button" onClick={() => this.objs.resetZoomMap()}>VISÃO GERAL</div>
        </div>

        <div className="row">
          <SwitcherOnOff label="ÚLTIMO MINUTO" onChange={this.changedOnlyRecents} />
          <SearchFilterWords placeholder="Filtro..." subtotal={items.length} total={total} onChange={this.changeWords} />
        </div>
      </div>
    )
  }

  renderStatusBar() {
    const { cintaVioladas, items } = this.state
    if (items.length === 0) {
      return null
    }
    const plural = cintaVioladas && cintaVioladas > 1 ? `S` : ``
    let msg = (!cintaVioladas ? `NENHUMA` : `${cintaVioladas}`) + ` CINTA${plural} VIOLADA${plural}`
    return (
      <div className={ `status-bar` + (cintaVioladas ? ` alert` : ``) }>
        { msg }
      </div>
    )
  }

  renderBody() {
    const { connected, error, items, total } = this.state
    if (error) {
      return <p className="message error">{ error }</p>
    }
    if (connected && items.length === 0 && total === 0) {
      return <p className="message">aguardando coletas...</p>
    }
    if (items.length === 0) {
      return <p className="message">nenhum item encontrado</p>
    }
    return <List card={ this } rows={ items } width={ 440 } height={ 400 } rowHeight={ 25 } highlightMarker={ true } zoomOnClick={ 15 }
			onClickRow={this.clickPopup}
      renderHeader={ () => (
        <React.Fragment>
          <div className="column code">Código</div>
          <div className="column right">Velocidade</div>
          <div className="column right">Horário</div>
        </React.Fragment>
      )}
      renderRow={ i => (
        <div className={ `item-row` + (i.error ? ` error` : ``) + (i.point ? `` : ` no-pointer`) }>
          <span className="column code">
            { i.code }
            { i.flag && !i.error ? <span key={ 1 }>{ i.flag }</span> : null }
            { i.error ? <span key={ 2 } className="msg">{ i.error }</span> : null }
          </span>
          { i.error ? null : <span key={ 3 } className="column right">{ i.speed } km/h</span> }
          { i.error ? null : <span key={ 4 } className="column right">{ i.dateS }</span> }
        </div>
      )}
    />
  }

}
