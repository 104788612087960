import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { publish, subscribe } from '../../../services/EventBus'
import './User.scss'

export default class HeaderRightUser extends Component {

  state = {
    menuVisible: false,
    username: ``,
    image: ``,
    email: ``
  }

  componentDidMount() {
    subscribe(`auth::login`, (topic, data) => {
      const { name, image, cpf } = data.client
      const s = name.split(` `)
      const first = s[0]
      const last = s.slice(-1)[0]
      const username = first !== last ? first + ` ` + last : first
      this.setState({username, image, cpf})
    })
    setTimeout(_ => {
      ReactDOM.findDOMNode(this).classList.add(`visible`)
    }, 1800);
  }

  render() {
    const { username, cpf } = this.state
    return (
      <div className="HeaderRightUser" onMouseEnter={ this.showMenu } onMouseLeave={ this.hideMenu }>
        <div className="username">
          <span className="name">{ username }</span>
          <div className="organization">{ cpf }</div>
        </div>
        { this.renderPhoto() }
        { !this.props.disableActions && this.renderDropMenu() }
      </div>
    );
  }

  renderPhoto() {
    const { image } = this.state
    const url = image ? (/^data:/.test(image) ? image : `data:image/png;base64,${image}`) : ``
    if (url)
      return <img src={ url } alt="" className="userphoto" />
    return <div className="userphoto" />
  }

  renderDropMenu() {
    const className = `dropmenu` + (this.state.menuVisible ? `` : ` hidden`)
    return (
      <div className={ className }>
        {/*
        <button onClick={ null }>
          <span className="icon settings"></span> <div>Configurações</div>
        </button>
        <button onClick={ null }>
          <span className="icon help"></span> <div>Suporte Técnico</div>
        </button>
        */}
        <button onClick={ this.logoutHandler }>
          <span className="icon exit"></span> <div>Sair</div>
        </button>
      </div>
    )
  }

  showMenu = () => {
    clearInterval(this.timer)
    this.setState({menuVisible: true})
  }
  hideMenu = () => {
    clearInterval(this.timer)
    this.timer = setTimeout(this.hideMenuNow, 500)
  }
  hideMenuNow = () => this.setState({menuVisible: false})

  logoutHandler = () => {
    this.setState({menuVisible: false})
    publish(`auth::logout`)
  }

}
