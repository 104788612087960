import React, { Component } from 'react';
import { SearchFilterWords } from '../../../UI/SearchFilterWords'
import './index.scss'

const COLORS = [`#e74c3c`, `#f1c40f`, `#2ecc71`, `#3498db`]
const ITEMS = []

export class CardFUNCEMEGeral extends Component {

  state = {
    filterWords: [],
    json: [],
    items: [],
    colors: [],
  }

  download() {
    return new Promise((resolve) => {
      if (ITEMS.length) {
        return resolve(ITEMS.map(i => ({...i})))
      }
      this.props.http.staticFiles(`/funceme/dataset.json`).then(json => {
        if (typeof json === `string`) {
          json = JSON.parse(json)
        }
        json.forEach((i, index) => {
          const item = {...i, id: index + 1}
          item.acudes.forEach(a => {
            a.regiao = {...i, acudes: undefined}
            delete a.acudes
            a.id    = a.codigo
            a.name  = a.codigo
            a.point = a.geom.coordinates
            a.color = a.estadoCor
            a.popup = this.createPopup
            a.click = this.clickPopup
            a.className = `circle`
            a.query = (a.id + ` ` + a.nome + ` ` + a.municipio + ` ` + a.regiao.nome).toUpperCase()
            ITEMS.push(a)
          })
        })
        resolve(ITEMS.map(i => ({...i})))
      })
    })
  }

  componentDidMount() {
    this.download().then(items => {
      this.props.publish(`map::layer.add`, {name: `borders`, points: items, color: `#fff`, opacity: 0.2, scale: 2.5})
      items.forEach(i => {
        this.props.publish(`map::marker.add`, i)
      })
      this.props.publish(`map::layer.zoom`, {name: `borders`})
      //
      const chart = {}
      items.forEach(i => {
        chart[i.color] = (chart[i.color] || 0) + 1
      })
      const colors = COLORS.map(color => {
        const total = chart[color]
        const width = (total / items.length * 100).toFixed(5) + `%`
        const percent = (total / items.length * 100).toFixed(1).replace(`.`,`,`) + `%`
        return {color, total, width, percent}
      })
      this.setState({json: items, items, colors}, this.props.show)
      this.props.setTitle(`FUNCEME`)
    })
  }

  createPopup = item => {
    const { nome, municipio } = item
    const title = nome === municipio ? nome : (nome + ` / ` + municipio)
    return {
      title: `AÇUDE ` + title,
      text: item.volumeAtualPerc.replace(`.`,`,`) + `%`
    }
  }

  clickPopup = item => {
    this.props.openCard(`CardFUNCEMEDetalhe`, item)
  }

  syncMap = () => {
    const { json, items } = this.state
    this.props.publish(`map::layer.update`, {name: `borders`, points: items})
    json.forEach(i => {
      const visible = items.find(item => i.id === item.id)
      this.props.publish(`map::marker.visibility`, {name: i.name, visible})
    })
    this.props.publish(`map::layer.zoom`, {name: `borders`})
  }

  clickCategoryHandler = item => {
    const { json } = this.state
    const items = json.filter(i => i.color === item.color)
    this.setState({items}, this.syncMap)
  }

  clickRowHandler = item => {
    this.props.publish(`map::zoom`, {point: item.point, zoom: 10}) 
  }

  doubleClickRowHandler = item => {
    this.clickRowHandler(item)
    this.props.openCard(`CardFUNCEMEDetalhe`, item)
  }

  filterTextHandler = (words) => {
    const { json } = this.state
    const items = words.length === 0 ? json : json.filter(i => {


      return words.map(w => 
        
        {

          let jsonNormalized = JSON.stringify(i).normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          let wordsNormalized = w.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

          if (jsonNormalized.includes(wordsNormalized)){
          return i
        }

          else {

          return i.query.includes(w)

          }


        }
        
        
        
        ).filter(_ => _).length === words.length


    })
    this.setState({filterWords: words, items}, this.syncMap)
  }

  render() {

    if (this.props.hidden) {
      return null
    }
    const { json } = this.state
    return (
      <div className="CardFUNCEMEGeral">
        <div className="title">FUNCEME <span>{ json.length } AÇUDES</span></div>
        { this.renderSearch() }
        { this.renderTable() }
      </div>
    )
  }

  renderSearch() {
    const { json, items, colors } = this.state
    const total = json.length
    const subtotal = items.length

    return (
      <div className="chart colors">
        {colors.map(i => (
          <div key={ i.color } className="bar" style={{width: i.width, background: i.color}}>
            <span className="value" onClick={ this.clickCategoryHandler.bind(this, i) }>{ i.percent }</span>
          </div>
        ))}
          
        <SearchFilterWords className = {items.filter(_ => !_.hidden).length>0?"search-error":"search-error"} subtotal={ subtotal } total={ total } onChange={ this.filterTextHandler } />

      </div>
    )
  }

  renderTable() {
    const { items } = this.state
    let foundResults = items.filter(_ => !_.hidden).length

    return (
      <div className="container-table">
        <table className="pointer">
          <thead>
            <tr>
              <th className="code">CÓDIGO</th>
              <th></th>
              <th>MUNICÍPIO</th>
              <th>AÇUDE</th>
              <th>TOTAL hm³</th>
            </tr>
          </thead>
          {foundResults > 0 &&
          <tbody>{items.filter(_ => !_.hidden).map(i => (
            <tr key={ i.id } className="row" onClick={ this.clickRowHandler.bind(this, i) } onDoubleClick={ this.doubleClickRowHandler.bind(this, i) }>
              <td className="code">{ i.codigo }</td>
              <td className="color">
                <div className="circle" style={{background: i.color}}></div>
              </td>
              <td className="name">{ i.municipio }</td>
              <td className="name">{ i.nome }</td>
              <td className="cap">{ i.capacidadeHectometrosCubicos.replace(`.`,`,`) }</td>
            </tr>
          ))}</tbody>
  }</table>

        {foundResults === 0 &&
        
      <div className="error-message">
     
       <center> <div>NENHUM RESULTADO ENCONTRADO</div></center>

      </div>}
        
      </div>
    )
  }

}
