import React, { Component } from 'react'
import util from '../../../../helpers/util'
import './index.scss'

export class CardSispenCriminal extends Component {

  state = {
    open: false,
    closing: false
  }

  componentDidMount() {
    this.props.show()
    this.props.setTitle(`SISPEN CRIMINAL ` + this.props.person.name)
  }

  accordionClick = () => {
    this.setState({closing: true})
    setTimeout(() => {
      this.setState({open: !this.state.open, closing: false})
    }, 100)
  }

  render() {
    if (this.props.hidden) {
      return null
    }
    return (
      <div className="CardSispenCriminal">
        <button className="accordion" onClick={this.accordionClick}>
            <div className="accordion_title">SISPEN
              <span className='accordion_base'>CRIMINAL</span>
              <span className={`dropdown_icon${!this.state.open ? ` closed` : ``}`}></span>
            </div>
        </button>
        <div className={`accordion_content${this.state.closing ? ` closing`: ``} ${this.state.open ? ` open` : ` closed`}`}>
          {
            this.state.open
            ? <>
                { this.renderCriminal() }
                { this.renderDwellingHouse() }
                { this.renderProcesses() }
              </>
            : null
          }
        </div>
      </div>
    )
  }

  renderCriminal() {
    const { criminal, prisonUnit, trialProcess } = this.props.person
    const items = [
      {name: `Prisioneiro`, value: criminal.prisonerId},
      {name: `Unidade Prisional`, value: prisonUnit.name},
      {name: `Nível de Segurança`, value: criminal.securityLevel},
      {name: `Prontuário`, value: criminal.medicalRecord},
      {name: `Origem`, value: criminal.origin},
      {name: `Última Alteração`, value: trialProcess.lastMovementDate ? util.date2str(trialProcess.lastMovementDate, true) : null},
      {name: `Regime`, value: trialProcess.regime},
      {name: `Situação`, value: trialProcess.situation},
    ].filter(i => i.value)
    return (
      <div className="panel">
        { items.map((i, index) => (
          <div className="line" key={ index }>
            <div className="label">{ i.name }</div>
            <div className="value">{ i.value }</div>
          </div>
        ))}
      </div>
    )
  }

  renderDwellingHouse() {
    const json = this.props.dwellingHouse
    if (!json.type && !json.situation && (!json.peopleCount || json.peopleCount === -1)) {
      return null
    }
    const items = [
      {name: `Residência`, value: json.type + (json.situation ? (` / ${json.situation}`) : ``)},
      {name: `Ocupação Atual`, value: `` + json.peopleCount + ` moradores / ` + json.compartmentsCount + ` compartimentos` },
    ]
    return (
      <div className="panel">
        { items.map((i, index) => (
          <div className="line" key={ index }>
            <div className="label">{ i.name }</div>
            <div className="value">{ i.value }</div>
          </div>
        ))}
      </div>
    )
  }

  renderProcesses() {
    const items = this.props.processes
    if (!items || items.length === 0) {
      return <p className="message empty">NENHUM PROCESSO ENCONTRADO</p>
    }
    return (
      <React.Fragment>
        <div className="header-line">Processos</div>
        {items.map((i, index) => (
          <div className="process" key={ index }>
            <div className="line id">{ i.number }</div>
            <div className="line dates">
              { i.prisonDate ? <div className="col"><span className="value">detenção</span> { util.date2str(i.prisonDate) } { util.date2time(i.prisonDate) }</div> : null }
              { i.inclusionDate ? <div className="col"><span className="value">inclusão</span> { util.date2str(i.inclusionDate) } { util.date2time(i.inclusionDate) }</div> : null }
              { i.updateDate ? <div className="col"><span className="value">atualização</span> { util.date2str(i.updateDate) } { util.date2time(i.updateDate) }</div> : null }
            </div>
            <div className="line text">{ i.court }</div>
            <div className="line text">{ i.unit }</div>
            <div className="line value">{ i.observations }</div>
          </div>
        ))}
      </React.Fragment>
    )
  }

  renderValues(items, className=``) {
    if (!items || items.length === 0) {
      return null
    }
    return (
      <div className={ `line-values ${className}` }>
        {items.map((i, index) => (
          <div className={ `item ` + (i.className || ``) } key={ index }>
            <div className="label">{ i.name }</div>
            <div className="value">{ i.value || `-` }</div>
          </div>
        ))}
      </div>
    )
  }

}
