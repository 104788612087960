
import { MapController } from './Controller'
import * as mapPopup from './MapPopup'
import { subscribe, publish } from '../../services/EventBus'

const LAYER_TYPES = [`points`, `lines`, `circles`, `polygons`]

const markerById = {}
const layerById  = {}
const layerVisibility = {}

/**
 * retorna configuração para criar popup para layers ou markers
 */
const createPopupInfo = (data={}) => {
  if (!data.popup)
    return {}
  return {
    popup: item => mapPopup.createHtml(data.popup(item)),
    click: data.click
  }
}

subscribe(`map::marker.add`, (topic, data) => {
  const popupInfo = createPopupInfo(data)
  data.id = data.cardId + `-` + (data.name || `default`)
  if (markerById[data.id] === undefined) {
    data.element = MapController.createMarker(data, popupInfo)
    markerById[data.id] = data
  } else {
    const m = markerById[data.id]
    if (m.updated === undefined || data.updated > m.updated) {
      Object.keys(data).forEach(key => m[key] = data[key])
      m.element.setLngLat(data.point)
    }
  }
})

subscribe(`map::marker.removeById`, (topic, id) => {
  const m = markerById[id]
  if (m) {
    MapController.removeMarker(m.element)
    delete markerById[id]
  }
})

subscribe(`map::marker.remove`, (topic, {cardId, name}) => {
  const id = cardId + `-` + (name || `default`)
  const m = markerById[id]
  if (m) {
    MapController.removeMarker(m.element)
    delete markerById[id]
  }
})

subscribe(`map::marker.removeByCardId`, (topic, cardId) => {
  if (cardId && cardId.cardId) {
    cardId = cardId.cardId
  }
  Object.keys(markerById).filter(id => markerById[id].cardId === cardId).forEach(id => {
    publish(`map::marker.removeById`, id)
  })
})

subscribe(`map::marker.visibility`, (topic, {cardId, name, visible}) => {
  Object.values(markerById).forEach(m => {
    if (m.cardId === cardId && m.name === name && m.element) {
      m.element.getElement().hidden = !visible
      m.hidden = !visible
    }
  })
})

subscribe(`map::layer.add`, (topic, data) => {
  LAYER_TYPES.forEach(type => {
    if (data[type] !== undefined) {
      data.type = type
      data.data = data[type]
      delete data[type]
    }
  })
  data.id = data.cardId + `-` + (data.name || `default`)
  data.element = MapController.createLayer(data, createPopupInfo(data))
  layerById[data.id] = data
  //
  let visible = data.hidden === undefined ? true : data.hidden
  if (layerVisibility[(data.group||{}).title] === false)
    visible = false
  MapController.showLayer(data.id, visible)
})

subscribe(`map::layer.update`, (topic, data) => {
  const id = data.cardId + `-` + (data.name || `default`)
  const layer = layerById[id]
  if (layer) {
    if (data[layer.type] !== undefined) {
      layer.data = data[layer.type]
      MapController.updateLayer({id, type: layer.type, data: layer.data})
    }
    if (data.className !== undefined) {
      if (data.className.includes(`offline`)) {
        layer.element.classList.add(`offline`)
      } else {
        layer.element.classList.remove(`offline`)
      }
    }
    if (data.hidden === true || data.hidden === false) {
      layer.hidden = data.hidden
      const visible = layerVisibility[(layer.group || {}).title] === false ? false : !layer.hidden
      MapController.showLayer(layer.id, visible)
    }
  }
})

subscribe(`map::layer.visibilityByTitle`, (topic, layer) => {
  const { title, hidden } = layer
  layerVisibility[title] = !hidden
  Object.values(layerById).filter(i => i.group && i.group.title === title).forEach(layer => {
    const visible = hidden ? false : !layer.hidden
    MapController.showLayer(layer.id, visible)
  })
})

subscribe(`map::layer.visibility`, (topic, data) => {
  const id = data.cardId + `-` + (data.name || `default`)
  const layer = layerById[id]
  if (layer) {
    layer.hidden = data.hidden
    const visible = layerVisibility[(layer.group ||{}).title] === false ? false : !data.hidden
    MapController.showLayer(id, visible)
  }
})

subscribe(`map::layer.color`, (topic, data) => {
  const { cardId, name, color } = data
  const layer = cardId + `-` + (name || `default`)
  MapController.setColor(layer, color)
})

subscribe(`map::layer.opacity`, (topic, data) => {
  const { cardId, name, opacity } = data
  const layer = cardId + `-` + (name || `default`)
  MapController.setOpacity(layer, opacity)
})

subscribe(`map::layer.remove`, (topic, layer) => {
  const { cardId, name } = layer
  if (name) {
    const id = cardId + `-` + (name || `default`)
    if (layerById[id]) {
      MapController.removeLayer(id)
      publish(`map::layer.removed`, {...layerById[id]})
      delete layerById[id]
    }
  }
})

subscribe(`map::layer.removeByCardId`, (topic, cardId) => {
  const layers = Object.values(layerById) || []
  layers.forEach(layer => {
    if (layer.cardId === cardId) {
      MapController.removeLayer(layer.id)
      publish(`map::layer.removed`, {...layer})
      delete layerById[layer.id]
    }
  })
})

subscribe(`map::layer.clear`, (topic, {cardId, name}) => {
  const id = cardId + `-` + (name || `default`)
  const layer = layerById[id]
  if (layer) {
    layer.data = []
    MapController.updateLayer({id, type: layer.type, data: layer.data})
  }
})

subscribe(`map::marker.zoom`, (topic, {cardId}) => {
  const points = Object.values(markerById).filter(i => i.cardId === cardId && !i.hidden).map(i => i.point)
  if (points.length)
    MapController.flyToPoints(points)
})

subscribe(`map::zoom`, (topic, {point, zoom, radius}) => {
  MapController.flyTo(point, zoom, radius)
})

subscribe(`map::layer.zoom`, (topic, {cardId, name, maxZoom}) => {
  const layers = []
  if (name) {
    const id = cardId + `-` + (name || `default`)
    layers.push(layerById[id])
  } else {
    Object.values(layerById).forEach(l => {
      if (l.cardId === cardId) {
        layers.push(l)
      }
    })
  }
  const points = layers
    .filter( layer => !!layer )
    .reduce((acc, layer) => [ ...acc, ...extractPointsFromLayer(layer) ], [])
  if (points.length > 0) {
    MapController.flyToPoints(points, {maxZoom})
  }
})

subscribe(`map::card.zoom`, (topic, {cardId}) => {
  let points = []
  Object.values(markerById).filter(i => i.cardId === cardId && !i.hidden).forEach(i => {
    points.push(i.point)
  })
  if (points.length) {
    return MapController.flyToPoints(points)
  }
  Object.values(layerById).filter(i => i.cardId === cardId && !i.hidden).forEach(i => {
    const groups = extractPointsFromLayer(i)
    if (groups) {
      groups.forEach(p => points.push(p))
    }
  })
  if (points.length) {
    MapController.flyToPoints(points)
  }
})

subscribe( `map::layer.addImage`, (topic, data) => {
	MapController.createImageLayer(data)
	MapController.showLayer(data.layerId, true)
	layerById[data.layerId] = { id: data.layerId }
})

subscribe( `map::layer.addGeoJson`, (topic, data) => {
	MapController.createGeoJsonLayer(data)
	MapController.showLayer(data.layerId, true)
	layerById[data.layerId] = { id: data.layerId }
	layerById[data.layerId + 'line'] = { id: data.layerId + 'line' }
})

subscribe(`map::layer.removeGeoJson`, (topic, layer) => {
  const { cardId, name } = layer
  if (name) {
    const id = cardId + `-` + (name || `default`)
    if (layerById[id]) {
      MapController.removeGeoJsonLayer(id)
      publish(`map::layer.removed`, {...layerById[id]})
      delete layerById[id]
    }
  }
})

function extractPointsFromLayer(layer) {
  const { type, data } = layer
  if (type === `points`) {
    return data.map(_ => _.point)
  }
  if (type === `polygons`) {
    if (data.type === `MultiPolygon`) {
      let points = []
      data.coordinates.forEach(polygon => {
        polygon.forEach(coords => points = points.concat(coords))
      })
      return points
    }
  }
}

