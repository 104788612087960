import React, { Component } from 'react'
import { List } from 'react-virtualized'
import { SearchListManager } from '../../../../helpers/searchListManager'
import { SearchFilterWords } from '../../../UI/SearchFilterWords'
import MapCircleSelection from '../../../UI/MapCircleSelection'
import WS from '../../../../services/websocket'
import util from '../../../../helpers/util'
import config from '../../../../config'
import { App } from '../../../../App'
import './index.scss'

const MAP_SELECTION_MAX_RADIUS = 5000

export class CardViaturasHistoricoArea extends Component {

  search = new SearchListManager()

  state = {
    circle: null, // {center: [], radius: 0}
    filterText: ``,
    selected: null,
    rows: [],
  }

  componentDidMount() {
    this.props.setTitle(`VIATURAS POR RAIO ` + this.getTimeTitle())
    this.props.show()
  }

  componentWillUnmount() {
    if (this.websocket) {
      this.websocket.disconnect()
      this.clearMap()
    }
  }

  extractQueryParams = () => {
    const { type, date, startDateTime, endDateTime } = this.props
    const { circle } = this.state
    let query = {}
    if (type === `date`) {
      query = {date: util.date2sql(date)}
    } else if (type === `startDateTime`) {
      query = {startDateTime: util.datetime2iso(startDateTime)}
    } else if (type === `rangeDateTime`) {
      query = {startDateTime: util.datetime2iso(startDateTime), endDateTime: util.datetime2iso(endDateTime)}
    }
    if (circle) {
      const { center, radius } = circle
      query = {...query, longitude: center[0], latitude: center[1], radius}
    }
    return query
  }

  clearMap = () => {
    this.search.getItems().forEach(({id}) => {
      this.props.publish(`map::layer.remove`, {name: `trace${id}`})
      this.props.publish(`map::layer.remove`, {name: `pts${id}`})
    })
  }

  createURLWebSocket = (path=``, params={}) => {
    params = {...App.headers, ...params}
    const host = config.gatewayAppWebsocket
    return host + path + `?` + Object.entries(params).map(i => i.join(`=`)).join(`&`)
  }

  downloadStream = () => {
    Object.keys(this.cache || {}).forEach(id => {
      this.props.publish(`map::layer.remove`, {name: `trace${id}`})
      this.props.publish(`map::layer.remove`, {name: `pts${id}`})
    })
    this.cache = {}
    const rows = this.search.setItems([]).applyFilter(``)
    this.setState({rows, hasData: false, errorMessage: null, streaming: true, streamClosed: false}, this.props.show)
    this.clearMap()
    if (this.websocket) {
      this.websocket.disconnect()
    }
    const query = this.extractQueryParams()
    this.websocket = new WS(this.createURLWebSocket(`/vehicles/area/socket`, query), {autoconnect: true, autoreconnect: false, interval: 5000, card: this})
      .on(`message`,  this.wsMessage)
      .on(`finished`, this.wsClose)
      .on(`error`,    this.wsError)
  }

  wsUpdateCache = data => {
    const { id, name, ignitionState, landmark, collectDate, position } = data
    if (!this.cache[id]) {
      this.cache[id] = {id, name, positions: []}
      const group = {color: `#08C2A2`, title: `Viaturas`}
      this.props.publish(`map::layer.add`, {name: `trace${id}`, lines: [], group, color: `#fff`, width: 3, gradient: true})
      this.props.publish(`map::layer.add`, {name: `pts${id}`, points: [], group, color: `#08C2A2`, popup: this.createPopup})
    }
    const v = this.cache[id]
    v.positions.push({id: v.positions.length + `-` + Date.now(), name, ignitionState, landmark, collectDate, position, point: position, date: new Date(collectDate)})
    v.positions.sort((a,b) => a.date - b.date)
    //
    const dateA = v.positions[0].date
    const dateB = v.positions[v.positions.length - 1].date
    v.timeRange = util.date2time(dateA) + ` ` + util.date2str(dateA) + ` - ` + util.date2time(dateB) + ` ` + util.date2str(dateB)
    v.query = id + ` ` + name + ` ` + v.timeRange
  }

  wsMessage = json => {
    // create table rows (group by)
    json.forEach(this.wsUpdateCache)
    // update layers
    json.forEach(({id}) => {
      const v = this.cache[id]
      const points = v.positions
      const lines = [points.map(_ => _.point),]
      this.props.publish(`map::layer.update`,  {name: `trace${id}`, lines})
      this.props.publish(`map::layer.update`,  {name: `pts${id}`, points})
    })
    const objs = Object.values(this.cache)
    const rows = this.search.setItems(objs).applyFilter(this.state.filterText)
    this.setState({rows, errorMessage: null, hasData: true})
  }

  wsClose = () => {
    this.setState({streaming: false, streamClosed: true, errorMessage: null})
  }

  wsError = () => {
    this.setState({streaming: false, streamClosed: true, errorMessage: `FALHA AO CONSULTAR DADOS`}, this.updateMap)
  }

  createPopup = i => {
    return {
      title: `${ i.name || i.id } ` + util.date2str(i.date) + ` ` + util.date2time(i.date),
      text: i.ignitionState + ` ` + i.landmark,
    }
  }

  getTimeTitle = () => {
    const { type, date, startDateTime, endDateTime } = this.props
    if (type === `date`)
      return ` em ` + util.date2str(date)
    if (type === `startDateTime`)
      return ` desde ` + util.date2time(startDateTime) + ` ` + util.date2str(startDateTime)
    if (type === `rangeDateTime`)
      return ` em ` + util.date2time(startDateTime) + ` ` + util.date2str(startDateTime) + ` - ` + util.date2time(endDateTime) + ` ` + util.date2str(endDateTime)
    return `-`
  }

  updateMap = () => {
    const vehicles = this.search.getItems()
    vehicles.forEach(v => {
      this.props.publish(`map::layer.update`, {name: `trace${v.id}`, hidden: v.hidden})
      this.props.publish(`map::layer.update`, {name: `pts${v.id}`, hidden: v.hidden})
    })
  }

  mapSelectionHandler = (circle) => {
    this.props.setLoading()
    this.clearMap()
    this.updateHighlightMarker(null)
    this.setState({selected: null, circle}, () => {
      this.downloadStream()
      this.props.show()
    })
  }

  filterWordsHandler = words => {
    this.updateHighlightMarker(null)
    const filterText = words.join(` `) || ``
    const rows = this.search.applyFilter(filterText)
    this.setState({rows, selected: null, filterText}, this.updateMap)
  }

  tableMouseLeaveHandler = () => {
    if (this.state.selected) {
      return
    }
    this.search.getItems().forEach(i => i.hidden = false)
    this.updateMap()
  }

  rowMouseEnterHandler = (item) => {
    if (this.state.selected) {
      return null
    }
    this.search.getItems().forEach(i => i.hidden = item.id !== i.id)
    this.updateMap()
  }

  rowClickHandler = (item) => {
    const selected = item === this.state.selected ? null : item
    this.setState({selected})
    this.search.getItems().forEach(i => i.hidden = item.id !== i.id)
    this.updateMap()
    this.updateHighlightMarker(null)
  }

  updateHighlightMarker = point => {
    this.props.publish(`map::marker.remove`, {id: `highlight`})
    if (point)
      this.props.publish(`map::marker.add`, {id: `highlight`, point, color: `#ca3`, className: `circle nobody border animated`})
  }

  cardMouseLeaveHandler = () => {
    clearTimeout(this.timer)
    this.timer = setTimeout(this.updateHighlightMarker, 2500)
  }

  render() {
    return (
      <div className="CardViaturasHistoricoArea" onMouseLeave={ this.cardMouseLeaveHandler }>
        <div className="card-title">
          VIATURAS POR RAIO <span>{ this.getTimeTitle() }</span>
        </div>
        { this.renderSummary() }
        { this.renderTable() }
      </div>
    )
  }

  renderStreamStatus() {
    const { streaming, streamClosed, errorMessage, hasData } = this.state
    if (hasData && errorMessage) {
      return <div className="stream-status error">FALHA AO CONSULTAR DADOS. INFORMAÇÃO INCOMPLETA!</div>
    }
    if (!errorMessage) {
      if (streaming === false && streamClosed === true) {
        return <div className="stream-status done">HISTÓRICO DE TRAJETÓRIAS RECUPERADO</div>
      } else if (streaming === true && !streamClosed) {
        return <div className="stream-status blink">RECUPERANDO HISTÓRICO DE TRAJETÓRIAS...</div>
      }
    }
    return null
  }

  renderSummary() {
    const { rows } = this.state
    const total = this.search.getTotal()
    return (
      <div className="summary">
        <MapCircleSelection maxRadius={ MAP_SELECTION_MAX_RADIUS } onChange={ this.mapSelectionHandler } />
        <SearchFilterWords subtotal={ rows.length } total={ total } onChange={ this.filterWordsHandler } />
        { this.renderStreamStatus() }
      </div>
    )
  }

  renderTable() {
    const { circle, streaming, streamClosed, selected, rows, hasData, errorMessage } = this.state
    if (!circle) {
      return <p className="message empty">aguardando definição de área no mapa...</p>
    }
    if (streaming && rows.length === 0) {
      return <p className="message empty">carregando...</p>
    }
    if (errorMessage && !hasData) {
      return <p className="message empty">{ errorMessage }</p>
    }
    if (rows.length === 0) {
      return <p className="message empty">nenhum dado encontrado</p>
    }
    return (
      <div className={ `table-list` + (streamClosed ? `` : ` no-interaction`) + (selected ? ` selected` : ``) } onMouseLeave={ this.tableMouseLeaveHandler }>
        <div className="row header">
          <div className="col code">CÓDIGO</div>
          <div className="col name">NOME</div>
          <div className="col pts">COLETAS</div>
          <div className="col time">PERÍODO</div>
        </div>
        { selected ? this.renderRowSelected() : <List width={ 570 } height={ 250 } rowHeight={ 30 } rowCount={ rows.length } rowRenderer={ this.renderRow } /> }
      </div>
    )
  }

  renderRow = ({index, key, style}) => {
    const { selected } = this.state
    const i = this.state.rows[index]
    return (
      <div key={ i.id } style={ style } className={ `row` + (selected && selected.id === i.id ? ` selected` : ``) } onClick={ this.rowClickHandler.bind(this, i) } onMouseEnter={ this.rowMouseEnterHandler.bind(this, i) }>
        <div className="col code">{ i.id }</div>
        <div className="col name">{ i.name }</div>
        <div className="col pts">{ i.positions.length }</div>
        <div className="col time">{ i.timeRange }</div>
        <div className="col close"></div>
      </div>
    )
  }

  renderRowSelected = () => {
    const { selected } = this.state
    return (
      <React.Fragment>
        <div className="row selected" onClick={ this.rowClickHandler.bind(this, selected) }>
          <div className="col code">{ selected.id }</div>
          <div className="col name">{ selected.name }</div>
          <div className="col pts">{ selected.positions.length }</div>
          <div className="col time">{ selected.timeRange }</div>
          <div className="col close">-</div>
        </div>
        <div className="table-collects-list">
          <List width={ 570 } height={ 210 } rowHeight={ 30 } rowCount={ selected.positions.length } rowRenderer={ this.renderCollectRow } />
        </div>
      </React.Fragment>
    )
  }

  renderCollectRow = ({index, key, style}) => {
    const i = this.state.selected.positions[index]
    return (
      <div key={ i.id } style={ style } className="row collect" onMouseEnter={ this.updateHighlightMarker.bind(this, i.point) }>
        <div className="col time">{ util.date2time(i.date, true) }</div>
        <div className="col date">{ util.date2str(i.date) }</div>
        <div className="col land">{ i.landmark }</div>
        <div className="col stat">{ i.ignitionState }</div>
      </div>
    )
  }

}

