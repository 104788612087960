import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import registerServiceWorker from './registerServiceWorker';
import boot from './boot';
import { App } from './App';
import './index.scss'
import './fonts.scss'


ReactDOM.render(<App />, document.getElementById('root'));

boot();

registerServiceWorker();

/**
 * desabilitando logs quando em produção ou stage
 */
 if (process.env.REACT_APP_ENV === `production` || process.env.REACT_APP_ENV === `stage`) {
  console.log = console.warn = () => 0
}
