import React, { Component } from 'react'
import util from '../../../../helpers/util'
import srcImageAvatar from '../../../../images/avatar.png'
import './Detalhe.scss'


const Item = (props) => (
  <div className="container-item">
    <div className={`item ` + (props.className || ``)}>
      <label>{props.label}</label>
      <div className="value">{props.children || `-`}</div>
    </div>
  </div>
)

const createGroupRows = (...params) => {
  const g = []
  for (let i = 0; i < params.length - 1; i += 2) {
    const [label, value] = [params[i], params[i + 1]]
    if (value) {
      g.push({ label, value })
    }
  }
  return g
}

export default class Detalhe extends Component {

  state = {
    picture: false,
    groups: []
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.hidden && prevState.picture) {
      return { ...prevState, picture: false }
    }
    return null
  }

  getRG = () => {
    const json = this.props.rgInfo
    let rg = json.rg + ` ` + json.orderType + ` / ` + json.station
    rg += json.identityDate ? ` ${util.date2str(json.identityDate)}` : ``
    if (json.expirationDate) {
      rg += ` válido até ` + util.date2str(json.expirationDate)
    }
    return rg
  }

  getSex = () => {
    if (this.props.basicInfo.sex === `F`) return `FEMININO`
    if (this.props.basicInfo.sex === `M`) return `MASCULINO`
    return `-`
  }

  componentDidMount() {
    const groups = []
    const doc = this.props.documentInfo
    const p = this.props
    //
    groups.push(createGroupRows(
      `RG`, this.getRG(),
      `RG 2ª via informada`, p.rgInfo.rgReissue === p.rgInfo.rg ? null : p.rgInfo.rgReissue,
      `RG de outro estado`, doc.anotherIdentityDocument ? (doc.anotherIdentityDocument + ` ` + doc.anotherIdentityEmitter.toUpperCase() + ` ` + doc.anotherIdentityState) : null,
      `CPF`, doc.cpf ? util.formatCPF(doc.cpf) : null,
      `CNH`, doc.cnh,
      `CTPS`, doc.ctpsNumber ? (doc.ctpsNumber + ` ` + doc.ctpsSeries + ` ` + doc.ctpsFederalUnit) : null,
      `CNS`, doc.cns,
      `NIS`, doc.nis,
      `DNI`, doc.dni,
      `Eleitor`, doc.voterTitle && doc.voterTitle !== `0` ? (`${doc.voterTitle} zona ${doc.electoralZone || 0} seção ${doc.electoralSection || 0}`) : null,
      `Reservista`, doc.militaryNumber,
    ))
    //
    groups.push(createGroupRows(
      `Nome Social`, p.basicInfo.socialName,
      `Data Nascimento`, util.date2str(p.basicInfo.birthDate) + ` - ` + util.calcAge(p.basicInfo.birthDate) + ` anos`,
      `Local de Nascimento`, p.basicInfo.birthCityStateCountry,
      `Sexo`, this.getSex(),
      `Estado Civil`, p.basicInfo.maritalStatus,
      `Nacionalidade`, p.basicInfo.nationality,
      `Sangue`, p.basicInfo.bloodType,
      `Cônjuge`, p.basicInfo.spouse,
    ))
    //
    groups.push(createGroupRows(
      `Mãe`, p.basicInfo.mother,
      `Pai`, p.basicInfo.father
    ))
    //
    if (Object.keys(p.foreignInfo || {}).length > 0) {
      groups.push(createGroupRows(
        `Processo Migração`, p.foreignInfo.process,
        `Portaria`, p.foreignInfo.ministerial + ` / ` + p.foreignInfo.year,
      ))
    }
    //
    groups.push(createGroupRows(
      `Documento`, doc.type,
      `Certidão`, (doc.certificateTerm || ``) + ` ` + util.date2str(doc.certificateDate) + ` ` + doc.certificateRegistryOffice,
      `Livro`, doc.certificateBook + ` pág.` + doc.certificateSheet,
      `Local`, doc.certificateCity,
      `Divórcio`, doc.certificateDivorce
    ))
    //
    const antro = this.props.anthropometrics
    if (antro && Object.keys(antro).length > 1) {
      groups.push(createGroupRows(
        `Altura`, antro.height ? `${antro.height.toFixed(2)}m` : null,
        `Pele`, antro.skinColor,
        `Olhos`, [antro.eyeColor || ``, antro.eyeType || ``].join(` `),
        `Cabelo`, [antro.hairColor || ``, antro.hairType || ``].join(` `)
      ))
    }
    //
    const { professionalIdentity, secondProfessionalIdentity, thirdProfessionalIdentity } = p.professionalInfo
    const jobs = [professionalIdentity, secondProfessionalIdentity, thirdProfessionalIdentity].filter(_ => _)
    groups.push(createGroupRows(
      jobs.length > 1 ? `Identidades Profissionais` : `Identidade Profissional`, jobs.join(` / `),
      `Escolaridade`, p.professionalInfo.educationDegree,
      `Ocupação`, p.professionalInfo.occupation,
    ))
    //
    if (this.props.contactInfo) {
      const phones = this.props.contactInfo.map(i => (i.replace(/^0+$/, ``))).filter(_ => _)
      if (phones.length) {
        groups.push(createGroupRows(
          `Contato`, phones.map(util.formatPhone).join(` / `)
        ))
      }
    }
    if (this.props.addressInfo) {
      let logr = p.addressInfo.address
      if (p.addressInfo.number) {
        logr += `, ` + p.addressInfo.number
      }
      if (p.addressInfo.complement) {
        logr += ` ` + p.addressInfo.complement
      }
      groups.push(createGroupRows(
        `Endereço`, logr,
        `Bairro`, p.addressInfo.district,
        `CEP`, p.addressInfo.cep === `0` ? null : p.addressInfo.cep,
        `Cidade`, p.addressInfo.cityStateCountry
      ))
    }
    this.setState({ groups })
  }

  openAffiliations = () => {
    const { mother, father } = this.props.basicInfo
    this.props.openCard(`CardBuscaFonetica`, { mother, father })
  }

  render() {
    if (this.props.hidden) {
      return <div className="Detalhe hidden"></div>
    }
    const { hidden } = this.props
    const { picture } = this.state
    return (
      <div className={`Detalhe ` + (hidden ? `hidden ` : ``) + (picture ? ` picture-mode` : ``)}>
        <div className="columns">
          <div className="column photo">
            {this.renderPicture()}
          </div>
          <div className="column info">
            <div className="row">
              <Item label="Nome">{this.props.basicInfo.name}</Item>
            </div>

            {this.renderGroups()}
          </div>
        </div>
      </div>
    )
  }

  renderGroups = () => {
    const { groups } = this.state
    return (
      <div className="group-info">
        {groups.map((group, index) => this.renderGroup(group, index))}
      </div>
    )
  }

  renderGroup = (group = [], index = 0) => {
    const { roles } = this.props
    const allowSearchAffiliations = roles.includes(`CEREBRUM_BUSCA_CIVIL_AFILIACOES`) || roles.includes(`CEREBRUM_BUSCA_CRIMINAL_AFILIACOES`)
    if (index === 2) {
      return (
        <div key={`c` + index} className={`clickable` + (allowSearchAffiliations ? `` : ` blocked`)} onClick={this.openAffiliations}>
          {this.renderGroup(group)}
        </div>
      )
    }
    return (
      <div className="group-info" key={index}>
        {group.map((i, key) => (
          <div key={key}>
            <Item label={i.label}>{i.value}</Item>
          </div>
        ))}
      </div>
    )
  }

  renderPicture = () => {
    const defaultImage = srcImageAvatar
    const url = this.props.picture || defaultImage
    const src = /^data/.test(url) || /\.\w{3,4}$/.test(url) ? url : (`data:image/png;base64,` + url)
    const isDefault = src === defaultImage
    return (
      <div className={`picture` + (isDefault ? ` empty` : ``)} onClick={this.togglePictureSize}>
        <img src={src} alt="" />
      </div>
    )
  }

  togglePictureSize = () => {
    const { picture } = this.state
    this.setState({ picture: !picture })
  }

}
