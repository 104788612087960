import React, { Component } from 'react';
import config from '../../../../config'
import './index.scss'
import { App } from '../../../../App'

const DEFAULT_QUALITY = 50
const MAX_QUALITY = 100
const QUALITIES = [ 
  // sorted
  { value: 25, label: 'BAIXA' },
  { value: 50, label: 'NORMAL' },
  { value: 75, label: 'ALTA' },
  { value: 100, label: 'MÁXIMA' },
]

const videoUrl = (id, quality) => {
  const baseUrl = config.gatewayApp
  const params = { ...App.headers, quality }
  const query = Object.entries(params).map(i => i.join(`=`)).join(`&`)
  return `${baseUrl}/surveillance/stream/camera/${id}?${query}`
}

function getInitialQuality() {
  return config.isThroughtIp ? MAX_QUALITY : DEFAULT_QUALITY
}

export class CardCameraStream extends Component {

  static ROLES = [`CEREBRUM_CAMERAS_STREAM`]

  state = {
    message: `CONECTANDO...`
  }

  constructor(props) {
    super(props)
    this.imgRef = React.createRef()
  }

  componentDidMount() {
    const { poleId, point, main, camera } = this.props
    if (main && point) {
      this.props.publish(`map::marker.add`, {point, color: `#1DAED9`, className: `circle border`})
    }
    const { id, name, type } = camera
    const quality = getInitialQuality()
    this.setState({ poleId, id, name, type, quality })
    this.props.show()
    this.props.setTitle(`CÂMERA ${id || '---'} ${type || ''} POSTE ${poleId}`)
  }

  componentWillUnmount() {
    const img = this.imgRef.current
    if (img) {
      img.setAttribute(`src`, `#`)
    }
  }

  imageErrorHandler = () => {
    this.setState({ message: `INDISPONIBILIDADE NO SERVIÇO EXTERNO DE VÍDEO` })
  }

  imageLoadedHandler = () => {
    this.setState({ message: ``, loaded: true })
  }

  clickFullScreenHandler = () => {
    const img = this.imgRef.current
    const methods = [`requestFullScreen`, `requestFullscreen`, `webkitRequestFullscreen`, `webkitRequestFullScreen`]
    for (const m of methods) {
      if (img[m])
        return img[m]()
    }
  }

  handleQualityChange = e => {
    this.setState({ quality: e.target.value })
  }

  render() {
    if (this.props.hidden) {
      return null
    }
    const { id, type, poleId } = this.state
    return (
      <div className="CardCameraStream">
        <div className="title">
          CÂMERA { id || `---` } { type || `` }
          <span>POSTE { poleId }</span>
        </div>
        { id ? this.renderContent() : this.renderEmpty() }
      </div>
    )
  }

  renderEmpty() {
    return <p className="error-message">nenhuma câmera instalada</p>
  }

  renderContent() {
    const { id, name, message, loaded, quality } = this.state
    return (
      <React.Fragment>
        <div className="video-container">
          { message ? <div className="message">{ message }</div> : null }
          <button className={ `ui-btn-fullscreen ` + (loaded ? `` : `hidden`) } onClick={ this.clickFullScreenHandler }></button>
          <img ref={ this.imgRef } src={ videoUrl(id, quality) } alt="" onLoad={ this.imageLoadedHandler } onError={ this.imageErrorHandler } />
        </div>
        <div className="details">
          { name ? <p>{ name }</p> : null }
          <div className="quality">
            <label>Qualidade</label>
            <div className="select-input">
              <select onChange={ this.handleQualityChange } value={ quality } >
                { this.getQualityRange().map(q => <option key={q.value} value={q.value}>{ q.label }</option> ) }
              </select>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  getQualityRange() {
    const canHq = this.props.roles.includes('CEREBRUM_CAMERAS_HQ_STREAM')
    if (canHq) {
      return QUALITIES
    } else {
      return QUALITIES.filter(q => q.value <= DEFAULT_QUALITY)
    }
  }

}
