
export const Funceme = {
  created: `2019-04-22`,
  context: `FUNCEME`,
  role: `CEREBRUM_FUNCEME`,
  title: `FUNCEME - Fundação Cearense de Meteorologia e Recursos Hídricos`,
  description: `Listagem de todos os açudes do estado do Ceará`,
  parser: () => {
    return {card: `CardFUNCEMEGeral`, props: {}}
  }
}
