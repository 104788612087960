import React, { useState } from 'react'
import { AboutPanel } from '../AboutPanel';
import { HelpPanel } from '../HelpPanel';
import './index.scss';

import { ReactComponent as AboutIcon } from '../../images/about-icon.svg';
import { ReactComponent as HelpIcon } from '../../images/help-icon.svg';

import { subscribe } from '../../services/EventBus'


export function Menu() {

    const [closePanel, setClosePanel] = useState([
        {
            id: 2,
            state: false
        },
        {
            id: 3,
            state: false
        },
    ])


    const [activeItem, setActiveItem] = useState({
        activeLink: null,
        items: [{ id: 0 }, { id: 1 }, { id: 2 }],
    });

    function handleClick(index) {
        setActiveItem({ ...activeItem, activeLink: activeItem.items[index] });

        setClosePanel(
            closePanel.map(item => 
                item.id === index-1 
                ? {...item, state : true} 
                : false
        ))
    }

    function toggleActiveStyles(index) {
        if (activeItem.items[index] === activeItem.activeLink) {
            return "icon-group-active"
        } 
        else {
            return "icon-group"
        }
    }

    subscribe(`panels::close`, () => {
        setActiveItem({...activeItem , activeLink: null})
    })

    return (
        <>
            <div className="initial-menu ">

                <div className={toggleActiveStyles(2)} onClick={(e) => { handleClick(2) }}>
                    <AboutIcon />
                    <h6>SOBRE</h6>
                </div>

                <div className={toggleActiveStyles(3)} onClick={(e) => { handleClick(3) }}>
                    <HelpIcon />
                    <h6>AJUDA</h6>
                </div>

            </div>

            <div className={closePanel[1] ? "hidden" : toggleActiveStyles(2) === 'icon-group' ? "hidden" : ""}>
                <AboutPanel handleClosePanel={value => {
                    setClosePanel(
                        closePanel.map(item => 
                            item.id === 2
                            ? {...item, state : value} 
                            : true
                            
                    ))
                    setActiveItem({...activeItem , activeLink: null})
                }} />
            </div>

            <div className={closePanel[2] ? "hidden" : toggleActiveStyles(3) === 'icon-group' ? "hidden" : ""}>
                <HelpPanel handleClosePanel={value => {
                    setClosePanel(
                        closePanel.map(item => 
                            item.id === 3
                            ? {...item, state : value} 
                            : true
                    ))
                    setActiveItem({...activeItem , activeLink: null})
                }} />
            </div>

        </>
    )
}
