import React, { Component } from 'react'
import { publish, subscribe } from '../../services/EventBus'
import './Chart.scss'

import * as d3 from 'd3'
import Model from './model'

export default class Chart extends Component {

  state = {
    visible: false,
    hidden: false,
  }

  constructor(props) {
    super(props)
    this.model = new Model()
    this.updates = 0
    this.state.elementId = `i` + Date.now()
  }

  componentDidMount() {
    setTimeout(_ => {
      if (this.removed)
        return
      this.loadModel(this.props.data)
      this.setState({visible: true})
    }, 300)
    //
    subscribe(`thermometer::indicator.period`, (topic, item) => {
      this.refs.chart.innerHTML = ``
      this.loadModel(this.props.models[item.attr])
      publish(`thermometer::indicator.selected`, null)
    })
    subscribe(`thermometer::indicator.reset`, () => {
      this.back2originalRootLevel()
    })
    subscribe(`thermometer::svg.clear`, () => {
      this.clear()
    })
  }

  componentWillUnmount() {
    this.removed = true
  }

  render() {
    const { elementId, visible, hidden } = this.state
    const clss = `Chart` + (visible ? ` visible` : ``) + (hidden ? ` hidden` : ``)
    return (
      <div id={ elementId } className={ clss }>
        { this.renderEffectCircles() }
        { this.renderChart() }
      </div>
    );
  }

  renderChart() {
    return <svg ref="chart" />
  }

  renderEffectCircles() {
    return (
      <div className="circles-effect">
        <div className="circle-effect a"></div>
        <div className="circle-effect b"></div>
      </div>
    )
  }

  loadModel(indicadores) {
    this.updates++
    this.model.load(indicadores)
    //
    this.currentDeep = undefined
    const nodes = this.model.getNodes()
    this.createDonutChart({nodes: nodes})
  }

  createDonutChart({loading, nodes}) {
    this.model.getDeeps().reverse().forEach(deep => {
      const nodes = this.model.getNodesByDeep(deep)
      this.createDonut(nodes, deep)
    })
  }

  openSubChart(selectedNodeId, selectedNodeDeep) {
    this.currentDeep = selectedNodeDeep
    const root = this.model.getNodeById(selectedNodeId)
    setTimeout(() => {
      this.refs.chart.innerHTML = ''
      const tree = this.model.findSubTree(root.id)
      this.model.getDeeps().filter(_ => _ >= selectedNodeDeep).forEach(deep => {
        let nodes = this.model.getNodesByDeep(deep).filter(_ => tree.includes(_.id))
        if (deep === selectedNodeDeep) {
          nodes = [root,]
        }
        nodes = nodes.map(i => {
          const o = Object.assign({}, i)
          o.draw  = Object.assign({}, o.draw)
          o.draw.deep = o.draw.deep - selectedNodeDeep + 2 - 1
          return o
        })
        this.createDonut(nodes, deep - selectedNodeDeep + 2 - 1, true)
      })
    }, 500)
  }

  createDonut(data, deep, isSubChart=false) {
    const width  = 400
    const height = 400
    //
    const extraMetric = isSubChart ? 8 : 0
    const deep2radius = {
      '1': 110 + extraMetric,
      '2': 140 + extraMetric,
      '3': 162 + extraMetric,
      '4': 180 + extraMetric,
      '5': 192 + extraMetric,
    }
    //
    function innerRadius(deep) {
      const v = deep2radius[deep]
      if (deep === 1)
        return v - (11 - (deep-1)*1.9) + 20
      return v - (11 - (deep-1)*1.9) // quanto maior o `deep`, menor a width
    }
    function outerRadius(deep) {
      const v = deep2radius[deep]
      if (deep === 1)
        return v + 15
      return v + (11 - (deep-1)*1.9)
    }
    //
    let created = Date.now()
    if (this.currentDeep !== undefined) // animacoes `mais rapidas`
      created -= 1500
    //
    const pie = d3.pie().value(d => d.draw.percent).sort(null)
    const arc = d3.arc()
                      .outerRadius(d => outerRadius(d.data.draw.deep))
                      .innerRadius(d => innerRadius(d.data.draw.deep))
                      .cornerRadius(0.5)
                      .padAngle(0.03)
    //
    let context = d3.select(this.refs.chart)
                  .attr(`width`,  width)
                  .attr(`height`, height)
                  .select(`g.group.deep-${deep}`)
    //
    if (context.size() === 0) {
      context = d3.select(this.refs.chart)
                  .append(`g`)
                  .attr(`class`, `group deep-${deep}`)
                  .attr(`transform`, `translate(` + (width/2) + `,` + (height/2) + `)`)
    }
    //
    context.selectAll(`.arc`)
      .data(pie(data))
      .enter()
        .append(`path`)
        .attr(`class`, d => d.data.draw.fake ? `arc fake` : `arc`)
        .style(`opacity`, 0)
        .style(`transform`, d => {
          const prefix = !d.data.draw.effect ? `scale(0.5) ` : ``
          const rotate = -360 + ((2 * 360) * Math.random())
          return prefix + `rotate(${rotate}deg)`
        })
        .style(`fill`, d => d.data.draw.color)
        .transition()
        .ease(d3.easeCircle)
        .duration(() => this.currentDeep !== undefined || this.updates > 1 ? 1000 : 1500)
        //
        .delay(d => {
          const unit = this.currentDeep !== undefined ? 0 : 500 // sub-chart deve ser mais rápido
          const time = d.data.draw.effect ? 0 : unit
          return time + parseInt(Math.random()*(unit+200))
        })
        //
        .attr(`d`, arc)
        .style(`opacity`, 1)
        .style(`transform`, d => {
          let s = ` rotate(0deg)`
          if (d.data.draw.effect) {
            const rotate = -360 + ((2 * 360) * Math.random())
            s = ` rotate(${rotate}deg)`
          }
          return `scale(1)` + s
        })
        .style(`fill`, d => d.data.draw.color)
    //
    this.removing = false
    //
    const self = this
    context.selectAll(`.arc`)
      .on(`mouseover`, function(d) {
        if (self.removing)
          return
        if (d.data.draw.fake)
          return
        if (Date.now() - created < 3000)
          return
        return self.onMouseEnterIndicador(this, d)
      })
      .on(`mouseout`, function(d) {
        if (self.removing)
          return
        if (d.data.draw.fake)
          return
        if (Date.now() - created < 3000)
          return
        return self.onMouseLeaveIndicador(this, d)
      })
      .on(`click`, function(d) {
        if (self.removing)
          return
        self.removing = true
        return self.onClickIndicador(this, d)
      })
  }

  back2originalRootLevel = () => {
    this.currentDeep = 1
    this.refs.chart.innerHTML = ''
    const nodes = this.model.getNodes()
    this.createDonutChart({nodes: nodes})
  }

  removeFadeOutClasses() {
    publish(`thermometer::indicator.back2root`)
    this.withFadeOutClass = false
    d3.select(this.refs.chart).selectAll(`.arc.fade-out`).each(function() {
      this.classList.remove(`fade-out`)
    })
  }

  addFadeOutClasses(element, d) {
    this.withFadeOutClass = true
    const nodeId = d.data.id
    const nodes  = this.model.findSubTree(nodeId)
    const arcs = d3.select(this.refs.chart).selectAll(`.arc`)
    //
    arcs.each(function(d, index) {
      if (nodes.includes(d.data.id)) {
        this.classList.remove(`fade-out`)
      } else {
        this.classList.add(`fade-out`)
      }
    })
  }

  clear = next => {
    const { elementId } = this.state
    d3.selectAll(`#${elementId} svg .arc`)
      .transition()
      .duration(200)
      .delay((d, index) => index === 0 ? 0 : parseInt(Math.random()*400))
      .style(`opacity`, `0`)
      .style(`transform`, `scale3d(0.8,0.8,1) rotate(0deg)`)
      .on(`end`, (d, index) => {
        if (index === 0 && next)
          next()
      })
  }

  onClickIndicador(element, d) {
    const selectedNodeId = d.data.id
    const selectedNodeDeep = d.data.draw.deep
    //
    publish(`thermometer::indicator.selected`, d.data.data)
    this.clear(() => {
      setTimeout(() => {
        this.openSubChart(selectedNodeId, selectedNodeDeep)
      }, 50)
    })
  }

  onMouseLeaveIndicador(element, d) {
    publish(`thermometer::indicator.mouseleave`)
    clearTimeout(this.timeoutMouseEnter)
    this.timeoutMouseLeave = setTimeout(() => this.removeFadeOutClasses(), 500)
  }

  onMouseEnterIndicador(element, d) {
    publish(`thermometer::indicator.mouseenter`, d)
    publish(`thermometer::mouse.enter`)
    //
    this.lastMouseEnter = Date.now()
    clearTimeout(this.timeoutMouseLeave)
    //
    this.timeoutMouseEnter = setTimeout(() => {
      this.addFadeOutClasses(element, d)
    }, this.withFadeOutClass ? 0 : 200)
  }

}
