import React, { useEffect, useState } from 'react';
import util from '../../../../helpers/util';
import './Details.scss';

export function CardCadOccurrenceDetails({
  http,
  show,
  setTitle,
  publish,
  hidden,
  openCard,
  code
}) {

  const [ occurrenceDetails, setOccurrenceDetails ] = useState({});

  useEffect(() => {
    
    http(`/cad/occurrences/${code}`).then(res => {
      const data = { ...res, point: res.occurrence.position };
      setOccurrenceDetails(data);
      show();
      setTitle(`Ocorrência ${code}`);
      publish(`map::marker.add`, { 
        name: `highlight`, 
        point: res.occurrence.position,  
        color: `#A98AFF`,
        opacity: 0.5, 
        className: 'circle border animated',
      });
      publish(`map::marker.zoom`, { name: `highlight`});
    });
  }, [])

  useEffect(() => {
    const { victim, offender, notifier, witnesses = [] } = occurrenceDetails;
    if ( victim || offender || notifier || witnesses.length > 0 ) {
      openCard('CardCadPersonsDetails', { victim, offender, notifier, witnesses });
    }
  }, [ occurrenceDetails ])

  const emptyItem = item => !item || (item.value || ``).trim().length === 0
  const nonEmptyItem = item => !emptyItem(item)

  function renderBlockTitle( title ) {
    return (
      <div className="block-title-container">
        <div className="block-title">
          <div className="block-title-text">
            { title }
          </div>
        </div>
      </div>
    )
  }

  function renderValues(items, className=``) {
    if (!items || items.length === 0 || items.filter(emptyItem).length === items.length) {
      return null;
    }
    const key = items.map(v => `${v.name}-${v.value}`).toString();
    return (
      <div key={key} className={ `line-values ${className}` }>
        {items.map((i, index) => (
          <div className={ `item ` + (i.className || ``) } key={ index }>
            <div className="label">{ i.name }</div>
            <div className="value">{ i.value || `-` }</div>
          </div>
        ))}
      </div>
    )
  }

  function renderNote( note ) {
    return (
      <div key={ note.id } className="cad-occurrence-note__container">
        <div className="cad-occurrence-note__title">
          <div className="cad-occurrence-note__group-name">{ note.groupName }</div>
          -
          <div className="cad-occurrence-note__user-name">{ note.userName }</div>
        </div>
        <div className="cad-occurrence-note__content">
          { note.text }
        </div>
      </div>
    );
  }

  function renderInfo() {
    const { occurrence, notes = [], attendantTeams = [] } = occurrenceDetails;
    const line1 = [
      {name: `ENDEREÇO`, value: occurrence.address},
    ];
    const line2 = [
      {name: `DATA DO OCORRIDO`, value: occurrence.createdAt && util.datetime2str(occurrence.createdAt)},
      {name: `STATUS`, value: occurrence.statusName},
    ];
    const line3 = [
      {name: `TIPO`, value: occurrence.type},
      {name: `SUB-TIPO`, value: occurrence.subType},
    ].filter(nonEmptyItem);

    const attendantTeamsLines = attendantTeams.map( (team, i) => ([
      { name: `EQUIPE ${i+1}`, value: `${team.leaderName} (${team.vehiclePlate || 'S/ Veículo'})` }
    ]));

    return (
      <React.Fragment>
        { renderBlockTitle('INFORMAÇÕES GERAIS') }
        { renderValues(line1, `text no-border`) }
        { renderValues(line2, `text no-border`) }
        { renderValues(line3, `text no-border`) }
        { attendantTeams.length > 0 && (
          <React.Fragment>
            { renderBlockTitle('EQUIPES DE ATENDIMENTO') }
            { renderValues(attendantTeamsLines[0], `text no-border`) }
            { attendantTeamsLines.slice(1).map( line => renderValues(line, `text no-border`)) }
          </React.Fragment>
        )}
        { notes.length > 0 && (
          <React.Fragment>
            { renderBlockTitle('NOTAS') }
            { notes.map( renderNote ) }
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
  
  if ( hidden ) return null;

  return (
    <div className="CardCadOccurrenceDetails">
      <div className="card-title">
        CICCM AIS 13
        <span className="light">Ocorrência { code }</span>
      </div>
      <div className="content">
        { renderInfo() }
      </div>
    </div>
  );

}

