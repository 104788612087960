import React, { Component } from 'react'
import './index.scss'

/*
<SwitcherOnOff defaultValue={ true } onChange={ func } />
*/
export default class SwitcherOnOff extends Component {

  state = {
    value: false,
    label: null,
    labelLeft: null,
  }

  constructor(props) {
    super(props)
    this.state = {
      value: props.defaultValue || false,
      label: props.label || null,
      labelLeft: props.labelLeft || null,
    }
  }

  render() {
    const { value, label, labelLeft } = this.state
    const { disabled } = this.props
    const button = (
      <div className={ `SwitcherOnOff ${value} ${disabled ? 'disabled':''}` } onClick={ this.clickHandler }>
        <div className={ `` + value }></div>
      </div>
    )
    if (label) {
      return (
        <div className={ `SwitcherOnOffContainer ${value} ${disabled ? 'disabled':''}` }>
          { labelLeft ? <label className="left">{ labelLeft }</label> : null }
          { button }
          <label className="right">{ label }</label>
        </div>
      )
    }
    return button
  }

  clickHandler = () => {
    if (this.props.disabled) {
      return
    }
    const value = !this.state.value
    this.setState({value})
    this.props.onChange(value)
  }

}