import React, { Component } from 'react'
import CivilDetalhe from '../../Civil/Historico/Detalhe'
import util from '../../../../helpers/util'
import './index.scss'

export class CardBiometriaPopupDetails extends Component {

  static ROLES = [
    `CEREBRUM_HIST_BIOMETRIA`,
  ]

  state = {json: null}

  componentDidMount() {
    const json = {...this.props}
    this.props.publish(`map::marker.add`, {point: json.point, color: `#DEED66`, className: `circle border`})
    this.setState({json})
    this.props.show()
    this.props.setTitle(`COLETA BIOMÉTRICA ` + util.date2str(this.props.date))
  }

  render() {
    const { json } = this.state
    if (!json) {
      return null
    }
    const { date } = this.props
    return (
      <div className="CardBiometriaPopupDetails">
        <div className="card-title">
          <label>COLETA BIOMÉTRICA</label>
          <span>{ util.date2str(date) } às { util.date2time(date) }</span>
        </div>
        { this.renderOfficer() }
        <CivilDetalhe roles={ this.props.roles } openCard={ this.props.openCard } {...json.searchResult} />
      </div>
    )
  }

  renderOfficer() {
    const { officer, registrationId } = this.props
    return (
      <div className="row-values">
        <div className="col">
          <div className="label">POLICIAL</div>
          <div className="value">{ registrationId } - { officer }</div>
        </div>
      </div>
    )
  }

}

