import React, { useState } from 'react';
import DropIcon from '../../../images/arrow-left-simple.svg';
import "./index.scss";

export default function ResultsManager({ results = [], handleRemove }) {
	const [showList, setShowList] = useState(false);

	return <div className='resultsMenu' >
		<h3> Resultados </h3>
		<span className='dropIcon' onClick={() => setShowList(!showList)} >
			<img width={18} src={DropIcon} alt='dropdown' style={{ transform: showList ? 'rotate(90deg)' : 'rotate(270deg)' }} />
		</span>
		{
			showList ? <ul>
				{
					results.map((item, index) => {
						const { person } = item;
						if (person) return <li>
							<span>{person.name}</span>
							<span>RG: {person.rg || '--'}</span>
							<span>CPF: {person.cpf || '--'}</span>
							<button className='removeBtn' onClick={() => handleRemove(index)} > X </button>
						</li>
						else return null;
					})
				}
			</ul> : null
		}
	</div>
}