import { api } from '../../services/api';

export const text = {
	fixText: (text) => {
		return text.replace(/\s{1,}/g, ` `);
	},
	getValue: (text) => {
		if (text.replace(/\W/g, ``).length === 0)
			throw new Error(`Texto inválido!`);
		return text.trim().toUpperCase();
	},
};

export const number = {
	fixText: (text) => {
		return (text.match(/\d/g) || []).join(``);
	},
	getValue: (text) => {
		if (!/^\d+$/.test(text)) throw new Error(`Número inválido!`);
		return parseInt(text, 10);
	},
};

export const cpf = {
	match: (text) => {
		return /^\d{11}$/.test(text.replace(/\W/g, ``));
	},
	fixText: (text) => {
		return text
			.replace(/\W/g, ``)
			.toUpperCase()
			.replace(/[A-Z]/g, ``)
			.replace(/^(\d{3})(\d{3})(\d{3})(\d+)/, `$1.$2.$3-$4`)
			.slice(0, 14)
			.replace(/^(\d{3})(\d{3})(\d+)/, `$1.$2.$3`)
			.replace(/^(\d{3})(\d+)/, `$1.$2`);
	},
	getValue: (text) => {
		if (text.length !== 14 || !/^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(text))
			throw new Error(`CPF inválido!`);
		return text.replace(/[.-]/g, ``);
	},
};

export const cnpj = {
	match: (text) => {
		return /^\d{14}$/.test(text.replace(/\W/g, ``));
	},
	fixText: (text) => {
		return text
			.replace(/\W/g, ``)
			.toUpperCase()
			.replace(/[A-Z]/g, ``)
			.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d+)/, `$1.$2.$3/$4-$5`)
			.slice(0, 18)
			.replace(/^(\d{2})(\d{3})(\d{3})(\d+)/, `$1.$2.$3/$4`)
			.replace(/^(\d{2})(\d{3})(\d+)/, `$1.$2.$3`)
			.replace(/^(\d{2})(\d+)/, `$1.$2`);
	},
	getValue: (text) => {
		if (text.length !== 18 || !/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/.test(text))
			throw new Error(`CNPJ inválido!`);
		return text.replace(/[.\-/]/g, ``);
	},
};

export const placaVeiculo = {
	match: (text) => {
		const num = text.replace(/\W/g, ``).toUpperCase();
		return /^([A-Z]{2,3}\d{4}|[A-Z]{3}\d[A-Z]\d{2})$/.test(num);
	},
	fixText: (text) => {
		return text.replace(/\W/g, ``).trim().toUpperCase();
	},
	getValue: (text) => {
		if (!/^([A-Z]{2,3}\d{4}|[A-Z]{3}\d[A-Z]\d{2})$/.test(text))
			throw new Error(`Placa inválida!`);
		return text;
	},
};

export const year = {
	fixText: (text) => {
		return (text.match(/\d/g) || []).join(``).slice(0, 4);
	},
	getValue: (text) => {
		if (!/^\d{4}$/.test(text) || text.replace(/^0+(\d)/, `$1`) === '0')
			throw new Error(`Ano inválido!`);
		return parseInt(text, 10);
	},
};

export const month = {
	fixText: (text) => {
		return (text.match(/\d/g) || []).join(``).slice(0, 2);
	},
	getValue: (text) => {
		if (
			!/^\d{1,2}$/.test(text) ||
			parseInt(text, 10) > 12 ||
			text.replace(/^0+(\d)/, `$1`) === '0'
		)
			throw new Error(`Mês inválido!`);
		return parseInt(text, 10);
	},
};

export const date = {
	fixText: (text) => {
		return text
			.replace(/\W/g, ``)
			.toUpperCase()
			.replace(/[A-Z]/g, ``)
			.replace(/^(\d{2})(\d{2})(\d+)/, `$1/$2/$3`)
			.slice(0, 10)
			.replace(/^(\d{2})(\d+)/, `$1/$2`);
	},
	getValue: (text) => {
		if (text.length !== 10 || !/^\d{2}\/\d{2}\/\d{4}$/.test(text))
			throw new Error(`Data inválida!`);
		const d = text.split(`/`).map((i) => parseInt(i, 10));
		return new Date(d[2], d[1] - 1, d[0]);
	},
};

export const datetime = {
	fixText: (text) => {
		return text
			.replace(/\W/g, ``)
			.toUpperCase()
			.replace(/[A-Z]/g, ``)
			.replace(/^(\d{2})(\d{2})(\d{4})(\d{2})(\d+)/, `$1/$2/$3 $4:$5`)
			.slice(0, 16)
			.replace(/^(\d{2})(\d{2})(\d{4})(\d+)/, `$1/$2/$3 $4`)
			.replace(/^(\d{2})(\d{2})(\d+)/, `$1/$2/$3`)
			.replace(/^(\d{2})(\d+)/, `$1/$2`);
	},
	getValue: (text) => {
		if (text.length !== 16) throw new Error(`Data/hora inválida!`);
		const d = text.split(`/`).map((i) => parseInt(i, 10));
		const t = text
			.split(` `)[1]
			.split(`:`)
			.map((i) => parseInt(i, 10));
		return new Date(d[2], d[1] - 1, d[0], t[0], t[1], 0, 0);
	},
};

export const time = {
	fixText: (text) => {
		text = text
			.replace(/\W/g, ``)
			.toUpperCase()
			.replace(/[A-Z]/g, ``)
			.slice(0, 4);
		return text.replace(/^(\d{1,2})(\d{2})$/, '$1:$2');
	},
	getValue: (text) => {
		if (text.length === 4) text = `0` + text;
		if (text.length !== 5) throw new Error(`Horário inválido!`);
		return text;
	},
};

export const scenarioSelect = {
	getValue: (selected) => {
		if(!selected) throw new Error('Nenhum item selecionado!');
		return selected;
	},
	loadOptions: async () => {
		try {
			const result = await api.get('/criminal/stains/scenarios');
			const { content } = result;
			const options = content.map((scenario) => {
				return {
					value: scenario.id,
					label: scenario.name,
				};
			});

			return options;
		} catch (err) {
			console.error(err);
			return [];
		}
	},
};

const daysWeekOptions = [
	{ value: 0, label: 'Dom' },
	{ value: 1, label: 'Seg' },
	{ value: 2, label: 'Ter' },
	{ value: 3, label: 'Qua' },
	{ value: 4, label: 'Qui' },
	{ value: 5, label: 'Sex' },
	{ value: 6, label: 'Sab' },
];

export const daysWeekSelect = {
	getValue: (selected) => {
		selected = selected ? [...selected] : [];
		const values = [];
		const labels = [];
		selected.forEach((option) => {
			if (!daysWeekOptions.find((item) => item.value === option.value))
				throw new Error('Dia da semana inválido!');
			else {
				values.push(option.value)
				labels.push(option.label)
			};
		});
		if(values.length === 0) throw new Error('Nenhum item selecionado!');
		return {values, labels};
	},
	options: daysWeekOptions,
	isMulti: true,
};
