import React, { Component } from 'react'
import { List } from 'react-virtualized'
import MapCircleSelection from '../../../UI/MapCircleSelection'
import SwitcherOnOff from '../../../UI/SwitcherOnOff'
import util from '../../../../helpers/util'
import './index.scss'

const COLOR = {
  cvp: `#f5f7d8`,
  cvli: `#fa2`
}

export class CardIndicadoresArea extends Component {

  state = {
    rows: [],
    rowsFiltered: [],
    allRows: [],
    types: [`cvp`, `cvli`],
    settings: {
      cvp: true,
      cvli: true
    },
    //
    filterText: ``,
    filterWords: [],
    totalHidden: 0,
    total: 0,
  }

  componentDidMount() {
    this.state.types.forEach(type => {
      const color = COLOR[type] || `#fff`
      const group = {color, title: type}
      this.props.publish(`map::layer.add`, {group, name: type, points: [], color, popup: this.createPopup, click: this.clickPopup})
    })
    this.props.show()
    this.props.setTitle(`INDICADORES POR RAIO ` + this.getTimeTitle())
  }

  extractQueryParams = (circle) => {
    const { type, date, startDate, endDate } = this.props
    let query = {}
    if (type === `date`) {
      query = {date: util.date2sql(date)}
    } else if (type === `startDate`) {
      query = {startDate: util.date2sql(startDate)}
    } else if (type === `rangeDate`) {
      query = {startDate: util.date2sql(startDate), endDate: util.date2sql(endDate)}
    }
    if (circle) {
      const { center, radius } = circle
      query = {...query, longitude: center[0], latitude: center[1], radius}
    }
    return query
  }

  download = (circle) => {
    const { types } = this.state
    const query = this.extractQueryParams(circle)
    const promises = types.map(type => {
      return this.props.http.indicators(`/area`, {...query, typeIndicator: type})
    })
    return Promise.all(promises).then(results => {
      let rows = []
      let nextIndex = 0
      types.forEach((type, index) => {
        rows = rows.concat((results[index] || []).map(i => this.importRow(nextIndex++, type, i)))
      })
      rows.forEach((i, index) => i._id = index)
      rows.sort((a,b) => b.sort - a.sort)
      return rows
    })
  }

  importRow = (index, type, i) => {
    i.type  = type
    i.index = index
    i.point = i.position
    i.text  = this.obj2text(i)
    i.query = this.obj2query(i).toUpperCase()
    i.hour  = i.hour.slice(0,5)
    i.sort  = new Date(i.date).getTime()
    i.date  = util.date2str(i.date)
    return i
  }

  obj2text = i => {
    if (i.type === `cvp`) {
      return i.cvpType + ` - AIS ` + i.ais
    }
    if (i.type === `cvli`) {
      return i.eventType + ` / ` + i.crimeWeapon + ` - ` + i.victimName
    }
  }

  obj2query = i => {
    let query = i.type
    query += ` ` + util.date2str(i.date, true)
    query += ` ` + i.hour.slice(0,5)
    if (i.type === `cvp`) {
      query += ` ` + i.cvpType
    }
    if (i.type === `cvli`) {
      query += ` ` + i.eventType
      query += ` ` + i.crimeWeapon
      query += ` ` + i.victimName
    }
    return query
  }

  createPopup = item => {
    const title = item.type.toUpperCase() + ` ` + item.date + ` ` + item.hour
    const text = item.type === `cvp` ? `${item.cvpType}` : `${item.procedureType} ${item.id}`
    return { title, text }
  }

  clickPopup = item => {
    if (item.type === `cvp`) {
      this.props.openCard(`CardCVPPopupDetails`, item)
    } else if (item.type === `cvli`) {
      const isMaster = this.props.roles.includes(`CEREBRUM_INQ_DETALHES`)
      const cardName = isMaster ? `CardCVLIOcorrencia` : `CardCVLIPopupDetails`
      this.props.openCard(cardName, item)
    }
  }

  getTimeTitle = () => {
    const { type, date, startDate, endDate } = this.props
    if (type === `date`)
      return ` em ` + util.date2str(date)
    if (type === `startDate`)
      return ` desde ` + util.date2str(startDate)
    if (type === `rangeDate`)
      return ` em ` + util.date2str(startDate) + ` - ` + util.date2str(endDate)
    return ` ano de ` + new Date().getFullYear()
  }

  clearMap = () => {
    this.state.types.forEach(type => {
      this.props.publish(`map::layer.clear`, {name: type})
    })
  }

  updateMap = () => {
    const { types, rowsFiltered } = this.state
    types.forEach(type => {
      this.props.publish(`map::layer.update`, {name: type, points: rowsFiltered.filter(_ => _.type === type)})
    })
  }

  mapSelectionHandler = circle => {
    this.props.setLoading()
    this.clearMap()
    this.setState({initialized: true, totalHidden: 0})
    this.download(circle).then(rows => {
      this.setState({allRows: rows, rowsFiltered: rows, total: rows.length, errorMessage: null}, this.syncRows)
      this.props.show()
    }).catch(_ => {
      this.setState({allRows: [], rowsFiltered: [], total: 0, errorMessage: `FALHA AO CONSULTAR DADOS`}, this.syncRows)
      this.props.show()
    })
  }

  syncRows = () => {
    const { settings, filterWords, allRows } = this.state
    const rows = allRows.filter(i => settings[i.type])
    const totalHidden = this.syncHiddenRows(settings, rows, filterWords)
    Object.entries(settings).forEach(i => {
      const [ type, enabled ] = i
      const points = enabled ? rows.filter(_ => _.type === type && !_.hidden) : []
      this.props.publish(`map::layer.update`, {name: type, points})
    })
    const rowsFiltered = rows.filter(_ => !_.hidden)
    this.setState({totalHidden, rows, rowsFiltered})
  }

  syncHiddenRows = (settings, rows, words) => {
    let totalHidden = 0
    rows.forEach(i => {
      i.hidden = settings[i.type] === false || this.testHidden(words, i.query)
      if (i.hidden)
        totalHidden++
    })
    return totalHidden
  }

  filterTextHandler = e => {
    const text = e.target.value
    clearTimeout(this.timeout)
    this.timeout = setTimeout(this.applyFilter.bind(this, text), 200)
    this.setState({filterText: text})
  }

  applyFilter = text => {
    const words = text.trim().toUpperCase().split(/\s+/)
    this.setState({filterWords: words}, this.syncRows)
  }

  testHidden = (filterWords, itemQuery) => {
    if (filterWords.length === 0) {
      return false
    }
    return filterWords.filter(word => itemQuery.indexOf(word) === -1).length
  }

  changeSettingsCVP = value => {
    const settings = {...this.state.settings, cvp: value}
    this.updateSettings(settings)
  }

  changeSettingsCVLI = value => {
    const settings = {...this.state.settings, cvli: value}
    this.updateSettings(settings)
  }

  updateSettings = settings => {
    let rows = this.state.allRows || []
    Object.entries(settings).forEach(i => {
      const [ type, enabled ] = i
      if (!enabled) {
        rows = rows.filter(_ => _.type !== type)
      }
    })
    const rowsFiltered = rows.filter(_ => !_.hidden)
    this.setState({settings, rows, rowsFiltered}, this.updateMap)
  }

  render() {
    return (
      <div className="CardIndicadoresArea">
        <div className="card-title">
          INDICADORES POR RAIO <span>{ this.getTimeTitle() }</span>
        </div>
        { this.renderSummary() }
        { this.renderRows() }
      </div>
    )
  }

  renderSummary() {
    const { settings, filterText, rows } = this.state
    const total = rows.length
    const visible = total === 0 ? 0 : (total - rows.filter(_ => _.hidden).length)
    return (
      <div className="summary">
        <div className="flex-container">
          <div className="flex">
            <SwitcherOnOff defaultValue={ settings.cvp  } onChange={ this.changeSettingsCVP } label="CVP" />
            <SwitcherOnOff defaultValue={ settings.cvli } onChange={ this.changeSettingsCVLI } label="CVLI" />
          </div>
          <div className="flex flex3 right detail">
            <MapCircleSelection onChange={ this.mapSelectionHandler } />
          </div>
        </div>
        <div className="input">
          <input placeholder="Filtro..." value={ filterText } onChange={ this.filterTextHandler } />
          <div className="total-hidden">
            { visible } / { total }
          </div>
        </div>
      </div>
    )
  }

  renderRows() {
    const { initialized, rowsFiltered, errorMessage } = this.state
    if (!initialized) {
      return <p className="message empty">AGUARDANDO DEFINIÇÃO DE ÁREA NO MAPA...</p>
    }
    if (errorMessage) {
      return <p className="message empty">{ errorMessage }</p>
    }
    if (rowsFiltered.length === 0) {
      return <p className="message empty">NENHUM DADO ENCONTRADO</p>
    }
    return (
      <div className="table-list">
        <List width={ 750 } height={ 400 } rowHeight={ 31 } rowCount={ rowsFiltered.length } rowRenderer={ this.renderRow } />
      </div>
    )
  }

  renderRow = ({index, key, style}) => {
    const i = this.state.rowsFiltered[index]
    return (
      <div key={ i._id } style={ style } className="row" onClick={ this.clickPopup.bind(this, i) }>
        <div className="col date">{ i.date }</div>
        <div className="col hour">{ i.hour }</div>
        <div className="col text" title={ i.text }>{ i.text }</div>
      </div>
    )
  }

}

