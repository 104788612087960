import React, { useEffect, useContext } from "react";
import SideMenu from "../HelpScreen/SideMenu";
import HelpScreenHeader from "./Header";
import HelpSections from "./Sections";
import { QuestionsContext } from "../../contexts/questions";

import "./index.scss";

const HelpScreen = ({ hash }) => {
  const questions = useContext(QuestionsContext);
  const hashtag = "#pergunta";

  useEffect(() => {
    const navigationHeight = document.querySelector(".HeaderLeft").offsetHeight;
    const scrollPadding = navigationHeight - 35;
    document.documentElement.style.setProperty(
      "--scroll-padding",
      scrollPadding + "px"
    );

    const sectionsElement = document.querySelector(".sections-container");
    const lastSectionHeight = Math.abs(
      window.innerHeight -
        sectionsElement.lastElementChild.offsetHeight -
        scrollPadding
    );
    document.documentElement.style.setProperty(
      "--last-section-height",
      lastSectionHeight + "px"
    );

    if (document.getElementById(hash))
      document.getElementById(hash).scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <div className="screen-container">
      <HelpScreenHeader />
      <SideMenu locationHash={hash} hashtag={hashtag} questions={questions} />
      <HelpSections hashtag={hashtag} />
    </div>
  );
};

export default HelpScreen;
