import React, { Component } from 'react'
import ChartRowsTotal from '../../../UI/Chart/RowsTotal'
import TableChart from '../../../UI/TableChart'
import util from '../../../../helpers/util'
import './index.scss'

export class CardCVLIAIS extends Component {

  state = {
    totals: {},
    weapons: [],
  }

  componentDidMount() {
    const {ais, type, date, month, year, startDate, endDate} = this.props
    let query = {ais}
    if (type === `date`) {
      query = {ais, date: util.date2sql(date)}
    } else if (type === `year`) {
      query = {ais, year}
    } else if (type === `month-year`) {
      query = {ais, month, year}
    } else if (type === `startDate`) {
      query = {ais, startDate: util.date2sql(startDate)}
    } else if (type === `rangeDate`) {
      query = {ais, startDate: util.date2sql(startDate), endDate: util.date2sql(endDate)}
    }
    this.props.http.indicators(`/cvli/search`, query).then(data => {
      const polygons = data.geometry || []
      const points = data.cvli || []
      const totals = {'TOTAL CRIMES': points.length, ...this.getAVGs(points)}
      const weapons = this.getCrimeWeapons(points)
      //
      points.forEach(p => {
        p.point = p.position
        p.turno = parseInt(parseInt(p.hour.split(`:`)[0], 10) / 6, 10)
        p.weekday = new Date(p.date + `T00:00:00`).getDay()
      })
      const datasource = this.extractDataSource(points)
      //
      this.setState({datasource, points, totals, weapons})
      const group = {color: `#fa2`, title: `CVLI`}
      this.props.publish(`map::layer.add`, {polygons, group: {color: `#abb3a8`, title: `AIS ${this.props.ais}`}})
      this.props.publish(`map::layer.add`, {name: `cvli`, points, group, color: `#fa2`, popup: this.createPopup, click: this.clickPopup})
      this.props.publish(`map::layer.zoom`, {name: `cvli`})
      this.props.show()
      this.props.setTitle(`CVLI AIS ` + this.props.ais + this.getTimeTitle())
    })
  }

  extractDataSource = points => {
    const header = [`DOM`, `SEG`, `TER`, `QUA`, `QUI`, `SEX`, `SÁB`]
    const turnos = [`00h - 06h`, `06h - 12h`, `12h - 18h`, `18h - 00h`]
    const rows = []
    turnos.forEach((turno, index) => {
      const cells = header.map((h, weekday) => points.filter(p => p.weekday === weekday && p.turno === index).length)
      rows.push({label: turno, cells})
    })
    return {header, rows, total: points.length}
  }

  createPopup = item => {
    return { title: item.procedureType, text: item.id }
  }

  clickPopup = item => {
    const isMaster = this.props.roles.includes(`CEREBRUM_INQ_DETALHES`)
    const cardName = isMaster ? `CardCVLIOcorrencia` : `CardCVLIPopupDetails`
    this.props.openCard(cardName, item)
  }

  getCrimeWeapons = (objs) => {
    const types = {}
    objs.forEach(o => {
      const t = o.crimeWeapon
      if (types[t] === undefined) {
        types[t] = 0
      }
      types[t] += 1
    })
    return Object.entries(types).map(i => {
      return {label: i[0], value: i[1]}
    }).sort((a,b) => b.value - a.value)
  }

  getAVGs = (objs) => {
    const info = {total: 0, male: 0, age: 0}
    objs.forEach(o => {
      if (o.victimSex && o.victimSex.length === 1 && typeof o.victimAge === `number`) {
        info.total++
        info.age += o.victimAge
        info.male += o.victimSex === `M` ? 1 : 0
      }
    })
    if (info.total === 0)
      return {}
    return {
      [`IDADE MÉDIA DAS VÍTIMAS`]: (info.age / info.total).toFixed(1).replace(`.`,`,`),
      [`VÍTIMA MASCULINA`]: (info.male / info.total * 100).toFixed(1).replace(`.`,`,`) + `%`,
    }
  }

  getTimeTitle = () => {
    const {type, date, month, year, startDate, endDate} = this.props
    if (type === `startDate`)
      return ` desde ` + util.date2str(startDate)
    if (type === `date`)
      return ` dia ` + util.date2str(date)
    if (type === `month-year`)
      return ` em ` + util.getMonthName(month) + ` de ` + year
    if (type === `rangeDate`)
      return ` em ` + util.date2str(startDate) + ` - ` + util.date2str(endDate)
    return ` ano de ` + (year || new Date().getFullYear())
  }

  renderTitle = () => {
    const { ais } = this.props
    const prefix = `CVLI AIS ` + ais
    return (
      <div className="card-title category">
        { prefix } <span>{ this.getTimeTitle() }</span>
      </div>
    )
  }

  render() {
    return (
      <div className="CardCVLIAIS">
        { this.renderTitle() }
        { this.renderTable() }
        { this.renderContent() }
      </div>
    )
  }

  renderContent() {
    const { totals, weapons } = this.state
    if (weapons.length === 0) {
      return <p className="message">NENHUM REGISTRO ENCONTRADO</p>
    }
    return (
      <React.Fragment>
        { this.renderWeapons(weapons) }
        <ChartRowsTotal data={ totals } />
      </React.Fragment>
    )
  }

  renderWeapons(weapons) {
    return (
      <div className="row-values">
        {weapons.map((w, index) => (
          <div className="col" key={ index }>
            <div className="label">{ w.label }</div>
            <div className="value">{ w.value }</div>
          </div>
        ))}
      </div>
    )
  }

  selectedCell = (label, index, info) => {
    const points = this.state.points.filter(p => p.turno === info.row && p.weekday === info.column)
    this.updateLayer(points)
  }

  rowSelected = (rowLabel, index) => {
    const points = this.state.points.filter(p => p.turno === index)
    this.updateLayer(points)
  }

  colSelected = (index) => {
    const points = this.state.points.filter(p => p.weekday === index)
    this.updateLayer(points)
  }

  clearSelection = () => {
    this.updateLayer(this.state.points)
  }

  updateLayer = (points=[]) => {
    const totals = {'TOTAL CRIMES': points.length, ...this.getAVGs(points)}
    const weapons = this.getCrimeWeapons(points)
    this.setState({totals, weapons})
    this.props.publish(`map::layer.update`, {name: `cvli`, points})
    this.props.publish(`map::layer.zoom`, {name: `cvli`})
  }

  renderTable() {
    const ds = this.state.datasource
    if (!ds || ds.total === 0)
      return null
    return <TableChart header={ ds.header } data={ ds.rows } extraInfo={ false }
                    selected={ this.selectedCell }
                    rowSelected={ this.rowSelected }
                    colSelected={ this.colSelected }
                    clearSelection={ this.clearSelection }
            />
  }

}
