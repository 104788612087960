import React, { Component } from 'react'
import { List } from 'react-virtualized'
import { SearchFilterWords } from '../../../UI/SearchFilterWords'
import util from '../../../../helpers/util'
import './index.scss'

const TIPOS = [`Privada`, `Municipal`, `Estadual`, `Federal`]
const COLOR = {
  Privada  : `#2dc6ff`,
  Municipal: `#f9ca1d`,
  Estadual : `#6be44d`,
  Federal  : `#b096fd`,
}

const ENUM_TIPO = {
  '1': 'Privada',
  '2': 'Municipal',
  '3': 'Estadual',
  '4': 'Federal'
}
const ENUM_STATUS = {
  '1': 'Em Atividade',
  '2': 'Paralisada',
  '3': 'Extinta'
}

let JSONData = {}

export class CardInepBrasil extends Component {

  componentDidMount() {
    this.download().then(json => {
      JSONData = json
      //
      let total = 0
      const categorias = {}
      TIPOS.forEach(i => categorias[i] = 0)
      //
      const ufList = Object.entries(json).map(([uf, data]) => {
        const obj = {id: uf, name: util.uf2name(uf), total: data.length}
        total += obj.total
        TIPOS.forEach(t => obj[t] = 0)
        data.forEach(i => {
          categorias[i.tipo]++
          obj[i.tipo]++
        })
        return obj
      })
      //
      const group = {color: `#fa5`, title: `Escolas`}
      this.props.publish(`map::layer.add`, {name: `escolas`, points: [], group, color: `#fa5`, popup: this.createPopup, click: this.clickPopup})
      this.props.publish(`map::layer.add`, {name: `circles`, circles: [], group, stroke: `#fff`, color: `rgba(255,255,255, 0.1)`, opacity: 0.2})
      this.props.publish(`map::zoom`, {point: [-51.894,-13.541], zoom: 4})
      //
      this.setState({...categorias, circles: [], items: ufList, total, current: null, ufSelected: null, filterWords: [], filteredRows: []}, () => {
        this.filterHandler(null)
        this.props.show()
        this.props.setTitle(`INEP Brasil`)
      })
    })
  }

  createPopup = item => {
    return {title: item.nome, text: item.text}
  }

  clickPopup = item => {
    const uf     = this.state.ufSelected
    const ufName = util.uf2name(uf)
    const data   = {...item, uf, ufName}
    this.props.openCard(`CardInepEscola`, data)
  }

  download() {
    return new Promise((resolve) => {
      const baseUrl = process.env.PUBLIC_URL
      this.props.http(`${baseUrl}/inep/brasil.json`).then(json => {
        if (typeof json === `string`) {
          json = JSON.parse(json)
        }
        let id = 1
        Object.keys(json).forEach(uf => {
          json[uf] = json[uf].map(i => {
            const [_tipo, _status, nome, alunos, lat, lng, address, cep, phone] = i
            const tipo   = ENUM_TIPO[_tipo]
            const status = ENUM_STATUS[_status]
            const point  = [lat, lng]
            const text   = tipo + ` - ` + (status === `Em Atividade` ? (this.formatNumber(alunos) + ` matrículas`) : status);
            const query  = nome + ` ` + text
            const obj = {id: id++, nome, tipo, status, alunos, text, query, point, address}
            if (cep) obj.cep = util.formatCEP(cep)
            if (phone) obj.phone = util.formatPhone(phone).replace(`)`, `) `)
            return obj
          })
        })
        resolve(json)
      })
    })
  }

  formatNumber = n => {
    return (`` + n).replace(/(\d)(\d{3})$/, `$1.$2`).replace(/(\d)(\d{3})\./, `$1.$2.`)
  }

  clickHandler = item => {
    const { current, ufSelected, circles } = this.state
    const uf = item.id
    if (ufSelected === uf) {
      this.props.publish(`map::layer.update`, {name: `circles`, circles})
      this.props.publish(`map::layer.update`, {name: `escolas`, points: []})
      this.props.publish(`map::zoom`, {point: [-51.894,-13.541], zoom: 4})
      this.setState({ufSelected: null, filterWords: [], rows: [], filteredRows: []})
      return
    }
    this.setState({loading: true}, () => setTimeout(() => {
      let points = JSONData[uf]
      if (current) {
        points = points.filter(i => i.tipo === current)
      }
      this.props.publish(`map::layer.update`, {name: `circles`, circles: []})
      this.props.publish(`map::layer.update`, {name: `escolas`, points})
      this.props.publish(`map::zoom`, {point: util.uf2gps(uf), zoom: 6})
      this.setState({ufSelected: uf, filterWords: [], rows: points, filteredRows: points}, () => {
        this.setState({loading: false})
      })
    }, 1))
  }

  filterHandler = category => {
    const { items, total } = this.state
    const current = this.state.current === category ? null : category
    let max = items.map(i => i[current] === undefined ? i.total : i[current]).reduce((a,b) => Math.max(a,b), 0)
    items.forEach(i => {
      if (!current) {
        max = Object.keys(COLOR).map(_ => i[_]).reduce((a,b) => a + b, 0)
      }
      i.width = {}
      Object.keys(COLOR).forEach(cat => {
        const width = ((i[cat] / max) * 100).toFixed(2) + `%`
        if (current) {
          i.width[cat] = cat === current ? width : `0%`
          if (cat === current) {
            i.percentValue = i[cat] / this.state[cat]
            i.percent = (i.percentValue * 100).toFixed(2) + `%`
          }
        } else {
          i.width[cat] = width
          i.percentValue = i.total / total
          i.percent = (i.percentValue * 100).toFixed(2) + `%`
        }
      })
    })
    items.sort(current ? (a,b) => b[current] - a[current] : (a,b) => b.total - a.total)
    this.setState({items, current}, this.updateMapCircles)
  }

  updateMapCircles = () => {
    const MIN = 70000
    const MAX = 1000000
    const { items } = this.state
    const circles = items.map(i => {
      const gps = util.uf2gps(i.id)
      return !gps || i.total === 0 ? 0 : {center: gps, radius: parseInt(MIN + ((MAX - MIN) * i.percentValue), 10)}
    }).filter(i => i)
    this.props.publish(`map::layer.update`, {name: `circles`, circles})
    this.setState({circles})
  }

  filterTextHandler = words => {
    const { rows } = this.state
    const filteredRows = words.length === 0 ? rows : rows.filter(i => {
      return words.map(w => i.query.includes(w)).filter(_ => _).length === words.length
    })
    this.setState({filterWords: words, filteredRows })
    this.props.publish(`map::layer.update`, {name: `escolas`, points: filteredRows})
    this.props.publish(`map::layer.zoom`, {name: `escolas`})
  }

  clearSelection = () => {
    this.clickHandler({id: this.state.ufSelected})
  }

  focusHandler = point => {
    this.props.publish(`map::zoom`, {point, zoom: 15})
  }

  render() {
    if (this.props.hidden) {
      return null
    }
    const { loading } = this.state
    return (
      <div className="CardInepBrasil">
        <div className="title">INEP<span>{ this.formatNumber(this.state.total) } ESCOLAS</span></div>
        <div className={ `content-container` + (loading ? ` loading` : ``) }>
          { this.renderContent() }
        </div>
        { loading ? <div className="msg-loading">CARREGANDO</div> : null }
      </div>
    )
  }

  renderLegends() {
    const { current } = this.state
    return (
      <div className={ `legends` + (current ? ` selection`:``) }>
        {Object.entries(COLOR).map(([name, color]) => (
          <div key={ name } className={ `legend` + (current === name ? ` selected`:``) } onClick={ this.filterHandler.bind(this, name) }>
            <div className="flag" style={{backgroundColor: color }}></div>
            <div className="name">{ name }</div>
          </div>
        ))}
      </div>
    )
  }

  renderContent() {
    if (!this.state.ufSelected) {
      return (
        <React.Fragment>
          { this.renderLegends() }
          { this.renderChart()   }
        </React.Fragment>
      )
    }
    return this.renderUF(this.state.ufSelected)
  }

  renderChart() {
    const { items, current, ufSelected } = this.state
    return (
      <div className="container-chart">
        {items.map(i => (
          <div key={ i.id } className="row" style={{display: ufSelected ? (ufSelected === i.id ? `block` : `none`) : `block`}} onClick={ this.clickHandler.bind(this, i) }>
            <div className="name">{ i.name }</div>
            <div className="bar">
              {Object.entries(COLOR).map(([category, color]) => (
                <div key={ i.id + category } className="life" style={{width: i.width[category], backgroundColor: color}}></div>
              ))}
            </div>
            <div className="total">{ this.formatNumber(current ? i[current] : i.total) }</div>
            <div className="total">{ i.percent }</div>
          </div>
        ))}
      </div>
    )
  }

  renderSearch() {
    const { rows, filteredRows, } = this.state
    const total = rows.length
    const subtotal = filteredRows.length
    return (
      <div className="container-search">
        <SearchFilterWords subtotal={ subtotal } total={ total } onChange={ this.filterTextHandler } />
      </div>
    )
  }

  renderUF(uf) {
    const { filteredRows } = this.state
    return (
      <React.Fragment>
        <div className="headerline">
          <div className="container-header">
            <div className="caption">{ util.uf2name(uf) }</div>
            <button className="btn-back" onClick={ this.clearSelection }>
              <div className="icon-left"></div>
              <div className="label">VOLTAR</div>
            </button>
          </div>
          { this.renderSearch() }
        </div>
        <div className="table-list">
          <List width={ 380 } height={ 380 } rowHeight={ 38 } rowCount={ filteredRows.length } rowRenderer={ this.renderRow } />
        </div>
      </React.Fragment>
    )
  }

  renderRow = ({index, key, style}) => {
    const i = this.state.filteredRows[index]
    return (
      <div key={ i.id } style={ style } className="row" onClick={ this.focusHandler.bind(this, i.point) }>
        <div className="name">{ i.nome }</div>
        <div className="details">{ i.text }</div>
      </div>
    )
  }

}
