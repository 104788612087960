
import util from '../../../helpers/util'
import * as type from '../fieldTypes'

export const CivilByName = {
  created: `2019-04-22`,
  context: `Civil`,
  role: `CEREBRUM_BUSCA_CIVIL`,
  params: {
    name:    {placeholder: `Nome`},
    mother:  {placeholder: `Mãe`, optional: true},
    father:  {placeholder: `Pai`, optional: true},
  },
  createRequest: params => {
    Object.entries(params).forEach(([key, value]) => {
      params[key] = value === undefined || value === `` ? undefined : value.trim()
    })
    if (util.countNames(params.name || ``) < 2)
      throw new Error(`É necessário detalhar mais o nome da pessoa. Por favor, tente novamente.`)
    //
    return {path: `/civil/search`, params}
  },
  createRow: json => {
    const { name, mother, father, rg, orderType, cpf, sex, picture } = json
    const birthday = json.birthday ? util.date2str(json.birthday) : null
    return {
      card: `CardCivilHistorico`,
      props: cpf ? {cpf} : {rg},
      //
      image: picture || `avatar.png`,
      title: birthday ? (birthday + ` ` + name) : name,
      lines: [
        [
          {label: `RG`, value: (rg + ` ` + orderType).toUpperCase()},
          {label: `CPF`, value: cpf ? util.formatCPF(cpf) : ``},
          {label: `Sexo`, value: sex === `M` ? `MASCULINO` : `FEMININO`},
        ],
        [{label: `Mãe`, value: (mother || ``).toUpperCase()}],
        [{label: `Pai`, value: (father || ``).toUpperCase()}],
      ]
    }
  }
}

export const CivilByCPF = {
  created: `2019-04-22`,
  context: `Civil`,
  role: `CEREBRUM_BUSCA_CIVIL`,
  params: {
    cpf: {label: `CPF`, placeholder: `000.000.000-00`, type: type.cpf},
  },
  parser: ({cpf}) => {
    cpf = cpf.replace(/\W/g, ``)
    return {card: `CardCivilHistorico`, props: {cpf}}
  }
}

export const CivilByRG = {
  created: `2019-04-22`,
  context: `Civil`,
  role: `CEREBRUM_BUSCA_CIVIL`,
  params: {
    rg: {label: `RG`, placeholder: `000000`, type: type.number},
  },
  parser: (json) => {
    return {card: `CardCivilHistorico`, props: json}
  }
}

