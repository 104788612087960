import React, { useEffect, useState } from 'react'
import { List } from 'react-virtualized'
import { SearchFilterWords } from '../../../UI/SearchFilterWords'
import util from '../../../../helpers/util'
import { publish } from "../../../../services/EventBus";
import './index.scss'

CardEnelHistorico.ROLES = [`CEREBRUM_BUSCA_ENEL`]

export function CardEnelHistorico(props) {
  const { show, http, setTitle, roles } = props
  const { cpf, cnpj } = props
  //
  const [filterWords, setFilterWords] = useState([])
  const [code, setCode] = useState(``)
  const [json, setJson] = useState([])
  const [rows, setRows] = useState([])
  const [page, setPage] = useState(0)
  const [done, setDone] = useState(false)
  const [message, setMessage] = useState(``)
  //
  useEffect(() => {
    const code = cpf ? util.formatCPF(cpf) : util.formatCNPJ(cnpj)
    setTitle(`ENEL ${code}`)
    setCode(code)
    if (cpf && roles.includes(`CEREBRUM_BUSCA_CIVIL`)) {
      // COMENTAMOS ESSA BUSCA
      // openCard(`CardCivilHistorico`, {cpf})
    }
  }, [])
  //  
  function nextPage(page = 0) {
    if (cpf) return http(`/enel/cpf/${cpf}`, { page })
    if (cnpj) return http(`/enel/cnpj/${cnpj}`, { page })
    return Promise.reject()
  }
  //
  const dataSetup = (enelList, enelListDone = false) => {
    show()
    
    if (enelList.length === 0 || enelListDone) {
      publish(`card::set-identifiers`, { hash: props.hash, cpf, data: {enelListDone: true} })
      return setDone(true)
    }
    enelList.forEach(i => {
      i.addressText = [i.address, i.district, i.city].filter(s => s).join(` - `)
      i.query = (i.name + ` ` + i.addressText).toUpperCase()
    })

    setTimeout(() => setPage(page + 1), 1000)
  }

  useEffect(() => {
    if(cpf || cnpj){
      if (props.data && props.data.enelListDone && props.data.enelList.length > 0) {
        const jsonList = [...json, ...props.data.enelList]
        setJson(jsonList)
        setRows(jsonList)
        dataSetup(props.data.enelList, props.data.enelListDone)
      } else {
        nextPage(page).then(enelList => {
          const { error } = enelList
          if (error) {
            return util.handleError(
              error,
              show,
              () => {
                setDone(true)
              },
              (message) => {
                setMessage(message)
                setDone(true)
              }
            );
          }
          const enelListData = props.data && props.data.enelList
          ? [ ...props.data.enelList, ...enelList ]
          : enelList
          publish(`card::set-identifiers`, { hash: props.hash, cpf, data: {enelList: enelListData} })
          const jsonList = [...json, ...enelList]
          setJson(jsonList)
          setRows(jsonList)
          dataSetup(enelList)
        })
      }
    } else {
      return setDone(true)
    }
  }, [page])
  //
  const filterWordsHandler = words => {
    const items = json.filter(i => {
      const matches = words.filter(w => i.query.indexOf(w) !== -1)
      const visible = words.length === 0 || matches.length === words.length
      return visible
    })
    setRows(items)
    setFilterWords(words)
  }
  //
  const renderFilter = () => {
    return <SearchFilterWords subtotal={rows.length} total={json.length} onChange={filterWordsHandler} />
  }
  const renderRow = ({ index, key, style }) => {
    const i = rows[index]
    return (
      <div key={i.id} style={style} className="row">
        <div className="line">
          <label>Nome</label>
          <div className="value" title={i.name}>{i.name}</div>
        </div>
        <div className="line">
          <label>Endereço</label>
          <div className="value" title={i.addressText}>{i.addressText}</div>
        </div>
      </div>
    )
  }
  const renderContent = () => {
    if (done && json.length === 0)
      return <div className="message">{message ? message : `NENHUM REGISTRO ENCONTRADO`}</div>
    const emptyByFilter = done && json.length && rows.length === 0 && filterWords.length
    return (
      <React.Fragment>
        {done ?
          <div className="container-filter">{renderFilter()}</div>
          :
          <div className="message-loading">CARREGANDO...</div>
        }
        {emptyByFilter ?
          <div className="message white">NENHUM REGISTRO ENCONTRADO</div>
          :
          <List width={720} height={640} rowHeight={50} rowCount={rows.length} rowRenderer={renderRow} />
        }
      </React.Fragment>
    )
  }
  return (
    <div className="CardEnelHistorico">
      <div className="card-title">
        ENEL<span>{code}</span>
      </div>
      <div className={`content` + (done ? ` done` : ``)}>
        {renderContent()}
      </div>
    </div>
  )
}
