import React from "react";

import image from "./images/image_placeholder.jpg";

export const QuestionsContext = React.createContext([
  {
    id: 1,
    title: "1. Como fazer uma busca?",
    content: (
      <>
        <img src={image} alt="imagem-placeholder" />
        <p>
          Para fazer uma consulta no Cerebrum, é só clicar na barra de
          pesquisas. Você pode fazer uma busca geral ou escolher uma das bases
          de dados disponíveis para fazer uma busca mais detalhada. Para fazer
          uma busca geral, basta digitar um nome ou termo diretamente na caixa
          de texto e pressionar "Enter". Caso queira fazer uma busca mais
          detalhada, clique na base que você deseja e escolha uma das opções
          disponíveis ou digite um nome para pesquisar, quando disponível.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: "2. Quais buscas posso fazer?",
    content: (
      <p>
        O Cerebrum permite fazer pesquisas em várias bases de dados do estado do
        Ceará, como Civil, Criminal, DETRAN, ENEL e SISPEN, além de possuir
        algumas visualizações específicas, como demarcações de AIS e Bordas
        Criminais, listagem dos açudes da FUNCEME, localização em tempo real de
        Câmeras, Tornozelados e Viaturas, e também a consulta de indicadores de
        criminalidade, tudo através da interação com a barra de pesquisas.
      </p>
    ),
  },
  {
    id: 3,
    title: "3. Como fazer uma busca por área?",
    content: (
      <>
        <p>
          Em algumas bases, é possível delimitar a sua área de busca, como na
          busca de Facções, por exemplo. Para isso:
        </p>
        <ol>
          <li>Clique no botão "Delimitar área"</li>
          <li>
            Clique em um ponto no mapa, segurando o botão esquerdo do mouse
          </li>
          <li>
            Arraste o mouse em uma direção para delimitar o raio da área
            desejada
          </li>
        </ol>
      </>
    ),
  },
  {
    id: 4,
    title: "4. Como funciona a base da FUNCEME?",
    content: (
      <>
        <p>
          A base da FUNCEME disponibiliza a listagem de todos os açudes no
          estado com suas localizações no mapa. Cada ponto no mapa representa um
          açude da lista, com um código de cores que representa suas faixas de
          volume de água acumulado. As cores representam as seguintes faixas:
        </p>
        <ul>
          <li>Vermelho: açude com menos de 30% do volume da sua capacidade</li>
          <li>Amarelo: açude com volume entre 30% a 50% da sua capacidade</li>
          <li>Verde: açude com volume de 50% a 90% da sua capacidade</li>
          <li>Azul: açude com mais de 90% do volume da sua capacidade</li>
        </ul>
        <p>
          Ao passar o mouse em cima de um ponto do mapa, são exibidos o nome do
          açude e sua porcentagem de volume acumulado. Para visualizar os
          detalhes de um açude, basta clicar duas vezes em um item da lista ou
          em um ponto do mapa. Se quiser, você pode filtrar a lista de açudes
          pelas faixas de volume acumulado clicando nas porcentagem presentes no
          topo da lista.
        </p>
      </>
    ),
  },
  {
    id: 5,
    title: "5. Como visualizar detalhes de um Tornozelado?",
    content: (
      <p>
        Para visualizar as informações de um tornozelado e sua lista de coletas,
        clique no código do seu alvo na lista para ser direcionado para sua
        localização no mapa e clique duas vezes no ponto referente a ele para
        visualizar seus dados.
      </p>
    ),
  },
  {
    id: 6,
    title: "6. Como exportar uma consulta a uma pessoa?",
    content: (
      <p>
        Para exportar os dados encontrados de uma determinada pessoa, basta
        clicar no botão “Relatório” no canto inferior esquerdo da janela da
        pessoa alvo, selecionar as bases que deseja visualizar no relatório e
        clicar no botão “Gerar relatório”. Assim que o relatório for gerado,
        clique em “Baixar relatório” para finalizar a exportação.
      </p>
    ),
  },
  {
    id: 7,
    title: "7. Todas as bases podem ser acessadas por qualquer usuário?",
    content: (
      <p>
        Não, as consultas disponíveis para as bases de dados dependem das
        permissões concedidas àquele usuário.
      </p>
    ),
  },
  {
    id: 8,
    title: "8. Como gerar a rede de vínculos de uma pessoa?",
    content: (
      <p>
        Para visualizar a rede de vínculos de uma pessoa, escolha uma das bases
        para consultar, selecione o alvo desejado, e clique no botão “Rede de
        vínculos”, localizado no canto inferior esquerdo da janela com os dados
        do seu alvo.
      </p>
    ),
  },
  {
    id: 9,
    title: "9. Para que serve o botão “Possibilidades” na base da SISPEN?",
    content: (
      <p>
        Após ter realizado uma busca por CPF, ao clicar no botão
        “Possibilidades” na base da SISPEN Visitante ou Apenado, são listados os
        possíveis vínculos do alvo nessas bases. Para vincular efetivamente os
        dados de uma opção da lista ao CPF do alvo, basta selecionar o item e
        clicar em “Confirmar vínculo”.
      </p>
    ),
  },
  {
    id: 10,
    title: "10. Como abrir novamente uma janela que foi minimizada?",
    content: (
      <p>
        Para abrir uma janela ou uma visualização que foi minimizada, basta
        clicar no ícone do olho do lado esquerdo do nome da janela ou da
        visualização que você deseja ver novamente.
      </p>
    ),
  },
]);
