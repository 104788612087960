import React, { Component } from 'react'
import { normalizeAddress } from '../../../../helpers/addressModel'
import { App } from '../../../../App'

import { publish } from '../../../../services/EventBus'

import util from '../../../../helpers/util'
import config from '../../../../config'
import srcImageAvatar from '../../../../images/avatar.png'
import { CardSispenVisitasRecebidas } from '../VisitasRecebidas'
import { CardSispenCriminal } from '../Criminal'
import { CardSispenObservacoes } from '../Observacoes'
import { CardSispenColetas } from '../Coletas'
import BodyAsync from '../../../SearchPanel/BodyAsync'


import './index.scss'

export class CardSispenDetalhe extends Component {

  static ROLES = [
    `CEREBRUM_BUSCA_SISPEN`,
  ]

  state = {
    picture: false,
    loading: true,
    extraCards: {},
    message: null,
    open: false,
    closing: false,
    rows: [],
    showBodyAsync: false,
    sispenApenadoDetailsList: [],
    selectedIndex: -1,
  }

  request  = async (url) => {
    return Promise.resolve(
      this.props.http(url).then((data) => {
        return data;
      })
    )
  };

  baseRequest = async (url) => {
    return new Promise((resolve, reject) => {
      return url ? this.request(url).then((response) => {
        return response.error ? reject({error: response.error, url}) : resolve(response);
      }) : reject(false);
    });
  };

  countCheckValidation = (param) => {
    const count = Object.values(param.props.infoValidation).filter(rowValue => rowValue)
    return count.length
  }

  getCPFNumbers = (identidades) => {
    const numbers = {}
    identidades.forEach(i => {
      if (i.documentInfo && i.documentInfo.cpf && i.documentInfo.cpf.length > 0) {
        const { cpf } = i.documentInfo
        numbers[cpf] = true
      }
    })
    return Object.keys(numbers)
  }
  getRGNumbers = (identidades) => {
    const numbers = {}
    identidades.forEach(i => {
      if (i.rgInfo) {
        numbers[i.rgInfo.rg] = true
      }
    })
    return Object.keys(numbers)
  }

  extractRG = (json) => {
    let rg = ``
    if (json.rg) rg += json.rg + ` `
    if (json.rgEmitter) rg += json.rgEmitter + ` `
    if (json.federalUnitRgEmitter) rg += json.federalUnitRgEmitter
    return rg.trim()
  }

  extractCPF = (json) => {
    if (json.cpf)
      return json.cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4')
  }

  createRow = jsonItem => {
    const {
      id,
      name,
      aliases,
      birthDate,
      thumb,
      cpf,
      rg,
      rgProvider,
      regime,
      prisonUnit,
      fatherName,
      motherName,
      infoValidation,
      isChecked,
    } = jsonItem;
    const lines = []
    if (aliases && aliases.length) {
      const alcunha = aliases.filter(i => i && i !== `SEM ALCUNHA`).join(`, `).toUpperCase()
      if (alcunha)
        lines.push([{label: `ALCUNHA`, value: alcunha}])
    }
    lines.push([
      {label: `RG`,  value: rg ? (rg + ` ` + rgProvider) : ``},
      {label: `CPF`, value: cpf ? util.formatCPF(cpf) : ``},
    ])
    lines.push([{label: `Regime`, value: regime + ` / ` + prisonUnit},])
    lines.push([{ label: `Data de Nascimento`, value: birthDate ? util.date2str(birthDate) : `` }])
    lines.push([{label: `Mãe`, value: motherName},])
    lines.push([{label: `Pai`, value: fatherName},])
    return {
      card: `CardSispenDetalhe`,
      props: {id, infoValidation, isChecked},
      image: thumb || `avatar.png`,
      title: `ID ` + id + ` - ` + name,
      lines
    }
  }

  possibilitiesSetup = (identidades) => {
    identidades.sort(
      (a, b) =>
        new Date(b.rgInfo.identityDate || Date.now()).getTime() -
        new Date(a.rgInfo.identityDate).getTime()
    );
    const name = identidades[0].basicInfo.name;

    this.props.http(`/sispen/search`, { name }).then((data) => {
      if(!data.error && data.length){
        const civilCPF = this.getCPFNumbers(identidades);
        const civilRG = this.getRGNumbers(identidades);
        const rows = []
  
        data.forEach((item) => {
          const infoValidation = {
            CPF: false,
            RG: false,
            name: false,
            Pai: false,
            Mãe: false,
            "Data de Nascimento": false,
          };
  
          const { cpf, rg, name, father, mother, birthDate } = item;
  
          if (cpf) {
            if (civilCPF[0] === cpf) {
              item.isChecked = true;
            } else if (rg) {
              if (civilRG[0] === rg) {
                item.isChecked = true;
              }
            }
          } else if (rg) {
            if (civilRG[0] === rg) {
              item.isChecked = true;
            }
          }
  
          if (cpf) {
            if (civilCPF[0] === cpf) {
              infoValidation["CPF"] = true;
            }
          }
          if (rg) {
            if (civilRG[0] === rg) {
              infoValidation["RG"] = true;
            }
          }
          if (name) {
            if (identidades[0].basicInfo.name === name) {
              infoValidation["name"] = true;
            }
          }
          if (father) {
            if (identidades[0].basicInfo.father === father) {
              infoValidation["Pai"] = true;
            }
          }
          if (mother) {
            if (identidades[0].basicInfo.mother === mother) {
              infoValidation["Mãe"] = true;
            }
          }
          if (birthDate) {
            if (identidades[0].basicInfo.birthDate === birthDate) {
              infoValidation["Data de Nascimento"] = true;
            }
          }
  
          item.infoValidation = infoValidation;
          const row = this.createRow(item);
  
          rows.push(row)
        });
  
        const possibilities = rows.sort(
          (a, b) => this.countCheckValidation(b) - this.countCheckValidation(a)
        );
  
        publish(`card::set-identifiers`, {
          hash: this.props.hash,
          data: {
            sispenApenadoPossibilities: rows
          },
        });
  
        if(possibilities.length > 0){
          this.setState({ rows });
        }
  
        const report = possibilities.find(item => item.props.isChecked)
  
        if(report) {
          this.selectRowHandler(report)
          return;
        }
      }

      this.setState({
        loading: false,
      }, this.props.show)
    });
  };
  
  errorHandler = (e) => {
    let message = `Identificador de consulta da base (CÓDIGO, CPF ou RG) não encontrado`;
    if(!e) {
        this.setState({
          message: message.toUpperCase(),
          loading: false,
      }, this.props.show)
      return;
    }
    const {error, url} = e
    const { cpf, rg, http } = this.props
    if(error && error.status === 404) {
      if(url.includes(`rg`) && cpf ) {
        this.baseRequest(this.extractUrlFromProps(true))
          .then(this.successHandler, this.errorHandler) 
        return;
      }

      let query = rg ? { rg } : cpf ? { cpf } : null;

      if (query){
        http(`/civil/details`, query).then((identidades) => {
          const { error } = identidades
          if(error){
            this.setState({
              loading: false,
            }, this.props.show)
            return;
          }
          if (identidades.length > 0) {
            this.possibilitiesSetup(identidades);
            return;
          }
          if (query.rg && cpf) {
            query = { cpf };
            http(`/civil/details`, query).then((rgs) => {
              const { error } = rgs
              if(error){
                this.setState({
                  loading: false,
                }, this.props.show)
                return;
              }
              if (rgs.length > 0) {
                this.possibilitiesSetup(rgs);
                return;
              }
            });
          }
        });
        this.setState({
          loading: false,
        }, this.props.show)
        return;
      }
    }

      if(error && error.message) message = error.message

      publish(`card::set-identifiers`, {hash: this.props.hash, data: {sispenApenado: {error}}})
      this.setState({
          message: message.toUpperCase(),
          loading: false,
      }, this.props.show)
  };

  successHandler = response => this.dataSetup(response);

  dataSetup = (data, skipPath) => {
    const extraCards = {}
    if (this.props.isDuplicated(data)) {
      return this.props.close()
    }
    //
    const { id, name } = data.person
    const { rg, cpf } = data.person.documentation || {}
    //
    this.props.setTitle(`SISPEN ` + name)
    this.setState({ data, loading: false }, this.props.show)
    this.geocodeFocus(data)
    //
    if (this.props.roles.includes(`CEREBRUM_VISITAS_SISPEN`)) {
      extraCards[`CardSispenVisitasRecebidas`] = { id, name }
    }
    if (data.collects && data.collects.length) {
      const collects = this.extractCollects(data.collects, skipPath)
      extraCards[`CardSispenColetas`] = { ...data, collects, id };
    }
    const { legalComments, futureClaims, additionalObservations } = data
    if ((legalComments && (data.legalComments.currentHistoricalCriminal || data.legalComments.criminalRecord)) || futureClaims || additionalObservations) {
      const params = { id, legalComments, futureClaims, additionalObservations }
      extraCards[`CardSispenObservacoes`] = params
    }
    extraCards[`CardSispenCriminal`] = { ...data, id }
    this.setState({extraCards})
    publish(`card::set-identifiers`, {hash: this.props.hash, cpf, rg, data: {sispenApenado: data, sispenApenadoSections: extraCards}})
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.hidden && prevState.picture) {
      return { ...prevState, picture: false }
    }
    return null
  }

  fixAssetPath(path) {
    const host = config.gatewayApp.replace(/\/$/, ``)
    const queryString = Object.entries({...App.headers}).map(i => i.join(`=`)).join(`&`)
    return `${host}/sispen/assets/${path.replace(/^\//,'')}?${queryString}`
  }

  extractCollects(jsonList, skipPath) {
    const collects = jsonList.map(i => {
      return {
        id: i.id,
        date: new Date(i.date),
        type: {
          fingerprints: [...i.fingerprints],
          tattoos: [...i.tattoos],
          photos: [...i.photos],
          voices: [...i.voices],
          videos: [...i.videos],
          bodyMarks: [...i.bodyMarks],
        }
      }
    }).sort((a,b) => a.date.getTime() - b.date.getTime())
    // fix path and description
    collects.forEach(c => {
      Object.entries(c.type).forEach(tuple => {
        const [ type, items ] = tuple
        items.forEach(i => {
          i.path = skipPath ? i.path : this.fixAssetPath(i.path)
          if ([`tattoos`, `bodyMarks`].includes(type)) {
            i.text = [i.bodyRegion || ``, i.category || ``, i.subCategory || ``, i.color || ``, i.description || ``].filter(_ => _.length).join(`, `)
          } else if (type === `videos`) {
            i.text = [i.factionName || ``, i.description || `SEM DESCRIÇÃO`].filter(_ => _.length).join(` - `)
          } else {
            i.text = (!i.category && !i.description) ? `SEM DESCRIÇÃO` : i.description
          }
          if ([`voices`, `videos`].includes(type)) {
            i.text = i.id + ` - ` + i.text
          }
        })
      })
    })
    return collects
  }

  extractUrlFromProps(useCpf = false) {
    const { idSispenApenado: id, rg, cpf } = this.props
    const base = `/sispen/details/`
    if(useCpf) return base + `cpf/` + cpf
    if (id) return base + id
    if (rg) return base + `rg/` + rg
    if (cpf) return base + `cpf/` + cpf
    return false
  }

  componentDidMount() {
    const { data } = this.props
    if(data && data.sispenApenado && !util.objIsEmpty(data.sispenApenado)){
      data.sispenApenadoRowIndex !== null && this.setState({ selectedIndex: data.sispenApenadoRowIndex });
      data.sispenApenadoPossibilities && this.setState({ rows: data.sispenApenadoPossibilities })
      data.sispenDetailsList && this.setState({ sispenDetailsList: data.sispenDetailsList })
      this.setState({ data: data.sispenApenado, extraCards: data.sispenApenadoSections, loading: false }, this.props.show)
      this.geocodeFocus(data.sispenApenado)
    } else {
      const url = this.extractUrlFromProps()
      this.baseRequest(url).then(this.successHandler, this.errorHandler)
    }
  }

  geocodeFocus = json => {
    json.addressInfo = json.homeAddress
    if (json.addressInfo && json.addressInfo.geocode) {
      const address = normalizeAddress(json.addressInfo)
      const { geocode } = address
      const data = {
        name: json.person.name,
        address: address.text,
        point: geocode.position,
        source: geocode.source,
      }
      const zoom = address.address ? 13 : 8
      this.props.publish(`map::zoom`, { point: data.point, zoom })
      setTimeout(() => {
        this.props.publish(`map::marker.add`, { name: `geocode`, point: data.point, data, color: `#3dca85`, className: `square animated`, popup: this.createPopup })
      }, 1)
    }
  }

  createPopup = json => {
    const { name, address, source } = json.data
    return {
      title: name,
      text: address,
      source,
    }
  }

  openAffiliations = () => {
    const { filiation } = this.state.data.person
    if (filiation) {
      const { motherName, fatherName } = filiation
      this.props.openCard(`CardBuscaFonetica`, { mother: motherName, father: fatherName })
    }
  }

  buildContent = (content) => {
    const { id, name } = (this.state.data || {}).person || {}
    return (
      <div className="CardSispenDetalhe">
        {id && name 
          ? <div className="card-title">SISPEN <span>{id} <b>{name}</b></span></div> 
          : <div className="card-title">SISPEN</div> }
        {content}
      </div>
    )
  }

  accordionClick = () => {
    this.setState({closing: true})
    setTimeout(() => {
      this.setState({open: !this.state.open, closing: false})
    }, 100)
  }

  selectRowHandler = (row) => {
    const { id } = row.props;
    this.state.rows.forEach((row, index) => {
      if (row.props.id === id) {
        if(index !== this.state.selectedIndex){
          this.setState({selectedIndex: index})
          publish(`card::set-identifiers`, { hash: this.props.hash, data: {sispenApenadoRowIndex: index} })
          let hasDetails = false
          this.state.sispenApenadoDetailsList.forEach((item) => {
            if(item.person.id === id){
              hasDetails = true
              this.dataSetup(item, true)
            }
          })
          if(!hasDetails){
            const url = `/sispen/details/${id}`
            this.props.http(url).then(data => {
              const error = data.error
              if(error){
                const { message } = error
                this.setState({
                  loading: false,
                  message: message.toUpperCase()
                }, this.props.show)
                publish(`card::set-identifiers`, {hash: this.props.hash, data: {sispenApenado: {error}}})
                return;
              }
              this.setState({ sispenApenadoDetailsList: [...this.state.sispenApenadoDetailsList, data] })
              publish(`card::set-identifiers`, { hash: this.props.hash, data: {sispenApenadoDetailsList: this.state.sispenApenadoDetailsList} })
              this.dataSetup(data)
              publish(`card::set-identifiers`, { hash: this.props.hash, data: {sispenApenado: data} })
            })
          }
        } else {
          this.setState({ selectedIndex: -1, data: null})
          publish(`card::set-identifiers`, { hash: this.props.hash, data: {sispenApenadoRowIndex: -1, sispenApenado: {}} })
        }
      }
    });
  };

  renderUncheckedList = (rows) => {
    if (this.state.showBodyAsync) {
      return (
        <>
          <div className="return-button-container">
            <div onClick={() => { this.setState({ showBodyAsync: !this.state.showBodyAsync })}} className="return-button">CONFIRMAR VÍNCULO</div>
          </div>
          <div className="label-list">Lista de possibilidades:</div>
          {this.state.rows.length > 0 
            ? <BodyAsync fromSispenVisitorCard={true} onClick={this.handleClickRow} rows={rows} selectedIndex={this.state.selectedIndex} handleSelectedRow={this.selectRowHandler} />
            : <p className="message">Não há possíveis registros com os dados utilizados</p>
          }
        </>
      )
    }

    return (
      <>
        <div className="return-button-container">
          <div onClick={() => this.setState({ showBodyAsync: !this.state.showBodyAsync })} className="return-button">POSSIBILIDADES</div>
        </div>
        <p className="message">{this.state.message ? this.state.message : `NENHUM REGISTRO ENCONTRADO`}</p>
      </>
    )
  }

  renderContent = (rows) => {
    if (this.state.showBodyAsync) {
      return (
        <>
          <div className="return-button-container">
            <div onClick={() => { this.setState({ showBodyAsync: !this.state.showBodyAsync })}} className="return-button">CONFIRMAR VÍNCULO</div>
          </div>
          <div className="label-list">Lista de possibilidades:</div>
          {rows.length > 0 
            ? <BodyAsync fromSispenVisitorCard={true} onClick={this.handleClickRow} rows={rows} selectedIndex={this.state.selectedIndex}  handleSelectedRow={this.selectRowHandler} />
            : <p className="message">Não há possíveis registros com os dados utilizados</p>
          }
        </>
      )
    }

    const { hidden } = this.props
    const { picture, extraCards } = this.state

    return (
      <>
        {
          rows.length > 0 &&
          <div className="return-button-container">
            <div onClick={() => this.setState({ showBodyAsync: !this.state.showBodyAsync })} className="return-button">POSSIBILIDADES</div>
          </div>
        }
        <div className={`content ${rows.length ? `w-possibilities` : ``}`}>
            <div className={`CardSispenDetalhe` + (hidden ? `hidden ` : ``) + (picture ? ` picture-mode` : ``)}>
              {this.renderPerson()}
            </div>
            <div className="details_section">
              <button className="accordion" onClick={this.accordionClick}>
                <div className="accordion_title">SISPEN { this.props.id }
                  <span className='accordion_base'>DETALHES</span>
                  <span className={`dropdown_icon${!this.state.open ? ` closed` : ``}`}></span>
                </div>
              </button>
              <div className={`accordion_content${this.state.closing ? ` closing`: ``} ${this.state.open ? ` open` : ` closed`}`}>
              { this.state.open
                ? <>
                  {this.renderFiliation()}
                  {this.renderAddresses()}
                  {this.renderReferrals()}
                  {this.renderBenefits()}
                  {this.renderQuestionnaire()}
                </>
                : null }
              </div>
            </div>
            {extraCards[`CardSispenCriminal`] && <CardSispenCriminal {...{...extraCards[`CardSispenCriminal`], ...this.props}}/>}
            {extraCards[`CardSispenObservacoes`] && <CardSispenObservacoes {...{...extraCards[`CardSispenObservacoes`], ...this.props}}/>}
            {extraCards[`CardSispenVisitasRecebidas`] && <CardSispenVisitasRecebidas {...{...extraCards[`CardSispenVisitasRecebidas`], ...this.props}}/>}
            {extraCards[`CardSispenColetas`] && <CardSispenColetas {...{...extraCards[`CardSispenColetas`], ...this.props}}/>}
        </div>
      </>
    )

  }

  render() {
    const { loading, rows, data } = this.state

    if(this.props.hidden){
      return null
    }

    if(loading){
      return this.buildContent(
        <div className="message-loading">
          CARREGANDO...
        </div>)
    }

    if (!data) {
      return this.buildContent(this.renderUncheckedList(rows))
    }

    return this.buildContent(this.renderContent(rows))
  }

  togglePictureSize = () => {
    const { picture } = this.state
    this.setState({ picture: !picture })
  }

  renderPerson() {
    const empty = !this.state.data.thumb
    const thumb = this.state.data.thumb || srcImageAvatar
    const src = /^data/.test(thumb) ? thumb : (`data:image/png;base64,` + thumb)
    return (
      <div className="group columns">
        <div className="column photo">
          <img src={src} alt="" className={`picture ` + empty ? `default` : ``} onClick={this.togglePictureSize}/>
        </div>
        <div className="column info">{this.renderPersonInfo()}</div>
      </div>
    )
  }

  extractAlcunha() {
    const { aliases } = this.state.data.person
    if (aliases && aliases.length) {
      const alcunha = aliases.filter(i => i && i !== `SEM ALCUNHA`).join(`, `).toUpperCase()
      if (alcunha)
        return alcunha
    }
  }

  renderPersonInfo() {
    const { person, somatic } = this.state.data
    const doc = person.documentation
    const line1 = [
      { name: `IDADE`, value: person.birthDate ? (util.calcAge(person.birthDate) + ` anos`) : `` },
      { name: `NASC.`, value: person.birthDate ? util.date2str(person.birthDate) : `` },
      { name: `GÊNERO`, value: person.demographic.gender },
      { name: `ESTADO CIVIL`, value: person.demographic.maritalStatus },
    ].filter(i => i.value.trim().length > 0)
    const line2 = [
      { name: `ESCOLARIDADE`, value: person.demographic.schooling },
      { name: `CIDADE`, value: person.location.city ? (person.location.city + ` - ` + person.location.state) : `` },
    ].filter(i => i.value.trim().length > 0)
    const line3 = [
      { name: `ALCUNHA`, value: this.extractAlcunha() || `` },
      { name: `RG`, value: doc && doc.rg ? (doc.rg + ` ` + (doc.rgProvider)) : `` },
      { name: `CPF`, value: doc && doc.cpf ? util.formatCPF(doc.cpf) : `` },
    ].filter(i => i.value.trim().length > 0)
    const line4 = [
      { name: `ALTURA`, value: somatic.height },
      { name: `PESO`, value: somatic.weight },
      { name: `RAÇA`, value: somatic.humanBreed },
      { name: `PELE`, value: somatic.skinColor, className: `max-width` },
      { name: `OLHOS`, value: somatic.eyes },
    ]
    const line5 = [
      { name: `NARIZ`, value: somatic.nose },
      { name: `BOCA`, value: somatic.mouthSize },
      { name: `BARBA`, value: somatic.beardColor },
      { name: `BIGODE`, value: somatic.moustache },
      { name: `CABELO`, value: somatic.hairColor, className: `max-width` },
    ]
    return (
      <React.Fragment>
        {this.renderValues(line1, `text no-border`)}
        {this.renderValues(line2, `text no-border`)}
        {this.renderValues(line3, `text no-border`)}
        {this.renderValues(line4, `text no-border`)}
        {this.renderValues(line5, `text no-border`)}
      </React.Fragment>
    )
  }

  renderFiliation() {
    const { roles } = this.props
    const allowSearchAffiliations = roles.includes(`CEREBRUM_BUSCA_CIVIL_AFILIACOES`) || roles.includes(`CEREBRUM_BUSCA_CRIMINAL_AFILIACOES`)
    const { filiation } = this.state.data.person
    const mother = (filiation || {}).motherName || `-`
    const father = (filiation || {}).fatherName || `-`
    return (
      <div className="group first-group column info">
        <div className="header-line">FILIAÇÃO</div>
        <div className={`clickable` + (allowSearchAffiliations ? `` : ` blocked`)} onClick={this.openAffiliations}>
          {mother}
          <br />
          {father}
        </div>
      </div>
    )
  }

  renderAddresses() {
    const { homeAddress = {}, businessAddress = {} } = this.state.data
    if (!homeAddress.street && !homeAddress.city && !businessAddress.street && !businessAddress.city) {
      return null
    }
    return (
      <div className="group column info">
        <div className="header-line">ENDEREÇO</div>
        {this.renderAddress(`RESIDENCIAL`, homeAddress)}
        {this.renderAddress(`COMERCIAL`, businessAddress)}
      </div>
    )
  }

  renderAddress(label, json = {}) {
    const { street, number, complement, district, city, state, zipCode, landline, cellPhone } = json;

    if (!street && !city) return null

    const part1 = street && (
      street +
      (number ? `, ` + number : ``) +
      (complement ? ` ` + complement : ``)
    )

    const part2 = district

    const part3 = city && (
      city +
      (state ? ` ` + state : ``)
    )

    const value = [part1, part2, part3].filter(x => x).join(' - ')

    const items = [{ name: label, value, className: `max-width` }]
    if (zipCode)
      items.push({ name: `CEP`, value: util.formatCEP(zipCode) })
    if (landline || cellPhone)
      items.push({ name: `Telefone`, value: this.extractPhoneNumbers(json) })
    return this.renderValues(items, `text`)
  }

  extractPhoneNumbers = json => {
    const list = []
    const phones = [json.landline, json.cellPhone]
    phones.forEach(text => {
      (text || ``).split(`/`).forEach(number => {
        list.push(number.trim())
      })
    })
    return list.filter(i => i).map(util.formatPhone).join(` / `)
  }

  renderReferrals() {
    const json = this.state.data.referrals
    const items = [
      { name: `Dentista`, value: json.dental },
      { name: `Jurídico`, value: json.juridical },
      { name: `Médico`, value: json.medical },
      { name: `Psiquiátrico`, value: json.psychiatric },
      { name: `Psicológico`, value: json.psychological },
      { name: `Serviço Social`, value: json.socialService },
    ]
    return (
      <div className="group column info">
        <div className="header-line">Encaminhamento</div>
        {this.renderValues(items, `inline`)}
      </div>
    )
  }

  renderBenefits() {
    const json = this.state.data.benefits
    const items = [
      { name: `Bolsa Família`, value: json.bolsaFamilia },
      { name: `Aposentadoria`, value: json.retirement },
      { name: `Aux. Reclusão`, value: json.confinementAid },
      { name: `Prestação Continuada`, value: json.continuedServiceBenefit },
      { name: `Plano de Saúde`, value: json.healthInsurance },
      { name: `Outros`, value: json.others },
    ]
    return (
      <div className="group column info">
        <div className="header-line">Benefício</div>
        {this.renderValues(items, `inline`)}
      </div>
    )
  }

  renderQuestionnaire() {
    const json = this.state.data.questionnaire
    const items = [
      { name: `Fumante`, value: json.smoker },
      { name: `Alcoólatra`, value: json.alcoholic },
      { name: `Cocaína`, value: json.cocaine },
      { name: `Crack`, value: json.crack },
      { name: `Maconha`, value: json.marijuana },
      { name: `Psicotrópico`, value: json.psychotropics },
      { name: `Solventes`, value: json.inhalantGlue },
      { name: `Outras drogas`, value: json.otherDrugs },
    ]
    return (
      <div className="group column info">
        <div className="header-line">Questionário</div>
        {this.renderValues(items, `inline`)}
        {json.reason ? <p className="message">{json.reason}</p> : null}
      </div>
    )
  }

  renderValues(items, className = ``) {
    if (!items || items.length === 0) {
      return null
    }
    return (
      <div className={`line-values ${className}`}>
        {items.map((i, index) => (
          <div className={`item ` + (i.className || ``)} key={index}>
            <div className="label">{i.name}</div>
            <div className="value">{i.value || `-`}</div>
          </div>
        ))}
      </div>
    )
  }

}
