
import * as type from '../fieldTypes'

export const CadTeamsActive = {
  created: `2019-11-04`,
  context: `CICCM.AIS 13.Equipes`,
  role: `CEREBRUM_CAD_EQUIPES_ATIVAS`,
  title: 'Equipes ativas',
  description: 'Visualizar todas as equipes que estão em atividade no momento',
  parser: () => ({ card: `CardCadTeamsList`, props: {} }),
}

export const CadTeamsHistory = {
  created: `2019-11-04`,
  context: `CICCM.AIS 13.Equipes`,
  role: `CEREBRUM_CAD_EQUIPES_HISTORICO`,
  params: {
    startDateTime: {label: `Data Inicial`, placeholder: `dd/mm/aaaa hh:mm`, type: type.datetime},
    endDateTime: {label: `Data Final`, placeholder: `dd/mm/aaaa hh:mm`, type: type.datetime},
    vehicleCode: {label: `Código do veículo`, type: type.text, optional: true},
  },
  parser: json => ({ card: `CardCadTeamsList`, props: json }),
}

export const CadOccurencesActive = {
  created: `2019-11-04`,
  context: `CICCM.AIS 13.Ocorrências`,
  role: `CEREBRUM_CAD_OCORRENCIAS_ATIVAS`,
  title: 'Ocorrências ativas',
  description: 'Visualizar todas as ocorrências que estão acontecendo no momento',
  parser: () => ({ card: `CardCadOccurrencesList`, props: {} }),
}

export const CadOccurencesSearch = {
  created: `2019-11-04`,
  context: `CICCM.AIS 13.Ocorrências`,
  role: `CEREBRUM_CAD_OCORRENCIAS_HISTORICO`,
  params: {
    startDateTime: {label: `Data Inicial`, placeholder: `dd/mm/aaaa hh:mm`, type: type.datetime},
    endDateTime: {label: `Data Final`, placeholder: `dd/mm/aaaa hh:mm`, type: type.datetime},
  },
  parser: json => ({ card: `CardCadOccurrencesList`, props: json }),
}

export const CadOccurencesDetails = {
  created: `2019-11-05`,
  context: `CICCM.AIS 13.Ocorrências`,
  role: `CEREBRUM_CAD_OCORRENCIAS_HISTORICO`,
  params: {
    code: {label: `Código`, placeholder: ``, type: type.text},
  },
  parser: json => ({ card: `CardCadOccurrenceDetails`, props: json }),
}
