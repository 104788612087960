import { StyleSheet, Font } from "@react-pdf/renderer";
import MontserratBold from "../../fonts/Montserrat-Bold.ttf";
import RobotoBold from "../../fonts/Roboto-Bold.ttf";
import RobotoRegular from "../../fonts/Roboto-Regular.ttf";
import ShareTech from "../../fonts/ShareTech-Regular.ttf";

Font.register({
  family: "RobotoBold",
  fonts: [{ src: RobotoBold }],
});
Font.register({
  family: "Roboto",
  fonts: [{ src: RobotoRegular }],
});

Font.register({
  family: "MontserratBold",
  fonts: [{ src: MontserratBold }],
});

Font.register({
  family: "Share Tech",
  fonts: [{ src: ShareTech }],
});

export const Styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#ffffff",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 30,
    paddingRight: 30,
  },
  pdfTitle: {
    fontWeight: 700,
    fontSize: "32px",
    color: "#4C4C4C",
    textAlign: "center",
    marginBottom: 20,
    fontFamily: "MontserratBold",
  },
  base: {
    display: "flex",
    flexDirection: "column",
  },
  baseTitle: {
    fontSize: 16,
    marginBottom: 10,
    marginTop: 10,
    paddingLeft: 10,
    borderLeftWidth: 4,
    borderColor: "#DBA70A",
    color: "#DBA70A",
    fontFamily: "Share Tech",
    minHeight: 16,
  },
  criminalItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 5,
  },
  criminalYear: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    fontSize: 10,
    color: "#4C4C4C",
    fontFamily: "RobotoBold",
  },
  criminalInfo: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    fontFamily: "Roboto",
    color: "#4C4C4C",
    fontSize: 10,
  },
  criminalDesc: {
    color: "#808080",
  },
  sispenText: {
    fontSize: 10,
    color: "#4C4C4C",
    fontFamily: "Roboto",
  },
  sispenCriminalInfo: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    fontFamily: "Roboto",
    color: "#4C4C4C",
    fontSize: 10,
    marginBottom: 10
  },
  sispenCriminalNumber: {
    color: "#4C4C4C",
    fontFamily: "RobotoBold",
  },
  sispenCriminalRow: {
    display: "flex",
    flexDirection: "row",
  },
  sispenCriminalLabel: {
    color: "#808080",
    fontFamily: "Share Tech",
    marginRight: 5
  },
  sispenCriminalValue: {
    marginRight: 5
  },
  subSectionTitle: {
    fontSize: 14,
    marginBottom: 10,
    marginTop: 10,
    paddingLeft: 10,
    borderLeftWidth: 4,
    borderColor: "#808080",
    color: "#808080",
    fontFamily: "Share Tech",
    minHeight: 14,
  },
  subBaseTitle: {
    fontSize: 14,
    marginBottom: 10,
    marginTop: 10,
    paddingLeft: 10,
    borderLeftWidth: 4,
    borderColor: "#DBA70A",
    color: "#DBA70A",
    fontFamily: "Share Tech",
    minHeight: 14,
  },
  bottomContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  mainContainer: {
    display: "flex",
    flexDirection: "column",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    padding: 10,
  },
  contentContainer: {
    marginLeft: 5,
    flexGrow: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  sispenContentContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  visitorItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 5,
  },
  visitorDateContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
  visitorDateText: {
    fontSize: 10,
    color: "#4C4C4C",
    fontFamily: "Roboto",
  },
  visitorTimeText: {
    fontSize: 10,
    color: "#4C4C4C",
    fontFamily: "RobotoBold",
  },
  prisonerInfo: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    fontFamily: "Roboto",
    color: "#4C4C4C",
    fontSize: 10,
  },
  prisonerBottomInfo: {
    display: "flex",
    flexDirection: "row",
  },
  prisonerTextContainer: {
    display: "flex",
    flexDirection: "row",
    paddingRight: 10,
  },
  prisonerLightText: {
    paddingRight: 3,
    fontSize: 10,
    color: "#858585",
    fontFamily: "Roboto",
  },
  prisonerName: {
    fontSize: 10,
    color: "#4C4C4C",
    fontFamily: "RobotoBold",
  },
  item: {
    marginRight: 8,
    marginBottom: 8,
    flexGrow: 1,
    paddingBottom: 8,
    paddingLeft: 8,
    borderColor: "#9BA2A6",
    borderTopWidth: 1.2,
    borderBottomWidth: 1.2,
    borderLeftWidth: 1.2,
    flexDirection: "column",
    height: "auto",
  },  
  sispenItem: {
    marginRight: 8,
    marginBottom: 8,
    paddingBottom: 8,
    paddingLeft: 8,
    borderColor: "#9BA2A6",
    borderTopWidth: 1.2,
    borderBottomWidth: 1.2,
    borderLeftWidth: 1.2,
    flexDirection: "column",
  },
  sispenErrorItem: {
    width: "100%",
    marginRight: 8,
    marginBottom: 8,
    paddingBottom: 8,
    paddingLeft: 8,
    borderColor: "#9BA2A6",
    borderTopWidth: 1.2,
    borderBottomWidth: 1.2,
    borderLeftWidth: 1.2,
    flexDirection: "column",
  },
  sispenImageContainer: {
    width: "100%",
    maxHeight: 320,
    marginRight: 10,
    flexDirection: "column",
    flexWrap: "wrap",
  },
  info: {
    fontSize: 10,
    color: "#4C4C4C",
    padding: 10,
    fontFamily: "RobotoBold",
  },
  value: {
    fontSize: 10,
    paddingRight: 8,
    color: "#4C4C4C",
    fontFamily: "Share Tech",
  },
  label: {
    display: "flex",
    fontSize: 8,
    color: "#9BA2A6",
    paddingLeft: 3,
    backgroundColor: "#fff",
    fontFamily: "RobotoBold",
    transform: "translateY(-5)",
    textTransform: "uppercase",
  },
  labelContainer: {
    display: "flex",
    width: "100%",
  },
  imageContainer: {
    width: 100,
    marginRight: 10,
    flexDirection: "column",
    flexWrap: "wrap",
  },
  image: {
    height: "auto",
  },
  imageBottom: {
    flexGrow: 1,
  },
  containerLogos: {
    display: "flex",
    flexDirection: "row",
  },
  logoStyle: {
    width: "auto",
    height: 30,
    paddingRight: 15,
    paddingLeft: 5
  },
  headerRow: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 5,
    marginBottom: 20,
    borderBottom: 1,
    borderColor: "#4C4C4C",
  },
  headerColumn: {
    display: "flex",
    flexDirection: "column",
  },
  headerText: {
    fontSize: 8,
    paddingBottom: 2,
    color: "#4C4C4C",
    fontFamily: "RobotoBold",
  },
  subHeader: {
    display: "flex",
    flexDirection: "column",
  },
  subHeaderTitle: {
    alignSelf: "center",
    textAlign: "center",
    fontSize: 12,
    paddingBottom: 20,
    color: "#4C4C4C",
    fontFamily: "RobotoBold",
  },
  error: {
    display: "flex",
    justifyContent: "center",
    fontSize: 10,
    marginVertical: 10,
    paddingLeft: 5,
    marginLeft: 10,
    borderLeftWidth: 4,
    borderColor: "#D14D4D",
    color: "#4C4C4C",
    fontFamily: "Roboto",
    minHeight: 16,
  },
  footerContainer: {
    marginTop: "auto",
    border: 1,
    borderColor: "#4C4C4C",
    padding: 10,
  },
  footerText: {
    alignSelf: "center",
    textAlign: "center",
    fontSize: 8,
    paddingBottom: 5,
    color: "#4C4C4C",
    fontFamily: "Roboto",
  },
  notFoundText: {
    fontFamily: "RobotoBold",
    color: "#4C4C4C",
    textAlign: "center",
    fontSize: 10,
  },
});
