
/**
 * gerencia uma lista de itens onde cada item possui um atributo `query`
 * query representa o objeto em uma linha de texto utilizada em consulta.
 * após aplicar filtro, métodos para paginação poderão ser utilizados.
 */
export class SearchListManager {

  items = []
  filteredItems = []

  setItems(items=[], obj2query=null) {
    if (obj2query) {
      items = items.map(i => ({...i, query: obj2query(i)}))
    }
    this.items = items
    this.filteredItems = items
    return this
  }

  applyFilter(query=``) {
    const words = query.trim().toUpperCase().split(/\s+/)
    this.filteredItems = this.items.filter(i => {
      const matches = words.filter(w => i.query.indexOf(w) !== -1)
      const visible = words.length === 0 || matches.length === words.length
      i.hidden = !visible
      return visible
    })
    return this.filteredItems
  }

  getItems() {
    return this.items
  }

  getFilteredItems() {
    return this.filteredItems
  }

  getTotal() {
    return this.items.length
  }

  getTotalVisible() {
    return this.filteredItems.length
  }

  getTotalHidden() {
    return this.items.length - this.filteredItems.length
  }

}
