import React, { Component } from 'react'
import util from '../../../../helpers/util'
import { publish } from "../../../../services/EventBus";
import Carteira from './Carteira'
import './index.scss'

export class CardCNHHabilitacao extends Component {

  static ROLES = [
    `CEREBRUM_BUSCA_CNH_CPF`,
  ]

  state = {
    data: null,
    loading: true,
    message: null
  }

  dataSetup(cnh) {
    const { empty, message } = cnh
    this.setState({loading: false})
    const {idDetran: id} = this.props
    
    if(empty){
      if(message){
        this.setState({message: message.toUpperCase()})
      }
      publish(`card::set-identifiers`, { hash: this.props.hash, cpf: cnh.cpf || id })
      // setTimeout(() => this.setState({ loading: false }), 2000)
      this.props.show()
      this.props.setTitle(`CNH ` + util.formatCPF(id))
       // COMENTAMOS ESSA BUSCA
      // this.props.openCard(`CardCivilHistorico`, {cpf: cnh.cpf || id})
      return
    }
    if (this.props.isDuplicated(cnh)) {
      return this.props.close()
    }
    // setTimeout(() => this.setState({ loading: false }), 2000)
    this.setState({data: cnh}, () => {
      this.props.show()
      this.props.setTitle(`CNH ` + cnh.name)
       // COMENTAMOS ESSA BUSCA
      //this.props.openCard(`CardCivilHistorico`, {cpf: cnh.cpf || id})
      if (this.props.roles.includes(`CEREBRUM_VISITANTES_SISPEN`)) {
         // COMENTAMOS ESSA BUSCA
        // this.props.openCard(`CardSispenVisitante`, {cpf: cnh.cpf || id})
      }
    })
  }

  componentDidMount() {
    if(this.props.data && this.props.data.cnh && !util.objIsEmpty(this.props.data.cnh) && !this.props.data.cnh.error){
      this.dataSetup(this.props.data.cnh)
    } else {
      const {idDetran: id} = this.props

      if (id){
        this.props.http(`/detran/cnh/` + id).then(cnh => {
          const { error } = cnh
          if (error) {
            publish(`card::set-identifiers`, { hash: this.props.hash, data: {cnh: {error}} })
            return util.handleError(
              error,
              this.props.show,
              () => {
                this.dataSetup({ empty: true })
              },
              (message) => {
                this.dataSetup({ empty: true, message })
              }
            );
          }
          if(!cnh.cnhNumber) 
            return this.dataSetup({ empty: true })
          const rg = cnh.rg.split(' ')[0]
          publish(`card::set-identifiers`, { hash: this.props.hash, cpf: cnh.cpf || id, rg, data: {cnh} })
          this.dataSetup(cnh)
        })
      } else {
        this.dataSetup({ empty: true })
      }
    }
  }

  loadVehicles = () => {
    if (this.props.roles.includes(`CEREBRUM_BUSCA_VEICULOS_CPF_CNPJ`)) {
     // COMENTAMOS ESSA BUSCA
    //   this.props.openCard(`CardCNHVeiculos`, {id: id})
    }
  }

  getTitleText = () => {
    const { uf } = this.state.data || {}
    return `DETRAN` + (uf ? ` - ${uf}` : ``)
  }

  render() {
    if (this.props.hidden) {
      return null
    }
    return (
      <div className="CardCNHHabilitacao">
        <div className="title">CNH <span style={{paddingLeft: 5, opacity: 0.5}}>{ this.getTitleText() }</span></div>
        { this.state.loading 
            ?  <div className="message-loading">CARREGANDO...</div> 
            :  this.state.data 
                ? <Carteira roles={ this.props.roles } openCard={ this.props.openCard } {...this.state.data} /> 
                : <p className="message">{this.state.message ? this.state.message : `NENHUM REGISTRO ENCONTRADO`}</p>}
      </div>
    )
  }

}
