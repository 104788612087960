
import React, { Component } from 'react'
import util from '../../../../helpers/util'
import './Carteira.scss'

const Item = (props) => (
  <div className="container-item">
    <div className={`item ` + (props.className || ``)}>
      <label>{props.label}</label>
      <div className="value">{props.children || `-`}</div>
    </div>
  </div>
)

const ItemObs = (props) => (
  <div className="container-item">
    <div className={`item ` + (props.className || ``)}>
      <label>{props.label}</label>
      <div className="value break-words">{props.children || `-`}</div>
    </div>
  </div>
)

export default class Carteira extends Component {

  state = {
    picture: false,
    groups: []
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.hidden && prevState.picture) {
      return { ...prevState, picture: false }
    }
    return null
  }

  openAffiliations = () => {
    const { mother, father } = this.props
    this.props.openCard(`CardBuscaFonetica`, { mother, father })
  }

  renderPicture() {
    let url = this.props.image
    if (!url) {
      url = `#`
    } else if (!/\.\w{2,}$/.test(url) && !/^data/.test(url)) {
      url = `data:image/png;base64,${url}`
    }
    return (
      <div className={`picture` + (url ? ` empty` : ``)} onClick={this.togglePictureSize}>
        <img src={url} alt="" />
      </div>
    )
  }

  togglePictureSize = () => {
    const { picture } = this.state
    this.setState({ picture: !picture })
  }

  render() {
    const { roles } = this.props
    const allowSearchAffiliations = roles.includes(`CEREBRUM_BUSCA_CIVIL_AFILIACOES`) || roles.includes(`CEREBRUM_BUSCA_CRIMINAL_AFILIACOES`)
    const i = this.props
    const { hidden } = this.props
    const { picture } = this.state
    return (

      <div className={`CNHHabilitacaoCarteira ` + (hidden ? `hidden ` : ``) + (picture ? ` picture-mode` : ``)}>

        <div className="columns row">
          <div className={`column photo ${!i.image ? 'empty' : ''} `}>
            {this.renderPicture()}
          </div>

          <div className="column info">

            <div className="row">
              <Item label="NOME">{i.name}</Item>
            </div>
            <div className="row">
              <Item label="SEXO">{i.sex}</Item>
              <Item label="NACIONALIDADE">{i.nationality}</Item>
              <Item label="CIDADE">{i.bornPlace}</Item>
            </div>
            <div className="row">
              <Item label="DOC IDENTIDADE / ORG EMISSOR / UF">{i.rg}</Item>
            </div>
            <div className="row">
              <Item label="CPF">{util.formatCPF(i.cpf)}</Item>
              <Item label="DATA NASC">{i.bornDate}</Item>
            </div>
            <div className="row">
              <Item label="FILIAÇÃO" className="min80">
                <div className={`clickable` + (allowSearchAffiliations ? `` : ` blocked`)} onClick={this.openAffiliations}>
                  {i.father}
                  <br />
                  {i.mother}
                </div>
              </Item>
            </div>
            <div className="row">
              <Item label="PERMISSÃO">{i.permissionHolder}</Item>
              <Item label="CATEGORIA">{i.category}</Item>
            </div>
            <div className="row">
              <Item label="Nº REGISTRO">{i.cnhNumber}</Item>
              <Item label="VALIDADE">{i.cnhValidity}</Item>
              <Item label="Nº PONTOS">{i.faultPoints}</Item>
            </div>


            <div className="row">
              <ItemObs label="OBSERVAÇÕES">{i.observation}</ItemObs>
            </div>

          </div>

        </div>

      </div>
    )
  }

}

