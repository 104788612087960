import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import './Notifications.scss'

export default class HeaderRightNotifications extends Component {

  componentDidMount() {
    setTimeout(_ => {
      ReactDOM.findDOMNode(this).classList.add(`visible`)
    }, 1900);
  }
  
  render() {
    const count = 0
    return (
      <div className={ `HeaderRightNotifications` + (count ? `` : ` empty`) }>
        <b>{ count }</b>
      </div>
    );
  }

}
