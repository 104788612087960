import React, { useEffect, useState } from 'react';
import util from '../../../../helpers/util';
import { cpf as cpfType } from '../../../SearchPanel/fieldTypes'
import './Details.scss';
import './PersonsDetails.scss';

export function CardCadPersonsDetails({
  show,
  setTitle,
  openCard,
  hidden,
  victim,
  offender,
  notifier,
  witnesses = [],
}) {

  const [ selectedTab, setSelectedTab ] = useState(-1);

  const personTabs = [ victim, offender, notifier, ...witnesses ];

  useEffect(() => {
    show();
    setTitle('ENVOLVIDOS');
  }, []);

  useEffect(() => {
    if (selectedTab === -1) {
      if (victim) setSelectedTab(0);
      else if (offender) setSelectedTab(1);
      else if (notifier) setSelectedTab(2);
      else if (witnesses && witnesses.length > 0) setSelectedTab(3);
    }
  }, [ victim, offender, notifier, witnesses, selectedTab ])

  const emptyItem = item => !item || (item.value || ``).toString().trim().length === 0;
  const nonEmptyItem = item => !emptyItem(item)

  function renderValues(items, className=``) {
    if (!items || items.length === 0 || items.filter(emptyItem).length === items.length) {
      return null;
    }
    const key = items.map(v => `${v.name}-${v.value}`).toString();
    return (
      <div key={key} className={ `line-values ${className}` }>
        {items.map((i, index) => (
          <div className={ `item ` + (i.className || ``) } key={ index }>
            <div className="label">{ i.name }</div>
            <div className="value">{ i.value || `-` }</div>
          </div>
        ))}
      </div>
    )
  }

  function getGenderValue({ gender }) {
    if (!gender) return 'NÃO INFORMADO';
    if (gender === 'M') return 'MASCULINO';
    if (gender === 'F') return 'FEMININO';
    return 'INDEFINIDO';
  }

  function openCivilDetailsCard({ rg, cpf, name }) {
    if (cpf || rg) openCard(`CardCivilHistorico`, { rg, cpf })
    else openCard(`CardBuscaFonetica`, { name })
  }

  function renderName( person ) {
    
    return (
      <div className="cpf-button" onClick={() => openCivilDetailsCard(person)}>
        { person.name }
        <div className="cpf-button-image"/>
      </div>
    ) ;
  }

  function renderPerson( person ) {
    const { 
      createdAt,
      lastUpdate,
      cpf,
      rg,
      mother,
      father,
      address,
      birthDate,
      birthCity, 
      birthState,
      isTourist,
    } = person;

    const line1 = [
      {name: `Nome`, value: renderName(person)},
      {name: `Mãe`, value: mother || '-'},
      {name: `Pai`, value: father || '-'},
      {name: `Sexo`, value: getGenderValue( person )},
      {name: `CPF`, value: cpf ? cpfType.fixText(cpf) : '-'},
      {name: `RG`, value: rg || '-'},
      {name: `Nascimento`, value: `${birthDate && util.date2str(birthDate)} ${birthCity}-${birthState}`},
    ];
    const line2 = [
      {name: `Turista`, value: isTourist ? 'SIM' : 'NÃO'},
      {name: `Endereço`, value: address.addressStreet},
      {name: `Bairro`, value: address.district},
      {name: `Cidade`, value: address.city},
    ].filter(nonEmptyItem);

    const line3 = [
      {name: `Criação`, value: createdAt && util.datetime2str(createdAt)},
      {name: `Última atualização`, value: lastUpdate && util.datetime2str(lastUpdate)},
    ].filter(nonEmptyItem);
    

    return (
      <React.Fragment>
        { renderValues(line1, `text inline`) }
        { renderValues(line2, `text inline spaced`) }
        { renderValues(line3, `text no-border centered background`) }
      </React.Fragment>
    )
  }

  function renderTabButton( position, title ) {
    return (
      <button 
        key={title}
        className={'' + ( selectedTab === position ? ' selected' : '' )}
        onClick={() => setSelectedTab(position)}>
        <div> { title } </div>
      </button>
    );
  }
  
  if ( hidden || selectedTab === -1 ) return null;

  const person = personTabs[selectedTab];

  return (
    <div className="CardCadOccurrenceDetails">
      <div className="card-title">
        CICCM AIS 13
        <span className="light">ENVOLVIDOS</span>
      </div>
      <div className="involved-container">
        <div className="involved-tabs">
          { victim && renderTabButton(0, 'Vítima') }
          { offender && renderTabButton(1, 'Autor') }
          { notifier && renderTabButton(2, 'Notificante') }
          { witnesses.length > 0 && (
            <React.Fragment>
              <div className="tab-separator"/>
              { [...witnesses.keys()].map( i => renderTabButton(i+3, `Testemunha ${i+1}`)) }
            </React.Fragment>
          )}
        </div>
        <div className="content">
          { renderPerson(person) }
        </div>
      </div>
      
    </div>
  );

}

