import React, { Component } from 'react'
import './index.scss'

let INDEX_COUNTER = 0

export class CardInepEscola extends Component {

  componentDidMount() {
    this.download().then(escolas => {
      const json = escolas[INDEX_COUNTER]
      INDEX_COUNTER = INDEX_COUNTER < escolas.length - 1 ? INDEX_COUNTER + 1 : 0
      const { uf, ufName, nome, tipo, status, alunos, address, cep, phone, point } = this.props
      this.props.publish(`map::marker.add`, {point, color: `#e6a641`, className: `circle border`})
      this.setState({json, uf, ufName, tipo, status, alunos, address, cep, phone})
      this.props.show()
      this.props.setTitle(nome)
    })
  }

  download() {
    return new Promise((resolve) => {
      const baseUrl = process.env.PUBLIC_URL
      this.props.http(`${baseUrl}/inep/escolas.json`).then(json => {
        if (typeof json === `string`) {
          json = JSON.parse(json)
        }
        window.cache = json
        resolve(json)
      })
    })
  }

  render() {
    if (this.props.hidden) {
      return null
    }
    return (
      <div className="CardInepEscola">
        <div className="title"><span>{ this.props.nome }</span></div>
        { this.renderBasicInfo() }
        { this.renderMatriculas() }
        { this.renderEstrutura() }
      </div>
    )
  }

  photoClickHandle = () => {
    const photoFocus = !this.state.photoFocus
    this.setState({photoFocus})
  }

  renderBasicInfo() {
    const { photoFocus } = this.state
    const { text, status, address, cep, phone } = this.props
    const { inicioAnoLetivo, terminoAnoLetivo, foto } = this.state.json
    return (
      <div className="basic-info">
        <div className="status">{ status } - { text.split(` - `)[1] }</div>
        <div className={ `panel` + (photoFocus ? ` photo-focus` : ``) }>
          <div className="info">
            <div className="periodo">{ inicioAnoLetivo } - { terminoAnoLetivo }</div>
            <div>{ address }</div>
            { cep   ? <div className="extra" key={ 1 }>CEP { cep }</div> : null }
            { phone ? <div className="extra" key={ 2 }>TEL { phone }</div> : null }
          </div>
          <div className="photo" onClick={ this.photoClickHandle }>
            <div style={{backgroundImage: `url(${foto})`}}></div>
          </div>
        </div>
      </div>
    )
  }

  renderMatriculas() {
    const m = this.state.json.matriculas
    const values = [
      [`Educação Básica`,    m.matEducacaoBasica],
      [`Educação Infantil`,  m.matEducacaoInfantil],
      [`Educação Especial`,  m.matEducacaoEspecial],
      [`Creche`,             m.matCreche],
      [`Pré Escola`,         m.matPreEscola],
      [`Ensino Fundamental`, m.matEnsinoFundamental],
      [`1ª - 4ª série`,      m.mat1a4SerieAnosIniciais],
      [`5ª - 8ª série`,      m.mat5a8SerieAnosFinais],
      [`Fundamental 8 anos`, m.matEnsinoFundamental8Anos],
      [`Fundamental 9 anos`, m.matEnsinoFundamental9Anos],
      [`Ensino Médio`,       m.matEnsinoMedio],
      [`Médio Integrado`,    m.matEnsinoMedioIntegrado],
      [`Médio Magistério`,   m.matEnsinoMedioMagisterio],
      [`Ensino Profissional`,m.matEnsinoProfissional],
      [`EJA`,                m.matEJA],
    ]
    const column_a = values.slice(0,8)
    const column_b = values.slice(8)
    return (
      <div className="group background">
        <div className="caption">Matrículas</div>
        <div className="column a">
          {column_a.map(i => (
            <div className="item" key={ i[0] }>
              <b>{ i[1] }</b><label>{ i[0] }</label>
            </div>
          ))}
        </div>
        <div className="column b">
          {column_b.map(i => (
            <div className="item" key={ i[0] }>
              <b>{ i[1] }</b><label>{ i[0] }</label>
            </div>
          ))}
        </div>
      </div>
    )
  }

  renderEstrutura() {
    const i = this.state.json
    const values = [
      [`Salas Existentes`, i.salasExistentes],
      [`Salas Utilizadas`, i.salasUtilizadas],
      [`Funcionários`,     i.qntFuncionarios],
      [`Computadores`,     i.qntComputadores],
      [`Computadores p/ Alunos`,         i.qntComputadoresParaAluno],
      [`Computadores p/ Administrativo`, i.qntComputadoresParaAdministrativo],
    ]
    const column_a = values.slice(0,3)
    const column_b = values.slice(3)
    return (
      <div className="group">
        <div className="caption">Estrutura</div>
        <div className="column a">
          {column_a.map(i => (
            <div className="item" key={ i[0] }>
              <b>{ i[1] }</b><label>{ i[0] }</label>
            </div>
          ))}
        </div>
        <div className="column b">
          {column_b.map(i => (
            <div className="item" key={ i[0] }>
              <b>{ i[1] }</b><label>{ i[0] }</label>
            </div>
          ))}
        </div>
        { this.renderEstruturaTags() }
      </div>
    )
  }

  renderEstruturaTags() {
    const hash = {
      salaDiretora: `Sala Diretor`,
      salaProfessor: `Sala Professor`,
      secretaria: `Secretaria`,
      refeitorio: `Refeitório`,
      despensa: `Despensa`,
      almoxarifado: `Almoxarifado`,
      auditorio: `Auditório`,
      labInformativa: `Laboratório de Informática`,
      labCiencias: `Laboratório de Ciências`,
      localFuncionamento: `Local Funcionamento Próprio`,
      regulamentacaoConselho: `Regulamentação Conselho`,
      conveniadaPoderPublico: `Conveniada Poder Público`,
      dependenciaPoderPublico: `Dependência Poder Público`,
      sindicator: `Sindicato`,
      finsLucrativos: `Fins Lucrativos`,
      acessibilidade: `Acessibilidade`,
      salaAtendimentoEspecial: `Sala de Atendimento Especial`,
      atendimentoEducacionalEspecializado: `Atendimento Educacional Especializado`,
      quadraEsporteCoberta: `Sala de Esporte Coberta`,
      quadraEsporteDescoberta: `Sala de Esporte Descoberta`,
      patioCoberto: `Pátio Coberto`,
      patioDescoberto: `Pátio Descoberto`,
      parqueInfantil: `Parque Infantil`,
      cozinha: `Cozinha`,
      biblioteca: `Biblioteca`,
      bercario: `Berçario`,
      sanitarioDentro: `Sanitário Dentro`,
      sanitarioFora: `Sanitário Fora`,
      sanitarioAdequado: `Sanitário Adequado`,
      banheiroChoveiro: `Banheiro com Choveiro`,
      lavanderia: `Lavanderia`,
      salaLeitura: `Sala de Leitura`,
      alojamentoAluno: `Alojamento Aluno`,
      alojamentoProfessor: `Alojamento Professor`,
      areaVerde: `Área Verde`,
      alimentacaoParaAluno: `Alimentação para Alunos`,
      aguaFiltrada: `Água Filtrada`,
      abastecimentoAguaRedePublica: `Abastecimento de Água Rede Pública`,
      abastecimentoEnergiaRedePublica: `Abastecimento de Energia Rede Pública`,
      gerador: `Gerador`,
      esgoto: `Esgoto`,
      destinacaoLixo: `Destinação Lixo`,
      internet: `Internet`,
      bandaLarga: `Internet Banda Larga`,
      computadores: `Computadores`,
      sistemaS: `Possui Sistemas de Informação`,
      dvd: `DVD`,
      impressora: `Impressora`,
      som: `Sistema de Áudio`,
      datashow: `Projetor`,
      fax: `Fax`,
      maquinaFotograficaFilmadora: `Camera/Filmadora`,
      parabolica: `Antena Parabólica`,
      copiadora: `Copiadora`,
      retroprojetor: `Retroprojetor`,
      TV: `TV`,
      videocassete: `Vídeo Cassete`,
      cedeEspacoProgramaBrasilAlfabetização: `Cede Espaço Brasil Alfabetização`,
      atividadeComplementar: `Atividade Complementar`,
      abreFdsParaComunidade: `Abre aos Finais de Semana para Comunidade`,
    }
    const yes = {SIM: `yes`, FILTRADA: `yes`, POSSUI: `yes`, OFERECE: `yes`, 'PRÓPRIO': `yes`}
    const tags = Object.entries(hash).map(([field, name]) => {
      const value = this.state.json.estrutura[field] || this.state.json[field] || ``
      const resposta = yes[value.toUpperCase()] || `no`
      return <div className={ `tag ` + resposta } key={ field }>{ name }</div>
    })
    return <div className="container-estrutura">{ tags }</div>
  }

}
