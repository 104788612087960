import { publish } from 'pubsub-js';
import React, { Component } from 'react';
import { api } from '../../../services/api';
import { List } from '../../UI/List';
import './index.scss';

export class CardBordaCriminal extends Component {
	state = {
		connected: [],
		error: null,
		items: [],
		total: 0,
		cardId: `CardBordaCriminal${new Date().getTime()}`
	};

	componentDidMount() {
		this.props.setTitle('BORDA CRIMINAL');
		this.updateItems(this.state);
	}

	componentWillUnmount() {
		publish('map::layer.removeGeoJson', { cardId: this.state.cardId, name: 'geojson' });
	}

	updateItems = async ({ connected, error, items, total }) => {
		if (connected && total === 0) {
			this.props.show();
		}

		const { params, scenario, scenarioName } = this.props

		try {
			const { startDate, endDate, startTime, endTime, weekDays, weekDaysLabels } = params
			let weekDaysStringQuery = ''

			const weekDaysArray = [...weekDays]
			weekDaysArray.forEach(item => {
				weekDaysStringQuery += `&weekDays=${item}`
			});

			const queryParams = `?startTime=${startTime}&endTime=${endTime}&startDate=${startDate}&endDate=${endDate}${weekDaysStringQuery}`

			const req = await api.get(`/criminal/stains/edge/${scenario + queryParams}`);
			const data = req.response().data
			const { numEvents, response } = data

			let weeksDaysString = ''
			if (weekDaysLabels.length > 0) {
				weeksDaysString = weekDaysLabels[0];

				weekDaysLabels.forEach((item, index) => {
					if (index) weeksDaysString += `, ${item}`;
				})
			}

			const startDateParts = String(startDate).split('-');
			const endDateParts = String(endDate).split('-');

			items = [
				{
					key: 'Número de eventos',
					value: numEvents,
				},
				{
					key: 'Ambiente - Cenário',
					value: scenarioName,
				},
				{
					key: 'Data inicial',
					value: `${startDateParts[2]}/${startDateParts[1]}/${startDateParts[0]}`,
				},
				{
					key: 'Data final',
					value: `${endDateParts[2]}/${endDateParts[1]}/${endDateParts[0]}`,
				},
				{
					key: 'Hora inicial',
					value: startTime,
				},
				{
					key: 'Hora final',
					value: endTime,
				},
				{
					key: 'Dias da semana',
					value: weeksDaysString,
				},
				{
					key: 'Tamanho da célula',
					value: '100',
				},
				{
					key: 'Raio',
					value: '650',
				},
				{
					key: 'Porcentagem de densidade',
					value: '0,3 (30%)',
				}
			];

			publish(`map::layer.addGeoJson`, { layerId: `${this.state.cardId}-geojson`, geojson: response })

		} catch (err) {
			console.error(err)
			error = true
		}

		this.setState({ connected, error, items, total });

	};

	render() {
		return (
			<div className='CardTornozeladosOnline'>
				<div className='card-title'>BORDA CRIMINAL</div>
				{this.renderBody()}
			</div>
		);
	}

	renderBody() {
		const { connected, error, items, total } = this.state;
		if (error) {
			return <p className='message error'>{error}</p>;
		}
		if (connected && items.length === 0 && total === 0) {
			return <p className='message'>carregando borda criminal...</p>;
		}
		if (items.length === 0) {
			return <p className='message'>erro ao carregar borda criminal</p>;
		}
		return (
			<List
				card={this}
				rows={items}
				width={440}
				height={400}
				rowHeight={25}
				highlightMarker={true}
				renderRow={(i) => (
					<div
						className={
							`item-row` +
							(i.error ? ` error` : ``) +
							(i.point ? `` : ` no-pointer`)
						}
					>
						<span className='column code' style={{ width: '100px' }} >
							{i.key}
							{i.flag && !i.error ? <span key={1}>{i.flag}</span> : null}
							{i.error ? (
								<span key={2} className='msg'>
									{i.error}
								</span>
							) : null}
						</span>
						{i.error ? null : (
							<span key={4} className='column right' style={{ width: '280px' }}>
								{i.value}
							</span>
						)}
					</div>
				)}
			/>
		);
	}
}
