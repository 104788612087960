import axios from 'axios'
import { DEFAULT_AUTH_HOST } from './defaults';

export const me = ({ token, app, host } = {}) => {
  // prepare
  host = host || DEFAULT_AUTH_HOST
  
  // validates
  if (!app) return Promise.reject('aplicação não informada')
  if (!token) return Promise.reject('token não informado')

  // runs
  return axios.get(`${host}/auth/me`, {
    headers: {
      'Authorization': token,
      'Accept-Client': app
    }
  }).then(handleMeSuccess, handleMeError)
};

const handleMeSuccess = response => response.data

const handleMeError = error => {
  const { response } = error;
  const responseError = {};
  if (response) {
    responseError.status = response.status;
    responseError.message = response.data.message;
  } else {
    console.error('[SPI-OAUTH2-JS | ERROR]', error.message)
    responseError.request && console.log('Request', error.request)
    responseError.message = 'Não foi possível obter suas informações.';
  } 
  return Promise.reject(responseError);
};