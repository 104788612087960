import React, { Component } from 'react'
import MapCircleSelection from '../../../UI/MapCircleSelection/'
import util from '../../../../helpers/util'
import './index.scss'

export class CardBiometriaGeoHistory extends Component {

  state = {
    officers: [],
    selected: false,
    total: null,
    timeQuery: {},
    timeTitle: ``
  }

  componentDidMount() {
    const params = this.extractTimeParams(this.props, util.date2str)
    const query  = this.extractTimeParams(this.props, util.date2sql)
    const timeQuery = {...query, title: undefined}
    const timeTitle = params.title
    this.setState({timeQuery, timeTitle}, () => {
      const group = {color: `#DEED66`, title: `Coletas Biométricas`}
      this.props.publish(`map::layer.add`, {group, name: `coletas`, points: [], color: `#DEED66`, popup: this.createPopup, click: this.clickPopup})
      this.props.show()
      this.props.setTitle(`BUSCA BIOMÉTRICA ` + timeTitle)
    })
  }

  extractTimeParams = (props, dateParser) => {
    const { type, date, startDate, endDate } = props
    if (type === `date`) {
      return {date: dateParser(date), title: `em ` + dateParser(date)}
    }
    if (type === `range`) {
      return {startDate: dateParser(startDate), endDate: dateParser(endDate), title: dateParser(startDate) + ` - ` + dateParser(endDate)}
    }
    if (type === `since`) {
      return {startDate: dateParser(startDate), title: `desde ` + dateParser(startDate)}
    }
    return {title: `HOJE`}
  }

  createPopup = item => {
    return { title: item.title }
  }

  clickPopup = item => {
    const data = {...item, searchResult: JSON.parse(item.searchResult)}
    this.props.openCard(`CardBiometriaPopupDetails`, data)
  }

  mapSelectionHandler = ({center, radius}) => {
    const [ longitude, latitude ] = center
    this.props.setLoading()
    this.props.publish(`map::layer.clear`, {name: `coletas`})
    this.setState({initialized: true, total: null})

    const params = { ...this.state.timeQuery, longitude, latitude, radius }
    
    this.props.http(`/civil/bio`, params).then(points => {
      points.forEach(p => {
        p.title = p.searchResult.basicInfo.name
        p.point = p.position
      })

      const officers = this.extractOfficers(points)
      this.setState({points, officers, total: points.length, errorMessage: null})
      this.props.publish(`map::layer.update`, {name: `coletas`, points})
      this.props.show()
    }).catch(_ => {
      this.setState({errorMessage: `FALHA AO CONSULTAR DADOS`})
      this.props.show()
    })
  }

  /**
   * extrai oficiais ordenados pela qtd de coletas realizadas
   */
  extractOfficers = points => {
    const hash = {}
    points.forEach(p => {
      const id = p.registrationId
      const name = p.officer
      const o = hash[id] || (hash[id] = {id, name, total: 0, percent: 0})
      o.total++
    })
    const officers = Object.values(hash).sort((a,b) => b.total - a.total)
    officers.forEach(o => {
      o.percent = (o.total / points.length * 100).toFixed(1).replace(/\W0$/, '') + `%`
    })
    return officers
  }

  clickOfficerHandler = officer => {
    const officers = this.state.officers.map(i => {
      if (i.id === officer.id) {
        i.selected = !i.selected
      } else {
        i.selected = false
      }
      return i
    })
    const officerSelected = (officers.filter(i => i.selected) || [])[0]
    if (officerSelected) {
      const points = this.state.points.filter(i => i.registrationId === officer.id)
      this.props.publish(`map::layer.update`, {name: `coletas`, points})
    } else {
      this.props.publish(`map::layer.update`, {name: `coletas`, points: this.state.points})
    }
    const selected = !!officerSelected
    this.setState({officers, selected})
  }

  renderTitle = () => {
    const prefix = `BUSCA BIOMÉTRICA`
    return (
      <div className="card-title category">
        { prefix }<span>{ this.state.timeTitle }</span>
      </div>
    )
  }

  render() {
    return (
      <div className="CardBiometriaGeoHistory">
        { this.renderTitle() }
        { this.renderAreaInfo() }
        { this.renderOfficers() }
      </div>
    )
  }

  renderAreaInfo() {
    const { initialized, errorMessage } = this.state
    const message = initialized ? null : (
      <p className="empty">
        AGUARDANDO DEFINIÇÃO DE ÁREA NO MAPA...
      </p>
    );
    return (
      <div className="container">
        <MapCircleSelection onChange={ this.mapSelectionHandler } />
        { errorMessage ? <p className="empty">{ errorMessage }</p> : message }
      </div>
    )
  }

  renderOfficers() {
    let { officers, selected, total } = this.state
    if (total === null) {
      return null
    }
    if (!officers || officers.length === 0) {
      return (
        <p className="empty white">
          NENHUMA COLETA ENCONTRADA
        </p>
      )
    }
    return (
      <div className={ `officers` + (selected ? ` selected` : ``) }>
        <h1>
          <span className="main">POLICIAIS</span>
          <span className="info"><b>{ total }</b> COLETAS</span>
        </h1>
        <table>
          <tbody>
            {officers.map((i, index) => (
              <tr className={ `row` + (i.selected ? ` selected` : ``) } key={ index } onClick={ this.clickOfficerHandler.bind(this, i) }>
                <td className="id">{ i.id }</td>
                <td className="name">{ i.name }</td>
                <td className="total">{ i.total }</td>
                <td className="chart">
                  <div className="bar">
                    <div className="life" style={{width: i.percent}}></div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }

}
