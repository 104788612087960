
import * as type from '../fieldTypes'

export const DetranCNHByCPF = {
  created: `2019-04-22`,
  context: `DETRAN.CNH`,
  role: `CEREBRUM_BUSCA_CNH_CPF`,
  params: {
    id: {label: `CPF`, placeholder: `000.000.000-00`, type: type.cpf}
  },
  parser: ({id}) => {
    id = id.replace(/\W/g, ``)
    return {card: `CardCNHHabilitacao`, props: {id}}
  }
}
