import React, { useEffect, useState } from 'react'
import util from '../../../../helpers/util'
import './index.scss'

export function CardPoliciaMilitar({ 
  // Queries
  reg: regQuery,
  cpf: cpfQuery,
  // Other props
  roles,
  http,
  show,
  isDuplicated,
  close,
  setTitle,
  openCard,
  hidden
}) {

  const [ json, setJson ] = useState()

  function loadCivil() {
    const { cpf } = json || {}
    if (cpf && roles.includes(`CEREBRUM_BUSCA_CIVIL`)) {
      openCard(`CardCivilHistorico`, { cpf })
    }
  }
  
  function fetchData() {
    if ( !!regQuery ) return http(`/police/military/${regQuery}`)
    if ( !!cpfQuery ) return http(`/police/military/cpf/${cpfQuery}`)
    return Promise.reject()
  }

  useEffect( () => {
    fetchData()
    .then(response => {
      if (isDuplicated(response)) {
        close()
      } else {
        setTimeout(loadCivil, 1000)
        setJson( response )
      }
    })
  }, [] )

  useEffect( () => {
    if (json) {
      show()
      setTitle(`PM ${json.name}`)
    }
  }, [ json ] )

  const emptyItem = item => !item || (item.value || ``).trim().length === 0
  const nonEmptyItem = item => !emptyItem(item)

  function renderPicture() {
    const { picture } = json
    const needsPrefix = picture && (!/\.\w{2,}$/.test(picture) && !/^data/.test(picture))
    const url = needsPrefix ? `data:image/png;base64,${picture}` : picture
    return <img src={ url } alt="" />
  }

  function renderValues(items, className=``) {
    if (!items || items.length === 0 || items.filter(emptyItem).length === items.length) {
      return null
    }
    return (
      <div className={ `line-values ${className}` }>
        {items.map((i, index) => (
          <div className={ `item ` + (i.className || ``) } key={ index }>
            <div className="label">{ i.name }</div>
            <div className="value">{ i.value || `-` }</div>
          </div>
        ))}
      </div>
    )
  }

  function renderInfo() {
    const line1 = [
      {name: `CARGO`, value: json.grade},
      {name: `ADMISSÃO`, value: json.initiationDate ? util.date2str(json.initiationDate) : ``},
      {name: `SITUAÇÃO`, value: json.status},
      {name: `ATIVIDADE`, value: json.activity},
    ]
    const line2 = [
      {name: `UNIDADE POLICIAL`, value: json.workplace}
    ]
    const line3 = [
      {name: `CPF`, value: json.cpf ? util.formatCPF(json.cpf) : `-`},
      {name: `SEXO`, value: json.gender},
      {name: `NASC.`, value: json.birthDate ? util.date2str(json.birthDate) : ``},
      {name: `TIPO SANGUÍNEO`, value: json.bloodType},
      {name: `FATOR RH`, value: json.rhFactor},
    ].filter(nonEmptyItem)
    const line4 = [
      {name: `COR PELE`, value: json.skinColor},
      {name: `COR CABELO`, value: json.hairColor},
      {name: `COR OLHOS`, value: json.eyeColor},
    ]
    const line5 = [
      {name: `MÃE`, value: json.mother},
      {name: `PAI`, value: json.father},
    ].filter(nonEmptyItem)

    return (
      <React.Fragment>
        { renderValues(line1, `text inline`) }
        { renderValues(line2, `text no-border inline`) }
        <br/>
        { renderValues(line3, `text inline`) }
        { renderValues(line4, `text no-border inline`) }
        { renderValues(line5, `text no-border inline`) }
      </React.Fragment>
    )
  }

  if ( hidden ) return null
  return (
    <div className="CardPoliciaMilitar">
      <div className="card-title">
        PM
        <span className="light">{ json.registry }</span>
        <span className="name">{ json.name }</span>
      </div>
      <div className="content">
        <div className={ `column picture ${json.picture ? '' : 'empty'}` }>
          { json.picture && renderPicture() }
        </div>
        <div className="column info">
          { renderInfo() }
        </div>
      </div>
    </div>
  )

}

CardPoliciaMilitar.ROLES = [
  `CEREBRUM_PM_PERFIL`,
]
