import React from 'react';
import "./index.scss";

export default function InfoMiniCard({ data, handleRemove }) {

	if (data)
		return <div className='infoCardContent' onMouseDown={(e) => e.stopPropagation()}>
			<h3> Informações </h3>
			<div> Nome: {data.name} </div>
			{data.rg ? <div> RG: {data.rg} </div> : null}
			{data.cpf ? <div> CPF: {data.cpf} </div> : null}
			<button className='removeBtn' onClick={handleRemove} > X </button>
		</div >

	else return null;
}