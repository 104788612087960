
const util = {}
export default util

const WEEKDAY = `Domingo,Segunda-feira,Terça-feira,Quarta-feira,Quinta-feira,Sexta-feira,Sábado`.split(`,`)
const MONTH_NAME = `Janeiro,Fevereiro,Março,Abril,Maio,Junho,Julho,Agosto,Setembro,Outubro,Novembro,Dezembro`.split(`,`)

util.getMonthName = function(index) {
  return MONTH_NAME[-1 + +index]
}

util.getMonthIndexByName = function(text) {
  const words = text.toLowerCase().trim().split(/\s+/).map(i => i[0].toUpperCase() + i.slice(1))
  for (let i = 0; i < MONTH_NAME.length; i++) {
    if (words.includes(MONTH_NAME[i])) {
      return i + 1
    }
  }
  return -1
}

util.getWeekday = function(date) {
  date = typeof date === `string` || typeof date === `number` ? new Date(date) : date
  return WEEKDAY[date.getDay()]
}

util.date2sql = function(date) {
  if (typeof date === `string`) {
    date = new Date(date)
  }
  const y = date.getFullYear()
  const m = (`0` + (date.getMonth() + 1)).slice(-2)
  const d = (`0` + date.getDate()).slice(-2)
  return `${y}-${m}-${d}`
}

util.datetime2iso = function(date) {
  return new Date(date).toISOString().replace(/\.\d+/, ``)
}

util.datetime2sql = function(date) {
  if (typeof date === `string`) {
    date = new Date(date)
  }
  const y = date.getFullYear()
  const m = (`0` + (date.getMonth() + 1)).slice(-2)
  const d = (`0` + date.getDate()).slice(-2)
  const H = (`0` + date.getHours()).slice(-2)
  const M = (`0` + date.getMinutes()).slice(-2)
  const S = (`0` + date.getSeconds()).slice(-2)
  return `${y}-${m}-${d} ${H}:${M}:${S}`
}

util.date2str = function(date, weekday=false) {
  if (typeof date === `string`) {
    if (/^\d{2}\/\d{2}\/\d{4}$/.test(date))
      date = date.split(`/`).reverse().join(`-`) + `T00:00:00`
    if (/^\d{4}-\d{2}-\d{2}$/.test(date))
      date += `T00:00:00`
  }
  date = new Date(date)
  const w = WEEKDAY[date.getDay()]
  const d = (`0`+date.getDate()).slice(-2)
  const m = (`0`+(date.getMonth() + 1)).slice(-2)
  const a = date.getFullYear()
  return (weekday ? (w + `, `) : ``) + `${d}/${m}/${a}`
}

util.date2time = function(date, seconds=false) {
  const d = typeof date === `string` ? new Date(date) : date
  const h = (`0` + d.getHours()).slice(-2)
  const m = (`0` + d.getMinutes()).slice(-2)
  const s = (`0` + d.getSeconds()).slice(-2)
  return h + `:` + m + (seconds ? (`:`+s) : ``)
}

util.datetime2str = function(date) {
  return util.date2str(date) + ` às ` + util.date2time(date)
}

util.datetime2strWithSeconds = function(date) {
  return util.date2str(date) + ` às ` + util.date2time(date, true)
}

util.str2date = function(text) {
  if (/^\d+\/\d+\/\d+$/.test(text)) {
    const p = text.split(`/`).map(i => parseInt(i, 10))
    return new Date(p[2], p[1] - 1, p[0])
  }
  if (/^\d+-\d+-\d+$/.test(text)) {
    const p = text.split(`-`).map(i => parseInt(i, 10))
    return new Date(p[0], p[1] - 1, p[2])
  }
  return new Date(text)
}

util.isDateInFuture = (date) => {
  const now = Date.now()
  return date.getTime() > now  
}

util.isDateToday = (date) => {
  const today = new Date()
  return date.getFullYear() === today.getFullYear() &&
         date.getMonth() === today.getMonth() &&
         date.getDate() === today.getDate()
}

util.calcAge = function(date) {
  const birthdate = typeof date === `string` ? util.str2date(date) : date
  const today = new Date()
  const age = today.getFullYear() - birthdate.getFullYear()
  const m = today.getMonth() - birthdate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
    return age - 1
  }
  return age
}

function truncateJSON(json) {
  const maxChars = 100
  Object.entries(json).forEach(([key, value]) => {
    const t = typeof value
    if (t === `string` && value.length > maxChars) {
      json[key] = value.slice(0, maxChars).trim() + `[...]`
    } else if (t === `object`) {
      if (value && value.constructor !== Array) {
        truncateJSON(value)
      } else if (key === `coordinates`) {
        json[key] = []
      } else if (value && value.length > 0) {
        value.forEach(truncateJSON)
      }
    }
  })
  return json
}
util.truncateJSON = truncateJSON

util.formatPhone = text => {
  text = ((text || ``) + ``).replace(/^0+/, ``)
  if (text.length === 8 || text.length === 9) {
    return text.replace(/^(\d+)(\d{4})$/, `$1-$2`)
  }
  if (text.length === 10 || text.length === 11) {
    return text.replace(/^(\d{2})(\d+)(\d{4})$/, `($1)$2-$3`)
  }
  return text
}

util.formatCEP = text => {
  text = (text || ``) + ``
  return text.length === 8 ? text.replace(/^(\d{2})(\d{3})(\d{3})$/, '$1.$2-$3') : text
}

util.formatCPF = text => {
  text = (text || ``) + ``
  return text.length === 11 ? text.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4') : text
}

util.formatCNPJ = text => {
  text = (text || ``) + ``
  return text.length === 14 ? text.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5') : text
}

util.formatCarPlateNumber = text => {
  text = (`` + (text || ``)).replace(/\s/g, ``).trim()
  const n = text.length
  if (n === 7)
    return text.replace(/^(\w{3})/, `$1-`)
  if (n === 6)
    return text.replace(/^(\w{2})/, `$1-`)
  return text
}

util.toCapitalize = function(text) {
  return (text || ``).replace(/\s{1,}/g, ` `).trim().split(` `).map(s => s || ``).map(word => {
    return word[0].toUpperCase() + word.slice(1).toLowerCase()
  }).join(` `)
}

util.extractYear = function(text) {
  if (/\s+\d{4}\s/.test(text)) {
    return text.match(/\s+(\d{4})\s/)[1]
  }
}

util.extractDates = function(text) {
  text = text.toLowerCase()
  const now = new Date()
  text = text.replace(`hoje`, util.date2str(now))
  text = text.replace(`ontem`, util.date2str(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1)))
  const dates = []
  const m = text.match(/\d+\/\d+\/\d+/g)
  if (m) {
    if (m.length > 0) {
      const d = m[0].split(`/`)
      dates.push(new Date(+d[2], -1 + +d[1], +d[0]))
    }
    if (m.length > 1) {
      const d = m[1].split(`/`)
      dates.push(new Date(+d[2], -1 + +d[1], +d[0]))
    }
  }
  return dates
}

util.extractTimes = function(text) {
  text = (text + ` `).replace(/(\d+)h(\d+)/g, `$1:$2`).replace(/(\s\d+:\d+)\s/g, `$1:00`)
  const times = text.match(/\d+:\d+:\d+/g) || []
  return times.map(t => {
    const [ h, m, s ] = t.split(`:`).map(i => parseInt(i, 10))
    return new Date(2000, 0, 1, h, m, s)
  })
}

util.extractSinceDate = function(text) {
  text = text.toLowerCase() + ` `
  const now = new Date()
  text = text.replace(`ontem`, util.date2str(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1)))
  let m, parts = text.split(/\sdesde\s/)
  if (parts.length === 1) {
    parts = text.split(/\sa\s?partir\sde\s/)
  }
  text = parts[1]
  if (text) {
    m = text.match(/(\d+)\/(\d+)\/(\d+)/)
    if (m) return new Date(+m[3], -1 + +m[2], +m[1])
    m = text.match(/(\d{1,2})\/(\d{1,2})\s/)
    if (m) return new Date(new Date().getFullYear(), -1 + +m[2], +m[1])
    m = text.match(/(\d+)\/(\d+)/)
    if (m) return new Date(+m[2], -1 + +m[1], 1)
    m = text.match(/(\d{4})/)
    if (m) return new Date(+m[1], 0, 1)
  }
}

const hashCodes = {}
util.basicText2hash = function(text) {
  text = text.replace(/\W/g, ``)
  text = text.slice(0, 256) + text.length
  return hashCodes[text] || (hashCodes[text] = Date.now().toString(36).toUpperCase())
}

util.countChars = function(text) {
  text = (text || ``).toLowerCase().replace(/[\W\d]/g, ` `).replace(/\s{1,}/g, ` `).trim()
  return text.length
}

util.countNames = function(text) {
  text = (text || ``).toLowerCase().replace(/[\W\d]/g, ` `).replace(/\s{1,}/g, ` `).trim()
  if (text.length === 0)
    return 0
  const prepositions = [`da`, `de`, `do`, `du`, `das`, `dos`, `e`, `na`, `ne`, `ni`, `no`, `nu`, `nas`, `nos`]
  const words = text.split(/\s+/)
  return words.filter(w => !prepositions.includes(w)).length
}

const asciiChar = {}
const accents = {
  a: `àáâãäåæ`,
  c: `ç`,
  e: `èéêëæ`,
  i: `ìíîï`,
  n: `ñ`,
  o: `òóôõöø`,
  s: `ß`,
  u: `ùúûü`,
  y: `ÿ`
}
Object.entries(accents).forEach(([ascii, chars]) => {
  for (const c of chars)
    asciiChar[c] = ascii
})
util.equalsText = function(a, b) {
  a = a.toLowerCase().split(``).map(c => asciiChar[c] || c).join(``)
  b = b.toLowerCase().split(``).map(c => asciiChar[c] || c).join(``)
  return a === b
}

util.uf2gps = function(uf) {
  const googleCoord = {
    AC: [-8.843436,-71.0545954],
    AL: [-9.655278,-36.2154275],
    AP: [1.5430735,-52.0210536],
    AM: [-3.6498964,-64.32722],
    BA: [-12.2815118,-41.51031],
    CE: [-4.3067878,-38.996726],
    DF: [-15.7797577,-47.792636],
    ES: [-19.8955963,-40.4368603],
    GO: [-15.5397981,-49.6953487],
    MA: [-4.9647087,-45.3933943],
    MT: [-12.9559843,-55.816285],
    MS: [-20.6223557,-55.0511151],
    MG: [-18.3421213,-44.709811],
    PA: [-4.810697,-52.7642579],
    PB: [-7.1932721,-35.9757869],
    PR: [-24.6750991,-51.7735376],
    PE: [-8.3264369,-38.0888212],
    PI: [-7.59894,-42.6035098],
    RJ: [-22.5537159,-42.5501755],
    RN: [-5.4709782,-36.163595],
    RS: [-29.7941453,-52.9335933],
    RO: [-10.9360469,-62.796551],
    RR: [1.7964602,-61.1168464],
    SC: [-27.3515511,-49.7183389],
    SP: [-21.9977252,-48.5874103],
    SE: [-10.7421808,-37.309018],
    TO: [-10.4051017,-48.0884705],
  }[uf]
  return googleCoord ? [googleCoord[1], googleCoord[0]] : null
}

util.uf2name = function(uf) {
  return {
    AC: `Acre`,
    AL: `Alagoas`,
    AP: `Amapá`,
    AM: `Amazonas`,
    BA: `Bahia`,
    CE: `Ceará`,
    DF: `Distrito Federal`,
    ES: `Espírito Santo`,
    GO: `Goiás`,
    MA: `Maranhão`,
    MT: `Mato Grosso`,
    MS: `Mato Grosso do Sul`,
    MG: `Minas Gerais`,
    PA: `Pará`,
    PB: `Paraíba`,
    PR: `Paraná`,
    PE: `Pernambuco`,
    PI: `Piauí`,
    RJ: `Rio de Janeiro`,
    RN: `Rio Grando do Norte`,
    RS: `Rio Grando do Sul`,
    RO: `Rondônia`,
    RR: `Roraima`,
    SC: `Santa Catarina`,
    SP: `São Paulo`,
    SE: `Sergipe`,
    TO: `Tocantins`,
  }[uf]
}

util.objIsEmpty = obj => {
  return Object.keys(obj).length === 0;
}

util.handleError = (error, show, defaultFunc, exceptionFunc) => {
  show()
  switch(error.status){
    case 404:
      exceptionFunc(error.message.toUpperCase())
      break;
    default:
      defaultFunc()
      break;
  }
}
