import React from 'react'

import Center from './Center'
import Left   from './Left'
import Right  from './Right'

export default () => (
  <>
    <Center />
    <Left />
    <Right />
  </>
);
