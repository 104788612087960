export const AISList = {
  created: `2019-12-23`,
  context: `AIS`,
  role: `CEREBRUM_BUSCA_AIS`,
  title: `LISTAR TODAS`,
  description: `Lista todas as Áreas Integradas de Segurança (AIS) do Ceará`,
  parser: () => ({ card: `CardAISList`, props: {}, className: 'CardAISList-container' })
}

// export const FortalezaAISList = {
//   created: `2019-12-27`,
//   context: `ais`,
//   role: `CEREBRUM_AIS_LISTA`,
//   title: `LISTAR AIS DE FORTALEZA`,
//   description: `Lista as Áreas Integradas de Segurança (AIS) de Fortaleza`,
//   parser: () => ({ card: `CardAISList`, props: { fortaleza: true }, className: 'CardAISList-container' })
// }