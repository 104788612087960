import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import './index.scss'

export default class SubHeaderRight extends Component {

    componentDidMount() {
      setTimeout(_ => {
        ReactDOM.findDOMNode(this).classList.add(`visible`)
      }, 500);
    }
    
    render() {
      return (
        <div className="SubHeaderRight">
            <a className="logo-header-right" href='https://www.supesp.ce.gov.br/' target="_blank" rel="noopener noreferrer"> </a>
        </div>
      )
    }
  
  }