import util from './util'
import srcImageAvatar from '../images/avatar.png'

const createGroupRows = (...params) => {
  const g = []
  for (let i = 0; i < params.length - 1; i += 2) {
    const [label, value] = [params[i], params[i + 1]]
    if (value) {
      g.push({ label: label.toUpperCase(), value })
    }
  }
  return g
}

const getPicture = (picture) => {
  const signatures = {
    iVBORw0KGgo: "image/png",
    "/9j/": "image/jpg"
  };

  const detectMimeType = base64 => {
    for (const signature in signatures) {
      if (base64.indexOf(signature) === 0) {
        return signatures[signature];
      }
    }
  }

  const defaultImage = srcImageAvatar
  const url = picture || defaultImage
  const src = /^data/.test(url) || /\.\w{3,4}$/.test(url) ? url : (`data:${detectMimeType(url)};base64,` + url)
  return src
}

const formatSex = text => {
  if (text === `M`) return `MASCULINO`
  if (text === `F`) return `FEMININO`
  if (text === `I`) return `INDEFINIDO`
  return typeof text === `string` && text.length ? text : `-`
}

export const formatData2Print = {
    header: props => {
      const { origin, date, user, title, info} = props
      return {origin: `${origin['label']} - ${origin['value']}`, dateTime: util.datetime2strWithSeconds(date), user, title, info}
    },
    civil: props => {

      const date2str = (text) => {
        if (text.length === 8) {
          return text.slice(0, 2) + `/` + text.slice(2, 4) + `/` + text.slice(4)
        }
        const date = new Date(text)
        const d = date.getDate()
        const m = date.getMonth() + 1
        const a = date.getFullYear()
        return (`0` + d).slice(-2) + `/` + (`0` + m).slice(-2) + `/` + a
      }
      
      const orderNumber = props.orderNumber
      const identityDate = props.rgInfo.identityDate ? date2str(props.rgInfo.identityDate) : `NÃO EXPEDIDA`


      const getRG = () => {
          const json = props.rgInfo
          let rg = json.rg + ` ` + json.orderType + ` / ` + json.station
          rg += json.identityDate ? ` ${util.date2str(json.identityDate)}` : ``
          if (json.expirationDate) {
            rg += ` válido até ` + util.date2str(json.expirationDate)
          }
          return rg
      }
        
      const getSex = () => {
          if (props.basicInfo.sex === `F`) return `FEMININO`
          if (props.basicInfo.sex === `M`) return `MASCULINO`
          return `-`
      }

      const groups = []
      const doc = props.documentInfo
      const p = props
      //
      groups.push(createGroupRows(
        `Nome`, p.basicInfo.name,
      ))
      //
      groups.push(createGroupRows(
        `RG`, getRG(),
        `RG 2ª via informada`, p.rgInfo.rgReissue === p.rgInfo.rg ? null : p.rgInfo.rgReissue,
        `RG de outro estado`, doc.anotherIdentityDocument ? (doc.anotherIdentityDocument + ` ` + doc.anotherIdentityEmitter.toUpperCase() + ` ` + doc.anotherIdentityState) : null,
        `CPF`, doc.cpf ? util.formatCPF(doc.cpf) : null,
        `CNH`, doc.cnh,
        `CTPS`, doc.ctpsNumber ? (doc.ctpsNumber + ` ` + doc.ctpsSeries + ` ` + doc.ctpsFederalUnit) : null,
        `CNS`, doc.cns,
        `NIS`, doc.nis,
        `DNI`, doc.dni,
        `Eleitor`, doc.voterTitle && doc.voterTitle !== `0` ? (`${doc.voterTitle} zona ${doc.electoralZone || 0} seção ${doc.electoralSection || 0}`) : null,
        `Reservista`, doc.militaryNumber,
      ))
      //
      groups.push(createGroupRows(
        `Nome Social`, p.basicInfo.socialName,
        `Data Nascimento`, util.date2str(p.basicInfo.birthDate) + ` - ` + util.calcAge(p.basicInfo.birthDate) + ` anos`,
        `Local de Nascimento`, p.basicInfo.birthCityStateCountry,
        `Sexo`, getSex(),
        `Estado Civil`, p.basicInfo.maritalStatus,
        `Nacionalidade`, p.basicInfo.nationality,
        `Sangue`, p.basicInfo.bloodType,
        `Cônjuge`, p.basicInfo.spouse,
      ))
      //
      groups.push(createGroupRows(
        `Mãe`, p.basicInfo.mother,
        `Pai`, p.basicInfo.father
      ))
      //
      if (Object.keys(p.foreignInfo || {}).length > 0) {
        groups.push(createGroupRows(
          `Processo Migração`, p.foreignInfo.process,
          `Portaria`, p.foreignInfo.ministerial + ` / ` + p.foreignInfo.year,
        ))
      }
      //
      groups.push(createGroupRows(
        `Documento`, doc.type,
        `Certidão`, (doc.certificateTerm || ``) + ` ` + util.date2str(doc.certificateDate) + ` ` + doc.certificateRegistryOffice,
        `Livro`, doc.certificateBook + ` pág.` + doc.certificateSheet,
        `Local`, doc.certificateCity,
        `Divórcio`, doc.certificateDivorce
      ))
      //
      const antro = props.anthropometrics
      if (antro && Object.keys(antro).length > 1) {
        groups.push(createGroupRows(
          `Altura`, antro.height ? `${antro.height.toFixed(2)}m` : null,
          `Pele`, antro.skinColor,
          `Olhos`, [antro.eyeColor || ``, antro.eyeType || ``].join(` `),
          `Cabelo`, [antro.hairColor || ``, antro.hairType || ``].join(` `)
        ))
      }
      //
      const { professionalIdentity, secondProfessionalIdentity, thirdProfessionalIdentity } = p.professionalInfo
      const jobs = [professionalIdentity, secondProfessionalIdentity, thirdProfessionalIdentity].filter(_ => _)
      groups.push(createGroupRows(
        jobs.length > 1 ? `Identidades Profissionais` : `Identidade Profissional`, jobs.join(` / `),
        `Escolaridade`, p.professionalInfo.educationDegree,
        `Ocupação`, p.professionalInfo.occupation,
      ))
      //
      if (props.contactInfo) {
        const phones = props.contactInfo.map(i => (i.replace(/^0+$/, ``))).filter(_ => _)
        if (phones.length) {
          groups.push(createGroupRows(
            `Contato`, phones.map(util.formatPhone).join(` / `)
          ))
        }
      }
      if (props.addressInfo) {
        let logr = p.addressInfo.address
        if (p.addressInfo.number) {
          logr += `, ` + p.addressInfo.number
        }
        if (p.addressInfo.complement) {
          logr += ` ` + p.addressInfo.complement
        }
        groups.push(createGroupRows(
          `Endereço`, logr,
          `Bairro`, p.addressInfo.district,
          `CEP`, p.addressInfo.cep === `0` ? null : p.addressInfo.cep,
          `Cidade`, p.addressInfo.cityStateCountry
        ))
      }

      const image = getPicture(props["picture"])
      const info = { orderNumber, identityDate }
      const content = groups
      return { info, image, content }
    },
    vehicles: props => {
      const groups = []
      const p = props

      const docType = p.ownerCpf.length === 11 ? `CPF` : `CNPJ`
      const docNum = docType === `CPF` ? util.formatCPF(p.ownerCpf) : util.formatCNPJ(p.ownerCpf)

      groups.push(createGroupRows(
        `NOME PROPRIETÁRIO`, p.ownerName,
        `${docType}`, docNum 
      ));

      const labelPlate = p.isMercosul ? `PLACA MERCOSUL` : `PLACA`

      groups.push(createGroupRows(
        `CÓD RENAVAM`, p.renavam,
        `${labelPlate}`, util.formatCarPlateNumber(p.plate),
        `CHASSI`, p.chassis,
        `ANO FAB / MODELO`, p.fabricationYear / p.modelYear ,
        `ESPÉCIE / TIPO`, p.kind / p.type,
        `COMBUSTÍVEL`, p.fuel,
        `CATEGORIA`, p.category,
        `PASSAGEIROS`, p.amountPassengers,
        `MOTOR`, p.vehicleMotor,
        `MARCA / MODELO`, p.markModel.replace(/\//g, ` / `),
        `COR PREDOMINANTE`, p.color,
        `CARROCERIA`, p.bodyWork,
      ));

      if (p.details) {
        const d = p.details.vehicle
        groups.push(createGroupRows(
          `DOCUMENTO`, d.docNumber,
          `POTÊNCIA`, d.potency,
          `CILINDRADA`, `${d.cylinders} cm³`,
          `EIXOS`, d.axisNumber,
          `PESO`, d.weight,
          `CAPACIDADE MÁX.`, d.chargeCapacity,
        ))
      }

      if (p.details && p.details.address) {
        const i = p.details.address
        const city = [i.city, i.uf].filter(i => i).join(` - `) || `-`
        let address = i.address
        if (i.addressComplement)
          address += `, ` + i.addressComplement
        if (i.district)
          address += ` - ` + i.district
        groups.push(createGroupRows(
          `ENDEREÇO`, address,
          `CEP`, util.formatCEP(i.cepNumber),
          `CIDADE`, util.formatCEP(city),
        ))
      }

      const name = p.renterName
      const doc  = p.renterCpf

      if (name || name.length > 0) {
        const docNum = doc.length === 11 ? util.formatCPF(doc) : util.formatCNPJ(doc)
        groups.push(createGroupRows(
          `ARRENDATÁRIO`, name,
          `CPF`, docNum,
        ))
      }

      const formatDate = text => text.replace(/^(\d{2})(\d{2})(\d{4})$/, `$1/$2/$3`)

      const formatYesNo = text => {
        if (text === `S` || text === `SIM`) return `SIM`
        if (text === `N` || text === `NAO`) return `NÃO`
        return text
      }

      if(p.details){
        const d = p.details
        groups.push(createGroupRows(
          `ANO EXERCÍCIO`, d.exerciseYear,
          `DATA ALTERAÇÃO`, formatDate(d.alterationDate),
          `LICENCIAMENTO`, formatDate(d.licensingDate),
          `EM DÉBITO`, formatYesNo(d.licensingDebit),
          `IPVA GRÁTIS`, d.freeIpva,
          `IPVA EM DÉBITO`, formatYesNo(d.debitoIpva),
          `QTD MULTAS`, d.amountFaults,
          `QTD RESTRIÇÕES`, d.amountRestricts,
        ))

        if(d.vehicle.theft){
          const t = d.vehicle.theft
          groups.push(createGroupRows(
            `ROUBADO`, `${t.type}${t.date ? ` ${t.date}` : ``} - ${t.message}`,
          ))
        }

        groups.push(createGroupRows(
          `PROPRIETÁRIO ANTERIOR`, `${(d.lastOwnerName || `NÃO INFORMADO`).toUpperCase()} - ${d.lastOwnerCpfCgc ? util.formatCPF(d.lastOwnerCpfCgc) + ` - ` : ``} ${d.lastOwnerUf}`,
        ))
      }

      const plate = util.formatCarPlateNumber(p.plate)
      const type = p.type

      const info = { plate, type }
      const content = groups
      return { info, content }
    },
    cnh: props => {
      const groups = []
      const c = props
      const error = c.error
 
      groups.push(createGroupRows(
        `NOME`, c.name,
        `SEXO`, c.sex,
        `NACIONALIDADE`, c.nationality,
        `CIDADE`, c.bornPlace,
        `DOC IDENTIDADE / ORG EMISSOR / UF`, c.rg,
        `CPF`, c.cpf,
        `DATA NASC`, c.bornDate,
        `PAI`, c.father,
        `MÃE`, c.mother,
        `PERMISSÃO`, c.permissionHolder,
        `CATEGORIA`, c.category,
        `Nº REGISTRO`, c.cnhNumber,
        `VALIDADE`, c.cnhValidity,
        `Nº PONTOS`, c.faultPoints,
        `OBSERVAÇÕES`, c.observation,
      ))

      const info = c.cnhNumber;
      const image = getPicture(props["image"]);
      const content = groups
      return {info, content, image, error}
    },
    enel: props => {
      const groups = []

      groups.push(createGroupRows(
        `Nome`, props.name,
        `Endereço`, props.addressText,
      ))

      const content = groups
      return { content }
    },
    criminal: props => {
      const groups = []
      const { details, aliases, recap = [], openArrestWarrants, arrests, error } = props

      const getAlcunha = () => {
        if (aliases && aliases.length) {
          const alcunha = aliases.filter(i => i && i !== `SEM ALCUNHA`).join(`, `).toUpperCase()
          if (alcunha)
            return alcunha
        }
        return `-`
      }

      const renderRowItem = (year, name, desc) => {
        return { year, name, desc }
      }

      const renderRowCrime = crime => {
        const year = crime.inquiryYear
        const desc = crime.policeStation
        let name = `Art. ` + crime.clauseNumber + ` do CP - Decreto Lei ` + crime.lawNumber
        if (crime.paragraphNumber)
          name += ` - Parág. ` + crime.paragraphNumber
        if (crime.subsectionNumber)
          name += ` ` + crime.subsectionNumber
        return {year, name, desc}
      }

      const recapFormat = recap.map((item) => {
        return renderRowCrime(item)
      })

      const formatPunishmentTime = item => {
        const d = item.punishmentDaysQnt
        const m = item.punishmentMonthsQnt
        const y = item.punishmentYearsQnt
        let output = []
        if (y !== undefined && y !== 0)
          output.push(y === 1 ? `1 ano` : `${y} anos`)
        if (m !== undefined && m !== 0)
          output.push(m === 1 ? `1 mês` : `${m} meses`)
        if (d !== undefined && d !== 0)
          output.push(d === 1 ? `1 dia` : `${d} dias`)
        return output.join(`, `).replace(/,\s+(\d+\s+\w+)$/, ` e $1`)
      }
      
      const openArrestFormat = openArrestWarrants.map((item) => {
        return renderRowItem(item.judRefYear, item.punishmentTypeDesc, formatPunishmentTime(item))
      })

      const arrestsFormat = arrests.map((item) => {
        return renderRowItem(formatDate(item.arrestmentDate), item.arrestmentType, item.policeStationName)
      })

      const formatDate = (text) => {
        if (text.length === 8) {
          return text.slice(0, 2) + `/` + text.slice(2, 4) + `/` + text.slice(4)
        }
        const date = new Date(text)
        const d = date.getDate()
        const m = date.getMonth() + 1
        const a = date.getFullYear()
        return (`0` + d).slice(-2) + `/` + (`0` + m).slice(-2) + `/` + a
      }

      groups.push(createGroupRows(
        `Nome`, details.regName,
      ))

      groups.push(createGroupRows(
        `Nº de Ocorrência`, details.regNumber,
      ))

      groups.push(createGroupRows(
        `Alcunha`, getAlcunha(),
        `RG`, details.rg ? `${details.rg} ${details.rgEmitter} ${details.federalUnitRgEmitter || ``}` : `-`
      ))

      groups.push(createGroupRows(
        `CPF`, details.cpf ? util.formatCPF(details.cpf) : `-`,
        `Nascimento`, details.birthday ? `${formatDate(details.birthday)} - ${util.calcAge(details.birthday)} anos` : `-`
      ))

      groups.push(createGroupRows(
        `Cidade`, `${details.city ? `${details.city} - ` : ``}${details.federalUnitName || `-`}`,
        `País`, details.country || `-`
      ))

      groups.push(createGroupRows(
        `Sexo`, formatSex(details.sex),
        `Status`, details.maritalStatus
      ))

      groups.push(createGroupRows(
        `Ocupação`, details.occupation ? details.occupation : `-`
      ))

      groups.push(createGroupRows(
        `Mãe`, details.mother || `-`,
        `Pai`, details.father || `-`
      ))

      const image = getPicture(details["picture"])
      const info = { regNumber: details.regNumber }
      const content = groups
      return { 
        info, 
        image, 
        content, 
        recap: recapFormat, 
        openArrestWarrants: openArrestFormat,
        arrests: arrestsFormat,
        error,
      }
    },
    sispen: {
      apenado: props => {
        let image
        const error = props.error
        const personGroup = []
        const filiationGroup = []
        const adressGroupResidencial = []
        const adressGroupComercial = []
        const referralsGroup = []
        const benefitsGroup = []
        const questionnaireGroup = []
        const sispenCriminalGroup = []
        const situacaoAtual = []
        const situacaoGeral = []
        const pretensoesFuturas = []
        const observacoes = []
        let visits = []
        let sispenCriminalProcesses = []
        let collects = []

        if (!error && !util.objIsEmpty(props)) {
          const {
            thumb,
            person,
            somatic,
            referrals,
            benefits,
            questionnaire,
            dwellingHouse,
            processes,
            visitors,
            collects2Print
          } = props;
          const doc = person.documentation
          
          //Thumb
          image = getPicture(thumb);

          //Person
          personGroup.push(createGroupRows(
            `CÓDIGO (APENADO)`, person.id,
            `NOME`, person.name,
          ))

          personGroup.push(createGroupRows(
            `IDADE`, person.birthDate ? (util.calcAge(person.birthDate) + ` anos`) : ``,
            `NASC.`, person.birthDate ? util.date2str(person.birthDate) : ``,
            `GÊNERO`, person.demographic.gender,
            `ESTADO CIVIL`, person.demographic.maritalStatus,
          ))

          personGroup.push(createGroupRows(
            `ESCOLARIDADE`, person.demographic.schooling,
            `CIDADE`, person.location.city ? (person.location.city + ` - ` + person.location.state) : ``,
          ))

          const extractAlcunha = () => {
            const { aliases } = person
            if (aliases && aliases.length) {
              const alcunha = aliases.filter(i => i && i !== `SEM ALCUNHA`).join(`, `).toUpperCase()
              if (alcunha)
                return alcunha
            }
          }

          personGroup.push(createGroupRows(
            `ALCUNHA`, extractAlcunha() || ``,
            `RG`, doc && doc.rg ? (doc.rg + ` ` + (doc.rgProvider)) : ``,
            `CPF`, doc && doc.cpf ? util.formatCPF(doc.cpf) : ``,
          ))

          personGroup.push(createGroupRows(
            `ALTURA`, somatic.height,
            `PESO`, somatic.weight,
            `RAÇA`, somatic.humanBreed,
            `PELE`, somatic.skinColor,
            `OLHOS`, somatic.eyes,
          ))

          personGroup.push(createGroupRows(
            `NARIZ`, somatic.nose,
            `BOCA`, somatic.mouthSize,
            `BARBA`, somatic.beardColor,
            `BIGODE`, somatic.moustache,
            `CABELO`, somatic.hairColor,
          ))

          //Filiation
          const { filiation } = person
          if (filiation) {
            const { motherName, fatherName } = filiation
            filiationGroup.push(createGroupRows(
              `MÃE`, motherName ? motherName : `-`,
              `PAI`, fatherName ? fatherName : `-`,
            ))
          }

          //Addresses
          const renderAddress = (json = {}) => {
            const { street, number, complement, district, city, state, zipCode, landline, cellPhone } = json;
        
            if (!street && !city) return null
        
            const part1 = street && (
              street +
              (number ? `, ` + number : ``) +
              (complement ? ` ` + complement : ``)
            )
        
            const part2 = district
        
            const part3 = city && (
              city +
              (state ? ` ` + state : ``)
            )
        
            const value = [part1, part2, part3].filter(x => x).join(' - ')
        
            const items = [{ label: `Endereço`, value }]
            if (zipCode)
              items.push({ label: `CEP`, value: util.formatCEP(zipCode) })
            if (landline || cellPhone)
              items.push({ label: `Telefone`, value: extractPhoneNumbers(json) })
            return renderValues(items)
          }

          const renderValues = (items) => {
            if (!items || items.length === 0) {
              return null
            }
            return items;
          }

          const extractPhoneNumbers = json => {
            const list = []
            const phones = [json.landline, json.cellPhone]
            phones.forEach(text => {
              (text || ``).split(`/`).forEach(number => {
                list.push(number.trim())
              })
            })
            return list.filter(i => i).map(util.formatPhone).join(` / `)
          }
        
          (function renderAddresses(){
            const { homeAddress = {}, businessAddress = {} } = props
            if (!homeAddress.street && !homeAddress.city && !businessAddress.street && !businessAddress.city) {
              return
            }

            const residencial = renderAddress(homeAddress)
            const comercial = renderAddress(businessAddress)

            if(residencial)
              adressGroupResidencial.push(residencial)
            
            if(comercial)
              adressGroupComercial.push(comercial)
            
          })()

          //Referrals
          referralsGroup.push(createGroupRows(
            `Dentista`, referrals.dental,
            `Jurídico`, referrals.juridical,
            `Médico`, referrals.medical,
            `Psiquiátrico`, referrals.psychiatric,
            `Psicológico`, referrals.psychological,
            `Serviço Social`, referrals.socialService,
          ))

          //Benefits
          benefitsGroup.push(createGroupRows(
            `Bolsa Família`, benefits.bolsaFamilia,
            `Aposentadoria`, benefits.retirement,
            `Aux. Reclusão`, benefits.confinementAid,
            `Prestação Continuada`, benefits.continuedServiceBenefit,
            `Plano de Saúde`, benefits.healthInsurance,
            `Outros`, benefits.others,
          ))

          //Questionnaire
          questionnaireGroup.push(createGroupRows(
            `Fumante`, questionnaire.smoker,
            `Alcoólatra`, questionnaire.alcoholic,
            `Cocaína`, questionnaire.cocaine,
            `Crack`, questionnaire.crack,
            `Maconha`, questionnaire.marijuana,
            `Psicotrópico`, questionnaire.psychotropics,
            `Solventes`, questionnaire.inhalantGlue,
            `Outras drogas`, questionnaire.otherDrugs,
            `Motivo`, benefits.reason
          ))

          //CardSispenVisitasRecebidas
          visits = visitors && (visitors.length > 0 || visitors.error) ? visitors : []

          //CardSispenColetas
          collects = collects2Print ? collects2Print : []

          //CardSispenCriminal
          const { criminal, prisonUnit, trialProcess } = person

          sispenCriminalGroup.push(createGroupRows(
            `Prisioneiro`, criminal.prisonerId,
            `Unidade Prisional`, prisonUnit.name,
            `Nível de Segurança`, criminal.securityLevel,
            `Prontuário`, criminal.medicalRecord,
            `Origem`, criminal.origin,
            `Última Alteração`, trialProcess.lastMovementDate ? util.date2str(trialProcess.lastMovementDate, true) : null,
            `Regime`, trialProcess.regime,
            `Situação`, trialProcess.situation
          ))

          ;(function renderDwellingHouse(){
            if (!dwellingHouse.type && !dwellingHouse.situation && (!dwellingHouse.peopleCount || dwellingHouse.peopleCount === -1)) {
              return;
            }

            sispenCriminalGroup.push(createGroupRows(
              `Residência`, dwellingHouse.type + (dwellingHouse.situation ? (` / ${dwellingHouse.situation}`) : ``),
              `Ocupação Atual`, `` + dwellingHouse.peopleCount + ` moradores / ` + dwellingHouse.compartmentsCount + ` compartimentos` ,
            ))
          })()

          sispenCriminalProcesses = processes && processes.length > 0 ? processes : [];

          //CardSispenObservacoes
          const text2lines = (targetList, text, title) => {
            const lines = (text || ``).replace(/\\\\/g, `\\`).replace(/\\"/g, `"`).trim().split(`\n`).filter(i => i)
            if (lines.length) 
              targetList.push([title, ...lines])
          }

          const { legalComments, futureClaims, additionalObservations } = props

          if (legalComments && legalComments.currentHistoricalCriminal) {
            text2lines(situacaoAtual, legalComments.currentHistoricalCriminal, ``)
          }
          if (legalComments && legalComments.criminalRecord) {
            text2lines(situacaoGeral, legalComments.criminalRecord, `ANTECEDENTES CRIMINAIS`)
          }
          if (futureClaims) {
            text2lines(pretensoesFuturas, futureClaims, `PRETENSÕES FUTURAS`)
          }
          if (additionalObservations && additionalObservations.additionalObservation) {
            text2lines(observacoes, additionalObservations.additionalObservation, `OBSERVAÇÕES COMPLEMENTARES`)
          }
        }

        const content = {
          error, 
          personGroup, 
          filiationGroup, 
          adressGroupResidencial, 
          adressGroupComercial,
          referralsGroup,
          benefitsGroup,
          questionnaireGroup,
          sispenCriminalGroup,
          sispenCriminalProcesses,
          visits,
          collects,
          observations: [
            situacaoAtual, 
            situacaoGeral, 
            pretensoesFuturas, 
            observacoes
          ].filter(item => item.length > 0)
        }
        return { content, image, error }
      },
      
      visitor: props => {
        const groups = []
        const visits = []
        const error = props.error
        let image = null

        if (!util.objIsEmpty(props)) {
          const { id, name, sex, birthDate, rg, cpf, addresses = {}, visitsMade = [], thumb } = props

          groups.push(createGroupRows(
            `ID`, `${id}`,
            `NOME`, name,
            `SEXO`, formatSex(sex),
          ))
  
          groups.push(createGroupRows(
            `IDADE`, birthDate ? (util.calcAge(birthDate) + ` anos`) : `-`,
            `Data de Nascimento`, birthDate ? util.date2str(birthDate) : `-`,
            `RG`, rg || `-`,
            `CPF`, cpf ? util.formatCPF(cpf) : `-`,
          ))
  
          const phones = [
            (addresses.homeAddress     || {}).landline,
            (addresses.businessAddress || {}).landline,
            (addresses.homeAddress     || {}).cellPhone,
            (addresses.businessAddress || {}).cellPhone,
          ].filter(i => i && i.trim().length > 5).map(i => util.formatPhone(i))
          if (phones.length > 0) {
            groups.push(createGroupRows(
              `TELEFONE`, phones.join(` / `),
            ))
          }
  
          visitsMade.forEach((visit) => {
            const date = new Date(visit.dateTime)
            visits.push({
              prisonerId: visit.prisonerId || `-`,
              linkType: visit.linkType,
              link: visit.link,
              date: util.date2str(date),
              time: util.date2time(date),
              prisonUnityName: visit.prisonUnity.name,
              prisonerName: visit.prisoner,
              prisonerCpf: util.formatCPF(visit.prisonerCpf),
              prisonerRg: visit.prisonerRg
            })
          })

          image = getPicture(thumb);
        }

        const content = groups
        return { content, visits, image, error }
      }
    }
}