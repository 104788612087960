
import * as type from '../fieldTypes'

export const CVLIbyCityRangeDate = {
  created: `2019-04-22`,
  context: `Indicadores.CVLI.relatório`,
  role: `CEREBRUM_BUSCA_CVLI_REPORT`,
  params: {
    city: {label: `Cidade`, placeholder: `Nome`, type: type.text},
    startDate: {label: `A partir de`, placeholder: `dd/mm/aaaa`, type: type.date, optional: true},
  },
  title: `GERAR RELATÓRIO`,
  subtitle: `Visualizar relatório de CVLI com base na Cidade e, de maneira opcional, a partir de uma data específica`,
  parser: ({city, startDate}) => {
    return {card: `CardCVLIReport`, props: {city, startDate}}
  }
}

export const CVLIbySituacaoAtualToday = {
  created: `2019-04-22`,
  context: `Indicadores.CVLI.situação atual`,
  role: `CEREBRUM_INDICADORES_SITUACAO_AIS`,
  title: `SITUAÇÃO ATUAL`,
  description: `Visualizar o estado atual de CVLI em todas as AIS em relação ao mesmo período do ano passado`,
  parser: () => {
    return {card: `CardCVLISituacaoAtual`, props: {date: new Date()}}
  }
}

export const CVLIbySituacaoAtualByDate = {
  created: `2019-04-22`,
  context: `Indicadores.CVLI.situação atual`,
  role: `CEREBRUM_INDICADORES_SITUACAO_AIS`,
  params: {
    date: {label: `Data`, placeholder: `dd/mm/aaaa`, type: type.date},
  },
  title: `SITUAÇÃO ATUAL POR DATA`,
  subtitle: `Visualizar o estado atual de CVLI em todas as AIS em uma data específica`,
  parser: (data) => {
    return {card: `CardCVLISituacaoAtual`, props: data}
  }
}
