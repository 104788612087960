import React, { Component } from 'react'
import classNames from 'classnames'
import { List as  WrapperList } from 'react-virtualized'
import './index.scss'

export class List extends Component {

  componentWillReceiveProps() {
    if  (this.list)
      this.list.forceUpdateGrid()
  }

  mouseLeaveHandler = () => {
    clearTimeout(this.timer)
    this.timer = setTimeout(this.updateHighlightMarker, 3000)
  }

  clickHandler = (point, row) => {
    const { card, onClickRow } = this.props
    card.props.publish(`map::zoom`, { point, zoom: this.props.zoomOnClick })
    if (onClickRow && row) onClickRow(row)
  }

  updateHighlightMarker = point => {
    if (point) {
      this.props.card.props.publish(`map::marker.add`, {id: `highlight`, point, className: `circle border animated`})
    } else {
      this.props.card.props.publish(`map::marker.remove`, {id: `highlight`})
    }
  }
  
  render() {
    const { rows=[], width=400, height=250, rowHeight=28 } = this.props
    const { renderHeader, highlightMarker } = this.props
    const total = rows.length
    const header = renderHeader && total ? <div className="row header">{ renderHeader() }</div> : null
    return (
      <div className="ui-list" onMouseLeave={ highlightMarker ? this.mouseLeaveHandler : null }>
        { header }
        <WrapperList
          width={ width }
          height={ Math.min( Math.max(total * rowHeight + 20, 160), height, 400 ) }
          rowHeight={ rowHeight }
          rowCount={ total }
          rowRenderer={ this.renderRow }
          ref={ i => this.list = i }
        />
      </div>
    )
  }

  renderRow = ({index, key, style}) => {
    const row = this.props.rows[index]
    const { renderRow, highlightMarker, zoomOnClick, selected, selectedField } = this.props
    const clickable = row.point && zoomOnClick
    const className = classNames(
      'row',
      'data',
      { 'pointer': clickable },
      { 'selected': row[selectedField] === selected }
    )
    return (
      <div key={ key } style={ style } className={className}
        onClick={ clickable ? this.clickHandler.bind(this, row.point, row) : null }
        onMouseEnter={ highlightMarker ? this.updateHighlightMarker.bind(this, row.point) : null }
      >
        { renderRow(row) }
      </div>
    )
  }

}

