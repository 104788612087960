import * as type from '../fieldTypes';

export const BordaCriminal = {
	created: `2022-06-17`,
	context: `Borda Criminal`,
	params: {
		scenario: {
			label: 'Ambiente - Cenário',
			placeholder: '',
			type: type.scenarioSelect,
		},
		startDateTime: {
			label: `Data inicial`,
			placeholder: `dd/mm/aaaa`,
			type: type.date,
		},
		endDateTime: {
			label: `Data final`,
			placeholder: `dd/mm/aaaa`,
			type: type.date,
		},
		startTime: { label: `Hora Inicial`, placeholder: `hh:mm`, type: type.time },
		endTime: { label: `Hora Final`, placeholder: `hh:mm`, type: type.time },
		weekDays: {
			label: 'Dias da semana',
			placeholder: '',
			type: type.daysWeekSelect,
		},
	},
	// description: `Intervalo de tempo para gerar a borda criminal`,

	parser: ({
		scenario,
		startDateTime,
		endDateTime,
		startTime,
		endTime,
		weekDays,
	}) => {

		const params = {
			startTime: `${startTime}:00`,
			endTime: `${endTime}:59`,
			startDate: new Date(startDateTime).toISOString().slice(0, 10),
			endDate: new Date(endDateTime).toISOString().slice(0, 10),
			weekDays: weekDays.values || [],
			weekDaysLabels: weekDays.labels || [],
		}

		return {
			card: `CardBordaCriminal`,
			props: {
				params,
				scenario: scenario.value,
				scenarioName: scenario.label
			}
		};
	},
};
