import React, { Component } from 'react'
import { publish, subscribe } from '../services/EventBus'
import { ServiceApiLogin } from '../services/api/login'
import { Login } from '../components/Login/Login'
import Routes from '../routes/Routes'

export class Root extends Component {

  state = {
    initialized: false,
    session: {},
    // // userActivity: false,
  }

  componentDidMount() {
    this.timer = 0
    // // this.renewSession = 0
    // // this.mouseReset = 0
    // // this.backToLogin = 0
    // // this.showSessionAlert = 0

    subscribe(`auth::login.start`, (topic, data) => {
      const { token, roles, client, error, message } = data
      this.setState({ token, roles, client, error, message })
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        publish(`auth::login`, data)
      }, 10)
    })
    subscribe(`auth::logout`, (topic, data) => {
      const options = data && data.http === false ? { http: false } : undefined
      ServiceApiLogin.clearSession(options)
      this.setState({ token: null, roles: null, client: null, error: null, message: null, loading: false })
    })
    this.sync()
  }

  sync = () => {
    ServiceApiLogin.getSession().then(data => {
      this.setState({ initialized: true })
      publish(`auth::login.start`, data)
    }).catch((_) => publish(`auth::logout`))
  }

  // // sessionRenewal = () => {

  // //   const {userActivity} = this.state

  // //   if(!userActivity){
    


  // //   clearTimeout(this.renewSession)
  // //   clearTimeout(this.backToLogin)
  // //   clearTimeout(this.mouseReset)
  // //   clearTimeout(this.showSessionAlert)
    
  // //     this.renewSession = setTimeout(() => {

  // //       ServiceApiLogin.clearSession()
  // //     }, 1800000)

  // //     this.backToLogin = setTimeout(() => {

  // //       window.location.reload()
  // //     }, 1801000)

  // //     this.mouseReset = setTimeout(() => {  
        
  // //       this.setState({userActivity:false})
  // //     }, 1000)

  // //     this.showSessionAlert = setTimeout(() => {
        
  // //       publish(`sessionAlert::open`)

  // //     },1500000)
      

  // //     this.setState({userActivity:true})
      
  // //   }


  // // }

  // hideModal(){
  //   publish(`sessionAlert::close`)
  // }


  render() {
    const { initialized, loading, client } = this.state
    if (!initialized) {
      return null // aguardando resposta de session /me
    }
    if (loading || !client) {
      return <Login/>
    }

    return (
      <>
        <Routes />
        {/* <div onMouseMove={this.sessionRenewal} onKeyDown={this.sessionRenewal} onClick={this.hideModal}>
          <Routes />
        </div>
        <SessionAlert /> */}
      </>
    )
  }
}
