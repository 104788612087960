import { App } from '../../App'
import { publish } from '../EventBus'

/**
 * abstração para comunicação em tempo real via websocket nativo
 * message sempre em forma de lista e notificada após intervalo em millis (default: 1000)
 */
export default class WS {

  static CLOSE_NORMAL      = 1000
  static CLOSE_ABNORMAL    = 1006
  static RECONNECT_TIMEOUT = 5000
  static POLICY_VIOLATION  = 1008

  constructor(url, options={}) {
    this.ws = null
    this.url = this.configureUrl(url)
    this.callbacks = {}
    this.autoreconnect = options.autoreconnect === false ? false : true
    this.connecting = false
    this.interval = options.interval || 1000
    this.card = options.card
    this.frames = []
    //
    if (options && options.autoconnect) {
      this.connect()
    }
  }

  configureUrl = url => {
    url = url || ''
    if (!url.includes('?')) url = url + '?'
    const hasParams = url.indexOf('?') < url.length - 1
    if (hasParams) url = url + '&'
    return url + 'shutdown=on-event' 
  }

  on = (topic, func) => {
    this.callbacks[topic] = func
    return this
  }

  notify = (name, event={}) => {
    const func = this.callbacks[name]
    if (func) {
      func(event)
    }
  }

  connect = () => {
    if (this.ws) {
      this.ws.close()
    }
    this.ws = new WebSocket(this.url)
    this.ws.onopen = this.onOpen
    this.ws.onmessage = this.onMessage
    this.ws.onerror = this.onError
    this.ws.onclose = this.onClose
    this.connecting = false
  }

  reconnect = () => {
    if (this.connecting || !this.autoreconnect) {
      return
    }
    this.connecting = true
    this.notify(`reconnecting`)
    setTimeout(() => {
      if (this.autoreconnect)
        this.connect()
    }, WS.RECONNECT_TIMEOUT)
  }

  disconnect = () => {
    clearInterval(this.timer)
    this.autoreconnect = false
    this.connecting = false
    if (this.ws) {
      this.ws.close()
      this.ws = null
    }
  }

  send = data => {
    if (typeof data === `object`)
      data = JSON.stringify(data)
    this.ws.send(data)
  }

  timerHandler = () => {
    if (this.frames.length === 0 || (this.card && this.card.props.hidden)) {
      return
    }
    const messages = this.frames.reduce((a,b) => a.concat(b), [])
    this.frames = []
    this.notify(`message`, messages)
  }

  onMessage = e => {
    if (!this.isValidSession()) {
      return this.disconnect()
    }
    if (e.data !== `[]` && e.data !== `{}`) {
      try {
        const json = JSON.parse(e.data)
        const list = typeof json === `object` && json.constructor === Array ? json : [json,]
        this.frames.push(list)
      } catch(e) {
        console.log(e)
      }
    }
  }

  isValidSession = () => {
    return !!App.getToken()
  }

  onOpen = e => {
    this.notify(`connected`)
    this.frames = []
    this.timer = setInterval(this.timerHandler, this.interval)
    if (this.interval > 2000)
      setTimeout(this.timerHandler, 1500)
  }

  onError = e => {
    clearInterval(this.timer)
    this.timerHandler()
    this.frames = []
    this.notify(`error`, e)
    if (this.autoreconnect) {
      this.reconnect()
    }
  }

  onClose = e => {
    clearInterval(this.timer)
    this.timerHandler()
    this.frames = []
    if (e.code === WS.POLICY_VIOLATION) {
      publish(`auth::logout`, { http: false })
      if (e.reason && e.reason.length > 5) {
        publish('error', e.reason)
      }
      this.notify(`error`, e.reason)
      return this.disconnect()
    }
    if (!this.isValidSession()) {
      return this.disconnect()
    }
    if (this.autoreconnect) {
      this.reconnect()
    } else {
      this.notify(e.code === WS.CLOSE_NORMAL ? `finished` : `error`, e)
    }
  }

}
