import React, { Component } from 'react'
import { MapController } from '../../Map/Controller'
import { publish } from '../../../services/EventBus'
import './index.scss'

export default class MapCircleSelection extends Component {

  state = {
    layer: {
      area: `circle-selection-area-` + Date.now() + `-` + Math.random().toString(36).slice(2),
      line: `circle-selection-line-` + Date.now() + `-` + Math.random().toString(36).slice(2),
      edit: `circle-selection-edit-` + Date.now() + `-` + Math.random().toString(36).slice(2),
    },
    circle: null, lat: ``, lng: ``, rad: `` // radius in meters
  }

  constructor(props) {
    super(props)
    this.state.maxRadius = props.maxRadius || 10000 // 10km
  }

  componentDidMount() {
    const { area, line, edit } = this.state.layer
    const group = {color: `#b7b7b7`, title: `Seleção Raio`}
    publish(`map::layer.add`, {name: area, circles: [], group, stroke: `rgba(0,0,0,0)`, color: `rgba(234,209,162,0.2)`, opacity: 0.2})
    publish(`map::layer.add`, {name: line, circles: [], group, stroke: `#fff`, color: `rgba(234,209,162,0.2)`, opacity: 0.2})
    publish(`map::layer.add`, {name: edit, circles: [], group, stroke: `#fec`, color: `rgba(234,209,162,0.1)`, opacity: 0.4})
  }

  componentWillUnmount() {
    const { area, line, edit } = this.state.layer
    publish(`map::layer.remove`, {name: area})
    publish(`map::layer.remove`, {name: line})
    publish(`map::layer.remove`, {name: edit})
  }

  hasInputChanged = () => {
    try {
      const lat1 = parseFloat(this.state.lat).toFixed(6)
      const lng1 = parseFloat(this.state.lng).toFixed(6)
      const rad1 = parseInt(this.state.rad, 10).toString()
      const lat2 = this.state.circle.center[1].toFixed(6)
      const lng2 = this.state.circle.center[0].toFixed(6)
      const rad2 = this.state.circle.radius.toString()
      return (lat1 !== lat2) || (lng1 !== lng2) || (rad1 !== rad2)
    } catch {
      return true // invalid input values OR circle is not defined
    }
  }

  changeHandler = (field, event) => {
    this.setState({[field]: event.target.value}, this.updateEditLayer)
  }

  keyDownHandler = e => {
    if (e.keyCode === 13) {
      this.clickHandler()
      return e.target.blur()
    }
    if (e.keyCode === 27 && this.state.circle) {
      const { center, radius } = this.state.circle
      const [ lng, lat ] = center.map(coord => coord.toFixed(7).slice(0,-1))
      this.setState({lat, lng, rad: radius.toString() + `m`})
      publish(`map::layer.clear`, {name: this.state.layer.edit})
      publish(`map::zoom`, {point: center, radius})
      return e.target ? e.target.blur() : null
    }
  }

  hasEmptyValues = () => {
    const { lat, lng, rad } = this.state
    return !lat || !lng || !rad
  }

  extractInputValues = () => {
    try {
      const lat = parseFloat(this.state.lat).toFixed(7).slice(0,-1)
      const lng = parseFloat(this.state.lng).toFixed(7).slice(0,-1)
      const rad = parseInt(this.state.rad, 10).toString()
      return {lat, lng, rad}
    } catch {
      return {}
    }
  }

  clearMap = () => {
    const { area, line, edit } = this.state.layer
    publish(`map::layer.clear`, {name: area})
    publish(`map::layer.clear`, {name: line})
    publish(`map::layer.clear`, {name: edit})
  }

  updateMap = circle => {
    const { area, line, edit } = this.state.layer
    const circleLine = {...circle}
    const circleArea = {...circle, radius: circle.radius * 1.05}
    publish(`map::layer.update`, {name: area, circles: [circleArea,]})
    publish(`map::layer.update`, {name: line, circles: [circleLine,]})
    publish(`map::layer.clear`,  {name: edit})
  }

  updateEditLayer = () => {
    const o = this.extractInputValues()
    if (o.lat) {
      const [ lat, lng, radius ] = [parseFloat(o.lat), parseFloat(o.lng), parseInt(o.rad, 10)]
      const circle = {center: [lng, lat], radius}
      publish(`map::layer.update`, {name: this.state.layer.edit, circles: [circle,]})
    }
  }

  apply = circle => {
    if (!circle) {
      if (this.state.circle) {
        this.updateMap(this.state.circle)
        this.keyDownHandler({keyCode: 27})
      }
      return
    }
    const { center, radius } = circle
    const [ lng, lat ] = center
    const rad = radius.toString() + `m`
    this.updateMap(circle)
    this.setState({circle, lat, lng, rad})
    this.props.onChange({...circle})
  }

  clickHandler = () => {
    const { circle } = this.state
    const { lng, lat, rad } = this.extractInputValues()
    const inputChanged = this.hasInputChanged()
    //
    if (!circle || (circle && !inputChanged) || this.hasEmptyValues()) {
      return this.wait4mapSelection().then(data => {
        this.apply(data)
      })
    }
    if (inputChanged && lat && lng && rad) {
      const center = [parseFloat(lng), parseFloat(lat)]
      const radius = parseInt(rad, 10)
      if (isNaN(center[0]) || isNaN(center[1]) || isNaN(radius)) {
        this.setState({error: true})
        setTimeout(this.clearError, 2000)
      } else {
        this.apply({center, radius})
        publish(`map::zoom`, {point: center, radius})
      }
    }
  }

  wait4mapSelection = () => {
    return new Promise(resolve => {
      this.clearMap()
      publish(`cardmanager::visibility`, false)
      const options = {type: `circle`, maxRadius: this.state.maxRadius}
      MapController.wait4circleSelection(options, circle => {
        if (circle && circle.center) {
          circle.center = circle.center.map(coord => parseFloat(coord.toFixed(7).slice(0,-1)))
        }
        publish(`cardmanager::visibility`, true)
        resolve(circle)
      })
    })
  }

  clearError = () => {
    this.setState({error: false})
  }

  getButtonLabel = () => {
    const edited = this.state.circle && this.hasInputChanged() && !this.hasEmptyValues()
    return edited ? `APLICAR` : `DEFINIR ÁREA`
  }

  render() {
    const { error, circle } = this.state
    const fields = [
      {id: `lat`, label: `Latitude`},
      {id: `lng`, label: `Longitude`},
      {id: `rad`, label: `Raio`},
    ]
    return (
      <div className="MapCircleSelection" onKeyDown={ this.keyDownHandler }>
        <div className={ `fields` + (error ? ` shake` : ``) + (circle ? `` : ` no-edit`)}>
          {fields.map(i => (
            <div className="field" key={ i.id }>
              <label>{ i.label }</label>
              <input value={ this.state[i.id] } onChange={ this.changeHandler.bind(this, i.id) } autoComplete="disabled" autoCorrect="disabled"/>
            </div>
          ))}
          <div className="field action">
            <button onClick={ this.clickHandler }>
              { this.getButtonLabel() }
            </button>
          </div>
        </div> 
      </div>
    )
  }

}
