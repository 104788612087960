
// Provider
import PubSub from 'pubsub-js'
import config from '../config'

if (config.development) {
  PubSub.immediateExceptions = true
}

// API
export const publish = PubSub.publish
export const subscribe = PubSub.subscribe
export const unsubscribe = PubSub.unsubscribe
export const clearAllSubscriptions = PubSub.clearAllSubscriptions
