
import util from '../../../helpers/util'
import * as type from '../fieldTypes'

export const TornozeladosOnline = {
  created: `2019-04-22`,
  context: `Tornozelados`,
  role: `CEREBRUM_MONITORADOS_ONLINE`,
  title: `TORNOZELADOS ONLINE`,
  description: `Acompanhamento em tempo real de todas as posições`,
  parser: () => {
    return {card: `CardTornozeladosOnline`, props: {}}
  }
}

export const TornozeladosByArea = {
  created: `2019-04-22`,
  context: `Tornozelados`,
  role: `CEREBRUM_MONITORADOS_AREA`,
  title: `TORNOZELADOS POR ÁREA HOJE`,
  description: `Visualizar todos os tornozelados que tiveram ao menos uma coleta de posição hoje dentro de uma área no mapa`,
  parser: () => {
    const startTime = util.date2time(new Date(2020,0,0,0,0,0), true)
    const endTime   = util.date2time(new Date(), true)
    return {card: `CardTornozeladosHistoricoArea`, props: {startTime, endTime, date: new Date()}}
  }
}

export const TornozeladosByRangeTime = {
  created: `2019-04-22`,
  context: `Tornozelados`,
  role: `CEREBRUM_MONITORADOS_AREA`,
  params: {
    date: {label: `Data`, placeholder: `dd/mm/aaaa`, type: type.date},
    startTime: {label: `Hora Inicial`, placeholder: `hh:mm`, type: type.time, optional: true},
    endTime: {label: `Hora Final`, placeholder: `hh:mm`, type: type.time, optional: true},
  },
  parser: ({date, startTime, endTime}) => {

    if (util.isDateInFuture(date))
      throw new Error('Não é possível buscar por uma data futura.')

    const now = new Date()
    const hour = now.getHours()
    const hourText = hour.toString()
    const min = now.getMinutes()
    let minText = min.toString()
    
    if (util.isDateToday(date)) {
      if (startTime) {
        const startAux = startTime.split(':')
        if (parseInt(startAux[0], 10) > hour || (parseInt(startAux[0], 10) === hour && parseInt(startAux[1], 10) > min))
          throw new Error('Não é possível buscar por uma hora futura.')
      } else {
        startTime = (startTime || `00:00`) + `:00`
      }
      
      if (endTime) {
        const endAux = endTime.split(':')
        if (parseInt(endAux[0], 10) > hour || (parseInt(endAux[0], 10) === hour && parseInt(endAux[1], 10) > min))
          throw new Error('Não é possível buscar por uma hora futura.')
      } else {
        if (minText.length < 2)
          minText = `0` + minText
        
        endTime = (endTime || hourText+`:`+minText) + `:00`
      }
    } else {
      startTime = (startTime || `00:00`) + `:00`
      endTime = (endTime || `23:59`) + `:59`
    }

    return {card: `CardTornozeladosHistoricoArea`, props: {date, startTime, endTime}}
  }
}
