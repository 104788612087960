
import { subscribe, unsubscribe } from '../../services/EventBus'

let map = null
let center = null
let border = null
let radius = null
let options = null
let callback = null
let domCircle = null
let domLine   = null
let domText   = null
let domShapeA = null
let domShapeB = null
let domShapeC = null
let beforeXY = null
let cancel = false
let listenCancel = null
let timer = null
let circleDistance = 0
let rotA = 0
let rotB = 0
let rotC = 0

export default (_map, _options, _callback) => {
  map = _map
  options = _options
  callback = _callback
  listenCancel = subscribe(`event::keyup.esc`, cancelSelection)
  //
  map.dom.addEventListener(`mousedown`, mouseDown, false)
  map.dom.addEventListener(`mouseup`,   mouseUp,   false)
  map.dom.addEventListener(`mousemove`, mouseMove, false)
  map.divCancel.querySelector(`button`).addEventListener(`click`, cancelSelection, false)
  map.divCancel.classList.add(`visible`)
  map.canvas.classList.add(`selection`)
  map.dom.classList.add(`selection`)
  document.body.classList.add(`no-header`)
  //
  map.drawerDIV.innerHTML = ``
    + `<div class="text hidden"></div>`
    + `<div class="tip">SEGURE E ARRASTE O MOUSE PARA SELECIONAR A ÁREA DE BUSCA</div>`
    + `<div class="point-center hidden"></div>`
    + `<div class="shape a"></div>`
    + `<div class="shape b"></div>`
    + `<div class="shape c"></div>`
  //
  map.drawerSVG.innerHTML = `<circle class="circle" r="0" cx="0" cy="0"/>`
    + `<line class="line" x1="0" y1="0" x2="0" y2="0"/>`
  //
  domText   = map.drawerDIV.querySelector(`.text`)
  domCircle = map.drawerSVG.querySelector(`.circle`)
  domLine   = map.drawerSVG.querySelector(`.line`)
  domShapeA = map.drawerDIV.querySelector(`.shape.a`)
  domShapeB = map.drawerDIV.querySelector(`.shape.b`)
  domShapeC = map.drawerDIV.querySelector(`.shape.c`)
  //
  timer = setInterval(timerHandler, 1100)
}

const timerHandlerNow = () => {
  const a = domShapeA
  const b = domShapeB
  const c = domShapeC
  //
  const value = (circleDistance * 2) + `px`
  a.style.width = value
  a.style.height = value
  //
  b.style.width = value
  b.style.height = value
  //
  c.style.width = value
  c.style.height = value
}

const timerHandler = () => {
  const a = domShapeA
  const b = domShapeB
  const c = domShapeC
  if (Math.random() > 0.5) a.style.opacity = 0.1 + Math.random() * 0.3
  if (Math.random() > 0.5) b.style.opacity = 0.1 + Math.random() * 0.5
  //
  if (Math.random() > 0.3) {
    const rot = parseInt(rotA + (-90 + Math.random() * 180), 10)
    const scale = 1 + (-0.05 + Math.random() * 0.2)
    a.style.transform = `translate(-50%,-50%) rotate(${rot}deg) scale(${scale})`
    rotA = rot
  }
  if (Math.random() > 0.3) {
    const rot = parseInt(rotB + (-90 + Math.random() * 180), 10)
    const scale = 1 + (-0.05 + Math.random() * 0.2)
    b.style.transform = `translate(-50%,-50%) rotate(${rot}deg) scale(${scale})`
    rotB = rot
  }
  const rot = parseInt(rotC + (-180 + Math.random() * 360), 10)
  const scale = 1 + (Math.random() * 0.05)
  c.style.transform = `translate(-50%,-50%) rotate(${rot}deg) scale(${scale})`
  rotC = rot
}

const toRad = degree => degree * Math.PI / 180

const calcDistance = (a, b) => {
  const R = 6373 // kilometers
  //
  let diffLat = toRad(b[1] - a[1])
  let diffLng = toRad(b[0] - a[0])
  let lat1 = toRad(a[1])
  let lat2 = toRad(b[1])
  let v = Math.pow(Math.sin(diffLat/2), 2)
        + Math.pow(Math.sin(diffLng/2), 2) * Math.cos(lat1) * Math.cos(lat2)
  let c = 2 * Math.atan2(Math.sqrt(v), Math.sqrt(1 - v))
  return parseInt(R * c * 1000, 10)
}

const clear = () => {
  clearInterval(timer)
  unsubscribe(listenCancel)
  map.dom.removeEventListener(`mousedown`, mouseDown)
  map.dom.removeEventListener(`mouseup`,   mouseUp)
  map.dom.removeEventListener(`mousemove`, mouseMove)
  map.divCancel.querySelector(`button`).removeEventListener(`click`, cancelSelection)
  map.divCancel.classList.remove(`visible`)
  map.canvas.classList.remove(`selection`)
  map.dom.classList.remove(`selection`)
  document.body.classList.remove(`no-header`)
  map = null
  callback = null
  center   = null
  border   = null
  radius   = null
  beforeXY = null
  domCircle = null
  domLine = null
  domShapeA = null
  domShapeB = null
  domShapeC = null
  circleDistance = 0
  cancel = false
}

const getPosition = e => {
  const p = map.unproject([e.x, e.y])
  return [p.lng, p.lat]
}

const updateLine = (p1, p2) => {
  domLine.setAttribute(`x1`, p1.x)
  domLine.setAttribute(`y1`, p1.y)
  domLine.setAttribute(`x2`, p2.x)
  domLine.setAttribute(`y2`, p2.y)
}

const mouseDown = e => {
  if (e.target.nodeName === `BUTTON`) {
    cancel = true
    return e.stopPropagation()
  }
  //
  center = getPosition(e)
  border = getPosition(e)
  //
  beforeXY = {x: e.x, y: e.y}
  domCircle.setAttribute(`cx`, e.x)
  domCircle.setAttribute(`cy`, e.y)
  domCircle.setAttribute(`r`, 0)
  //
  domText.innerHTML = `0`
  domText.style.top = e.y + `px`
  domText.style.left = e.x + `px`
  //
  updateLine(beforeXY, beforeXY)
  //
  const p = map.drawerDIV.querySelector(`.point-center`)
  p.classList.remove(`hidden`)
  p.style.left = e.x + `px`
  p.style.top = e.y + `px`
  //
  const a = domShapeA
  const b = domShapeB
  const c = domShapeC
  a.style.left = e.x + `px`
  a.style.top = e.y + `px`
  b.style.left = e.x + `px`
  b.style.top = e.y + `px`
  c.style.left = e.x + `px`
  c.style.top = e.y + `px`
  //
  mouseMove(e)
}

const mouseMove = e => {
  if (!center || cancel) {
    return
  }
  e = limitMousePosition(e)
  //
  const current = {x: e.x, y: e.y}
  const p0 = beforeXY || current
  const p1 = current
  const x  = p1.x - p0.x
  const y  = p1.y - p0.y
  const dist = Math.sqrt(x*x + y*y)
  circleDistance = dist
  timerHandlerNow()
  //
  domCircle.setAttribute(`r`, dist)
  updateLine(p0, p1)
  //
  const midX = parseInt((p0.x + p1.x) / 2, 10)
  const midY = parseInt((p0.y + p1.y) / 2, 10)
  domText.style.left = midX + `px`
  domText.style.top = midY + `px`
  if (dist < 50 && domText.className === "text") {
    domText.className = "text hidden"
  } else if (dist > 50 && domText.className === "text hidden") {
    domText.className = "text"
  }
  //
  border = getPosition(e) || center
  try {
    radius = calcDistance(center, border)
    if (radius < 1000) {
      domText.innerHTML = radius + `m`
    } else {
      domText.innerHTML = (radius / 1000).toFixed(2).replace(/\d$/,``).replace(`.`,`,`) + `km`
    }
  } catch(err) {
    console.log(`Falha ao calcular mapa circulo raio`, err)
  }
}

const mouseUp = (e) => {
  if (cancel) {
    return cancelSelection()
  }
  callback({center, radius, text: domText.innerText})
  clear()
}

const cancelSelection = () => {
  if (map) {
    callback(null)
    clear()
  }
}

const limitMousePosition = e => {
  if (!options || !options.maxRadius) {
    return e
  }
  const pos = {x: e.x, y: e.y}
  const diffX = pos.x - beforeXY.x
  const diffY = pos.y - beforeXY.y
  if (diffX === 0 && diffY === 0) {
    return e
  }
  const radius = calcDistance(center, getPosition(pos))
  if (radius < options.maxRadius) {
    return e
  }
  const scale = radius / options.maxRadius
  const x = beforeXY.x + diffX / scale
  const y = beforeXY.y + diffY / scale
  return { x, y }
}
