import mapboxgl from 'mapbox-gl'
import * as DrawLayer from './DrawLayer'
import DrawCircleSelection from './DrawCircleSelection'
import { createHtml as createHtmlPopup } from './MapPopup'

// flag `desativa` mouseover (possibilidade de click) para níveis "< 12"
const ZOOM_MIN_CLICK = 10

/**
 * rotinas para manipulação de layers, click, flyTo, etc
 */
export const MapController = {

  // instancia atual do mapa / mapboxgl
  map: null,

  wait4circleSelection: (options={}, callback) => {
    DrawCircleSelection(MapController.map, options, callback)
  },

  // registra uma layer com sua respectiva `source`
  createLayer: function(layer, options) {
    const layerInfo = DrawLayer.generateGeoJSON(layer)
    const geojson   = DrawLayer.data2geojson(layer)
    this.map.addSource(layer.id, geojson)
    this.map.addLayer(layerInfo)
    const { popup, click } = options
    if (popup) {
      handleClickEvents(this.map, layer.id, popup, click, pointClicked => {
        click(pointClicked)
      })
    }
  },
  updateLayer: function(layer) {
    const geojson = DrawLayer.data2geojson(layer)
    this.map.getSource(layer.id).setData(geojson.data)
  },
  removeLayer: function(id) {
    if (typeof id === `object` && id.id !== undefined) {
      id = id.id
    }
    this.map.removeLayer(id)
    this.map.removeSource(id)
  },

  createMarker: function(data, options) {
    const popupData = data.popup ? data.popup(data) : {}
    const clickHandler = data.click || options.click
    //
    const div = document.createElement(`div`)
    div.innerHTML = `<div class="box-container"><div class="box"></div></div>`
    div.className = [
      `map-marker`,
      data.className || ``,
      clickHandler ? `pointer` : ``,
    ].filter(_ => _).join(` `)
    if (data.color) {
      div.style.backgroundColor = data.color
    } else {
      div.style.backgroundColor = `transparent`
    }
    const marker = new mapboxgl.Marker(div)
    marker.setLngLat(data.point)
    if (options && options.popup) {
      const html = createHtmlPopup(popupData)
      const opts = {closeButton: false, closeOnClick: true, offset: 5}
      const popup = new mapboxgl.Popup(opts).setHTML(html)
      div.addEventListener(`mouseenter`, _ => !popup.isOpen() && marker.togglePopup())
      div.addEventListener(`mouseleave`, _ =>  popup.isOpen() && marker.togglePopup())
      if (clickHandler) {
        const obj = {...data}
        delete obj.element
        div.addEventListener(`click`, _ => clickHandler(obj))
      }
      marker.setPopup(popup)
    }
    marker.addTo(this.map)
    return marker
  },

  removeMarker: function(marker) {
    const popup = marker.getPopup()
    if (popup) {
      popup.remove()
    }
    marker.remove()
  },

  showLayer: function(layerId, visible) {
    this.map.setLayoutProperty(layerId, 'visibility', visible ? `visible`: `none`)
  },

  setColor: function(layerId, color) {
    this.map.setPaintProperty(layerId, `circle-color`, color)
  },
  setOpacity: function(layerId, opacity) {
    this.map.setPaintProperty(layerId, `circle-opacity`, opacity)
  },

  flyTo: function(point, zoom, radius=0) {
    if (radius && !zoom) {
      const coords = DrawLayer.circle2coords(point, radius)[0]
      return this.flyToPoints(coords, {maxZoom: 18, padding: 200})
    }
    this.map.flyTo({center: point || [-38.6, -3.8], zoom: zoom || 10.8})
  },

  flyToPoints: function(points=[], options={}) {
    if (points.length === 0)
      return
    const maxZoom = options.maxZoom || 15
    const padding = options.padding || 150
    const p = points[0]
    const bounds = points.reduce((bounds, point) => bounds.extend(point), new mapboxgl.LngLatBounds(p, p))
    this.map.fitBounds(bounds, {padding, maxZoom})
  },

  flyToLayers: function(layers, padding=250) {
    if (layers.filter(_ => _.data.zoom === null).length === layers.length) {
      return
    }
    let points = []
    layers.forEach(layer => {
      if (layer.type === `points`) {
        points = points.concat(layer.data.map(_ => _.point))
      } else if (layer.point) {
        points.push(layer.point)
      } else if (layer.type === `lines`) {
        layer.data.forEach(line => {
          points = points.concat(line)
        })
      } else if (layer.type === `polygons`) {
        layer.data.coordinates.forEach(polygon => {
          polygon.forEach(coords => {
            points = points.concat(coords)
          })
        })
      }
    })
    if (points.length === 0) {
      return
    }
    const p = points[0]
    const bounds = points.reduce((bounds, point) => bounds.extend(point), new mapboxgl.LngLatBounds(p, p))
    this.map.fitBounds(bounds, {padding})
  },

	createImageLayer: function(data) {
		try {
			const { layerId, image64, boundingArea } = data

			const corner1Long = boundingArea.minLongitude
			const corner1Lat = boundingArea.maxLatitude

			const corner2Long = boundingArea.maxLongitude
			const corner2Lat = boundingArea.maxLatitude

			const corner3Long = boundingArea.maxLongitude
			const corner3Lat = boundingArea.minLatitude

			const corner4Long = boundingArea.minLongitude
			const corner4Lat = boundingArea.minLatitude	


			this.map.addSource(layerId, {
				'type': 'image',
				'url': `data:image/png;base64,${image64}`,
				'coordinates': [
					[corner1Long, corner1Lat],
					[corner2Long, corner2Lat],
					[corner3Long, corner3Lat],
					[corner4Long, corner4Lat],
				],
				});

			this.map.addLayer({
				id: layerId,
				'type': 'raster',
				'source': layerId,

				'paint': {
				'raster-fade-duration': 0
				}
			});

			const bounds = new mapboxgl.LngLatBounds([corner1Long, corner1Lat], [corner1Long, corner1Lat]);
			bounds.extend([corner1Long, corner1Lat]);
			bounds.extend([corner2Long, corner2Lat]);
			bounds.extend([corner3Long, corner3Lat]);
			bounds.extend([corner4Long, corner4Lat]);

			this.map.fitBounds(bounds, { padding: 20 });

		} catch (error) {
			console.error(error);

		}
	},

	createGeoJsonLayer: function(layer) {
		const { geojson, layerId } = layer
		// const layerInfo = 

		try {
			this.map.addSource(layerId, {
				type: 'geojson',
				data: geojson
			});

			// Preenchimento
			this.map.addLayer({
				'id': layerId,
				'type': 'fill',
				'source': layerId,
				'paint': {
					'fill-color': '#e2dcb8', // blue color fill
					'fill-opacity': 0.2
				}
			});

			// Linha
			this.map.addLayer({
				'id': `${layerId}line`,
				'type': 'line',
				'source': layerId,
				'paint': {
					'line-color': '#e2dcb8',
					'line-opacity': 1,
					'line-width': 4,
				}
			});

			const coords = geojson.features[0].geometry.coordinates
			const bounds = new mapboxgl.LngLatBounds(coords[0][0], coords[0][0]);
	
			geojson.features.forEach(function(feature){
				const coords = feature.geometry.coordinates
				coords.forEach(coord => {
					bounds.extend(coord)
				})
			});

			this.map.fitBounds(bounds, { padding: 60 });

		} catch (error) {
			console.error(error)
		}

	},

	removeGeoJsonLayer: function(id) {
    this.map.removeLayer(id);
    this.map.removeLayer(id+'line');
    this.map.removeSource(id);
	}
}


function handleClickEvents(map, layerId, cbDrawPopup, clickable, cbPointSelected) {
  // controle de tempo para que um ponto não seja clicado muitas vezes em sequencia 
  const SLEEP_BETWEEN_CLICKS = 1000
  let clickedAt = Date.now()
  //
  if (map.popup === undefined) {
    map.popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false
    })
  }
  if (clickable) {
    map.on(`click`, layerId, e => {
      if (map.getZoom() < ZOOM_MIN_CLICK)
        return
      if (map.popup) {
        map.getCanvas().style.cursor = ``
        map.popup.remove()
      }
      if (Date.now() - clickedAt < SLEEP_BETWEEN_CLICKS * 1.1)
        return
      clickedAt = Date.now()
      let data = e.features[0].properties
      if (data.point && /^\[-?\d+/.test(data.point))
        data.point = JSON.parse(data.point)
      cbPointSelected(data)
    })
  }
  map.on(`mouseenter`, layerId, e => {
    if (map.getZoom() < ZOOM_MIN_CLICK)
      return
    if (!(e.features && e.features[0].geometry))
      return
    if (Date.now() - clickedAt < SLEEP_BETWEEN_CLICKS)
      return
    //
    // html da popup sendo gerado a partir do dado para que as atualizações
    // na layer seja automaticamente refletida no conteúdo das popups
    const coords = getFeatureCoords(e.features[0])
    const data   = e.features[0].properties
    const html   = cbDrawPopup(data)
    map.popup.setLngLat(coords).setHTML(html).addTo(map)
    map.getCanvas().style.cursor = clickable ? `pointer` : `default`
  })
  map.on(`mouseleave`, layerId, e => {
    if (map.popup) {
      map.getCanvas().style.cursor = ``
      map.popup.remove()
    }
  })
}


export function getFeatureCoords({ geometry }) {
  switch (geometry.type) {
    case 'Polygon': return getPolygonCenter(geometry.coordinates)
    case 'Point': return geometry.coordinates.slice()
    default: 
      console.log(`Uncovered type on getFeatureCoords: ${geometry.type}. Returning [0, 0]`)
      return [0, 0]
  }
}

function getPolygonCenter(polygonCoordinates) {
  const allLng = [].concat(...polygonCoordinates.map(_ => _.map(_ => _[0])))
  const allLat = [].concat(...polygonCoordinates.map(_ => _.map(_ => _[1])))
  const lowestLng = Math.min(...allLng)
  const lowestLat = Math.min(...allLat)
  const highestLng = Math.max(...allLng)
  const highestLat = Math.max(...allLat)
  const centerLng = lowestLng + ((highestLng - lowestLng) / 2)
  const centerLat = lowestLat + ((highestLat - lowestLat) / 2)
  return [centerLng, centerLat]
}

