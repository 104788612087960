import React, { Component } from 'react'
import util from '../../../../helpers/util'
import './index.scss'

export class CardCVLIOcorrencia extends Component {

  static ROLES = [`CEREBRUM_INQ_DETALHES`]

  state = {
    json: {}
  }

  extractQueryParams = () => {
    const  { policeStation, code, year, procedureTypeId } = this.props
    return { policeStation, code, year, procedureTypeId }
  }

  createMarker = () => {
    const { point } = this.props
    if (point) {
      this.props.publish(`map::marker.add`, {point, color: `#fa2`, className: `circle border`})
    }
  }

  componentDidMount() {
    const query = this.extractQueryParams()
    this.props.http(`/criminal/occurrence/details`, query).then(json => {
      this.setState({json}, this.props.show)
      this.props.openCard(`CardCVLIOcorrenciaDepoimentos`, {...json, ...this.props})
    })
    this.props.setTitle(`CVLI OCORRÊNCIA ` + this.props.procedureType + ` ` + this.props.id)
    this.createMarker()
  }

  render() {
    if (this.props.hidden) {
      return null
    }
    const { id, procedureType } = this.props
    return (
      <div className="CardCVLIOcorrencia">
        <div className="card-title">{ procedureType } <span>{ id }</span></div>
        { this.renderContent() }
      </div>
    )
  }

  getAgeRow = () => {
    const sex = this.props.victimSex
    const age = this.props.victimAge
    if (sex === `M`) return `${age} / MASCULINO`
    if (sex === `F`) return `${age} / FEMININO`
    if (sex === `I`) return `${age} / SEXO INDEFINIDO`
    return `${age} / SEXO NÃO DEFINIDO`
  }

  renderContent() {
    const { details } = this.state.json
    const groups = [
      {label: `DATA`,        value: util.date2str(details.installDate) + ` ` + util.date2time(details.installDate) + ` ` + util.getWeekday(details.installDate)},
      {label: `NOTIFICAÇÃO`, value: util.date2str(details.notificationDate) + ` ` + util.date2time(details.notificationDate) + ` ` + util.getWeekday(details.notificationDate)},
      {label: `OCORRÊNCIA`,  value: util.date2str(details.occurrenceDate) + ` ` + util.date2time(details.occurrenceDate) + ` ` + util.getWeekday(details.occurrenceDate)},
      null,
      {label: `REF.`,     value: details.referencePlace},
      {label: `NATUREZA`, value: details.nature},
      {label: `ARMA`,     value: this.props.crimeWeapon},
      {label: `VÍTIMA`,   value: this.props.victimName},
      {label: `IDADE`,    value: this.getAgeRow()},
      null,
      {label: `LOCAL`,    value: details.placeType},
      {label: `ENDEREÇO`, value: details.address},
      {label: ``,         value: [details.district || ``, details.city || ``].filter(i => i).join(` / `)},
    ].filter(i => i === null || (i && i.value))
    return this.renderGroups(``, [groups,])
  }

  renderGroups = (title=``, groups=[]) => {
    return (
      <div className="group-info">
        { title ? <p className="main"><label></label>{ title }</p> : null }
        {groups.map((group, index) => (
          <div className="group-info" key={ index }>
            {group.map((i, key) => (
              <React.Fragment key={ key }>
                { i === null ? <div className="space"/> : <p><label>{ i.label }</label>{ i.value }</p>}
              </React.Fragment>
            ))}
          </div>
        ))}
      </div>
    )
  }

}
