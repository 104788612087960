import React, { Component } from 'react'
import './index.scss'

export default class TableChart extends Component {

  constructor(props) {
    super(props)
    this.state = {data: props.data, header: props.header}
    this.state.data.forEach(dataRow => {
      dataRow.cells = dataRow.cells.map(_ => ({total: _, selected: false}))
    })
    // calc circle radius/delay
    const radius = 26
    const max = this.state.data.map(row => row.cells.map(_ => _.total).reduce((a,b)=>Math.max(a,b),0)).reduce((a,b)=>Math.max(a,b),0)
    this.state.data.forEach(row => {
      row.cells.forEach(cell => {
        cell.radius = parseInt((cell.total / max) * radius, 10)
        cell.delay  = max*2 - cell.total*2
      })
    })
  }

  /*
   * verifica se a seleção solicitada pelo usuário será a mesma
   * se sim, o resultado será de desfazer a seleção corrente
   */
  _clearIsTheSame = (name) => {
    let data = this.state.data
    data.forEach(rowData => {
      rowData.cells.forEach(_ => _.selected = false)
    })
    if (this.state.selection === name) {
      this.setState({...this.state, data, selection: null})
      if (this.props.clearSelection)
        this.props.clearSelection()
      return true
    }
    return false
  }

  onClickColumn = (index) => {
    const selection = `column:${index}`
    if (this._clearIsTheSame(selection))
      return
    const data = this.state.data
    data.forEach(row => {
      row.cells[index].selected = true
    })
    this.setState({...this.state, data, selection})
    if (this.props.colSelected)
      this.props.colSelected(index)
  }

  onClickRow = (label, index) => {
    const selection = `row:${label}`
    if (this._clearIsTheSame(selection))
      return
    let data = this.state.data
    data.filter(_ => _.label === label)[0].cells.map(_ => _.selected = true)
    this.setState({...this.state, data, selection})
    if (this.props.rowSelected)
      this.props.rowSelected(label, index)
  }

  onClickCell = (label, index, object, info) => {
    const selection = `cell:${label+index}`
    if (this._clearIsTheSame(selection))
      return
    let data = this.state.data
    data.filter(_ => _.label === label)[0].cells[index].selected = true
    this.setState({...this.state, data, selection})
    if (this.props.selected)
      this.props.selected(label, index, info)
  }

  render() {
    const clss = "TableChart " + (this.props.type || '')
    return (
      <div className={ clss }>
        <div className="header">
          { this.renderHeader(this.state.header) }
        </div>
        <div className="body">
          { this.renderRows(this.state.data) }
        </div>
      </div>
    )
  }

  renderHeader(header) {
    const { extraInfo } = this.props
    return (
      <React.Fragment>
        <span className="column just-label">-</span>
        {header.map((column, index) =>
          <span key={ index } className={ `column column-${index} column-${column} pointer` } onClick={ this.onClickColumn.bind(this, index) }>
            { column }
          </span>
        )}
        { extraInfo === false ? null :
          <React.Fragment>
            <span className="spacer"></span>
            <span className="column totalizador just-label">MÉDIA</span>
            <span className="column totalizador just-label">TOTAL</span>
          </React.Fragment>
        }
      </React.Fragment>
    )
  }


  renderRows(data) {
    return (
      <React.Fragment>
        {data.map((item, index) =>
          <span key={ index } className={ `data-row data-row-${index}` }>
            { this.renderRow(item, index) }
          </span>
        )}
      </React.Fragment>
    )
  }

  renderRow(data, indexRow) {
    const { extraInfo } = this.props
    const label = data.label
    const total = data.cells.map(_ => _.total).reduce((a,b) => a + b, 0)
    const media = (total / data.cells.length).toFixed(2).replace(`.`,`,`)
    //
    return (
      <div className="row-data">
        <span className="column label pointer" onClick={ this.onClickRow.bind(this, label, indexRow) }>{ label }</span>
        {data.cells.map((value, index) =>
          <React.Fragment key={ index }>
            <span className={ `column cell ` + (value.selected ? `selected` : ``) } onClick={ value.total !== `-` ? this.onClickCell.bind(this, label, index, value, {column: index, row: indexRow}) : null } >
              { value.radius !== undefined && <div className="circle" style={ {width: value.radius, height: value.radius, transitionDelay: value.delay + `ms` } }></div> }
              <span className={ `value value-${value.total}` }>{ value.total }</span>
            </span>
          </React.Fragment>
        )}
        { extraInfo === false ? null : this.renderExtraInfo(media, total) }
      </div>
    )
  }

  renderExtraInfo(media, total) {
    return (
      <React.Fragment>
        <span className="spacer"></span>
        <span className="column totalizador just-label">{ media }</span>
        <span className="column totalizador just-label">{ total }</span>
      </React.Fragment>
    )
  }

}
