import React, { Component } from 'react'

import { ReactComponent as LeftArrowIcon } from '../../../../images/arrow-left-icon.svg';
import { ReactComponent as RightArrowIcon } from '../../../../images/arrow-right-icon.svg';

import './MenuBarVeiculos.scss'

export default class MenuBarVeiculos extends Component {

  state = {
    itemsPerPage: 5,
    currentPage: 0,
    totalPages: 0,
  };

  componentDidMount() {
    this.setState({ totalPages: this.props.totalPages })
  }

  render() {
    const { current, items } = this.props
    const { currentPage, itemsPerPage, totalPages } = this.state
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = items.slice(startIndex, endIndex);
    const btnWidth = 120
    const left = current * btnWidth

    return (
      <div className="MenuBar-container">
        <div className={totalPages > 1 ? 'pagination-container' : 'hidden'}>
          <div className={currentPage === 0 || currentItems.length === 0 ? 'inactive-arrow' : 'active-arrow'} onClick={this.handlePreviousPage}><LeftArrowIcon /></div>
          <div>{currentPage + 1}/{totalPages}</div>
          <div className={totalPages === currentPage + 1 || currentItems.length === 0 ? 'inactive-arrow' : 'active-arrow'} onClick={this.handleNextPage}><RightArrowIcon /></div>
        </div>
        {currentItems.length === 0 ?
          <div className="loading">Carregando...</div> :
          <div className="MenuBar-veiculos">
            <div className="buttons-veiculos">
              {currentItems.map((i, index) => (
                <button key={index} className={index === current ? `selected` : ``} onClick={this.click.bind(this, index)}>
                  {i.plate}
                  <div>{i.type}</div>
                </button>
              ))}
              {this.props.children}
            </div>
            <div className="select-line-veiculos" style={{ transform: `translateX(${left}px)` }}></div>
          </div>
        }
      </div>
    )
  }

  click = (index) => {
    this.props.onChange(index)
  }

  handleNextPage = () => {
    const { currentPage, totalPages } = this.state
    if (currentPage === totalPages - 1)
      return
    this.props.loadNextPage()
    this.setState({ currentPage: currentPage + 1 });
  }

  handlePreviousPage = () => {
    const { currentPage } = this.state
    if (currentPage === 0)
      return
    this.props.previousPage()
    this.setState({ currentPage: currentPage - 1 });
  }
}
