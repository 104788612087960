
import util from '../../../helpers/util'
import * as type from '../fieldTypes'

export const SispenApenadoAsync = {
  created: `2019-04-22`,
  context: `SISPEN.Apenado`,
  role: `CEREBRUM_BUSCA_SISPEN`,
  params: {
    name: {placeholder: `Nome ou Alcunha`},
    date: {placeholder: `Data de Nascimento`, type: type.date, optional: true},
  },
  createRequest: ({name, date}) => {
    const names = util.countNames(name)
    const chars = util.countChars(name)
    if (names === 0 || (names === 1 && chars < 3))
      throw new Error(`É necessário detalhar mais o nome/alcunha da pessoa. Por favor, tente novamente.`)
    //
    const birthDate = date ? util.date2sql(date) : null
    return {path: `/sispen/search`, params: {name, birthDate}}
  },
  createRow: jsonItem => {
    const { id, name, aliases, birthDate, thumb, cpf, rg, rgProvider, regime, prisonUnit, fatherName, motherName } = jsonItem
    const lines = []
    if (aliases && aliases.length) {
      const alcunha = aliases.filter(i => i && i !== `SEM ALCUNHA`).join(`, `).toUpperCase()
      if (alcunha)
        lines.push([{label: `ALCUNHA`, value: alcunha}])
    }
    lines.push([
      {label: `RG`,  value: rg ? (rg + ` ` + rgProvider) : ``},
      {label: `CPF`, value: cpf ? util.formatCPF(cpf) : ``},
    ])
    lines.push([{label: `Regime`, value: regime + ` / ` + prisonUnit},])
    lines.push([{label: `Mãe`, value: motherName},])
    lines.push([{label: `Pai`, value: fatherName},])
    return {
      card: `CardSispenDetalhe`,
      props: {id},
      image: thumb || `avatar.png`,
      title: (birthDate ? (util.date2str(birthDate) + ` - `) : ``) + name,
      lines
    }
  }
}

export const SispenApenadoById = {
  created: `2019-04-22`,
  context: `SISPEN.Apenado`,
  role: `CEREBRUM_BUSCA_SISPEN`,
  params: {
    id: {label: `Código (Apenado)`, placeholder: `00000`, type: type.number},
  },
  parser: ({id}) => {
    return {card: `CardSispenDetalhe`, props: {id}}
  }
}

export const SispenApenadoByRG = {
  created: `2019-04-22`,
  context: `SISPEN.Apenado`,
  role: `CEREBRUM_BUSCA_SISPEN`,
  params: {
    rg: {label: `RG`, placeholder: `00000`, type: type.number},
  },
  parser: ({rg}) => {
    return {card: `CardSispenDetalhe`, props: {rg}}
  }
}

export const SispenApenadoByCPF = {
  created: `2019-04-22`,
  context: `SISPEN.Apenado`,
  role: `CEREBRUM_BUSCA_SISPEN`,
  params: {
    cpf: {label: `CPF`, placeholder: `000.000.000-00`, type: type.cpf},
  },
  parser: ({cpf}) => {
    cpf = cpf.replace(/\W/g, ``)
    return {card: `CardSispenDetalhe`, props: {cpf}}
  }
}
