import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import User from './User'
import Notifications from './Notifications'
import SubHeaderRight from './SubRight';

import './index.scss'

export default class HeaderRight extends Component {

  componentDidMount() {
    setTimeout(_ => {
      ReactDOM.findDOMNode(this).classList.add(`visible`)
    }, 500);
  }
  
  render() {
    return (
      <div className="HeaderRight">
        <SubHeaderRight />
        <User {...this.props}/>
        {!this.props.disableActions && <Notifications />}
      </div>
    )
  }

}
