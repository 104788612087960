import React, { Component } from 'react'
import util from '../../../../helpers/util'
import './index.scss'

const WEEKDAY = [`D`, `S`, `T`, `Q`, `Q`, `S`, `S`]

export class CardTornozeladosDetalhe extends Component {

  state = {
    json: null
  }

  fixJSON = json => {
    json.areas.forEach((i, index) => {
      i.id = index
      i.typeText = i.type === `individual` ? `INDIVIDUAL` : `COLETIVA`
      i.timeRules.forEach(rule => {
        rule.startAtText = util.datetime2str(rule.startAt)
        rule.period   = rule.weekStartTime.slice(0,5) + ` - ` + rule.weekEndTime.slice(0,5)
        rule.typeText = rule.type.replace(`SAO`, `SÃO`)
      })
    })
  }

  createMapLayers = () => {
    const style = {color: `rgba(234,201,150, 0.5)`, stroke: `#fea`, opacity: 0.4}
    const group = {color: `#F4C94C`, title: `Tornozelados`}
    this.state.json.areas.forEach(i => {
      const { type, points, center, radius } = i.area
      if (type === `radial`) {
        const circles = [{center, radius},]
        this.props.publish(`map::layer.add`, {name: i.id, circles, group, ...style})
        i.center = center
      } else if (type === `polygon`) {
        const pts = points.map(p => ([p[1], p[0]]))
        const polygons = {type: `MultiPolygon`, coordinates: [[pts,],]}
        this.props.publish(`map::layer.add`, {name: i.id, polygons, group, ...style})
        i.center = [0,0]
        i.center[0] = pts.map(_ => _[0]).reduce((a,b) => a + b, 0) / pts.length
        i.center[1] = pts.map(_ => _[1]).reduce((a,b) => a + b, 0) / pts.length
      }
    })
  }

  componentDidMount() {
    const { id, cpf } = this.props
    const path = id ? `/code/${id}` : `/cpf/${cpf}`
    this.props.http(`/justice-monitor/monitored${path}`).then(json => {
      const { code } = json
      if (this.props.isDuplicated(json)) {
        return this.props.close()
      }
      this.fixJSON(json)
      this.setState({json}, () => {
        this.createMapLayers()
        this.geocodeFocus()
      })
      this.props.show()
      this.props.setTitle(`TORNOZELADO ` + code)
      setTimeout(() => {
        this.props.openCard(`CardTornozeladosColetas`, {code})
      }, 3000)
    })
  }

  monitoredAreaFocus = item => {
    this.props.publish(`map::zoom`, {point: item.center, zoom: 17})
  }

  geocodeFocus = () => {
    const { code, geocode, address } = this.state.json
    if (geocode && address) {
      const data = {
        code,
        address,
        point: geocode.position,
        source: geocode.source,
      }
      this.props.publish(`map::zoom`, {point: data.point, zoom: 13})
      setTimeout(() => {
        this.props.publish(`map::marker.add`, {name: `geocode`, point: data.point, data, color: `#3dca85`, className: `square animated`, popup: this.createPopup})
      }, 1)
    }
  }

  createPopup = item => {
    const { code, address, source } = item.data
    return {title: `TORNOZELADO ${code}`, text: address, source}
  }

  render() {
    const { json } = this.state
    if (this.props.hidden || !json) {
      return null
    }
    return (
      <div className="CardTornozeladosDetalhe">
        <div className="card-title">
          TORNOZELADO <span>{ json.code }</span>
        </div>
        { this.renderPersonPanel() }
        { this.renderTableAreas() }
      </div>
    )
  }

  renderPersonPanel() {
    const o = this.state.json
    const lines = []
    lines.push([
      {name: `NOME`, value: o.name},
      {name: `CPF`, value: o.cpf ? util.formatCPF(o.cpf) : null},
      {name: `RG`, value: o.rg},
      {name: `MÃE`, value: o.motherName},
      {name: `PAI`, value: o.fatherName},
    ].filter(i => i.value))
    lines.push([
      {name: `ENDEREÇO`, value: o.address},
      {name: `TELEFONE`, value: o.phoneNumber ? util.formatPhone(o.phoneNumber) : null},
    ].filter(i => i.value))
    return (
      <div className="container-lines">
        {lines.filter(items => items.length > 0).map((line, index) => (
          <div className="line" key={ index }>
            {line.map(i => (
              <div className="item" key={ i.name }>
                <div className="label">{ i.name }</div>
                <div className="value">{ i.value }</div>
              </div>
            ))}
          </div>
        ))}
      </div>
    )
  }

  renderTableAreas() {
    const { areas } = this.state.json
    return (
      <div className="table">
        <div className="row header">
          <div className="column area">ÁREA</div>
          <div className="column inicio">DATA INÍCIO</div>
          <div className="column tipo">TIPO</div>
          <div className="column horario">HORÁRIO</div>
          <div className="column semana">SEMANA</div>
          <div className="column local">LOCAL</div>
        </div>
        {areas.map(i => (
          <div className="row data" key={ i.address } onClick={ this.monitoredAreaFocus.bind(this, i) }>
            <div className="column area">{ i.typeText }</div>
            <div className="column inicio">
              {i.timeRules.map((rule, index) => (
                <div key={ index }>{ rule.startAtText }</div>
              ))}
            </div>
            <div className="column tipo">
              {i.timeRules.map((rule, index) => (
                <div key={ index }>{ rule.typeText }</div>
              ))}
            </div>
            <div className="column horario">
              {i.timeRules.map((rule, index) => (
                <div key={ index }>{ rule.period }</div>
              ))}
            </div>
            <div className="column semana">
              {i.timeRules.map((rule, index) => (
                <React.Fragment key={ index }>{ this.renderWeekTimeRules(rule.weekDays) }</React.Fragment>
              ))}
            </div>
            <div className="column local">
              <div className="description">{ i.description }</div>
              <div className="address" title={ i.address }>{ i.address }</div>
            </div>
          </div>
        ))}
      </div>
    )
  }

  renderWeekTimeRules(weekdays) {
    return (
      <div className="weekday-timerules">
        {WEEKDAY.map((day, index) => (
          <div key={ index } className={ `day ` + (weekdays[index] ? `on` : `off`) }>
            { day }
          </div>
        ))}
      </div>
    )
  }

}
