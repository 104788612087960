
import * as type from '../fieldTypes'

export const BiometriaToday = {
  created: `2019-04-22`,
  context: `Biometria`,
  role: `CEREBRUM_HIST_BIOMETRIA`,
  title: `Consultas Biométricas Hoje`,
  description: `Visualizar todas as consultas biométricas confirmadas que foram realizadas no PCA hoje`,
  parser: () => {
    return {card: `CardBiometriaGeoHistory`, props: {type: `today`}}
  }
}

export const BiometriaByDate = {
  created: `2019-04-22`,
  context: `Biometria`,
  role: `CEREBRUM_HIST_BIOMETRIA`,
  params: {
    date: {label: `Data`, placeholder: `dd/mm/aaaa`, type: type.date},
  },
  parser: ({date}) => {
    return {card: `CardBiometriaGeoHistory`, props: {type: `date`, date}}
  }
}

export const BiometriaByRange = {
  created: `2019-04-22`,
  context: `Biometria`,
  role: `CEREBRUM_HIST_BIOMETRIA`,
  params: {
    startDate: {label: `Data de Início*`,  placeholder: `dd/mm/aaaa`, type: type.date},
    endDate:   {label: `Data de Término`, placeholder: `dd/mm/aaaa`, type: type.date, optional: true},
  },
  description: `Intervalo de tempo para filtrar a busca de consultas biométricas`,
  parser: (data) => {
    data.type = data.endDate ? `range` : `since`
    return {card: `CardBiometriaGeoHistory`, props: data}
  }
}
