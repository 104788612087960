import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TextInputMask from 'react-masked-text';
import util from '../../helpers/util';

const COMPLETED_CPF_SIZE = 11;

export class InputCpf extends PureComponent {

  static propTypes = {
    onCpfChange: PropTypes.func
  };

  static defaultProps = {
    onCpfChange: () => {}
  }

  constructor(props) {
    super(props);
    this.input = React.createRef();
  }

  render() {
    const { onCpfChange, ...props } = this.props;
    return (
      <TextInputMask
        {...props}
        ref={this.input}
        kind="cpf"
        onChangeText={this.handleTextChange}/>
    )
  }

  handleTextChange = () => {
    const value = util.formatCPF(this.input.current.getRawValue());
    const invalid = !this.input.current.isValid();
    const completed = value.length === COMPLETED_CPF_SIZE;
    this.props.onCpfChange({ value, invalid, completed });
  };

  focus = () => {
    this.input.current.getElement().focus();
  }

}
