
export const Inep = {
  created: `2019-04-22`,
  context: `INEP`,
  role: `CEREBRUM_INEP_ESCOLAS`,
  title: `INEP - Ensino Básico`,
  description: `Listagem de todas as escolas do Brasil`,
  parser: () => {
    return {card: `CardInepBrasil`, props: {}}
  }
}
