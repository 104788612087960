import React, { Component } from 'react'
import util from '../../../../helpers/util'
import './index.scss'

export class CardCVPPopupDetails extends Component {

  componentDidMount() {
    const { point, cvpType, aaescId } = this.props
    this.props.publish(`map::marker.add`, {point, color: `#f5f7d8`, className: `circle border`})
    this.props.show()
    this.props.setTitle(`CVP ` + cvpType + ` ` + aaescId)
  }

  render() {
    const { aaescId, cityName, cvpType, hour } = this.props
    const date = util.date2str(this.props.date, true)
    let when = ``
    if (date) {
      when = <div>{ hour }<br/>{ date }</div>
    }
    //
    return (
      <div className="CardCVPPopupDetails">
        <div className="card-title">{ cvpType } <span>{ aaescId }</span></div>

        <div className="subtitle type main">{ when }</div>
        <div className="subtitle type">{ cityName }</div>
      </div>
    )
  }

}
