import React, { Component } from "react";
import Card from "./Card";
import Thermometer from "../Thermometer";
import * as Cards from "../Card";
import CardMinify from "./CardMinify";
import { MapLayers } from "./MapLayers";
import { publish, subscribe } from "../../services/EventBus";
import config from "../../config";
import util from "../../helpers/util";

// obtém component/class de um card por nome/string
function getComponentByCardName(name) {
  const component = Cards[name];
  if (component === undefined) {
    if (config.production) return null;
    throw new Error(`Componente não encontrado ~ ${name}`);
  }
  component.cardName = name;
  return component;
}

const MAP_CENTER_POINT = [-38.6, -3.8];
const MAP_CENTER_ZOOM = 10.8;

let Z_INDEX = 1000; // útil para posicionar o último card clicado (Z_INDEX++)

const cardListUnified = [
  "CardCivilHistorico",
  "CardCNHHabilitacao",
  "CardCNHVeiculos",
  "CardEnelHistorico",
  "CardCNHVeiculoById",
  "CardCriminalHistorico",
  "CardSispenVisitante",
  "CardSispenDetalhe",
  // "CardBuscaFonetica"
  // "CardCivilSindionibus",
];

const unifiedCardMenuList = (searchedCard) => [
  "CardCivilHistorico",
  "CardCNHHabilitacao",
  searchedCard && searchedCard === "CardCNHVeiculoById"
    ? "CardCNHVeiculoById"
    : "CardCNHVeiculos",
  "CardEnelHistorico",
  "CardCriminalHistorico",
  "CardSispenVisitante",
  "CardSispenDetalhe",
];

const cardIdentifiers = {
  'CardCriminalHistorico': ["regNumber"],
  'CardSispenVisitante': ["idSispenVisitante"],
  'CardSispenDetalhe': ["idSispenApenado"],
}

export class CardManager extends Component {
  state = {
    session: {},
    hidden: false, // "hide" para todos o "CardManager"/cards
    cards: [],
    unifiedCards: [],
  };

  componentDidMount() {
    subscribe(`cardmanager::set-identifiers`, (topic, cardsData) => {
      const { cardId, params, data } = cardsData;
      if (this.state.cards.find((card) => card.id === cardId)) {
        const hashList = this.createHashList(params);
        const duplicated = this.state.cards.find((card) => {
          if (card.id !== cardId && card.type === "unified") {
            const hashExists = card.hashList.find((i) => {
              return hashList.includes(i);
            });
            return hashExists ? true : false;
          }
          return false;
        });
          if (duplicated) {
            publish(`card::close`, cardId);
            setTimeout(() => publish(`card::autofocus`, duplicated.id), 200);
            const allCards = this.state.cards.map((card) => {
              if (card.id === duplicated.id) {
                const updatedHashList = [...card.hashList]
                hashList.forEach(hash => {
                  if(card.hashList.indexOf(hash) === -1){
                    updatedHashList.push(hash)
                  }
                })
                return {
                  ...card,
                  hashList: updatedHashList,
                  props: { ...card.props, ...params, data: {...card.props.data, ...data}},
                };
              }
              return card;
            });
            this.setState({ cards: allCards });
          } else {
            const allCards = this.state.cards.map((card) => {
              if (card.id === cardId) {
                return {
                  ...card,
                  hashList: hashList,
                  props: { ...card.props, ...params, data: {...card.props.data, ...data}},
                };
              }
              return card;
            });
            this.setState({ cards: allCards });
          }
        }
      }
    );
    subscribe(`auth::login`, (topic, data) => {
      this.setState({ session: data });
    });
    subscribe(`auth::logout`, () => {
      this.setState({ session: {} });
    });
    subscribe(`cardmanager::visibility`, (topic, value) => {
      this.setState({ hidden: !value });
    });
    subscribe(
      `cardmanager::card.create`,
      (
        topic,
        { cardName, card, props, parent, className, inner, parentCardName, newUnifiedCard }
      ) => {
        this.openCard({
          cardName: cardName || card,
          props,
          parent,
          className,
          inner,
          parentCardName,
          newUnifiedCard,
        });
      }
    );
    subscribe(`cardmanager::card.title`, (topic, { cardId, title }) => {
      const cards = this.state.cards.map((i) => {
        return i.id !== cardId ? i : { ...i, title };
      });
      this.setState({ cards });
    });
    setTimeout(() => {
      // publish(`cardmanager::card.create`, {card: `CardCameraLista`, props: {ais: `1`}})
    }, 1000);
  }

  componentDidUpdate(prevProps, prevState) {
    const oldCards = prevState.cards.length;
    const newCards = this.state.cards.length;
    if (newCards === 0 && oldCards > 0) {
      publish(`map::zoom`, { point: MAP_CENTER_POINT, zoom: MAP_CENTER_ZOOM });
    }
  }

  openCard = ({
    cardName,
    props = {},
    parent = null,
    className = null,
    inner = false,
    parentCardName = null,
    newUnifiedCard = false
  }) => {
    const cardNameToId = {
      'CardSispenVisitante': "idSispenVisitante",
      'CardSispenDetalhe': "idSispenApenado",
      'CardCNHHabilitacao': "idDetran",
      'CardCNHVeiculos': "idDetran"

    }

    const identifiersToRenameList = [
      "CardSispenVisitante",
      "CardSispenDetalhe",
      "CardCNHHabilitacao",
      'CardCNHVeiculos',
    ];

    const result = identifiersToRenameList.find((crdName) => cardName === crdName);

    const cardNameToReplaceId = {
      'CardSispenVisitante': "id",
      'CardSispenDetalhe': "id",
      'CardCNHHabilitacao': "id",
      'CardCNHVeiculos': "id"
    }

    if (result){
      if (props[cardNameToReplaceId[cardName]]){
        props[cardNameToId[cardName]] = props[cardNameToReplaceId[cardName]]
        delete props[cardNameToReplaceId[cardName]];
      }
    }

    const MaxCards = 10;
    if (config.development && this.state.cards.length >= MaxCards) {
      return console.warn(`Ops! máximo de ${MaxCards} cards!`);
    }
    const { cards } = this.state;
    const card = this.generateNewCard(
      cardName,
      props,
      parent,
      className,
      inner,
      parentCardName,
      newUnifiedCard
    );
    let duplicated;
    switch (card.type) {
      case "unified":
        duplicated = cards.find((findCard) => {
          if (findCard.id !== card.id && findCard.type !== "common") {
            return findCard.hashList.includes(card.hash);
          }
          return false;
        });
        break;
      default:
        duplicated = cards.find((i) => i.hash === card.hash);
        break;
    }
    if (duplicated && !card.unified) {
      setTimeout(() => publish(`card::autofocus`, duplicated.id), 200);
    } else {
      if (card.orphan) return;
      if (cards.length === 0) {
        this.setState({ cards: [card] });
      } else {
        let newCard;
        const updateCards = cards.map((mapCard) => {
          if (mapCard.id === card.id) {
            return card;
          }
          const cardExists = cards.find((findCard) => findCard.id === card.id);
          if (!cardExists) {
            newCard = card;
          }
          return mapCard;
        });
        newCard
          ? this.setState({ cards: [...updateCards, newCard] })
          : this.setState({ cards: updateCards });
      }
    }
  };

  setHash(cardName, props) {
    return util.basicText2hash(cardName + JSON.stringify(props));
  }

  createHashList(props) {
    const hashList = [];
    const identifiers = [];
    const { cpf, rg, idDetran } = props;
    const setCpf = cpf ? cpf : idDetran;
    const setId = idDetran ? idDetran : cpf;
    const propsList = [{ cpf: setCpf }, { rg }, { idDetran: setId }];
    propsList.forEach((prop) => {
      if (prop.cpf !== undefined) identifiers.push({ cpf: prop.cpf });
      if (prop.idDetran !== undefined) identifiers.push({ idDetran: prop.idDetran });
      if (prop.rg !== undefined) identifiers.push({ rg: prop.rg });
    });
    cardListUnified.forEach((crdName) => {
      identifiers.forEach((i) => {
        if(hashList.indexOf(this.setHash(crdName, i)) === -1){
          hashList.push(this.setHash(crdName, i))
        }
      });
    })
    return hashList;
  }

  getChildrenComponents(cardName, roles) {
    const components = []
    unifiedCardMenuList(cardName).forEach((crdName) => {
      const component = getComponentByCardName(crdName)
      const matchedRoles = component.ROLES.filter(role => roles.includes(role))
      matchedRoles.length > 0 && components.push(getComponentByCardName(crdName))
    })
    return components
  }

  cardConfig(
    cardName,
    props,
    parent,
    className,
    inner,
    parentCardName,
    cardType,
    newUnifiedCard
  ) {
    let origin = props
    if (cardType === "unified" && props.nameSearch && cardIdentifiers[cardName]) {
      origin = {}
      for (let prop in props) {
        if (cardIdentifiers[cardName].includes(prop)) origin[prop] = props[prop]
      }
    }
    //Configura novo card caso não tenha sido gerado a partir de outro componente
    //Ou seja card comum gerado por componente unificado
    //Ou seja card unificado gerado por card comum
    if (
      parentCardName === null ||
      (cardType === "common" && parentCardName !== null) ||
      (cardType === "unified" && ( newUnifiedCard || cardListUnified.indexOf(parentCardName) === -1))
    ) {
      this.nextSeq = (this.nextSeq || 0) + 1;
      const roles = (this.state.session.roles || []).concat([]);
      const card = {
        id: (parent || ``) + `C` + this.nextSeq + `$`, // permite identificar `parent` by id
        cardName,
        component: getComponentByCardName(cardName),
        props,
        title: ``,
        hidden: false,
        zIndex: ++Z_INDEX,
        parent,
        roles: roles,
        hash: this.setHash(cardName, props),
        className,
        type: cardType,
      };
      //Se for card unificado, retorna card com componente filho(item do menu)
      //Se for card comum, retorna somente o card
      const newCard =
        cardType === "unified"
          ? {
            ...card,
            origin: {cardName, props: origin},
            children: this.getChildrenComponents(cardName, roles),
            hashList: this.createHashList(props),
          }
          : card;
      return newCard;
    }
    const innerCardParent = this.state.cards.find((card) => card.id === parent);
    //Para componentes chamados dentro do card unificado ao clicar em opção do menu
    //Atualiza componente de conteúdo do card unificado
    if (inner) {
      return {
        ...innerCardParent,
        unified: true,
        component: getComponentByCardName(cardName),
        props,
      };
    }
    //Caso o card unificado já possua o componente como filho, retorna o card unificado sem alterações
    //Caso contrário, o componente será adicionado à lista de filhos (adiciona nova opção no menu)
    if (innerCardParent) {
      const alreadyExists = innerCardParent.children.find(
        (card) => card.cardName === cardName
      );
      if (alreadyExists) {
        return { ...innerCardParent };
      }
      return {
        ...innerCardParent,
        unified: true,
        children: [
          ...innerCardParent.children,
          { cardName, props, parent, className },
        ],
        hashList: [...innerCardParent.hashList, this.setHash(cardName, props)],
      };
    }
    //Caso o card unificado seja fechado durante o carregamento de componentes filhos
    return { orphan: true };
  }

  generateNewCard = (
    cardName,
    props,
    parent,
    className,
    inner,
    parentCardName,
    newUnifiedCard
  ) => {
    if (cardListUnified.indexOf(cardName) !== -1) {
      return this.cardConfig(
        cardName,
        props,
        parent,
        className,
        inner,
        parentCardName,
        "unified",
        newUnifiedCard,
      );
    }
    return this.cardConfig(
      cardName,
      props,
      parent,
      className,
      inner,
      parentCardName,
      "common"
    );
  };

  closeCards = (prefixId = ``) => {
    const cards = this.state.cards.filter(({ id }) => {
      if (prefixId && id.indexOf(prefixId) !== 0) return true;
      publish(`card::removed`, id);
      publish(`map::marker.removeByCardId`, id);
      publish(`map::layer.removeByCardId`, id);
      return false;
    });
    this.setState({ cards });
  };

  closeCard = (cardId, error = null) => {
    // se "fechamento sem erro" .. cards filhos tbm devem ser removidos
    if (!error) {
      return this.closeCards(cardId);
    }
    // fechamento individual
    publish(`card::removed`, cardId);
    publish(`map::marker.removeByCardId`, cardId);
    publish(`map::layer.removeByCardId`, cardId);
    const cards = this.state.cards.filter((i) => i.id !== cardId);
    this.setState({ cards });
  };

  cardTopZIndex = (cardId, next) => {
    const cards = this.state.cards.map((i) => {
      return i.id !== cardId ? i : { ...i, zIndex: ++Z_INDEX };
    });
    this.setState({ cards }, next);
  };

  cardVisibility = (cardId, visible = true) => {
    this.cardTopZIndex(cardId, () => {
      const cards = this.state.cards.map((i) => {
        return i.id !== cardId ? i : { ...i, hidden: !visible };
      });
      this.setState({ cards });
    });
  };

  render() {
    return (
      <>
        <MapLayers />
        {this.renderCardMinify()}
        {/* { this.renderIndicators() } */}
        {this.renderCards()}
      </>
    );
  }

  renderCardMinify() {
    const { cards } = this.state;
    return (
      <CardMinify
        cards={cards}
        setVisibility={this.cardVisibility}
        closeCards={this.closeCards}
      />
    );
  }

  renderIndicators() {
    const roles = this.state.session.roles || [];
    const hidden = this.state.cards.length > 0;
    const isThermom = roles.includes(
      `CEREBRUM_INDICADORES_TERMOMETRO_VIEWER`
    );
    return isThermom ? <Thermometer hidden={hidden} /> : null;
  }

  renderCards() {
    const { cards, session, hidden } = this.state;

    return (
      <div className="CardManager">
        {
            cards.map((card) => {
              return (
                <Card
                  session={session}
                  origin={card.origin}
                  hash={card.hash}
                  children={card.children ? card.children : []}
                  cardName={card.cardName}
                  key={card.id}
                  className={card.className}
                  id={card.id}
                  component={card.component}
                  hidden={card.hidden === true ? true : hidden}
                  roles={card.roles}
                  params={card.props}
                  zIndex={card.zIndex}
                  type={card.type}
                  //
                  onClose={this.closeCard}
                  //
                  setVisibility={this.cardVisibility}
                  setTopZIndex={this.cardTopZIndex}
                //
                />
              );
            })
          // )
        }
      </div>
    );
  }
}
