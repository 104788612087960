import React, { Component } from 'react'
import TimelineRoutes from './TimelineRoutes'
import SwitcherOnOff from '../../../UI/SwitcherOnOff'
import { RealtimeMarker } from './RealtimeMarker.js'
import WS from '../../../../services/websocket'
import config from '../../../../config'
import { App } from '../../../../App'
import util from '../../../../helpers/util'
import './index.scss'


export class CardSateliteEquipe extends Component {

  ONLINE_MAX_SECONDS = 60 // tempo limite para considerar marker "online"

  ROUTE_COLOR = [
    `#d4c012`,
    `#ec7f29`,
  ]

  state = {
    settings: { // opções de visualização via switcher on/off
      satelite: true,
      gps: false,
      rota: false,
      flex: false,
      online: false,
    },
    satelliteName: null,
    teamName: null,
    shift: null,
    date: null,
    trackable: null,
    components: [],
    routes: [],
    pointsByComponent: {},
    animateFrame: -1, // intepolação de 0 a 100%
    intervals: [], // intervalos combinados de todas as rotas
    currentTime: null, // tracking realtime move
    timelineRoutes: [], // lista de routeId sobre itens ativos/visíveis
    timelineRouteNow: null // id da rota ativa neste momento - quanto em realtime
  }

  extractFakeDateTime = date => {
    const d = new Date(`2000-01-01 ` + util.date2time(date))
    if (d < this.state.intervals[0].begin) {
      d.setDate(d.getDate() + 1)
    }
    return d
  }

  componentDidMount() {
    this.props.http(`/satellites/${this.props.id}`).then(json => {
      const { satelliteName, teamName, shift } = json
      const components = this.extractComponents(json)
      const routes     = this.extractRoutes(json)
      const date       = new Date(json.performanceDate)
      const intervals  = this.extractIntervals(json)
      const trackable  = json.flagOnline
      const timelineRoutes = routes.map(_ => _.id)
      const canShowPolice = this.props.roles.includes(`CEREBRUM_PM_PERFIL`)
      //
      this.createMapLayers(json, components)
      this.setState({satelliteName, teamName, shift, date, trackable, components, routes, intervals, timelineRoutes, canShowPolice}, () => {
        this.props.show()
        this.props.setTitle(`SATÉLITE ` + this.props.id + ` EM ` + util.date2str(date))
        this.realtimeMarker = new RealtimeMarker(this, components)
        this.websocket = new WS(this.createURLWebSocket(`/online-objects/` + this.props.id), {card: this})
          .on(`message`, this.wsMessage)
          .on(`connected`, this.wsConnected)
          .on(`reconnecting`, this.wsReconnecting)
      })
    })
  }

  componentWillUnmount() {
    if (this.realtimeMarker) {
      this.realtimeMarker.clear()
    }
    clearInterval(this.timerAnimatedLines)
    clearInterval(this.timerSyncWSMarkers)
    if (this.websocket) {
      this.websocket.disconnect()
    }
  }

  resetTimers() {
    const { online } = this.state.settings
    clearInterval(this.timerAnimatedLines)
    clearInterval(this.timerSyncWSMarkers)
    if (online) {
      this.timerSyncWSMarkers = setInterval(this.timerNextEmptyFrame, 1000)
    } else {
      this.timerAnimatedLines = setInterval(this.timerDrawAnimatedPath, 100)
    }
  }

  createURLWebSocket = (path=``) => {
    const params = {...App.headers}
    const host = config.gatewayAppWebsocket
    return host + path + `?` + Object.entries(params).map(i => i.join(`=`)).join(`&`)
  }

  wsMessage = json => {
    this.realtimeMarker.update(json)
    this.updateNextRouteByFrameNow(json)
  }

  wsConnected = () => {
    const settings = {...this.state.settings, onlineFeedback: null}
    this.setState({settings})
  }

  wsReconnecting = () => {
    const settings = {...this.state.settings, onlineFeedback: `Falha ao conectar! Tentando conexão...`}
    this.setState({settings})
    this.realtimeMarker.reset()
  }

  extractTimeUpdates = (wsJson=[]) => {
    const updates = {}
    wsJson.forEach(i => {
      const [ id, newTime ] = [ i.componentId, new Date(i.collectDate) ]
      const time = updates[id]
      if (time === undefined || time < newTime) {
        updates[id] = newTime
      }
    })
    return updates
  }

  updateNextRouteByFrameNow = (items=[]) => {
    const now = this.getTimeNow()
    const oldRouteId = this.state.timelineRouteNow
    const currentRouteId = this.getRouteIdByTime(now)
    const routeChanged = oldRouteId !== currentRouteId
    this.setState({currentTime: now, timelineRouteNow: currentRouteId}, () => {
      this.updateWSComponents(items)
      if (routeChanged) {
        this.syncMapRender()
      }
    })
  }

  timerNextEmptyFrame = () => {
    const now = Date.now()
    const updated = this.wsMessageUpdated || 0
    const seconds = (now - updated) / 1000
    if (seconds < 1) {
      return
    }
    this.updateWSComponents()
    this.wsMessageUpdated = now // simula uma leitura de message ws
  }

  updateWSComponents = (items=[]) => {
    const updates = this.extractTimeUpdates(items)
    const components = this.state.components.map(i => {
      i.updated = updates[i.id] || i.updated
      i.offline = this.ONLINE_MAX_SECONDS < parseInt((Date.now() - i.updated) / 1000, 10)
      return i
    })
    this.realtimeMarker.updateOfflineStatus(components)
    this.setState({components})
    this.wsMessageUpdated = Date.now()
  }

  getRouteIdByTime = time => {
    let routeId = -1
    this.state.intervals.forEach(i => {
      if (i.begin <= time && i.end > time) {
        routeId = i.route
      }
    })
    return routeId
  }

  createMapLayers(json, components) {
    const group = {color: `#3cc0ac`, title: `Satélites`}
    this.props.publish(`map::layer.add`, {name: `area`, polygons: json.satelliteGeom, group})
    json.routes.forEach((i, index) => {
      const color = this.ROUTE_COLOR[index]
      this.props.publish(`map::layer.add`, {name: `route${i.route}flex`, group, polygons: i.routeGeomFlex, color: `#fff`, opacity: 0.1, hidden: true})
      this.props.publish(`map::layer.add`, {name: `route${i.route}line`, group, lines: i.routeGeom.coordinates, color, opacity: 0.6, width: 6, hidden: true})
      this.props.publish(`map::layer.add`, {name: `route${i.route}stop`, group, points: i.points.map(_ => ({point: _.coordinates})), color, opacity: 1, hidden: true})
    })
    components.forEach(i => {
      this.props.publish(`map::layer.add`, {name: `police${i.id}animate`,   group, lines: [], color: `#fff`, width: 3, gradient: true})
      this.props.publish(`map::layer.add`, {name: `police${i.id}trace`,     group, lines: [], color: `#fff`, opacity: 0.2, width: 1})
      this.props.publish(`map::layer.add`, {name: `police${i.id}truePoint`, group, points: [], color: i.color, popup: this.createPopupComponentTime})
      this.props.publish(`map::layer.add`, {name: `police${i.id}snapPoint`, group, points: [], color: i.color, scale: 0.5})
    })
  }

  extractComponents(json) {
    const base = 35
    const range = 45
    return json.components.map((c, index) => {
      const colorLightness = base + parseInt(range * (index / json.components.length), 10)
      return {
        index: index,
        id: c.componentId,
        name: c.name,
        code: c.registrationId,
        color: `hsl(170,83%,${colorLightness}%)`,
        hidden: false,
        offline: true,
        updated: new Date(2010,10,10).getTime() // offline
      }
    })
  }

  extractRoutes(json) {
    return json.routes.map((r, index) => {
      return {
        index: index,
        id: r.route,
        name: r.routeType,
        color: this.ROUTE_COLOR[index],
        times: this.extractIntervals(json).filter(_ => _.route === r.route),
        hidden: false,
      }
    })
  }

  /**
   * extrair intervalos de todas as rotas desconsiderando `date`
   * extração com correção de horas para "dia seguinte" (`23:00` -> `01:00`)
   */
  extractIntervals(json) {
    let firstTime = null
    const intervals = []
    json.routes.forEach(route => {
      route.times.forEach(time => {
        if (firstTime === null) {
          firstTime = new Date(`2000-01-01 ` + time.startTime)
        }
        let times = {}
        for (const typeTime in time) {
          times[typeTime] = new Date(`2000-01-01 ` + time[typeTime])
          if (times[typeTime] < firstTime)
            times[typeTime].setDate(times[typeTime].getDate() + 1) // ajuste "dia seguinte"
        }
        intervals.push({route: route.route, begin: times.startTime, end: times.endTime})
      })
    })
    return intervals.sort((a,b) => a.begin - b.begin)
  }

  render() {
    const { satelliteName, teamName, shift, date } = this.state
    if (!satelliteName)
      return null
    return (
      <div className="CardSateliteEquipe">
        <div className="card-title">
          { satelliteName } <span>{ util.date2str(date, true) }</span>
        </div>
        <div className="header-line">
          <span>{ teamName }</span>
          <span>{ shift }</span>
        </div>
        <div className="satelite">
          { this.renderSettings() }
          { this.renderComponents() }
          { this.renderTimelineRoutes() }
        </div>
      </div>
    )
  }

  renderSettings() {
    const { settings, trackable } = this.state
    const { onlineFeedback } = settings
    const canViewRealtime = this.props.roles.includes(`CEREBRUM_AOM_PCA_ONLINE`)
    const blockedRealtime = trackable && !canViewRealtime
    return (
      <div className="settings">
        <SwitcherOnOff defaultValue={ settings.satelite } onChange={ this.changedGeometriaSatelite } label="Geometria do Satéllite" />
        <SwitcherOnOff defaultValue={ settings.gps      } onChange={ this.changedColetasGPS        } label="Coletas GPS" />
        <SwitcherOnOff defaultValue={ settings.rota     } onChange={ this.changedRotaGeom          } label="Rota Planejada" />
        <SwitcherOnOff defaultValue={ settings.flex     } onChange={ this.changedRotaGeomFlex      } label="Rota Flex" />
        <SwitcherOnOff defaultValue={ settings.online   } onChange={ this.changedRealtime          } label="Acompanhamento em tempo real"
          disabled={ !trackable || blockedRealtime }
        />
        { blockedRealtime ? <div className="tip">Você não possui permissão para visualização em tempo real</div> : null }
        { onlineFeedback  ? <div className="tip">{ onlineFeedback }</div> : null }
      </div>
    )
  }

  calcFlagTimeClass = (time, now) => {
    const seconds = parseInt((now - time) / 1000, 10)
    if (seconds > this.ONLINE_MAX_SECONDS) {
      return `gray`
    }
    if (seconds < 20) return `green`
    if (seconds < 40) return `yellow`
    return `orange`
  }

  calcTimeElapsed = (timeA, timeB) => {
    const seconds = parseInt((timeB - timeA) / 1000, 10)
    if (seconds > this.ONLINE_MAX_SECONDS) {
      return `--:--`
    }
    timeA = new Date(timeA)
    timeB = new Date(timeB)
    let m = timeB.getMinutes() - timeA.getMinutes()
    let s = timeB.getSeconds() - timeA.getSeconds()
    if (s < 0) {
      s += 60
      m--
    }
    if (m < 0) {
      m += 60
    }
    return `+` + (`0` + m).slice(-2) + `:` + (`0` + s).slice(-2)
  }

  renderComponents() {
    const { settings, canShowPolice } = this.state
    const now = Date.now()
    return (
      <div className="container componentes">
        <h1>Policiais</h1>
        <div className="table-container">
          <table>
            <tbody>
              {this.state.components.map((i, index) => (
                <tr key={ i.id } className={ i.hidden ? `closed` : `` }>
                  <td className="view">
                    <button className={ `eye ${i.hidden ? 'closed':''}` } onClick={ this.clickComponentEyeHandler.bind(this, index) }></button>
                  </td>
                  <td className={ `flag ` + this.calcFlagTimeClass(i.updated, now) }>
                    <div className="status" style={{background: i.color}}></div>
                    <div className={ `time ` + (settings.online ? `` : `hidden`) }>{ this.calcTimeElapsed(i.updated, now) }</div>
                  </td>
                  <td className="code">{ i.code }</td>
                  <td className="name">{ i.name }</td>
                  { canShowPolice ? <td><button className="link" onClick={ this.clickComponentDetailsHandler.bind(this, i.code) }></button></td> : null }
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  clickComponentDetailsHandler = (policeCode) => {
    this.props.openCard(`CardPoliciaMilitar`, {id: policeCode})
  }

  renderTimelineRoutes() {
    const msg = this.state.timelineLoading || this.state.timelineError ? (this.state.timelineError || `CARREGANDO...`) : null
    return (
      <div className={ `timeline-container` + (msg ? ` has-message` : ``) }>
        <TimelineRoutes
          routes={ this.state.routes }
          currentTime={ this.state.currentTime }
          onTimeChanged={ this.onClickTimelineChanged }
          onRouteViewChanged={ this.routeViewHandler }
        />
        { msg ? <div className="message">{ msg }</div> : null }
      </div>
    )
  }

  createPopupComponentTime = item => {
    return {
      title: util.date2time(item.collectDate, true)
    }
  }

  clickComponentEyeHandler = index => {
    const components = this.state.components.map(c => {
      return c.index === index ? {...c, hidden: !c.hidden} : c
    })
    this.realtimeMarker.setVisibility(components[index].id, !components[index].hidden)
    this.setState({components}, this.syncMapRender)
  }

  routeViewHandler = (routeId, hidden) => {
    const routes = this.state.routes.map(r => {
      return r.id === routeId ? {...r, hidden} : r
    })
    this.setState({routes}, this.syncMapRender)
  }

  onClickTimelineChanged = (period) => {
    this.setState({timelineRoutes: period.routes, timelineLoading: true, timelineError: null})
    const query = {
      startTime: util.date2time(period.begin, true),
      endTime: util.date2time(period.end, true)
    }
    this.clearMapLayers()
    this.props.http(`/satellites/teams/${this.props.id}`, query).then(jsonList => {
      // normalizando...
      jsonList.forEach(i => {
        i.collects.forEach(_ => _.point = _.point || _.position)
        i.mapMatch.forEach(_ => _.point = _.point || _.position)
      })
      const pointsByComponent = {}
      this.state.components.forEach(i => {
        const jsonComponent = jsonList.find(j => j.componentId === i.id) || {collects: [], mapMatch: []}
        const points = jsonComponent.mapMatch
        const trace = [points.map(_ => _.point),]
        pointsByComponent[i.id] = trace
        let n15 = parseInt(trace[0].length * 0.15, 10)
        const lastPoint = trace[0][trace[0].length - 1]
        while (n15--) { // add mais pontos para a posição final (para a animação permanecer)
          pointsByComponent[i.id][0].push(lastPoint)
        }
        this.props.publish(`map::layer.update`, {name: `police${i.id}truePoint`, points: jsonComponent.collects})
        this.props.publish(`map::layer.update`, {name: `police${i.id}snapPoint`, points})
        this.props.publish(`map::layer.update`, {name: `police${i.id}trace`, lines: trace})
      })
      this.setState({timelineLoading: false, pointsByComponent, animateFrame: 0,}, this.syncMapRender)
      this.resetTimers()
    }).catch(() => {
      this.setState({timelineError: `Falha ao carregar dados`})
      setTimeout(() => {
        this.setState({timelineLoading: false, timelineError: null})
      }, 5000)
    })
  }

  clearMapLayers = () => {
    this.state.routes.forEach(i => {
      this.props.publish(`map::layer.update`, {name: `route${i.id}flex`, hidden: true})
      this.props.publish(`map::layer.update`, {name: `route${i.id}line`, hidden: true})
      this.props.publish(`map::layer.update`, {name: `route${i.id}stop`, hidden: true})
    })
    this.state.components.forEach(i => {
      this.props.publish(`map::layer.clear`, {name: `police${i.id}truePoint`})
      this.props.publish(`map::layer.clear`, {name: `police${i.id}snapPoint`})
      this.props.publish(`map::layer.clear`, {name: `police${i.id}trace`})
      this.props.publish(`map::layer.clear`, {name: `police${i.id}animate`})
    })
  }

  changedGeometriaSatelite = value => {
    const settings = {...this.state.settings, satelite: value}
    this.setState({settings}, this.syncMapRender)
  }

  changedColetasGPS = value => {
    const settings = {...this.state.settings, gps: value}
    this.setState({settings}, this.syncMapRender)
  }

  changedRotaGeom = value => {
    const settings = {...this.state.settings, rota: value}
    this.setState({settings}, this.syncMapRender)
  }

  changedRotaGeomFlex = value => {
    const settings = {...this.state.settings, flex: value}
    this.setState({settings}, this.syncMapRender)
  }

  changedRealtime = value => {
    const settings = {...this.state.settings, online: value, onlineFeedback: null}
    const currentTime = value ? this.getTimeNow() : null
    this.setState({settings, currentTime, timelineRouteNow: null}, () => {
      this.resetTimers()
      if (currentTime) {
        const { components } = this.state
        components.forEach(i => {
          i.updated = new Date(2010,10,10).getTime()
          i.offline = true
        })
        this.setState({components}, this.updateNextRouteByFrameNow)
      } else {
        this.syncMapRender()
      }
    })
    //
    this.realtimeMarker.reset()
    if (value) {
      this.websocket.connect()
    } else {
      this.websocket.disconnect()
    }
  }

  /**
   * retorna um `time` desconsiderando o dia (porém mantendo ordem nos intervalos)
   */
  getTimeNow = () => {
    const date = new Date()
    date.setFullYear(2000)
    date.setMonth(0)
    date.setDate(1)
    if (date.getHours() < 4) {
      date.setDate(2)
    } else if (date < this.state.intervals[0].begin) {
      date.setTime(this.state.intervals[this.state.intervals.length - 1].end.getTime())
    }
    return date
  }

  syncMapRender = () => {
    const { settings, routes, components, timelineRoutes, timelineRouteNow } = this.state
    //
    this.props.publish(`map::layer.update`, {name: `area`, hidden: !settings.satelite})
    routes.forEach(i => {
      let enabled = timelineRoutes.includes(i.id) && !i.hidden
      if (timelineRouteNow) {
        enabled = timelineRouteNow === i.id
      }
      this.props.publish(`map::layer.update`, {name: `route${i.id}flex`, hidden: !settings.flex || !enabled})
      this.props.publish(`map::layer.update`, {name: `route${i.id}line`, hidden: !settings.rota || !enabled})
      this.props.publish(`map::layer.update`, {name: `route${i.id}stop`, hidden: !settings.rota || !enabled})
    })
    components.forEach(i => {
      const hidden = i.hidden || settings.online
      this.props.publish(`map::layer.update`, {name: `police${i.id}truePoint`, hidden: i.hidden || !settings.gps || settings.online})
      this.props.publish(`map::layer.update`, {name: `police${i.id}snapPoint`, hidden})
      this.props.publish(`map::layer.update`, {name: `police${i.id}trace`, hidden})
      this.props.publish(`map::layer.update`, {name: `police${i.id}animate`, hidden})
    })
  }

  timerDrawAnimatedPath = () => {
    const { pointsByComponent, timelineLoading, animateFrame } = this.state
    if (timelineLoading || animateFrame < 0) {
      return
    }
    const begin = animateFrame
    const end = begin - 15 < 0 ? 0 : (begin - 15)
    this.setState({animateFrame: animateFrame + 5 > 100 ? 0 : (animateFrame + 5)})
    //
    Object.entries(pointsByComponent).forEach(tuple => {
      const [ id, lines ] = tuple
      const t0 = parseInt(lines[0].length * (end   / 100), 10)
      const t1 = parseInt(lines[0].length * (begin / 100), 10)
      const line = lines[0].slice(t0, t1)
      this.props.publish(`map::layer.update`, {name: `police${id}animate`, lines: [line,]})
    })
  }

}
