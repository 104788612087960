import { message as AntMessage } from 'antd'
import React, { Component } from 'react'
import LoginScreen from "../../screens/LoginScreen"
import { ServiceApiLogin } from '../../services/api/login'
import { publish } from '../../services/EventBus'

// import { LoginScreen } from 'spi-oauth2'
// import config from '../../config'

export class Login extends Component {

  render() {
    // const { appName, appVersion, appToken, oauth2Url } = config
    // const options = {
    //   host: oauth2Url,
    //   app: appToken
    // }
    return (
      // <LoginScreen
      //   app={ appName }
      //   version={ appVersion }
      //   options={ options }
      //   onLogin={ this.handleLogin }
      //   onRecover={ this.handleRecover }
      // />
      <LoginScreen onLogin={ this.handleLogin }/>
    );
  }

  handleLogin = (data) => {
    ServiceApiLogin.createSession(data).then(() => {
      publish(`auth::login.start`, data)
    })
  }

  handleRecover = () => {
    const message = `Instruções para alterar senha foram enviadas para seu email`
    const duration = 10
    AntMessage.info(message, duration)
    publish(`auth::logout`, {http: false})
  }

}
