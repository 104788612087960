import React, { Component } from 'react'
import SwitcherOnOff from '../UI/SwitcherOnOff'
import { publish, subscribe } from '../../services/EventBus'
import { createChartSvg, number2str } from './TooltipChart'
import './Tooltip.scss'
import './TooltipChart.scss'

export default class Tooltip extends Component {

  state = {
    hidden: true,
    mouseover: false,
    indicator: null,
    indicatorSelected: null,
    acumulado: true,
    currentYear: new Date().getFullYear(),
    rootNode: null,
    summary: [],
    meta: [], // guarda eixo y de cada week (tipo linha)
    bars: [], // guarda eixo y de cada week (tipo barra)
  }

  constructor(props) {
    super(props)
    this.ref = React.createRef()
    this.state.rootNode = {data: props.rootIndicator}
  }

  componentDidMount() {
    subscribe(`thermometer::indicator.mouseenter`, (topic, indicator) => {
      clearTimeout(this.timer)
      this.setState({indicator, hidden: false}, this.recalc)
    })
    subscribe(`thermometer::hidden`, () => {
      this.setState({hidden: true})
    })
    subscribe(`thermometer::visible`, () => {
      this.setState({hidden: false})
    })
    subscribe(`thermometer::indicator.back2root`, () => {
      const indicator = this.state.indicatorSelected || {data: this.props.rootIndicator}
      this.setState({indicator}, this.recalc)
    })
    subscribe(`thermometer::indicator.selected`, (topic, data) => {
      const indicator = data ? {data: {data}} : {data: this.props.rootIndicator}
      this.setState({indicator, indicatorSelected: indicator}, this.recalc)
    })
    subscribe(`thermometer::indicator.reset`, () => {
      const indicator = {data: this.props.rootIndicator}
      this.setState({indicator, indicatorSelected: null}, this.recalc)
    })
  }

  mouseEnter = () => {
    this.setState({mouseover: true}, this.show)
    publish(`thermometer::mouse.enter`)
  }

  mouseLeave = () => {
    publish(`thermometer::mouse.leave`)
  }

  minimizerHandler = () => {
    publish(`thermometer::minimize`)
  }

  show = () => {
    clearTimeout(this.timer)
    this.setState({hidden: false})
  }

  hide = () => {
    if (this.state.mouseover)
      return
    this.setState({hidden: true})
  }

  hideTooltip = () => {
    this.hide()
  }

  changeAcumulado = (value) => {
    if (this.state.summary.length === 0)
      return this.recalc()
    this.setState({acumulado: value})
  }

  recalc = () => {
    const { weeklyCount, dailyAverage } = this.state.indicator.data.data
    const weekAverage = dailyAverage * 7
    const summary = this.generateSummary()
    const lista = [false, true]
    lista.forEach(acumulado => {
      const meta = []
      const bars = []
      weeklyCount.forEach((i, index) => {
        meta.push((acumulado && index > 0 ? meta[index - 1] : 0) + weekAverage)
        bars.push((acumulado && index > 0 ? bars[index - 1] : 0) + i.count)
      })
      const dataset = bars.map((i, index) => ([index+1, meta[index], bars[index]]))
      const element = this.ref.current.querySelector(`.chart-container.` + (acumulado ? `acumulado` : `absoluto`))
      createChartSvg(element, dataset)
    })
    this.setState({summary})
  }

  generateSummary = () => {
    const period = this.props.periodSelected.label
    const { weeklyCount, dailyAverage, polarity } = this.state.indicator.data.data
    const weekMax  = weeklyCount.map(i => i.count).reduce((a,b) => Math.max(a,b), 0)
    return [
      {label: `Polaridade`,   value: polarity ? `Positiva` : `Negativa`},
      {label: `Meta Semanal`, value: number2str(dailyAverage * 7)},
      {label: `Máx. Semanal`, value: number2str(weekMax)},
      {label: `Período Comparado`, value: period},
    ]
  }

  render() {
    const { hidden } = this.state
    const clss = `Tooltip` + (hidden ? ` hidden` : ``)
    return (
      <div ref={ this.ref } className={ clss } onMouseEnter={ this.mouseEnter } onMouseLeave={ this.mouseLeave }>
        { this.renderMinimizer() }
        { this.renderHeader() }
        { this.renderSummary() }
        { this.renderBody() }
      </div>
    )
  }

  renderMinimizer() {
    return <div className="minimizer" onClick={ this.minimizerHandler } />
  }

  renderHeader() {
    const data   = ((this.state.indicator || {data: {}}).data || {}).data || {}
    const value  = number2str(data.value || 0)
    const status = data.status || `-`
    const name   = data.name   || `-`
    return (
      <>
        <div className="columns">
          <div className="valor">{ name }</div>
          <div className="status">{ value }<span>{ status }</span></div>
        </div>
        <div className="nome">
          <div className="settings">
            <span className={ this.state.acumulado ? `` : `enabled` }>ABSOLUTO</span>
            <SwitcherOnOff defaultValue={ true } onChange={ this.changeAcumulado } label="ACUMULADO" />
          </div>
        </div>
      </>
    )
  }

  renderSummary() {
    const { summary } = this.state
    return (
      <div className="group-info">
        {summary.map((i, key) => (
          <div className="column" key={ key }>
            <label>{ i.label }</label><div>{ i.value }</div>
          </div>
        ))}
      </div>
    )
  }

  renderBody() {
    const { acumulado, currentYear } = this.state
    return (
      <>
        <div className={ `charts` + (acumulado ? ` left` : ` right`) }>
          <div className="chart-container absoluto" />
          <div className="chart-container acumulado" />
        </div>
        <div className="footer">SEMANAS DE { currentYear } </div>
      </>
    )
  }

}

