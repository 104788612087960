import React, { Component } from 'react'
import './index.scss'

export class CardSispenObservacoes extends Component {

  state = {
    situacaoAtual: [],
    situacaoGeral: [],
    pretensoesFuturas: [],
    observacoes: [],
    open: false,
    closing: false
  }

  componentDidMount() {
    const { id, legalComments, futureClaims, additionalObservations } = this.props
    const situacaoAtual = []
    const situacaoGeral = []
    const pretensoesFuturas = []
    const observacoes = []
    if (legalComments && legalComments.currentHistoricalCriminal) {
      this.text2lines(situacaoAtual, legalComments.currentHistoricalCriminal)
    }
    if (legalComments && legalComments.criminalRecord) {
      this.text2lines(situacaoGeral, legalComments.criminalRecord)
    }
    if (futureClaims) {
      this.text2lines(pretensoesFuturas, futureClaims)
    }
    if (additionalObservations && additionalObservations.additionalObservation) {
      this.text2lines(observacoes, additionalObservations.additionalObservation)
    }
    this.setState({situacaoAtual, situacaoGeral, pretensoesFuturas, observacoes, id})
    this.props.setTitle(`SISPEN OBSERVAÇÕES ${id}`)
    this.props.show()
  }

  text2lines = (targetList, text) => {
    const lines = (text || ``).replace(/\\\\/g, `\\`).replace(/\\"/g, `"`).trim().split(`\n`).filter(i => i)
    if (lines.length)
      targetList.push(lines)
  }

  accordionClick = () => {
    this.setState({closing: true})
    setTimeout(() => {
      this.setState({open: !this.state.open, closing: false})
    }, 100)
  }

  render() {
    if (this.props.hidden) {
      return null
    }
    const { situacaoAtual, situacaoGeral, pretensoesFuturas, observacoes } = this.state
    return (
      <div className="CardSispenObservacoes">
        <button className="accordion" onClick={this.accordionClick}>
          <div className="accordion_title">SISPEN
            <span className='accordion_base'>OBSERVAÇÕES JURÍDICAS</span>
            <span className={`dropdown_icon${!this.state.open ? ` closed` : ``}`}></span>
          </div>
        </button>
        <div className={`accordion_content${this.state.closing ? ` closing`: ``} ${this.state.open ? ` open` : ` closed`}`}>
          {
            this.state.open
            ? <>
                { this.renderLines(``, situacaoAtual) }
                { this.renderLines(`ANTECEDENTES CRIMINAIS`, situacaoGeral) }
                { this.renderLines(`PRETENSÕES FUTURAS`, pretensoesFuturas) }
                { this.renderLines(`OBSERVAÇÕES COMPLEMENTARES`, observacoes) }
              </>
            : null
          }
        </div>
      </div>
    )
  }

  renderLines(title, groupLines) {
    if (groupLines.length === 0) {
      return null
    }
    return (
      <div className="container-text">
        { title && <div className="caption">{ title }</div> }
        <div className="text">
          {groupLines.map((lines, gkey) => (
            <div key={ gkey } className="group-lines">
              {lines.map((line, lkey) => (
                <div className="value" key={ lkey }>{ line }</div>
              ))}
            </div>
          ))}
        </div>
      </div>
    )
  }

}
