import React, { useContext } from "react";
import { QuestionsContext } from "../../../contexts/questions";

import "./index.scss";

const HelpSections = ({ hashtag }) => {
  const qsts = useContext(QuestionsContext);

  return (
    <div className="sections-container">
      {qsts.map((qst) => {
        return (
          <section key={qst.id} id={`${hashtag}${qst.id}`}>
            <h1>{qst.title}</h1>
            {qst.content}
          </section>
        );
      })}
    </div>
  );
};

export default HelpSections;
