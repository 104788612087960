import React from "react";
import Route from "./Route";
import Router from "./Router";

import MainScreen from "../screens/MainScreen";
import HelpScreen from "../screens/HelpScreen";

const routes = [
  { path: "/cerebrum", exact: true, component: MainScreen },
  { path: "/cerebrum/ajuda", component: HelpScreen },
];

const Routes = () => {
  return (
    <Router>
      {routes.map(route => {
        return (
          <Route
            key={route.path}
            path={route.path}
            exact={!!route.exact}
            component={route.component}
          />
        );
      })}
    </Router>
  );
};

export default Routes;
