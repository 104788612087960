import React, { Component } from 'react'
import util from '../../../../helpers/util'
import './index.scss'

export class CardCVPLista extends Component {

  state = {
    summary: [],
    weekdays: [],
    rows: [],
    points: [],
    timeSelected: null,
    typeSelected: null,
    weekdaySelected: null
  }

  extractServiceQueryParams = () => {
    if (this.props.searchType === `City`) {
      const {type, city, date, month, year, startDate, endDate} = this.props
      let query = {city}
      if (type === `date`) {
        query = {city, date: util.date2sql(date)}
      } else if (type === `year`) {
        query = {city, year}
      } else if (type === `month-year`) {
        query = {city, month, year}
      } else if (type === `startDate`) {
        query = {city, startDate: util.date2sql(startDate)}
      } else if (type === `rangeDate`) {
        query = {city, startDate: util.date2sql(startDate), endDate: util.date2sql(endDate)}
      }
      return {url: `/cvp/search/city`, query}
    }
    if (this.props.searchType === `AIS`) {
      const {ais, type, date, month, year, startDate, endDate} = this.props
      let query = {ais}
      if (type === `date`) {
        query = {ais, date: util.date2sql(date)}
      } else if (type === `year`) {
        query = {ais, year}
      } else if (type === `month-year`) {
        query = {ais, month, year}
      } else if (type === `startDate`) {
        query = {ais, startDate: util.date2sql(startDate)}
      } else if (type === `rangeDate`) {
        query = {ais, startDate: util.date2sql(startDate), endDate: util.date2sql(endDate)}
      }
      return {url: `/cvp/search/ais`, query}
    }
  }

  /**
   * download de CVP baseado pelo conteúde de `props`
   * podendo ser listagem por AIS ou City
   */
  download() {
    const { url, query } = this.extractServiceQueryParams()
    return this.props.http.indicators(url, query).then(data => {
      if (data.name === undefined || data.geometry === undefined) {
        data = {type: `city`, points: data}
      } else {
        data = {type: `ais`, name: data.name, geometry: data.geometry, points: data.cvp}
      }
      this.normalizePoints(data.points)
      return data
    })
  }

  normalizePoints = points => {
    points.forEach(p => {
      p.point = p.position
      p.type = p.cvpType
      p.time = parseInt(parseInt(p.hour.split(`:`)[0], 10) / 6, 10)
      p.weekday = new Date(p.date + `T00:00:00`).getDay()
    })
  }

  componentDidMount() {
    this.download().then(data => {
      if (data.geometry) {
        const polygons = data.geometry
        const group = {color: `#abb3a8`, title: `AIS ${this.props.ais}`}
        this.props.publish(`map::layer.add`, {polygons, group})
      }
      const { points } = data
      const cityName = (points[0] || {}).cityName
      const summary  = this.extractSummary(points)
      const weekdays = this.extractWeekdays(points)
      const rows     = this.extractRows(points)
      const group    = {color: `#f5f7d8`, title: `CVP`}
      //
      this.setState({cityName, summary, weekdays, rows, points, totalPoints: points.length})
      this.props.publish(`map::layer.add`, {name: `cvp`, points, group, color: `#f5f7d8`, popup: this.createPopup, click: this.clickPopup})
      this.props.publish(`map::layer.zoom`, {name: `cvp`})
      this.props.show()
      this.props.setTitle(this.getTitlePrefix() + ` ` + this.getTimeTitle())
    })
  }

  createPopup = item => {
    return { title: item.cvpType, text: `` + item.aaescId }
  }

  clickPopup = item => {
    this.props.openCard(`CardCVPPopupDetails`, item)
  }

  extractSummary = points => {
    const hash = {}
    points.forEach(p => {
      hash[p.time] = (hash[p.time] || 0) + 1
    })
    const summary = [
      {label: `TOTAL CRIMES`, value: points.length, current: points.length},
      {label: `MANHÃ`, time: 1},
      {label: `TARDE`, time: 2},
      {label: `NOITE`, time: 3},
      {label: `MADRUGADA`, time: 0},
    ]
    summary.slice(1).forEach(i => {
      const v = ((hash[i.time] || 0) / points.length * 100).toFixed(1).replace(`.`,`,`) + `%`
      i.value = v
      i.current = v
    })
    return summary
  }

  extractWeekdays = points => {
    const hash = {}
    points.forEach(p => {
      hash[p.weekday] = (hash[p.weekday] || 0) + 1
    })
    const weekdays = [`Domingo`, `Segunda`, `Terça`, `Quarta`, `Quinta`, `Sexta`, `Sábado`].map((i, index) => {
      const total = hash[index] || 0
      return {id: index, label: i, value: total, total, percent: (total / points.length * 100).toFixed(1).replace(`.`,`,`) + `%`}
    })
    return weekdays
  }

  extractRows = points => {
    const rows = {}
    points.forEach(p => {
      rows[p.type] = (rows[p.type] || 0) + 1
    })
    return Object.entries(rows).map(i => {
      return {label: i[0], value: i[1], type: i[0], current: i[1], percent: (i[1] / points.length * 100).toFixed(1) + `%`}
    }).sort((a,b) => b.value - a.value)
  }

  getTimeTitle = () => {
    const {type, date, month, year, startDate, endDate} = this.props
    if (type === `startDate`)
      return ` desde ` + util.date2str(startDate)
    if (type === `date`)
      return ` dia ` + util.date2str(date)
    if (type === `month-year`)
      return ` em ` + util.getMonthName(month) + ` de ` + year
    if (type === `rangeDate`)
      return ` em ` + util.date2str(startDate) + ` - ` + util.date2str(endDate)
    return ` ano de ` + (year || new Date().getFullYear())
  }

  getTitlePrefix = () => {
    const { searchType } = this.props
    let prefix = ``
    if (searchType === `AIS`) {
      prefix = `CVP AIS ` + this.props.ais
    } else if (searchType === `City`) {
      const cityName = this.state.cityName || this.props.city
      prefix = `CVP cidade de ` + cityName
    }
    return prefix
  }

  renderTitle = () => {
    return (
      <div className="card-title category">
        { this.getTitlePrefix() } <span>{ this.getTimeTitle() }</span>
      </div>
    )
  }

  selectColumnHandler = label => {
    const next = label === this.state.timeSelected ? null : label
    this.setState({timeSelected: next}, this.sync)
  }

  selectWeekdayHandler = index => {
    const weekdaySelected = this.state.weekdaySelected === index ? null : index
    this.setState({weekdaySelected}, this.sync)
  }

  selectRowHandler = label => {
    const next = label === this.state.typeSelected ? null : label
    this.setState({typeSelected: next}, this.sync)
  }

  sync = () => {
    const { typeSelected, timeSelected, weekdaySelected, points } = this.state
    const currentPoints = this.filterPoints(   points, typeSelected, timeSelected, weekdaySelected)
    const summary   = this.syncSummary( currentPoints, typeSelected, timeSelected, weekdaySelected)
    const weekdays  = this.syncWeekdays(currentPoints, typeSelected, timeSelected, weekdaySelected)
    const rows      = this.syncRows(    currentPoints, typeSelected, timeSelected, weekdaySelected)
    this.setState({summary, weekdays, rows})
    this.props.publish(`map::layer.update`, {name: `cvp`, points: currentPoints})
    this.props.publish(`map::layer.zoom`, {name: `cvp`})
  }

  syncSummary = (points, type, time, weekday) => {
    const { summary } = this.state
    summary.slice(1).forEach(i => {
      if (time === null) {
        i.selected = false
        i.current = i.value
      } else {
        i.selected = i.time === time;
        i.current = i.selected ? i.value : `-`
      }
      if (type !== null) {
        const n = points.filter(p => p.time === i.time).length
        i.current = (n / points.length * 100).toFixed(1).replace(`.`,`,`) + `%`
      }
      if (points.length === 0 && i.current !== `-`) {
        i.current = `0%`
      }
      if (!i.selected && time !== null && type !== null) {
        i.current = `-`
      }
    })
    if (type !== null || time !== null || weekday !== null) {
      summary[0].current = points.length + ` / ` + summary[0].value
    } else {
      summary[0].current = summary[0].value
    }
    return summary
  }

  syncWeekdays = (points, type, time, weekday) => {
    const { weekdays, totalPoints } = this.state
    const total = type ? points.filter(p => p.type === type).length : totalPoints
    weekdays.forEach(i => {
      const pts  = points.filter(p => p.weekday === i.id)
      const perc = (total === 0 ? `0` : (pts.length / total * 100).toFixed(1).replace(`.`,`,`)) + `%`
      i.value    = pts.length
      i.total    = pts.length
      i.selected = i.id === weekday
      i.percent  = weekday === null ? perc : (i.selected ? perc : `-`)
    })
    return weekdays
  }

  syncRows = (points, type, time, weekday) => {
    const rows = this.state.rows
    const hash = {}
    rows.forEach(i => {
      hash[i.type] = 0
    })
    points.forEach(p => hash[p.type]++)
    rows.forEach(i => {
      if (type === null) {
        i.selected = false
        i.current = hash[i.type]
      } else {
        i.selected = i.type === type;
        i.current = i.selected ? hash[i.type] : `-`
      }
      if (i.current === `-`) {
        i.percent = `0%`
      } else {
        i.percent = ((i.current / points.length * 100).toFixed(1) + `%`).replace(/\.0%$/, `%`)
      }
    })
    return rows
  }

  filterPoints = (points, type, time, weekday) => {
    return points.filter(p => {
      if (type !== null && p.type !== type)
        return false
      if (time !== null && p.time !== time)
        return false
      if (weekday !== null && p.weekday !== weekday)
        return false
      return true
    })
  }

  render() {
    return (
      <div className="CardCVPLista">
        { this.renderTitle() }
        { this.renderContent() }
      </div>
    )
  }

  renderContent() {
    const { summary, rows, rowSelected } = this.state
    if (rows.length === 0) {
      return <p className="message">NENHUM REGISTRO ENCONTRADO</p>
    }
    return (
      <React.Fragment>
        { this.renderSummary(summary) }
        { this.renderWeekdays() }
        { this.renderLineChart(rows, rowSelected) }
      </React.Fragment>
    )
  }

  renderSummary(items) {
    return (
      <div className="row-values">
        {items.map((i, index) => (
          <div key={ index } className={ `col` + (i.selected ? ` selected` : ``) } onClick={ this.selectColumnHandler.bind(this, i.time) }>
            <div className="label">{ i.label }</div>
            <div className="value">{ i.current }</div>
          </div>
        ))}
      </div>
    )
  }

  renderWeekdays() {
    const { weekdays } = this.state
    return (
      <div className="weekdays">
        {weekdays.map((i, index) => (
          <div key={ index } className={ `column` + (i.selected ? ` selected` : ``) } onClick={ this.selectWeekdayHandler.bind(this, index) }>
            <div className="label">{ i.label }</div>
            <div className="value">{ i.percent }</div>
          </div>
        ))}
      </div>
    )
  }

  renderLineChart(rows) {
    return (
      <div className="line-chart">
        {rows.map((i, index) => (
          <div key={ index } className={ `row` + (i.selected ? ` selected` : ``) + (i.current === 0 ? ` empty` : ``) } onClick={ this.selectRowHandler.bind(this, i.label) }>
            <div className="value">{ i.current }</div>
            <div className="bar">
              <div className="life" style={ {width: i.percent} }></div>
              <div className="percent">{ i.percent }</div>
            </div>
            <div className="label">{ i.label }</div>
          </div>
        ))}
      </div>
    )
  }

}
