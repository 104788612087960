import React, { Component } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async'
import './Input.scss';

export default class Input extends Component {
	state = {
		id: `n` + Date.now(),
	};

	constructor(props) {
		super(props);
		this.inputRef = React.createRef();
	}

	componentDidMount() {
		this.adjustWidthChars();
	}

	changeHandler = (e) => {
		const { type, label } = this.props;
		const value = type ? type.fixText(e.target.value) : e.target.value;
		this.props.onChange({ label, value });
		setTimeout(this.adjustWidthChars, 1);
	};

	selectChangeHandler = (selected, actionMeta) => {
		const { label, type } = this.props;

		if(type.isMulti){
			const { action, option } = actionMeta;
			if(action === 'select-option' && option.value === 'all') selected = type.options
		}

		this.props.onChange({ label, value: selected || [] });
		setTimeout(this.adjustWidthChars, 1);
	};

	getSelectOption = () => {
		const { type, value } = this.props;
		try {
			let options = [...type.options];
			if(value.length !== type.options.length) options.push({ label: 'Todos', value: 'all' })
			return options
		} catch (error) {
			return []
		}
	}

	adjustWidthChars = () => {
		const input = this.inputRef.current;
		if (input) {
			const size = Math.max(input.value.length, input.placeholder.length);
			input.style.width = parseInt((Math.max(size, 7) + 3) * 1.1, 10) + `ch`;
		}
	};

	clickHandler = () => {
		if (this.inputRef.current) this.inputRef.current.focus();
	};

	focusHandler = () => {
		this.setState({ focus: true });
	};

	blurHandler = () => {
		this.setState({ focus: false });
	};

	keyDownHandler = (e) => {
		if (e.keyCode === 13 && this.props.onEnterKey) {
			this.props.onEnterKey();
		}
	};

	renderSelect() {
		const { type, value, placeholder } = this.props;

		const styles = {
			control: (styles) => ({
				...styles,
				backgroundColor: 'transparent',
				outline: '0',
				boxShadow: 'none',
				border: '0',
				lineHeight: '1.2',
				fontSize: '12px',
				padding: '0',
				margin: '-9px auto',
			}),
			input: (styles) => ({
				...styles,
				color: 'rgba(255,255,255, 0.6)',
				backgroundColor: 'transparent',
				border: '0',
				padding: '0',
			}),
			option: (styles, {data}) => ({
				...styles,
				backgroundColor: '#3B4046',
				fontWeight: data.value === 'all' ? '900' : undefined
			}),
			menuList: (styles) => ({ ...styles, backgroundColor: '#3B4046' }),
			singleValue: (styles) => ({
				...styles,
				color: 'rgba(255, 255, 255, 0.6)',
			}),
			multiValue: (styles) => ({
				...styles,
				color: 'rgba(255,255,255, 0.6)',
				backgroundColor: '#3B4046',
				border: '1px solid rgba(255,255,255, 0.3)',
				':hover': { borderColor: '#DAA709', },
			}),
			multiValueLabel: (styles) => ({
				...styles,
				color: 'rgba(255,255,255, 0.6)',
			}),
			multiValueRemove: (styles) => ({
				...styles,
				':hover': { backgroundColor: '#DAA709', color:'#3B4046', cursor: 'pointer'},
			}),
			clearIndicator: (styles) => ({
				...styles,
				':hover': { color: 'rgba(255,255,255, 0.6)', cursor: 'pointer'},
			}),
		};

		if (type.loadOptions)
			return <AsyncSelect
				cacheOptions defaultOptions
				loadOptions={type.loadOptions}
				isMulti={type.isMulti}
				styles={styles}
				placeholder={placeholder || 'Selecionar'}
				onKeyDown={this.keyDownHandler}
				onFocus={this.focusHandler}
				onBlur={this.blurHandler}
				onChange={this.selectChangeHandler}
			/>

		return (
			<Select
				options={type.isMulti ? this.getSelectOption() : type.options}
				isMulti={type.isMulti}
				value={value}
				styles={styles}
				placeholder={placeholder || 'Selecionar'}
				onKeyDown={this.keyDownHandler}
				onFocus={this.focusHandler}
				onBlur={this.blurHandler}
				onChange={this.selectChangeHandler}
			/>
		);
	}

	render() {
		const { label, value, placeholder, optional, type } = this.props;
		const { id, focus } = this.state;
		return (
			<div
				className={`SearchPanel-BodyOptions-Input` + (focus ? ` focus` : ``)}
				onClick={this.clickHandler}
			>
				<div className='label'>
					{label}
					{optional ? <span>[OPCIONAL]</span> : ``}
				</div>

				{type && (type.options || type.loadOptions) ? (
					this.renderSelect()
				) : (
					<input
						type='text'
						ref={this.inputRef}
						id={id}
						name={id}
						className='input'
						value={value}
						placeholder={placeholder}
						onKeyDown={this.keyDownHandler}
						onChange={this.changeHandler}
						onFocus={this.focusHandler}
						onBlur={this.blurHandler}
						autoComplete='disabled'
						autoCorrect='disabled'
					/>
				)}
			</div>
		);
	}
}
