
import util from '../../../../helpers/util'

const MAX_HISTORY_DAYS = 5
const MAX_REQUEST_ERRORS = 1

export default {
  download: downloaderStart
}

function downloaderStart(httpRequest, params, options) {
  const { startDateTime, endDateTime } = params
  let closed = false
  let filters = []
  try {
    filters = datetime2requestFilters(startDateTime, endDateTime)
  } catch(e) {
    return options.error(e)
  }
  if (options.initialized) {
    options.initialized()
  }
  let busy = false
  let requests = filters.map((_, index) => ({id: index, started: false, done: false, errors: 0, params: filters[index]}))
  let timer = setInterval(() => {
    const pending = requests.filter(i => !i.done).length
    if (pending === 0 || closed) {
      options.done()
      return clearInterval(timer)
    }
    const filter = requests.find(i => !i.started && !i.done)
    if (filter && !busy) {
      busy = true
      filter.started = true
      httpRequest(filter.params).then(json => {
        busy = false
        filter.done = true
        const date = util.date2str(util.str2date(filter.params.date))
        let time = `--:--`
        if (json.length > 0) {
          const dates = json.map(i => new Date(i.date))
          const min = dates.reduce((a,b) => Math.min(a,b), dates[0])
          const max = dates.reduce((a,b) => Math.max(a,b), dates[0])
          time = util.date2time(new Date(min)) + ` - ` + util.date2time(new Date(max))
        }
        options.data({id: filter.id, date, time, json})
      }, _ => {
        busy = false
        filter.started = false
        if (++filter.errors >= MAX_REQUEST_ERRORS) {
          clearInterval(timer)
          options.error(new Error(`Falha ao buscar histórico de coletas`))
        }
      })
    }
  }, 1000)
  return {
    cancel: () => {
      clearTimeout(timer)
      requests = []
      closed = true
    }
  }
}

/**
 * dado um intervalo de dias (start/end datetime)
 * é retornada uma lista de filtros - um para cada dia do intervalo
 */
function datetime2requestFilters(startDateTime, endDateTime) {
  const filter = []
  let start = new Date(startDateTime)
  for (let i = 0; i < MAX_HISTORY_DAYS + 1; i++) {
    let end = new Date(start)
    end.setHours(23)
    end.setMinutes(59)
    end.setSeconds(59)
    end.setMilliseconds(0)
    if (end > endDateTime) {
      end.setHours(endDateTime.getHours())
      end.setMinutes(endDateTime.getMinutes())
      end.setSeconds(endDateTime.getSeconds())
    }
    const queryString = {
      date: util.date2sql(start),
      startTime: util.date2time(start, true),
      endTime: util.date2time(end, true),
    }
    filter.push(queryString)
    //
    start.setDate(start.getDate() + 1)
    start.setHours(0)
    start.setMinutes(0)
    start.setSeconds(0)
    start.setMilliseconds(0)
    if (start > endDateTime)
      break
  }
  if (filter.length > MAX_HISTORY_DAYS) {
    throw new Error(`Histórico de coletas é limitado a ${MAX_HISTORY_DAYS} dias`)
  }
  return filter
}
