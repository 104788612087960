import React, { PureComponent } from 'react'
import { Root } from './screens/Root'
import config from './config'


export class App extends PureComponent {

  static LOCAL_STORAGE_TOKEN_PROPERTY = `token-${config.appToken}`

  static setAuthorizationHeader = (token) => {
    if (token) {
      token = `Bearer ${token}`
      localStorage.setItem(App.LOCAL_STORAGE_TOKEN_PROPERTY, token)
    } else {
      token = null
      localStorage.removeItem(App.LOCAL_STORAGE_TOKEN_PROPERTY)
    }
    App.headers['Authorization'] = token
  }

  static getToken = () => {
    return localStorage.getItem(App.LOCAL_STORAGE_TOKEN_PROPERTY) || null
  }

  static headers = {
    'Accept-Client': config.appToken,
    'Client-Name': config.appName,
    'Client-Version': config.appVersion,
    'Authorization': App.getToken(),
  }

  render() {
    return <Root />
  }
}

//REFRESH TOKEN

// static LOCAL_STORAGE_REFRESH_TOKEN_PROPERTY = `refresh_token-${config.appToken}`

// static getRefreshToken = () => {
//   return localStorage.getItem(App.LOCAL_STORAGE_REFRESH_TOKEN_PROPERTY) || null
// }

// static setAuthorizationHeader = (token, refresh_token) => {
//   if (token && refresh_token) {
//     token = `Bearer ${token}`
//     localStorage.setItem(App.LOCAL_STORAGE_TOKEN_PROPERTY, token)
//     localStorage.setItem(App.LOCAL_STORAGE_REFRESH_TOKEN_PROPERTY, refresh_token)
//   } else {
//     token = null
//     refresh_token = null
//     localStorage.removeItem(App.LOCAL_STORAGE_TOKEN_PROPERTY)
//     localStorage.removeItem(App.LOCAL_STORAGE_REFRESH_TOKEN_PROPERTY)
//   }
//   App.headers['Authorization'] = token
// }
