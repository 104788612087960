import React, { useEffect, useState } from 'react'
import { publish, subscribe } from '../../services/EventBus'
import './MapLayers.scss'

let   updated = false
const layerByTitle = {}
const layerVisibility = {}
const layerColor = {}

let timer = 0

function addLayer(layer) {
  updated = true
  const { title, color } = layer
  layerColor[title] = color
  if (layerByTitle[title] === undefined) {
    layerByTitle[title] = [layer,]
  } else {
    layerByTitle[title].push(layer)
  }
}

function removeLayer(layerId) {
  updated = true
  const title = getTitleByLayerId(layerId)
  const layers = (layerByTitle[title] || []).filter(i => i.id !== layerId)
  layerByTitle[title] = layers
  if (layers.length === 0) {
    delete layerVisibility[title]
    delete layerByTitle[title]
  }
}

function removeLayerByCardId(cardId) {
  updated = true
  Object.entries(layerByTitle).forEach(([title, layers]) => {
    const list = layers.filter(i => i.cardId !== cardId)
    if (list.length) {
      layerByTitle[title] = list
    } else {
      delete layerVisibility[title]
      delete layerByTitle[title]
    }
  })

}

function getTitleByLayerId(layerId) {
  let title = ``
  Object.values(layerByTitle).forEach(layers => {
    const layer = layers[0]
    if (layerId === layer.id && layer.title)
      title = layer.title
  })
  return title
}

function toggleVisibility(layerId) {
  updated = true
  const titleSelected = getTitleByLayerId(layerId)
  const layers = layerByTitle[titleSelected] || []
  const hidden = !layerVisibility[titleSelected]
  layerVisibility[titleSelected] = hidden
  layers.forEach(layer => {
    layer.hidden = hidden
    publish(`map::layer.visibilityByTitle`, {...layer})
  })
}

function extractLayers() {
  const list = []
  Object.entries(layerByTitle).forEach(([title, layers]) => {
    const layer = layers[0]
    if (layer) {
      const i = {...layer, hidden: layerVisibility[title]}
      list.push(i)
    }
  })
  return list.sort((a,b) => a.title < b.title ? -1 : +1)
}

export function MapLayers() {
  const [ ready, setReady ] = useState(false)
  const [ hidden, setHidden ] = useState(true)
  const [ layers, setLayers ] = useState([])
  //
  useEffect(() => {
    setInterval(() => {
      if (updated) {
        setLayers(extractLayers())
        updated = false
      }
    }, 1000)
    //
    subscribe(`map::ready`, () => {
      setTimeout(() => setReady(true), 1100)
    })
    subscribe(`map::layer.add`, (topic, data) => {
      const { id, cardId, type, name, group } = data
      if (group) {
        const { color, title } = group
        const hidden = layerVisibility[title] === false ? true : data.hidden
        addLayer({ id, cardId, type, name, title, color, hidden})
      }
    })
    subscribe(`map::layer.removeByCardId`, (topic, data) => {
      removeLayerByCardId(data.cardId)
    })
    subscribe(`map::layer.removed`, (topic, data) => {
      removeLayer(data.id)
    })
  }, [])
  //
  const maximize = () => {
    setHidden(true)
  }
  const clickHandler = layer => {
    toggleVisibility(layer.id)
    setLayers(extractLayers())
  }
  const mouseEnterHandler = () => {
    clearTimeout(timer)
    if (layers.length)
      setHidden(false)
  }
  const mouseLeaveHandler = () => {
    clearTimeout(timer)
    if (layers.length) {
      timer = setTimeout(maximize, 500)
    }
  }
  //
  const renderLayer = layer => {
    const { title, color } = layer
    return (
      <div key={ layer.id } className={ `line layer` + (layer.hidden ? ` closed` : ``) } onClick={ clickHandler.bind(null, layer) }>
        <div className="name">{ title }</div>
        <div className="circle-container">
          <div className={ `circle ` + (hidden ? `hidden` : `visible`) } style={{background: color}} />
        </div>
      </div>
    )
  }
  //
  return (
    <div className={ `MapLayers` + (ready ? ` ready` : ``) + (hidden ? ` hidden` : ``) } onMouseEnter={ mouseEnterHandler } onMouseLeave={ mouseLeaveHandler }>
      { layers.map(renderLayer) }
    </div>
  )
}
