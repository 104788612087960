import React, { Component } from 'react'
import { subscribe } from '../../../services/EventBus'
import Search from './Search'
import './index.scss'

export default class HeaderCenter extends Component {

  state = {
    hidden: true,
    slow: true
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({hidden: false})
    }, 500)
    setTimeout(() => {
      this.setState({slow: false})
    }, 5000)
    subscribe(`search::open`, () => {
      this.setState({minimized: true})
    })
    subscribe(`search::close`, () => {
      this.setState({minimized: false})
    })
  }
  
  render() {
    const { minimized, hidden, slow } = this.state
    let clss = ``
    if (hidden)    clss += ` hidden`
    if (minimized) clss += ` minimized`
    if (slow)      clss += ` slow`
    return (
      <div className={ `HeaderCenter` + clss }>
        <Search />
      </div>
    );
  }

}
