
const config = {}

/**
 * transforma definições de `.env` para `config`/js
 * ex.:
 * REACT_APP_TOKEN=12345  -->>  {token: 12345}
 * REACT_APP_GATEWAY_LOGIN=http://...  -->>  {gatewayLogin: `http://...`}
 */
Object.entries(process.env).forEach(i => {
  const [ key, value ] = i
  const name = key
    .replace(/^REACT_APP_/, ``)
    .toLowerCase()
    .split(`_`)
    .map((word, index) => index ? (word[0].toUpperCase() + word.slice(1)) : word)
    .join(``)
  //
  config[name] = value
})

// shortcuts
config.production = config.nodeEnv === `production`
config.development = config.nodeEnv === `development`

// access via IP improvements
// flag to indicate if user is accessing throught IP address
config.isThroughtIp = window.location.hostname.match(/[^0-9.:]/) === null
if (config.isThroughtIp && config.production) {
  // update API urls to use page origin as origin (with IP)
  const gatewayAppUrl = new URL( config.gatewayApp )
  const gatewayIndicatorsUrl = new URL( config.gatewayIndicators )
  config.gatewayApp = window.location.origin + gatewayAppUrl.pathname
  config.gatewayIndicators = window.location.origin + gatewayIndicatorsUrl.pathname
}

console.log(`=== App Configuration:`)
console.log(config)
console.log(``)

export default config
