import React, { Component } from 'react';
import './index.scss'

export class CardFUNCEMEDetalhe extends Component {

  state = {
    groups: [],
    currentImage: 0,
  }

  componentDidMount() {
    const p = this.props
    const { point, color } = p
    const groups = [
      {label: `REGIÃO`, value: p.regiao.nome},
      {label: `COTA METROS`, value: p.cotaMetros.replace(`.`,`,`) + ` em ` + p.dataColeta},
      {label: `VOLUME ATUAL`, value: p.volumeAtual + ` (` + p.volumeAtualPerc.replace(`.`,`,`) + `%)`},
      {label: `VAZÃO`, value: p.vazaoLitrosPorSegundo + ` l/s`},
    ]
    this.props.publish(`map::marker.add`, {point, className: `circle animated`, color})
    this.setState({groups}, this.props.show)
    this.props.setTitle(`AÇUDE ${this.props.codigo} - ${this.props.nome}`)
  }

  imageClickHandler = index => {
    this.setState({currentImage: index})
  }

  render() {
    if (this.props.hidden) {
      return null
    }
    return (
      <div className="CardFUNCEMEDetalhe">
        { this.renderTitle() }
        { this.renderPhotos() }
        { this.renderContent() }
      </div>
    )
  }

  renderTitle() {
    const { codigo, nome, municipio, capacidadeHectometrosCubicos } = this.props
    const capacidade = capacidadeHectometrosCubicos.replace(`.`,`,`) + `hm³`
    return <div className="title">AÇUDE { codigo } - { nome } <span>{ municipio } / { capacidade }</span></div>
  }

  renderContent() {
    const { groups } = this.state
    return (
      <div className="info">
        {groups.map((i, index) => (
          <div className="group" key={ index }>
            <div className="label">{ i.label }</div>
            <div className="value">{ i.value }</div>
          </div>
        ))}
      </div>
    )
  }

  renderPhotos() {
    const { galeria } = this.props
    const { currentImage } = this.state
    const x = (currentImage * 272.48) + (currentImage * 10) + (currentImage > 0 ? -80 : 0)
    return (
      <div className="galeria">
        <div className="imgs" style={{transform: `translate(${-x}px,0)`}}>
          {galeria.map((i, index) => (
            <div className="image" key={ index } onClick={ this.imageClickHandler.bind(this, index) }>
              <img src={ i } alt="" />
            </div>
          ))}
        </div>
      </div>
    )
  }

}
