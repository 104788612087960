import React, { PureComponent } from 'react'
import './index.scss'

export default class ChartRowsTotal extends PureComponent {
  
  render() {
    const json = this.props.data
    let rows = json
    if (json.length === undefined) {
      rows = Object.keys(json).sort((a,b) => json[b] - json[a]).map(i => {
        return {label: i, total: json[i]}
      })
    }
    //
    const items = rows.map(item =>
      <div className="item"  key={ item.label } >
        <div className="label">{ item.label }</div>
        <div className="total">{ item.total }</div>
      </div>
    )
    return (
      <div className="ChartRowsTotal">
        { items }
      </div>
    )
  }
}
