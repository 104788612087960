import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import './Parceiros.scss'

export default class HeaderLeftParceiros extends Component {

  componentDidMount() {
    setTimeout(_ => {
      ReactDOM.findDOMNode(this).classList.add(`visible`)
    }, 1500);
  }
  
  render() {
    const logos = [
      {id: `logo1`, link: `https://www.ceara.gov.br`},
      {id: `logo2`, link: `http://www.sspds.ce.gov.br`},
    ]
    return (
      <div className={`HeaderLeftParceiros ${this.props.className}`}>
        {logos.map(i => (
          <a key={ i.id } href={ i.link } className={ `logo ${i.id} `} target="_blank" rel="noopener noreferrer"><b></b></a>
        ))}
      </div>
    );
  }

}
