import React, { useState, useContext } from 'react'
import Link from '../../routes/Link';
import { QuestionsContext } from '../../contexts/questions';

import { ReactComponent as ExternalLinkIcon } from '../../images/external-link-icon.svg';
import { ReactComponent as LeftArrowIcon } from '../../images/arrow-left-icon.svg';
import { ReactComponent as RightArrowIcon } from '../../images/arrow-right-icon.svg';


import './index.scss'

export function HelpPanel(props) {
  const questions = useContext(QuestionsContext);

  const [itemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const pages = Math.ceil(questions.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = questions.slice(startIndex, endIndex);

  function handleNextPage() {
    if (currentPage === pages - 1) {
      return
    } else
      setCurrentPage(currentPage + 1);
  }

  function handlePreviousPage() {
    if (currentPage === 0) {
      return
    } else
      setCurrentPage(currentPage - 1);
  }

  return (
    <div className="help-container">
      <div className="HelpPanelBackground" onClick={() => props.handleClosePanel(true)}></div>
      <div className="HelpPanel">
        <button className="btn-close" onClick={() => props.handleClosePanel(true)}>
          {/* <span>ESC</span> */}
        </button>
        <div className="container-content">

          <div className="container-title">
            <div className="main-title">PRINCIPAIS DÚVIDAS</div>
            <div className="yellow-detail" />
          </div>

          <div className="questions-container">
            {
              currentItems.map(item => {
                return (
                  <div key={item.id} className="questions-item">
                    {item.title}
                    <Link to={`/cerebrum/ajuda#pergunta${item.id}`} target="_blank">
                      <ExternalLinkIcon />
                    </Link>
                  </div>
                )
              })
            }
          </div>

          <div className="pagination-container">
            <div>Veja outras páginas</div>
            <div onClick={handlePreviousPage}><LeftArrowIcon /></div>
            <div className="pages">{currentPage+1}/{pages}</div>
            <div className="right-arrow" onClick={handleNextPage}><RightArrowIcon /></div>
          </div>

        </div>
      </div>
    </div>
  )
}
