import React, { Component } from "react";
import srcImageAvatar from "../../../images/avatar.png";
import "./index.scss";

export default class BodyAsync extends Component {
  clickHandler = (row) => {
    this.props.onClick(row);
  };

  render() {
    const { rows, selectedIndex } = this.props;
    if (rows === undefined) {
      return null;
    }

    return (
      <div className="SearchPanel-BodyAsync">
        {rows.map((i, index) => (
          <div
            key={index}
            className="row"
            onClick={
              this.props.fromCriminalCard || this.props.fromSispenVisitorCard
                ? () => {
                  document.getElementById(`row-${index}`).click()
                }
                : this.clickHandler.bind(this, i)
            }
          >
            <div
              className={
                `image` +
                (!i.image ? ` no-image` : ``) +
                (i.image === `avatar.png` ? ` default` : ``)
              }
            >
              <img src={this.createImageSrc(i.image)} alt="" />
            </div>
            <div className={`info` + (!i.image ? ` no-image` : ``)}>
              {this.props.fromCriminalCard && (
                <div className="checkbox-container">
                  <input
                    defaultChecked={i.props.isChecked}
                    type="checkbox"
                    id={`row-${index}`}
                    onChange={() => {
                      this.props.handleSelectedRow(i);
                    }}
                  />
                  <label htmlFor={`row-${index}`}>VINCULAR REGISTRO</label>
                </div>
              )}
              {this.props.fromSispenVisitorCard && (
                <div className="radioButton-container">
                  <input
                    checked={index === selectedIndex}
                    type="radio"
                    id={`row-${index}`}
                    onClick={() => {
                      this.props.handleSelectedRow(i);
                    }}
                  />
                  <label htmlFor={`row-${index}`}>VINCULAR REGISTRO</label>
                </div>
              )}
              <div className="item-title">
                {this.props.fromCriminalCard
                  ? `${i.props.regNumber} - ${i.title}`
                  : i.title}
                {this.props.fromCriminalCard &&
                i.props.infoValidation["regName"] ? (
                  <span className="check-icon" />
                ) : null}
              </div>
              <div className="item-lines">{this.renderRowInfoLines(i)}</div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  createLine = (label, value, from, row) => {
    const { infoValidation } = row.props;
    return (
      <div key={label} className="line-item">
        <div className="label">{label}</div>
        <div className="value">{value}</div>{" "}
        {from && infoValidation[label] ? (
          <span className="check-icon" />
        ) : null}
      </div>
    );
  };

  renderRowInfoLines(row) {
    const { lines } = row;
    return (lines || [])
      .filter((_) => _)
      .map((line, index) => {
        let html = ``;
        if (typeof line === `object` && line.constructor === Array) {
          if (typeof line[0] === `object` && line[0].label && line[0]) {
            html = line
              .filter((_) => _.value)
              .map(({ label, value }) => {
                return this.createLine(
                  label,
                  value,
                  this.props.fromCriminalCard || this.props.fromSispenVisitorCard,
                  row
                );
              });
          }
        }
        return (
          <div key={index} className="row-autocomplete-line">
            {html}
          </div>
        );
      });
  }

  createImageSrc(image) {
    if (image === `avatar.png`) {
      return srcImageAvatar;
    }
    if (!image || image === `` || image.match(/\.\w{3,4}$/)) {
      return image || `#`;
    }
    return /^data/.test(image) ? image : `data:image/png;base64,` + image;
  }
}
