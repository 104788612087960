import React, { useEffect, useState } from 'react';
import './Tag.scss';

export default function Tag({
  label, 
  isLast, 
  onSelect,
  onRemove,
}) {

  const [ hidden, setHidden ] = useState(true);

  useEffect(() => {
    setHidden(false);
  }, [])

  function handleSelection() {
    if (!isLast) onSelect();
  }
  
  return (
    <div className={ `SearchBar-Tag` + (hidden ? ` hidden` : ``) + (isLast ? ` last` : ``)} onClick={ handleSelection }>
      <div className="label">{ label }</div>
      { isLast ? <div className="close" onClick={ onRemove }></div> : null }
    </div>
  );

}
