import React, { useEffect, useState } from 'react';
import InputList from './InputList';
import Tag from './Tag';
import './index.scss';

function limbo(){}

export default function SearchBar ({
  tags = [],
  action = {},
  onChange = limbo,
  onSelectTag = limbo,
  onRemoveTag = limbo,
  clearTags = limbo,
}) {

  const [ tagsId, setTagsId ] = useState('');
  const [ params, setParams ] = useState({});

  useEffect(() => {
    const newTagsId = tags.join('');
    if ( newTagsId !== tagsId ) {
      setTagsId( newTagsId );
      setParams({});
    }
  }, [ tags, tagsId ])

  function handleClearClick() {
    if (tags.length > 0) clearTags();
  }

  function changeInputParam({ name, value, requiredParams }) {
    if (name) {
      const newParams = { ...params, [name]: value };
      setParams( newParams );
      onChange( newParams, requiredParams )
    }
  }

  function renderTags() {
    return (
      <React.Fragment>
        { tags.map( (tag, index) => (
          <Tag 
            key={ tag + `-` + index } 
            label={ tag } 
            onSelect={ () => onSelectTag(tag) }
            onRemove={ () => onRemoveTag(tag) } 
            isLast={ index === tags.length - 1 } />
        ))}
      </React.Fragment>
    )
  }

  function renderInputs() {
    let paramsList = []
    if (tags.length === 0) {
      paramsList = [{ id: `text`, placeholder: `Busca Geral` }]
    } else if (action && action.paramsList && action.createRow) {
      paramsList = action.paramsList
    }
    return <InputList data={paramsList} onChange={changeInputParam} />
  }

  // const [bntHistoryValue, setBntHistoryValue] = useState("VER HISTÓRICO DE BUSCA");
  // const [clicked, setClicked] = useState(false);

  // function onHistoryCLick() {
  //   setClicked(!clicked);
  //   clicked ? setBntHistoryValue("VER HISTÓRICO DE BUSCA") : setBntHistoryValue("FECHAR HISTÓRICO DE BUSCA")
  //   console.log(clicked)
  // }

  const searchFocus = true
  return (
    <div className="SearchPanel-SearchBar">
      <div className={ `flex-container search-bar` + (searchFocus ? ` focus` : ``) }>
        <div className="search-panel-icon-container">
          <div className={'icon search' + ( tags.length === 0 ? '' : ' hidden' )} />
          <div className={'icon clear' + ( tags.length === 0 ? ' hidden' : '' )} onClick={ handleClearClick } />
        </div>
        { renderTags() }
        { renderInputs() }
      </div>
      {/* <div className="btn-search-history"><button type="button" onCLick={onHistoryCLick}>{bntHistoryValue}</button></div> */}
    </div>
  );

}
