import axios from 'axios';
import { path } from 'ramda';
import { publish } from '../EventBus';
import { App } from '../../App'


// API

export const createApi = ({ 
  baseURL, 
  // defaults
  interceptorRequestHandler = defaultInterceptorRequestHandler,
  interceptorRequestError = defaultInterceptorRequestError,
  interceptorResponseHandler = defaultInterceptorResponseHandler,
  interceptorResponseError = defaultInterceptorResponseError,
  headers = { 'Accept': 'application/json' },
  silently = false
}) => {

  const api = axios.create({
    baseURL,
    headers
  });

  api.all = axios.all

  if (silently) {
    return api
  }

  api.interceptors.request.use(
    interceptorRequestHandler,
    interceptorRequestError
  )

  api.interceptors.response.use(
    interceptorResponseHandler,
    interceptorResponseError
  )

  return api

}

// DEFAULTS

export const defaultInterceptorRequestHandler = config => {
  Object.entries(App.headers).forEach(([name, value]) => {
    if (value) config.headers[name] = value
  })
  return config
}

export const defaultInterceptorRequestError = error => {
  console.error(`Error: axios interceptor request:`, error)
  return handleError(error)
};

export const defaultInterceptorResponseHandler = response => {
  return mapResponseToUsefulData(response)
}

export const defaultInterceptorResponseError = error  => {
  console.error(`Error: axios interceptor response :`, error)
  return handleError(error)
}

// UTILITY

const handleError = error => {
  // log
  console.error(error);
  console.debug(error);
  // extract in useful error format
  const usefulError = mapToUsefulError(error);
  // important: 
  // message isn't string. It's object, with: { message: '...', error: {} }
  // publish error event
  publish('error', usefulError);
  // return as promise rejected
  return Promise.reject( usefulError );
};

const mapResponseToUsefulData = response => {
  const data = response.data;
  if (typeof data === `object` && data.constructor === Array) {
    return [...data]
  }
  return {
    ...data,
    // as function it can't be serialized
    response: () => response
  };
};

const mapToUsefulError = error => {
  // limpando token quando ele estiver inválido
  if (error && error.response && error.response.status === 401) {
    publish(`auth::logout`, {http: false})
  }
  // try get from body
  const messageFromBody = path(['response', 'data', 'message'], error);
  if (messageFromBody) return { message: messageFromBody, error };
  // try get exception
  const messageFromError = error && error.message;
  if (messageFromError) return { message: messageFromError, error };
  // otherwise, return error
  return { message: error, error };
};
