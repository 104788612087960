import * as type from '../fieldTypes'

export const PoliceStationsAll = {
  created: `2019-11-14`,
  context: `Polícia.Delegacias`,
  role: `CEREBRUM_DELEGACIAS`,
  title: 'TODAS',
  description: 'Mostra todas as delegacias do Ceará',
  parser: () => ({ card: `CardPoliceStationList`, props: {} }),
}

export const PoliceStationsByRadius = {
  created: `2019-11-04`,
  context: `Polícia.Delegacias`,
  role: `CEREBRUM_DELEGACIAS`,
  title: `CONSULTA POR ÁREA`,
  description: `Visualizar todas as delegacias existentes dentro de uma área no mapa`,
  parser: () => ({ card: `CardPoliceStationList`, props: {type: `circle`} })
}

export const PoliceStationsSearch = {
  created: `2019-11-14`,
  context: `Polícia.Delegacias`,
  role: `CEREBRUM_DELEGACIAS`,
  params: {
    name: {label: `Nome`, type: type.text, optional: true},
    ais: {label: `AIS`, type: type.number, optional: true},
  },
  parser: json => ({ card: `CardPoliceStationList`, props: json }),
}

export const PoliceStationsById = {
  created: `2019-11-14`,
  context: `Polícia.Delegacias`,
  role: `CEREBRUM_DELEGACIAS`,
  params: {
    id: {label: `Código (Delegacia)`, type: type.number},
  },
  parser: json => ({ card: `PoliceStationDetails`, props: json }),
}

