
import util from '../../../helpers/util'
import * as type from '../fieldTypes'

export const EnelByName = {
  created: `2019-10-23`,
  context: `ENEL`,
  role: `CEREBRUM_BUSCA_ENEL`,
  params: {
    name:     {placeholder: `Nome`, optional: true},
    address:  {placeholder: `Endereço`, optional: true},
    district: {placeholder: `Distrito`, optional: true},
    city:     {placeholder: `Cidade`, optional: true},
  },
  createRequest: params => {
    let maxValue = ``
    Object.entries(params).forEach(([key, value]) => {
      params[key] = value ? value : undefined
      maxValue = maxValue || params[key]
    })
    if (maxValue.length < 3)
      throw new Error(`É necessário mínimo de 3 caracteres em algum campo.`)
    //
    return {path: `/enel/search`, params}
  },
  createRow: json => {
    const { name, address, city, district, cnpj, cpf } = json
    const lineId = [
      {label: `CPF`,  value: cpf  ? util.formatCPF(cpf)   : ``},
      {label: `CNPJ`, value: cnpj ? util.formatCNPJ(cnpj) : ``},
    ].filter(i => i.value)
    const lineAddress = [
      {label: `Endereço`, value: [address, district, city].filter(i => i).join(` - `)},
    ]
    return {
      card: `CardEnelHistorico`,
      props: {cpf, cnpj},
      //
      title: name,
      lines: [lineId, lineAddress]
    }
  }
}

export const EnelByCPF = {
  created: `2019-10-23`,
  context: `ENEL`,
  role: `CEREBRUM_BUSCA_ENEL`,
  params: {
    cpf: {label: `CPF`, placeholder: `000.000.000-00`, type: type.cpf},
  },
  parser: ({cpf}) => {
    cpf = cpf.replace(/\W/g, ``)
    return {card: `CardEnelHistorico`, props: {cpf}}
  }
}

export const EnelByCNPJ = {
  created: `2019-10-23`,
  context: `ENEL`,
  role: `CEREBRUM_BUSCA_ENEL`,
  params: {
    cnpj: {label: `CNPJ`, placeholder: `00.000.000/0000-00`, type: type.cnpj},
  },
  parser: ({cnpj}) => {
    cnpj = cnpj.replace(/\W/g, ``)
    return {card: `CardEnelHistorico`, props: {cnpj}}
  }
}

