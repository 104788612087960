
import * as type from '../fieldTypes'

export const IndicadoresAreaToday = {
  created: `2019-04-22`,
  context: `Indicadores.Por raio`,
  role: `CEREBRUM_INDICADORES_AREA`,
  title: `CONSULTA POR ÁREA HOJE`,
  description: `Visualizar todos os indicadores cadastrados até hoje dentro de uma área no mapa`,
  parser: () => {
    return {card: `CardIndicadoresArea`, props: {type: `date`, date: new Date()}}
  }
}

export const IndicadoresAreaByDate = {
  created: `2019-04-22`,
  context: `Indicadores.Por raio`,
  role: `CEREBRUM_INDICADORES_AREA`,
  params: {
    date: {label: `Data`, placeholder: `dd/mm/aaaa`, type: type.date},
  },
  title: `CONSULTA POR DATA`,
  subtitle: `Visualizar todos os indicadores cadastrados em uma data específica `,
  parser: ({date}) => {
    return {card: `CardIndicadoresArea`, props: {type: `date`, date}}
  }
}

export const IndicadoresAreaByRange = {
  created: `2019-04-22`,
  context: `Indicadores.Por raio`,
  role: `CEREBRUM_INDICADORES_AREA`,
  params: {
    startDate: {label: `Data Inicial`, placeholder: `dd/mm/aaaa`, type: type.date},
    endDate: {label: `Data Final`, placeholder: `dd/mm/aaaa`, type: type.date, optional: true},
  },
  title: `CONSULTA POR INTERVALO ENTRE DATAS`,
  subtitle: `Visualizar todos os indicadores cadastrados em um intervalo entre datas`,
  parser: (data) => {
    data.type = data.endDate ? `rangeDate` : `startDate`
    return {card: `CardIndicadoresArea`, props: data}
  }
}

