import React, { Component } from 'react'
import { List } from 'react-virtualized'
import { normalizeAddress } from '../../../../helpers/addressModel'
import util from '../../../../helpers/util'
import { publish } from "../../../../services/EventBus"
import BodyAsync from '../../../SearchPanel/BodyAsync'
import srcImageAvatar from '../../../../images/avatar.png'
import './index.scss'

export class CardSispenVisitante extends Component {

  static ROLES = [
    `CEREBRUM_BUSCA_SISPEN`,
  ]

  state = {
    loading: true,
    message: null,
    rows: [],
    current: 0,
    showBodyAsync: false,
    sispenDetails: {},
    sispenDetailsList: [],
    selectedIndex: -1,
  }

  dataSetup = (data) => {
    const allowClick = this.props.roles.includes(`CEREBRUM_BUSCA_SISPEN`)
    const { id, name, cpf, rg, addresses, thumb, thumbDefault, error } = data
    const sex = this.formatSex(data.sex)
    const birthDate = data.birthDate ? util.str2date(data.birthDate) : null
    const visits = data.visitsMade.sort((a,b) => b.dateTime < a.dateTime ? -1 : +1)
    if (addresses) {
      Object.values(addresses).forEach(json => json && json.geocode ? this.geocodeFocus(json, id, name) : 0)
    }
    const sispenDetails = {id, name, cpf, rg, sex, birthDate, addresses, thumb, thumbDefault, visits, error, allowClick}
    this.setState({sispenDetails}, () => {
      this.props.setTitle(`SISPEN Visitante ` + name)
      this.props.show()
      this.setState({loading: false})
    })
  }

  componentDidMount() {
    const { data, hash, show } = this.props
    if(data && data.sispenVisitor && !util.objIsEmpty(data.sispenVisitor)){
      data.sispenVisitorRowIndex !== null && this.setState({ selectedIndex: data.sispenVisitorRowIndex });
      data.sispenVisitorRows && this.setState({ rows: data.sispenVisitorRows })
      data.sispenDetailsList && this.setState({ sispenDetailsList: data.sispenDetailsList })
      this.dataSetup(data.sispenVisitor)
    } else {
      this.loadSispenVisitorData().then( data => {
        const { cpf, rg } = data
        this.dataSetup(data)
        this.setState({sispenDetailsList: [data]})
        this.state.rows.length > 0 && publish(`card::set-identifiers`, { hash: hash, data: {sispenDetailsList: [data]} })
        publish(`card::set-identifiers`, { hash: this.props.hash, cpf, rg, data: {sispenVisitor: data} })
      }).catch((error) => {
        util.handleError(
          error,
          show,
          () => {
            this.setState({loading: false})
          },
          (message) => {
            this.setState({loading: false, message})
          }
        );
      })
    }
  }

  geocodeFocus = (json, id, name) => {
    if (!json.street && !json.city && !json.state) {
      return
    }
    const address = normalizeAddress(json)
    const { geocode } = json
    const data = {
      point: geocode.position,
      source: geocode.source,
      address: address.text,
      title: id + ` - ` + name
    }
    const zoom = address.address ? 13 : 8
    this.props.publish(`map::zoom`, {point: data.point, zoom})
    setTimeout(() => {
      this.props.publish(`map::marker.add`, {name: `geocode`, point: data.point, data, color: `#3dca85`, className: `square animated`, popup: this.createPopup})
    }, 1)
  }

  createPopup = json => {
    const { title, address, source } = json.data
    return { title, text: address, source }
  }

  getCPFNumbers = (identities) => {
    const numbers = {}
    identities.forEach(i => {
      if (i.documentInfo && i.documentInfo.cpf && i.documentInfo.cpf.length > 0) {
        const { cpf } = i.documentInfo
        numbers[cpf] = true
      }
    })
    return Object.keys(numbers)
  }
  getRGNumbers = (identities) => {
    const numbers = {}
    identities.forEach(i => {
      if (i.rgInfo) {
        numbers[i.rgInfo.rg] = true
      }
    })
    return Object.keys(numbers)
  }

  createRow = json => {
    const { thumb, name, sex, rg, cpf, birthDate, id } = json
    return {
      card: `CardSispenVisitante`,
      props: cpf ? {...json} : {...json, thumbDefault: srcImageAvatar},
      //
      image: thumb || `avatar.png`,
      title: `ID ` + id + ` - ` + name,
      lines: [
        [
          {label: `RG`, value: rg},
          {label: `CPF`, value: cpf ? util.formatCPF(cpf) : ``},
        ],
        [
          {label: `Sexo`, value: sex ? (sex === `F` ? `Feminino` : `Masculino`) : ``},
          {label: `Data de Nascimento`, value: util.date2str(util.str2date(birthDate))},
        ]
      ],
      fields: [
        {label: `Nasc`, value: birthDate ? util.date2str(util.str2date(birthDate)) : null},
        {label: `Nome`, value: name},
        {label: `RG`,   value: rg ? rg : null},
        {label: `CPF`,  value: cpf ? util.formatCPF(cpf) : null},
      ]
    }
  }

  countCheckValidation = (param) => {
    const count = Object.values(param.props.infoValidation).filter(rowValue => rowValue)
    return count.length
  }

  loadPossibilities = (identities, resolve, reject, error) => {
    identities.sort(
      (a, b) =>
        new Date(b.rgInfo.identityDate || Date.now()).getTime() -
        new Date(a.rgInfo.identityDate).getTime()
    );
    const name = identities[0].basicInfo.name;

    this.props.http(`/sispen/visitors`, { name }).then((data) => {
      if (data.error) return error ? reject(error) : this.sispenVisitorErrors(`ERROR`, reject)
      const civilCPF = this.getCPFNumbers(identities);
      const civilRG = this.getRGNumbers(identities);

      let report = {};

      data.forEach((item) => {
        const infoValidation = {
          CPF: false,
          RG: false,
          Nome: false,
          Sexo: false,
          "Data de Nascimento": false,
        };

        const { name, sex, cpf, rg, birthDate } = item;

        if (cpf) {
          if (civilCPF[0] === cpf) {
            item.isChecked = true;
            report = item;
          } else if (rg) {
            if (civilRG[0] === rg) {
              item.isChecked = true;
              report = item;
            }
          }
        } else if (rg) {
          if (civilRG[0] === rg) {
            item.isChecked = true;
            report = item;
          }
        }

        if (cpf) {
          if (civilCPF[0] === cpf) {
            infoValidation["CPF"] = true;
          }
        }
        if (rg) {
          if (civilRG[0] === rg) {
            infoValidation["RG"] = true;
          }
        }
        if (name) {
          if (identities[0].basicInfo.name === name) {
            infoValidation["Nome"] = true;
          }
        }
        if (sex) {
          if (identities[0].basicInfo.sex === sex) {
            infoValidation["Sexo"] = true;
          }
        }
        if (birthDate) {
          if (identities[0].basicInfo.birthDate === birthDate) {
            infoValidation["Data de Nascimento"] = true;
          }
        }

        item.infoValidation = infoValidation;
        const row = this.createRow(item);
        this.setState({ rows: [...this.state.rows, row] });
      });
      
      const rows = this.state.rows.sort(
        (a, b) => this.countCheckValidation(b) - this.countCheckValidation(a)
      );
      this.setState({ rows: rows });
      publish(`card::set-identifiers`, { hash: this.props.hash, data: {sispenVisitorRows: rows} })
      
      if (report.id) {
        rows.forEach((row, index) => {
          if(row.props.id === report.id){
            this.setState({ selectedIndex: index })
            publish(`card::set-identifiers`, { hash: this.props.hash, data: {sispenVisitorRowIndex: index} })
          }
        })
        const url = `/sispen/visitors/${report.id}/visits`
        this.props.http(url).then(data => {
          const { id, name, sex, cpf, rg, birthDate, thumb, thumbDefault } = report
          const visitorResponse = {id, name, sex, cpf, rg, birthDate, thumb, thumbDefault}
          let visitsMade = data
          if (data.error) {
            visitsMade = []
            visitorResponse.error = data.error
          }
          visitorResponse.visitsMade = visitsMade
          resolve(visitorResponse)
        })
      } else {
        error ? reject(error) : this.sispenVisitorErrors(`NOT-FOUND`, reject)
      }
    })
  }

  civilErrors = (type, reject, rg) => {
    switch (type) {
      case `ERROR`:
        reject({status: 404, message: `Ocorreu um erro ao consultar a base CIVIL. Não foi possível buscar possíveis registros`});
        break;
      case `EMPTY`:
        reject({status: 404, message: `Nenhum registro na base CIVIL para o RG ${rg}`});
        break;
      default:
        break;
    }
  }

  sispenVisitorErrors = (type, reject) => {
    switch (type) {
      case `ERROR`:
        reject({status: 404, message: `Ocorreu um erro ao consultar a base SISPEN. Não foi possível buscar possíveis registros`});
        break;
      case `WITHOUT-ID`:
        reject({status: 404, message: `Identificador de consulta da base (CPF ou RG) não encontrado`});
        break;
      case `NOT-FOUND`:
        reject({status: 404, message: `Nenhum registro encontrado`});
        break;
      default:
        break;
    }
  }

  possibilitiesSearch = (rg, resolve, reject, error) => {
    if(rg){
      return this.props.http(`/civil/details`, {rg}).then((identities) => {
        if (identities.error) {
          return error ? reject(error) : this.civilErrors(`ERROR`, reject)
        }
        if (identities.length > 0) {
          return this.loadPossibilities(identities, resolve, reject, error);
        } 
        error ? reject(error) : this.civilErrors(`EMPTY`, reject, rg)
      });
    }
    error ? reject(error) : this.sispenVisitorErrors(`WITHOUT-ID`, reject)
  }

  loadSispenVisitorData = () => {
    return new Promise((resolve, reject) => {
      const { idSispenVisitante: id, name, sex, cpf, rg, birthDate, thumb, thumbDefault } = this.props
      const searchByCpf = cpf && !id && !name
      if(cpf || id){
        const url = `/sispen/visitors` + (searchByCpf ? `/${cpf}` : `/${id}/visits`)
        return this.props.http(url).then( data => {
          const { error } = data
          if (error) {
            return this.possibilitiesSearch(rg, resolve, reject, error)
          }
          resolve(searchByCpf ? data : {id, name, sex, cpf, rg, birthDate, thumb, thumbDefault, visitsMade: data})
        }).catch(reject)
      }
      this.possibilitiesSearch(rg, resolve, reject)
    })
  }

  selectRowHandler = (row) => {
    const { id } = row.props;
    this.state.rows.forEach((row, index) => {
      if (row.props.id === id) {
        if(index !== this.state.selectedIndex){
          this.setState({selectedIndex: index})
          publish(`card::set-identifiers`, { hash: this.props.hash, data: {sispenVisitorRowIndex: index} })
          let hasDetails = false
          this.state.sispenDetailsList.forEach((item, index) => {
            if(item.id === row.props.id){
              hasDetails = true
              this.dataSetup(this.state.sispenDetailsList[index])
              publish(`card::set-identifiers`, { hash: this.props.hash, data: {sispenVisitor: this.state.sispenDetailsList[index]} })
            }
          })
          if(!hasDetails){
            const url = `/sispen/visitors/${row.props.id}/visits`
            this.props.http(url).then(data => {
              const { id, name, sex, cpf, rg, birthDate, thumb, thumbDefault } = row.props
              const selectedRowData = {id, name, sex, cpf, rg, birthDate, thumb, thumbDefault}
              let visitsMade = data
              if (data.error) {
                visitsMade = []
                selectedRowData.error = data.error
              }
              selectedRowData.visitsMade = visitsMade
              this.setState({ sispenDetailsList: [...this.state.sispenDetailsList, selectedRowData] })
              publish(`card::set-identifiers`, { hash: this.props.hash, data: {sispenDetailsList: this.state.sispenDetailsList} })
              this.dataSetup(selectedRowData)
              publish(`card::set-identifiers`, { hash: this.props.hash, data: {sispenVisitor: selectedRowData} })
            })
          }
        } else {
          this.setState({ selectedIndex: -1, sispenDetails: {}})
          publish(`card::set-identifiers`, { hash: this.props.hash, data: {sispenVisitorRowIndex: -1, sispenVisitor: {}} })
        }
      }
    });
  };

  formatSex = text => {
    if (text === `M`) return `MASCULINO`
    if (text === `F`) return `FEMININO`
    if (text === `I`) return `INDEFINIDO`
    return typeof text === `string` && text.length ? text : `-`
  }

  clickRowHandler = prisonerId => {
    this.props.openCard(`CardSispenDetalhe`, {id: prisonerId}, {}, true)
  }

  renderUncheckedList = (rows) => {
    if (this.state.showBodyAsync) {
      return (
        <>
          <div className="return-button-container">
            <div onClick={() => { this.setState({ showBodyAsync: !this.state.showBodyAsync })}} className="return-button">CONFIRMAR VÍNCULO</div>
          </div>
          <div className="label-list">Lista de possibilidades:</div>
          {this.state.rows.length > 0 
            ? <BodyAsync fromSispenVisitorCard={true} onClick={this.handleClickRow} rows={rows} selectedIndex={this.state.selectedIndex} handleSelectedRow={this.selectRowHandler} />
            : <p className="message">Não há possíveis registros com os dados utilizados</p>
          }
        </>
      )
    }

    return (
      <>
        <div className="return-button-container">
          <div onClick={() => this.setState({ showBodyAsync: !this.state.showBodyAsync })} className="return-button">POSSIBILIDADES</div>
        </div>
        <p className="message">{this.state.message ? this.state.message : `NENHUM REGISTRO ENCONTRADO`}</p>
      </>
    )
  }

  renderContent = (rows) => {
    if (this.state.showBodyAsync) {
      return (
        <>
          <div className="return-button-container">
            <div onClick={() => { this.setState({ showBodyAsync: !this.state.showBodyAsync })}} className="return-button">CONFIRMAR VÍNCULO</div>
          </div>
          <div className="label-list">Lista de possibilidades:</div>
          {rows.length > 0 
            ? <BodyAsync fromSispenVisitorCard={true} onClick={this.handleClickRow} rows={rows} selectedIndex={this.state.selectedIndex}  handleSelectedRow={this.selectRowHandler} />
            : <p className="message">Não há possíveis registros com os dados utilizados</p>
          }
        </>
      )
    }
     
    return (
      <>
        {
          rows.length > 0 &&
          <div className="return-button-container">
            <div onClick={() => this.setState({ showBodyAsync: !this.state.showBodyAsync })} className="return-button">POSSIBILIDADES</div>
          </div>
        }
        <div className="content">
          {this.renderPerson()}
          {this.renderVisits()}
        </div>
      </>
    )
  }

  render() {
    const { rows } = this.state
    if (this.props.hidden) {
      return null
    }

    return (
      <div className="CardSispenVisitante">
        <div className="card-title">
          SISPEN VISITANTE
          <span>
            {this.state.loading || this.state.message ? null : this.state.name}
          </span>
        </div>
        {
          this.state.loading ? (
            <div className="message-loading">CARREGANDO...</div>
          ) : this.state.sispenDetails.id ? (
            this.renderContent(rows)
          ) : (
            this.renderUncheckedList(rows)
          )
        }
      </div>
    );
  }

  renderPerson() {
    const { thumb, thumbDefault, addresses } = this.state.sispenDetails
    const address = addresses ? Object.keys(addresses).length : 0
    const empty = !thumb
    const image = thumb || thumbDefault || srcImageAvatar
    const src = /^data/.test(image) ? image : (`data:image/png;base64,` + image)
    return (
      <div className="group columns">
        <div className={ `column photo` + (empty ? ` empty` : ``) + (src === srcImageAvatar ? ` avatar` : ``)}>
          <img src={ src } alt="" />
        </div>
        <div className={ `column info` + (address ? `` : ` padding-top`) + (src === srcImageAvatar ? ` avatar` : ``)}>
          { this.renderPersonInfo() }
          { this.renderPhones() }
          { this.renderAddress(`Endereço Residencial`, `homeAddress`) }
          { this.renderAddress(`Endereço Comercial`, `businessAddress`) }
        </div>
      </div>
    )
  }

  renderPersonInfo() {
    const { id, name, sex, cpf, rg, birthDate } = this.state.sispenDetails
    const line1 = [
      {name: `ID`,    value: `` + id},
      {name: `NOME`,  value: name},
      {name: `SEXO`,  value: sex},
    ].filter(i => i.value.trim().length > 0)
    const line2 = [
      {name: `IDADE`, value: birthDate ? (util.calcAge(birthDate) + ` anos`) : `-`},
      {name: `Data de Nascimento`, value: birthDate ? util.date2str(birthDate) : `-`},
      {name: `RG`, value: rg || `-`},
      {name: `CPF`, value: cpf ? util.formatCPF(cpf) : `-`},
    ].filter(i => i.value.trim().length > 0)
    return (
      <React.Fragment>
        { this.renderValues(line1, `text no-border`) }
        { this.renderValues(line2, `text no-border`) }
      </React.Fragment>
    )
  }

  renderPhones() {
    const address = this.state.sispenDetails.addresses || {}
    const phones = [
      (address.homeAddress     || {}).landline,
      (address.businessAddress || {}).landline,
      (address.homeAddress     || {}).cellPhone,
      (address.businessAddress || {}).cellPhone,
    ].filter(i => i && i.trim().length > 5).map(i => util.formatPhone(i))
    if (phones.length === 0) {
      return null
    } else {
      return this.renderValues([{name: `TELEFONE`, value: phones.join(` / `)}], `text no-border`)
    }
  }

  renderAddress(title, field) {
    const address = (this.state.addresses || {})[field]
    if (!address || (!address.street && !address.city && !address.state)) {
      return null
    }
    return (
      <div className="line-values text no-border" key={ field }>
        <div className="item text text no-border">
          <div className="label">{ title }</div>
          <div className="value">{ normalizeAddress(address).fulltext }</div>
        </div>
      </div>
    )
  }

  renderValues(items, className=``) {
    if (!items || items.length === 0) {
      return null
    }
    return (
      <div className={ `line-values ${className}` }>
        {items.map((i, index) => (
          <div className={ `item ` + (i.className || ``) } key={ index }>
            <div className="label">{ i.name }</div>
            <div className="value">{ i.value || `-` }</div>
          </div>
        ))}
      </div>
    )
  }

  renderVisits() {
    const { sispenDetails } = this.state
    if (sispenDetails.error) {
      return (
        <div className="error-container">
          <p className='error-message'><span className='alert-icon' />Ocorreu um erro. Não foi possível carregar as visitas deste registro.</p>
        </div>
      )
    }
    const { visits } = this.state.sispenDetails
    if (visits.length === 0) {
      return  <div className="message empty">NENHUMA VISITA REALIZADA</div>
    }
    return (
      <div className="container-list">
        <List width={ 650 } height={ 380 } rowHeight={ 75 } rowCount={ visits.length } rowRenderer={ this.renderRow } />
      </div>
    )
  }

  renderRow = ({index, key, style}) => {
    const { allowClick } = this.state.sispenDetails
    const item = this.state.sispenDetails.visits[index]
    const date = new Date(item.dateTime)
    return (
      <div className={ `row` + (!allowClick || !item.prisonerId ? ` no-click` : ``) } style={ style } key={ key } onClick={ this.clickRowHandler.bind(this, item.prisonerId) }>
        <div className="column a">
          <div className="date">{ util.date2str(date) }</div>
          <div className="time">{ util.date2time(date) }</div>
        </div>
        <div className="column b">
          <div className="line">{ item.prisonUnity.name }</div>
          <div className="mainline">{ item.prisoner }</div>
          <div className="labels link"><span>{ item.linkType } / { item.link }</span></div>
          <div className="labels">
            <span><b>ID</b>{ item.prisonerId || `-` }</span>
            { item.prisonerCpf ? <span><b>CPF</b>{ util.formatCPF(item.prisonerCpf) }</span> : null }
            { item.prisonerRg  ? <span><b>RG</b>{ item.prisonerRg || `-` }</span> : null }
          </div>
        </div>
      </div>
    )
  }

}
