import React, { Component } from 'react'
import { publish, subscribe, unsubscribe } from '../../services/EventBus'
import PinchZoomPan from 'react-responsive-pinch-zoom-pan'
import './index.scss'

const MAX_ZOOM = 5

export default class ImageViewer extends Component {

  state = {
    image: null // { imgProps, label }
  }

  static OPEN_IMAGE_EVENT = 'imageviewer::open'

  static open(image) {
    publish(ImageViewer.OPEN_IMAGE_EVENT, image)
  }

  render() {
    return  this.isOpen ? this.renderImageViewer() : null
  }

  renderImageViewer() {
    const { image } = this.state
    const { imgProps, label } = image
    return (
      <div 
        className="image-viewer__container"
        onMouseDown={this.handleContainerClickStart}>
        <div className="image-viewer__frame">
          <PinchZoomPan 
            zoomButtons={false}
            position="center"
            maxScale={MAX_ZOOM}>
            <img 
              alt=""
              {...imgProps} />
          </PinchZoomPan>
        </div>
        { label && 
          <div className="image-viewer__label">
            { label }
          </div> }
      </div>
    );
  }

  componentDidMount() {
    this.unsubscribe = subscribe(ImageViewer.OPEN_IMAGE_EVENT, this.handleOpen)
  }

  componentWillUnmount() {
    unsubscribe(this.unsubscribe)
  }

  handleContainerClickStart = e => {
    if (e.target.className === 'image-viewer__container') this.close()
  }

  handleOpen = (_, image) => {
    this.setState({ image }, () => this.forceUpdate())
  }

  close = () => {
    this.setState({ image: null })
  }

  get isOpen() {
    return !!this.state.image
  }

}
