import React, { Component } from 'react'
import { publish, subscribe } from '../../services/EventBus'
import './CenterPanel.scss'

export default class CenterPanel extends Component {

  state = {
    loading: true,
    hidden: true,
    selecting: false,
    periodSelected: null,
    indicator: null,
    isRootNode: true,
    nodeSelected: null,
  }

  constructor(props) {
    super(props)
    this.state.periodSelected = props.periodTypes[0]
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({loading: false})
    }, 1600)
    //
    subscribe(`thermometer::indicator.mouseenter`, (topic, data) => {
      this.setIndicator(data)
    })
    subscribe(`thermometer::indicator.selected`, (topic, data) => {
      this.setState({isRootNode: data ? false : true, nodeSelected: data}, () => {
        if (data) {
          this.setState({indicator: data})
        }
      })
    })
    subscribe(`thermometer::indicator.reset`, () => {
      this.setState({isRootNode: true, nodeSelected: null})
    })
    subscribe(`thermometer::hidden`, () => {
      const indicator = this.state.nodeSelected || this.props.rootIndicator.data
      this.setState({indicator})
    })
    subscribe(`thermometer::indicator.back2root`, () => {
      const indicator = this.state.nodeSelected || this.props.rootIndicator.data
      this.setState({indicator})
    })
  }

  clickBtnBackHandler = () => {
    this.setState({indicator: this.props.rootIndicator.data})
    publish(`thermometer::indicator.reset`)
  }

  show = () => this.setState({hidden: false})
  hide = () => this.setState({hidden: true})
  hideSelection = () => this.setState({hidden: true, selecting: false})

  showChart = () => {
    publish(`thermometer::mouse.enter`)
  }

  mouseEnterHandler = () => {
    clearTimeout(this.timer)
    this.showChart()
  }

  mouseLeaveHandler = () => {
    clearTimeout(this.timer)
    this.timer = setTimeout(this.hideSelection, 500)
  }

  selectOption = (item) => {
    publish(`thermometer::svg.clear`)
    this.setState({selecting: false})
    setTimeout(() => {
      publish(`thermometer::indicator.period`, item)
      this.setState({periodSelected: item, indicator: null})
    }, 500)
  }

  showOptions = () => {
    this.setState({selecting: true})
  }

  toggleOptions = () => {
    const selecting = !this.state.selecting
    this.setState({selecting})
  }

  setIndicator = (data) => {
    const indicator = data.data.data
    this.setState({indicator}, this.hide)
  }

  render() {
    const { loading, hidden, isRootNode, selecting } = this.state
    const clss = `CenterPanel` + (hidden ? ` hidden` : ``) + (selecting ? ` selecting` : ``) + (loading ? ` loading` : ``)
    return (
      <div className={ clss }>
        { this.renderIndicatorStatus() }
        { isRootNode ? this.renderPanel() : this.renderBtnBack() }
      </div>
    )
  }

  renderIndicatorStatus() {
    const { name, value, status } = this.state.indicator || this.props.rootIndicator.data
    const title = name.replace(/\//g, ` / `)
    return (
      <div className="container-indicator">
        <div className="value" >{ (value || 0).toFixed(1).replace(`.`,`,`) }</div>
        <div className="status">{ status }</div>
        <div className="title" >{ title }</div>
      </div>
    )
  }

  renderBtnBack() {
    return (
      <button className="btn-back" onClick={ this.clickBtnBackHandler }></button>
    )
  }

  renderPanel() {
    const periodSelected = this.state.periodSelected || this.props.periodTypes[0]
    const options = this.props.periodTypes
    return (
      <div className="container-panel" onMouseEnter={ this.mouseEnterHandler } onMouseLeave={ this.mouseLeaveHandler }>
        <div className="panel">
          <div className="panel-title">COMPARADO COM</div>
          <div className="panel-options">
            {options.map(i => (
              <button key={ i.attr } className={ i.label === periodSelected.label ? `selected` : `` } onClick={ this.selectOption.bind(this, i) }>
                { i.label }
              </button>
            ))}
          </div>
        </div>
        <button className="btn-select current" onClick={ this.showOptions } onMouseEnter={ this.showChart }>
          { periodSelected.shortLabel }
        </button>
      </div>
    )
  }

}
