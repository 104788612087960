
import { subscribe } from '../../services/EventBus'
import * as actions from './ActionTypes/'

const userActions = []
let userRoles = []

subscribe(`auth::login`, (topic, data) => {
  userRoles = [...(data.roles || [])]
})

subscribe(`auth::logout`, () => {
  userRoles = []
})

export function syncActionsByRoles() {
  const roles = [...userRoles]
  while (userActions.length) {
    userActions.pop()
  }
  Object.values(actions).forEach(action => {
    if (!action.role || roles.includes(action.role)) {
      const paramsList = Object.entries(action.params || {}).map(([id, value]) => ({...value, id}))
      userActions.push({...action, paramsList})
    }
  })
}

function getAllTagsOnContext( parentTags=[] ) {
  const tags = getTags( parentTags )
  return tags.concat([].concat( ...tags.map( tag => getAllTagsOnContext( parentTags.concat(tag.value) ) ) ))
}

export function getAllTags() {
  return getAllTagsOnContext()
}

export function getTags(parentTags=[]) {
  const context = parentTags.join(`.`)
  return userActions
    .filter(i => i.context.indexOf(context) === 0 && i.context !== context)
    .map(action => action.context.replace(context, ``).replace(/^\./, ``).split(`.`)[0])
    .sort((a,b) => a.localeCompare(b))
    .reduce((list, tag) => list.includes(tag) ? list : [...list, tag], [])
    .map( tag => ({ value: tag, parents: parentTags }) )
}

export function getOptions(parentTags=[]) {
  const context = parentTags.join(`.`)
  return userActions.filter(i => i.context === context && i.parser)
}

export function getAction(tags=[]) {
  const context = tags.join(`.`)
  const actions = userActions.filter(i => i.context === context && i.createRow)
  return actions.length === 1 ? actions[0] : null
}

export function getTypeByText(text) {
  let type = null
  userActions.filter(i => i.parser).forEach(action => {
    Object.values(action.params || {}).forEach(field => {
      if (field.type && field.type.match && field.type.match(text))
        type = {...field.type, text: field.type.fixText(text)}
    })
  })
  return type
}

export function getSuggestionsByFieldType(text) {
  const actions = []
  userActions.filter(i => i.parser).forEach(action => {
    Object.values(action.params || {}).forEach(field => {
      if (field.type && field.type.match && field.type.match(text))
        actions.push({...action, text: field.type.fixText(text)}) 
    })
  })
  return actions
}
