import React, { useState, useEffect, useRef } from "react";
import SideMenuSlider from "./Slider";

import "./index.scss";

const SideMenu = ({ locationHash, hashtag, questions }) => {
  const previousHeight = useRef()
  const [itemsAmount, setItemsAmount] = useState(
    questions.length >= 6 ? 6 : questions.length
  );
  const previousAmount = useRef(itemsAmount)
  const [qsts, setQsts] = useState([...questions].slice(0, itemsAmount));
  const [activeItem, setActiveItem] = useState({
    id: locationHash ? locationHash : `${hashtag}1`,
  });
  const [sliderValue, setSliderValue] = useState(1);
  const [start, setStart] = useState(true);

  const scrollToSection = (itemId, behavior) => {
    if (document.getElementById(`${hashtag}${itemId}`)) {
      document
        .getElementById(`${hashtag}${itemId}`)
        .scrollIntoView({ behavior: behavior });
      window.history.pushState(
        null,
        null,
        `${window.location.pathname}${hashtag}${itemId}`
      );
    }
  };

  const getHashId = (hash) => {
    return parseInt(hash.replace(hashtag, ""));
  };

  const changeHandler = (e) => {
    setSliderValue(e.target.value);
    scrollToSection(e.target.value, "instant");
  };

  const removeListener = (type, listener) => {
    window.removeEventListener(type, listener);
  };

  const addListener = (type, listener) => {
    window.addEventListener(type, listener);
  };

  useEffect(() => {
    const onScrollListener = () => {
      const sections = document.querySelectorAll("section");

      let currentSectionId = "";
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        if (window.scrollY >= sectionTop - sectionHeight / 3) {
          currentSectionId = section.getAttribute("id");
        }
      });

      if (activeItem.id !== currentSectionId) {
        if (getHashId(currentSectionId) > qsts[qsts.length - 1].id) {
          setQsts(
            [...questions].slice(
              getHashId(currentSectionId) - previousAmount.current,
              getHashId(currentSectionId)
            )
          );
        } else if (getHashId(currentSectionId) < qsts[0].id) {
          setQsts(
            [...questions].slice(
              getHashId(currentSectionId) - 1,
              getHashId(currentSectionId) + (previousAmount.current - 1)
            )
          );
        }
        setActiveItem({ id: currentSectionId });
      }

      if (currentSectionId !== activeItem.id) {
        setSliderValue(getHashId(currentSectionId));
        setActiveItem({ id: currentSectionId });
      }
    };

    const onStart = () => {
      setStart(false);
      scrollToSection(getHashId(activeItem.id), "smooth");
      removeListener("scroll", onStart);
      addListener("scroll", onScrollListener);
    };

    addListener("scroll", start ? onStart : onScrollListener);

    return () => {
      removeListener("scroll", onScrollListener);
    };
  }, [activeItem, qsts, itemsAmount]);

  useEffect(() => {
    if(previousAmount.current !== itemsAmount){
      if(itemsAmount !== questions.length){
        if (getHashId(activeItem.id) + itemsAmount - 1 > questions.length) {
          setQsts(
            [...questions].slice(
              Math.abs(getHashId(activeItem.id) - itemsAmount),
              getHashId(activeItem.id)
            )
          );
        } else {
          setQsts(
            [...questions].slice(
              getHashId(activeItem.id) - 1,
              getHashId(activeItem.id) + itemsAmount - 1
            )
          );
        }
      } else {
        setQsts(questions)
      }
      previousAmount.current = itemsAmount
    }
  }, [itemsAmount, activeItem]);

  useEffect(() => {
    const queryMatchHandler = (event) => {
      if(previousHeight.current !== window.innerHeight){
        const amount = Math.floor(event.target.innerHeight / 120) - 2;
        if (amount >= 2 && amount <= questions.length) {
          setItemsAmount(amount);
        } else if (amount > questions.length) {
          setItemsAmount(questions.length);
        } else {
          setItemsAmount(2);
        }
        previousHeight.current = event.target.innerHeight
      }
    };

    queryMatchHandler({ target: { innerHeight: window.innerHeight } });
    window.addEventListener("resize", queryMatchHandler);

    return () => {
      removeListener("resize", queryMatchHandler);
    };
  }, []);

  return (
    <aside className={"side-menu-container"}>
      <nav className="nav">
        <SideMenuSlider
          itemsAmount={itemsAmount}
          value={sliderValue}
          onChange={changeHandler}
        />
        <ul className="nav__list">
          {qsts.map((qst) => (
            <li
              className="nav__list-item"
              onClick={() => scrollToSection(qst.id, "smooth")}
              key={qst.id}
            >
              <a
                className={
                  activeItem.id === `${hashtag}${qst.id}` ? "active" : ""
                }
                href={`${hashtag}${qst.id}`}
              >
                {qst.title}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </aside>
  );
};

export default SideMenu;
