import React, { Component } from 'react'
import { List } from 'react-virtualized'
import { SearchListManager } from '../../../../helpers/searchListManager'
import { SearchFilterWords } from '../../../UI/SearchFilterWords'
import MapCircleSelection from '../../../UI/MapCircleSelection'
import SwitcherOnOff from '../../../UI/SwitcherOnOff'
import WS from '../../../../services/websocket'
import util from '../../../../helpers/util'
import config from '../../../../config'
import { App } from '../../../../App'
import './index.scss'
import '../Online/index.scss'

export class CardTornozeladosHistoricoArea extends Component {

  search = new SearchListManager()

  state = {
    circle: null, // {center: [], radius: 0}
    filterText: ``,
    selected: null,
    rows: [],
		showAll: false,
		hoverId: null,
  }

  componentDidMount() {
    this.props.setTitle(`TORNOZELADOS POR RAIO ` + this.getTimeTitle())
    this.props.show()
  }

  componentWillUnmount() {
    if (this.websocket) {
      this.websocket.disconnect()
      this.clearMap()
    }
  }

  getTimeTitle = () => {
    const { date, startTime, endTime } = this.props
    return util.date2str(date) + ` ` + startTime.slice(0,5) + ` - ` + endTime.slice(0,5)
  }

  extractQueryParams = () => {
    const { date, startTime, endTime } = this.props
    const { circle } = this.state
    let query = { date: util.date2sql(date), startTime, endTime }
    if (circle) {
      const { center, radius } = circle
      query = {...query, longitude: center[0], latitude: center[1], radius}
    }
    return query
  }

  clearMap = () => {
    this.cache = {}
    this.search.getItems().forEach(({id}) => {
      this.props.publish(`map::layer.remove`, {name: `trace${id}`})
      this.props.publish(`map::layer.remove`, {name: `pts${id}`})
    })
  }

  createURLWebSocket = (path=``, params={}) => {
    params = {...App.headers, ...params}
    const host = config.gatewayAppWebsocket
    return host + path + `?` + Object.entries(params).map(i => i.join(`=`)).join(`&`)
  }

  downloadStream = () => {
    Object.keys(this.cache || {}).forEach(id => {
      this.props.publish(`map::layer.remove`, {name: `trace${id}`})
      this.props.publish(`map::layer.remove`, {name: `pts${id}`})
    })
    this.cache = {}
    const rows = this.search.setItems([]).applyFilter(``)
    this.setState({rows, hasData: false, errorMessage: null, streaming: true, streamClosed: false}, this.props.show)
    this.clearMap()
    if (this.websocket) {
      this.websocket.disconnect()
    }
    const query = this.extractQueryParams()
    this.websocket = new WS(this.createURLWebSocket(`/justice-monitor/search`, query), {autoconnect: true, autoreconnect: false, card: this})
      .on(`message`,  this.wsMessage)
      .on(`finished`, this.wsClose)
      .on(`error`,    this.wsError)
  }

  wsMessage = json => {
    json.forEach(this.wsUpdateCache)
  }

  wsUpdateCache = data => {
    const { code, collects } = data
    const id = code
    const lines = [collects.map(i => i.position),]
    const points = collects.map((i, index) => {
      return {id: `i${code}-${index}`, code, point: i.position, date: new Date(i.date)}
    })
    const click = this.props.roles.includes(`CEREBRUM_MONITORADOS_DETALHES`) ? this.clickPopup : undefined
    const group = {color: `#F4C94C`, title: `Tornozelados`}
    this.props.publish(`map::layer.add`,  {name: `trace${id}`, lines, group, color: `#fff`, width: 3, gradient: true})
    this.props.publish(`map::layer.add`,  {name: `pts${id}`,  points, group, color: `#F4C94C`, popup: this.createPopup, click})
    //
    const dateA = points[0].date
    const dateB = points[points.length - 1].date
    const timeRange = util.date2time(dateA) + ` ` + util.date2str(dateA) + ` - ` + util.date2time(dateB) + ` ` + util.date2str(dateB)
    const query = code + ` ` + timeRange
    this.cache[id] = {id, code, timeRange, query, collects: points}
    //
    const json = Object.values(this.cache)
    const rows = this.search.setItems(json).applyFilter(this.state.filterText)
    this.setState({rows, errorMessage: null, hasData: true})
		if(!this.state.showAll){
			this.search.getItems().forEach(i => i.hidden = this.state.hoverId ? this.state.hoverId !== i.id : true)
    	this.updateMap()
		}
  }

  wsClose = () => {
    this.setState({streaming: false, streamClosed: true, errorMessage: null})
  }

  wsError = () => {
    this.setState({streaming: false, streamClosed: true, errorMessage: `FALHA AO CONSULTAR DADOS`}, this.updateMap)
  }

  createPopup = i => {
    return { title: i.code, text: util.date2str(i.date) + ` ` + util.date2time(i.date) }
  }

  clickPopup = ({code}) => {
    this.props.openCard(`CardTornozeladosDetalhe`, {id: code})
  }

  updateMap = () => {
    this.search.getItems().forEach(v => {
      this.props.publish(`map::layer.update`, {name: `trace${v.id}`, hidden: v.hidden})
      this.props.publish(`map::layer.update`, {name: `pts${v.id}`, hidden: v.hidden})
    })
  }

  mapSelectionHandler = (circle) => {
    this.props.setLoading()
    this.clearMap()
    this.updateHighlightMarker(null)
    this.setState({selected: null, circle}, () => {
      this.downloadStream()
      this.props.show()
    })
  }

  filterWordsHandler = words => {
    this.updateHighlightMarker(null)
    const filterText = words.join(` `) || ``
    const rows = this.search.applyFilter(filterText)
    this.setState({rows, selected: null, filterText}, this.updateMap)
  }

  tableMouseLeaveHandler = () => {
    if (this.state.selected || !this.state.showAll) {
      return
    }
    this.search.getItems().forEach(i => i.hidden = false)
    this.updateMap()
  }

  rowMouseEnterHandler = (item) => {
    if (this.state.selected) {
      return null
    }
    this.search.getItems().forEach(i => i.hidden = item.id !== i.id)
    this.updateMap()
		this.setState({ hoverId: item.id });
  }

  rowClickHandler = (item) => {
    const selected = item === this.state.selected ? null : item
    this.setState({selected})
    this.search.getItems().forEach(i => i.hidden = item.id !== i.id)
    this.updateMap()
    this.updateHighlightMarker(null)
  }

  updateHighlightMarker = point => {
    this.props.publish(`map::marker.remove`, {id: `highlight`})
    if (point)
      this.props.publish(`map::marker.add`, {id: `highlight`, point, color: `#ca3`, className: `circle nobody border animated`})
  }

  cardMouseLeaveHandler = () => {
    clearTimeout(this.timer)
    this.timer = setTimeout(this.updateHighlightMarker, 2500)
  }

	toggleShowAll = () => {
		if(!this.state.showAll && !this.state.selected){
			this.search.getItems().forEach(i => i.hidden = false)
    	this.updateMap()
		} else if(!this.state.selected) {
			this.search.getItems().forEach(i => i.hidden = true)
    	this.updateMap()
		}

		this.setState({ showAll: !this.state.showAll })
	}

  render() {
    return (
      <div className="CardTornozeladosHistoricoArea" onMouseLeave={ this.cardMouseLeaveHandler }>
        <div className="card-title">
          TORNOZELADOS POR RAIO <span>{ this.getTimeTitle() }</span>
        </div>
        { this.renderSummary() }
        { this.renderRows() }
      </div>
    )
  }

  renderStreamStatus() {
    const { streaming, streamClosed, errorMessage, hasData } = this.state
    if (hasData && errorMessage) {
      return <div className="stream-status error">FALHA AO CONSULTAR DADOS. INFORMAÇÃO INCOMPLETA!</div>
    }
    if (!errorMessage) {
      if (streaming === false && streamClosed === true) {
        return <div className="stream-status done">HISTÓRICO DE COLETAS RECUPERADO</div>
      } else if (streaming === true && !streamClosed) {
        return <div className="stream-status blink">RECUPERANDO HISTÓRICO DE COLETAS...</div>
      }
    }
    return null
  }

  renderSummary() {
    const { rows } = this.state
    const total = this.search.getTotal()
    return (
      <div className="summary">
        <div className="flex-container">
          <div className="flex flex3 right detail">
            <MapCircleSelection onChange={ this.mapSelectionHandler } />
          </div>
        </div>
        <SearchFilterWords subtotal={ rows.length } total={ total } onChange={ this.filterWordsHandler } />
        { this.renderStreamStatus() }
      </div>
    )
  }

  renderRows() {
    const { circle, streaming, rows, hasData, selected, errorMessage } = this.state
    if (!circle) {
      return <p className="message empty">aguardando definição de área no mapa...</p>
    }
    if (streaming && rows.length === 0) {
      return <p className="message empty">carregando...</p>
    }
    if (errorMessage && !hasData) {
      return <p className="message empty">{ errorMessage }</p>
    }
    if (rows.length === 0) {
      return <p className="message empty">nenhum dado encontrado</p>
    }
    return (
      <React.Fragment>
				<br/>
				<SwitcherOnOff label='EXIBIR O HISTÓRICO DE TODOS OS TORNOZELADOS' defaultValue={this.state.showAll} onChange={this.toggleShowAll} />
        <div className='ui-list'>
          <div className='row header'>
            <div className="column code">Código</div>
            <div className="column collects">N° Pontos</div>
            <div className="column time-range">Intervalo de Tempo</div>
          </div>
        </div>
        <div className={ `table-container` + (selected ? ` selected` : ``) } onMouseLeave={ this.tableMouseLeaveHandler }>
          <table className="vehicles">
            <tbody>
              {rows.map(i => (
                <tr key={ i.id } className={ selected && selected.id === i.id ? `selected` : `` } onClick={ this.rowClickHandler.bind(this, i) } onMouseEnter={ this.rowMouseEnterHandler.bind(this, i) }>
                  <td className="date">{ i.code }</td>
                  <td className="collects">{ i.collects.length } pts</td>
                  <td className="text">{ i.timeRange }</td>
                  <td className="close"></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        { selected ? this.renderTableCollects(selected.collects.length) : null }
      </React.Fragment>
    )
  }

  renderTableCollects(countItems) {
    return (
      <div className="table-collects">
        <List width={ 550 } height={ 500 } rowHeight={ 31 } rowCount={ countItems } rowRenderer={ this.renderRowCollect } />
      </div>
    )
  }

  renderRowCollect = ({index, key, style}) => {
    const i = this.state.selected.collects[index]
    return (
      <div key={ i.id } style={ style } className="row" onMouseEnter={ this.updateHighlightMarker.bind(this, i.point) }>
        <div className="time">{ util.date2time(i.date, true) }</div>
        <div className="date">{ util.date2str(i.date) }</div>
        <div className="coord">{ i.point.join(`, `) }</div>
      </div>
    )
  }

}

