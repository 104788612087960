
import React, { Component } from 'react'
import util from '../../../../helpers/util'
import './CarDocument.scss'

const Item = (props) => (
  <div className={ `container-item ` + (props.className || ``) }>
    <div className="item">
      <label>{ props.label }</label>
      <div className="value">{ props.children || `-` }</div>
    </div>
  </div>
)

export default class CarDocument extends Component {

  formatDate = text => text.replace(/^(\d{2})(\d{2})(\d{4})$/, `$1/$2/$3`)

  formatYesNo = text => {
    if (text === `S` || text === `SIM`) return `SIM`
    if (text === `N` || text === `NAO`) return `NÃO`
    return text
  }

  render() {
    if (this.props.hidden) {
      return <div className="CNHVeiculosCarDocument hidden"></div>
    }
    const data = this.props
    return (
      <div className="CNHVeiculosCarDocument">
        { this.renderOwner(data) }
        { this.renderMotor(data) }
        { this.renderAddress(data) }
        { this.renderRenter(data) }
        { this.renderDetails(data) }
      </div>
    )
  }

  renderOwner(d) {
    const docType = d.ownerCpf.length === 11 ? `CPF` : `CNPJ`
    const docNum = docType === `CPF` ? util.formatCPF(d.ownerCpf) : util.formatCNPJ(d.ownerCpf)
    return (
      <div className="group">
        <div className="row">
          <Item label="NOME PROPRIETÁRIO" className="flex2">{ d.ownerName }</Item>
          <Item label={ docType }>{ docNum }</Item>
        </div>
      </div>
    )
  }

  renderMotor(d) {
    const labelPlate = d.isMercosul ? `PLACA MERCOSUL` : `PLACA`
    return (
      <div className="group">
        <div className="row">
          <Item label="CÓD RENAVAM">{ d.renavam }</Item>
          <Item label={ labelPlate }>{ util.formatCarPlateNumber(d.plate) }</Item>
          <Item label="CHASSI">{ d.chassis }</Item>
          <Item label="ANO FAB / MODELO">{ d.fabricationYear } / { d.modelYear }</Item>
        </div>
        <div className="row">
          <Item label="ESPÉCIE / TIPO">{ d.kind } / { d.type }</Item>
          <Item label="COMBUSTÍVEL">{ d.fuel }</Item>
          <Item label="CATEGORIA">{ d.category }</Item>
          <Item label="PASSAGEIROS">{ d.amountPassengers }</Item>
        </div>
        <div className="row">
          <Item label="MOTOR">{ d.vehicleMotor }</Item>
          <Item label="MARCA / MODELO">{ d.markModel.replace(/\//g, ` / `) }</Item>
          <Item label="COR PREDOMINANTE">{ d.color }</Item>
          <Item label="CARROCERIA">{ d.bodyWork }</Item>
        </div>
        { d.details ? this.renderMotorDetails(d.details.vehicle) : null }
      </div>
    )
  }

  renderMotorDetails(d) {
    return (
      <div className="row">
        <Item label="DOCUMENTO">{ d.docNumber }</Item>
        <Item label="POTÊNCIA">{ d.potency }</Item>
        <Item label="CILINDRADA">{ d.cylinders }cm³</Item>
        <Item label="EIXOS">{ d.axisNumber }</Item>
        <Item label="PESO">{ d.weight }</Item>
        <Item label="CAPACIDADE MÁX.">{ d.chargeCapacity }</Item>
      </div>
    )
  }

  renderDetails({details}) {
    if (!details) {
      return null
    }
    const d = details
    return (
      <div className="group">
        <div className="row">
          <Item label="ANO EXERCÍCIO">{ d.exerciseYear }</Item>
          <Item label="DATA ALTERAÇÃO">{ this.formatDate(d.alterationDate) }</Item>
          <Item label="LICENCIAMENTO">{ this.formatDate(d.licensingDate) }</Item>
          <Item label="EM DÉBITO">{ this.formatYesNo(d.licensingDebit) }</Item>
        </div>
        <div className="row">
          <Item label="IPVA GRÁTIS">{ d.freeIpva }</Item>
          <Item label="IPVA EM DÉBITO">{ this.formatYesNo(d.debitoIpva) }</Item>
          <Item label="QTD MULTAS">{ d.amountFaults }</Item>
          <Item label="QTD RESTRIÇÕES">{ d.amountRestricts }</Item>
        </div>
        { this.renderTheft(d.vehicle.theft) }
        <div className="row">
          <Item label="PROPRIETÁRIO ANTERIOR">{ (d.lastOwnerName || `NÃO INFORMADO`).toUpperCase() } - { d.lastOwnerCpfCgc ? util.formatCPF(d.lastOwnerCpfCgc) + ` - ` : `` } { d.lastOwnerUf }</Item>
        </div>
      </div>
    )
  }

  renderTheft(data) {
    if (!data) {
      return null
    }
    return (
      <div className="row">
        <Item label="ROUBADO" className="yellow">
          { data.type }{ data.date ? ` ${data.date}` : `` }
          <span>-</span>
          { data.message }
        </Item>
      </div>
    )
  }

  renderAddress(d) {
    if (!d.details || !d.details.address) {
      return null
    }
    const i = d.details.address
    const city = [i.city, i.uf].filter(i => i).join(` - `) || `-`
    let address = i.address
    if (i.addressComplement)
      address += `, ` + i.addressComplement
    if (i.district)
      address += ` - ` + i.district
    return (
      <div className="group">
        <div className="row">
          <Item label="ENDEREÇO">{ address }</Item>
          <Item label="CEP">{ util.formatCEP(i.cepNumber) }</Item>
          <Item label="CIDADE">{ city }</Item>
        </div>
      </div>
    )
  }

  renderRenter(d) {
    const name = d.renterName
    const doc  = d.renterCpf
    if (!name || name.length === 0) {
      return null
    }
    const docNum = doc.length === 11 ? util.formatCPF(doc) : util.formatCNPJ(doc)
    return (
      <div className="group">
        <div className="row">
          <Item label="ARRENDATÁRIO" className="flex2">{ name }</Item>
          <Item label="CPF">{ docNum }</Item>
        </div>
      </div>
    )
  }

}

