import React from 'react'
// import { auth } from '../auth' 
import './Button.scss'

export default class Button extends React.Component {

  constructor(props) {
    super(props)
    //
    const label = this.props.label || `Autenticar`
    const style = this.props.style || {}
    const className = [
      `spi-oauth2-button`,
      this.props.theme,
      this.props.className,
    ].filter(_ => _).join(` `)
    //
    this.state = { className, style, label }
  }

  render() {
    const { className, style, label } = this.state
    return (
      <button className={ className } style={ style }>
        <div className="column icon"></div>
        <div className="column text">
          <div className="main">{label}</div>
          <div className="help">PLATAFORMA SPI</div>
        </div>
      </button>
    )
  }

}
