import React, { useEffect, useState, useRef } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PdfDocument } from "../PdfDocument";
import { publish } from "../../services/EventBus";
import Jimp from "jimp/es";
import { App } from '../../App'
import config from '../../config'
import util from "../../helpers/util";
import srcImageAvatar from "../../images/avatar.png";
import "./index.scss";

export function ReportConfig(props) {
  const bases2propsData = {}
  const bases = [
    "Histórico Civil",
    "Habilitação",
    "Veículos",
    "ENEL",
    "Registros Policiais",
    "SISPEN Visitante",
    "SISPEN Apenado",
  ].filter((base) => {
    const { roles } = props
    let result
    switch (base) {
      case "Histórico Civil":
          result = roles.includes(`CEREBRUM_BUSCA_CIVIL`)
          if(result)
            bases2propsData[base] = "identidades"
        break;
      case "Habilitação":
        result = roles.includes(`CEREBRUM_BUSCA_CNH_CPF`)
        if(result)
          bases2propsData[base] = "cnh"
        break;
      case "Veículos":
        result = roles.includes(`CEREBRUM_BUSCA_VEICULOS_CPF_CNPJ`)
        if(result)
          bases2propsData[base] = "vehicles"
        break;
      case "ENEL":
        result = roles.includes(`CEREBRUM_BUSCA_ENEL`)
        if(result)
          bases2propsData[base] = "enelList"
        break;
      case "Registros Policiais":
        result = roles.includes(`CEREBRUM_BUSCA_CRIMINAL`)
        if(result)
          bases2propsData[base] = "criminalDetails"
        break;
      case "SISPEN Visitante":
        result = roles.includes(`CEREBRUM_BUSCA_SISPEN`)
        if(result)
          bases2propsData[base] = "sispenVisitor"
        break;
      case "SISPEN Apenado":
        result = roles.includes(`CEREBRUM_BUSCA_SISPEN`)
        if(result)
          bases2propsData[base] = "sispenApenado"
        break;
      default:
        result = false
        break;
    }
    return result
  });

  const checksSetup = (value) => {
    const basesCheck = {};
    bases.forEach((base) => {
      basesCheck[base] = value;
    });
    return basesCheck;
  };

  const {
    show,
    http,
    hash,
    identifiers,
    data,
    generateReport,
    data2Print,
    origin,
    backAction,
    roles,
  } = props;
  const { vehicles, enelList, criminalDetails, criminalSearch, enelListDone, sispenApenado, sispenApenadoSections, sispenCollects2Print } =
    data || {};
  const [checkboxes, setCheckboxes] = useState(checksSetup(true));
  const [loading, setLoading] = useState(false);
  const [ready2Print, setReady2Print] = useState(false);
  const [errors, setErrors] = useState({});
  const [retryErrorList, setRetryErrorList] = useState([])

  const [criminalItem, setCriminalItem] = useState(-1);
  const [filterCriminal, setFilterCriminal] = useState(false);
  const [criminalDone, setCriminalDone] = useState(false);

  const [enelPage, setEnelPage] = useState(-1);
  const [enelDone, setEnelDone] = useState(false);

  const [sispenDone, setSispenDone] = useState(false);
  const [innerSispenLoad, setInnerSispenLoad] = useState(false);

  const [vehiclesPage, setVehiclesPage] = useState(-1);
  const [vehiclesDone, setVehiclesDone] = useState(false);

  const [title, setTitle] = useState("");
  const [info, setInfo] = useState("");

  const firstUpdate = useRef(true);

  useEffect(() => {
    show();
  }, []);

  const ready = () => {
    let checkboxCount = 0;
    let dataCount = 0;
    for (let checkbox in checkboxes) {
      if (checkboxes[checkbox]) {
        checkboxCount++;
      }
    }

    for (let checkbox in checkboxes) {
      if (checkboxes[checkbox] && data && data[bases2propsData[checkbox]] && !retryErrorList.includes(checkbox)) {
        dataCount++;
      }
    }

    return (
      checkboxCount > 0 &&
      checkboxCount === dataCount &&
      (!bases.includes("SISPEN Apenado") || sispenDone) &&
      (!bases.includes("ENEL") || enelDone) &&
      (!bases.includes("Veículos") || vehiclesDone) &&
      (!bases.includes("Registros Policiais") || criminalDone)
    );
  };

  const tranformKeys = (bases) => {
    const checkedBases = [];
    for (let base in bases) {
      if (bases[base]) {
        checkedBases.push(bases2propsData[base]);
      }
    }
    return checkedBases;
  };

  const extractRG = (json) => {
    let rg = ``;
    if (json.rg) rg += json.rg + ` `;
    if (json.rgEmitter) rg += json.rgEmitter + ` `;
    if (json.federalUnitRgEmitter) rg += json.federalUnitRgEmitter;
    return rg.trim();
  };

  const extractCPF = (json) => {
    if (json.cpf)
      return json.cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
  };

  const countCheckValidation = (param) => {
    const count = Object.values(param.props.infoValidation).filter(
      (rowValue) => rowValue
    );
    return count.length;
  };

  const createRow = (json) => {
    const name = json.regName;
    const { aliases, picture, mother, father } = json;
    const birthday = json.birthday ? util.date2str(json.birthday) : null;
    const rg = extractRG(json);
    const cpf = extractCPF(json);
    const lines = [];
    if (aliases && aliases.length) {
      const alcunha = aliases
        .filter((i) => i && i !== `SEM ALCUNHA`)
        .join(`, `)
        .toUpperCase();
      if (alcunha) lines.push([{ label: `ALCUNHA`, value: alcunha }]);
    }
    lines.push([
      { label: `RG`, value: rg },
      { label: `CPF`, value: cpf },
    ]);
    lines.push([{ label: `Data de Nascimento`, value: birthday }]);
    lines.push([{ label: `Mãe`, value: (mother || ``).toUpperCase() }]);
    lines.push([{ label: `Pai`, value: (father || ``).toUpperCase() }]);
    return {
      card: `CardCriminalHistorico`,
      props: json,
      //
      image: picture || `avatar.png`,
      title: name,
      lines,
    };
  };

  const createSispenVisitorRow = (json) => {
    const { thumb, name, sex, rg, cpf, birthDate, id } = json;
    return {
      card: `CardSispenVisitante`,
      props: cpf ? { ...json } : { ...json, thumbDefault: srcImageAvatar },
      //
      image: thumb || `avatar.png`,
      title: `ID ` + id + ` - ` + name,
      lines: [
        [
          { label: `RG`, value: rg },
          { label: `CPF`, value: cpf ? util.formatCPF(cpf) : `` },
        ],
        [
          {
            label: `Sexo`,
            value: sex ? (sex === `F` ? `Feminino` : `Masculino`) : ``,
          },
          {
            label: `Data de Nascimento`,
            value: util.date2str(util.str2date(birthDate)),
          },
        ],
      ],
      fields: [
        {
          label: `Nasc`,
          value: birthDate ? util.date2str(util.str2date(birthDate)) : null,
        },
        { label: `Nome`, value: name },
        { label: `RG`, value: rg ? rg : null },
        { label: `CPF`, value: cpf ? util.formatCPF(cpf) : null },
      ],
    };
  };

  const matchProp = ({ cpf, rg, civilCPF, civilRG, item }) => {
    if (cpf && civilCPF[0] === cpf) {
      item.isChecked = true;
      return item;
    }
    if (rg && civilRG[0] === rg) {
      item.isChecked = true;
      return item;
    }
    return false;
  };

  const getCPFNumbers = (identities) => {
    const numbers = {};
    identities.forEach((i) => {
      if (
        i.documentInfo &&
        i.documentInfo.cpf &&
        i.documentInfo.cpf.length > 0
      ) {
        const { cpf } = i.documentInfo;
        numbers[cpf] = true;
      }
    });
    return Object.keys(numbers);
  };

  const getRGNumbers = (identities) => {
    const numbers = {};
    identities.forEach((i) => {
      if (i.rgInfo) {
        numbers[i.rgInfo.rg] = true;
      }
    });
    return Object.keys(numbers);
  };

  useEffect(() => {
    if (ready()) {
      generateReport({
        bases: tranformKeys(checkboxes),
        subHeader: { title, info },
      });
    }
  }, [data, enelDone, vehiclesDone, criminalDone, sispenDone]);

  useEffect(() => {
    if(sispenCollects2Print || (sispenApenado && (util.objIsEmpty(sispenApenado) || sispenApenado.error)))
      setSispenDone(true)
  }, [sispenCollects2Print, sispenApenado]);

  useEffect(() => {
    if(innerSispenLoad && (sispenApenado && (!util.objIsEmpty(sispenApenado) && !sispenApenado.error)))
      formatImage()
  }, [sispenApenado]);

  const formatImage = async () => {
    if (
      sispenApenadoSections &&
      sispenApenadoSections.CardSispenColetas &&
      !sispenCollects2Print
    ) {
      const collects = await Promise.all(
        sispenApenadoSections.CardSispenColetas.collects.map(async (item) => {
          const { fingerprints, bodyMarks, photos, tattoos } = item.type;
          const sections = { fingerprints, bodyMarks, photos, tattoos };
          const data = {};
          for (let section in sections) {
            const sectionData = await Promise.all(
              sections[section].map(async (i) => {
                const formatPath = new Promise((resolve) => {
                  Jimp.read(i.path)
                    .then((image) => {
                      image
                        .getBase64Async(Jimp.MIME_JPEG)
                        .then((result) => {
                          resolve(result);
                        })
                        .catch((error) => {
                          resolve({error: error, message: 'Ocorreu um erro. Não foi possível carregar esta imagem.'});
                        });
                    })
                    .catch((error) => {
                      resolve({error: error, message: 'Ocorreu um erro. Não foi possível carregar esta imagem.'});
                    });
                });
                const path = await formatPath;
                return { ...i, path };
              })
            );
            data[section] = sectionData;
          }
          return { ...item, type: data };
        })
      );
      publish(`card::set-identifiers`, {
        hash: hash,
        data: { sispenCollects2Print: collects },
      });
    } else {
      setSispenDone(true);
    }
  };

  useEffect(() => {
    if (ready()) {
      if (data2Print) {
        setLoading(false);
        setReady2Print(true);
      }
    }
  }, [data2Print]);

  useEffect(() => {
    if (vehicles && vehicles.length > 0 && checkboxes["Veículos"]) {
      setVehiclesDone(true);
    }
  }, [vehicles]);

  useEffect(() => {
    if (enelList && enelList.length > 0 && checkboxes["ENEL"]) {
      setEnelPage(enelPage + 1);
    }
  }, [enelList]);

  useEffect(() => {
    if (
      !firstUpdate.current &&
      checkboxes["Registros Policiais"] &&
      criminalSearch &&
      criminalSearch.length > 0 &&
      !criminalDetails
    ) {
      setCriminalItem(0);
    } else {
    }
  }, [criminalSearch]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (checkboxes["Registros Policiais"] && criminalDetails) {
      let rest = 0;
      const detailsItems = criminalDetails.filter(({ details }) => {
        const searchItems = criminalSearch.map(({ regNumber }) => {
          return regNumber;
        });

        if (searchItems.includes(details.regNumber)) {
          return true;
        }
        rest++;
        return false;
      });

      console.log(
        detailsItems.length,
        "/",
        criminalSearch.length,
        "REST:",
        rest
      );

      console.log("REST", rest);

      if (detailsItems.length < criminalSearch.length) {
        setCriminalItem(criminalItem + 1);
      } else if (rest > 0) {
        console.log("OUT - criminalDetails", criminalDetails, criminalSearch);
        publish(`card::set-identifiers`, {
          hash: hash,
          data: { criminalDetails: detailsItems },
        });
      } else {
        setCriminalDone(true);
      }
    } else {
    }
  }, [criminalDetails, filterCriminal]);

  useEffect(() => {
    if (criminalItem >= 0) {
      const { regNumber } = criminalSearch[criminalItem];
      const detailsItem = criminalDetails
        ? criminalDetails.find((itemDetails) => {
            const { details } = itemDetails;
            return details.regNumber === regNumber;
          })
        : null;
      if (!detailsItem) {
        http(`/criminal/details/` + regNumber).then((details) => {
          const { error } = details;
          if (error) {
            const searchItem = criminalSearch.find(
              (item) => item.regNumber === regNumber
            );
            const detailsObj = {
              error,
              details: searchItem,
              openArrestWarrants: [],
              arrests: [],
              recap: [],
              images: [],
              aliases: [],
            };
            const criminalData = criminalDetails
              ? [...criminalDetails, detailsObj]
              : [detailsObj];
            publish(`card::set-identifiers`, {
              hash: hash,
              data: { criminalDetails: criminalData },
            });
          } else {
            const criminalData = criminalDetails
              ? [...criminalDetails, details]
              : [details];
            publish(`card::set-identifiers`, {
              hash: hash,
              data: { criminalDetails: criminalData },
            });
          }
        });
      } else {
        if (criminalItem < criminalSearch.length - 1) {
          setCriminalItem(criminalItem + 1);
        } else {
          console.log("FILTER");
          setFilterCriminal(true);
        }
      }
    }
  }, [criminalItem]);

  useEffect(() => {
    if (enelListDone) {
      setEnelDone(true);
      return;
    }
    if (enelPage >= 0) {
      const { cpf, cnpj } = identifiers;
      let request = http(`/enel/cpf/${cpf}`, { page: enelPage });
      if (cnpj) request = http(`/enel/cnpj/${cnpj}`, { page: enelPage });
      request.then((enelList) => {
        const { error } = enelList;
        if (error) {
          publish(`card::set-identifiers`, {
            hash: hash,
            data: { enelList: [], enelListDone: true },
          });
          return setEnelDone(true);
        }
        if (enelList.length > 0) {
          enelList.forEach((i) => {
            i.addressText = [i.address, i.district, i.city]
              .filter((s) => s)
              .join(` - `);
            i.query = (i.name + ` ` + i.addressText).toUpperCase();
          });
          const updatedData =
            data && data.enelList ? [...data.enelList, ...enelList] : enelList;
          return publish(`card::set-identifiers`, {
            hash: hash,
            data: { enelList: updatedData },
          });
        }
        const enelData =
          enelPage === 0
            ? { enelList: [], enelListDone: true }
            : { enelListDone: true };
        publish(`card::set-identifiers`, {
          hash: hash,
          data: enelData,
        });
      });
    }
  }, [enelPage, enelListDone]);

  useEffect(() => {
    if (vehiclesPage >= 0) {
      const { idDetran } = identifiers;
      http(`/detran/vehicles`, {
        identifier: idDetran,
        page: vehiclesPage,
      }).then((vehicles) => {
        const { error } = vehicles;
        if (error) {
          publish(`card::set-identifiers`, {
            hash: hash,
            data: { vehicles: [] },
          });
          return setVehiclesDone(true);
        }
        if (vehicles.length > 0) {
          const updatedData =
            data && data.vehicles ? [...data.vehicles, ...vehicles] : vehicles;
          return publish(`card::set-identifiers`, {
            hash: hash,
            data: { vehicles: updatedData },
          });
        }
        setVehiclesDone(true);
      });
    }
  }, [vehiclesPage]);

  const removeError = (fields) => {
    const updateErrors = {};
    for (let error in errors) {
      if (fields.indexOf(error) === -1) {
        updateErrors[error] = errors[error];
      }
    }
    setErrors(updateErrors);
  };

  useEffect(() => {
    removeError(getErrors());

    setReady2Print(false);
    setVehiclesDone(false);
    setEnelDone(false);
    setSispenDone(false);
  }, [checkboxes, title, info]);

  const getErrors = () => {
    const errorFields = [];
    let hasCheck = false;
    for (let checkbox in checkboxes) {
      if (checkboxes[checkbox]) {
        hasCheck = true;
      }
    }

    if (hasCheck) {
      errorFields.push("checkboxes");
    }

    if (title) {
      errorFields.push("title");
    }

    return errorFields;
  };

  const searchEmptyFields = () => {
    let hasCheck = false;
    const errorFields = [];

    for (let checkbox in checkboxes) {
      if (checkboxes[checkbox]) {
        hasCheck = true;
      }
    }

    if (!title) {
      errorFields.push({
        title: {
          message: "Você precisa de um título para o seu relatório.",
        },
      });
    }

    if (!hasCheck) {
      errorFields.push({
        checkboxes: {
          message: "Selecione pelo menos uma base para gerar o relatório.",
        },
      });
    }

    return errorFields;
  };

  const criminalSearchSetup = (criminalSearch, identities) => {
    const civilCPF = getCPFNumbers(identities);
    const civilRG = getRGNumbers(identities);
    const criminalReports = [];
    const allRows = [];

    criminalSearch.forEach((item) => {
      const infoValidation = {
        CPF: false,
        RG: false,
        regName: false,
        Pai: false,
        Mãe: false,
        "Data de Nascimento": false,
      };

      const { cpf, rg, regName, father, mother, birthday } = item;

      const result = matchProp({ cpf, rg, civilCPF, civilRG, item });
      if (result) criminalReports.push(result);

      const props2Check = { cpf, rg, regName, father, mother, birthday };

      for (let prop in props2Check) {
        switch (prop) {
          case "cpf":
            if (cpf && civilCPF[0] === cpf) {
              infoValidation["CPF"] = true;
            }
            break;
          case "rg":
            if (rg && civilRG[0] === rg) {
              infoValidation["RG"] = true;
            }
            break;
          case "regName":
            if (regName && identities[0].basicInfo.name === regName) {
              infoValidation["regName"] = true;
            }
            break;
          case "father":
            if (father && identities[0].basicInfo.father === father) {
              infoValidation["Pai"] = true;
            }
            break;
          case "mother":
            if (mother && identities[0].basicInfo.mother === mother) {
              infoValidation["Mãe"] = true;
            }
            break;
          case "birthday":
            if (birthday && identities[0].basicInfo.birthDate === birthday) {
              infoValidation["Data de Nascimento"] = true;
            }
            break;
          default:
            break;
        }
      }

      item.infoValidation = infoValidation;
      allRows.push(createRow(item));
    });

    const rows = allRows.sort(
      (a, b) => countCheckValidation(b) - countCheckValidation(a)
    );

    publish(`card::set-identifiers`, {
      hash: hash,
      data: { rows },
    });

    if (criminalReports.length > 0) {
      publish(`card::set-identifiers`, {
        hash: hash,
        data: { criminalSearch: criminalReports },
      });
    } else {
      publish(`card::set-identifiers`, {
        hash: hash,
        data: { criminalSearch: [], criminalDetails: [] },
      });
    }
  };

  const loadPossibilities = (identities, resolve, reject) => {
    identities.sort(
      (a, b) =>
        new Date(b.rgInfo.identityDate || Date.now()).getTime() -
        new Date(a.rgInfo.identityDate).getTime()
    );
    const name = identities[0].basicInfo.name;

    http(`/sispen/visitors`, { name }).then((data) => {
      if (data.error) return reject();
      const civilCPF = getCPFNumbers(identities);
      const civilRG = getRGNumbers(identities);

      let report = {};
      const rows = [];

      data.forEach((item) => {
        const infoValidation = {
          CPF: false,
          RG: false,
          Nome: false,
          Sexo: false,
          "Data de Nascimento": false,
        };

        const { name, sex, cpf, rg, birthDate } = item;

        if (cpf) {
          if (civilCPF[0] === cpf) {
            item.isChecked = true;
            report = item;
          } else if (rg) {
            if (civilRG[0] === rg) {
              item.isChecked = true;
              report = item;
            }
          }
        } else if (rg) {
          if (civilRG[0] === rg) {
            item.isChecked = true;
            report = item;
          }
        }

        if (cpf) {
          if (civilCPF[0] === cpf) {
            infoValidation["CPF"] = true;
          }
        }
        if (rg) {
          if (civilRG[0] === rg) {
            infoValidation["RG"] = true;
          }
        }
        if (name) {
          if (identities[0].basicInfo.name === name) {
            infoValidation["Nome"] = true;
          }
        }
        if (sex) {
          if (identities[0].basicInfo.sex === sex) {
            infoValidation["Sexo"] = true;
          }
        }
        if (birthDate) {
          if (identities[0].basicInfo.birthDate === birthDate) {
            infoValidation["Data de Nascimento"] = true;
          }
        }

        item.infoValidation = infoValidation;
        const row = createSispenVisitorRow(item);
        rows.push(row);
      });

      rows.sort((a, b) => countCheckValidation(b) - countCheckValidation(a));
      publish(`card::set-identifiers`, {
        hash,
        data: { sispenVisitorRows: rows },
      });

      if (report.id) {
        rows.forEach((row, index) => {
          if (row.props.id === report.id) {
            publish(`card::set-identifiers`, {
              hash,
              data: { sispenVisitorRowIndex: index },
            });
          }
        });
        const url = `/sispen/visitors/${report.id}/visits`;
        return http(url).then((data) => {
          const { id, name, sex, cpf, rg, birthDate, thumb, thumbDefault } =
            report;
          const visitorResponse = {
            id,
            name,
            sex,
            cpf,
            rg,
            birthDate,
            thumb,
            thumbDefault,
          };
          let visitsMade = data;
          if (data.error) {
            visitsMade = [];
            visitorResponse.error = data.error;
          }
          visitorResponse.visitsMade = visitsMade;
          resolve(visitorResponse);
        });
      }
      reject();
    });
  };

  const possibilitiesSearch = (rg, resolve, reject, error) => {
    const response = error ? { error } : {};
    if (rg) {
      return http(`/civil/details`, { rg }).then((identities) => {
        if (identities.error) {
          return reject();
        }
        if (identities.length > 0) {
          return loadPossibilities(identities, resolve, reject);
        }
        reject();
      });
    }
    reject(response);
  };

  const loadSispenVisitorData = () => {
    return new Promise((resolve, reject) => {
      const {
        idSispenVisitante: id,
        name,
        sex,
        cpf,
        rg,
        birthDate,
        thumb,
        thumbDefault,
      } = identifiers;
      const searchByCpf = cpf && !id && !name;
      if (cpf || id) {
        const url =
          `/sispen/visitors` + (searchByCpf ? `/${cpf}` : `/${id}/visits`);
        return http(url)
          .then((data) => {
            const { error } = data;
            if (error) return possibilitiesSearch(rg, resolve, reject, error);
            resolve(
              searchByCpf
                ? data
                : {
                    id,
                    name,
                    sex,
                    cpf,
                    rg,
                    birthDate,
                    thumb,
                    thumbDefault,
                    visitsMade: data,
                  }
            );
          })
          .catch(reject);
      }
      possibilitiesSearch(rg, resolve, reject);
    });
  };

  const func = {
    sispenApenadoVisits: {
      request: async (id) => {
        return Promise.resolve(
          http(`/sispen/details/${id}/visits`).then((data) => {
            return data;
          })
        );
      },

      baseRequest: async (id) => {
        return new Promise((resolve, reject) => {
          return id
            ? func.sispenApenadoVisits.request(id).then((response) => {
                return response.error
                  ? reject(response.error)
                  : resolve(response);
              })
            : reject(false);
        });
      },

      errorHandler: (error) => {
        error
          ? console.log(`Error to load visits at SISPEN Apenado.`, error)
          : console.log(`idSipenApenado not found. Unable to load visits.`);

        publish(`card::set-identifiers`, {
          hash: hash,
          data: { sispenApenadoVisits: {error} },
        });
      },

      successHandler: (response) =>
        func.sispenApenadoVisits.dataSetup(response),

      dataSetup: (data) => {
        const visits = data.map((i) => {
          i.id = i.visitorID;
          i.sex = func.sispenApenadoVisits.formatSex(i.sex);
          i.birthDate = i.birthDate ? util.str2date(i.birthDate) : null;
          i.linkDate = i.linkDate ? util.str2date(i.linkDate) : null;
          return i;
        });
        visits.sort((a, b) => (a.linkDate > b.linkDate ? -1 : +1));
        publish(`card::set-identifiers`, {
          hash: hash,
          data: { sispenApenadoVisits: visits },
        });
      },

      formatSex: (text) => {
        if (text === `M`) return `MASCULINO`;
        if (text === `F`) return `FEMININO`;
        if (text === `I`) return `INDEFINIDO`;
        return typeof text === `string` && text.length ? text : `-`;
      },
    },

    sispenApenado: {
      request: async (url) => {
        return Promise.resolve(
          http(url).then((data) => {
            return data;
          })
        );
      },

      baseRequest: async (url) => {
        return new Promise((resolve, reject) => {
          return url
            ? func.sispenApenado.request(url).then((response) => {
                return response.error
                  ? reject({ error: response.error, url })
                  : resolve(response);
              })
            : reject(false);
        });
      },

      errorHandler: (e) => {
        if(!e) {
          publish(`card::set-identifiers`, {
            hash: hash,
            data: {sispenApenado: {}},
          });
          return;
        }
        const { error, url } = e
        const { cpf } = identifiers;
        if (error && error.status === 404) {
          if (url.includes(`rg`) && cpf) {
            func.sispenApenado
              .baseRequest(func.sispenApenado.extractUrlFromProps(true))
              .then(func.sispenApenado.successHandler, func.sispenApenado.errorHandler);
            return;
          }
        }
        setRetryErrorList(retryErrorList.filter((base) => {
          return base !== "SISPEN Apenado"
        }))
        publish(`card::set-identifiers`, {
          hash: hash,
          data: {sispenApenado: {error}},
        });
      },

      successHandler: (response) => func.sispenApenado.dataSetup(response),
      
      fixAssetPath: (path) => {
        const host = config.gatewayApp.replace(/\/$/, ``)
        const queryString = Object.entries({...App.headers}).map(i => i.join(`=`)).join(`&`)
        return `${host}/sispen/assets/${path.replace(/^\//,'')}?${queryString}`
      },
    
      extractCollects: (jsonList) => {
        const collects = jsonList.map(i => {
          return {
            id: i.id,
            date: new Date(i.date),
            type: {
              fingerprints: [...i.fingerprints],
              tattoos: [...i.tattoos],
              photos: [...i.photos],
              voices: [...i.voices],
              videos: [...i.videos],
              bodyMarks: [...i.bodyMarks],
            }
          }
        }).sort((a,b) => a.date.getTime() - b.date.getTime())
        // fix path and description
        collects.forEach(c => {
          Object.entries(c.type).forEach(tuple => {
            const [ type, items ] = tuple
            items.forEach(i => {
              i.path = func.sispenApenado.fixAssetPath(i.path)
              if ([`tattoos`, `bodyMarks`].includes(type)) {
                i.text = [i.bodyRegion || ``, i.category || ``, i.subCategory || ``, i.color || ``, i.description || ``].filter(_ => _.length).join(`, `)
              } else if (type === `videos`) {
                i.text = [i.factionName || ``, i.description || `SEM DESCRIÇÃO`].filter(_ => _.length).join(` - `)
              } else {
                i.text = (!i.category && !i.description) ? `SEM DESCRIÇÃO` : i.description
              }
              if ([`voices`, `videos`].includes(type)) {
                i.text = i.id + ` - ` + i.text
              }
            })
          })
        })
        return collects
      },

      dataSetup: (data) => {
        const extraCards = {};
        //
        const { id, name } = data.person;
        //
        if (roles.includes(`CEREBRUM_VISITAS_SISPEN`)) {
          extraCards[`CardSispenVisitasRecebidas`] = { id, name };
          func.sispenApenadoVisits
            .baseRequest(id)
            .then(
              func.sispenApenadoVisits.successHandler,
              func.sispenApenadoVisits.errorHandler
            );
        }
        if (data.collects && data.collects.length) {
          const collects = func.sispenApenado.extractCollects(data.collects)
          extraCards[`CardSispenColetas`] = { ...data, collects, id };
        }
        const { legalComments, futureClaims, additionalObservations } = data;
        if (
          (legalComments &&
            (data.legalComments.currentHistoricalCriminal ||
              data.legalComments.criminalRecord)) ||
          futureClaims ||
          additionalObservations
        ) {
          const params = {
            id,
            legalComments,
            futureClaims,
            additionalObservations,
          };
          extraCards[`CardSispenObservacoes`] = params;
        }
        extraCards[`CardSispenCriminal`] = { ...data, id };
        setInnerSispenLoad(true)
        setRetryErrorList(retryErrorList.filter((base) => {
          return base !== "SISPEN Apenado"
        }))
        publish(`card::set-identifiers`, {
          hash: hash,
          data: { sispenApenado: data, sispenApenadoSections: extraCards },
        });
      },

      extractUrlFromProps: (useCpf = false) => {
        const { idSispenApenado: id, cpf, rg } = identifiers;
        const base = `/sispen/details/`;
        if (useCpf) return base + `cpf/` + cpf;
        if (id) return base + id;
        if (rg) return base + `rg/` + rg;
        if (cpf) return base + `cpf/` + cpf;
        return false;
      },
    },
  };

  const loadSispenApenadoData = () => {
    const url = func.sispenApenado.extractUrlFromProps();
    func.sispenApenado
      .baseRequest(url)
      .then(func.sispenApenado.successHandler, func.sispenApenado.errorHandler);
  };

  const getBasesData = () => {
    const emptyFields = searchEmptyFields();

    let fields = {};
    emptyFields.forEach((e) => {
      fields = { ...fields, ...e };
    });

    if (emptyFields.length > 0) {
      return setErrors({
        ...errors,
        ...fields,
      });
    }

    setLoading(true);
    let loadData = [];
    for (let checkbox in checkboxes) {
      if (
        checkboxes[checkbox] &&
        (data === undefined || (data[bases2propsData[checkbox]] === undefined || data[bases2propsData[checkbox]].error))
      ) {
        if(data && data[bases2propsData[checkbox]] && data[bases2propsData[checkbox]].error){
          setRetryErrorList([...retryErrorList, checkbox])
        }
        const { cpf, cnpj, rg, idDetran } = identifiers;
        switch (checkbox) {
          case "Histórico Civil":
            console.log("Histórico Civil");
            loadData.push(checkbox);
            if (cpf || rg) {
              http(`/civil/details`, { cpf, rg }).then((identidades) => {
                const { error } = identidades;
                if (error) {
                  setRetryErrorList(retryErrorList.filter((base) => {
                    return base !== checkbox
                  }))
                  publish(`card::set-identifiers`, {
                    hash: hash,
                    data: { identidades: {error} },
                  });
                } else {
                  setRetryErrorList(retryErrorList.filter((base) => {
                    return base !== checkbox
                  }))
                  publish(`card::set-identifiers`, {
                    hash: hash,
                    data: { identidades },
                  });
                }
              });
            } else {
              publish(`card::set-identifiers`, {
                hash: hash,
                data: { identidades: [] },
              });
            }
            break;
          case "Habilitação":
            console.log("Habilitação");
            loadData.push(checkbox);
            if (idDetran) {
              http(`/detran/cnh/` + idDetran).then((cnh) => {
                const { error } = cnh;
                if (error) {
                  setRetryErrorList(retryErrorList.filter((base) => {
                    return base !== checkbox
                  }))
                  publish(`card::set-identifiers`, {
                    hash: hash,
                    data: { cnh: {error} },
                  });
                } else {
                  setRetryErrorList(retryErrorList.filter((base) => {
                    return base !== checkbox
                  }))
                  publish(`card::set-identifiers`, {
                    hash: hash,
                    data: cnh.cnhNumber ? { cnh } : { cnh: {} },
                  });
                }
              });
            } else {
              publish(`card::set-identifiers`, {
                hash: hash,
                data: { cnh: {} },
              });
            }
            break;
          case "Veículos":
            console.log("Veículos");
            loadData.push(checkbox);
            if (idDetran) {
              http(`/detran/vehicles/count`, { identifier: idDetran }).then(
                (vehiclesCount) => {
                  const { error } = vehiclesCount;
                  if (error) {
                    publish(`card::set-identifiers`, {
                      hash: hash,
                      data: { vehiclesCount: {}, vehicles: [] },
                    });
                    setVehiclesDone(true);
                  } else {
                    if (vehiclesCount.count > 0) {
                      publish(`card::set-identifiers`, {
                        hash: hash,
                        data: { vehiclesCount },
                      });
                      setVehiclesPage(0);
                    } else {
                      publish(`card::set-identifiers`, {
                        hash: hash,
                        data: { vehiclesCount: {}, vehicles: [] },
                      });
                      setVehiclesDone(true);
                    }
                  }
                }
              );
            } else {
              publish(`card::set-identifiers`, {
                hash: hash,
                data: { vehiclesCount: {}, vehicles: [] },
              });
              setVehiclesDone(true);
            }
            break;
          case "ENEL":
            console.log("ENEL");
            loadData.push(checkbox);
            if (cpf || cnpj) {
              setEnelPage(0);
            } else {
              publish(`card::set-identifiers`, {
                hash: hash,
                data: { enelList: [], enelListDone: true },
              });
              setEnelDone(true);
            }
            break;
          case "Registros Policiais":
            console.log("Registros Policiais");
            loadData.push(checkbox);
            if (cpf || rg) {
              const { identidades: identities = [] } = data || {};
              let name = "";
              if (identities.length > 0) {
                name = identities[0].basicInfo.name;
                http(`/criminal/search`, { name }).then((criminalSearch) => {
                  const { error } = criminalSearch;
                  if (error) {
                    publish(`card::set-identifiers`, {
                      hash: hash,
                      data: { criminalSearch: [], criminalDetails: [] },
                    });
                  } else {
                    criminalSearchSetup(criminalSearch, identities);
                  }
                });
              } else {
                http(`/civil/details`, { cpf, rg }).then((identities) => {
                  const { error } = identities;
                  if (error) {
                    publish(`card::set-identifiers`, {
                      hash: hash,
                      data: { criminalSearch: [], criminalDetails: [] },
                    });
                  } else {
                    if (identities.length > 0) {
                      name = identities[0].basicInfo.name;
                      http(`/criminal/search`, { name }).then(
                        (criminalSearch) => {
                          const { error } = criminalSearch;
                          if (error) {
                            publish(`card::set-identifiers`, {
                              hash: hash,
                              data: { criminalSearch: [], criminalDetails: [] },
                            });
                          } else {
                            criminalSearchSetup(criminalSearch, identities);
                          }
                        }
                      );
                    } else {
                      publish(`card::set-identifiers`, {
                        hash: hash,
                        data: { criminalSearch: [], criminalDetails: [] },
                      });
                    }
                  }
                });
              }
            } else {
              publish(`card::set-identifiers`, {
                hash: hash,
                data: { criminalSearch: [], criminalDetails: [] },
              });
            }
            break;
          case "SISPEN Visitante":
            console.log("SISPEN Visitante");
            loadData.push(checkbox);
            loadSispenVisitorData()
              .then((data) => {
                publish(`card::set-identifiers`, {
                  hash: hash,
                  data: { sispenVisitor: data, sispenDetailsList: [data] },
                });
              })
              .catch(() => {
                publish(`card::set-identifiers`, {
                  hash: hash,
                  data: { sispenVisitor: {} },
                });
              });
            break;
          case "SISPEN Apenado":
            console.log("SISPEN Apenado");
            loadData.push(checkbox);
            loadSispenApenadoData();
            break;
          default:
            break;
        }
      }
    }

    bases.forEach(async (base) => {
      if (!loadData.includes(base)) {
        switch (base) {
          case "Veículos":
            setVehiclesDone(true);
            break;
          case "SISPEN Apenado":
            if (checkboxes[base]){
              formatImage()
            } else {
              setSispenDone(true)
            }
            break;
          case "ENEL":
            setEnelDone(true);
            break;
          case "Registros Policiais":
            if (checkboxes[base]) {
              if (criminalSearch.length > 0) {
                setCriminalItem(0);
              } else if (criminalDetails && criminalDetails.length > 0) {
                publish(`card::set-identifiers`, {
                  hash: hash,
                  data: { criminalDetails: [] },
                });
              } else {
                setCriminalDone(true);
              }
            } else {
              setCriminalDone(true);
            }
            break;
          default:
            break;
        }
      }
    });
  };

  const capitalizeFirstLetter = (value) => {
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  return (
    <div className="CardConfig">
      <div className="container-btn-back">
        <button className="btn-back" onClick={backAction}>
          <div className="icon-left"></div>
          <div className="label">VOLTAR</div>
        </button>
      </div>
      <h1 className="title">RELATÓRIO</h1>
      <div className="input-container">
        <label htmlFor={`${hash}-title`}>TÍTULO DO RELATÓRIO</label>
        <input
          type="text"
          id={`${hash}-title`}
          autoFocus
          value={title}
          onChange={(e) => {
            setTitle(capitalizeFirstLetter(e.target.value));
          }}
        />
        {errors["title"] && (
          <p className="input error">{errors["title"]["message"]}</p>
        )}
      </div>
      <div className="input-container">
        <label htmlFor={`${hash}-info`}>INFORMAÇÕES ADICIONAIS</label>
        <input
          type="text"
          id={`${hash}-info`}
          value={info}
          onChange={(e) => {
            setInfo(capitalizeFirstLetter(e.target.value));
          }}
        />
      </div>
      <div className="bases-container">
        <h3 className="sub title">SELECIONE AS BASES QUE DESEJA EXPORTAR</h3>
        <div className="buttons-container">
          <button
            className="button"
            onClick={() => {
              setCheckboxes(checksSetup(true));
            }}
          >
            Selecionar tudo
          </button>
          <button
            className="button"
            onClick={() => {
              setCheckboxes(checksSetup(false));
            }}
          >
            Limpar tudo
          </button>
        </div>
        <div className="checkboxes">
          {bases.map((base, index) => {
            return (
              <div key={base} className="checkbox-container">
                <input
                  key={`${hash}-checkbox-${index}`}
                  checked={checkboxes[base]}
                  type="checkbox"
                  id={`${hash}-checkbox-${index}`}
                  onChange={() => {
                    const obj = {};
                    obj[base] = !checkboxes[base];
                    setCheckboxes({ ...checkboxes, ...obj });
                  }}
                />
                <label
                  key={`${hash}-label-${index}`}
                  htmlFor={`${hash}-checkbox-${index}`}
                >
                  {base}
                </label>
              </div>
            );
          })}
          {errors["checkboxes"] && (
            <p className="error">{errors["checkboxes"]["message"]}</p>
          )}
        </div>
        {loading ? (
          <div className="loading"></div>
        ) : ready2Print ? (
          <div className="export-container">
            <p className="success-feedback">
              <span className="success-icon" />
              Relatório gerado com sucesso!
            </p>
            <PDFDownloadLink
              document={<PdfDocument data={data2Print} />}
              fileName={`${origin["label"]}-${origin["value"]}.pdf`}
              style={{
                display: "flex",
                backgroundColor: "#497936ff",
                width: "fit-content",
                borderRadius: "5px",
                color: "#ffffff",
                fontSize: "1rem",
                fontFamily: "Roboto",
                fontWeight: "bold",
                paddingTop: "6px",
                paddingBottom: "6px",
                paddingLeft: "12px",
                paddingRight: "12px",
              }}
            >
              BAIXAR RELATÓRIO
            </PDFDownloadLink>
          </div>
        ) : (
          <button className="main button" onClick={getBasesData}>
            GERAR RELATÓRIO
          </button>
        )}
      </div>
    </div>
  );
}
