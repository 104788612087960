import React, { Component } from 'react'
import CarDocument from '../Veiculos/CarDocument'
import util from '../../../../helpers/util'
import { normalizeAddress } from '../../../../helpers/addressModel'
import { publish } from "../../../../services/EventBus";
import './index.scss'

export class CardCNHVeiculoById extends Component {

  static ROLES = [
    `CEREBRUM_BUSCA_VEICULO_DETALHE`,
  ]

  state = {
    title: ``,
    data: null,
    loading: true,
    message: null
  }

  dataSetup = (data) => {
    const { id, label } = this.extractQuery()
    const title = `VEÍCULO ${label} ${id}`.toUpperCase()
    this.setState({ loading: false })
    const { vehicle, address } = data
    data = { ...vehicle, ...data, details: { address, ...data } }

    this.setState({ id, label, data, title }, () => {
      this.geocodeFocus(data)
      this.props.show()
      this.props.setTitle(title)
    })
  }

  componentDidMount() {
    if (this.props.data && this.props.data.vehicleById) {
      this.dataSetup(this.props.data.vehicleById)
    } else {
      const { id, label, query } = this.extractQuery()
      this.props.http(query).then(data => {
        const title = `VEÍCULO ${label} ${id}`.toUpperCase()
        const { error } = data
        if (error) {
          return util.handleError(
            error,
            this.props.show,
            () => {
              this.setState({ loading: false, title })
            },
            (message) => {
              this.setState({ message, loading: false, title })
            }
          );
        }
        this.dataSetup(data);
        publish(`card::set-identifiers`, { hash: this.props.hash, data: {vehicleById: data } })
        const docType = data.ownerCpf && data.ownerCpf.length === 11 ? `CPF` : `CNPJ`
        if (docType === `CPF`)
          publish(`card::set-identifiers`, { hash: this.props.hash, cpf: data.ownerCpf })
      })
    }
  }

  extractQuery = () => {
    const { motor, chassi } = this.props
    const plate = (this.props.plate || ``).replace(/[\s-]/g, ``).trim()
    if (chassi) return { id: chassi, label: `CHASSI`, query: `/detran/vehicles/chassis/${chassi}` }
    if (plate) return { id: plate, label: `PLACA`, query: `/detran/vehicles/${plate}` }
    if (motor) return { id: motor, label: `MOTOR`, query: `/detran/vehicles/motor/${motor}` }
    throw new Error(`Identificação inválida de veículo`)
  }

  geocodeFocus = json => {
    if (json.details.address && json.details.address.geocode) {
      const { address } = json.details
      const { geocode } = address
      const data = {
        point: geocode.position,
        source: geocode.source,
        address: normalizeAddress(address).text,
        name: json.markModel,
        plate: util.formatCarPlateNumber(json.plate),
        type: json.type,
        color: json.color,
      }
      const zoom = address.address ? 13 : 8
      this.props.publish(`map::zoom`, { point: data.point, zoom })
      setTimeout(() => {
        this.props.publish(`map::marker.add`, { name: `geocode`, point: data.point, data, color: `#3dca85`, className: `square animated`, popup: this.createPopup })
      }, 1)
    }
  }

  createPopup = json => {
    const { plate, type, color, address, source } = json.data
    return {
      title: plate + ` ` + type + ` ` + color,
      text: address,
      source
    }
  }

  render() {
    if (this.props.hidden) {
      return null
    }
    const { title, data, message } = this.state
    return (
      <div className="CardCNHVeiculoById">
        <div className="title">{title}</div>
        {this.state.loading
          ? <div className="message-loading">CARREGANDO...</div>
          : data
            ? <>
              <CarDocument {...data} />
            </>
            : <p className="message">{message ? message : `NENHUM REGISTRO ENCONTRADO`}</p>
        }
      </div>
    )
  }

}
