import config from '../../config'
import { App } from '../../App'
import { api } from '../api'
import { me, auth, logout, recovery } from '../login'

const loginServer = {
  'stage': config.gatewayLogin,
  'development': config.gatewayLogin,
  'production': config.gatewayLogin
}[config.env]

const clearAuthorizationHeader = () => {
  App.setAuthorizationHeader(null)
}

const ServiceApiLogin = {

  createSession: ({token, roles, client}) => {
    App.setAuthorizationHeader(token)
    return Promise.resolve({token, roles, client})
  },

  clearSession: (options={http: true}) => {
    const token = App.getToken()
    if (token) {
      if (options.http) {
        logout({token, app: config.appToken, host: loginServer}).then(
          clearAuthorizationHeader,
          clearAuthorizationHeader
        )
      } else {
        clearAuthorizationHeader()
      }
    }
    return Promise.resolve()
  },

  getSession: () => {
    // utilizando servidor mockado durante ambiente de desenvolvimento localhost
    if (config.development && loginServer.includes(`127.0.0.1`)) {
      return api.get(loginServer + `/auth/me`)
    }
    // ambiente production ou stage
    const token = App.getToken()
    if (!token) {
      return Promise.resolve({})
    }
    return new Promise((resolve, reject) => {
      me({token, app: config.appToken, host: loginServer}).then(data => {
        const { roles } = data
        delete data.roles;
        const result = { token, roles, client: data }
        resolve(result)
      }, error => {
        if (error && error.error && error.message === `Network Error`) {
          console.log(`append message ERROR Network Error!!`)
        }
        reject({error, message: `falha ao requisitar dados`})
      })
    })
  },

  doLogin: (credentials) => {
    // ambiente production ou stage
    return new Promise((resolve, reject) => {
      auth({app: config.appToken, host: loginServer, credentials}).then(data => {
        resolve(data)
      }, error => {
        if (error && error.error && error.message === `Network Error`) {
          console.log(`append message ERROR Network Error!!`)
        }
        reject({error, message: `falha ao requisitar dados`})
      })
    })
  },

  doPasswordRecovery: ({email}) => {
    return new Promise((resolve, reject) => {
      recovery({app: config.appToken, host: loginServer, email}).then(data => {
        resolve(data)
      }, error => {
        if (error && error.error && error.message === `Network Error`) {
          console.log(`append message ERROR Network Error!!`)
        }
        reject({error, message: `falha ao requisitar dados`})
      })
    })
  },
  
  //REFRESH TOKEN
  // import { refresh } from '../login/refresh'

  //ERROR 401 (getSession > me > catch)
  //Add refreshedToken as param in getSession
  // if(error.status === 401 && !refreshedToken){
  //   return ServiceApiLogin.updateSession({http: true}).then(() => {
  //     return ServiceApiLogin.getSession(true).then(data => {
  //       resolve(data)
  //     })
  //   })
  // }  

  // const updateAuthorizationHeader = ({token, refresh_token}) => {
  //   App.setAuthorizationHeader(token, refresh_token)
  // }

  // createSession: ({token, refresh_token, roles, client}) => {
  //   App.setAuthorizationHeader(token, refresh_token)
  //   return Promise.resolve({token, refresh_token, roles, client})
  // },

  // updateSession: (options={http: true}) => {
  //   const refreshToken = App.getRefreshToken()
  //   const token = App.getToken()
  //   if (refreshToken) {
  //     if (options.http) {
  //       refresh({token, refreshToken, app: config.appToken, host: loginServer}).then(
  //         updateAuthorizationHeader,
  //         clearAuthorizationHeader
  //       )
  //     } else {
  //       clearAuthorizationHeader()
  //     }
  //   }
  //   return Promise.resolve()
  // },

};

export { ServiceApiLogin }
