import React, { Component } from 'react';
import MenuBar from '../../Civil/Historico/MenuBar'
import './index.scss'

const COLOR = {
  central: `#37cc81`,
  regional: `#24a3f1`,
  cicc: `#e0bf12`,
  redundancia: `#e08e37`,
}
const ITEMS = []
const ITEM = {}

export class CardPNIDGeral extends Component {

  state = {
    drawItem: {},
    items: [],
    current: 0,
    groups: [
      {id: `todos`, title: `Todos`, text: ``},
      {id: `central`, title: `Central`, text: ``},
      {id: `regional`, title: `Regional`, text: ``},
      {id: `cicc`, title: `CICC`, text: ``},
      {id: `redundancia`, title: `Redundância`, text: ``},
    ]
  }

  download() {
    return new Promise((resolve) => {
      if (ITEMS.length) {
        return resolve(ITEMS.map(i => ({...i})))
      }
      const baseUrl = process.env.PUBLIC_URL
      this.props.http.staticFiles(`/pnid/dataset.json`).then(json => {
        if (typeof json === `string`) {
          json = JSON.parse(json)
        }
        json.forEach(i => {
          const item = {...i, point: i.geom.coordinates}
          item.label = this.state.groups.find(_ => _.id === i.tipo).title
          item.color = COLOR[i.tipo]
          item.popup = this.createPopup
          item.click = this.clickPopup
          item.className = `circle animated`
          item.image = baseUrl + `/pnid/img/${i.id}.png`
          delete item.geom
          delete item.imagem
          ITEMS.push(item)
          ITEM[i.id] = item
        })
        resolve(ITEMS.map(i => ({...i})))
      })
    })
  }

  componentDidMount() {
    const current = 1
    this.download().then(items => {
      this.setState({items, current}, () => {
        this.menuChange(current)
        this.props.show()
        this.props.setTitle(`PNID BIGDATA`)
      })
    })
  }

  createPopup = item => {
    return { title: item.titulo }
  }

  clickPopup = item => {
    this.props.openCard(`CardPNIDDetalhe`, item)
  }

  mapClear = () => {
    const { drawItem } = this.state
    Object.keys(drawItem).forEach(id => {
      if (drawItem[id].lines) {
        this.props.publish(`map::layer.remove`, {name: id})
      } else {
        this.props.publish(`map::marker.remove`, {name: id})
      }
    })
  }

  handleClickRow = item => {
    this.props.publish(`map::zoom`, {point: item.point, zoom: 10})
  }

  menuChange = index => {
    const drawItem = {}
    const typeSelected = this.state.groups[index].id
    if (typeSelected !== `todos`) {
      this.mapDrawByType(typeSelected, drawItem, typeSelected === `central`)
    } else {
      this.state.groups.map(g => g.id).filter(id => id !== `todos`).forEach(id => {
        this.mapDrawByType(id, drawItem, false)
      })
    }
    //
    this.mapClear()
    const group = {color: `#fff`, title: `PNID`}
    Object.keys(drawItem).filter(i => drawItem[i].lines).forEach(id => {
      this.props.publish(`map::layer.add`, {...drawItem[id], name: id, group})
    })
    Object.keys(drawItem).filter(i => drawItem[i].point).forEach(id => {
      this.props.publish(`map::marker.add`, {...drawItem[id], name: id})
    })
    this.setState({current: index, drawItem})
  }

  mapDrawByType = (typeSelected, drawItem, forceFocus=false) => {
    const items = this.state.items.filter(_ => _.tipo === typeSelected)
    items.forEach(item => {
      drawItem[`point${item.id}`] = item
    })
    // regional e conexões...
    items.filter(_ => _.tipo === `regional`).forEach(regional => {
      const color = `#d9a700`
      const lines = regional.conexoes.map(conexaoId => {
        const another = ITEM[conexaoId]
        if (!items.find(_ => _.id === another.id)) {
          drawItem[`point${another.id}`] = another
        }
        return [regional.point, ITEM[conexaoId].point]
      })
      drawItem[`point${regional.id}`] = regional
      drawItem[`line${regional.id}`] = {lines, color, opacity: 0.4, width: 3}
    })
    // hierarquias...
    items.filter(_ => _.tipo === typeSelected && _.hierarquia).forEach(item => {
      const parent = ITEM[item.hierarquia]
      if (!items.find(_ => _.id === parent.id)) {
        drawItem[`point${parent.id}`] = parent
      }
      const lines = [[item.point, parent.point]]
      drawItem[`line${item.id}-${parent.id}`] = {lines, gradient: true}
      drawItem[`point${item.id}`] = item
    })
    // overide initial zoom
    if (forceFocus && typeSelected === `central`) {
      const { point } = items[0]
      this.props.publish(`map::zoom`, {point, zoom: 5})
    }
  }

  render() {
    if (this.props.hidden) {
      return null
    }
    const { current, groups } = this.state
    return (
      <div className="CardPNIDGeral">
        <div className="title">PNID <span>Programa Nacional de Integração de Dados</span></div>
        <MenuBar items={ groups } current={ current } onChange={ this.menuChange } />
        { this.renderContent() }
      </div>
    )
  }

  renderContent() {
    const typeSelected = this.state.groups[this.state.current].id
    const items = this.state.items.filter(_ => typeSelected === `todos` || _.tipo === typeSelected)
    return (
      <div className="container-rows">
        {items.map(i => (
          <div className={ `row ` + typeSelected } key={ i.id } onClick={ this.handleClickRow.bind(this, i) }>
            { typeSelected === `todos` ? <div className="tipo">{ i.label }</div> : null }
            <div className="text">
              <div className="row-title">{ i.titulo }</div>
              <div className="row-subtitle">{ i.subtitulo }</div>
            </div>
          </div>
        ))}
      </div>
    )
  }

}
