import React, { Component } from 'react';
import './index.scss'

export class CardPNIDDetalhe extends Component {

  componentDidMount() {
    this.props.publish(`map::marker.add`, {...this.props, className: `circle border`, popup: this.createPopup})
    this.props.show()
    this.props.setTitle(`PNID - ` + this.props.titulo)
  }

  createPopup = item => {
    return {
      title: item.titulo
    }
  }

  render() {
    const { titulo, subtitulo } = this.props
    return (
      <div className="CardPNIDDetalhe">
        <div className="title">{ titulo }</div>
        <div className="subtitle">{ subtitulo }</div>
        { this.renderImage() }
      </div>
    )
  }

  renderImage() {
    return <img src={ this.props.image } alt="" />
  }

  renderContent() {
    const { titulo, subtitulo } = this.props
    return (
      <div className="content">
        <div>{ titulo }</div>
        <div className="opacity50">{ subtitulo }</div>
      </div>
    )
  }

}
