
import util from '../../../helpers/util'
import * as type from '../fieldTypes'

const extractRG = json => {
  let rg = ``
  if (json.rg) rg += json.rg + ` `
  if (json.rgEmitter) rg += json.rgEmitter + ` `
  if (json.federalUnitRgEmitter) rg += json.federalUnitRgEmitter
  return rg.trim()
}

const extractCPF = json => {
  if (json.cpf)
    return json.cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4')
}

export const CriminalAutocompleteByName = {
  created: `2019-04-22`,
  context: `Criminal`,
  role: `CEREBRUM_BUSCA_CRIMINAL`,
  params: {
    name:   {placeholder: `Nome`, optional: true},
    alias:  {placeholder: `Alcunha`, optional: true},
    mother: {placeholder: `Mãe`, optional: true},
    father: {placeholder: `Pai`, optional: true},
  },
  createRequest: params => {
    Object.entries(params).forEach(([key, value]) => {
      params[key] = value === undefined || value === `` ? undefined : value.trim()
    })
    const aliasCount = util.countNames(params.alias)
    const nameCount  = util.countNames(params.name)
    if (nameCount === 0 && aliasCount === 0)
      throw new Error(`É necessário informar nome ou alcunha para realizar pesquisa.`)
    if (nameCount < 2 && aliasCount === 0)
      throw new Error(`É necessário detalhar mais o nome da pessoa. Por favor, tente novamente.`)
    if (nameCount > 0 && aliasCount > 0)
      throw new Error(`É necessário pesquisar por nome ou alcunha. Por favor, tente novamente.`)
    //
    return {path: `/criminal/search`, params}
  },
  createRow: json => {
    const name = json.regName
    const { aliases, picture, mother, father } = json
    const birthday = json.birthday ? util.date2str(json.birthday) : null
    const rg = extractRG(json)
    const cpf = extractCPF(json)
    const lines = []
    if (aliases && aliases.length) {
      const alcunha = aliases.filter(i => i && i !== `SEM ALCUNHA`).join(`, `).toUpperCase()
      if (alcunha)
        lines.push([{label: `ALCUNHA`, value: alcunha}])
    }
    lines.push([
      {label: `RG`,  value: rg},
      {label: `CPF`, value: cpf},
    ])
    lines.push([{label: `Mãe`, value: (mother || ``).toUpperCase()}])
    lines.push([{label: `Pai`, value: (father || ``).toUpperCase()}])
    return {
      card: `CardCriminalHistorico`,
      props: json,
      //
      image: picture || `avatar.png`,
      title: birthday ? (birthday + ` ` + name) : name,
      lines
    }
  }
}

export const CriminalById = {
  created: `2019-04-22`,
  context: `Criminal`,
  role: `CEREBRUM_BUSCA_CRIMINAL`,
  params: {
    regNumber: {label: `Número Cadastro`, placeholder: `000000`, type: type.number},
  },
  parser: (json) => {
    return {card: `CardCriminalHistorico`, props: json}
  }
}

