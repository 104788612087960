
import WS from '../services/websocket'
import { App } from '../App'
import config from '../config'

export {
  onlineObjects
}

const ONE_MINUTE = 60 * 1000 // in millis
const DEFAULT_MAX_ALIVE_POINTS = 35 * 1000 // millis

const createWSAuthenticatedURL = (path=``, query={}) => {
  const params = {...App.headers, ...query}
  const host = config.gatewayAppWebsocket
  return host + path + `?` + Object.entries(params).map(i => i.join(`=`)).join(`&`)
}

const hiddenByQueryWords = (query, words=[]) => {
  return words.length === 0 ? false : words.filter(w => query.indexOf(w) === -1).length > 0
}

function onlineObjects(card, path, options) {
  const { publish } = card.props
  const { title, popup, click, color, frame2obj, onChange } = options
  if (!frame2obj || !onChange)
    throw new Error(`function frame2obj and onChange is required!`)
  //
  path = createWSAuthenticatedURL(path, options.query)
  const alive = options.alive || DEFAULT_MAX_ALIVE_POINTS
  const data = {
    error: null,
    hash:  {}, // todos os objetos referenciados pelo id
    items: [], // todos os objetos filtrados por `words`
    words: []
  }
  //
  const group = {color, title}
  publish(`map::layer.add`, {name: `points`, points: [], group, opacity: 1, color, popup, click})
  publish(`map::layer.add`, {name: `points-weak`, points: [], group, opacity: 0.6, color, popup, click})
  publish(`map::layer.add`, {name: `effect`, points: [], group, opacity: 0.2, scale: 1.8, color: `#fff`})
  publish(`map::layer.add`, {name: `effect-weak`, points: [], group, opacity: 0.03, scale: 4, color: `#fff`})
  //
  function reset() {
    publish(`map::layer.clear`, {name: `points`})
    publish(`map::layer.clear`, {name: `points-weak`})
    publish(`map::layer.clear`, {name: `effect`})
    publish(`map::layer.clear`, {name: `effect-weak`})
    data.connected = false
    data.error = null
    data.words = []
    data.hash  = {}
    data.items = []
    data.total = 0
    data.lastMinute = false
  }
  function notifyChanges() {
    onChange(data)
  }
  function applyFilter() {
    const { hash, words } = data
    const now = Date.now()
    const rows = Object.values(hash)
    data.items = rows.filter(i => {
      i.hidden = hiddenByQueryWords(i.query, words)
      if (data.lastMinute && !i.hidden && now - i.updated > ONE_MINUTE) {
        i.hidden = true
      }
      return !i.hidden
    })
    data.items.sort((a,b) => b.date - a.date)
    data.total = rows.length
    updateMap()
    notifyChanges()
  }
  //
  function wsMessage(jsonList) {
    const updated = Date.now()
    const objs = jsonList.map(frame2obj)
    objs.forEach(o => {
      data.hash[o.id] = o
      data.hash[o.id].updated = updated
    })
    applyFilter()
  }
  function wsConnected() {
    reset()
    data.connected = true
    notifyChanges()
  }
  function wsReconnecting() {
    reset()
    data.error = `Falha ao conectar! Tentando conexão...`
    notifyChanges()
  }
  function updateMap() {
    const now     = Date.now()
    const points  = data.items.filter(i => i.point)
    const recents = points.filter(i => now - i.updated <  alive)
    const weaks   = points.filter(i => now - i.updated >= alive)
    publish(`map::layer.update`, {name: `points`, points: recents})
    publish(`map::layer.update`, {name: `points-weak`, points: weaks})
    publish(`map::layer.update`, {name: `effect`, points: recents})
    publish(`map::layer.update`, {name: `effect-weak`, points: weaks})
  }
  const websocket = new WS(path, {autoconnect: true, card})
    .on(`message`, wsMessage)
    .on(`connected`, wsConnected)
    .on(`reconnecting`, wsReconnecting)
  //
  let effect = 0
  let timer = setInterval(() => { console.log('TESSTE')})
  publish(`map::layer.visibility`, {name: `effect`, hidden: true})
  publish(`map::layer.visibility`, {name: `effect-weak`, hidden: true})
  //
  return {
    
    resetZoomMap: () => {
      publish(`map::zoom`, { point: [-38.6, -3.8], zoom: 10.8 })
    },
    setEffect: value => {
      if (value) {
        timer = setInterval(() => {
          effect = (effect + 1) % 8
          publish(`map::layer.visibility`, {name: `effect`, hidden: !!(effect % 2)})
          publish(`map::layer.visibility`, {name: `effect-weak`, hidden: effect > 4})
        }, 500)
      } else {
        publish(`map::layer.visibility`, {name: `effect`, hidden: true})
        publish(`map::layer.visibility`, {name: `effect-weak`, hidden: true})
        clearInterval(timer)
      }
    },
    setFilter: words => {
      data.words = words
      applyFilter()
    },
    setLastMinute: value => {
      data.lastMinute = value
      applyFilter()
    },
    disable: () => {
      clearInterval(timer)
      if (websocket) {
        websocket.disconnect()
        reset()
      }
    }
  }
}


