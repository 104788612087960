import React, { Component } from 'react'
import util from '../../../../helpers/util'
import './index.scss'

export class CardCVLIPopupDetails extends Component {

  state = {}

  componentDidMount() {
    const { point } = this.props
    if (point) {
      this.props.publish(`map::marker.add`, {point, color: `#fa2`, className: `circle border`})
    }
    this.props.show()
    this.props.setTitle(`CVLI ` + this.props.procedureType + ` ` + this.props.id + ` `)
  }

  render() {
    const { id, procedureType, ais } = this.props
    const { crimeWeapon, eventType } = this.props
    const { victimName, victimSex, victimAge } = this.props
    const date = util.date2str(this.props.date, true)
    const hour = (this.props.hour.match(/(\d+:\d+)/) || {})[1]
    let when = ``
    if (date) {
      when = date + (hour ? (` às ` + hour) : ``)
    }
    const attrs = [
      {label: `Vítima`, value: victimName || `-`},
      {label: `Sexo`,   value: victimSex === `M` ? `Masculino` : (victimSex === `F` ? `Feminino` : `-`)},
      {label: `Idade`,  value: (victimAge || `-`) + ` anos`},
    ].filter(_ => _.value)
    //
    return (
      <div className="CardCVLIPopupDetails">
        <div className="card-title">{ procedureType } <span>{ id }</span></div>

        <div className="subtitle type main">{ when } (AIS { ais })</div>
        <div className="subtitle type">{ eventType }</div>
        <div className="subtitle type">{ crimeWeapon }</div>

        <div className="group">
          {attrs.map(i => (
            <div className="row" key={ i.label }>
              <label>{ i.label }</label><b>{ i.value  || `-` }</b>
            </div>
          ))}
        </div>
      </div>
    )
  }

}
