import React from 'react'
import { ReactComponent as DataBaseIcon } from '../../images/database-icon.svg';
import { ReactComponent as EyeIcon } from '../../images/eye-icon.svg';
import { ReactComponent as ActivityIcon } from '../../images/activity-icon.svg';

import './index.scss'

export function AboutPanel(props) {

  return (
    <div className="about-container">
      <div className="AboutPanelBackground" onClick={() => props.handleClosePanel(true)}></div>
      <div className="AboutPanel">
        <button className="btn-close" onClick={() => props.handleClosePanel(true)}>
          {/* <span>ESC</span> */}
        </button>
        <div className="container-content">

          <div className="container-title">
            <div className="main-title">SOBRE O CEREBRUM</div>
            <div className="yellow-detail" />
          </div>

          <div className="subtitle">Cerebrum é um agregador de informações de diversas
            fontes de dados que permite uma visão unificada sobre indicadores relacionados a violência no Estado do Ceará e fornece uma visualização inovadora dos dados integrados.
          </div>

          <div className="icons-row">

            <div className="info-column">
              <div><DataBaseIcon /></div>
              <div className="info-title">Diversas bases</div>
              <p className="info-text">É possível fazer buscas em bases civis, criminais, do DETRAN, da ENEL, além de diversas outras visualizações possíveis como a de tornozelados e de facções.</p>
            </div>

            <div className="info-column">
              <div><EyeIcon /></div>
              <div className="info-title">Em tempo real</div>
              <p className="info-text">Câmeras de monitoramento de todo o estado, viaturas e tornozelados podem ser visualizados em tempo real.</p>
            </div>

            <div className="info-column">
              <div><ActivityIcon /></div>
              <div className="info-title">Consulta de Indicadores</div>
              <p className="info-text">Indicadores criminais com detalhes e periodicidades escolhidas pelo usuário como CVP e CVLI podem ser visualizados com facilidade através de gráficos.</p>
            </div>

          </div>


        </div>
      </div>
    </div>
  )
}
