import React, { useEffect, useState } from "react";

const Route = ({ path, exact, component: Content, children }) => {
  const [currentPath, setCurrentPath] = useState(window.location.pathname);

  useEffect(() => {
    const onLocationChange = () => {
      setCurrentPath(window.location.pathname);
    };
    window.addEventListener("popstate", onLocationChange);

    return () => {
      window.removeEventListener("popstate", onLocationChange);
    };
  }, []);

  if (!exact && currentPath.indexOf(path, -1) !== -1) {
    return <Content {...window.location}>{children}</Content>;
  } else if (currentPath === path) {
    return <Content />;
  } else return null;
};

export default Route;
