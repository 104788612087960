
/**
 * container para os markers plotados no mapa com movimentação em tempo real
 */
export class RealtimeMarker {

  maxHistory = 10
  card = null
  objects = {}
  objList = []
  oldList = []
  history = {}

  constructor(card, objects=[], maxHistory=10) {
    this.card = card
    this.visible = {}
    this.objects = {}
    objects.forEach(o => this.objects[o.id] = o)
    this.maxHistory = maxHistory
    const group = {color: `#3cc0ac`, title: `Satélites`}
    this.card.props.publish(`map::layer.add`, {name: `lines`,  group, lines: [], color: `#fff`, gradient: true})
    this.card.props.publish(`map::layer.add`, {name: `points`, group, points: [], opacity: 1, color: `#31cc98`, popup: this.createPopup})
    this.card.props.publish(`map::layer.add`, {name: `effect`, group, points: [], opacity: 0.2, scale: 1.8, color: `#fff`})
    //
    let effect = 0
    this.timer = setInterval(() => {
      this.card.props.publish(`map::layer.visibility`, {name: `effect`, hidden: ++effect % 2 === 0})
    }, 500)
  }

  clear() {
    clearInterval(this.timer)
    this.reset()
  }

  reset() {
    this.oldList = []
    this.history = {}
    this.card.props.publish(`map::layer.clear`, {name: `lines`})
    this.card.props.publish(`map::layer.clear`, {name: `points`})
    this.card.props.publish(`map::layer.clear`, {name: `effect`})
  }

  createPopup = item => {
    return { title: item.code }
  }

  updateOfflineStatus = (components=[]) => {
    components.forEach(i => {
      this.card.props.publish(`map::layer.update`, {name: i.id, className: i.offline ? `animated offline` : `animated`})
      if (i.offline) {
        delete this.history[i.id]
      }
    })
    this.renderLines()
  }

  setVisibility = (componentId, visible) => {
    if (!visible) {
      delete this.history[componentId]
    }
    this.visible[componentId] = visible
    this.update(this.oldList)
  }

  update = (list) => {
    this.oldList = [...list]
    //
    list = this.extractLastCollects(list).filter(i => {
      return this.card.state.components.find(_ => _.id === i.componentId).hidden === false
    })
    this.objList = list.map(i => {
      return {...this.objects[i.componentId], point: i.position}
    })
    this.renderMarkers()
    this.renderLines()
  }

  extractLastCollects = collects => {
    const items = collects.map(c => ({...c, time: new Date(c.collectDate)})).sort((a,b) => a.time - b.time)
    const last = {}
    items.forEach(i => {
      last[i.componentId] = i
      this.updateHistoryByCollect(i)
    })
    return Object.values(last)
  }

  updateHistoryByCollect = collect => {
    const id = collect.componentId
    const point = collect.position
    if (this.history[id] === undefined) {
      this.history[id] = [point,]
    } else {
      const last = this.history[id].slice(-1)[0]
      if (last[0] !== point[0] || last[1] !== point[1]) {
        this.history[id] = [...this.history[id], point].slice(-1 * this.maxHistory)
      }
    }
  }

  renderMarkers() {
    const points = this.objList.filter(i => this.visible[i.id] !== false)
    this.card.props.publish(`map::layer.update`, {name: `effect`, points})
    this.card.props.publish(`map::layer.update`, {name: `points`, points})
  }

  renderLines() {
    const lines = Object.keys(this.history).filter(id => this.visible[id] !== false).map(id => this.history[id])
    this.card.props.publish(`map::layer.update`, {name: `lines`, lines})
  }

}
