import React from "react";
import { CardManager } from "../components/CardManager";
import { Map } from "../components/Map/Map";
import SearchPanel from "../components/SearchPanel";
import Header from "../components/Header";
import ImageViewer from "../components/ImageViewer";
import { Menu } from "../components/Menu";

const MainScreen = () => (
  <>
    <Header />
    <Map />
    <SearchPanel />
    <Menu />
    <CardManager />
    <ImageViewer />
  </>
);

export default MainScreen;
