import React, { Component } from 'react'
import { subscribe, unsubscribe } from '../../../services/EventBus'
import './InputList.scss'

export default class InputList extends Component {

  state = {
    hidden: true,
    config: {}
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const hash = nextProps.data.map(i => i.id).join(`:`)
    if (hash !== prevState.hash) {
      return {hash, config: {}}
    }
    return null
  }

  componentDidMount() {
    this.setState({hidden: false})
    this.subscribeToken = subscribe(`event::keyup.esc`, () => {
      this.props.onChange({})
      this.setState({config: {}})
    })
  }

  componentWillUnmount() {
    unsubscribe(this.subscribeToken)
  }

  keyDownHandler = e => {
    if (this.props.onKeyDown) {
      this.props.onKeyDown(e.keyCode)
    }
  }

  changeHandler = (actionParam, e) => {
    const { id, type } = actionParam
    const { requiredParams } = this
    const value  = e.target.value.toUpperCase()
    const param  = {[id]: type ? type.fixText(value) : value}
    const config = {...this.state.config, ...param}
    this.setState({config})
    if (this.props.onChange) {
      try {
        if ( !config[id] || (config[id] || ``).trim().length === 0 ) {
          param[id] = undefined
        } else if (type) {
          param[id] = type.getValue(config[id])
        }
        this.props.onChange({name: id, value: param[id], requiredParams})
      } catch (e) {
        console.error(`SearchBar.InputList... error:`, e)
      }
    }
  }

  render() {
    const { hidden, config } = this.state
    const { data } = this.props
    if (!data || data.length === 0) {
      return <div key={ -1 } className="input-field index0 spacer"></div>
    }
    return (
      <React.Fragment>
        {data.map((i, index) => (
          <div key={ i.id } className={ `input-field index${index}` + (hidden ? ` hidden` : ``) }>
            { this.renderLabel(config[i.id], i.optional, i.placeholder) }
            <input
              value={ config[i.id] || `` }
              onChange={ this.changeHandler.bind(this, i) }
              onKeyDown={ this.keyDownHandler }
              placeholder={ i.placeholder }
              autoComplete="disabled"
              autoCorrect="disabled"
            />
          </div>
        ))}
      </React.Fragment>
    )
  }

  renderLabel = (empty, optional=false, placeholder=``) => {
    let s = placeholder
    if (empty && optional)
      s += ` - OPCIONAL`
    else if (!empty && optional)
      s = `OPCIONAL`
    s = s.replace(/^\s-\s/, ``)
    if (empty && !optional)
      s = placeholder
    return <div className={ `label` + (empty || optional ? `` : ` hidden`) + (s === `OPCIONAL` ? ` optional` : ``)}>{ s }</div>
  }

  get requiredParams() {
    const { data } = this.props
    return (data || []).filter(i => !i.optional).map(i => i.id)
  }

}
