
import * as type from '../fieldTypes'

export const ViaturasOnline = {
  created: `2019-04-22`,
  context: `Viaturas`,
  role: `CEREBRUM_AOM_VIATURAS_ONLINE`,
  title: `VIATURAS ONLINE`,
  description: `Acompanhamento em tempo real de todas as posições`,
  parser: () => {
    return {card: `CardViaturasOnline`, props: {}}
  }
}

export const ViaturasByAreaOntem = {
  created: `2019-04-22`,
  context: `Viaturas`,
  role: `CEREBRUM_AOM_VIATURAS_AREA`,
  title: `VIATURAS POR ÁREA ONTEM`,
  description: `Visualizar todas as viaturas que tiveram ao menos uma coleta de posição dentro de uma área no mapa`,
  parser: () => {
    const date = new Date()
    date.setDate(date.getDate() - 1)
    return {card: `CardViaturasHistoricoArea`, props: {type: `date`, date}}
  }
}

export const ViaturasByRaioAndDate = {
  created: `2019-04-22`,
  context: `Viaturas`,
  role: `CEREBRUM_AOM_VIATURAS_AREA`,
  params: {
    date: {label: `Data`, placeholder: `dd/mm/aaaa`, type: type.date},
  },
  parser: ({date}) => {
    return {card: `CardViaturasHistoricoArea`, props: {type: `date`, date}}
  }
}

export const ViaturasByRaioAndRangeDateTime = {
  created: `2019-04-22`,
  context: `Viaturas`,
  role: `CEREBRUM_AOM_VIATURAS_AREA`,
  params: {
    startDateTime: {label: `Data Inicial`, placeholder: `dd/mm/aaaa hh:mm`, type: type.datetime},
    endDateTime: {label: `Data Final`, placeholder: `dd/mm/aaaa hh:mm`, type: type.datetime, optional: true},
  },
  description: `Intervalo de tempo para filtrar a busca de posições geográficas das viaturas que atuaram entre as datas escolhidas`,
  parser: ({startDateTime, endDateTime}) => {
    const type = endDateTime ? `rangeDateTime` : `startDateTime`
    return {card: `CardViaturasHistoricoArea`, props: {type, startDateTime, endDateTime}}
  }
}

