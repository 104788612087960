import React from 'react'
import LoginInputs from './Inputs'
import Button from './Button'


import './index.scss'
import './ScreenMobile.scss'
import './ScreenTablet.scss'

export default class LoginScreen extends React.Component {

  constructor(){
    super()
    this.state = {
      hidden: true,
      isModalVisible: false,
      disableModal: false,
    }
    this.disableModal = this.disableModal.bind(this)
    this.logos = [
      {id: `gov-logo`, link: `https://www.ceara.gov.br`},
      {id: `pca-logo`, link: `http://www.sspds.ce.gov.br`},
      {id: `supesp-logo`, link: `https://www.supesp.ce.gov.br/`},
    ]
  }

  static defaultProps = {
    autoClose: true
  }

  componentDidMount() {
    this.timeout = setTimeout(_ => {
      this.setState({ hidden: false })
    }, 1)
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  disableModal(value) {
    this.setState({disableModal: value})
  }

  render() {
    const { hidden, disableModal, isModalVisible } = this.state
    return (

      <>
        <div className={`spi-oauth2-login-screen ${hidden ? 'hidden' : ''}`}>
          <div className={`modal-overlay${!isModalVisible ? ` hidden` : ``}`} onClick={ !disableModal ? this.handleCloseModal : null} />
          <div className="main-supesp-logo"></div>
          {this.renderHorizontalBar()}
          {this.renderMainShape()}
          {this.renderForm()}
          {this.renderFooter()}
        </div>
      </>

    )
  }

  renderHorizontalBar = () => {
    return <div className="horizontal-bar"></div>
  }

  renderMainShape = () => {
    return <div className="main-shape"></div>
  }

  handleCloseModal = () => {
    setTimeout(() => {
      this.setState({ isModalVisible: false })
    }, 100)
  }

  renderForm = () => {
    const { app, version } = this.props


    return (
      <div className="form">

        {
          !this.state.isModalVisible
            ?
            <>
              <div className="application">{app}</div>
              <div className="version">{version}</div>
              <div onClick={() => this.setState({ isModalVisible: true })}>
                <Button
                  options={this.props.options}
                  onLogin={this.handleLogin}
                  onRecover={this.handleRecover}
                />
              </div>
            </>

            : <LoginInputs onClose={this.handleCloseModal} onLogin={this.handleLogin} disableModal={this.disableModal}/>
        }


      </div>
    )
  }

  renderFooter = () => {
    const year = new Date().getFullYear()
    return (
      <div className="footer-access">
        <div className="logos-container">
          {this.logos.map(i => (
            <a key={ i.id } href={ i.link } className={ i.id } target="_blank" rel="noopener noreferrer"><b></b></a>
          ))}
        </div>
        <div className="footer-shape"></div>
        <div className="footer-text">
          © {year}, SSPDS <span> ~ Todos os direitos reservados.</span>
        </div>
      </div>
    )
  }

  handleLogin = (data) => {
    const { autoClose, onLogin } = this.props
    if (autoClose) this.setState({ hidden: true })
    onLogin(data)
  }

  handleRecover = (data) => {
    this.props.onRecover(data)
  }

}
