import React, { useEffect, useState } from 'react';
import util from '../../../../helpers/util';
import './Details.scss';

export function CardCadTeamsDetails({
  show,
  hidden,
  setTitle,
  openCard,
  id,
  agents,
  inAttendance,
  vehicle,
  startedAt,
  endedAt,
}) {

  useEffect(() => {
    show();
    setTitle('CICCM Detalhes da equipe')
  }, []);

  const orderedAgents = agents.sort((a, b) => b.leader - a.leader)

  const emptyItem = item => !item || (item.value || ``).toString().trim().length === 0;

  function handleAgentNameClick(name) {
    openCard(`CardBuscaFonetica`, { name });
  }

  function renderBlockTitle(title) {
    return (
      <div className="block-title-container">
        <div className="block-title">
          <div className="block-title-text">
            {title}
          </div>
        </div>
      </div>
    )
  }

  function renderValues(items, className = ``) {
    if (!items || items.length === 0 || items.filter(emptyItem).length === items.length) {
      return null;
    }
    const key = items.map(v => `${v.name}-${v.value}`).toString();
    return (
      <div key={key} className={`line-values ${className}`}>
        {items.map((i, index) => (
          <div className={`item ` + (i.className || ``)} key={index}>
            <div className="label">{i.name}</div>
            <div className="value">{i.value || `-`}</div>
          </div>
        ))}
      </div>
    )
  }

  function renderTable(columns) {
    if (!columns || columns.length === 0) return null;

    return (
      <div className="card-table-container">
        {columns.map((col, i) => (
          <div key={`${col.title}-${i}`} className={'card-table-column ' + (col.className || '')}>
            <div className="card-table-column-title">{col.title}</div>
            {(col.values || []).map((cell, j) => (
              <div key={`${cell.toString()}-${j}`} className="card-table-cell">
                {cell}
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }

  function openVehicleCard() {
    const { plate = '' } = vehicle || {};
    openCard('CardCNHVeiculoById', { plate: plate.replace(/\W/g, ``) });
  }

  function renderPlate() {
    const { plate } = vehicle || {};

    if (!plate) return '-';

    return (
      <div className="plate-button" onClick={openVehicleCard}>
        {plate}
        <div className="plate-button-image" />
      </div>
    );
  }

  const [showLeaderTooltip, setShowLeaderTooltip] = useState(false);

  function handleLeaderTooltip() {
    setShowLeaderTooltip(!showLeaderTooltip);
  }

  function renderInfo() {
    const infoLine = [
      { name: `INÍCIO`, value: startedAt && util.datetime2str(startedAt) },
      {
        name: endedAt ? 'TÉRMINO' : 'EM ATENDIMENTO',
        value: endedAt ? util.datetime2str(endedAt) : (
          <div className={'team-in-atendance' + (inAttendance ? ' active' : '')} />
        )
      }
    ];

    const agentsTable = [
      { title: 'CÓDIGO', values: orderedAgents.map(a => a.code) },
      {
        title: 'NOME', values: orderedAgents.map(a => (
          <div className="agent-name-container" onClick={() => handleAgentNameClick(a.name)}>
            <div className="agent-name">
              {a.name}

              <div onMouseOver={handleLeaderTooltip} onMouseOut={handleLeaderTooltip} className={'leader-indication' + (a.leader ? ' visible' : '')}>
                <span className={showLeaderTooltip ? 'leader-tooltip' : 'leader-tooltip-hidden'}>
                  {a.name} é o<strong> lider</strong> dessa equipe.
                </span>
              </div>

              <div className={a.leader ? 'plate-button-image-leader' : 'plate-button-image'}/>
            </div>

          </div>
        ))
      },
      { title: 'INSTITUIÇÃO', values: orderedAgents.map(a => a.tag) },
    ];

    const vehicleLine = [
      { name: `CÓDIGO`, value: (vehicle || {}).code },
      { name: `PLACA`, value: renderPlate() },
      { name: `MARCA`, value: (vehicle || {}).brand },
      { name: `MODELO`, value: (vehicle || {}).model },
    ];

    return (
      <React.Fragment>
        {renderBlockTitle('INFORMAÇÕES GERAIS')}
        {renderValues(infoLine, `text no-border inline`)}
        {renderBlockTitle('INTEGRANTES')}
        {renderTable(agentsTable)}
        {vehicle && (
          <React.Fragment>
            {renderBlockTitle('VEÍCULO')}
            {renderValues(vehicleLine, `text no-border inline`)}
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }

  if (hidden) return null;

  return (
    <div className="CardCadTeamsDetails">
      <div className="card-title">
        CICCM AIS 13
        <span className="light">Equipe {id}</span>
      </div>
      <div className="content">
        {renderInfo()}
      </div>
    </div>
  );

}

