import React, { Component } from 'react'
import Loading from '../UI/Loading'
import './Card.scss'
import { staticFiles, api, indicators } from '../../services/api'
import { subscribe, publish } from '../../services/EventBus'
import { notifyError } from '../../services/ExceptionToServer'
import util from '../../helpers/util'
import { pdfDocumentSetup } from "../PdfDocument"
import { ReportConfig } from '../ReportConfig'
// import NetworkViewer from '../Card/RedeVinculos';

const cardNameToTitle = {
  'CardCNHHabilitacao': "Habilitação",
  'CardCNHVeiculos': "Veículos",
  'CardCNHVeiculoById': "Veículo",
  'CardEnelHistorico': "ENEL",
  'CardCivilHistorico': "Histórico Civil",
  'CardCriminalHistorico': "Registros Policiais",
  'CardSispenDetalhe': "SISPEN Apenado",
  'CardSispenVisitante': "SISPEN Visitante",
  'CardCivilSindionibus': "Bilhete Único"
}

const searchOriginList = {
  'cpf': "CPF",
  'rg': "RG",
  'cnpj': 'CNPJ',
  'idSispenVisitante': "ID SISPEN VISITANTE",
  'idSispenApenado': "ID SISPEN APENADO",
  'regNumber': "ID REGISTRO POLICIAL",
  'idDetran': "ID DETRAN",
  'plate': "PLACA",
  'motor': "MOTOR",
  'chassi': "CHASSI",
}

const tooltipVisibleValidation = [
  // { menuItem: 'Histórico Civil', tooltipValue: 'Inserir texto tooltip' },s
  { menuItem: 'Registros Policiais', tooltipValue: 'Correspondem aos registros policiais, tais como: Boletim de Ocorrência e registros criminais.' },
  // { menuItem: 'Habilitação', tooltipValue: 'Inserir texto tooltip' },
  // { menuItem: 'ENEL', tooltipValue: 'Inserir texto tooltip' },
  // { menuItem: 'Veículo', tooltipValue: 'Inserir texto tooltip' },
  // { menuItem: 'Veículos', tooltipValue: 'Inserir texto tooltip' },
  { menuItem: 'SISPEN Visitante', tooltipValue: 'Correspondem aos cadastros realizados para visitar pessoas no Sistema Penitenciário (SISPEN).' },
  { menuItem: 'SISPEN Apenado', tooltipValue: 'Correspondem aos registros no Sistema Penitenciário (SISPEN).' },
]

// arquiva referencia de cada card para movimentação na tela
const cardPosition = {}
const cardHttpJSON = {}
const cardJSONById = {}

subscribe(`card::removed`, (topic, cardId) => {
  Object.values(cardPosition).forEach(c => {
    delete cardPosition[cardId]
    if (c.props.id === cardId && c.cardHash && cardHttpJSON[c.cardHash]) {
      cardHttpJSON[c.cardHash] -= 1
    }
  })
})

subscribe(`card::autofocus`, (topic, card) => {
  const cardId = card && card.id ? card.id : card
  getLiveCards().filter(c => c.props.id === cardId).forEach(c => c.focus())
})

subscribe(`card::close`, (topic, cardId) => {
  getLiveCards().filter(c => c.props.id === cardId).forEach(c => c.handleClose())
})

subscribe(`card::minimize.animation`, (topic, data) => {
  const source = data.elem.getBoundingClientRect()
  const target = document.querySelector(`.CardMinify`).getBoundingClientRect()
  const zIndex = data.zIndex + 99
  const styleSource = `z-index:${zIndex};opacity:0.6;`
    + `top:${source.top}px;`
    + `left:${source.left}px;`
    + `width:${parseInt(source.width, 10)}px;`
    + `height:${parseInt(source.height, 10)}px;`
  const styleTarget = styleSource + `opacity:0;`
    + `top:${target.top}px;`
    + `left:${target.left}px;`
    + `width:${parseInt(target.width, 10)}px;`
    + `height:${parseInt(target.height, 10)}px;`
  const id = `card-border-` + Date.now() + `-` + Math.random().toString(10).slice(2, 9)
  const div = document.createElement(`div`)
  div.setAttribute(`id`, id)
  div.setAttribute(`class`, `card-box-minimize-animation`)
  div.setAttribute(`style`, styleSource)
  document.body.appendChild(div)
  requestAnimationFrame(() => {
    div.setAttribute(`style`, styleTarget)
    setTimeout(() => {
      document.body.removeChild(div)
    }, 3000)
  })
})

function getLiveCards() {
  return Object.values(cardPosition).filter(c => c.domRef && c.domRef.current)
}

// obtem uma posição de tela para um novo card
function getNewCardPosition() {
  const positions = getLiveCards().map(c => ({ x: c.state.x, y: c.state.y }))
  if (positions.length === 0)
    return { x: 150, y: 150 }
  const xMax = positions.map(i => i.x).reduce((a, b) => Math.max(a, b), 0)
  const yMax = positions.map(i => i.y).reduce((a, b) => Math.max(a, b), 0)
  const ratio = window.innerWidth / window.innerHeight
  const x = xMax + 30 * ratio
  const y = yMax + 30
  return { x: parseInt(x, 10), y: parseInt(y, 10) }
}

function appendCard(card) {
  cardPosition[card.id] = card
}

export default class Card extends Component {

  state = {
    id: 100000,
    createdAt: Date.now(),
    removedAt: null,
    width: 400,
    height: 300,
    x: 20,
    y: window.innerHeight,
    fullscreen: false,
    moving: false,
    savedX: 0, // posicao x,y no momento do `mouseDown`
    savedY: 0,
    hidden: true,
    noAnim: true,
    loading: true,
    loadingClass: ``,
    loadingMessage: `CARREGANDO...`,
    exception: null,
    lastLayerId: 0,
    blockedByRole: false,
    httpSuccess: 0, // total acumulado de requests sem erros
    httpErrorInterceptor: true, // fecha card caso alguma requisição inicial falhe
    current: 0,
    params: {},
    tooltipMenuVisibility: { activeTooltip: null, state: false },
    data2Print: null,
    showReportConfig: false,
    origin: null,
  }

  static getDerivedStateFromError(error) {
    return { exception: error ? error : null }
  }

  constructor(props) {
    super(props)
    this.domRef = React.createRef()
    this.id = Date.now()
    // extending http prop passed down to cards
    // such extension adds the .indicators function in http property
    // so, cards can do `this.props.http.indicators(...)` as like as http(...)
    this.doHttpRequest.indicators = (method, url, params) => {
      return this.doHttpRequest(method, url, params, indicators)
    }
    // download static files from PUBLIC
    this.doHttpRequest.staticFiles = (url) => {
      return this.doHttpRequest(`get`, url, {}, staticFiles)
    }
  }

  componentDidMount() {
    if(this.props.type === "unified"){
      const label = searchOriginList[Object.keys(this.props.origin.props)[0]]
      const value = this.props.origin.props[Object.keys(this.props.origin.props)[0]]
      this.setState({origin: {label, value}})
    }

    this.props.children.forEach((child, index) => {
      if (this.props.component.cardName === child.cardName)
        this.sideMenuChange(index)
    })

    subscribe(`card::set-identifiers`, (topic, params) => {

      const { cpf, rg, idDetran, hash, data, overrideRg } = params

      if (hash === this.props.hash) {
        const identifiersToRenameList = [
          'CardCNHVeiculos',
          'CardCNHHabilitacao'
        ];

        const result = identifiersToRenameList.find(cardName => this.props.component.cardName === cardName)

        const setCpf = result ? (cpf ? cpf : idDetran) : cpf;
        const setIdDetran = idDetran ? idDetran : cpf
        const paramsList = [{ cpf: setCpf }, { rg }, { idDetran: setIdDetran }]
        const identifiers = {}
        paramsList.forEach(param => {
          if (param.cpf !== undefined && !this.props.params.cpf) {
            identifiers.cpf = param.cpf
          }
          if (param.idDetran !== undefined && !this.props.params.idDetran) {
            identifiers.idDetran = param.idDetran
          }
          if (param.rg !== undefined && (overrideRg || !this.props.params.rg))
            identifiers.rg = param.rg
        })
        const update = { cardId: this.props.id, params: { ...this.props.params, ...identifiers, inner: true } }
        if (data) {
          update.data = data
        }
        publish(`cardmanager::set-identifiers`, update)
        this.setState({ params: { ...identifiers } })
      }
    });

    document.body.addEventListener(`mousemove`, this.handleMouseMove, false)
    document.body.addEventListener(`mouseup`, this.handleMouseUp, false)
    //
    const cardRoles = this.props.component.ROLES || []
    const userRoles = this.props.roles
    const blockedByRole = !cardRoles.every(role => userRoles.includes(role))
    //
    const { x, y } = getNewCardPosition()
    this.props.setTopZIndex(this.props.id)
    this.setState({ x, y }, () => {
      appendCard(this)
      this.timerInit0 = setTimeout(_ => {
        this.setState({ noAnim: false }, () => {
          this.timerInit1 = setTimeout(_ => {
            this.setState({ hidden: false, blockedByRole })
          }, 10)
        })
      }, 10)
    })
  }

  componentWillUnmount() {
    document.body.removeEventListener(`mousemove`, this.handleMouseMove)
    document.body.removeEventListener(`mouseup`, this.handleMouseUp)
    clearTimeout(this.timerInit0)
    clearTimeout(this.timerInit1)
  }

  componentDidCatch(error, info) {
    const { cardName } = this.props.component
    const { params } = this.props
    notifyError(error.message, error.stack, cardName, params).then(status => {
      publish(`error`, `Erro ao exibir card. O problema já foi reportado.`)
      setTimeout(() => publish(`card::close`, this.props.id), 15000)
    })
  }

  handleMinimize = () => {
    const elem = this.domRef.current
    const { zIndex } = this.props
    this.props.setVisibility(this.props.id, false)
    return publish(`card::minimize.animation`, { elem, zIndex, ...this.state })
  }

  handleClose = (error = null) => {
    this._blockCardSize()
    this.props.onClose(this.props.id, error)
    this.setState({ removedAt: Date.now(), hidden: true })
    this.publish(`map::marker.removeByCardId`, this.props.id)
    this.publish(`map::layer.removeByCardId`, this.props.id)
  }

  _blockCardSize() {
    const dom = this.domRef.current
    if (dom) {
      dom.style.width = dom.offsetWidth + `px`
      dom.style.height = dom.offsetHeight + `px`
    }
  }

  /**
   * Ao iniciar um click de mouse pode ser que o usuário queira mover o card
   * Algumas camadas/DIVs poderão permitir o movimento
   * O objetivo é que o clique possa iniciar de diferentes partes do card - não apenas header/title
   */
  handleMouseDown = (e) => {
    if (e.button !== 0 || this.state.removedAt) {
      return
    }
    // const classes = [`Card`, `CardBody`, `CardCivilHistorico`, `Detalhe`]
    // const elems = [e.target, e.target.parentNode, e.target.parentNode.parentNode]
    // const canMove = elems.map(_ => classes.includes(_.className))
    const dontMove =
      e.target.nodeName === `BUTTON` ||
      e.target.className === `value` || e.target.nodeName === `INPUT`
    if (dontMove) {
      return this.props.setTopZIndex(this.props.id)
    }

    const x = this.state.x - e.clientX
    const y = this.state.y - e.clientY
    this.props.setTopZIndex(this.props.id)
    this.setState({ moving: true, savedX: x, savedY: y })
  }

  handleMouseMove = (e) => {
    if (this.removedAt)
      return
    if (!this.state.moving) {
      return
    }
    const MIN_Y = 65
    //
    let x = e.clientX + this.state.savedX
    let y = e.clientY + this.state.savedY
    //
    y = Math.max(MIN_Y, y)
    this.setState({ x, y })
  }

  handleMouseUp = (e) => {
    if (this.removedAt)
      return
    this.setState({ moving: false })
  }

  setLoading = (loading = true) => {
    this.setState({ loading })
  }

  showCard = () => {
    const duration = Date.now() - this.state.createdAt
    setTimeout(_ => {
      this.setState({ loading: false })
    }, duration > 1500 ? 0 : 1000 - duration + parseInt(Math.random() * 1000, 10))
  }

  handleDoubleClick = (e) => {
    if (this.state.removedAt) {
      return
    }
    const classes = [`Card`, `CardBody`]
    const elems = [e.target, e.target.parentNode, e.target.parentNode.parentNode]
    const canMove = elems.map(_ => classes.includes(_.className))
    const dontMove = canMove.filter(_ => _).length === 0 || e.target.nodeName === `BUTTON`
    if (dontMove) {
      return this.props.setTopZIndex(this.props.id)
    }
    //
    this.publish(`map::card.zoom`)
    this.setState({ doubleClick: true })
    setTimeout(_ => {
      this.setState({ doubleClick: false })
    }, 100)
  }

  setCardTitle = title => {
    publish(`cardmanager::card.title`, { cardId: this.props.id, title })
  }

  /**
   * possibilita que um card possa solicitar a criação de outros
   */
  openCard = (cardName, props = {}, _internalOptions = {}, newUnifiedCard = false) => {
    const parent = _internalOptions.parent === null ? null : this.props.id
    const parentCardName = this.props.cardName
    const data = { cardName, props, parent, parentCardName, newUnifiedCard }
    const time = this.state.loading ? 1500 + parseInt(Math.random() * 1000, 10) : 1
    setTimeout(this._openCard.bind(this, data), time)
  }

  /**
   * possibilita que um card seja aberto de forma independente / órfão
   */
  openMainCard = (cardName, props = {}) => {
    this.openCard(cardName, props, { parent: null })
  }

  _openCard = data => {
    this._delay = (this._delay || 0) + 10
    setTimeout(() => {
      publish(`cardmanager::card.create`, data)
    }, this._delay)
  }

  openInnerCard = (cardName, props = {}, parent, inner, parentCardName) => {
    const data = { cardName, props, parent, inner, parentCardName }
    publish(`cardmanager::card.create`, data)
  }

  sideMenuChange = (index) => {
    this.setState({ current: index })
  }

  publish = (topic, data = {}) => {
    data = { ...data, cardId: this.props.id }
    publish(topic, data)
  }

  subscribe = (topic, callback) => {
    subscribe(topic, callback)
  }

  disableHttpErrorInterceptor = () => {
    this.setState({ httpErrorInterceptor: false })
  }

  /**
   * método deve ser invocado após requisição http de inicialização de cada card
   * objetivo: verificar cards duplicados e permitindo executar close no card repetido
   */
  isDuplicated = json => {
    if (!this.props.params.inner) {
      const { component } = this.props
      const text = [component.cardName, JSON.stringify(json)].join(`$`)
      const hash = util.basicText2hash(text)
      this.cardHash = hash
      if (cardHttpJSON[hash] === undefined) {
        cardHttpJSON[hash] = 0
        cardJSONById[hash] = this.props.id
      } else {
        setTimeout(() => publish(`card::autofocus`, cardJSONById[hash]), 200)
      }
      cardHttpJSON[hash] += 1
      return cardHttpJSON[hash] > 1
    }
  }

  /**
   * permite que cards possam invocar chamadas http via -> this.props.http(/url/, {}).then(...
   * e controle automático para casos de erro de requisição
   */
  doHttpRequest = (method, url, params, targetApi) => {
    if (params === undefined && (typeof url === `object` || url === undefined)) {
      params = url
      url = method
      method = `get`
    }

    // defining api
    targetApi = targetApi || api

    return targetApi[method.toLowerCase()](url, { params }).then(response => {
      let data = response
      if (response && typeof response.response === `function`)
        data = response.response().data
      //
      if (data && typeof data.status === `number` && typeof data.message === `string`) {
        return Promise.reject(`HTTP Error: ` + data.message)
      }
      this.setState({ httpSuccess: this.state.httpSuccess + 1 })

      // if (url.includes(`/visitor`)) {
      //   return {error: {status: 404, message: "TESTE"}}
      // }   

      return data
    }).catch(e => {
      const status = ((((e || {}).error || {}).response || {}).data || {}).status

      if(this.props.type === "unified") {
        const error = e.error.response.data
        return {error}
      }

      if (e && e.error && e.error.response && typeof e.error.response.data === `string` && /<\/\s?\w/.test(e.error.response.data)) {
        e.message = `Ocorreu um erro ao processar a requisição. Tente novamente. Caso persista o problema, contate o administrador.`
        throw e
      }
      if (!this.state.loading || this.state.httpSuccess > 0 || !this.state.httpErrorInterceptor)
        throw e
      //
      let message = `FALHA AO INICIALIZAR`
      if (status === 401) message = `ACESSO NEGADO`
      if (status === 404) message = `NENHUM DADO ENCONTRADO`
      //
      const className = [401, 404].includes(status) ? `warn` : `error`
      //
      setTimeout(() => this.setState({ loadingClass: className, loadingMessage: e.message }), 500)
      // setTimeout(() => this.setState({loadingClass: className, loadingMessage: message}), 500)
      setTimeout(() => this.handleClose(message), 8000)
      //
      return Promise.reject()
    })
  }

  focus = () => {
    const dom = this.domRef.current
    const x = parseInt(window.innerWidth * 0.5 - dom.clientWidth * 0.5, 10)
    const y = document.documentElement.scrollTop + (parseInt(window.innerHeight * 0.5 - dom.clientHeight * 0.5, 10))
    this.props.setTopZIndex(this.props.id)
    this.setState({ doubleClick: true, x, y })
    setTimeout(_ => {
      this.setState({ doubleClick: false })
    }, 100)
  }

  closeReportConfig = () => {
    this.setState({showReportConfig: false})
  }

  generateReport = ({bases, subHeader}) => {
    const checkedData = {}
    bases.forEach(base => {
      if (base === 'sispenApenado') {
        const sispenApenadoData = {...this.props.params.data[base]}
        if(this.props.params.data['sispenCollects2Print']) 
          sispenApenadoData.collects2Print = this.props.params.data['sispenCollects2Print']
        if(this.props.params.data['sispenApenadoVisits'])
          sispenApenadoData.visitors = this.props.params.data['sispenApenadoVisits']
        checkedData[base] = sispenApenadoData
      } else {
        checkedData[base] = this.props.params.data[base]
      }
    })
    const { cpf } = this.props.session.client
    const data = pdfDocumentSetup({...checkedData, origin: this.state.origin, user: {cpf: util.formatCPF(cpf)}, ...subHeader})
    setTimeout(() => {
      this.setState({ data2Print: data })
    }, 1000)
  }

  tooltipKey(tooltip) {
    return Object.values(tooltip).toString()
  }

	pushNetwork() {
		this.openMainCard('CardRedeVinculos');
		setTimeout(() => {
			publish(`cardRedeVinculos::push`, {cpf: this.state.params.idDetran});
		}, 2000);
	}

  render() {
    const left = this.state.x + `px`
    const top = this.state.y + `px`
    const { zIndex } = this.props
    const transition = this.state.moving ? `none` : null
    const style = { left, top, transition, zIndex }
    const current = this.state.current

    const {tooltipMenuVisibility, showReportConfig } = this.state

    const cardType = this.props.type;

    let s = `Card`

    cardType === 'unified' ? s += ` CardUnified-size` : s = `Card`

    if (this.props.hidden)
      s += ` hidden bg-highlight`
    if (this.props.className)
      s += ` ${this.props.className}`
    if (this.state.hidden)
      s += ` hidden`
    if (this.state.noAnim)
      s += ` no-anim`
    if (this.state.loading)
      s += ` loading`
    if (this.state.moving)
      s += ` moving`
    if (this.state.doubleClick)
      s += ` bg-highlight`

		let redeVinculosRole = false;
		try {
			// redeVinculosRole = roles.includes('###TODO');
			redeVinculosRole = true;
		} catch (error) {
			console.error('Falha ao verificar permissões');
		}

    return (
      <div ref={this.domRef} className={s} style={style} onMouseDown={this.handleMouseDown} onDoubleClick={this.handleDoubleClick}>
        <div className="card-highlight"></div>

        <React.Fragment>
          <button className="card-btn-close" onClick={this.handleClose.bind(this, null)}><span></span></button>
          <button className="card-btn-minimize" onClick={this.handleMinimize}><span></span></button>
        </React.Fragment>
        {this.state.loading ? <Loading className={this.state.loadingClass} message={this.state.loadingMessage} /> : null}
        <div className="CardBody">

          <div className="card-row">
            <div>


              <div className={this.props.type === 'unified' ? "left-menu" : "hidden-menu"}>

                {
                  this.props.origin &&
                  <>
                    <div className="search-origin">RESULTADO DA BUSCA POR: <br />
                      {searchOriginList[Object.keys(this.props.origin.props)[0]]} <br />
                      {this.props.origin.props[Object.keys(this.props.origin.props)[0]]}
                    </div>
                    <div className="divisor" />
                  </>
                }

                <div className="labels-container">
                  {
                    this.props.children.map((child, index) => {
                      return (
                        <div key={child.cardName} className="card-row">
                          <div
                            className={current === index && !showReportConfig ? `menu-labels selected` : `menu-labels`}
                            onClick={() => {
                              this.setState({showReportConfig: false })
                              this.openInnerCard(child.cardName, { ...this.state.params, ...this.props.params, inner: true }, this.props.id, true, this.props.cardName)
                              this.sideMenuChange(index)
                            }}
                            onMouseOver={(e) => this.showTooltipMenu(index) }
                            onMouseOut={(e) => this.showTooltipMenu(index) }
                          >

                            {cardNameToTitle[child.cardName]}
                          </div>

                          {tooltipVisibleValidation.map(item => {
                            if (item.menuItem === cardNameToTitle[child.cardName]) {
                              return <span key={this.tooltipKey(item)} className={(tooltipMenuVisibility.state) && (index === tooltipMenuVisibility.activeTooltip) ? "tooltip-menu-message" : "tooltip-hidden"}>{item.tooltipValue}</span>
                            } else {
                              return <span key={this.tooltipKey(item)} className="tooltip-hidden" />
                            }
                          })}

                        </div>
                      )
                    })
                  }
                </div>
                <div className="menu-footer">
                    <div className="menu-divisor" />
										{
											this.props.type === 'unified' && redeVinculosRole ? <div>
												<button
													className={`button-rede`}
													type='button'
													onClick={() => this.pushNetwork()} >
														REDE DE VÍNCULOS
												</button>
											</div> : null
										}
                    <div className={`create-report-button ${showReportConfig ? `selected` : ``}`} onClick={() => {
                      this.setState({showReportConfig: true })
                    }}>
                      <img src={require("../../images/create-report-icon.svg")} alt="" className='report-download-icon'/>
                      RELATÓRIO
                    </div>
                </div>
              </div>
            </div>
            {this.renderCardContent()}
          </div>
        </div>
      </div>
    )
  }

  showTooltipMenu(index) {
    this.setState({ tooltipMenuVisibility: { activeTooltip: index, state: !this.state.tooltipMenuVisibility.state } });
  }

  renderCardContent() {
    if (this.state.exception) {
      return (
        <div className="card-error-message">
          <b>Erro ao processar dados</b>
          <div>Foi identificado um erro ao processar as informações para apresentação.</div>
          <div>A correção estará disponível o mais breve possível.</div>
        </div>
      )
    }
    if (this.state.blockedByRole) {
      return (
        <div className="card-error-message">
          <b>Não Autorizado</b>
          <div>Você não possui permissão suficiente para visualizar a informação.</div>
        </div>
      )
    }

    if (this.state.showReportConfig) {
      return <ReportConfig 
        roles={this.props.roles} 
        show={this.showCard} 
        backAction={this.closeReportConfig} 
        http={this.doHttpRequest} 
        origin={this.state.origin} 
        hash={this.props.hash} 
        data2Print={this.state.data2Print} 
        generateReport={this.generateReport} 
        data={this.props.params.data} 
        identifiers={{...{ ...this.state.params, ...this.props.params 
        }}}/>
    }

    const CardContent = this.props.component
    return <CardContent {...{ ...this.state.params, ...this.props.params }} cardId={this.props.id}
      show={this.showCard}
      hash={this.props.hash}
      hidden={this.state.hidden || this.state.loading}
      //
      roles={this.props.roles}
      //
      setTitle={this.setCardTitle}
      setLoading={this.setLoading}
      close={this.handleClose}
      openCard={this.openCard}
      openMainCard={this.openMainCard}
      //
      publish={this.publish}
      subscribe={this.subscribe}
      //
      http={this.doHttpRequest}
      disableHttpAutoClose={this.disableHttpErrorInterceptor}
      isDuplicated={this.isDuplicated}
    />
  }
}

//REFRESH TOKEN
// import { ServiceApiLogin } from '../../services/api/login'

//ERROR 401 (doHttpRequest > catch)
//Add refreshedToken as param in doHttpRequest
// if (status === 401) {
//   if(!refreshedToken) {
//     return ServiceApiLogin.updateSession({http: true}).then(() => {
//       const { method, url, params, targetApi } = info
//       return this.doHttpRequest(method, url, params, targetApi, true)
//     })
//   }
//   publish(`auth::logout`, {http: false})
// }
