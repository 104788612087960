import React, { useEffect, useState } from 'react';
import './Details.scss';

export function PoliceStationDetails({
  http,
  show,
  setTitle,
  hidden,
  publish,
  // recieved props
  id,
  station,
}) {

  const [ stationDetails, setStationDetails ] = useState({});

  function createHighlight( point ) {
    publish(`map::marker.add`, { 
      name: `highlight`, 
      point,  
      color: !station ? `#8f4426` : '#8f442600', 
      opacity: 0.5,
      scale: 1.2, 
      className: 'circle border animated',
    });
    publish(`map::marker.zoom`, { name: `highlight` });
  }

  useEffect(() => {
    if ( id && !station ) {
      http(`/police/stations/${id}`).then(res => {
        const data = { ...res, point: res.position };
        setStationDetails(data);
        show();
        setTitle(`Delegacia ${res.id}`);
        createHighlight(res.position);
      });
    } else if ( station ) {
      setStationDetails({ ...station, point: station.position });
      show();
      setTitle(`Delegacia ${station.id}`);
      createHighlight(station.point || station.position);
    }
    
  }, [id, station])

  const emptyItem = item => !item || (item.value || ``).toString().trim().length === 0
  const nonEmptyItem = item => !emptyItem(item)

  // function renderBlockTitle( title ) {
  //   return (
  //     <div className="block-title-container">
  //       <div className="block-title">
  //         <div className="block-title-text">
  //           { title }
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }

  function renderValues(items, className=``) {
    if (!items || items.length === 0 || items.filter(emptyItem).length === items.length) {
      return null;
    }
    const key = items.map(v => `${v.name}-${v.value}`).toString();
    return (
      <div key={key} className={ `line-values ${className}` }>
        {items.map((i, index) => (
          <div className={ `item ` + (i.className || ``) } key={ index }>
            <div className="label">{ i.name }</div>
            <div className="value">{ i.value || `-` }</div>
          </div>
        ))}
      </div>
    )
  }

  function renderInfo() {
    const { 
      name,
      type,
      code,
      address,
      ais,
      city,
      territory,
      telephone,
    } = stationDetails;
    const line1 = [
      { name: `Nome`, value: name + (code ? ` - ${code}` : '') },
    ];
    const line2 = [
      { name: `Tipo`, value: type },
    ];
    const line3 = [
      { name: 'Telefone', value: telephone }
    ].filter(nonEmptyItem);
    const line4 = [
      { name: `Endereço`, value: address },
    ];
    const line5 = [
      { name: `AIS`, value: ais },
      { name: 'Cidade', value: city },
      { name: 'Território', value: territory }
    ];

    return (
      <React.Fragment>
        { renderValues(line1, `text`) }
        { renderValues(line2, `text no-border`) }
        { renderValues(line3, `text`) }
        { renderValues(line4, `text`) }
        { renderValues(line5, `text no-border`) }
      </React.Fragment>
    )
  }
  
  if ( hidden ) return null;

  return (
    <div className="CardCadOccurrenceDetails">
      <div className="card-title">
        DELEGACIA
        <span className="light">{ stationDetails.id || id }</span>
      </div>
      <div className="content">
        { renderInfo() }
      </div>
    </div>
  );

}

