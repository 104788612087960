import React, { Component } from 'react'
import './MenuBarCriminal.scss'

import { ReactComponent as LeftArrowIcon } from '../../../../images/arrow-left-icon.svg';
import { ReactComponent as RightArrowIcon } from '../../../../images/arrow-right-icon.svg';

export default class MenuBarCriminal extends Component {

  state = {
    itemsPerPage: 5,
    currentPage: 0,
    left: 0
  };

  componentDidMount() {
    this.setState({currentPage: Math.floor(this.props.current / 5)})
    this.updateTab(this.props.current % 5, this.props.totalPages)
    this.props.handlerClickMenu(this.updateTab);
  }

  render() {

    const { current, items, totalPages } = this.props
    const { currentPage, itemsPerPage } = this.state
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = items.slice(startIndex, endIndex);
    
    return (

      <div className="MenuBar-criminal">
        <div className={totalPages > 1 ? 'pagination-container' : 'hidden'}>
          <div className={currentPage === 0 || currentItems.length === 0 ? 'inactive-arrow' : 'active-arrow'} onClick={this.previousPage}><LeftArrowIcon /></div>
          <div>{currentPage + 1}/{totalPages}</div>
          <div className={totalPages === currentPage + 1 || currentItems.length === 0 ? 'inactive-arrow' : 'active-arrow'} onClick={this.loadNextPage}><RightArrowIcon /></div>
        </div>
        <div className="buttons">
          {currentItems.map((i, index) => (
            <button key={index} className={index === current % 5 ? `selected` : ``} onClick={this.click.bind(this, index)}>
              {i.regNumber}
              <div>Número Cadastro</div>
            </button>
          ))}
          {this.props.children}
        </div>
        <div className="select-line" style={{ transform: `translateX(${this.state.left}px)` }}></div>
      </div>
    )
  }

  click = (index) => {
    const { currentPage } = this.state

    this.props.onChange(index + (currentPage * 5))
    const activedTab = index * 120;
    this.setState({ left: activedTab })
  }

  updateTab = (index, pages) => {
    const { currentPage } = this.state

    if (pages <= currentPage) {
      this.setState({ currentPage: currentPage - 1 })
    }

    const activedTab = index * 120;
    this.setState({ left: activedTab })
  }

  previousPage = () => {
    const { currentPage } = this.state
    if (currentPage >= 0) {
      this.setState({ currentPage: currentPage - 1, left: 0 })
      this.props.onChange((this.state.currentPage - 1) * 5)
    }
  }

  loadNextPage = () => {
    const { currentPage } = this.state
    if (currentPage >= 0) {
      this.setState({ currentPage: currentPage + 1, left: 0 })
      this.props.onChange((this.state.currentPage + 1) * 5)
    }
  }
}
