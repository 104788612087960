import React from "react";

import "./index.scss";

const PageNotFound = () => {
  return (
    <div className="page-container">
      <div class="content">
        <div class="status">
          <p>404</p>
          <b>404</b>
        </div>
        <div class="message">Página não encontrada</div>{" "}
      </div>
    </div>
  );
};

export default PageNotFound;
