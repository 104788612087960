import React, { useEffect, useState, useMemo } from 'react';
import classNames from 'classnames';
import { List } from '../../UI/List';
import './index.scss';

const HOVER_MAP_COLOR = `#fc5`

export function CardAISList({
  http,
  show,
  setTitle,
  publish
}) {

  const [ aisList, setAisList ] = useState([])
  const [ initialized, setInitialized ] = useState(false)
  const [ errorMessage, setErrorMessage ] = useState(null)

  function mergeAisList( aisToMerge ) {
    return aisToMerge.map( ais => {
      const { geom = {}} = ais
      const { type = '' } = geom

      if (type.toLowerCase() === 'multipolygon') {
        return geom.coordinates[0]
      } else if (type.toLowerCase() === 'polygon') {
        return geom.coordinates
      }

      return null
    }).filter(coord => !!coord)
  }

  const coordinates = useMemo(() => mergeAisList(aisList.filter(ais => !ais.hidden)), [aisList])
  
  useEffect(() => {
    const params = {}

    http('/shapes/ais', params).then(res => {
      
      res = res 
        .sort( (a, b) => a.identifier - b.identifier )
        .map( ais => ({ ...ais, hidden: false }) )
      
      setAisList( res )      
      show()
      setTitle('AIS')
      setInitialized(true)

      const color = '#fff'
      const stroke = '#fff'
      const opacity = 0.2   
      const group = {color: '#fff', title: 'Áreas Integradas de Segurança' }   
      const geometry = {type: 'MultiPolygon', coordinates: mergeAisList(res)}

      publish(`map::layer.add`, {name: 'ais-list', polygons: geometry, color, stroke, opacity, group})
      publish(`map::layer.add`, {name: `hover`, polygons: [], color: HOVER_MAP_COLOR, stroke: '#fff', opacity })
      publish(`map::layer.zoom`, {name: 'ais-list'})
    }).catch(() => {
      setErrorMessage('FALHA AO CONSULTAR DADOS')
    });
  }, [])

  useEffect(() => {
    const geometry = { type: 'MultiPolygon', coordinates }
    publish(`map::layer.update`, {name: 'ais-list', polygons: geometry})
    publish(`map::layer.zoom`, {name: 'ais-list'})
  }, [coordinates, publish])

  useEffect(() => {
    if (aisList.length > 0 && aisList.every(ais => ais.hidden)) {
      setAisList(aisList.map(ais => ({...ais, hidden: false})))
    }
  }, [aisList])

  function renderEmpty() {
    return (
      <p className="message no-data">
        NENHUMA AIS ENCONTRADA
      </p>
    );
  }

  function makeAllVisible() {
    setAisList(aisList.map( ais => ({ ...ais, hidden: false })))
  }

  function onAisClick( ais ) {
    if (aisList.every(ais => !ais.hidden)) {
      const updatedAISList = aisList.map(originAIS => {
        if (originAIS.identifier !== ais.identifier) {
          return {...originAIS, hidden: true}
        }
        return originAIS
      })

      setAisList(updatedAISList)
    } else {
      const updatedAISList = aisList.map(originAIS => {
        if (originAIS.identifier === ais.identifier) {
          return {...originAIS, hidden: !ais.hidden}
        }
        return originAIS
      })

      setAisList(updatedAISList)
    }
  }

  function onRowFocus( item ) {
    publish(`map::layer.update`, {name: `hover`, polygons: item.geom});
  }

  function onRowBlur() {
    publish(`map::layer.clear`, {name: `hover`})
  }

  function renderRow( ais ) {
    const { identifier, title } = ais;

    const className = classNames(
      'table-row',
      {'activated': !ais.hidden}
    )

    return (
      <div 
        key={ identifier } 
        className={className}
        onClick={() => onAisClick(ais)}
        onMouseEnter={() => onRowFocus(ais)}
        onMouseLeave={() => onRowBlur()}
      >
        <div className="selected-bar"></div>
        <div className={`col name ${ais.hidden ? 'hidden' : ''}`} >{ title }</div>
      </div>
    )
  }

  function renderTable() {
    if (!errorMessage && initialized) {
      if (aisList.length === 0) return renderEmpty();
    
      return (
        <div className="table-list">
          <div 
            className={`table-row header${aisList.some(ais => ais.hidden) ? '' : ' dismiss'}`} 
            onClick={() => makeAllVisible()}
          >
            <div className={`text `}>
              <button className={ `col check` }></button>
              <div className="col name header">TODAS</div>
            </div>
          </div>
          <List width={ 150 } height={ 350 } rowHeight={ 32 } rows={ aisList } renderRow={ renderRow } />
        </div>
      )
    }
  }

  function renderTitle() {
    return (
      <div className="card-title">
        AIS
      </div>
    );
  }
  
  return (
    <div className="CardAISList">
      { renderTitle() }
      { renderTable() }
    </div>
  )

}