import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { List } from '../../../UI/List';
import MapCircleSelection from '../../../UI/MapCircleSelection';
import './index.scss';

export function CardPoliceStationList({
  http,
  show,
  setLoading,
  openCard,
  setTitle,
  publish,
  type,
  name,
  ais,
}) {

  const [ stations, setStations ] = useState([]);
  const [ stationFilter, setStationFilter ] = useState('');
  const [ initialized, setInitialized ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState(null);

  const isAreaSelection = type === 'circle';

  const searchMatches = useCallback(( ...words ) => {
    const filterWords = stationFilter.toUpperCase().split(' ');
    return filterWords.every( filter => words.some( word => word.toUpperCase().includes(filter) ) );
  }, [ stationFilter ]);

  const filteredStations = useMemo(() => (
    stations.filter(station => searchMatches(station.id, station.name))
  ), [ stations, searchMatches ]);

  function createPopup( item ) {
    return { title: item.name };
  }

  function onPoliceStationClick( station ) {
    openCard('PoliceStationDetails', { station });
  }

  function onRowFocus( item ) {
    publish(`map::layer.update`, {name: `current`, points: [item,]});
  }

  function onRowBlur() {
    publish(`map::layer.clear`, {name: `current`});
  }

  useEffect(() => {
    const group = {color: `#BF5A28`, title: `Delegacias`}
    publish('map::layer.add', { name: `police-stations`, points: [], group, color: `#BF5A28`, opacity: 0.75, popup: createPopup, click: onPoliceStationClick });
    publish(`map::layer.add`, { name: `current`, points: [], group, color: `#BF5A28`, scale: 1.3})
  }, [])

  useEffect(() => {
    if (!isAreaSelection) {
      const params = {}
      if (name) params.name = name;
      if (ais) params.ais = ais;

      http('/police/stations/search', params).then(res => {
        const data = res.map(o => ({ ...o, point: o.position }))
        setStations(data);
        show();
        setTitle(`DELEGACIAS`);
      });
    } else {
      show();
      setTitle(`DELEGACIAS POR ÁREA`);
    }
  }, [ type, name, ais ]);

  useEffect(() => {
    publish('map::layer.update', { name: 'police-stations', points: filteredStations });
    publish(`map::layer.zoom`, { name: 'police-stations' });
  }, [ filteredStations ]);

  

  function handleFilterChange( e ) {
    setStationFilter( e.target.value );
  }

  function handleAreaSelection({ center, radius }) {
    setLoading()
    publish(`map::layer.clear`, { name: `police-stations` })
    setInitialized(true);

    const params = {
      longitude: center[0],
      latitude: center[1],
      radius
    }

    http('/police/stations', params).then( res => {
      const data = res.map(o => ({ ...o, point: o.position }))
      setStations(data);
    }).catch(() => {
      setErrorMessage('FALHA AO CONSULTAR DADOS')
    }).finally(() => {
      show();
      setTitle(`DELEGACIAS`);
    })
  }

  function renderEmpty() {
    return (
      <p className="message no-data">
        NENHUMA DELEGACIA ENCONTRADA
      </p>
    );
  }

  function renderRow( station ) {
    const { id, name } = station;
    return (
      <div 
        key={ id } 
        className="table-row" 
        onClick={ () => onPoliceStationClick(station) } 
        onMouseEnter={() => onRowFocus(station)}
        onMouseLeave={() => onRowBlur(station)}
      >
        <div className="col id">{ id }</div>
        <div className="col name">{ name }</div>
      </div>
    )
  }

  function renderTable() {
    if (!errorMessage && (!isAreaSelection || initialized)) {
      if (filteredStations.length === 0) return renderEmpty();
    
      return (
        <div className="table-list">
          <div className="table-row header">
            <div className="col id">CÓDIGO</div>
            <div className="col name">NOME</div>
          </div>
          <List width={ 530 } height={ 350 } rowHeight={ 32 } rows={ filteredStations } renderRow={ renderRow } />
        </div>
      )
    }
  }

  function renderCircleComponent() {
    const message = initialized ? null : (
      <p className="message empty">
        AGUARDANDO DEFINIÇÃO DE ÁREA NO MAPA...
      </p>
    );
    return (
      <React.Fragment>
        <MapCircleSelection onChange={ handleAreaSelection } />
        { errorMessage ? <p className="message empty">{ errorMessage }</p> : message }
      </React.Fragment>
    )
  }

  function renderSummary() {
    if (!errorMessage && (!isAreaSelection || initialized)) {
      return (
        <div className="summary">
          <div className="input">
            <input placeholder="Filtro..." onChange={ handleFilterChange } />
            <div className="total-hidden">
              { `${filteredStations.length} / ${stations.length}` }
            </div>
          </div>
        </div>
      )
    }
  }

  function renderAreaInfo() {
    return (
      <div className="container">
        { type === 'circle' && renderCircleComponent() }
        { renderSummary() }
      </div>
    )
  }

  function renderTitle() {
    const subtitle = [ ais && `AIS ${ais}`, name ].filter(x => x).join(' - ');
    return (
      <div className="card-title">
        DELEGACIAS
        <span>{subtitle}</span>
      </div>
    );
  }

  return (
    <div className="CardPoliceStationList">
      { renderTitle() }
      { renderAreaInfo() }
      { renderTable() }
    </div>
  )

}
