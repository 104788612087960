import axios from 'axios'
import { DEFAULT_AUTH_HOST } from './defaults';

export const auth = ({ app, host, credentials } = {}) => {
  // prepare
  host = host || DEFAULT_AUTH_HOST
  
  // validates
  if (!app) return Promise.reject('aplicação não informada')

  // runs
  return axios({
    method: 'post',
    url: `${host}/auth`,  
    headers: {
      'Accept-Client': app
    },
    data: credentials
  }).then(handleAuthSuccess, handleAuthError)
};

const handleAuthSuccess = response => response.data

const handleAuthError = error => {
  const { response } = error;
  const responseError = {};
  if (response) {
    responseError.status = response.status;
    responseError.message = response.data.message;
  } else {
    console.error('[SPI-OAUTH2-JS | ERROR]', error.message)
    responseError.request && console.log('Request', error.request)
    responseError.message = 'Não foi possível obter suas informações.';
  } 
  return Promise.reject(responseError);
};