import React, { Component } from 'react'
import TableChart from '../../../UI/TableChart'
import util from '../../../../helpers/util'
import parser from './groupByMonth'


export class CardCVLIReport extends Component {

  static ROLES = [`CEREBRUM_BUSCA_CVLI_REPORT`]

  componentDidMount() {
    const query = {
      city: this.props.city,
      startDate: this.props.startDate ? util.date2sql(this.props.startDate) : null,
    }
    this.props.http.indicators(`/cvli/report`, query).then(results => {
      const points = results.filter(_ => _.position)
      points.forEach(p => p.point = p.position)
      const data = parser(points)
      const years = data.data.map(_ => _.label)
      this.setState({points, years, data})
      const group = {color: `#fa2`, title: `CVLI`}
      this.props.publish(`map::layer.add`, {name: `cvli`, points, group, color: `#fa2`, popup: this.createPopup, click: this.clickPopup})
      this.props.publish(`map::layer.zoom`, {name: `cvli`})
      this.props.show()
      this.props.setTitle(`CVLI RELATÓRIO ` + this.getCardTitleYears() + ` em ` + this.props.city)
    })
  }

  createPopup = item => {
    return { title: item.procedureType, text: item.id }
  }

  clickPopup = item => {
    const isMaster = this.props.roles.includes(`CEREBRUM_INQ_DETALHES`)
    const cardName = isMaster ? `CardCVLIOcorrencia` : `CardCVLIPopupDetails`
    this.props.openCard(cardName, item)
  }

  updateLayer = (points) => {
    this.props.publish(`map::layer.update`, {name: `cvli`, points})
    this.props.publish(`map::layer.zoom`, {name: `cvli`})
  }

  selectedCell = (label, index, value) => {
    const filter = {ano: parseInt(label, 10), mes: index + 1}
    const points = this.state.points.filter(i => {
      return i.year === filter.ano && i.month === filter.mes
    })
    this.updateLayer(points)
  }

  rowSelected = (rowLabel) => {
    const ano = +rowLabel
    const points = this.state.points.filter(_ => _.year === ano)
    this.updateLayer(points)
  }

  colSelected = (index) => {
    const mes = index + 1
    const points = this.state.points.filter(_ => _.month === mes)
    this.updateLayer(points)
  }

  clearSelection = () => {
    this.updateLayer(this.state.points)
  }

  getCardTitleYears = () => {
    const { startDate } = this.props
    if (startDate) {
      return `desde ` + util.date2str(startDate)
    }
    return `ano de ` + new Date().getFullYear()
  }

  render() {
    if (!this.state || !this.state.data) {
      return null
    }
    const title = `RELATÓRIO CVLI - ` + this.getCardTitleYears() + ` em ` + this.props.city
    const { header, data } = this.state.data
    return (
      <React.Fragment>
        <div className="card-title">{ title }</div>

        <TableChart header={ header } data={ data }
                    selected={ this.selectedCell }
                    rowSelected={ this.rowSelected }
                    colSelected={ this.colSelected }
                    clearSelection={ this.clearSelection }
        />
      </React.Fragment>
    );
  }

}
