import React, { Component } from 'react'
import { indicators as httpIndicators } from '../../services/api'
import { subscribe, publish } from '../../services/EventBus'
import Chart from './Chart'
import CenterPanel from './CenterPanel'
import Tooltip from './Tooltip'
import parser from './parserSSPDS'
import './index.scss'

export default class Thermometer extends Component {

  state = {
    hidden: false
  }

  componentDidMount() {
    httpIndicators.get(`/thermometer`).then(json => {
      const { periodTypes, models, years } = parser(json)
      const periodSelected = periodTypes[0]
      const indicators = models[periodSelected.attr]
      this.setState({indicators, years, periodTypes, models, periodSelected, maximized: false})
    })
    subscribe(`cardmanager::visibility`, (topic, value) => {
      this.setState({hidden: !value})
    })
    subscribe(`thermometer::indicator.period`, (topic, data) => {
      const indicators = this.state.models[data.attr]
      this.setState({indicators, periodSelected: data})
    })
    subscribe(`thermometer::mouse.enter`, () => {
      clearTimeout(this.timer)
      this.show()
    })
    subscribe(`thermometer::mouse.leave`, () => {
      clearTimeout(this.timer)
      this.timer = setTimeout(this.hide, 1500)
    })
    subscribe(`thermometer::minimize`, () => {
      clearTimeout(this.timer)
      this.hide()
    })
  }

  show = () => this.notifyVisibility(true)
  hide = () => this.notifyVisibility(false)

  hideTooltipHint = () => {
    const hint = document.querySelector(`.Tooltip-hint`)
    if (hint) {
      hint.style.opacity = 0
    }
  }

  notifyVisibility = (visible) => {
    if (!visible) {
      setTimeout(this.hideTooltipHint, 1000)
    }
    publish(visible ? `thermometer::visible` : `thermometer::hidden`)
  }

  render() {
    if (!this.state || !this.state.models) {
      return null
    }
    const { hidden, models, periodTypes, periodSelected, indicators } = this.state
    const root = indicators[0]
    return (
      <div className={ `Thermometer` + (hidden || this.props.hidden ? ` hidden` : ``) }>
        <Tooltip rootIndicator={ root } periodSelected={ periodSelected } />
        <CenterPanel rootIndicator={ root } periodTypes={ periodTypes } />
        <Chart data={ indicators } models={ models } />
      </div>
    )
  }

}

