
const MESES = [`JAN`, `FEV`, `MAR`, `ABR`, `MAI`, `JUN`, `JUL`, `AGO`, `SET`, `OUT`, `NOV`, `DEZ`]

/**
 * recebe o json sobre CVLIs e retorna uma estrutura para plotagem mais `facil` em tabela
 */
export default function parser(json) {
  var minYear = +999999
  var maxYear = -999999
  json.forEach(i => {
    i.date = new Date(i.date)
    i.year = i.date.getFullYear()
    i.month = i.date.getMonth() + 1
    if (i.year < minYear) minYear = i.year
    if (i.year > maxYear) maxYear = i.year
  })
  //
  var data = {
    header: MESES,
    data: [],
  }
  //
  var anos = []
  for (var i = minYear; i <= maxYear; i++)
    anos.push(i)
  //
  anos.forEach(ano => {
    var cells   = []
    const items = json.filter(_ => _.year === ano)
    for (var i = 1; i <= 12; i++) {
      cells.push(filterItemsByMonth(items, i).length)
    }
    data.data.push({label: ano, cells})
  })
  //
  return data
}

function filterItemsByMonth(items, month) {
  return items.filter(_ => _.month === month)
}
