import React, { Component } from 'react'
import Detalhe from './Detalhe'
import { publish } from "../../../../services/EventBus";
import MenuBarCriminal from './MenuBarCriminal';
import util from '../../../../helpers/util';
import BodyAsync from '../../../SearchPanel/BodyAsync';


import './index.scss';

export class CardCriminalHistorico extends Component {

  static ROLES = [
    `CEREBRUM_BUSCA_CRIMINAL`,
  ]

  state = {
    data: null,
    current: 0,
    currentContent: 0,
    reportList: [],
    reportDetailsList: [],
    reportFromClickRow: [],
    loading: true,
    loadingSelectedRow: false,
    rows: [],
    showBodyAsync: false,
    error: null,
    updateMenuTab: () => { }
  }

  dataSetup(data) {
    if (data.images) {
      data.images.reverse()
    }

    this.setState({ data, reportDetailsList: [data], loading: false }, () => {
      this.props.setTitle(`CRIMINAL ` + data.details.regName)
      this.props.show()
    })
  }

  apiRequest(regNumber) {
    this.props.http(`/criminal/details/` + regNumber).then(data => {
      const { error } = data
      if (error) {
        const searchItem = this.state.reportList.find(
          (item) => item.regNumber === regNumber
        );
        const detailsObj = {
          error,
          "details": {},
          "openArrestWarrants": [],
          "arrests": [],
          "recap": [],
          "images": [],
          "aliases": []
        }
        if (searchItem) {
          detailsObj.details = searchItem
          publish(`card::set-identifiers`, { hash: this.props.hash, data: {criminalDetails: [detailsObj]} })
        }
        return this.dataSetup(detailsObj)
      }
      this.dataSetup(data)
      publish(`card::set-identifiers`, { hash: this.props.hash, data: {criminalDetails: [data]} })
    })
  }

  apiRequestClickRow(regNumber) {
    this.props.http(`/criminal/details/` + regNumber).then(data => {
      const { error } = data
      if (error) {
        const searchItem = this.state.reportList.find(
          (item) => item.regNumber === regNumber
        );
        const detailsObj = {
          error,
          "details": {},
          "openArrestWarrants": [],
          "arrests": [],
          "recap": [],
          "images": [],
          "aliases": []
        }
        if (searchItem) {
          detailsObj.details = searchItem
        }
        return this.setState({ reportFromClickRow: [...this.state.reportFromClickRow, detailsObj], loadingSelectedRow: false })
      }
      this.setState({ reportFromClickRow: [...this.state.reportFromClickRow, data], loadingSelectedRow: false })
    })
  }

  menuChangeRequest(regNumber, index) {
    const { reportDetailsList } = this.state
    let indexContent
    this.state.reportDetailsList.forEach((report, index) => {
      if (report.details.regNumber === regNumber) {
        indexContent = index
      }
    })

    if (indexContent !== undefined) {
      this.setState({ currentContent: indexContent, current: index })
    } else {
      this.props.http(`/criminal/details/` + regNumber).then(data => {
        const { error } = data
        if (error) {
          const searchItem = this.state.reportList.find(
            (item) => item.regNumber === regNumber
          );
          const detailsObj = {
            error,
            "details": {},
            "openArrestWarrants": [],
            "arrests": [],
            "recap": [],
            "images": [],
            "aliases": []
          }
          if (searchItem) {
            detailsObj.details = searchItem
          }
          this.setState({ reportDetailsList: [...reportDetailsList, detailsObj], currentContent: reportDetailsList.length, current: index })
          return publish(`card::set-identifiers`, { hash: this.props.hash, data: {criminalDetails: [...reportDetailsList, detailsObj]} })
        }
        this.setState({ reportDetailsList: [...reportDetailsList, data], currentContent: reportDetailsList.length, current: index })
        publish(`card::set-identifiers`, { hash: this.props.hash, data: {criminalDetails: [...reportDetailsList, data]} })
      })
    }


  }

  responseSetup = (identidades) => {
    identidades.sort(
      (a, b) =>
        new Date(b.rgInfo.identityDate || Date.now()).getTime() -
        new Date(a.rgInfo.identityDate).getTime()
    );
    const name = identidades[0].basicInfo.name;

    this.props.http(`/criminal/search`, { name }).then((data) => {
      if (data.error) {
        this.props.show()
        return this.setState({loading: false})
      }
      const civilCPF = this.getCPFNumbers(identidades);
      const civilRG = this.getRGNumbers(identidades);

      const criminalReports = [];

      data.forEach((item) => {
        const infoValidation = {
          CPF: false,
          RG: false,
          regName: false,
          Pai: false,
          Mãe: false,
          "Data de Nascimento": false,
        };

        const { cpf, rg, regName, father, mother, birthday } = item;

        if (cpf) {
          if (civilCPF[0] === cpf) {
            item.isChecked = true;
            criminalReports.push(item);
          } else if (rg) {
            if (civilRG[0] === rg) {
              item.isChecked = true;
              criminalReports.push(item);
            }
          }
        } else if (rg) {
          if (civilRG[0] === rg) {
            item.isChecked = true;
            criminalReports.push(item);
          }
        }

        if (cpf) {
          if (civilCPF[0] === cpf) {
            infoValidation["CPF"] = true;
          }
        }
        if (rg) {
          if (civilRG[0] === rg) {
            infoValidation["RG"] = true;
          }
        }
        if (regName) {
          if (identidades[0].basicInfo.name === regName) {
            infoValidation["regName"] = true;
          }
        }
        if (father) {
          if (identidades[0].basicInfo.father === father) {
            infoValidation["Pai"] = true;
          }
        }
        if (mother) {
          if (identidades[0].basicInfo.mother === mother) {
            infoValidation["Mãe"] = true;
          }
        }
        if (birthday) {
          if (identidades[0].basicInfo.birthDate === birthday) {
            infoValidation["Data de Nascimento"] = true;
          }
        }

        item.infoValidation = infoValidation;
        const row = this.createRow(item);

        this.setState({ rows: [...this.state.rows, row] });
      });

      const rows = this.state.rows.sort(
        (a, b) => this.countCheckValidation(b) - this.countCheckValidation(a)
      );

      publish(`card::set-identifiers`, {
        hash: this.props.hash,
        data: {
          rows: rows,
          criminalSearch: criminalReports,
        },
      });

      if(rows.length > 0){
        this.setState({ rows: rows });
      }
      
      if (criminalReports.length > 0) {
        const { current } = this.state;
        this.setState({ reportList: criminalReports })
        this.apiRequest(criminalReports[current].regNumber)
      } else {
        this.setState({ loading: false });
      }
    });
  };

  componentDidMount() {
    const { current } = this.state;
    const { data, regNumber, hash, http, cpf, rg } = this.props
    const { criminalSearch = [], criminalDetails = [], rows = []} = data || {}
    if (data && (criminalSearch.length > 0 || rows.length > 0)) {
      if(criminalDetails.length > 0){
        this.setState({
          reportDetailsList: criminalDetails
        });
        this.dataSetup(criminalDetails[current]);
      }
      if(criminalSearch.length > 0) {
        this.setState({
          reportList: criminalSearch,
          loading: false
        });
      }
      if(rows.length > 0){
        this.setState({
          rows: rows,
          loading: false
        });
      }
    } else if (regNumber) {
      http(`/criminal/details/` + regNumber)
        .then((data) => {
          const { error } = data
          if (error) {
            this.props.show()
            return this.setState({ loading: false, error });
          }
          const { cpf, rg } = data.details;
          this.setState({ reportList: [data.details] });
          publish(`card::set-identifiers`, {
            hash: hash, cpf, rg,
            data: { criminalDetails: [data], criminalSearch: [data.details]},
          });

          this.dataSetup(data);
          let query = rg ? { rg } : cpf ? { cpf } : null;

          if (query) {
            http(`/civil/details`, query).then((identidades) => {
              const { error } = identidades
              if (error) {
                return;
              }
              if (identidades.length === 0) {
                if (query.rg && cpf) {
                  query = { cpf };
                  http(`/civil/details`, query).then((rgs) => {
                    const { error } = rgs
                    if (error) {
                      return;
                    }
                    if(rgs.length > 0) {
                      return this.responseSetup(rgs);
                    }
                    this.setState({ loading: false });
                  });
                  return;
                } else {
                  this.setState({ loading: false });
                  return;
                }
              }
              this.responseSetup(identidades);
            });
          }
        });
    } else {
      let query = rg ? { rg } : cpf ? { cpf } : null;

      if (query){
        http(`/civil/details`, query).then((identidades) => {
          const { error } = identidades
          if (error) {
            this.props.show()
            return this.setState({ loading: false, error });
          }
          if (identidades.length > 0) {
            this.responseSetup(identidades);
          } else {
            if (query.rg && cpf) {
              query = { cpf };
              http(`/civil/details`, query).then((rgs) => {
                const { error } = rgs
                if (error) {
                  this.props.show()
                  return this.setState({ loading: false, error });
                }
                if (rgs.length > 0) {
                  return this.responseSetup(rgs);
                }
                return this.setState({ loading: false });
              });
            } else {
              this.setState({ loading: false });
              publish(`card::set-identifiers`, {
                hash: hash,
                data: {
                  rows: [],
                  criminalSearch: [],
                },
              });
            }
          }
        });
      } else {
        this.setState({ loading: false });
        publish(`card::set-identifiers`, {
          hash: hash,
          data: {
            rows: [],
            criminalSearch: [],
          },
        });
      }
    }
  }

  countCheckValidation = (param) => {
    const count = Object.values(param.props.infoValidation).filter(rowValue => rowValue)
    return count.length
  }

  getCPFNumbers = (identidades) => {
    const numbers = {}
    identidades.forEach(i => {
      if (i.documentInfo && i.documentInfo.cpf && i.documentInfo.cpf.length > 0) {
        const { cpf } = i.documentInfo
        numbers[cpf] = true
      }
    })
    return Object.keys(numbers)
  }
  getRGNumbers = (identidades) => {
    const numbers = {}
    identidades.forEach(i => {
      if (i.rgInfo) {
        numbers[i.rgInfo.rg] = true
      }
    })
    return Object.keys(numbers)
  }

  extractRG = (json) => {
    let rg = ``
    if (json.rg) rg += json.rg + ` `
    if (json.rgEmitter) rg += json.rgEmitter + ` `
    if (json.federalUnitRgEmitter) rg += json.federalUnitRgEmitter
    return rg.trim()
  }

  extractCPF = (json) => {
    if (json.cpf)
      return json.cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4')
  }

  createRow = (json) => {
    const name = json.regName
    const { aliases, picture, mother, father } = json
    const birthday = json.birthday ? util.date2str(json.birthday) : null
    const rg = this.extractRG(json)
    const cpf = this.extractCPF(json)
    const lines = []
    if (aliases && aliases.length) {
      const alcunha = aliases.filter(i => i && i !== `SEM ALCUNHA`).join(`, `).toUpperCase()
      if (alcunha)
        lines.push([{ label: `ALCUNHA`, value: alcunha }])
    }
    lines.push([
      { label: `RG`, value: rg },
      { label: `CPF`, value: cpf },
    ])
    lines.push([{ label: `Data de Nascimento`, value: birthday }])
    lines.push([{ label: `Mãe`, value: (mother || ``).toUpperCase() }])
    lines.push([{ label: `Pai`, value: (father || ``).toUpperCase() }])
    return {
      card: `CardCriminalHistorico`,
      props: json,
      //
      image: picture || `avatar.png`,
      title: name,
      lines
    }
  }

  loadExtraCards = () => {
    const { cpf, rg } = this.state.data.details
    if (this.props.roles.includes(`CEREBRUM_SINDIONIBUS`)) {
      this.props.openCard(`CardCivilSindionibus`, cpf ? { cpf } : { rg })
    }
    if (this.props.roles.includes(`CEREBRUM_BUSCA_CIVIL`)) {
      this.props.openCard(`CardCivilHistorico`, cpf ? { cpf } : { rg })
    }
    if (cpf) {
      if (this.props.roles.includes(`CEREBRUM_BUSCA_CNH_CPF`)) {
        this.props.openCard(`CardCNHHabilitacao`, { id: cpf })
      }
    }
  }

  selectRowHandler = (row) => {
    const { regNumber } = row.props;
    let selectedIndex
    const rows = this.state.rows.map((row, index) => {
      if (row.props.regNumber === regNumber) {
        row.props.isChecked = !row.props.isChecked;
        selectedIndex = index
      }
      return row;
    });

    if (rows[selectedIndex].props.isChecked) {
      this.setState({
        reportList: [...this.state.reportList, row.props],
        rows: rows,
      });
      publish(`card::set-identifiers`, {
        hash: this.props.hash,
        data: { 
          criminalSearch: [...this.state.reportList, row.props],
          rows: rows
        },
      });
      this.menuChangeRequest(regNumber, this.state.reportList.length)
    } else {
      const reportList = this.state.reportList.filter((report) => {
        return report.regNumber !== regNumber;
      })
      const current =
        this.state.current !== 0 ? this.state.current - 1 : this.state.current;
      this.setState({
        reportList: reportList,
        rows: rows,
      });
      publish(`card::set-identifiers`, {
        hash: this.props.hash,
        data: { 
          criminalSearch: reportList,
          rows: rows
        },
      });
      if (reportList.length > 0)
        this.menuChangeRequest(reportList[current].regNumber, current)
    }
  };

  menuClickHandler = (click) => {
    this.setState({ updateMenuTab: click })
  }

  innerSelectRowHandler = (row) => {
    const { regNumber } = row;
    const rows = this.state.rows.map((row, index) => {
      if (row.props.regNumber === regNumber) {
        row.props.isChecked = !row.props.isChecked;
      }
      return row;
    });

    const reportList = this.state.reportList.filter((report) => {
      return report.regNumber !== regNumber;
    })
    const current =
      this.state.current % 5 === 0 && reportList[this.state.current] !== undefined ? this.state.current : this.state.current - 1;

    this.setState({
      reportList: reportList,
      rows: rows,
    });

    publish(`card::set-identifiers`, {
      hash: this.props.hash,
      data: { 
        rows: rows, 
        criminalSearch: reportList 
      },
    });

    if (reportList.length > 0) {
      const totalPages = Math.ceil(reportList.length / 5)
      this.menuChangeRequest(reportList[current].regNumber, current)
      this.state.updateMenuTab(current % 5, totalPages)
    }
  };

  renderContent = () => {
    const { reportList, reportDetailsList, current, currentContent } = this.state
    const totalPages = Math.ceil(reportList.length / 5)
    if (this.state.showBodyAsync) {
      return (
        <>
          <div className="return-button-container">
            <div onClick={() => { this.setState({ showBodyAsync: !this.state.showBodyAsync }) }} className="return-button">CONFIRMAR VÍNCULOS</div>
          </div>
          <div className="label-list">Lista de possibilidades:</div>
          {this.state.rows.length > 0 
            ? <BodyAsync fromCriminalCard={true} onClick={this.handleClickRow} rows={this.state.rows} handleSelectedRow={this.selectRowHandler} />
            : <p className="message">Não há possíveis registros com os dados utilizados</p>
          }
        </>
      )
    }

    if (this.state.loadingSelectedRow) {
      return <div className="message-loading">CARREGANDO...</div>
    }

    return (
      <>
        <div className="return-button-container">
          <div onClick={() => this.setState({ showBodyAsync: !this.state.showBodyAsync })} className="return-button">POSSIBILIDADES</div>
        </div>
        <div className="menuCriminal-container">
          {reportList.length > 0 ? <MenuBarCriminal items={reportList} totalPages={totalPages} current={current} onChange={this.menuChange} handlerClickMenu={this.menuClickHandler} /> : null}
        </div>
        <div>
          { this.state.rows.length > 0 &&
            <div className={`return-button-container ${reportDetailsList[currentContent].error ? `unlink` : ``}`}>
              <div onClick={() => this.innerSelectRowHandler(reportList[current])} className="return-button">DESVINCULAR REGISTRO</div>
            </div>
          }
          <div className="error-container">
            {reportDetailsList[currentContent].error ? <p className='error-message'><span className='alert-icon' />Ocorreu um erro. Não foi possível carregar todas as informações deste registro.</p> : null}
          </div>
          <Detalhe roles={this.props.roles} openCard={this.props.openCard} {...reportDetailsList[currentContent]} />
        </div>
      </>
    )
  }

  handleChangeCurrent = (index) => {
    this.setState({ current: index })
  }

  renderUncheckedList = (rows) => {
    if (this.state.showBodyAsync) {
      return (
        <>
          <div className="return-button-container">
            <div onClick={() => { this.setState({ showBodyAsync: !this.state.showBodyAsync })}} className="return-button">CONFIRMAR VÍNCULOS</div>
          </div>
          <div className="label-list">Lista de possibilidades:</div>
          {this.state.rows.length > 0 
            ? <BodyAsync fromCriminalCard={true} onClick={this.handleClickRow} rows={this.state.rows} handleSelectedRow={this.selectRowHandler} />
            : <p className="message">Não há possíveis registros com os dados utilizados</p>
          }
        </>
      )
    }

    return (
      <>
        <div className="return-button-container">
          <div onClick={() => this.setState({ showBodyAsync: !this.state.showBodyAsync })} className="return-button">POSSIBILIDADES</div>
        </div>
        <p className="message">{this.state.error ? `NENHUM REGISTRO ENCONTRADO` : `NENHUM REGISTRO ADICIONADO AO GRUPO`}</p>
      </>
    )
  }


  render() {
    const { current, rows } = this.state

    return (
      <div className="CardHistoricoCriminal">

        <div className="card-title category">
          <div className="criminal-title">REGISTROS POLICIAIS</div>
        </div>

        {this.state.loading
          ? <div className="message-loading">CARREGANDO...</div>
          : this.state.reportList.length > 0 && this.state.reportDetailsList.length > 0
            ? this.renderContent(current)
            : this.renderUncheckedList(rows)
        }
      </div>

    )
  }

  handleClickRow = (row) => {
    if (!this.state.reportFromClickRow.find(report => report.details.regNumber === row.props.regNumber)) {
      this.setState({ loadingSelectedRow: true });
      this.apiRequestClickRow(row.props.regNumber);
    }

    this.setState({ showBodyAsync: !this.state.showBodyAsync });
  }


  menuChange = index => {
    this.menuChangeRequest(this.state.reportList[index].regNumber, index)
  }
}

