import React, { Component } from 'react'
import { App } from '../../../../App'
import config from '../../../../config'
import util from '../../../../helpers/util'
import srcImageAvatar from '../../../../images/avatar.png'
import './Detalhe.scss'


const Item = (props) => (
  <div className="container-item">
    <div className={`item ` + (props.className || ``)}>
      <label>{props.label}</label>
      <div className="value">{props.children || `-`}</div>
    </div>
  </div>
)

const fixImagePath = (imageId) => {
  const host = config.gatewayApp.replace(/\/$/, ``)
  const queryString = Object.entries({ ...App.headers }).map(i => i.join(`=`)).join(`&`)
  return `${host}/criminal/image/${imageId}?${queryString}`
}

export default class Detalhe extends Component {

  state = {
    picture: false,
    images: []
  }

  constructor(props) {
    super(props)
    this.state.images = (props.images || []).map(i => {
      return { path: fixImagePath(i.id), label: util.date2str(i.date), loaded: false, error: null }
    })
  }

  render() {
    const { picture } = this.state;
    return (
      <div className={`CriminalHistoricoDetalhe` + (picture ? ` picture-mode` : ``)}>
        <div className="columns">
          <div className="column photo">{this.renderPicture()}</div>
          <div className="column info">
            {picture
              ? this.renderCriminalImages()
              : (
                <>
                  {this.add(`details`)}
                  {this.renderPanels()}
                </>
              )}
          </div>
        </div>
      </div>
    );
  }

  renderPanels() {
    return (
      <div className="panels">
        {this.renderOpenArrestWarrants()}
        {this.renderArrestments()}
        <div className="panel">
          {this.add(`recap`)}
        </div>
      </div>
    )
  }

  add(propertyName) {
    const json = this.props[propertyName]
    if (typeof json === `object` && json.constructor === Array) {
      return this[propertyName](json)
    }
    return json && Object.keys(json).length ? this[propertyName](json) : null
  }

  renderOpenArrestWarrants() {
    const procurado = this.props.openArrestWarrants.length > 0
    if (!procurado) {
      return this.criminalHistoryClean()
    }
    return (
      <div className="panel yellow red">
        {this.add(`openArrestWarrants`)}
      </div>
    )
  }

  renderArrestments() {
    if (this.props.arrests.length === 0)
      return null
    return (
      <div className="panel yellow">
        {this.add(`arrests`)}
      </div>
    )
  }

  formatCPF = text => {
    return (text + ``).replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4')
  }

  formatCEP = text => {
    return (text + ``).replace(/^(\d+)(\d{3})(\d{3})$/, `$1.$2-$3`)
  }

  formatSex = text => {
    if (text === `M`) return `MASCULINO`
    if (text === `F`) return `FEMININO`
    if (text === `I`) return `INDEFINIDO`
    return typeof text === `string` && text.length ? text : `-`
  }

  formatDate = (text) => {
    if (text.length === 8) {
      return text.slice(0, 2) + `/` + text.slice(2, 4) + `/` + text.slice(4)
    }
    const date = new Date(text)
    const d = date.getDate()
    const m = date.getMonth() + 1
    const a = date.getFullYear()
    return (`0` + d).slice(-2) + `/` + (`0` + m).slice(-2) + `/` + a
  }

  formatPunishmentTime = item => {
    const d = item.punishmentDaysQnt
    const m = item.punishmentMonthsQnt
    const y = item.punishmentYearsQnt
    let output = []
    if (y !== undefined && y !== 0)
      output.push(y === 1 ? `1 ano` : `${y} anos`)
    if (m !== undefined && m !== 0)
      output.push(m === 1 ? `1 mês` : `${m} meses`)
    if (d !== undefined && d !== 0)
      output.push(d === 1 ? `1 dia` : `${d} dias`)
    return output.join(`, `).replace(/,\s+(\d+\s+\w+)$/, ` e $1`)
  }

  renderPicture = () => {
    const defaultImage = srcImageAvatar
    const url = (this.props.details || {}).picture || defaultImage
    const src = /^data/.test(url) || /\.\w{3,4}$/.test(url) ? url : (`data:image/png;base64,` + url)
    const isDefault = src === defaultImage
    return (
      <div className={`picture` + (isDefault ? ` empty` : ``)} onClick={this.togglePictureSize}>
        <img src={src} alt="" />
        <div className="see-more">
          <div className={`icon ${this.state.picture ? `close` : ``}`}></div>
        </div>
      </div>
    )
  }

  renderCriminalImages = () => {
    const { picture, images } = this.state
    if (!picture) {
      return
    }
    return (
      <div className={`container-images` + (picture ? `` : ` hidden`)}>
        <div className="container-header">
          <button className="btn-back" onClick={this.togglePictureSize}>
            <div className="icon-left"></div>
            <div className="label">VOLTAR</div>
          </button>
        </div>
        <div className="images">
          {images.length === 0 ? <p className="message">NENHUMA IMAGEM DE ALTA RESOLUÇÃO</p> : null}
          {images.map((i, index) => (
            <div className="image" key={index}>
              <img src={i.path} alt=""
                onLoad={this.imageLoadedHandler.bind(this, index)}
                onError={this.imageErrorHandler.bind(this, index)}
              />
              <div className="image-label">
                <div className={`text-loading` + (!i.loaded && !i.error ? ` blink` : ``)}>
                  {i.error ? i.error : (i.loaded ? `` : `CARREGANDO`)}
                </div>
                {i.label}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  imageLoadedHandler = index => {
    const { images } = this.state
    images[index].loaded = true
    this.setState({ images })
  }

  imageErrorHandler = index => {
    const { images } = this.state
    images[index].error = `IMAGEM NÃO ENCONTRADA`
    this.setState({ images })
  }

  togglePictureSize = () => {
    const { picture } = this.state
    this.setState({ picture: !picture })
  }

  openAffiliations = () => {
    const { mother, father } = this.props.details
    this.props.openCard(`CardBuscaFonetica`, { mother, father })
  }

  renderAlcunha() {
    const { aliases } = this.props
    if (aliases && aliases.length) {
      const alcunha = aliases.filter(i => i && i !== `SEM ALCUNHA`).join(`, `).toUpperCase()
      if (alcunha)
        return <div>{alcunha}</div>
    }
    return null
  }

  details = json => {
    const { roles } = this.props
    const allowSearchAffiliations = roles.includes(`CEREBRUM_BUSCA_CIVIL_AFILIACOES`) || roles.includes(`CEREBRUM_BUSCA_CRIMINAL_AFILIACOES`)
    return (
      <div className="group-info">
        
        <div className="row">
          <Item label="Nome">{json.regName}</Item>
        </div>

        <div className="row">
          <Item label="Nº de Ocorrência">{json.regNumber}</Item>
        </div>


        <div className="row">
          <Item label="Alcunha">{this.renderAlcunha()}</Item>
          <Item label="RG">{json.rg && <div>{json.rg} {json.rgEmitter} {json.federalUnitRgEmitter}</div>}</Item>
        </div>

        <div className="row">
          <Item label="CPF">{json.cpf ? this.formatCPF(json.cpf) : null}</Item>
          <Item label="Nascimento">{json.birthday ? `${this.formatDate(json.birthday)} - ${util.calcAge(json.birthday)} anos` : null}</Item>
        </div>

        <div className="row">
          <Item label="Cidade">{json.city ? `${json.city} - ` : ``}{json.federalUnitName || `-`}</Item>
          <Item label="Páis">{json.country || `-`}</Item>
        </div>

        <div className="row">
          <Item label="Sexo">{this.formatSex(json.sex)}</Item>
          <Item label="Status">{json.maritalStatus}</Item>
        </div>

        <div className="row">
          <Item label="Ocupação">{json.occupation && <div>{json.occupation}</div>}</Item>
        </div>

        <div className={`clickable` + (allowSearchAffiliations ? `` : ` blocked`)} onClick={this.openAffiliations}>
          <Item label="Mãe">{json.mother}</Item>
          <Item label="Pai">{json.father || `-`}</Item>
        </div>

      </div>
    )
  }

  criminalHistoryClean = () => {
    return (
      <div className="group-info green mandatos">
        <p className="main">NENHUM MANDADO EM ABERTO</p>
      </div>
    )
  }

  openArrestWarrants = json => {
    return (
      <div className="group-info mandatos">
        <p className="main">MANDADOS EM ABERTO</p>
        {json.map((i, index) => (
          <React.Fragment key={index}>
            {this.renderRowItem(i.judRefYear, i.punishmentTypeDesc, this.formatPunishmentTime(i))}
          </React.Fragment>
        ))}
      </div>
    )
  }

  arrestts = json => {
    return (
      <div className="group-info mandatos">
        <p className="main">PRISÕES</p>
        {json.map((i, index) => (
          <React.Fragment key={index}>
            {this.renderRowItem(this.formatDate(i.arrestmentDate), i.arrestmentType, i.policeStationName)}
          </React.Fragment>
        ))}
      </div>
    )
  }

  recap = json => {
    if (json.length === 0) {
      return null
    }
    return (
      <div className="group-info mandatos">
        <p className="main">ANTECEDENTES CRIMINAIS</p>
        <div className="scroll">
          {json.map((crime, index) => (
            <React.Fragment key={index}>
              {this.renderRowCrime(crime)}
            </React.Fragment>
          ))}
        </div>
      </div>
    )
  }

  renderRowItem = (year, name, desc) => {
    return (
      <div className="row-item">
        <div className="col a">
          {year}
        </div>
        <div className="col b">
          <div className="name">{name}</div>
          <div className="desc">{desc}</div>
        </div>
      </div>
    )
  }

  renderRowCrime = crime => {
    const year = crime.inquiryYear
    const desc = crime.policeStation
    let name = `Art. ` + crime.clauseNumber + ` do CP - Decreto Lei ` + crime.lawNumber
    if (crime.paragraphNumber)
      name += ` - Parág. ` + crime.paragraphNumber
    if (crime.subsectionNumber)
      name += ` ` + crime.subsectionNumber
    return this.renderRowItem(year, name, desc)
  }

}

