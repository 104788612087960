import React from "react";
import HeaderLeft from "../../../components/Header/Left";
import HeaderRight from "../../../components/Header/Right";

import "./index.scss";

const HelpScreenHeader = () => {
  const classes = {
    headerLeft: "HelpHeaderLeft",
    appLogo: "HelpHeaderLeftAppLogo",
    partnerships: "HelpHeaderLeftParceiros",
  };
  return (
    <>
      <HeaderLeft classes={classes} pageName="CENTRAL DE AJUDA DO " />
      <HeaderRight disableActions={true} />
    </>
  );
};

export default HelpScreenHeader;
