
export const normalizeAddress = json => {
  const output = {address: ``, number: ``, complement: ``, cep: ``, district: ``, city: ``, state: ``}
  //
  if (json.cityStateCountry) {
    const [ city, state ] = json.cityStateCountry.split(` - `)
    output.city  = city  || ``
    output.state = state || ``
  }
  output.state      = json.uf || json.state || output.state
  output.city       = json.city || output.city
  output.district   = json.district || output.district
  output.cep        = json.cepNumber || json.zipCode || json.cep || output.cep
  output.complement = json.addressComplement || json.complement || output.complement
  output.number     = json.number || output.number
  output.address    = json.address || json.street || output.address
  output.geocode    = json.geocode
  //
  output.text = output.address || ``
  output.full = output.address || ``
  if (output.number && output.address) {
    output.text += `, ` + output.number
    output.full += `, ` + output.number
  }
  if (output.complement) {
    output.full += `, ` + output.complement
  }
  if (output.district) {
    output.text += (output.text ? ` - ` : ``) + output.district
    output.full += (output.full ? ` - ` : ``) + output.district
  }
  if (output.city) {
    output.text += (output.text ? ` - ` : ``) + output.city
    output.full += (output.full ? ` - ` : ``) + output.city
  }
  if (output.state) {
    output.text += (output.text ? ` - ` : ``) + output.state
    output.full += (output.full ? ` - ` : ``) + output.state
  }
  output.text = output.text.toUpperCase()
  output.fulltext = output.full.toUpperCase()
  delete output[`full`]
  //
  return output
}
