import React, { Component } from "react";
import GraphMamager from "./GraphManager";
import ResultsManager from "./ResultsManager";
import InfoMiniCard from "./InfoMiniCard";
import { api } from '../../../services/api';
import { subscribe } from '../../../services/EventBus'
import ImgLoading from '../../../images/icon-horizontal-loading-dark.gif'
import "./index.scss";

export class CardRedeVinculos extends Component {

	state = {
		showGraph: false,
		errorLoad: '',
		data: [],
		showData: null,
		noCPF: null,
	}

	loadNetwork = async (cpf) => {
		try {
			this.setState({ showGraph: false, errorLoad: '' });
			const req = await api.get(`/civil/networklinks?cpf=${cpf}&limit=15`);
			const data = req.response().data;
			this.setState({ data: [...this.state.data, data] });

		} catch (error) {
			console.error(error.message.toString());
			this.setState({ errorLoad: String(error.message) || true });
		} finally {
			setTimeout(() => {
				this.setState({ showGraph: true });
			}, 1000);
		}
	}

	handleRemoveItem = (index) => {
		let update = [...this.state.data];
		update.splice(index, 1)
		this.setState({ data: update });
	}

	handleNodeClick = (node) => {
		this.setState({
			showData: {
				name: node.name,
				rg: node.rg,
				cpf: node.cpf
			}
		})
	}

	componentDidMount() {
		this.props.setTitle('REDE DE VÍNCULOS');
		subscribe(`cardRedeVinculos::push`, (topic, data) => {
			const { cpf } = data;
			const findedOldPush = this.state.data.find(item => String(item.person.cpf) === cpf);
			if (cpf && !findedOldPush) {
				this.setState({ noCPF: false });
				this.loadNetwork(cpf);
			} else if (!cpf) {
				this.setState({ noCPF: true, showGraph: true });
			}

			this.props.show();
		});
	}

	render() {
		// const { roles } = this.props
		let sispenRole = false;
		try {
			// const sispenRole = roles.includes('###TODO');
			sispenRole = true;
		} catch {
			console.error('Falha ao verificar permissões');
		}

		return (
			<div className="CardConfig" style={{ minHeight: 650, minWidth: 900 }} >
				<h1 className="title">REDE DE VÍNCULOS</h1>

				{
					!this.state.errorLoad ? this.state.showGraph ?
						<GraphMamager data={this.state.data} handleNodeClick={this.handleNodeClick} /> :
						<div className="legends-area" style={{ height: '100%' }} > <img src={ImgLoading} alt="loading..." width={300} /> </div> :
						<div className="legends-area" style={{ height: '100%' }} >
							{this.state.errorLoad}
						</div>
				}

				{
					this.state.noCPF ?
						<div className="legends-area" style={{ height: '100%' }} >
							Não foi possível exibir a rede de vínculos pois não foi possível encontrar registros de CPF.
						</div>
						: null
				}

				{
					this.state.data.length > 0 ? <ResultsManager results={this.state.data} handleRemove={this.handleRemoveItem} /> : null
				}

				<InfoMiniCard data={this.state.showData} handleRemove={() => this.setState({ showData: null })} />

				<div className="legends-area" >
					<label>
						<span style={{ backgroundColor: '#ff0000' }} /> Alvos
					</label>
					<label>
						<span style={{ backgroundColor: '#6497b1' }} /> Filiações
					</label>
					<label>
						<span style={{ backgroundColor: '#00A36C' }} /> Irmãos
					</label>
					{
						sispenRole ? <label>
							<span style={{ backgroundColor: '#AF8826' }} /> Visitantes SISPEN
						</label> : null
					}
				</div>

			</div>
		)
	}
}