import React, { useEffect, useState } from 'react'
import util from '../../../../helpers/util'
import { List } from '../../../UI/List'
import './index.scss'

export function CardCadTeamsList({
  http,
  show,
  setTitle,
  openCard,
  startDateTime,
  endDateTime,
  vehicleCode
}) {

  const searchActiveTeams = !startDateTime && !endDateTime && !vehicleCode;

  const [ teams, setTeams ] = useState([]);
  const [ teamFilter, setTeamFilter ] = useState('');

  useEffect(() => {
    const url = searchActiveTeams ? '/cad/teams/active' : '/cad/teams';
    const params = { 
      startDateTime: startDateTime && util.datetime2iso(startDateTime), 
      endDateTime: endDateTime && util.datetime2iso(endDateTime),
    };
    if (vehicleCode) params.vehicleCode = vehicleCode;

    http(url, params).then(res => {
      setTeams(res);
      show();
      setTitle('TIMES CICCM')
    });
  }, []);

  function onRowClick( team ) {
    openCard('CardCadTeamsDetails', { ...team });
  }

  function searchMatches( ...words ) {
    const filterWords = teamFilter.toUpperCase().split(' ');
    return filterWords.every( filter => words.some( word => word && word.toUpperCase().includes(filter) ) );
  }

  function leader( team ) {
    return (team.agents || []).find( agent => agent.leader ) || {};
  }

  const filteredTeams = teams
    .filter(team => {
      const { vehicle = {}, startedAt, endedAt } = team;
      return searchMatches(
        leader(team).name, 
        vehicle.brand, 
        vehicle.model, 
        vehicle.plate, 
        util.datetime2str(startedAt), 
        endedAt && searchMatches(util.datetime2str(endedAt))
      );
    });

  function handleFilterChange( e ) {
    setTeamFilter( e.target.value );
  }

  function renderEmpty() {
    return (
      <p className="message no-data">
        NENHUMA EQUIPE ATIVA
      </p>
    );
  }

  function renderRow( team ) {
    const { id, vehicle, startedAt, endedAt, inAttendance } = team;
    const leaderNameSplit = (leader(team).name || '').split(' ') || [];
    const leaderFirstName = leaderNameSplit[0];
    const leaderFocusedName = leaderNameSplit.find( n => n === n.toUpperCase() ) || leaderNameSplit.slice(-1)[0];
    return (
      <div key={ id } className="table-row" onClick={ () => onRowClick(team) } >
        <div className="col leader">{ `${leaderFirstName} ${leaderFocusedName}` }</div>
        <div className="col vehicle">{ `${vehicle.model} (${vehicle.plate})` }</div>
        <div className="col start">{ startedAt && util.datetime2str(startedAt) }</div>
        { searchActiveTeams && (
          <div className="col in-attendance">
            <div className={ 'team-in-atendance' + (inAttendance ? ' active' : '') }/>
          </div> 
        )}
        { !searchActiveTeams && <div className="col end">{ endedAt && util.datetime2str(endedAt) }</div> }
      </div>
    )
  }

  function renderTable() {
    if (filteredTeams.length === 0) return renderEmpty();
    return (
      <div className="table-list">
        <div className="table-row header">
          <div className="col leader">LÍDER</div>
          <div className="col vehicle">VEÍCULO</div>
          <div className="col start">INÍCIO</div>
          { searchActiveTeams && <div className="col in-attendance">EM ATENDIMENTO</div> }
          { !searchActiveTeams && <div className="col end">TÉRMINO</div> }
        </div>
        <List width={ 820 } height={ 350 } rowHeight={ 32 } rows={ filteredTeams } renderRow={ renderRow } />
      </div>
    )
  }

  function renderSummary() {
    return (
      <div className="summary">
        <div className="input">
          <input placeholder="Filtro..." onChange={ handleFilterChange } />
          <div className="total-hidden">
            { `${filteredTeams.length} / ${teams.length}` }
          </div>
        </div>
      </div>
    )
  }

  function renderTitle() {
    const cardTitle = searchActiveTeams ? 'Equipes ativas' : 'Histórico de equipes';
    return (
      <div className="card-title">
        CICCM AIS 13
        <span>{ cardTitle }</span>
      </div>
    );
  }

  function renderSubTitle() {
    return (
      <div className="card-sub-title">
        { `${util.datetime2str(startDateTime)} - ${util.datetime2str(endDateTime)}` }
        { vehicleCode && <div>Código do veículo: {vehicleCode}</div>}
      </div>
    );
  }


  return (
    <div className="CardCadTeamsList">
      { renderTitle() }
      { !searchActiveTeams && renderSubTitle() }
      { renderSummary() }
      { renderTable() }
    </div>
  )


  // renderHeader() {
  //   const { items, total } = this.state
  //   return (
  //     <div className="filter-container">
  //       <SwitcherOnOff label="ÚLTIMO MINUTO" onChange={ this.changedOnlyRecents } />
  //       <SearchFilterWords placeholder="Filtro..." subtotal={ items.length } total={ total } onChange={ this.changeWords } />
  //     </div>
  //   )
  // }

  // renderStatusBar() {
  //   const { cintaVioladas, items } = this.state
  //   if (items.length === 0) {
  //     return null
  //   }
  //   const plural = cintaVioladas && cintaVioladas > 1 ? `S` : ``
  //   let msg = (!cintaVioladas ? `NENHUMA` : `${cintaVioladas}`) + ` CINTA${plural} VIOLADA${plural}`
  //   return (
  //     <div className={ `status-bar` + (cintaVioladas ? ` alert` : ``) }>
  //       { msg }
  //     </div>
  //   )
  // }

  // renderBody() {
  //   const { connected, error, items, total } = this.state
  //   if (error) {
  //     return <p className="message error">{ error }</p>
  //   }
  //   if (connected && items.length === 0 && total === 0) {
  //     return <p className="message">aguardando coletas...</p>
  //   }
  //   if (items.length === 0) {
  //     return <p className="message">nenhum item encontrado</p>
  //   }
  //   return <List card={ this } rows={ items } width={ 440 } height={ 400 } rowHeight={ 25 } highlightMarker={ true } zoomOnClick={ 15 }
  //     renderRow={ i => (
  //       <div className={ `item-row` + (i.error ? ` error` : ``) + (i.point ? `` : ` no-pointer`) }>
  //         <span className="column code">
  //           { i.code }
  //           { i.flag && !i.error ? <span key={ 1 }>{ i.flag }</span> : null }
  //           { i.error ? <span key={ 2 } className="msg">{ i.error }</span> : null }
  //         </span>
  //         { i.error ? null : <span key={ 3 } className="column right">{ i.speed } km/h</span> }
  //         { i.error ? null : <span key={ 4 } className="column right">{ i.dateS }</span> }
  //       </div>
  //     )}
  //   />
  // }

}
