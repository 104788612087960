import React, { Component } from 'react'
import SearchBar from './SearchBar/'
import BodyAsync from './BodyAsync/'
import BodyOptions from './BodyOptions/'
import BodyPeople from './BodyPeople/'
import { api } from '../../services/api/'
import * as actions from './actions'
import distance from 'jaro-winkler'
import { subscribe, publish } from '../../services/EventBus'
import util from '../../helpers/util'
import './index.scss'

export default class SearchPanel extends Component {

  state = {
    initialized: false,
    hidden: true,
    tags: [],
    filteredTags: [],
    options: [],
    examples: [],
    people: null,
    typeSelected: null,
    text: ``,
  }

  componentDidMount() {
    this.elemRef = React.createRef()
    this.setState({initialized: true})
    //
    subscribe(`auth::login`, () => {
      actions.syncActionsByRoles()
      this.setState({tags: actions.getTags()})
    })
    subscribe(`search::open`, () => {
      this.setState({hidden: false})
      setTimeout(this.doInputFocus, 200)
    })
    subscribe(`search::close`, () => {
      this.setState({hidden: true})
    })
    subscribe(`event::keyup.esc`, () => {
      const { text, filteredTags, action, rows, errorMessage, people } = this.state
      this.request = null
      //
      if (errorMessage && filteredTags.length === 0 && !action) {
        return this.clear()
      }
      if (action && action.createRequest && ((rows && rows.length) || errorMessage)) {
        return this.updateFilteredTags([...filteredTags])
      }
      if (filteredTags.length) {
        return this.clear()
      }
      if (filteredTags.length === 0) {
        return ((this.request || people) || text.length) ? this.clear() : publish(`search::close`)
      }
    })
  }

  updateFilteredTags = (filteredTags=[], focusInput=true) => {
    actions.syncActionsByRoles()
    const tags = actions.getTags(filteredTags)
    const action = actions.getAction(filteredTags)
    const options = actions.getOptions(filteredTags)
    //
    this.setState({loading: true, filteredTags, tags, options, action, typeSelected: null, rows: [], examples: [], people: null, errorMessage: null})
    
    setTimeout(() => {
      this.setState({loading: false})
      if (focusInput) setTimeout(this.doInputFocus, 1)
    }, 250 + parseInt(Math.random()*200, 10))
  }

  doInputFocus = () => {
    if (this.elemRef.current) {
      const input = this.elemRef.current.querySelector(`input`)
      if (input) {
        input.focus()
      }
    }
  }

  addFilteredTag = tag => {
    this.updateFilteredTags([...(tag.parents || []), tag.value])
  }

  selectFilteredTag = tag => {
    const { filteredTags = [] } = this.state
    const newFilteredTags = filteredTags.slice(0, filteredTags.indexOf(tag) + 1)
    this.updateFilteredTags( newFilteredTags )
  }

  removeFilteredTag = tag => {
    this.updateFilteredTags(this.state.filteredTags.filter(i => i !== tag))
  }

  executeAction = row => {
    const { card, props, className } = row
    publish(`cardmanager::card.create`, {card, props, className})
    this.doHideComponent()
  }

  clear = () => {
    this.setState({loading: true, text: ``, rows: [], options: [], examples: []}, () => {
      this.updateFilteredTags([])
    })
  }

  doHideComponent = () => {
    publish(`search::close`)
  }

  autoSelectTagByText = text => {
    for (const tag of this.state.tags) {
      if (tag.value + ` ` === text) {
        this.addFilteredTag(tag)
        return true
      }
    }
  }

  httpRequest = (url, params, callback) => {
    if (!url) {
      this.request = null
      return
    }
    const request = Date.now()
    this.request = request
    api.get(url, {params}).then(data => {
      if (request === this.request) {
        this.request = null
        callback(null, data)
      }
    }).catch(err => {
      const netError = err && err.message === `Network Error`
      const msg =  netError ? `Falha de rede ao obter dados.` : `Falha ao buscar sugestões de busca.`
      if (request === this.request) {
        this.request = null
        callback(msg)
      }
    })
  }

  changeTextEmptyTag = text => {
    // autocomplete field type
    const typeSelected = actions.getTypeByText(text)
    const examples = actions.getSuggestionsByFieldType(text)
    // buscando async search by people name
    if (examples.length === 0 && util.countNames(text) > 1) {
      return this.httpRequest(`/people`, {name: text}, (err, rows) => {
        if (err)
          return this.setState({loading: false, people: null, errorMessage: `Falha ao buscar dados!`})
        this.setState({loading: false, people: rows, errorMessage: rows.length ? null : `Nenhum dado encontrado`})
      })
    }
    this.setState({text, typeSelected, examples, loading: false, people: null})
  }

  searchChangeHandler = (params, requiredParams) => {
    const { filteredTags } = this.state;
    const text = Object.values(params).join(``).replace(/\s{1,}/g, ``).trim()
    const tagDistance = tag => distance(tag.value, text, { caseSensitive: false })
    clearTimeout(this.searchTimeout)
    this.httpRequest(null)
    if (text === ``) {
      this.updateFilteredTags(filteredTags, false)
    } else if ((filteredTags || []).length === 0) {
      const tags = actions
        .getAllTags()
        .sort((t1, t2) => tagDistance(t2) - tagDistance(t1))
        .filter( tag => tag.value.toLowerCase().includes(text.toLowerCase()) || tagDistance(tag) > 0.8 )
      this.setState({text, params, requiredParams, tags}, this.processSearchTextParams)
    } else {
      this.setState({text, params, requiredParams}, this.processSearchTextParams)
    }
  }

  processSearchTextParams = () => {
    const { text, params, requiredParams=[], action=null } = this.state
    // param required
    const paramRequirementNotMet = requiredParams.filter(p => !params[p]).map(p => (action.params[p] || {}).placeholder || (action.params[p] || {}).label)
    if (paramRequirementNotMet.length > 0) {
      const multiParamText = paramRequirementNotMet.slice(0, -1).join(", ") + ` e ${paramRequirementNotMet[paramRequirementNotMet.length - 1]}`
      const errorMessage = paramRequirementNotMet.length === 1 ?
       `O parâmetro ${paramRequirementNotMet[0].toLowerCase()} é obrigatório na pesquisa.` :
       `Os parâmetros ${multiParamText.toLowerCase()} são obrigatórios na pesquisa.`
      return this.setState({loading: false, errorMessage})
    }
    // empty search
    if (text.length === 0) {
      return this.setState({loading: false, errorMessage: null, people:  null, rows: [], examples: [], options: []})
    }
    if (!action && params.text) {
      if (this.autoSelectTagByText(params.text.toLowerCase()))
        return
      this.setState({loading: true, errorMessage: null, text, rows: [], examples: [], options: []})
      return this.searchTimeout = setTimeout(this.changeTextEmptyTag.bind(this, params.text.toLowerCase()), 1000)
    }
    // async
    if (action && action.createRequest) {
      this.setState({loading: true, errorMessage: null, rows: [], examples: [], options: []})
      return this.searchTimeout = setTimeout(this.startDownloadSuggestions.bind(this, action, {...params}), 1500)
    }
    // sync actions - rows hardcoded
    if (action && !action.createRequest && action.createRow) {
      this.setState({loading: true, errorMessage: null, rows: [], examples: [], options: []})
      return this.searchTimeout = setTimeout(this.startStaticSuggestions.bind(this, action, {...params}), 500)
    }
  }

  startStaticSuggestions = (action, params) => {
    if (this.state.action !== action)
      return
    try {
      const row  = action.createRow(params)
      const rows = row ? [row,] : []
      this.setState({loading: false, errorMessage: null, rows})
    } catch(e) {
      const errorMessage = e.message || `Falha ao gerar sugestões de consulta`
      console.error(e.stack)
      this.setState({loading: false, errorMessage})
    }
  }

  startDownloadSuggestions = (action, params) => {
    if (this.state.action !== action)
      return
    try {
      const result = action.createRequest(params)
      const started = Date.now()
      this.httpRequest(result.path, result.params, (err, data) => {
        if (err) {
          return this.setState({loading: false, errorMessage: `Falha ao buscar sugestões`})
        }
        const rows = data.map(i => action.createRow(i))
        const delay = Date.now() - started
        setTimeout(() => {
          this.setState({rows, loading: false, errorMessage: rows.length ? null : `Nenhum dado encontrado`})
        }, delay > 1500 ? 0 : Math.max(delay, 800))
      })
    } catch(e) {
      const errorMessage = e.message || `Falha ao gerar consulta de sugestões`
      console.error(e.stack)
      this.setState({loading: false, errorMessage})
    }
  }

  render() {
    const { initialized, hidden } = this.state
    if (!initialized) {
      return null
    }
    const clss = `SearchPanel` + (hidden ? ` hidden` : ``)
    return (
      <React.Fragment>
        <div className={ `SearchPanelBackground` + (hidden ? ` hidden`: ``) } onClick={ this.doHideComponent }></div>
        <div className={ clss } ref={ this.elemRef }>
          <button className="btn-close" onClick={ this.doHideComponent }></button>
          <div className="container-search">
            { this.renderSearchBar() }
          </div>
          <div className="container-content">
            { this.renderContent() }
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderSearchBar() {
    const { filteredTags, action } = this.state
    return (
      <SearchBar
        tags={ filteredTags }
        action={ action }
        onChange={ this.searchChangeHandler }
        onSelectTag={ this.selectFilteredTag }
        onRemoveTag={ this.removeFilteredTag }
        clearTags={ this.clear }
      />
    )
  }

  renderContent() {
    const { text, action, loading, rows, errorMessage, filteredTags, tags, options, typeSelected, examples, people } = this.state
    if (loading) {
      return <div className="loading"></div>
    }
    if (errorMessage) {
      return <div className="error-message"><div className="icon"></div>{ errorMessage }</div>
    }
    if (people) {
      return <BodyPeople rows={ people } onClick={ this.executeAction } />
    }
    if (action && action.createRow && rows && rows.length > 0) {
      return <BodyAsync rows={ rows } onClick={ this.executeAction } />
    }
    return (
      <BodyOptions
        text={ text }
        tags={ tags }
        filteredTags={ filteredTags }
        tagsParentsVisible={ (filteredTags || []).length === 0 }
        options={ options }
        typeSelected={ typeSelected }
        examples={ examples }
        onTagClick={ this.addFilteredTag }
        onRowClick={ this.executeAction }
      />
    )
  }

}

