
import util from '../../../helpers/util'
import * as type from '../fieldTypes'

export const Satelite = {
  created: `2019-04-22`,
  context: `Satélite`,
  role: `CEREBRUM_AOM_PCA`,
  params: {
    id: {placeholder: `Código`, type: type.number},
    date: {placeholder: `Data`, type: type.date, optional: true},
  },
  createRequest: ({id, date}) => {
    return {
      path: `/satellites`,
      params: {
        satellite: id,
        date: util.date2sql(date || new Date())
      }
    }
  },
  createRow: json => {
    return {
      card: `CardSateliteEquipe`,
      props: {id: json.teamSatelliteId},
      title: json.satelliteName + ` - ` + json.teamName,
      lines: [
        [
          {label: `Data`, value: util.date2str(json.performanceDate)},
          {label: `Período`, value: json.shift},
        ]
      ]
    }
  }
}
