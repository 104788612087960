import util from '../../../helpers/util'
import * as type from '../fieldTypes'

const PAGE_SIZE = 30

export const PolicialCivilSearch = {
  created: `2019-10-10`,
  context: `Polícia.Civil`,
  role: `CEREBRUM_PC_PERFIL`,
  params: {
    name: {label: `Nome`, placeholder: `NOME`, type: type.text},
    mother: {label: `Mãe`, placeholder: `MÃE`, type: type.text, optional: true},
    father: {label: `Pai`, placeholder: `PAI`, type: type.text, optional: true},
    birthDate: {label: `Data de Nascimento`, placeholder: `dd/mm/aaaa`, type: type.date, optional: true},
  },
  createRequest: search => {
    const { name, mother, father, birthDate } = search
    const params = {
      name: (name || ``).trim(),
      mother: (mother || ``).trim(),
      father: (father || ``).trim(),
      birthDate: !!birthDate ? util.date2sql(birthDate) : ``,
      size: PAGE_SIZE,
    }
    Object.entries(params).forEach(([key, value]) => {
      params[key] = ( !value || value === `` ) ? undefined : value
    })
    if (util.countNames(params.name || ``) < 2 && Object.values(params).filter(v => !!v).length < 3)
      throw new Error(`É necessário detalhar mais a pesquisa. Por favor, tente novamente.`)
    //
    return { path: `/police/civil/search`, params }
  },
  createRow: json => {
    const { registry: reg, cpf, rg, name, mother, father, gender, occupation } = json
    const birthDate = json.birthDate ? util.date2str(json.birthDate) : null
    return {
      card: `CardPoliciaCivil`,
      props: { reg },
      //
      image: `avatar.png`,
      title: reg + ` ` + name,
      lines: [
        [
          {label: `CPF`, value: cpf ? util.formatCPF(cpf) : ``},
          {label: `RG`, value: rg || ``},
          {label: `Cargo`, value: (occupation || ``).toUpperCase()}
        ],
        [
          {label: `Sexo`, value: gender === `M` ? `MASCULINO` : `FEMININO`},
          {label: `Nascimento`, value: birthDate || ``},
        ],
        [{label: `Mãe`, value: (mother || ``).toUpperCase()}],
        [{label: `Pai`, value: (father || ``).toUpperCase()}],
      ]
    }
  }
}

export const PolicialCivilByRegistry = {
  created: `2019-10-10`,
  context: `Polícia.Civil`,
  role: `CEREBRUM_PC_PERFIL`,
  params: {
    reg: {label: `Matrícula`, placeholder: `Número`, type: type.text},
  },
  parser: (data) => {
    return {card: `CardPoliciaCivil`, props: data}
  }
}

export const PolicialCivilByCPF = {
  created: `2019-10-10`,
  context: `Polícia.Civil`,
  role: `CEREBRUM_PC_PERFIL`,
  params: {
    cpf: {label: `CPF`, placeholder: `Número`, type: type.cpf},
  },
  parser: (data) => {
    return {card: `CardPoliciaCivil`, props: data}
  }
}

export const PolicialCivilByRG = {
  created: `2019-10-10`,
  context: `Polícia.Civil`,
  role: `CEREBRUM_PC_PERFIL`,
  params: {
    rg: {label: `RG`, placeholder: `Número`, type: type.text},
  },
  parser: (data) => {
    return {card: `CardPoliciaCivil`, props: data}
  }
}
