import React, { Component } from 'react';
import util from '../../../../helpers/util'
import './index.scss'

const MAP_YELLOW = `#fc5`
const MAP_GREEN = `#8f9`

export class CardCVLISituacaoAtual extends Component {

  static ROLES = [`CEREBRUM_INDICADORES_SITUACAO_AIS`]

  state = {
    loading: 0,
    currentYear: new Date().getFullYear(),
    previousYear: new Date().getFullYear() - 1,
    tabs: [
      { name: `AIS`, selected: true },
      { name: `PERCENTUAL` },
      { name: `EVENTOS` },
    ],
    filterSelected: '',
    items: [],
    contentHidden: true,
  }

  componentDidMount() {
    this.cache = {}
    const { date } = this.props
    const query = {
      date: date ? util.date2sql(date) : null
    }
    const currentYear = (date ? new Date(date) : new Date()).getFullYear()
    const previousYear = currentYear - 1
    const title = `CVLI SITUAÇÃO ` + (date ? util.date2str(date) : `ATUAL`)
    this.props.http.indicators(`/situation`, query).then(data => {
      const items = this.extractItemsJSON(data, currentYear)
      data.forEach(ais => {
        const name = ais.name.replace(/\s/g, `-`)
        const color = ais.currentYearTotal > ais.previousYearTotal ? MAP_YELLOW : MAP_GREEN
        const polygons = ais.geometry
        const group = { color: `#fff`, title }
        this.props.publish(`map::layer.add`, { name, polygons, color, stroke: `#fff`, opacity: 0.2, group })
      })
      this.setState({ currentYear, previousYear, items }, () => {
        this.tabClickHandler(this.state.tabs[0])
        this.props.show()
        this.props.setTitle(title)
      })
    })
  }

  extractMax = (items, prefixDiff = "unit") => {
    let hasInfinity = false
    const values = items.map(i => {
      const v = i[`${prefixDiff}Diff`]
      if (!isFinite(v)) {
        hasInfinity = true
      }
      return isNaN(v) || !isFinite(v) ? 0 : Math.abs(v)
    })
    const max = values.reduce((acc, item) => Math.max(acc, item), -99999)
    return hasInfinity ? (max * 1.1) : max
  }

  /**
   * simplificar modelo json externo - adicionando textos, bar width/height, ...
   */
  extractItemsJSON = (items, currentYear) => {
    items = items.map(ais => {
      const oldTotal = ais.previousYearTotal
      const newTotal = ais.currentYearTotal
      const unitDiff = newTotal - oldTotal
      const percDiff = (newTotal - oldTotal) / oldTotal * 100
      const maxHeight = ais.months.reduce((sum, i) => Math.max(Math.max(i.current, i.previous), sum), 0)
      let months = ais.months.map(i => {
        const isEmpty = i.current === i.previous && i.current === 0;
        return {
          name: util.getMonthName(i.month).slice(0, 3).toUpperCase(),
          old: i.previous,
          new: i.current,
          diff: this.number2textSigned(i.current - i.previous, ``),
          oldBarHeight: isEmpty ? 0 : ((i.previous / maxHeight) * 50),
          newBarHeight: isEmpty ? 0 : ((i.current / maxHeight) * 50),
        }
      })
      return {
        id: ais.name.replace(/\s/g, `-`),
        name: ais.name,
        date: new Date(ais.lastDate),
        oldTotal,
        newTotal,
        months,
        unitDiff,
        percDiff,
        maximized: false,
      }
    })
    const percMax = this.extractMax(items, `perc`)
    const unitMax = this.extractMax(items, `unit`)
    return items.map(ais => {
      const message = ais.newTotal === 0 ? `NENHUM REGISTRO DE CVLI PARA O ANO ${currentYear}` : `DADOS ATÉ ${util.date2str(ais.date)}`
      const percent = (ais.newTotal - ais.oldTotal) / ais.oldTotal * 100
      const unitText = this.number2textSigned(ais.unitDiff)
      const percText = this.number2textSigned(percent, `%`)
      const percBarWidth = Math.abs(ais.percDiff) / percMax * 100
      const unitBarWidth = (Math.abs(ais.unitDiff) / unitMax) * 100 * 0.25
      return { ...ais, message, unitText, percText, unitBarWidth, percBarWidth: ais.unitDiff === 0 ? 0 : (ais.oldTotal === 0 ? 100 : percBarWidth) }
    })
  }

  number2textSigned = (value, suffix = "") => {
    return (`+` + value.toFixed(2).slice(0, -1))
      .replace(/\+-/, `-`)
      .replace(`.`, `,`)
      .replace(/,0$/, ``)
      .replace(/.*Na.*/, `+0`)
      .concat(suffix)
      .replace(/.*Infinit.*/, `- - -`)
  }

  tabClickHandler = (tab) => {
    const items = this.state.items.map(i => {
      return { ...i, maximized: false }
    })
    const tabs = this.state.tabs.map(t => {
      return { ...t, selected: t.name === tab.name }
    })
    if (tab.name === `AIS`) items.sort((a, b) => a.name < b.name ? -1 : 1)
    if (tab.name === `PERCENTUAL`) items.sort((a, b) => b.percDiff - a.percDiff)
    if (tab.name === `EVENTOS`) items.sort((a, b) => b.unitDiff - a.unitDiff)
    this.setState({ tabs, items, aisSelected: null }, this.clearPoints)
    this.focusMap()
    this.setState({ contentHidden: !this.state.contentHidden })
    this.setState({filterSelected: tab.name})
  }

  clearPoints = () => {
    const { aisSelected } = this.state
    Object.keys(this.cache).forEach(id => {
      this.props.publish(`map::layer.visibility`, { name: `ais${id}`, hidden: aisSelected !== +id })
    })
  }

  rowClickHandler = (item) => {
    const items = this.state.items.map(i => {
      return { ...i, maximized: i.id === item.id ? !i.maximized : false }
    })
    const ais = items.find(i => i.maximized)
    const aisSelected = ais ? +(ais.id.match(/\d+/)[0]) : 0
    this.focusMap(ais ? ais.id : null)
    this.setState({ items, aisSelected }, this.clearPoints)
    if (ais && !this.cache[aisSelected]) {
      setTimeout(this.downloadPoints(aisSelected), 1)
    }
  }

  downloadPoints = (aisSelected) => {
    this.cache[aisSelected] = true
    const { date } = this.props
    const query = { ais: aisSelected }
    if (date) {
      query.type = `rangeDate`
      query.endDate = util.date2sql(new Date(date))
      query.startDate = util.date2sql(new Date(new Date(date).getFullYear(), 0, 1))
    } else {
      query.type = `year`
      query.year = this.state.currentYear
    }
    this.setState({ loading: this.state.loading + 1 })
    this.props.http.indicators(`/cvli/search`, query).then(data => {
      this.setState({ loading: this.state.loading - 1 })
      data.cvli.forEach(i => {
        i.point = i.position
      })
      const group = { color: `#fc0`, title: `CVLI` }
      const layer = { name: `ais${aisSelected}`, points: data.cvli || [], group, color: `#fc0`, popup: this.createPopup }
      if (this.props.roles.includes(`CEREBRUM_BUSCA_CVLI_AIS`)) {
        layer.click = this.clickPopup
      }
      this.props.publish(`map::layer.add`, layer)
      if (aisSelected !== this.state.aisSelected) {
        this.props.publish(`map::layer.visibility`, { name: `ais${aisSelected}`, hidden: true })
      }
    }).catch(error => {
      this.cache[aisSelected] = false
      this.setState({ loading: this.state.loading - 1 })
    })
  }

  createPopup = item => {
    return { title: item.procedureType, text: item.id }
  }

  clickPopup = item => {
    const isMaster = this.props.roles.includes(`CEREBRUM_INQ_DETALHES`)
    const cardName = isMaster ? `CardCVLIOcorrencia` : `CardCVLIPopupDetails`
    this.props.openCard(cardName, item)
  }

  focusMap = (layerId) => {
    this.state.items.forEach(({ id }) => {
      this.props.publish(`map::layer.visibility`, { name: id, hidden: layerId ? id !== layerId : false })
    })
    this.props.publish(`map::layer.zoom`, { name: layerId })
  }

  render() {
    const { date } = this.props
    const { loading, currentYear, previousYear } = this.state
    return (
      <div className="CardCVLISituacaoAtual">
        <div className="card-title">
          CVLI <span>SITUAÇÃO {date ? util.date2str(date) : `ATUAL`}</span>
          <span className="years">
            {previousYear}→{currentYear}
          </span>
          {loading ? <div className="loading-message">CARREGANDO</div> : null}
        </div>
        {this.renderTab()}
        <div className="container">
          {this.renderChart()}
        </div>
      </div>
    )
  }

  renderTab() {
    const { tabs } = this.state;
    const { contentHidden } = this.state;
    return (
      <div className="filter-button-row">
        <div className="dropdown-label">Ordenar por:</div>
        <div className="dropdown-button" onClick={this.openDropdownButton}>{this.state.filterSelected}<div className="arrow-icon"> </div> </div>
        <div className={contentHidden ? `dropdown-content` : `dropdown-hidden`}>
          {tabs.map(t => (
            <div key={t.name} className={t.selected ? `selected dropButton-row ` : `dropButton-row`} onClick={this.tabClickHandler.bind(this, t)}>
              {t.name}
            </div>
          ))}
        </div>
      </div>
    )
  }

  openDropdownButton = () => {
    this.setState({ contentHidden: !this.state.contentHidden })
  }

  renderChart() {
    const { items } = this.state
    return (
      <div className="chart">
        {items.map(i => (
          <React.Fragment key={i.id}>
            <div className={`row ` + (i.unitDiff > 0 ? `yellow` : `green`) + (i.maximized ? ` maximized` : ``)} onClick={this.rowClickHandler.bind(this, i)}>
              <div className="label">
                {i.name}
              </div>
              <div className="lifebar">
                <div className="life" style={{ width: i.percBarWidth + `%` }}></div>
                <div className="value">{i.percText}</div>
              </div>
              <div className="total">
                <span className="left">{i.oldTotal}</span>
                <span className="center">→</span>
                <span className="right">{i.newTotal}</span>
              </div>
              <div className={`bars ` + (i.unitDiff >= 0 ? `positive` : `negative`)}>
                <div className="label left">{i.unitText}</div>
                <div className="life left" style={{ width: i.unitBarWidth + `%` }}></div>
                <div className="spacer"></div>
                <div className="life right" style={{ width: i.unitBarWidth + `%` }}></div>
                <div className="label right">{i.unitText}</div>
              </div>
              {this.renderChartRowContent(i)}
            </div>
          </React.Fragment>
        ))}
      </div>
    )
  }

  renderChartRowContent(item) {
    const { currentYear, previousYear } = this.state
    return (
      <div className="content">
        <div className="columns">
          {item.months.map(m => (
            <div key={m.name} className="column">
              <div className="verticalbar-container">
                <div className="old" style={{ height: m.oldBarHeight }}></div>
                <div className="new" style={{ height: m.newBarHeight }}>
                  <div className="hint">{m.diff}</div>
                </div>
              </div>
              <div className="label">
                {m.name}
              </div>
              <div className="label value">
                {m.new}
              </div>
            </div>
          ))}
        </div>
        <div className="legend">
          <div className="circle old"></div><div className="name">{previousYear}</div>
          <div className="circle new"></div><div className="name">{currentYear}</div>
          <div className="tip">{item.message}</div>
        </div>
      </div>
    )
  }

}
