import React from "react";
import { formatData2Print } from "../../helpers/formatData2Print";
import { Styles } from "./styles";
import { Page, Document, Text, View, Image } from "@react-pdf/renderer";
import supespLogo from "../../images/supesp-logo-pdf.png";
import govLogo from "../../images/gov-logo-pdf.png";
import util from "../../helpers/util";

const styles = Styles;

export const PdfDocument = ({ data }) => {
  const buildPage = () => {
    const sections = [];

    for (let section in data) {
      switch (section) {
        case "header":
          sections.push(data[section]);
          break;
        case "subHeader":
          sections.push(data[section]);
          break;
        case "civil":
          sections.push(data[section]);
          break;
        case "cnh":
          sections.push(data[section]);
          break;
        case "vehicles":
          sections.push(data[section]);
          break;
        case "enel":
          sections.push(data[section]);
          break;
        case "criminal":
          sections.push(data[section]);
          break;
        case "sispenVisitor":
          sections.push(data[section]);
          break;
        case "sispenApenado":
          sections.push(data[section]);
          break;
        case "footer":
          sections.push(data[section]);
          break;
        default:
          break;
      }
    }
    return sections;
  };

  return (
    <Document>
      <Page style={styles.page}>{buildPage()}</Page>
    </Document>
  );
};

const generateReports = (sections) => {
  const data2Print = {};

  for (let section in sections) {
    switch (section) {
      case "origin":
        const formatHeader = formatData2Print.header({
          origin: sections[section],
          date: new Date(),
          user: sections["user"],
          title: sections["title"],
          info: sections["info"],
        });
        data2Print["header"] = formatHeader;
        break;
      case "identidades":
        let formatCivil;
        if (sections[section].error) {
          formatCivil = sections[section];
        } else {
          formatCivil = sections[section].map((identidade) => {
            return formatData2Print.civil(identidade);
          });
        }
        data2Print["civil"] = formatCivil;
        break;
      case "cnh":
        const formatCnh = formatData2Print.cnh(sections[section]);
        data2Print["cnh"] = formatCnh;
        break;
      case "vehicles":
        const formatVehicles = sections[section].map((vehicle) => {
          return formatData2Print.vehicles(vehicle);
        });
        data2Print["vehicles"] = formatVehicles;
        break;
      case "enelList":
        const formatEnelList = sections[section].map((enel) => {
          return formatData2Print.enel(enel);
        });
        data2Print["enelList"] = formatEnelList;
        break;
      case "criminalDetails":
        const formatCriminal = sections[section].map((details) => {
          return formatData2Print.criminal(details);
        });
        data2Print["criminalDetails"] = formatCriminal;
        break;
      case "sispenVisitor":
        const formatSispenVisitor = formatData2Print.sispen.visitor(
          sections[section]
        );
        data2Print["sispenVisitor"] = formatSispenVisitor;
        break;
      case "sispenApenado":
        const formatSispenApenado = formatData2Print.sispen.apenado(
          sections[section]
        );
        data2Print["sispenApenado"] = formatSispenApenado;
        break;
      default:
        break;
    }
  }

  return data2Print;
};

const buildComponents = (components) => {
  const {
    header,
    civil,
    vehicles,
    enelList,
    cnh,
    criminalDetails,
    sispenVisitor,
    sispenApenado,
  } = components;
  const headerComponent = () => {
    const { dateTime, user } = header;
    return (
      <View key={`header`} fixed={true} style={styles.headerRow}>
        <View style={styles.containerLogos}>
          <Image src={supespLogo} style={styles.logoStyle} />
          <Image src={govLogo} style={styles.logoStyle} />
        </View>
        <View style={styles.headerColumn}>
          <Text
            style={styles.headerText}
            render={({ pageNumber, totalPages }) =>
              `Página ${pageNumber} / ${totalPages}`
            }
          />
          <Text style={styles.headerText}>Gerado pelo CEREBRUM</Text>
          <Text style={styles.headerText}>em {dateTime}</Text>
          <Text style={styles.headerText}>CPF do requisitante: {user.cpf}</Text>
        </View>
      </View>
    );
  };

  const subHeaderComponent = () => {
    const { origin, title, info } = header;
    return (
      <View key={`subheader`} style={styles.subHeader}>
        <Text style={styles.subHeaderTitle}>{title}</Text>
        <Text style={styles.headerText}>Busca gerada: {origin}</Text>
        {info && (
          <Text style={styles.headerText}>Informações adicionais: {info}.</Text>
        )}
      </View>
    );
  };

  const civilComponent = () => {
    return (
      <View key={`civil`} style={styles.base}>
        <Text style={styles.baseTitle} wrap={false}>
          HISTÓRICO CIVIL
        </Text>
        {civil.length ? (
          civil.map((identity) => {
            const { info, image, content } = identity;
            return (
              <View
                key={`civil${info.orderNumber}`}
                style={styles.mainContainer}
              >
                <Text style={styles.info}>
                  {info.orderNumber} ({info.identityDate})
                </Text>
                <View style={styles.container}>
                  <View style={styles.imageContainer}>
                    <Image style={styles.image} src={image} />
                    <View style={styles.imageBottom} />
                  </View>
                  <View style={styles.contentContainer}>
                    {content.map((group) => {
                      return group.map((item, index) => {
                        return (
                          <View
                            key={`civil${info.orderNumber}${index}`}
                            style={styles.item}
                            wrap={false}
                          >
                            <View style={styles.labelContainer}>
                              <Text style={styles.label}>{item["label"]}</Text>
                            </View>
                            <Text style={styles.value}>{item["value"]}</Text>
                          </View>
                        );
                      });
                    })}
                  </View>
                </View>
              </View>
            );
          })
        ) : civil.error ? (
          <View style={styles.error} wrap={false}>
            <Text>
              Ocorreu um erro. Não foi possível carregar as informações desta
              seção.
            </Text>
          </View>
        ) : (
          <View>
            <Text style={styles.notFoundText}>NENHUM REGISTRO ENCONTRADO</Text>
          </View>
        )}
      </View>
    );
  };

  const vehiclesComponent = () => {
    return (
      <View key={`vehicles`} style={styles.base}>
        <Text style={styles.baseTitle} wrap={false}>
          VEíCULOS
        </Text>
        {vehicles.length ? (
          vehicles.map((vehicle) => {
            const { info, content } = vehicle;
            return (
              <View key={`vehicles${info.plate}`} style={styles.mainContainer}>
                <Text style={styles.info}>
                  {info.plate} ({info.type})
                </Text>
                <View style={styles.container}>
                  <View style={styles.contentContainer}>
                    {content.map((group) => {
                      return group.map((item, index) => {
                        return (
                          <View
                            key={`vehicles${info.plate}${index}`}
                            style={styles.item}
                            wrap={false}
                          >
                            <View style={styles.labelContainer}>
                              <Text style={styles.label}>{item["label"]}</Text>
                            </View>
                            <Text style={styles.value}>{item["value"]}</Text>
                          </View>
                        );
                      });
                    })}
                  </View>
                </View>
              </View>
            );
          })
        ) : (
          <View>
            <Text style={styles.notFoundText}>NENHUM REGISTRO ENCONTRADO</Text>
          </View>
        )}
      </View>
    );
  };

  const cnhComponent = () => {
    const { content, image, error } = cnh;
    return (
      <View key={`cnh`} style={styles.base}>
        <Text style={styles.baseTitle} wrap={false}>
          HABILITAÇÃO
        </Text>
        <View style={styles.mainContainer}>
          {content[0].length ? (
            <View style={styles.container}>
              <View style={styles.imageContainer}>
                <Image style={styles.image} src={image} />
                <View style={styles.imageBottom} />
              </View>
              <View key={`cnhcontent`} style={styles.contentContainer}>
                {content.map((group) => {
                  return group.map((item, index) => {
                    return (
                      <View
                        key={`cnhcontent${index}`}
                        style={styles.item}
                        wrap={false}
                      >
                        <View style={styles.labelContainer}>
                          <Text style={styles.label}>{item["label"]}</Text>
                        </View>
                        <Text style={styles.value}>{item["value"]}</Text>
                      </View>
                    );
                  });
                })}
              </View>
            </View>
          ) : error ? (
            <View style={styles.error} wrap={false}>
              <Text>
                Ocorreu um erro. Não foi possível carregar as informações desta
                seção.
              </Text>
            </View>
          ) : (
            <View>
              <Text style={styles.notFoundText}>
                NENHUM REGISTRO ENCONTRADO
              </Text>
            </View>
          )}
        </View>
      </View>
    );
  };

  const enelComponent = () => {
    return (
      <View key={`enel`} style={styles.base}>
        <Text style={styles.baseTitle} wrap={false}>
          ENEL
        </Text>
        {enelList.length ? (
          enelList.map((enel, index) => {
            const { content } = enel;
            return (
              <View key={`enel${index}`} style={styles.mainContainer}>
                <Text style={styles.info}>{`Registro ${index + 1}`}</Text>
                <View style={styles.container}>
                  <View style={styles.contentContainer}>
                    {content.map((group) => {
                      return group.map((item, index) => {
                        return (
                          <View
                            key={`enelcontent${index}`}
                            style={styles.item}
                            wrap={false}
                          >
                            <View style={styles.labelContainer}>
                              <Text style={styles.label}>{item["label"]}</Text>
                            </View>
                            <Text style={styles.value}>{item["value"]}</Text>
                          </View>
                        );
                      });
                    })}
                  </View>
                </View>
              </View>
            );
          })
        ) : (
          <View>
            <Text style={styles.notFoundText}>NENHUM REGISTRO ENCONTRADO</Text>
          </View>
        )}
      </View>
    );
  };

  const criminalComponent = () => {
    return (
      <View key={`criminal`} style={styles.base}>
        <Text style={styles.baseTitle} wrap={false}>
          REGISTROS POLICIAIS
        </Text>
        {criminalDetails.length ? (
          criminalDetails.map((details) => {
            const {
              info,
              image,
              content,
              recap,
              openArrestWarrants,
              arrests,
              error,
            } = details;
            return (
              <View
                key={`criminal${info.regNumber}`}
                style={styles.mainContainer}
              >
                <Text style={styles.info}>{info.regNumber}</Text>
                {error ? (
                  <View style={styles.error} wrap={false}>
                    <Text>
                      Ocorreu um erro. Não foi possível carregar todas as
                      informações deste registro.
                    </Text>
                  </View>
                ) : null}
                <View style={styles.container}>
                  <View style={styles.imageContainer}>
                    <Image style={styles.image} src={image} />
                    <View style={styles.imageBottom} />
                  </View>
                  <View style={styles.contentContainer}>
                    {content.map((group) => {
                      return group.map((item, index) => {
                        return (
                          <View
                            key={`criminal${info.regNumber}${index}`}
                            style={styles.item}
                            wrap={false}
                          >
                            <View style={styles.labelContainer}>
                              <Text style={styles.label}>{item["label"]}</Text>
                            </View>
                            <Text style={styles.value}>{item["value"]}</Text>
                          </View>
                        );
                      });
                    })}
                    <View style={styles.bottomContainer}>
                      {openArrestWarrants.length > 0 ? (
                        <Text style={styles.subBaseTitle} wrap={false}>
                          MANDADOS EM ABERTO
                        </Text>
                      ) : (
                        <Text style={styles.subBaseTitle} wrap={false}>
                          NENHUM MANDADO EM ABERTO
                        </Text>
                      )}
                      {openArrestWarrants.map((item, index) => {
                        const { year, name, desc } = item;
                        return (
                          <View
                            key={`open${info.regNumber}${index}`}
                            style={styles.criminalItem}
                            wrap={false}
                          >
                            <Text style={styles.criminalYear}>{year}</Text>
                            <View style={styles.criminalInfo}>
                              <Text>{name}</Text>
                              <Text style={styles.criminalDesc}>{desc}</Text>
                            </View>
                          </View>
                        );
                      })}
                    </View>
                    <View style={styles.bottomContainer}>
                      {arrests.length > 0 ? (
                        <Text style={styles.subBaseTitle} wrap={false}>
                          PRISÕES
                        </Text>
                      ) : null}
                      {arrests.map((item, index) => {
                        const { year, name, desc } = item;
                        return (
                          <View
                            key={`arrest${info.regNumber}${index}`}
                            style={styles.criminalItem}
                            wrap={false}
                          >
                            <Text style={styles.criminalYear}>{year}</Text>
                            <View style={styles.criminalInfo}>
                              <Text>{name}</Text>
                              <Text style={styles.criminalDesc}>{desc}</Text>
                            </View>
                          </View>
                        );
                      })}
                    </View>
                    <View style={styles.bottomContainer}>
                      {recap.length > 0 ? (
                        <Text style={styles.subBaseTitle} wrap={false}>
                          ANTECEDENTES CRIMINAIS
                        </Text>
                      ) : null}
                      {recap.map((item, index) => {
                        const { year, name, desc } = item;
                        return (
                          <View
                            key={`recap${info.regNumber}${index}`}
                            style={styles.criminalItem}
                            wrap={false}
                          >
                            <Text style={styles.criminalYear}>{year}</Text>
                            <View style={styles.criminalInfo}>
                              <Text>{name}</Text>
                              <Text style={styles.criminalDesc}>{desc}</Text>
                            </View>
                          </View>
                        );
                      })}
                    </View>
                  </View>
                </View>
              </View>
            );
          })
        ) : (
          <View>
            <Text style={styles.notFoundText}>NENHUM REGISTRO ENCONTRADO</Text>
          </View>
        )}
      </View>
    );
  };

  const sispenVisitorComponent = () => {
    const { content, visits, image, error } = sispenVisitor;
    return (
      <View key={`visitor`} style={styles.base}>
        <Text style={styles.baseTitle} wrap={false}>
          SISPEN VISITANTE
        </Text>
        <View style={styles.mainContainer}>
          {error ? (
            <View style={styles.error} wrap={false}>
              <Text>
                Ocorreu um erro. Não foi possível carregar as visitas deste
                registro.
              </Text>
            </View>
          ) : null}
          {content.length ? (
            <View style={styles.container}>
              <View style={styles.imageContainer}>
                <Image style={styles.image} src={image} />
                <View style={styles.imageBottom} />
              </View>
              <View style={styles.contentContainer}>
                {content.map((group) => {
                  return group.map((item, index) => {
                    return (
                      <View
                        key={`visitor${index}`}
                        style={styles.item}
                        wrap={false}
                      >
                        <View style={styles.labelContainer}>
                          <Text style={styles.label}>{item["label"]}</Text>
                        </View>
                        <Text style={styles.value}>{item["value"]}</Text>
                      </View>
                    );
                  });
                })}
                <View style={styles.bottomContainer}>
                  {visits.length > 0 ? (
                    <Text style={styles.subBaseTitle} wrap={false}>
                      VISITAS REALIZADAS
                    </Text>
                  ) : error ? null : (
                    <Text style={styles.subBaseTitle} wrap={false}>
                      NENHUMA VISITA REALIZADA
                    </Text>
                  )}
                  {visits.map((item, index) => {
                    const {
                      prisonerId,
                      linkType,
                      link,
                      date,
                      time,
                      prisonUnityName,
                      prisonerName,
                      prisonerCpf,
                      prisonerRg,
                    } = item;
                    return (
                      <View
                        key={`visit${prisonerId}${index}`}
                        style={styles.visitorItem}
                        wrap={false}
                      >
                        <View style={styles.visitorDateContainer}>
                          <Text style={styles.visitorDateText}>{date}</Text>
                          <Text style={styles.visitorTimeText}>{time}</Text>
                        </View>
                        <View style={styles.prisonerInfo}>
                          <Text>{prisonUnityName}</Text>
                          <Text style={styles.prisonerName}>
                            {prisonerName}
                          </Text>
                          <Text>
                            {linkType} / {link}
                          </Text>
                          <View style={styles.prisonerBottomInfo}>
                            {prisonerId ? (
                              <View style={styles.prisonerTextContainer}>
                                <Text style={styles.prisonerLightText}>ID</Text>
                                <Text>{prisonerId}</Text>
                              </View>
                            ) : null}
                            {prisonerCpf ? (
                              <View style={styles.prisonerTextContainer}>
                                <Text style={styles.prisonerLightText}>
                                  CPF
                                </Text>
                                <Text>{prisonerCpf}</Text>
                              </View>
                            ) : null}
                            {prisonerRg ? (
                              <View style={styles.prisonerTextContainer}>
                                <Text style={styles.prisonerLightText}>RG</Text>
                                <Text>{prisonerRg}</Text>
                              </View>
                            ) : null}
                          </View>
                        </View>
                      </View>
                    );
                  })}
                </View>
              </View>
            </View>
          ) : (
            <View>
              <Text style={styles.notFoundText}>
                NENHUM REGISTRO ENCONTRADO
              </Text>
            </View>
          )}
        </View>
      </View>
    );
  };

  const sispenApenadoComponent = () => {
    const { content, image, error } = sispenApenado;
    const {
      personGroup,
      filiationGroup,
      adressGroupResidencial,
      adressGroupComercial,
      referralsGroup,
      benefitsGroup,
      questionnaireGroup,
      sispenCriminalGroup,
      sispenCriminalProcesses,
      observations,
      visits,
      collects,
    } = content;
    return (
      <View key={`apenado`} style={styles.base}>
        <Text style={styles.baseTitle} wrap={false}>
          SISPEN APENADO
        </Text>
        <View style={styles.mainContainer}>
          {personGroup.length ? (
            <View style={styles.container}>
              <View style={styles.imageContainer}>
                <Image style={styles.image} src={image} />
                <View style={styles.imageBottom} />
              </View>
              <View style={styles.contentContainer}>
                {personGroup.map((group) => {
                  return group.map((item, index) => {
                    return (
                      <View
                        key={`apenado${index}`}
                        style={styles.item}
                        wrap={false}
                      >
                        <View style={styles.labelContainer}>
                          <Text style={styles.label}>{item["label"]}</Text>
                        </View>
                        <Text style={styles.value}>{item["value"]}</Text>
                      </View>
                    );
                  });
                })}
                <View style={styles.bottomContainer}>
                  <Text style={styles.subSectionTitle} wrap={false}>
                    FILIAÇÃO
                  </Text>
                </View>
                {filiationGroup.map((group) => {
                  return group.map((item, index) => {
                    return (
                      <View
                        key={`apenado-filiation${index}`}
                        style={styles.item}
                        wrap={false}
                      >
                        <View style={styles.labelContainer}>
                          <Text style={styles.label}>{item["label"]}</Text>
                        </View>
                        <Text style={styles.value}>{item["value"]}</Text>
                      </View>
                    );
                  });
                })}
                {adressGroupResidencial.length > 0 ? (
                  <View style={styles.bottomContainer}>
                    <Text style={styles.subSectionTitle} wrap={false}>
                      ENDEREÇO RESIDENCIAL
                    </Text>
                  </View>
                ) : null}
                {adressGroupResidencial.map((group) => {
                  return group.map((item, index) => {
                    return (
                      <View
                        key={`apenado-adress-res${index}`}
                        style={styles.item}
                        wrap={false}
                      >
                        <View style={styles.labelContainer}>
                          <Text style={styles.label}>{item["label"]}</Text>
                        </View>
                        <Text style={styles.value}>{item["value"]}</Text>
                      </View>
                    );
                  });
                })}
                {adressGroupComercial.length > 0 ? (
                  <View style={styles.bottomContainer}>
                    <Text style={styles.subSectionTitle} wrap={false}>
                      ENDEREÇO COMERCIAL
                    </Text>
                  </View>
                ) : null}
                {adressGroupComercial.map((group) => {
                  return group.map((item, index) => {
                    return (
                      <View
                        key={`apenado-adress-com${index}`}
                        style={styles.item}
                        wrap={false}
                      >
                        <View style={styles.labelContainer}>
                          <Text style={styles.label}>{item["label"]}</Text>
                        </View>
                        <Text style={styles.value}>{item["value"]}</Text>
                      </View>
                    );
                  });
                })}
                {referralsGroup.length > 0 ? (
                  <View style={styles.bottomContainer}>
                    <Text style={styles.subSectionTitle} wrap={false}>
                      ENCAMINHAMENTOS
                    </Text>
                  </View>
                ) : null}
                {referralsGroup.map((group) => {
                  return group.map((item, index) => {
                    return (
                      <View
                        key={`apenado-referrrals${index}`}
                        style={styles.item}
                        wrap={false}
                      >
                        <View style={styles.labelContainer}>
                          <Text style={styles.label}>{item["label"]}</Text>
                        </View>
                        <Text style={styles.value}>{item["value"]}</Text>
                      </View>
                    );
                  });
                })}
                {benefitsGroup.length > 0 ? (
                  <View style={styles.bottomContainer}>
                    <Text style={styles.subSectionTitle} wrap={false}>
                      BENEFÍCIOS
                    </Text>
                  </View>
                ) : null}
                {benefitsGroup.map((group) => {
                  return group.map((item, index) => {
                    return (
                      <View
                        key={`apenado-benefits${index}`}
                        style={styles.item}
                        wrap={false}
                      >
                        <View style={styles.labelContainer}>
                          <Text style={styles.label}>{item["label"]}</Text>
                        </View>
                        <Text style={styles.value}>{item["value"]}</Text>
                      </View>
                    );
                  });
                })}
                {questionnaireGroup.length > 0 ? (
                  <View style={styles.bottomContainer}>
                    <Text style={styles.subSectionTitle} wrap={false}>
                      QUESTIONÁRIO
                    </Text>
                  </View>
                ) : null}
                {questionnaireGroup.map((group) => {
                  return group.map((item, index) => {
                    return (
                      <View
                        key={`apenado-questionnaire${index}`}
                        style={styles.item}
                        wrap={false}
                      >
                        <View style={styles.labelContainer}>
                          <Text style={styles.label}>{item["label"]}</Text>
                        </View>
                        <Text style={styles.value}>{item["value"]}</Text>
                      </View>
                    );
                  });
                })}
                <View style={styles.bottomContainer}>
                  <Text style={styles.subBaseTitle} wrap={false}>
                    SISPEN CRIMINAL
                  </Text>
                </View>
                {sispenCriminalGroup.map((group) => {
                  return group.map((item, index) => {
                    return (
                      <View
                        key={`apenado-criminal${index}`}
                        style={styles.item}
                        wrap={false}
                      >
                        <View style={styles.labelContainer}>
                          <Text style={styles.label}>{item["label"]}</Text>
                        </View>
                        <Text style={styles.value}>{item["value"]}</Text>
                      </View>
                    );
                  });
                })}
                <View style={styles.bottomContainer}>
                  <Text style={styles.subSectionTitle} wrap={false}>
                    PROCESSOS
                  </Text>
                </View>
                {sispenCriminalProcesses.length > 0 ? (
                  sispenCriminalProcesses.map((item, index) => {
                    const {
                      number,
                      prisonDate,
                      inclusionDate,
                      updateDate,
                      court,
                      unit,
                      observations,
                    } = item;
                    return (
                      <View key={`sispen-processes${index}`} wrap={false}>
                        <View style={styles.sispenCriminalInfo}>
                          <Text style={styles.sispenCriminalNumber}>
                            {number}
                          </Text>
                          <View style={styles.sispenCriminalRow}>
                            {prisonDate ? (
                              <Text style={styles.sispenCriminalValue}>
                                <Text style={styles.sispenCriminalLabel}>
                                  DETENÇÃO
                                </Text>
                                {` ${util.date2str(
                                  prisonDate
                                )} ${util.date2time(prisonDate)}`}
                              </Text>
                            ) : null}
                            {inclusionDate ? (
                              <Text style={styles.sispenCriminalValue}>
                                <Text style={styles.sispenCriminalLabel}>
                                  INCLUSÃO
                                </Text>
                                {` ${util.date2str(
                                  inclusionDate
                                )} ${util.date2time(inclusionDate)}`}
                              </Text>
                            ) : null}
                            {updateDate ? (
                              <Text style={styles.sispenCriminalValue}>
                                <Text style={styles.sispenCriminalLabel}>
                                  ATUALIZAÇÃO
                                </Text>
                                {` ${util.date2str(
                                  updateDate
                                )} ${util.date2time(updateDate)}`}
                              </Text>
                            ) : null}
                          </View>
                          <Text>{court}</Text>
                          <Text>{unit}</Text>
                          <Text style={styles.criminalDesc}>
                            {observations}
                          </Text>
                        </View>
                      </View>
                    );
                  })
                ) : (
                  <View>
                    <Text style={styles.notFoundText}>
                      NENHUM PROCESSO ENCONTRADO
                    </Text>
                  </View>
                )}
                {observations.length > 0 && (
                  <View style={styles.bottomContainer}>
                    <Text style={styles.subBaseTitle} wrap={false}>
                      SISPEN OBSERVAÇÕES JURÍDICAS
                    </Text>
                  </View>
                )}
                {observations.map((item) => {
                  if (item.length > 0) {
                    return item.map((lines, gkey) => (
                      <View key={gkey} wrap={false}>
                        {lines.map((line, lkey) => (
                          <Text
                            style={
                              lkey === 0
                                ? styles.prisonerLightText
                                : styles.sispenText
                            }
                            key={lkey}
                          >
                            {line}
                          </Text>
                        ))}
                      </View>
                    ));
                  }
                  return null;
                })}
                <View style={styles.bottomContainer}>
                  <Text style={styles.subBaseTitle} wrap={false}>
                    SISPEN VISITAS RECEBIDAS
                  </Text>
                </View>
                {visits.error ? (
                  <View style={styles.error} wrap={false}>
                    <Text>
                      Ocorreu um erro. Não foi possível carregar as informações
                      desta seção.
                    </Text>
                  </View>
                ) : visits.length > 0 ? (
                  visits.map((item, index) => {
                    const {
                      birthDate,
                      linkDate,
                      linkType,
                      sex,
                      name,
                      rg,
                      cpf,
                    } = item;
                    return (
                      <View
                        key={`apenado-visits${index}`}
                        style={styles.visitorItem}
                        wrap={false}
                      >
                        <View style={styles.visitorDateContainer}>
                          <Text style={styles.prisonerName}>
                            {util.date2str(linkDate)}
                          </Text>
                        </View>
                        <View style={styles.prisonerInfo}>
                          <Text style={styles.prisonerLightText}>
                            {linkType} - {sex}
                          </Text>
                          <Text style={styles.prisonerName}>{name}</Text>
                          <View style={styles.prisonerBottomInfo}>
                            {birthDate ? (
                              <View style={styles.prisonerTextContainer}>
                                <Text>
                                  {util.date2str(birthDate)} -{" "}
                                  {util.calcAge(birthDate)} anos
                                </Text>
                              </View>
                            ) : null}
                            {cpf ? (
                              <View style={styles.prisonerTextContainer}>
                                <Text style={styles.prisonerLightText}>
                                  CPF
                                </Text>
                                <Text>{cpf}</Text>
                              </View>
                            ) : null}
                            {rg ? (
                              <View style={styles.prisonerTextContainer}>
                                <Text style={styles.prisonerLightText}>RG</Text>
                                <Text>{rg}</Text>
                              </View>
                            ) : null}
                          </View>
                        </View>
                      </View>
                    );
                  })
                ) : (
                  <View>
                    <Text style={styles.notFoundText}>
                      NENHUMA VISITA REGISTRADA
                    </Text>
                  </View>
                )}
                {collects.length > 0 && (
                  <View style={styles.bottomContainer}>
                    <Text style={styles.subBaseTitle} wrap={false}>
                      SISPEN COLETAS
                    </Text>
                  </View>
                )}
                {collects.map((collect) => {
                  return (
                    <View style={styles.sispenContentContainer}>
                      {collect.type.fingerprints.length > 0 && (
                        <View style={styles.bottomContainer}>
                          <Text style={styles.subBaseTitle} wrap={false}>
                            COLETAS EM {util.date2str(collect.date)}
                          </Text>
                        </View>
                      )}
                      {collect.type.fingerprints.length > 0 ? (
                        <View style={styles.bottomContainer}>
                          <Text style={styles.subSectionTitle} wrap={false}>
                            IMPRESSÕES DIGITAIS - MÃO ESQUERDA
                          </Text>
                        </View>
                      ) : null}
                      {collect.type.fingerprints
                        .filter((item) => item["text"].includes("esquerdo"))
                        .map((item, index) => {
                          return (
                            <View
                              key={`apenado-collects${index}`}
                              style={
                                item.path.error
                                  ? styles.sispenErrorItem
                                  : styles.sispenItem
                              }
                              wrap={false}
                            >
                              <View style={styles.labelContainer}>
                                <Text style={styles.label}>{item["text"]}</Text>
                              </View>
                              {item.path.error ? (
                                <View style={styles.error} wrap={false}>
                                  <Text>{item.path.message}</Text>
                                </View>
                              ) : (
                                <View style={styles.imageContainer}>
                                  <Image style={styles.image} src={item.path} />
                                </View>
                              )}
                            </View>
                          );
                        })}
                      {collect.type.fingerprints.length > 0 ? (
                        <View style={styles.bottomContainer}>
                          <Text style={styles.subSectionTitle} wrap={false}>
                            IMPRESSÕES DIGITAIS - MÃO DIREITA
                          </Text>
                        </View>
                      ) : null}
                      {collect.type.fingerprints
                        .filter((item) => item["text"].includes("direito"))
                        .map((item, index) => {
                          return (
                            <View
                              key={`apenado-collects${index}`}
                              style={
                                item.path.error
                                  ? styles.sispenErrorItem
                                  : styles.sispenItem
                              }
                              wrap={false}
                            >
                              <View style={styles.labelContainer}>
                                <Text style={styles.label}>{item["text"]}</Text>
                              </View>
                              {item.path.error ? (
                                <View style={styles.error} wrap={false}>
                                  <Text>{item.path.message}</Text>
                                </View>
                              ) : (
                                <View style={styles.imageContainer}>
                                  <Image style={styles.image} src={item.path} />
                                </View>
                              )}
                            </View>
                          );
                        })}
                      {collect.type.photos.length > 0 ? (
                        <View style={styles.bottomContainer}>
                          <Text style={styles.subSectionTitle} wrap={false}>
                            FOTOS
                          </Text>
                        </View>
                      ) : null}
                      {collect.type.photos.map((item, index) => {
                        return (
                          <View
                            key={`apenado-collects${index}`}
                            style={
                              item.path.error
                                ? styles.sispenErrorItem
                                : styles.sispenItem
                            }
                            wrap={false}
                          >
                            <View style={styles.labelContainer}>
                              <Text style={styles.label}>{item["text"]}</Text>
                            </View>
                            {item.path.error ? (
                              <View style={styles.error} wrap={false}>
                                <Text>{item.path.message}</Text>
                              </View>
                            ) : (
                              <View style={styles.sispenImageContainer}>
                                <Image style={styles.image} src={item.path} />
                              </View>
                            )}
                          </View>
                        );
                      })}
                      {collect.type.tattoos.length > 0 ? (
                        <View style={styles.bottomContainer}>
                          <Text style={styles.subSectionTitle} wrap={false}>
                            TATUAGENS
                          </Text>
                        </View>
                      ) : null}
                      {collect.type.tattoos.map((item, index) => {
                        return (
                          <View
                            key={`apenado-collects${index}`}
                            style={
                              item.path.error
                                ? styles.sispenErrorItem
                                : styles.sispenItem
                            }
                            wrap={false}
                          >
                            <View style={styles.labelContainer}>
                              <Text style={styles.label}>{item["text"]}</Text>
                            </View>
                            {item.path.error ? (
                              <View style={styles.error} wrap={false}>
                                <Text>{item.path.message}</Text>
                              </View>
                            ) : (
                              <View style={styles.sispenImageContainer}>
                                <Image style={styles.image} src={item.path} />
                              </View>
                            )}
                          </View>
                        );
                      })}
                      {collect.type.bodyMarks.length > 0 ? (
                        <View style={styles.bottomContainer}>
                          <Text style={styles.subSectionTitle} wrap={false}>
                            MARCAS CORPORAIS
                          </Text>
                        </View>
                      ) : null}
                      {collect.type.bodyMarks.map((item, index) => {
                        return (
                          <View
                            key={`apenado-collects${index}`}
                            style={
                              item.path.error
                                ? styles.sispenErrorItem
                                : styles.sispenItem
                            }
                            wrap={false}
                          >
                            <View style={styles.labelContainer}>
                              <Text style={styles.label}>{item["text"]}</Text>
                            </View>
                            {item.path.error ? (
                              <View style={styles.error} wrap={false}>
                                <Text>{item.path.message}</Text>
                              </View>
                            ) : (
                              <View style={styles.sispenImageContainer}>
                                <Image style={styles.image} src={item.path} />
                              </View>
                            )}
                          </View>
                        );
                      })}
                    </View>
                  );
                })}
              </View>
            </View>
          ) : error && error.status !== 404 ? (
            <View style={styles.error} wrap={false}>
              <Text>
                Ocorreu um erro. Não foi possível carregar as informações desta
                seção.
              </Text>
            </View>
          ) : (
            <View>
              <Text style={styles.notFoundText}>
                NENHUM REGISTRO ENCONTRADO
              </Text>
            </View>
          )}
        </View>
      </View>
    );
  };

  const footerComponent = () => {
    return (
      <View key={`footer`} style={styles.footerContainer} wrap={false}>
        <Text style={styles.footerText}>
          O sigilo deste documento é protegido e controlado pela Lei Nº
          12.527/2011. A divulgação, a revelação, o fornecimento, a utilização
          ou a reprodução desautorizada de seu conteúdo, a qualquer tempo, meio
          e modo, inclusive mediante acesso ou facilitação de acessos indevidos,
          constituem condutas ilícitas que ensejam responsabilidades penais,
          civis e administrativas.
        </Text>
      </View>
    );
  };

  const loadComponents = {
    header: headerComponent(),
    subHeader: subHeaderComponent(),
  };

  for (let component in components) {
    switch (component) {
      case "civil":
        loadComponents[component] = civilComponent();
        break;
      case "cnh":
        loadComponents[component] = cnhComponent();
        break;
      case "vehicles":
        loadComponents[component] = vehiclesComponent();
        break;
      case "enelList":
        loadComponents["enel"] = enelComponent();
        break;
      case "criminalDetails":
        loadComponents["criminal"] = criminalComponent();
        break;
      case "sispenVisitor":
        loadComponents[component] = sispenVisitorComponent();
        break;
      case "sispenApenado":
        loadComponents[component] = sispenApenadoComponent();
        break;
      default:
        break;
    }
  }

  loadComponents["footer"] = footerComponent();

  return loadComponents;
};

export const pdfDocumentSetup = (data) => {
  const reports = generateReports(data);
  return buildComponents(reports);
};
