
import util from '../../../helpers/util'

export const Filiacoes = {
  created: `2019-04-22`,
  context: `Filiações`,
  params: {
    mother: {placeholder: `Mãe`},
    father: {placeholder: `Pai`}
  },
  createRow: (params) => {
    if (util.countNames(params.mother || ``) < 2 && util.countNames(params.father || ``) < 2)
      throw new Error(`É necessário detalhar mais o nome da pessoa. Por favor, tente novamente.`)
    //
    return {
      card: `CardBuscaFonetica`,
      props: params,
      //
      image: `avatar.png`,
      title: `Possíveis Filiações`,
      lines: [
        [{label: `Mãe`, value: (params.mother || ``).toUpperCase()}],
        [{label: `Pai`, value: (params.father || ``).toUpperCase()}],
      ]
    }
  }
}
