import React, { Component } from 'react'
import Input from './Input'
import './Row.scss'

export default class Row extends Component {

  state = { params: [] }

  componentDidMount() {
    const params = Object.entries(this.props.option.params || {}).map(([id, param], index) => {
      const { type, label, placeholder, optional } = param
      return { index, id, type, label, placeholder, optional, value: `` }
    })
    this.setState({ params })
  }

  clickHandler = () => {
    const { typeSelected } = this.props
    const data = {}
    try {
      this.state.params.forEach(i => {
        if (typeSelected && i.type && typeSelected.fixText === i.type.fixText) {
          data[i.id] = typeSelected.text
        } else {
          if (i.optional) {
            data[i.id] = i.type && i.type.getValue && i.value ? i.type.getValue(i.value) : i.value
          } else {
            data[i.id] = i.type && i.type.getValue ? i.type.getValue(i.value) : i.value
          }
        }
      })
      const json = this.props.option.parser(data)
      this.props.onClick(json)
    } catch (e) {
      console.error(e)
      this.setState({ error: true })
      setTimeout(() => {
        this.setState({ error: false })
      }, 1200)
    }
  }

  changeInputTagHandler = (data) => {
    const { label, value } = data
    const params = this.state.params.map(i => {
      return { ...i, value: i.label === label ? value : i.value }
    })
    this.setState({ params })
  }

  render() {
    const noParams = Object.keys(this.props.option.params || {}).length === 0
    const { error } = this.state
    return (
      <div className={`SearchPanel-BodyOptions-Row` + (error ? ` shake` : ``) + (noParams ? ` clickable` : ``)} onClick={noParams ? this.clickHandler : null}>
        <div className="content">
          {this.renderFirstColumn()}
          {this.renderContent()}
        </div>
        <button className="btn-done" onClick={this.clickHandler}></button>
      </div>
    )
  }

  renderFirstColumn() {
    const { tags = [] } = this.props
    if (tags.length === 0)
      return <span className="context-name">&bull;</span>
    return (
      <React.Fragment>
        {tags.map(i => (
          <div className="tag" key={i}>{i}</div>
        ))}
      </React.Fragment>
    )
  }

  renderContent() {
    const { tag, option, typeSelected = null } = this.props
    const { params } = this.state
    if (params.length === 0 && option.title) {
      return (
        <div className="title">
          <b>{option.title}</b>
          <p>{option.description || `-`}</p>
        </div>
      )
    }

    if (params.length !== 0 && option.title) {
      return (
        <React.Fragment>
          <div className="description">
            <div className="informative-title">{option.title}</div>
            <div className="informative-subtitle">{option.subtitle}</div>
            {params.map(i => (
              <Input key={tag + `-` + i.label}
                type={i.type}
                label={i.label}
                placeholder={i.placeholder}
                optional={i.optional}
                value={i.type && typeSelected && typeSelected.fixText === i.type.fixText ? typeSelected.text : i.value}
                onChange={this.changeInputTagHandler}
                onEnterKey={this.clickHandler}
              />
            ))}
            <p>{option.description || ``}</p>
          </div>
        </React.Fragment>
      )
    }
    if (params.length !== 0 && option.description) {
      return (
        <React.Fragment>
          <div className="description">
            {params.map(i => (
              <Input key={tag + `-` + i.label}
                type={i.type}
                label={i.label}
                placeholder={i.placeholder}
                optional={i.optional}
                value={i.type && typeSelected && typeSelected.fixText === i.type.fixText ? typeSelected.text : i.value}
                onChange={this.changeInputTagHandler}
                onEnterKey={this.clickHandler}
              />
            ))}
            <p>{option.description || ``}</p>
          </div>
        </React.Fragment>
      )
    }
    return (
      <React.Fragment>
        {params.map(i => {
					return <>
						<> {i.type && i.type.isMulti &&
							<><div className="divisor" ><br/>
							</div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</> } </>
						<Input key={tag + `-` + i.label}
							type={i.type}
							label={i.label}
							placeholder={i.placeholder}
							optional={i.optional}
							value={i.type && typeSelected && typeSelected.fixText === i.type.fixText ? typeSelected.text : i.value}
							onChange={this.changeInputTagHandler}
							onEnterKey={this.clickHandler}
						/>
					</>
				})}
      </React.Fragment>
    )
  }

}
