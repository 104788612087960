import srcImageAvatar from '../../../images/avatar.png'
import util from '../../../helpers/util'
import * as type from '../fieldTypes'

export const SispenVisitante = {
  created: `2019-04-22`,
  context: `SISPEN.Visitante`,
  role: `CEREBRUM_VISITANTES_SISPEN`,
  params: {
    name: {placeholder: `Nome`},
  },
  createRequest: params => {
    if (util.countNames(params.name || ``) < 2)
      throw new Error(`É necessário detalhar mais o nome da pessoa. Por favor, tente novamente.`)
    //
    return {path: `/sispen/visitors`, params}
  },
  createRow: json => {
    
    const { thumb, name, sex, rg, cpf, birthDate, id } = json
    return {
      card: `CardSispenVisitante`,
      props: cpf ? {cpf} : {...json, thumbDefault: srcImageAvatar},
      //
      image: thumb || `avatar.png`,
      title: `ID ` + id + ` - ` + name,
      lines: [
        [
          {label: `RG`, value: rg},
          {label: `CPF`, value: cpf ? util.formatCPF(cpf) : ``},
        ],
        [
          {label: `Sexo`, value: sex ? (sex === `F` ? `Feminino` : `Masculino`) : ``},
          {label: `Data de Nascimento.`, value: birthDate ? util.date2str(util.str2date(birthDate)) : ``},
        ]
      ],
      fields: [
        {label: `Data de Nascimento`, value: birthDate ? util.date2str(util.str2date(birthDate)) : null},
        {label: `Nome`, value: name},
        {label: `RG`,   value: rg ? rg : null},
        {label: `CPF`,  value: cpf ? util.formatCPF(cpf) : null},
      ]
    }
  }
}

export const SispenVisitanteByCPF = {
  created: `2019-04-22`,
  context: `SISPEN.Visitante`,
  role: `CEREBRUM_VISITANTES_SISPEN`,
  params: {
    cpf: {label: `CPF`, placeholder: `000.000.000-00`, type: type.cpf},
  },
  parser: ({cpf}) => {
    cpf = cpf.replace(/\W/g, ``)
    return {card: `CardSispenVisitante`, props: {cpf}}
  }
}
